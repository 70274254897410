import { useEffect, useMemo, useRef, useState } from 'react'
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine'
import { draggable, dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import {
  type Edge,
  attachClosestEdge,
  extractClosestEdge
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge'
import DropIndicator from '@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box'
import { motion } from 'framer-motion'
import { useLatest } from 'react-use'

type TProps = {
  index?: number
  onDrop?: ({
    indexOfSource,
    indexOfTarget,
    closestEdgeOfTarget
  }: {
    indexOfSource: number
    indexOfTarget: number
    closestEdgeOfTarget: Edge | null
  }) => void
}

export const useOptionItemDragging = ({ index, onDrop }: TProps) => {
  const [closestEdge, setClosestEdge] = useState<Edge | null>(null)
  const [isDragging, setIsDragging] = useState(false)

  const ref = useRef<HTMLDivElement>(null)
  const dragHandleRef = useRef<HTMLDivElement>(null)
  const optionItemMetaRef = useLatest({ index })
  const onDropRef = useLatest(onDrop)

  useEffect(() => {
    const element = ref.current
    const dragHandle = dragHandleRef.current

    if (!element || !dragHandle) return

    return combine(
      draggable({
        element,
        dragHandle,
        getInitialData: () => optionItemMetaRef.current,
        onDragStart: () => setIsDragging(true),
        onDrop: () => setIsDragging(false)
      }),
      dropTargetForElements({
        element,
        canDrop: ({ source }) => source.element !== element,
        getData: ({ input }) => {
          const data = optionItemMetaRef.current

          return attachClosestEdge(data, {
            input,
            element,
            allowedEdges: ['top', 'bottom']
          })
        },
        getIsSticky: () => true,
        onDrag: ({ self }) => {
          const closestEdge = extractClosestEdge(self.data)

          setClosestEdge(closestEdge)
        },
        onDragLeave: () => setClosestEdge(null),
        onDrop: ({ location, source }) => {
          setClosestEdge(null)

          const dropTarget = location.current.dropTargets[0]

          if (!dropTarget || !onDropRef.current) return

          const sourceData = source.data
          const targetData = dropTarget.data

          const indexOfSource = sourceData.index as number
          const indexOfTarget = targetData.index as number

          if (indexOfSource < 0 || indexOfTarget < 0) return

          const closestEdgeOfTarget = extractClosestEdge(targetData)

          onDropRef.current({ indexOfSource, indexOfTarget, closestEdgeOfTarget })
        }
      })
    )
  }, [ref, optionItemMetaRef, onDropRef])

  const dropIndicator = useMemo(() => {
    if (!closestEdge) return null

    return (
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <DropIndicator edge={closestEdge} gap="10px" />
      </motion.div>
    )
  }, [closestEdge])

  return { ref, dragHandleRef, dropIndicator, isDragging }
}
