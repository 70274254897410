import {
  type CompatServiceDefinition,
  createChannel,
  createClient,
  FetchTransport
} from 'nice-grpc-web'

export class _GrpcService {
  private _istioHost = new URL(
    window.istioHost || import.meta.env.VITE_ISTIO_HOST || 'https://lxapidev.praxie.com/'
  ).origin

  private _channel = createChannel(this._istioHost, FetchTransport({ credentials: 'include' }))

  createClient<Service extends CompatServiceDefinition>(service: Service) {
    return createClient(service, this._channel)
  }
}

export const GrpcService = new _GrpcService()
