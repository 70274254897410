import messages from 'constants/messages'

export const MAX_NAV_ITEMS_NUMBER = 20

export const CARD_OPENING_MODE = {
  detailed: 'detailed',
  fullScreen: 'fullScreen',
  app: 'app'
}

export const CARD_OPENING_MODE_OPTIONS = [
  {
    value: CARD_OPENING_MODE.app,
    label: messages.APP_VIEW
  },
  {
    value: CARD_OPENING_MODE.detailed,
    label: messages.DETAILED_VIEW
  },
  {
    value: CARD_OPENING_MODE.fullScreen,
    label: messages.FULL_SCREEN_VIEW
  }
]

export const BOARD_OPENING_MODE_OPTIONS = [
  {
    value: 'boardView',
    label: messages.BOARD_VIEW
  }
]

export const NAV_ITEM_TYPES = {
  board: 'board',
  card: 'card',
  external: 'external',
  group: 'group'
}

export const NAV_ITEMS_DRAG_TYPES = {
  BOARD_LIST_ITEM: 'BOARD_LIST_ITEM',
  NAVIGATION_ITEM: 'NAVIGATION_ITEM'
}
