import { Link } from 'react-router-dom'
import BoardIcon from 'assets/images/icons/ic_board.svg?react'
import { type IJob, EJobStatuses } from 'features/jobs/jobs.types'
import { PATHS } from 'constants/paths.constants'
import messages from 'constants/messages'
import CircleSpinner from 'components/spinners/CircleSpinner'

type TProps = {
  job: IJob
  retryCopyBoard: () => void
  onLinkClick: () => void
}

export const CopyBoardsIndicatorItem = ({ job, retryCopyBoard, onLinkClick }: TProps) => (
  <div className="board_item">
    <div className="board_data">
      <BoardIcon />
      <div className="board_content">
        <span className="board_name bold">{job.data.boardName}</span>
        {job.status === EJobStatuses.ERROR && (
          <span className="error-text">{messages.OPERATION_FAILED}</span>
        )}
      </div>
    </div>
    <span className="board_progress">
      {job.status !== EJobStatuses.DONE && job.status !== EJobStatuses.ERROR && (
        <CircleSpinner className="copied-board__spinner" />
      )}
      {job.status === EJobStatuses.DONE && (
        <Link
          onClick={onLinkClick}
          to={`${PATHS.board.url}/${job.data.dstTenantId}/${job.data.dstBoardId}`}
          className="blue-link"
        >
          {messages.OPEN}
        </Link>
      )}
      {job.status === EJobStatuses.ERROR && (
        <span className="blue-link" onClick={retryCopyBoard}>
          {messages.RETRY}
        </span>
      )}
    </span>
  </div>
)
