import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchVariableConnections } from 'actions'
import { makeCancelable } from 'helpers/fetchHelpers'
import { ConfirmationModal } from 'components/modals/confirmation/confirmationModal'
import ErrorIcon from 'assets/images/icons/ic_error.svg?react'
import WarningIcon from 'assets/images/icons/ic_workflow_warning.svg?react'
import type { TVariableConnection } from '../variableConnections/variableConnections.types'
import { ConnectionList } from '../variableConnections/connectionList/connectionList'
import './variable-update-confirmation.scss'

type TProps = {
  isOpen: boolean
  tenantId: string
  variableId: string
  onConfirm: () => void
  onCancel: () => void
  connectionsNumber: number
}

export const VariableUpdateConfirmation = ({
  isOpen,
  onConfirm,
  onCancel,
  tenantId,
  variableId,
  connectionsNumber
}: TProps) => {
  const [isFetching, setIsFetching] = useState(false)
  const [isError, setIsError] = useState(false)
  const [connections, setConnections] = useState<TVariableConnection[]>([])

  const dispatch = useDispatch()

  useEffect(() => {
    let request: { promise: Promise<TVariableConnection[]>; cancel: () => void }

    if (isOpen) {
      setIsFetching(true)
      setIsError(false)

      request = makeCancelable(
        dispatch(
          // @ts-ignore
          fetchVariableConnections({ variableId, tenantId })
        )
      )

      request.promise
        .then(data => setConnections(data))
        .catch(() => setIsError(true))
        .finally(() => setIsFetching(false))
    }

    return () => {
      if (request) {
        request.cancel()
        setIsFetching(false)
        setIsError(false)
      }
    }
  }, [dispatch, isOpen, variableId, tenantId])

  return (
    <ConfirmationModal
      size="md"
      show={isOpen}
      className="variable-update-confirmation-modal"
      showCancelButton
      onModalConfirm={onConfirm}
      onModalCancel={onCancel}
      confirmButtonLabel="Save & update all"
      title={
        <>
          <div className="warning-icon">
            <WarningIcon />
          </div>
          <span>
            There are
            <span className="connections-count"> {connectionsNumber} </span>
            connections using this variable.
          </span>
        </>
      }
      descriptionAs="div"
      description={
        <div className="content">
          <div>Do you want to proceed and update all connections?</div>
          <div className="connections-list">
            <div className="title">Connections ({connectionsNumber})</div>
            {isError ? (
              <div className="connections-fetch-error">
                <ErrorIcon />
                Loading failed. Please try again
              </div>
            ) : (
              <ConnectionList connections={connections} loading={isFetching} />
            )}
          </div>
        </div>
      }
    />
  )
}
