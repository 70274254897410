import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import 'scss/buttons/up-round-icon-button.scss'

const RoundIconButton = forwardRef(
  (
    {
      as: Component,
      id,
      'data-testid': dataTestId,
      type,
      size,
      children,
      className,
      appearance,
      onClick,
      onMouseOver,
      onMouseLeave,
      onFocus,
      onBlur,
      disabled
    },
    ref
  ) => {
    return (
      <Component
        ref={ref}
        id={id}
        data-testid={dataTestId}
        type={type}
        className={classNames('up-round-icon-button', appearance, size, className, {
          disabled
        })}
        disabled={disabled}
        onClick={onClick}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        {children}
      </Component>
    )
  }
)

RoundIconButton.propTypes = {
  as: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  size: PropTypes.oneOf([
    'extra-small',
    'small-20',
    'small',
    'medium',
    'large',
    'extra-large',
    'xxl'
  ]),
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  appearance: PropTypes.oneOf(['white-bg', 'light-bg', 'gray-bg', 'dark-bg']),
  onClick: PropTypes.func,
  onMouseOver: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool
}

RoundIconButton.defaultProps = {
  as: 'button',
  type: 'button',
  size: 'medium',
  className: '',
  appearance: 'white-bg',
  onClick: null,
  disabled: false
}

export default RoundIconButton
