export enum EJobStatuses {
  PENDING = 'PENDING',
  DONE = 'DONE',
  ERROR = 'ERROR',
  RUNNING = 'RUNNING'
}

export enum EJobTypes {
  EXPORT_BOARDS_JOB = 'EXPORT_BUNDLE',
  IMPORT_BOARDS_JOB = 'IMPORT_BUNDLE',
  PUBLISH_APP_JOB = 'PUBLISH_APP_JOB',
  COPY_BOARD_JOB = 'COPY_BOARD'
}
export interface IJob {
  authorId: string
  createdAt: number
  id: string
  organizationId: string
  tenantId: string
  status: EJobStatuses
  type: EJobTypes
  lastUpdated: number
  data: {
    name?: string
    presentationBundleName?: string
    bundleName: string
    boardName?: string
    boardIds?: string[]
    dstBoardId?: string
    dstTenantId?: string
  }
  importedContext?: {
    appId?: string
  }
  error?: {
    type: string
  }
}

export type ISocket = {
  on: (type: string, callback: (args: { jobId: string; status?: string }) => void) => void
  off: (type: string, callback: (args: { jobId: string; status?: string }) => void) => void
}
