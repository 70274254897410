/* eslint-disable import/no-cycle */
import { matchPath } from 'react-router-dom'
import dayjs from 'dayjs'
import { nanoid } from 'nanoid'
import {
  CreateRequest,
  DeleteRequest,
  QueryRequest,
  UpdateRequest
} from '_proto/comments/v1/comments.pb'
import { FileFolder, UploadBundleRequest } from '_proto/uploader/v1/uploader.pb'
import { FileUploaderGrpcService } from 'services/fileUploader.service'
import { getWidgetsRequest, getCard, getColumn } from 'api/bindCardAPI'
import { getJob, getUserJobs } from 'api/jobsAPI'
import { processAddTenantRequest } from 'api/tenantAPI'
import { bulkWidgetsCreate, bulkWidgetsUpdate, widgetDelete } from 'api/widgetAPI'
import { CommentListenerService } from 'features/cards/comments/cardComments.grpc.helpers'
import { EJobStatuses } from 'features/jobs/jobs.types'
import { useTeamStore } from 'features/team/team.store'
import { CardCommentsGrpcService } from 'features/cards/comments/cardComments.grpc.service'
import { APP_SCHEMA_IMPORTED, JOB_COMPLETED } from 'constants/actionTypes'
import { history } from 'services/common/history.service'
import { AsyncQueueService } from 'services/common/asyncQueue.service'
import { IS_MOBILE_DEVICE } from 'helpers/userAgent.helpers'
import { convertDate } from 'helpers/common/datesHelpers'
import { isServerUnavailable, isTextErrorMessage } from 'helpers/errorsHandlingHelpers'
import { cardWidgetsQuery } from 'graphql/queries/cardQueries'
import {
  checkRoute,
  navigateToBoard,
  navigateToDetailedView,
  navigateToEmptyTenant,
  routesList,
  navigateToHomePage,
  redirectToEmptyTenant
} from 'helpers/routesHelpers'
import { isAnyWidgetFromFirstSection } from 'helpers/widget/widgetDataHelpers'
import { getInitialSectionsLoading } from 'helpers/detailedView/sectionsLoading'
import { restrictWidgetsCount } from 'helpers/builderHelpers'
import {
  mapAuthorDataFromComment,
  mapAuthorDataFromCommentList,
  replaceTempComment
} from 'helpers/comments/commentsHelpers'
import { cardWidgetsSelector } from 'selectors/boardSelectors'
import { getSocket } from 'helpers/socketHelpers'
import { generateGUID } from 'helpers/common/commonHelpers'
import { updateSnapshotsOnCards } from 'helpers/board/boardOperations'
import { setUserPreferences, getUserPreferences } from 'helpers/userPreferences.helpers'
import { getOrgActiveCollections } from 'selectors/profile.selectors'
import { fetchCardsCommentsCount, getCardComment } from './commentsAction'
import {
  toggleBoardSpinner,
  toggleColumnLoading,
  toggleTopMenuLoader,
  showModalWindowSpinner,
  hideModalWindowSpinner,
  toggleCardNameSpinner,
  toggleNewColumnSpinner
} from './spinnerActions'
import {
  addNewJob,
  removeJob,
  subscribeToBoard,
  subscribeToCards,
  toggleBoardCopying
} from './socketActions'
import {
  boardMenuRequest,
  fetchWorkspaceSubscriptions,
  updateBoardName as updateBoardNameAction,
  getTeamSettings,
  toggleIsOnBoardSettings,
  updateNewImportedApps
} from './profileActions'
import { toggleDrawerDrag } from './drawerActions'
import {
  setSavingInProgress,
  clearSectionsLoading,
  setSectionsLoading,
  togglePresentationMode,
  setLoadedCardsSections
} from './detailedViewActions'
import { openCardBuilder, toggleBuilderCreateMode, togglePopupNotification } from './builderActions'
import messages from '../constants/messages'
import { PATHS } from '../constants/paths.constants'
import {
  SET_CARDS_POSITION_ETAG,
  SET_BOARD_THIRD_PARTY_WIDGETS,
  ADD_TOAST_MESSAGE,
  TOGGLE_TOAST_MESSAGE_VISIBLE,
  HIDE_TOAST_MESSAGE,
  TOAST_MESSAGE_TYPE,
  DRAFT_MESSAGE_TYPE,
  SET_BOARD_CARDS,
  ADD_NEW_CARDS,
  RELOAD_BOARD_CARDS,
  TOGGLE_BOARD_WIDGETS_LOADING,
  SET_BOARD_CARDS_WIDGETS,
  UPDATE_BOARD_CARDS_WIDGETS,
  APPEND_CARD_WIDGETS,
  APPLY_CARDS_OPERATIONS,
  DISABLE_BOARD_DRAG,
  TOGGLE_CARD_TITLE_EDITING,
  RECEIVE_BOARD_PERMISSIONS,
  RENAME_BOARD_TOGGLE,
  TOGGLE_RENAME_BOARD_MODAL,
  TOGGLE_WELCOME_CARD_PREVIEW,
  RECEIVE_BOARD,
  CLEAR_CURRENT_BOARD,
  SET_BOARD_LOADING_ERROR,
  TOGGLE_BOARD_CREATION_MODAL,
  TOGGLE_BOARD_COPY_MODAL,
  TOGGLE_BOARD_DELETE_MODAL,
  TOGGLE_BOARD_NAME_LOADER,
  RECEIVE_UPDATE_BOARD,
  CLEAR_CARD_SNAPSHOT,
  RECEIVE_COLUMN_UPDATE,
  UPDATE_CARD_OWNERS,
  REQUEST_DELETE_CARD,
  RECEIVE_DELETE_CARD,
  RECEIVE_DELETE_CARD_ERROR,
  TOGGLE_COLUMN_DELETE_MODAL,
  TOGGLE_CARD_DELETE_MODAL,
  COPY_BOARD_INDICATOR_TYPE,
  EXPORT_BUNDLE_INDICATOR_TYPE,
  IMPORT_BUNDLE_INDICATOR_TYPE,
  WORKFLOW_EXECUTION_INDICATOR_TYPE,
  APP_PUBLISHING_INDICATOR_TYPE,
  RECEIVE_WIDGET_UPDATE_ON_DETAILED_VIEW,
  RECEIVE_WIDGET_CREATE_ON_DETAILED_VIEW,
  RECEIVE_WIDGET_DELETE_ON_DETAILED_VIEW,
  ADD_CARD_PARTICIPANTS,
  REMOVE_CARD_PARTICIPANTS,
  ADD_CARD_COMMENT,
  SET_CARD_COMMENTS,
  SET_CARD_COMMENTS_LIST_OFFSET,
  SET_CARD_COMMENTS_LOADED,
  DELETE_CARD_COMMENT,
  EDIT_CARD_COMMENT,
  CLEAN_UP_CARD_COMMENTS,
  MS_AUTH_MESSAGE_TYPE,
  JOB_COMPLETED_SOCKET,
  ADD_NEW_JOB,
  REQUEST_BOARD_SETTINGS,
  RECEIVE_BOARD_SETTINGS,
  RECEIVE_BOARD_SETTINGS_ERROR,
  TOGGLE_BOARDS_EXPORT_MODAL,
  IMPORT_APP_INDICATOR_TYPE,
  TOGGLE_TENANT_CREATION_MODAL,
  SET_BOARD_CARDS_COMMENTS_COUNT
} from '../constants/actionTypes'
import {
  processSaveCardsRequest,
  processAddCardRequest,
  renameCard as renameCardAction,
  toggleColumnSorting as toggleColumnSortingAction,
  toggleColumnKanbanVisibility as toggleColumnKanbanVisibilityAction,
  toggleColumnVisibility as toggleColumnVisibilityAction,
  changeCardOwners as changeCardOwnersAction,
  processDeleteCardRequest,
  processCopyCardRequest,
  processAddCardParticipants,
  processRemoveCardParticipants
} from '../api/cardAPI'
import { getBoard as getBoardAPI } from '../api/bindBoardAPI'
import {
  processBoardPermissionsRequest,
  processGetBoardRequest,
  createBoard,
  updateBoard as updateBoardAction,
  processDeleteBoardRequest,
  processCopyBoardRequest,
  processBoardSettingsRequest,
  processUpdateBoardSettings,
  processPublicBoardRequest,
  updateBoardAutoRefreshThirdParty,
  updateBoardConditionalLayout,
  exportAppBundle,
  processExportBoardsBundle,
  processImportBoardsBundle
} from '../api/boardAPI'
import { updateThirdPartyWidgets } from '../helpers/linkingResolution/thirdPartyUpdating'
import { navigateToAppImportView } from '../helpers/routesHelpers'

const TOAST_APPEARING_ANIMATION_DELAY = 100
const TOAST_DISAPPEARING_ANIMATION_DELAY = 500
const COMMENTS_LIMIT = 20
const MINIMUM_BOARDS_COUNT = 1

export function setCardsPositionsETag(payload) {
  return { type: SET_CARDS_POSITION_ETAG, payload }
}

export function setBoardThirdPartyWidgets(payload) {
  return { type: SET_BOARD_THIRD_PARTY_WIDGETS, payload }
}

export function addToastMessage(payload) {
  return { type: ADD_TOAST_MESSAGE, payload }
}

export function toggleToastMessageVisible(payload) {
  return { type: TOGGLE_TOAST_MESSAGE_VISIBLE, payload }
}

export function hideToastMessage(payload) {
  return dispatch => {
    dispatch(toggleToastMessageVisible({ id: payload, visible: false }))
    setTimeout(() => {
      dispatch({ type: HIDE_TOAST_MESSAGE, payload })
    }, TOAST_DISAPPEARING_ANIMATION_DELAY)
  }
}

export function showToastMessage(data, type = TOAST_MESSAGE_TYPE, delay = 5000) {
  return dispatch => {
    const id = nanoid()
    // add toast message to array
    dispatch(addToastMessage({ id, data, type }))
    // make it visible
    setTimeout(() => {
      dispatch(toggleToastMessageVisible({ id, visible: true }))
    }, TOAST_APPEARING_ANIMATION_DELAY)

    if (Number.isFinite(delay)) {
      // hide it after delay
      setTimeout(() => {
        dispatch(hideToastMessage(id))
      }, delay)
    }

    return id
  }
}

export function toggleDraftMessage(isDraftMessageShown) {
  return (dispatch, getState) => {
    const toastMessages = getState().board.toastMessages
    const draftMessages = toastMessages.filter(message => message.type === DRAFT_MESSAGE_TYPE)
    if (isDraftMessageShown && draftMessages.length === 0) {
      dispatch(showToastMessage({ size: 'L' }, DRAFT_MESSAGE_TYPE, Infinity))
    } else if (isDraftMessageShown === false && draftMessages.length) {
      const draftMessage = draftMessages[0]
      dispatch(hideToastMessage(draftMessage.id))
    }
  }
}

function notFoundFallback(error) {
  return dispatch => {
    if (error.errorCode === 404) {
      dispatch(
        showToastMessage({
          text: messages.REFRESH_PAGE,
          size: 'M'
        })
      )
    } else if (error.errorCode === 403) {
      dispatch(
        showToastMessage({
          text: messages.DONT_HAVE_PERMISSION,
          size: 'M'
        })
      )
    }
    if (error.errorCode === 404 || error.errorCode === 403) {
      dispatch(boardMenuRequest()).then(() => navigateToBoard({ isRecent: true }))
    }
  }
}

// Get cards
export function setBoardCards(payload) {
  const updatedCards = updateSnapshotsOnCards(payload)
  return { type: SET_BOARD_CARDS, payload: updatedCards }
}

export function addNewCards(payload) {
  return { type: ADD_NEW_CARDS, payload }
}

// needs to fix this bug https://leverxeu.atlassian.net/browse/EUPBOARD01-3638
// update first card to pass new cards set to state of boardDragContainer
// and remove cards which was added to state and then don't resolved in store
// because of error from server
export function reloadCards(payload) {
  return { type: RELOAD_BOARD_CARDS, payload }
}

export const toggleBoardWidgetsLoading = payload => ({
  type: TOGGLE_BOARD_WIDGETS_LOADING,
  payload
})

export const setBoardWidgets = payload => ({
  type: SET_BOARD_CARDS_WIDGETS,
  payload
})

export const updateBoardWidgets = payload => ({
  type: UPDATE_BOARD_CARDS_WIDGETS,
  payload
})

export const appendCardWidgets = payload => ({
  type: APPEND_CARD_WIDGETS,
  payload
})

export const getCardsWidgets = payload => dispatch => {
  dispatch(toggleBoardWidgetsLoading(true))

  const queries = payload.cards
    .filter(card => !card.isCol)
    .map(card => cardWidgetsQuery({ card, showOnBoardView: true }))

  return dispatch(getWidgetsRequest({ ...payload, queries }))
    .then(response => {
      dispatch(updateBoardWidgets(response.data))

      return response.data
    })
    .finally(() => dispatch(toggleBoardWidgetsLoading(false)))
}

export const clearBoardCards = () => dispatch => {
  dispatch(setBoardCards([]))
  dispatch(setBoardWidgets({}))
}

export function boardRequest(payload) {
  return (dispatch, getState) =>
    dispatch(
      getBoardAPI({
        ...payload,
        expand: {
          cards: {
            lockOwner: true,
            widgetsToShowNumber: true
          }
        }
      })
    )
      .then(response => {
        const {
          data: { cards, cardsPositionsETag }
        } = response

        const { organizationId } = getState().profile.activeOrganization
        dispatch(setBoardCards(cards))
        dispatch(fetchCardsCommentsCount(cards))
        dispatch(setCardsPositionsETag(cardsPositionsETag))

        dispatch(clearSectionsLoading())
        dispatch(setSectionsLoading(getInitialSectionsLoading(cards)))
        CommentListenerService.createCardsCommentUpdateListener(cards, organizationId, () =>
          dispatch(fetchCardsCommentsCount(cards, { forceCommentsCountUpdate: true }))
        )
      })
      .catch(err => {
        dispatch(notFoundFallback(err))

        return Promise.reject(err)
      })
}

// board reloader in case if board version in outdated
export function boardReloader(payload) {
  return dispatch => {
    dispatch(
      showToastMessage(
        {
          text: messages.SOMETHING_WRONG,
          size: 'M'
        },
        TOAST_MESSAGE_TYPE,
        10000
      )
    )
    dispatch(toggleBoardSpinner(true))
    dispatch(boardRequest(payload)).finally(() => dispatch(toggleBoardSpinner(false)))
  }
}

// Save cards coordinates into the board
export function applyCardsOperations(payload) {
  return { type: APPLY_CARDS_OPERATIONS, payload }
}

export const updateColumnCards = payload => {
  return (dispatch, getState) => {
    const { cards } = getState().board

    const shouldUpdateColumn = cards.some(
      card => card.uuid === payload.columnUuid && card.isSortedColumn
    )

    if (!shouldUpdateColumn) return

    dispatch(
      getColumn({
        ...payload,
        expand: {
          cards: { lockOwner: true, widgetsToShowNumber: true },
          column: true
        }
      })
    ).then(({ data: columnCards }) => {
      // Replace cards with updated position cards
      const updatedCards = cards.map(originalCard => {
        const matchedCard = columnCards.find(columnCard => columnCard.uuid === originalCard.uuid)

        if (matchedCard) return matchedCard

        return originalCard
      })

      dispatch(setBoardCards(updatedCards))
    })
  }
}

const reorderingRequestsQueue = new AsyncQueueService()

export const reorderCards = payload => dispatch => {
  dispatch(applyCardsOperations(payload.operations))

  const queuedRequest = prevResponse => {
    const localPayload = { ...payload }

    if (prevResponse && prevResponse.cardsPositionsETag) {
      localPayload.cardsPositionsETag = prevResponse.cardsPositionsETag
    }

    return processSaveCardsRequest(localPayload)
      .then(response => {
        dispatch(setCardsPositionsETag(response.cardsPositionsETag))

        // Update cards in this column if sorting is enabled and a card from another column is added
        dispatch(updateColumnCards(localPayload.operations.update))

        return response
      })
      .catch(err => {
        dispatch(notFoundFallback(err))
        if (err.errorCode !== 404) {
          dispatch(boardReloader(payload))
        }
      })
  }

  reorderingRequestsQueue.enqueue(queuedRequest)
}

// disable drag on board
export function disableBoardDrag(payload) {
  return { type: DISABLE_BOARD_DRAG, payload }
}

// to enable card/column title editing on board pass here card/column uuid
export function toggleCardTitleEditing(payload) {
  return { type: TOGGLE_CARD_TITLE_EDITING, payload }
}

export function addCard(payload) {
  return (dispatch, getState) => {
    const {
      spinner: { loadingColumnUuid },
      board: {
        currentBoard: { defaultCard }
      }
    } = getState()

    // skip add card request when previous add card operation in progress
    // or while column creating
    if (loadingColumnUuid) {
      return null
    }

    dispatch(toggleColumnLoading(payload.card.columnUuid))
    dispatch(disableBoardDrag(true))

    return processAddCardRequest(payload)
      .then(response => {
        const copiedCard = response.data

        ga('send', {
          hitType: 'event',
          eventCategory: 'Card',
          eventAction: 'Create',
          eventValue: getState().board.cards.filter(card => !card.isCol).length + 1
        })

        const isOpenCardBuilder =
          !copiedCard.isCreatedFromDefaultCard ||
          (copiedCard.isCreatedFromDefaultCard && defaultCard.addingOption === 'card_builder')

        if (isOpenCardBuilder) {
          dispatch(toggleBuilderCreateMode(true))

          return dispatch(
            openCardBuilder({
              tenantId: payload.tenantId,
              boardId: payload.boardId,
              cardID: copiedCard.uuid
            })
          )
        }
        dispatch(addNewCards([copiedCard]))
        dispatch(setCardsPositionsETag(response.cardsPositionsETag))

        if (defaultCard.addingOption === 'full_screen') {
          dispatch(togglePresentationMode(true))
        }
        if (
          defaultCard.addingOption === 'full_screen' ||
          defaultCard.addingOption === 'detailed_view'
        ) {
          navigateToDetailedView({
            cardUuid: copiedCard.uuid,
            tenantId: copiedCard.tenantId,
            boardId: copiedCard.boardId
          })
        }

        if (defaultCard.addingOption === 'board') {
          dispatch(toggleCardTitleEditing(copiedCard.uuid))
        }

        dispatch(
          subscribeToCards({
            cards: [copiedCard],
            tenantId: payload.tenantId,
            boardId: payload.boardId
          })
        )

        return dispatch(
          getWidgetsRequest({
            tenantId: payload.tenantId,
            boardId: payload.boardId,
            queries: [cardWidgetsQuery({ card: copiedCard, showOnBoardView: true })]
          })
        )
          .then(({ data: widgets }) => {
            dispatch(setSectionsLoading(getInitialSectionsLoading([copiedCard])))
            dispatch(
              appendCardWidgets({
                cardUuid: copiedCard.uuid,
                widgets: widgets[copiedCard.uuid]
              })
            )
          })
          .catch(err => console.error(err))
      })
      .finally(() => {
        dispatch(disableBoardDrag(false))
        dispatch(toggleColumnLoading(null))
      })
      .catch(err => dispatch(notFoundFallback(err)))
  }
}

export function addColumn(payload) {
  return dispatch => {
    dispatch(toggleColumnLoading(payload.card.uuid))
    dispatch(applyCardsOperations({ create: payload.card }))
    return processAddCardRequest(payload)
      .finally(() => dispatch(toggleColumnLoading(null)))
      .then(response => {
        const {
          cardsPositionsETag,
          data: { uuid: columnUuid }
        } = response

        dispatch(setCardsPositionsETag(cardsPositionsETag))
        dispatch(toggleCardTitleEditing(columnUuid))
      })
      .catch(err => {
        dispatch(notFoundFallback(err))
      })
  }
}

// board permissions
export function boardPermissionsReceive(payload) {
  return { type: RECEIVE_BOARD_PERMISSIONS, payload }
}

export const getBoardPermissions = payload => dispatch =>
  processBoardPermissionsRequest(payload).then(response =>
    dispatch(boardPermissionsReceive(response.data))
  )

// set editable state for 'Rename board' input
export function toggleRenameBoard(payload) {
  return { type: RENAME_BOARD_TOGGLE, payload }
}

export function toggleRenameBoardModal(payload) {
  return { type: TOGGLE_RENAME_BOARD_MODAL, payload }
}

// toggle welcome card preview
export function toggleWelcomeCardPreview(payload) {
  return { type: TOGGLE_WELCOME_CARD_PREVIEW, payload }
}

// Get board
export function receiveBoard(payload) {
  return { type: RECEIVE_BOARD, payload }
}

export function clearCurrentBoard() {
  return { type: CLEAR_CURRENT_BOARD }
}

function requestTeamIfNeeded(payload) {
  return (dispatch, getState) => {
    if (!payload.requestTeamIfNeeded) {
      return
    }

    const currentTenantId = getState().profile.teamSettings.id

    if (payload.tenantId === currentTenantId) {
      return
    }

    dispatch(getTeamSettings(payload))
  }
}

export function setBoardLoadingError(payload) {
  return { type: SET_BOARD_LOADING_ERROR, payload }
}

export function getBoard(payload) {
  return (dispatch, getState) => {
    const subscription = getOrgActiveCollections(getState())
    if (payload.showBoardSpinner) {
      dispatch(toggleTopMenuLoader(true))
      dispatch(toggleBoardSpinner(true))
    }
    dispatch(setBoardLoadingError({ isBoardLoadingError: false }))
    return Promise.all([
      dispatch(
        getBoardAPI({
          ...payload,
          expand: {
            cards: {
              lockOwner: true,
              widgetsToShowNumber: true
            }
          }
        })
      ),
      dispatch(getBoardPermissions(payload))
    ])
      .then(([response]) => {
        const {
          profile: {
            activeOrganization: { organizationId },
            user: { id: userId }
          },
          board: {
            currentBoard: { boardPermissions }
          }
        } = getState()
        dispatch(requestTeamIfNeeded(payload))
        const { cards, ...board } = response.data

        const userPreferences = getUserPreferences()

        // default isPublic: undefined
        board.isPublic = !!board.isPublic
        board.isColumnsHidden = !userPreferences[userId]?.boardsWithUnhiddenColumns?.includes(
          board.boardId
        )

        dispatch(receiveBoard(board))
        dispatch(setBoardCards(cards))
        dispatch(fetchCardsCommentsCount(cards))
        dispatch(setCardsPositionsETag(board.cardsPositionsETag))
        dispatch(toggleBoardCopying(!!board.isLocked))
        dispatch(toggleDrawerDrag(!!board.isLocked))
        CommentListenerService.createCardsCommentUpdateListener(cards, organizationId, () =>
          dispatch(fetchCardsCommentsCount(cards, { forceCommentsCountUpdate: true }))
        )

        const needToShowWelcomeCard = !!board.welcomeCard && !board.isWelcomeCardHidden
        const isDetailedView = checkRoute(window.location.pathname, routesList.detailedView)

        if (payload.showWelcomeCard && needToShowWelcomeCard && !isDetailedView) {
          dispatch(
            toggleWelcomeCardPreview({
              ...payload,
              state: true,
              showFooter: true,
              cardUuid: board.welcomeCard
            })
          )
        }

        dispatch(clearSectionsLoading())
        dispatch(setSectionsLoading(getInitialSectionsLoading(cards)))
        if (board.isAutoRefresh3rdPartyDataSources && boardPermissions?.updateWidget) {
          updateThirdPartyWidgets(payload).then(data => {
            if (data?.data) dispatch(setBoardThirdPartyWidgets(data.data))
          })
        }
      })
      .catch(err => {
        // https://leverxeu.atlassian.net/browse/EUPBOARD01-5921
        // to prevent board reloading on each error and go to auth page
        if (err.errorCode === 401) {
          return
        }

        if (isServerUnavailable(err.errorCode)) {
          dispatch(toggleTopMenuLoader(false))
          dispatch(toggleBoardSpinner(false))
          dispatch(setBoardLoadingError({ isBoardLoadingError: true }))
          return
        }

        if (err.errorCode === 404 || err.errorCode === 403) {
          if (!subscription) {
            navigateToHomePage()
            return
          }
          dispatch(boardMenuRequest()).then(data => {
            const { boards } = data
            const tenantsBoard = boards.filter(board => board.tenantId === payload.tenantId)
            // check if we have other available boards in tenant
            if (tenantsBoard.length) {
              const boardId = tenantsBoard[0] && tenantsBoard[0].boardId
              dispatch(
                getBoard({
                  boardId,
                  requestTeamIfNeeded: true,
                  showBoardSpinner: true,
                  showWelcomeCard: true,
                  tenantId: payload.tenantId
                })
              )
            } else {
              navigateToHomePage()
            }
          })
        } else {
          dispatch(
            togglePopupNotification({
              state: true,
              title: messages.BOARD_ERROR,
              desc: messages.BOARD_REQUEST_ERROR
            })
          )
          navigateToHomePage()
        }
      })
      .finally(() => {
        dispatch(toggleBoardSpinner(false))
        dispatch(toggleTopMenuLoader(false))
      })
  }
}

export const getAppViewCard = payload => async (dispatch, getState) => {
  const start = performance.now()
  const {
    tenantId,
    boardId,
    cardUuid,
    boardExpand = {},
    cardExpand = {
      lockOwner: true,
      widgetsJSON: true
    }
  } = payload

  dispatch(toggleTopMenuLoader(true))
  dispatch(toggleBoardSpinner(true))

  try {
    const [
      { data: board },
      {
        data: { widgets, ...card }
      }
    ] = await Promise.all([
      dispatch(getBoardAPI({ tenantId, boardId, expand: boardExpand })),
      dispatch(
        getCard({
          tenantId,
          boardId,
          cardUuid,
          expand: cardExpand,
          filters: {
            widgetsJSON: { fromFirstVisibleSection: true }
          },
          calledFrom: 'appViewCard'
        })
      ),
      dispatch(getBoardPermissions({ tenantId, boardId }))
    ])
    const {
      profile: {
        activeOrganization: { organizationId }
      },
      board: {
        currentBoard: { boardPermissions }
      }
    } = getState()
    dispatch(requestTeamIfNeeded({ tenantId, requestTeamIfNeeded: true }))

    dispatch(
      receiveBoard({
        ...board,
        isPublic: !!board.isPublic // default isPublic: undefined
      })
    )
    dispatch(setBoardCards([card]))
    dispatch(fetchCardsCommentsCount([card]))
    dispatch(setLoadedCardsSections({ [cardUuid]: widgets }))
    dispatch(setBoardWidgets({ [cardUuid]: widgets }))
    dispatch(setCardsPositionsETag(board.cardsPositionsETag))
    dispatch(toggleBoardCopying(!!board.isLocked))
    CommentListenerService.createCardsCommentUpdateListener([card], organizationId, () =>
      dispatch(fetchCardsCommentsCount([card], { forceCommentsCountUpdate: true }))
    )

    dispatch(subscribeToBoard())

    if (board?.isAutoRefresh3rdPartyDataSources && boardPermissions.updateWidget) {
      updateThirdPartyWidgets(payload)
    }
    const end = performance.now()

    ga('send', {
      hitType: 'timing',
      timingCategory: 'App view',
      timingVar: 'load [data flow]',
      timingValue: end - start,
      timingLabel: `${tenantId}/${boardId}/${cardUuid}`
    })
  } catch (err) {
    /* empty */
  } finally {
    dispatch(toggleTopMenuLoader(false))
    dispatch(toggleBoardSpinner(false))
  }
}

export function getBoardInfo(payload) {
  return dispatch =>
    Promise.all([processGetBoardRequest(payload), processBoardPermissionsRequest(payload)])
      .then(([board, permissions]) => {
        dispatch(receiveBoard(board.data))
        dispatch(boardPermissionsReceive(permissions.data))
      })
      .catch(err => {
        if (err.errorCode === 404 || err.errorCode === 403) {
          navigateToBoard({ isRecent: true })
        }

        console.error(err)
      })
}

export function toggleBoardCreationModal(payload) {
  return { type: TOGGLE_BOARD_CREATION_MODAL, payload }
}

export function addBoard(payload) {
  return dispatch => {
    dispatch(showModalWindowSpinner())
    return createBoard(payload)
      .then(response => {
        dispatch(toggleBoardCreationModal(false))
        dispatch(hideModalWindowSpinner())
        navigateToBoard({
          tenantId: response.data.tenantId,
          boardId: response.data.boardId
        })

        ga('send', {
          hitType: 'event',
          eventCategory: 'Board',
          eventAction: 'Create',
          eventLabel: payload.gaLabel
        })
      })
      .then(() => dispatch(boardMenuRequest()))
      .catch(() => dispatch(hideModalWindowSpinner()))
  }
}

export function toggleBoardCopyModal(payload) {
  return { type: TOGGLE_BOARD_COPY_MODAL, payload }
}

export function toggleBoardDeleteModal(payload) {
  return { type: TOGGLE_BOARD_DELETE_MODAL, payload }
}

// board update
export function toggleBoardNameLoader(payload) {
  return { type: TOGGLE_BOARD_NAME_LOADER, payload }
}

export function receiveUpdateBoard(payload) {
  return { type: RECEIVE_UPDATE_BOARD, payload }
}

// update board and put changed into current board
export function updateBoard(payload) {
  return dispatch =>
    updateBoardAction(payload)
      .then(response => {
        // There is no need to use the isColumnsHidden field when updating the board.
        // The isColumnsHidden field is required to support legacy boards, but is now handled by the FE.
        const { isColumnsHidden, ...restData } = response.data

        dispatch(receiveUpdateBoard(restData))
      })
      .catch(err => {
        dispatch(
          showToastMessage({
            text: messages.SOMETHING_WENT_WRONG_TRY_AGAIN,
            size: 'M'
          })
        )

        throw new Error(err)
      })
}

export const updateBoardName = payload => dispatch => {
  dispatch(toggleBoardNameLoader(true))

  return updateBoardAction(payload)
    .then(() => {
      dispatch(updateBoardNameAction(payload))
      dispatch(receiveUpdateBoard({ name: payload.data.name }))
    })
    .catch(err => {
      dispatch(notFoundFallback(err))
      throw new Error(err)
    })
    .finally(() => dispatch(toggleBoardNameLoader(false)))
}

export function clearCardSnapshot(payload) {
  return { type: CLEAR_CARD_SNAPSHOT, payload }
}

export function receiveColumnUpdate(payload) {
  return { type: RECEIVE_COLUMN_UPDATE, payload }
}

export function renameCard(payload) {
  return dispatch => {
    dispatch(toggleCardNameSpinner(payload.cardID))
    return renameCardAction(payload)
      .then(response => {
        const { data } = response
        const { commentsNumber, posD, posN, ...commentData } = data
        dispatch(receiveColumnUpdate(commentData))
        return data
      })
      .then(data => {
        // Update cards in this column if sorting is enabled and a card in this column is renamed
        dispatch(updateColumnCards({ ...payload, columnUuid: data.columnUuid }))
      })
      .finally(() => dispatch(toggleCardNameSpinner(null)))
      .catch(err => dispatch(notFoundFallback(err)))
  }
}

export function toggleColumnSorting(payload) {
  return dispatch =>
    toggleColumnSortingAction(payload)
      .then(({ data }) => {
        dispatch(receiveColumnUpdate(data))

        // Update cards in this column after enabling sorting
        if (payload.data.enableSorting) {
          dispatch(updateColumnCards(payload))
        }
      })
      .catch(err => {
        dispatch(
          showToastMessage({
            text: messages.SOMETHING_WENT_WRONG_TRY_AGAIN,
            size: 'M'
          })
        )
        dispatch(notFoundFallback(err))
      })
}

export function toggleColumnKanbanVisibility(payload) {
  return dispatch =>
    toggleColumnKanbanVisibilityAction(payload)
      .then(response => dispatch(receiveColumnUpdate(response.data)))
      .catch(err => dispatch(notFoundFallback(err)))
}

export function toggleColumnVisibility(payload) {
  return dispatch =>
    toggleColumnVisibilityAction(payload)
      .then(response => dispatch(receiveColumnUpdate(response.data)))
      .catch(err => dispatch(notFoundFallback(err)))
}

export function updateCardOwners(payload) {
  return { type: UPDATE_CARD_OWNERS, payload }
}

export function changeCardOwners(payload) {
  return dispatch => {
    dispatch(updateCardOwners(payload))

    return changeCardOwnersAction({ ...payload, data: { ownersIds: payload.data } }).catch(err =>
      console.error(err)
    )
  }
}

// Column and card deleting
export function deleteCardRequestStart() {
  return { type: REQUEST_DELETE_CARD }
}

export function deleteCardReceive(payload) {
  return { type: RECEIVE_DELETE_CARD, payload }
}

export function receiveDeleteCardErrorMessage(payload) {
  return { type: RECEIVE_DELETE_CARD_ERROR, payload }
}

// toggle column deleting modal
export function toggleColumnDeleteModal(payload) {
  return { type: TOGGLE_COLUMN_DELETE_MODAL, payload }
}

export function toggleCardDeleteModal(payload) {
  return { type: TOGGLE_CARD_DELETE_MODAL, payload }
}

function afterDeleteCard(payload) {
  return dispatch => {
    if (payload.isColumnDeleting) {
      dispatch(
        toggleColumnDeleteModal({
          state: false,
          data: {}
        })
      )
    } else {
      dispatch(
        toggleCardDeleteModal({
          state: false,
          data: {}
        })
      )
    }

    dispatch(hideModalWindowSpinner())

    navigateToBoard({
      tenantId: payload.tenantId,
      boardId: payload.boardId
    })
  }
}

export function deleteCard(payload) {
  return (dispatch, getState) => {
    dispatch(showModalWindowSpinner())
    dispatch(deleteCardRequestStart())

    return processDeleteCardRequest(payload)
      .then(response => {
        dispatch(setCardsPositionsETag(response.cardsPositionsETag))

        dispatch(
          deleteCardReceive({
            cardUuid: payload.cardID,
            isColumnDeleting: payload.isColumnDeleting
          })
        )

        dispatch(afterDeleteCard(payload))
        dispatch(
          showToastMessage({
            text: payload.isColumnDeleting
              ? messages.DELETE_COLUMN_TOAST_MESSAGE
              : messages.DELETE_CARD_TOAST_MESSAGE
          })
        )

        const { cards } = getState().board

        if (payload.isColumnDeleting) {
          ga('send', {
            hitType: 'event',
            eventCategory: 'Column',
            eventAction: 'Delete',
            eventValue: cards.filter(card => card.isCol).length
          })
        } else {
          ga('send', {
            hitType: 'event',
            eventCategory: 'Card',
            eventAction: 'Delete',
            eventLabel: payload.gaLabel,
            eventValue: cards.filter(card => !card.isCol).length
          })
        }
      })
      .catch(err => {
        dispatch(receiveDeleteCardErrorMessage(err))
        dispatch(afterDeleteCard(payload))
        dispatch(notFoundFallback(err))
        if (err.errorCode !== 404 && err.errorCode !== 424) {
          dispatch(boardReloader(payload))
        }
        if (err.errorCode === 424) {
          // column contains draft card
          dispatch(
            showToastMessage({
              text: err.message,
              size: 'M'
            })
          )
        }
      })
  }
}

export function deleteBoard(payload) {
  return (dispatch, getState) => {
    dispatch(showModalWindowSpinner())
    return processDeleteBoardRequest(payload)
      .then(() => {
        const boards = getState().profile.boardMenu.boards
        const isBoardPage = !!matchPath(window.location.pathname, { path: PATHS.board.routerPath })
        // State returns the count of boards before deletion
        const isNoBoardsLeftAfterRemoval = boards.length <= MINIMUM_BOARDS_COUNT

        if (isNoBoardsLeftAfterRemoval && isBoardPage) {
          redirectToEmptyTenant(payload.tenantId)
        }

        dispatch(boardMenuRequest(payload.goToRecentBoard))
      })
      .then(() => {
        dispatch(
          showToastMessage({
            text: messages.DELETE_BOARD_TOAST_MESSAGE
          })
        )

        ga('send', {
          hitType: 'event',
          eventCategory: 'Board',
          eventAction: 'Delete',
          eventLabel: 'Dots Pulldown - Opened Delete Board'
        })
      })
      .finally(() => {
        dispatch(toggleBoardDeleteModal(false))
        dispatch(hideModalWindowSpinner())
      })
      .catch(err => {
        if (err.errorCode === 424) {
          dispatch(
            showToastMessage({
              text: messages.CANNOT_DELETE_BOARD,
              size: 'M'
            })
          )
        } else {
          dispatch(notFoundFallback(err))
        }
      })
  }
}

const toggleJobIndicator = ({ type, isIndicatorShown, dispatch, getState }) => {
  const toastMessages = getState().board.toastMessages
  const indicator = toastMessages.filter(message => message.type === type)[0]
  if (isIndicatorShown && !indicator) {
    dispatch(showToastMessage({ size: 'S' }, type, Infinity))
  } else if (!isIndicatorShown && indicator) {
    dispatch(hideToastMessage(indicator.id))
  }
}

export function toggleCopyBoardsIndicator(isIndicatorShown) {
  return (dispatch, getState) =>
    toggleJobIndicator({
      type: COPY_BOARD_INDICATOR_TYPE,
      isIndicatorShown,
      dispatch,
      getState
    })
}

export function toggleExportIndicator(isIndicatorShown) {
  return (dispatch, getState) =>
    toggleJobIndicator({
      type: EXPORT_BUNDLE_INDICATOR_TYPE,
      isIndicatorShown,
      dispatch,
      getState
    })
}

export function toggleImportIndicator(isIndicatorShown) {
  return (dispatch, getState) =>
    toggleJobIndicator({
      type: IMPORT_BUNDLE_INDICATOR_TYPE,
      isIndicatorShown,
      dispatch,
      getState
    })
}

export function toggleWorkflowExecutionIndicator(isIndicatorShown) {
  return (dispatch, getState) =>
    toggleJobIndicator({
      type: WORKFLOW_EXECUTION_INDICATOR_TYPE,
      isIndicatorShown,
      dispatch,
      getState
    })
}

export function toggleAppPublishingIndicator(isIndicatorShown) {
  return (dispatch, getState) =>
    toggleJobIndicator({
      type: APP_PUBLISHING_INDICATOR_TYPE,
      isIndicatorShown,
      dispatch,
      getState
    })
}

export function copyBoard(payload) {
  return dispatch => {
    dispatch(showModalWindowSpinner())
    return processCopyBoardRequest(payload)
      .then(response => {
        dispatch(addNewJob(response.data))
        dispatch(toggleCopyBoardsIndicator(true))
        ga('send', {
          hitType: 'event',
          eventCategory: 'Board',
          eventAction: 'Copy',
          eventLabel: 'Dots Pulldown - Opened Copy Board'
        })
      })
      .finally(() => {
        dispatch(toggleBoardCopyModal(false))
        dispatch(hideModalWindowSpinner())
      })
      .catch(err => dispatch(notFoundFallback(err)))
  }
}

export function retryCopyBoard(payload) {
  return dispatch =>
    processCopyBoardRequest({
      tenantId: payload.tenantId,
      boardId: payload.boardId,
      boardName: payload.data.boardName,
      targetTenantId: payload.data.dstTenantId
    })
      .then(response => {
        dispatch(removeJob(payload))
        dispatch(addNewJob(response.data))
      })
      .catch(err => dispatch(notFoundFallback(err)))
}

// update widget on detailed view
export function widgetUpdateOnDetailedViewReceive(payload) {
  return { type: RECEIVE_WIDGET_UPDATE_ON_DETAILED_VIEW, payload }
}

// create widgets on detailed view
export function widgetCreateOnDetailedViewReceive(payload) {
  return { type: RECEIVE_WIDGET_CREATE_ON_DETAILED_VIEW, payload }
}

// create widgets on detailed view
export function widgetDeleteOnDetailedViewReceive(payload) {
  return { type: RECEIVE_WIDGET_DELETE_ON_DETAILED_VIEW, payload }
}

export function updateWidgetOnDetailedView(payload) {
  // if linked card is editing on detailed - need to send request to the source card
  // and then update linked card on board
  return dispatch => {
    const requestPayload = payload.sourceCardData
      ? {
          data: payload.data,
          tenantId: payload.sourceCardData.tenantId,
          boardId: payload.sourceCardData.boardId,
          itemID: payload.sourceCardData.itemID,
          target: 'active'
        }
      : {
          ...payload,
          target: 'active'
        }

    ga('send', {
      hitType: 'event',
      eventCategory: 'Widget',
      eventAction: 'Edit',
      eventLabel: payload.data[0].widgetClassName
    })

    dispatch(
      widgetUpdateOnDetailedViewReceive({
        data: payload.data,
        cardID: payload.itemID
      })
    )

    dispatch(setSavingInProgress(true))

    return bulkWidgetsUpdate(requestPayload)
      .then(() => {
        if (isAnyWidgetFromFirstSection(payload.data)) {
          dispatch(clearCardSnapshot({ uuid: payload.itemID }))
        }
        dispatch(setSavingInProgress(false))
        return false
      })
      .catch(err => {
        console.error(err)

        dispatch(
          showToastMessage(
            {
              text: isServerUnavailable(err.errorCode)
                ? messages.WIDGET_UPDATE_ERROR_WHEN_SERVER_DOWN
                : messages.WIDGET_UPDATE_ERROR_WHEN_CARD_EDITING,
              size: 'M'
            },
            TOAST_MESSAGE_TYPE,
            10000
          )
        )

        return Promise.reject(err)
      })
  }
}

export function updateWidgetOnPreview(payload) {
  return dispatch => {
    const requestPayload = {
      ...payload,
      target: 'active'
    }

    return bulkWidgetsUpdate(requestPayload).catch(err => {
      dispatch(
        showToastMessage(
          {
            text: isServerUnavailable(err.errorCode)
              ? messages.WIDGET_UPDATE_ERROR_WHEN_SERVER_DOWN
              : messages.WIDGET_UPDATE_ERROR_WHEN_CARD_EDITING,
            size: 'M'
          },
          TOAST_MESSAGE_TYPE,
          10000
        )
      )
    })
  }
}

export function createWidgetOnDetailedView(payload) {
  return dispatch => {
    const requestPayload = payload.sourceCardData
      ? {
          data: payload.data,
          tenantId: payload.sourceCardData.tenantId,
          boardId: payload.sourceCardData.boardId,
          itemID: payload.sourceCardData.itemID,
          target: 'active'
        }
      : {
          ...payload,
          target: 'active'
        }
    return bulkWidgetsCreate(requestPayload)
      .then(() => {
        dispatch(
          widgetCreateOnDetailedViewReceive({
            data: payload.data,
            cardID: payload.itemID
          })
        )

        dispatch(clearCardSnapshot({ uuid: payload.itemID }))

        return false
      })
      .catch(err => {
        console.error(err)
      })
  }
}

export function deleteWidgetOnDetailedView(payload) {
  return dispatch => {
    const requestPayload = payload.sourceCardData
      ? {
          widgetUuid: payload.widgetUuid,
          tenantId: payload.sourceCardData.tenantId,
          boardId: payload.sourceCardData.boardId,
          itemID: payload.sourceCardData.itemID,
          target: 'active'
        }
      : {
          ...payload,
          target: 'active'
        }

    return widgetDelete(requestPayload)
      .then(() => {
        dispatch(
          widgetDeleteOnDetailedViewReceive({
            data: payload.widgetUuid,
            cardID: payload.itemID
          })
        )

        dispatch(clearCardSnapshot({ uuid: payload.itemID }))
        return false
      })
      .catch(err => {
        console.error(err)
      })
  }
}

// copy card to buffer
export function copyCardToBuffer(payload) {
  return dispatch => {
    localStorage.setItem('upboard-card-copy-buffer', JSON.stringify(payload))
    dispatch(
      showToastMessage(
        {
          text: messages.COPY_CARD_TOAST,
          size: 'M'
        },
        TOAST_MESSAGE_TYPE,
        7000
      )
    )
  }
}

export function copyCard(payload) {
  return dispatch => {
    dispatch(disableBoardDrag(true))
    dispatch(toggleNewColumnSpinner(payload.columnIndex))
    dispatch(toggleDrawerDrag(true))

    return processCopyCardRequest(payload)
      .then(response => {
        const copiedCards = response.data

        dispatch(addNewCards(copiedCards))

        dispatch(setCardsPositionsETag(response.cardsPositionsETag))
        dispatch(
          subscribeToCards({
            cards: copiedCards,
            tenantId: payload.data.targetTenantId,
            boardId: payload.data.targetBoardId
          })
        )

        if (payload.isDuplicate) {
          dispatch(toggleCardTitleEditing(copiedCards[0].uuid))
        }

        if (!payload.fromDrawer) {
          dispatch(
            showToastMessage({
              text: messages.CARD_SUCCESSFULLY_PASTE
            })
          )
        }

        return dispatch(
          getWidgetsRequest({
            tenantId: payload.data.targetTenantId,
            boardId: payload.data.targetBoardId,
            queries: copiedCards
              .filter(card => !card.isCol)
              .map(card => cardWidgetsQuery({ card, showOnBoardView: true }))
          })
        )
          .then(({ data: widgetsMap }) => {
            dispatch(setSectionsLoading(getInitialSectionsLoading(copiedCards)))

            Object.entries(widgetsMap).forEach(([cardUuid, widgets]) => {
              dispatch(
                appendCardWidgets({
                  cardUuid,
                  widgets
                })
              )
            })
          })
          .catch(err => console.error(err))
      })
      .finally(() => {
        dispatch(disableBoardDrag(false))
        dispatch(toggleNewColumnSpinner(-1))
        dispatch(toggleDrawerDrag(false))
      })
      .catch(err => {
        if (err.errorCode !== 401) {
          dispatch(
            togglePopupNotification({
              state: true,
              title: '',
              desc: messages.UNABLE_TO_PASTE_REMOVED_CARD
            })
          )
        }
        if (payload.fromDrawer) {
          dispatch(reloadCards())
        }
      })
  }
}

export function addParticipants(payload) {
  return { type: ADD_CARD_PARTICIPANTS, payload }
}

export function removeParticipants(payload) {
  return { type: REMOVE_CARD_PARTICIPANTS, payload }
}

export function addCardParticipants(payload) {
  return dispatch => {
    dispatch(addParticipants(payload))
    return processAddCardParticipants(payload).catch(err => console.error(err))
  }
}

export function removeCardParticipants(payload) {
  return dispatch => {
    dispatch(removeParticipants(payload))

    const promises = payload.data.map(userId =>
      processRemoveCardParticipants({
        ...payload,
        data: userId
      })
    )

    return Promise.all(promises).catch(err => console.error(err))
  }
}

export function addComment(payload) {
  return { type: ADD_CARD_COMMENT, payload }
}

export const addCommentFromListener =
  ({ key }) =>
  async (dispatch, getState) => {
    const addedComments = CardCommentsGrpcService.addedComments

    if (addedComments.has(key.id)) {
      addedComments.delete(key.id)
      return Promise.resolve()
    }

    const { comments } = getState().board

    // we need to check for temporary comment in case if listener callback was fired before
    // response from create comment request
    const tempComment = comments.reduce((updateComment, comment) => {
      if (comment.isTemp) {
        return comment
      }
      const repliedComment = comment?.replies?.find(reply => reply.isTemp)
      if (repliedComment) return repliedComment

      return updateComment
    }, null)

    if (!tempComment) {
      const comment = await getCardComment(key)

      const membersMap = useTeamStore.getState().getActiveMembersMap()
      const { data } = convertDate({ data: comment })

      dispatch(addComment(mapAuthorDataFromComment(data, membersMap)))
      CardCommentsGrpcService.addedComments.delete(key)
    }

    return Promise.resolve()
  }

export function setComments(payload) {
  return { type: SET_CARD_COMMENTS, payload }
}

export const addCardComment =
  ({ organizationId, tenantID, boardID, cardUUID, data }) =>
  async (dispatch, getState) => {
    const { profile, board } = getState()
    const membersMap = useTeamStore.getState().getActiveMembersMap()
    const { comments: prevComments } = board
    const tempId = generateGUID()
    const date = dayjs()

    let newComment = {
      tenantId: tenantID,
      boardId: boardID,
      cardUuid: cardUUID,
      commentId: tempId,
      authorUserId: profile.user?.id,
      date: date.valueOf(),
      dateDay: date.format('ll'),
      mentionedUsersIds: data.mentionedUsersIds,
      parentCommentId: data.parentCommentId,
      text: data.text,
      createdByWorkflow: false,
      isWorkspaceUser: false,
      isTemp: true
    }

    newComment = mapAuthorDataFromComment(newComment, membersMap)

    dispatch(addComment(newComment))

    const request = CreateRequest.create({
      comments: [
        {
          key: {
            id: '',
            scope: {
              organizationId,
              tenantId: tenantID,
              boardId: boardID,
              cardId: cardUUID
            }
          },
          parentId: data.parentCommentId,
          authorId: profile.user?.id,
          text: data.text,
          mentions: data.mentionedUsersIds
        }
      ]
    })

    try {
      const res = await CardCommentsGrpcService.createComments({ request })

      const key = res.keys[0]?.id
      CardCommentsGrpcService.addedComments.add(key)

      const { comments } = getState().board
      const newComments = replaceTempComment(comments, tempId, key)
      dispatch(setComments(newComments))
    } catch (error) {
      dispatch(setComments(prevComments))

      throw new Error(error)
    }
  }

export function setCommentsListOffset(payload) {
  return { type: SET_CARD_COMMENTS_LIST_OFFSET, payload }
}

export function setAllCommentsLoaded(payload) {
  return { type: SET_CARD_COMMENTS_LOADED, payload }
}

export const setCardComments =
  ({ organizationId, tenantID, boardID, cardUUID }) =>
  async (dispatch, getState) => {
    const { commentsListOffset, comments, areAllCommentsLoaded } = getState().board
    const membersMap = useTeamStore.getState().getActiveMembersMap()

    if (areAllCommentsLoaded) {
      dispatch(setComments(comments))
      return Promise.resolve()
    }

    const request = QueryRequest.create({
      scope: {
        organizationId,
        tenantId: tenantID,
        boardId: boardID,
        cardId: cardUUID
      },
      filter: {
        parentId: ''
      },
      page: {
        limit: COMMENTS_LIMIT,
        startCursor: commentsListOffset
      }
    })

    const res = await CardCommentsGrpcService.fetchCommentList({ request })

    let commentList = res.comments.map(comment => CardCommentsGrpcService.parseComment(comment))
    const endCursor = res.page?.endCursor

    if (endCursor.length) {
      dispatch(setCommentsListOffset(endCursor))
    } else {
      dispatch(setAllCommentsLoaded(true))
    }

    commentList = convertDate({ data: commentList }).data
    commentList = mapAuthorDataFromCommentList(commentList, membersMap, tenantID)
    commentList = commentsListOffset
      ? [...commentList.reverse(), ...comments]
      : commentList.reverse()

    dispatch(setComments(commentList))
    return Promise.resolve()
  }

export function deleteComment(payload) {
  return { type: DELETE_CARD_COMMENT, payload }
}

export const deleteCardCommentFromListener =
  ({ key }) =>
  (dispatch, getState) => {
    const { comments } = getState().board

    if (CardCommentsGrpcService.deletedComments.has(key.id)) {
      CardCommentsGrpcService.deletedComments.delete(key.id)
    } else {
      const commentToDelete = comments.reduce((result, comment) => {
        if (result) return result

        if (comment.commentId === key.id) {
          result = comment
        } else {
          const repliedComment = comment.replies?.find(reply => reply.commentId === key.id)

          if (repliedComment) {
            result = repliedComment
          }
        }

        return result
      }, null)

      if (commentToDelete) {
        dispatch(
          deleteComment({
            cardUUID: key.scope?.cardId,
            commentId: key.id,
            parentCommentId: commentToDelete.parentCommentId
          })
        )
      }
    }
  }

export const deleteCardComment =
  ({ organizationId, tenantID, boardID, cardUUID, commentId, parentCommentId }) =>
  async (dispatch, getState) => {
    const { comments } = getState().board

    CardCommentsGrpcService.deletedComments.add(commentId)

    const request = DeleteRequest.create({
      keys: [
        {
          id: commentId,
          scope: {
            organizationId,
            tenantId: tenantID,
            boardId: boardID,
            cardId: cardUUID
          }
        }
      ]
    })

    dispatch(deleteComment({ cardUUID, commentId, parentCommentId }))

    try {
      await CardCommentsGrpcService.deleteComments({ request })
    } catch (e) {
      CardCommentsGrpcService.deletedComments.delete(commentId)
      dispatch(setComments(comments))
      throw new Error(e)
    }
  }

export function editComment(payload) {
  return { type: EDIT_CARD_COMMENT, payload }
}

export const updateCommentFromListener =
  ({ key }) =>
  async dispatch => {
    if (CardCommentsGrpcService.updatedComments.has(key.id)) {
      CardCommentsGrpcService.updatedComments.delete(key.id)
      return Promise.resolve()
    }

    const comment = await getCardComment(key)

    const membersMap = useTeamStore.getState().getActiveMembersMap()
    const { data } = convertDate({ data: comment })

    dispatch(editComment(mapAuthorDataFromComment(data, membersMap)))

    return Promise.resolve()
  }

export const editCardComment =
  ({ organizationId, tenantID, boardID, cardUUID, data, editedCommentId }) =>
  async (dispatch, getState) => {
    const { comments } = getState().board
    const membersMap = useTeamStore.getState().getActiveMembersMap()

    const commentToUpdate = comments.reduce((updateComment, comment) => {
      if (data.parentCommentId) {
        const parentComment = comments.find(com => com.commentId === data.parentCommentId)
        return parentComment?.replies?.find(com => com.commentId === editedCommentId)
      }

      if (comment.commentId === editedCommentId) return comment

      return updateComment
    }, null)

    if (commentToUpdate) {
      dispatch(editComment(mapAuthorDataFromComment({ ...commentToUpdate, ...data }, membersMap)))

      const { authorUserId } = commentToUpdate
      CardCommentsGrpcService.updatedComments.add(editedCommentId)

      const request = UpdateRequest.create({
        comments: [
          {
            key: {
              id: editedCommentId,
              scope: {
                organizationId,
                tenantId: tenantID,
                boardId: boardID,
                cardId: cardUUID
              }
            },
            parentId: data.parentCommentId,
            authorId: authorUserId,
            text: data.text,
            mentions: data.mentionedUsersIds
          }
        ]
      })

      try {
        await CardCommentsGrpcService.updateComments({ request })
      } catch (e) {
        CardCommentsGrpcService.updatedComments.delete(editedCommentId)
        dispatch(setComments(comments))
        throw new Error(e)
      }
    }
  }

export function cleanUpCardComments() {
  return { type: CLEAN_UP_CARD_COMMENTS }
}

export function toggleMSAuthRequest(isMsMessageVisible) {
  return (dispatch, getState) => {
    const toastMessages = getState().board.toastMessages
    const authMessage = toastMessages.filter(message => message.type === MS_AUTH_MESSAGE_TYPE)[0]
    if (isMsMessageVisible && !authMessage) {
      dispatch(showToastMessage({ size: 'L' }, MS_AUTH_MESSAGE_TYPE, Infinity))
    } else if (!isMsMessageVisible && authMessage) {
      dispatch(hideToastMessage(authMessage.id))
    }
  }
}

const clearJobInterval = (jobs, jobId) => {
  const successJob = jobs.filter(item => item.id === jobId)[0]
  if (successJob) {
    clearInterval(successJob.intervalId)
  }
}

export function requestEJobStatus(payload) {
  return (dispatch, getState) =>
    getJob(payload)
      .then(response => {
        const job = response.data

        if (job.status === EJobStatuses.DONE) {
          clearJobInterval(getState().socket.jobs, job.id)
          dispatch(removeJob(job))
        } else if (job.status === EJobStatuses.ERROR) {
          clearJobInterval(getState().socket.jobs, job.id)
          dispatch(removeJob(job))

          return processCopyBoardRequest({
            tenantId: payload.tenantId,
            boardId: payload.boardId,
            boardName: payload.data.boardName,
            targetTenantId: payload.data.dstTenantId
          }).then(
            (
              copyResponse // eslint-disable-next-line no-use-before-define
            ) => dispatch(poleInitialBoardsCopying(copyResponse.data))
          )
        } else {
          dispatch({
            type: JOB_COMPLETED_SOCKET,
            payload: job
          })
        }

        return response.data
      })
      .catch(() => {
        clearJobInterval(getState().socket.jobs, payload.id)
        dispatch(removeJob(payload))
      })
}

export function goToHomePageAfterSignUp(isEntitlementFlow) {
  return dispatch => {
    navigateToHomePage()
    if (!isEntitlementFlow) {
      dispatch(toggleIsOnBoardSettings(true))
    }
  }
}

export function navigateAfterSignUp() {
  return dispatch => {
    const search = new URLSearchParams(history.location.search)
    const isEntitlementFlow = !!search.get('entitlementId')

    if (!isEntitlementFlow && IS_MOBILE_DEVICE) {
      navigateToBoard({ isRecent: true })
    } else {
      dispatch(goToHomePageAfterSignUp(isEntitlementFlow))
    }
  }
}

export function poleInitialBoardsCopying(payload) {
  return dispatch => {
    const intervalId = setInterval(() => {
      dispatch(requestEJobStatus(payload)).then(response => {
        if (response && response.status === EJobStatuses.DONE) {
          dispatch(navigateAfterSignUp())
        }
      })
    }, 5 * 1000)

    dispatch({ type: ADD_NEW_JOB, payload: { ...payload, intervalId } })
    dispatch(requestEJobStatus(payload))
  }
}

export function poleJitBoardsCopying(intervalId) {
  return dispatch => {
    getUserJobs({}).then(response => {
      const jobs = response.data
      const jobsCompleted = !jobs.length || jobs.some(job => job.status === EJobStatuses.DONE)
      if (jobsCompleted) {
        clearInterval(intervalId)
        dispatch(navigateAfterSignUp())
      }
    })
  }
}

export function boardSettingsRequestStart() {
  return { type: REQUEST_BOARD_SETTINGS }
}

export function boardSettingsReceive(payload) {
  return { type: RECEIVE_BOARD_SETTINGS, payload }
}

export function boardSettingsReceiveErrorMessage(payload) {
  return { type: RECEIVE_BOARD_SETTINGS_ERROR, payload }
}

export function getBoardSettings(payload) {
  return dispatch => {
    // Show loading widgets state in case if we switched to a card on another board
    dispatch(toggleBoardWidgetsLoading(true))
    dispatch(boardSettingsRequestStart())
    return processBoardSettingsRequest(payload)
      .then(response => dispatch(boardSettingsReceive(response.data)))
      .catch(err => dispatch(boardSettingsReceiveErrorMessage(err)))
      .finally(() => dispatch(toggleBoardWidgetsLoading(false)))
  }
}

export function updateBoardSettings(payload) {
  return dispatch =>
    processUpdateBoardSettings(payload).then(response =>
      dispatch(boardSettingsReceive(response.data))
    )
}

export function switchPublicBoard(payload) {
  return dispatch =>
    processPublicBoardRequest(payload)
      .then(response => dispatch(boardSettingsReceive(response.data)))
      .catch(err => console.error(err))
}

export function switchAutoRefreshThirdPartyBoard(payload) {
  return dispatch =>
    updateBoardAutoRefreshThirdParty(payload)
      .then(response => dispatch(boardSettingsReceive(response.data)))
      .catch(err => console.error(err))
}

export function switchConditionalLayout(payload) {
  return dispatch =>
    updateBoardConditionalLayout(payload)
      .then(response => dispatch(boardSettingsReceive(response.data)))
      .catch(err => console.error(err))
}

export function toggleBoardsExportModal(payload) {
  return { type: TOGGLE_BOARDS_EXPORT_MODAL, payload }
}

export function exportBundle(payload) {
  return dispatch => {
    dispatch(showModalWindowSpinner())

    const promise =
      payload.type === 'app' ? exportAppBundle(payload) : processExportBoardsBundle(payload)

    return promise
      .then(response => {
        dispatch(addNewJob(response.data))
        dispatch(toggleExportIndicator(true))
      })
      .finally(() => dispatch(hideModalWindowSpinner()))
      .catch(err => console.error(err))
  }
}

export function retryExportBoardsBundle(payload) {
  return dispatch => {
    const promise = payload.data.appId
      ? exportAppBundle({
          tenantId: payload.tenantId,
          appId: payload.data.appId,
          data: payload.data
        })
      : processExportBoardsBundle({
          tenantId: payload.tenantId,
          data: payload.data
        })

    return promise
      .then(response => {
        dispatch(removeJob(payload))
        dispatch(addNewJob(response.data))
      })
      .catch(err => console.error(err))
  }
}

function handlePrepareAppImport({ socket, appStoreID, jobId, tenantId, fromAppImport }) {
  return dispatch => {
    const handleAppSchemaImported = ({ appId, tenantId: jobTenantId, jobId: appJobId }) => {
      navigateToAppImportView({
        tenantId: jobTenantId,
        appID: appId,
        appJobId,
        appStoreID,
        fromAppImport
      })
      socket.off(APP_SCHEMA_IMPORTED, handleAppSchemaImported)
    }
    dispatch(
      updateNewImportedApps({
        jobId,
        tenantId,
        status: EJobStatuses.PENDING
      })
    )
    socket.on(APP_SCHEMA_IMPORTED, handleAppSchemaImported)
  }
}

export function importBundle(request, type) {
  return payload => dispatch =>
    request(payload)
      .then(response => {
        const jobId = response.data.id

        dispatch(addNewJob(response.data))

        const socket = getSocket()

        if (type === IMPORT_APP_INDICATOR_TYPE) {
          dispatch(
            handlePrepareAppImport({
              socket,
              appStoreID: payload.appId,
              jobId,
              tenantId: payload.tenantId,
              fromAppImport: payload.fromAppImport
            })
          )
        } else {
          dispatch(toggleImportIndicator(true, type))
        }

        const handleJobComplete = _payload => {
          if (_payload.jobId === jobId) {
            dispatch(fetchWorkspaceSubscriptions())
            socket.off(JOB_COMPLETED, handleJobComplete)
          }
        }

        // Subscribe to job and re-fetch deployed entitlements once import is finished.
        socket.on(JOB_COMPLETED, handleJobComplete)

        return { jobId: response.data.id }
      })
      .catch(err => {
        if (isTextErrorMessage(err.message)) {
          dispatch(
            showToastMessage({
              text: err.message,
              size: 'M'
            })
          )
        }

        return Promise.reject(err)
      })
}

export const importBoardsBundle =
  ({ tenantId, file }) =>
  async dispatch => {
    const data = await FileUploaderGrpcService.convertFileToUint8Array(file)

    const request = UploadBundleRequest.create({
      key: {
        scope: { tenantId }
      },
      info: {
        name: file.name,
        mimeType: file.type
      },
      folder: FileFolder.BUNDLE_FOLDER,
      data
    })

    try {
      const res = await FileUploaderGrpcService.uploadBundle({ request })
      const { name: bundleName } = FileUploaderGrpcService.parseFile(res)

      return dispatch(
        importBundle(
          processImportBoardsBundle,
          IMPORT_BUNDLE_INDICATOR_TYPE
        )({
          tenantId,
          bundleName
        })
      )
    } catch (err) {
      return Promise.reject(err)
    }
  }

export function onChangeAvatarFile(event, sucessCallback) {
  return dispatch => {
    event.preventDefault()
    let files
    if (event.dataTransfer) {
      files = event.dataTransfer.files
    } else if (event.target) {
      files = event.target.files
    }
    if (!files?.length) {
      return
    }

    const avatar = files[0]
    if (!avatar) {
      return
    }

    const { type, size } = avatar

    const types = ['image/png', 'image/jpeg', 'image/gif']
    const correctType = types.indexOf(type) !== -1

    if (correctType && size <= 2000000) {
      const reader = new FileReader()
      reader.readAsDataURL(files[0])
      reader.onload = () => {
        sucessCallback(reader.result)
      }
    } else if (!correctType) {
      dispatch(
        showToastMessage({
          text: messages.INVALID_AVATAR_TYPE(avatar.name),
          size: 'M'
        })
      )
    } else if (size > 2000000) {
      dispatch(
        showToastMessage({
          text: messages.INVALID_AVATAR_SIZE(avatar.name),
          size: 'M'
        })
      )
    }
  }
}

// tenant creation
export function toggleTenantCreationModal(payload) {
  return { type: TOGGLE_TENANT_CREATION_MODAL, payload }
}

export function goToEmptyTenant(payload) {
  return dispatch =>
    dispatch(getTeamSettings(payload)).then(() => {
      dispatch(clearCurrentBoard())
      navigateToEmptyTenant(payload.tenantId)
    })
}

export function addNewTenant(payload) {
  return (dispatch, getState) => {
    dispatch(showModalWindowSpinner())
    return processAddTenantRequest(payload)
      .then(response => {
        const {
          boardMenu: { tenants }
        } = getState().profile

        ga('send', {
          hitType: 'event',
          eventCategory: 'Team',
          eventAction: 'Create',
          eventValue: tenants.length + 1
        })

        dispatch(goToEmptyTenant({ tenantId: response.data.id }))
      })
      .finally(() => {
        dispatch(toggleTenantCreationModal(false))
        dispatch(hideModalWindowSpinner())
      })
      .then(() => dispatch(boardMenuRequest()))
      .catch(err => console.error(err))
  }
}

export function toggleHiddenColumnsVisibility({ userId, boardId, isColumnsHidden }) {
  return dispatch => {
    dispatch(receiveUpdateBoard({ isColumnsHidden }))

    const userPreferences = getUserPreferences()
    const currentUserBoards = userPreferences[userId]?.boardsWithUnhiddenColumns || []

    if (isColumnsHidden) {
      setUserPreferences({
        preferences: { boardsWithUnhiddenColumns: currentUserBoards.filter(id => id !== boardId) },
        userId
      })
    } else {
      currentUserBoards.push(boardId)
      setUserPreferences({
        preferences: { boardsWithUnhiddenColumns: currentUserBoards },
        userId
      })
    }
  }
}

export function restrictAddedWidgets(newWidgets, widgets) {
  return dispatch => {
    const existingCount = widgets.length
    const newCount = newWidgets.length
    const { restricted, limitReached, allowedCount } = restrictWidgetsCount(newCount, existingCount)
    if (limitReached) {
      dispatch(
        showToastMessage({
          text: messages.MAX_WIDGETS_COUNT_WARNING,
          size: 'M'
        })
      )
    }
    return { restricted, allowedCount }
  }
}

export function restrictAddedWidgetsOnDetailedView(newWidgets, cardUuid) {
  return (dispatch, getState) => {
    const { widgets } = cardWidgetsSelector(cardUuid)(getState())
    return dispatch(restrictAddedWidgets(newWidgets, widgets))
  }
}

export const setCardsCommentsCount = payload => ({
  type: SET_BOARD_CARDS_COMMENTS_COUNT,
  payload
})
