import React from 'react'
import { connect } from 'react-redux'

import JobsIndicator from '../../components/jobsIndicator/JobsIndicator'
import { WorkflowExecutionIndicatorItem } from '../../components/jobsIndicator/workflowExecutionIndicatorItem'
import jobsIndicatorHOC from '../hoc/jobsIndicatorHOC'

import * as actions from '../../actions/index'
import messages from '../../constants/messages'
import { EJobStatuses } from '../../features/jobs/jobs.types'
import { WORKFLOW_EXECUTION_JOB } from '../../features/jobs/jobs.constants'

const JOB_TYPES = [WORKFLOW_EXECUTION_JOB]

const getTitleText = jobs => {
  const activeJobs = jobs.filter(item => item.status === EJobStatuses.PENDING)

  if (activeJobs.length) {
    return messages.EXECUTING_N_WORKFLOWS(activeJobs.length)
  }

  return jobs.length === 1 ? messages.WORKFLOW_IS_EXECUTED : messages.WORKFLOW_EXECUTED
}

const WorkflowExecutionIndicatorContainer = props => {
  const {
    isIndicatorHidden,
    jobs,
    visible,
    invisible,
    width,
    transform,
    onMessageHide,
    onToggleHideJobsIndicator,
    onCloseJobsIndicator
  } = props

  return (
    <JobsIndicator
      title={getTitleText(jobs)}
      closeTooltipText={messages.CLOSE_WORKFLOW_EXECUTION_INDICATOR}
      visible={visible}
      invisible={invisible}
      width={width}
      transform={transform}
      onMessageHide={onMessageHide}
      hidden={isIndicatorHidden}
      onCloseJobsIndicator={onCloseJobsIndicator}
      onToggleHideJobsIndicator={onToggleHideJobsIndicator}
    >
      {jobs.map(job => (
        <WorkflowExecutionIndicatorItem key={job.id} job={job} />
      ))}
    </JobsIndicator>
  )
}

function mapStateToProps(state) {
  return {
    jobs: state.socket.jobs.filter(job => {
      const isWorkflowJob = JOB_TYPES.includes(job.type)
      const shouldBeShown = job.data && job.data.isExecutionShown

      return isWorkflowJob && shouldBeShown
    })
  }
}

const mapDispatchToProps = {
  toggleJobsIndicator: actions.toggleWorkflowExecutionIndicator
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(jobsIndicatorHOC(WorkflowExecutionIndicatorContainer))
