import type { MouseEventHandler, ReactNode } from 'react'
import classNames from 'classnames'
import messages from 'constants/messages'

type TProps = {
  className?: string
  children: ReactNode
  height?: string | number
  width?: string | number
  transform?: string
  discardText?: ReactNode
  continueText?: ReactNode
  isVisible?: boolean
  isAvailableOnMobile?: boolean
  onDiscard?: MouseEventHandler<HTMLDivElement>
  onContinue: MouseEventHandler<HTMLDivElement>
}

const ActionsToastMessage = ({
  className,
  children,
  height,
  width,
  transform,
  discardText = messages.DISCARD,
  continueText = messages.CONTINUE,
  isVisible,
  isAvailableOnMobile,
  onDiscard,
  onContinue
}: TProps) => (
  <div
    className={classNames({
      className,
      visible: isVisible,
      'unsaved-draft-message': true,
      'hidden-on-touch': !isAvailableOnMobile,
      'hidden-on-md': !isAvailableOnMobile,
      'hidden-on-sm': !isAvailableOnMobile,
      'toast-message': true
    })}
    style={{ height, width, transform }}
  >
    <div className="wording">{children}</div>
    <div className="actions">
      {onDiscard && (
        <div className="discard-button" onClick={onDiscard}>
          {discardText}
        </div>
      )}
      <div className="continue-button" onClick={onContinue}>
        {continueText}
      </div>
    </div>
  </div>
)

export default ActionsToastMessage
