import React from 'react'
import { connect } from 'react-redux'

import { DownloadAppTemplateIndicatorItem } from 'components/jobsIndicator/downloadAppTemplateIndicatorItem'
import JobsIndicator from '../../components/jobsIndicator/JobsIndicator'
import jobsIndicatorHOC from '../hoc/jobsIndicatorHOC'

import * as actions from '../../actions/index'
import messages from '../../constants/messages'

const DownloadAppTemplateIndicatorContainer = props => {
  const {
    isIndicatorHidden,
    visible,
    invisible,
    width,
    transform,
    onMessageHide,
    onToggleHideJobsIndicator,
    onCloseJobsIndicator,
    appTemplateDownloadProgress
  } = props

  return (
    <JobsIndicator
      title={messages.DOWNLOADING_TEMPLATE}
      closeTooltipText={messages.CLOSE_DOWNLOADING_TEMPLATE_WINDOW}
      visible={visible}
      invisible={invisible}
      width={width}
      transform={transform}
      onMessageHide={onMessageHide}
      hidden={isIndicatorHidden}
      onCloseJobsIndicator={onCloseJobsIndicator}
      onToggleHideJobsIndicator={onToggleHideJobsIndicator}
    >
      {appTemplateDownloadProgress.map(download => (
        <DownloadAppTemplateIndicatorItem key={download.id} download={download} />
      ))}
    </JobsIndicator>
  )
}

function mapStateToProps(state) {
  return {
    appTemplateDownloadProgress: state.appBuilder.appTemplateDownloadProgress
  }
}

const mapDispatchToProps = {
  toggleJobsIndicator: actions.toggleAppTemplateDownloadIndicator
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(jobsIndicatorHOC(DownloadAppTemplateIndicatorContainer))
