import { createReducer } from 'redux-create-reducer'
import * as ActionTypes from '../constants/actionTypes'

export const teamAdministration = createReducer(
  {},
  {
    [ActionTypes.TOGGLE_DEACTIVATING_TENANT_MEMBER_MODAL](state, action) {
      return {
        ...state,
        deactivateTenantMemberData: {
          state: action.payload.state,
          member: action.payload.member || {},
          tenant: action.payload.tenant || {},
          onConfirm: action.payload.onConfirm || null
        }
      }
    },

    [ActionTypes.TOGGLE_INVITATION_MODAL](state, action) {
      return {
        ...state,
        isInvitationModalShown: action.payload.isShown,
        invitationModalState: action.payload.state || {}
      }
    },

    [ActionTypes.TOGGLE_INVITE_MEMBERS_LOADER](state, action) {
      return {
        ...state,
        isInvitationRequested: action.payload
      }
    },

    [ActionTypes.CHANGE_PASSWORD_REQUEST](state) {
      return {
        ...state,
        isChangePasswordRequested: true,
        changePasswordErrorMessage: ''
      }
    },

    [ActionTypes.CHANGE_PASSWORD_RECEIVE](state) {
      return {
        ...state,
        isChangePasswordRequested: false,
        changePasswordErrorMessage: ''
      }
    },

    [ActionTypes.CHANGE_PASSWORD_RECEIVE_ERROR](state, action) {
      return {
        ...state,
        isChangePasswordRequested: false,
        changePasswordErrorMessage: action.payload.message
      }
    },

    [ActionTypes.TOGGLE_TERMINATE_USER_MODAL](state, action) {
      return {
        ...state,
        isTerminateUserModalShown: action.payload,
        terminateUserErrorMessage: ''
      }
    },

    [ActionTypes.TERMINATE_USER_REQUEST](state) {
      return {
        ...state,
        terminateUserErrorMessage: ''
      }
    },

    [ActionTypes.TERMINATE_USER_RECEIVE](state) {
      return {
        ...state,
        terminateUserErrorMessage: ''
      }
    },

    [ActionTypes.TERMINATE_USER_RECEIVE_ERROR](state, action) {
      return {
        ...state,
        terminateUserErrorMessage: action.payload.message
      }
    },

    [ActionTypes.SET_LEAVING_TEAM_DATA](state, action) {
      return {
        ...state,
        leavingTeamData: action.payload
      }
    },

    [ActionTypes.CLEAN_UP_LEAVING_TEAM_DATA](state) {
      return {
        ...state,
        leavingTeamData: {}
      }
    },

    [ActionTypes.TOGGLE_DELETE_TEAM_MEMBER_MODAL](state, action) {
      return {
        ...state,
        isDeleteTeamMemberModalShown: action.payload,
        deleteTeamMemberErrorMessage: ''
      }
    },

    [ActionTypes.TOGGLE_DELETE_TEAM_MEMBER_REJECTION_MODAL](state, action) {
      return {
        ...state,
        isDeleteTeamMemberRejectionModalShown: action.payload
      }
    },

    [ActionTypes.DELETE_TEAM_MEMBER_REQUEST](state) {
      return {
        ...state,
        deleteTeamMemberErrorMessage: ''
      }
    },

    [ActionTypes.DELETE_TEAM_MEMBER_RECEIVE](state) {
      return {
        ...state,
        deleteTeamMemberErrorMessage: ''
      }
    },

    [ActionTypes.DELETE_TEAM_MEMBER_RECEIVE_ERROR](state, action) {
      return {
        ...state,
        deleteTeamMemberErrorMessage: action.payload.message
      }
    },

    [ActionTypes.TOGGLE_DELETE_TEAM_MODAL](state, action) {
      return {
        ...state,
        isDeleteTeamModalShown: action.payload,
        deleteTeamErrorMessage: ''
      }
    },

    [ActionTypes.DELETE_TEAM_REQUEST](state) {
      return {
        ...state,
        deleteTeamErrorMessage: ''
      }
    },

    [ActionTypes.DELETE_TEAM_RECEIVE](state) {
      return {
        ...state,
        deleteTeamErrorMessage: ''
      }
    },

    [ActionTypes.DELETE_TEAM_RECEIVE_ERROR](state, action) {
      return {
        ...state,
        deleteTeamErrorMessage: action.payload.message
      }
    },

    [ActionTypes.RECEIVE_CURRENT_TENANT](state, action) {
      return {
        ...state,
        currentTeam: {
          ...action.payload.tenant,
          permissions: action.payload.permissions.permissions || {},
          tenantRole: action.payload.permissions.role || 'PublicBoardViewer'
        }
      }
    },

    [ActionTypes.UPDATE_CURRENT_TENANT](state, action) {
      return {
        ...state,
        currentTeam: {
          ...state.currentTeam,
          ...action.payload
        }
      }
    },

    [ActionTypes.TOGGLE_DELETE_ORGANIZATION_MODAL](state, action) {
      return {
        ...state,
        isDeleteOrganizationModalShown: action.payload,
        deleteOrganizationErrorMessage: ''
      }
    },

    [ActionTypes.DELETE_ORGANIZATION_REQUEST](state) {
      return {
        ...state,
        deleteOrganizationErrorMessage: ''
      }
    },

    [ActionTypes.DELETE_ORGANIZATION_RECEIVE](state) {
      return {
        ...state,
        deleteOrganizationErrorMessage: ''
      }
    },

    [ActionTypes.DELETE_ORGANIZATION_RECEIVE_ERROR](state, action) {
      return {
        ...state,
        deleteOrganizationErrorMessage: action.payload
      }
    }
  }
)

export default teamAdministration
