export const updateOutputsHistory = ({ outputsHistory, outputs, blockId }) =>
  outputs.reduce(
    (acc, output) => {
      acc[output.id] = [...(acc[output.id] || []), blockId]

      return acc
    },
    { ...outputsHistory }
  )

export const removeOutputsHistory = ({ outputsHistory, outputs }) => {
  const newHistory = { ...outputsHistory }
  outputs.forEach(output => {
    delete newHistory[output.id]
  })

  return newHistory
}

export const removeBlockFromHistory = ({ outputsHistory, outputId, blockId }) => {
  const history = { ...outputsHistory }

  history[outputId] = history[outputId].filter(id => id !== blockId)

  return history
}

export const addBlockToHistory = ({ workflowBlocksMap, outputsHistory, outputId, blockId }) => {
  const history = { ...outputsHistory }

  history[outputId] = [...history[outputId], blockId]
    // need to sort history base on blocks ordering
    .sort((leftId, rightId) => workflowBlocksMap[leftId].index - workflowBlocksMap[rightId].index)

  return history
}

export const updateOutputsHistoryBlock = ({ outputsMap, outputsHistory, blockId }) => {
  const existingIds = Object.values(outputsMap).flatMap(outputArray =>
    outputArray.map(output => output.id)
  )

  return existingIds.reduce((acc, id) => {
    acc[id] = outputsHistory.hasOwnProperty(id) ? outputsHistory[id] : [blockId]

    return acc
  }, {})
}
