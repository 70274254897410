import gql from 'graphql-tag'

const cardLockOwnerFragmentName = 'CardLockOwner'

const CardLockOwnerFragment = {
  name: cardLockOwnerFragmentName,
  fragment: gql`
        fragment ${cardLockOwnerFragmentName} on CardLockOwner {
            id
            username
        }
    `
}

export default CardLockOwnerFragment
