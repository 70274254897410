import messages from '../constants/messages'

export function validateEmail(value) {
  let emailError = ''
  const emailPattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (!emailPattern.test(value)) {
    emailError = messages.incorrectEmail
  }
  if (!value) {
    emailError = messages.emptyField
  }
  return emailError
}

export function checkPasswordStrength(password) {
  const numberPattern = /\d/
  const letterPattern = /\w/
  const minimumPasswordLength = 8
  const passwordLength = password.length
  let hasUppercaseLetters = false
  let hasLowercaseLetters = false

  for (let i = 0; i < passwordLength; i += 1) {
    const letter = password[i]
    if (letterPattern.test(letter) && !numberPattern.test(letter)) {
      if (isNaN(parseInt(letter, 10)) && letter.toUpperCase() === letter) {
        hasUppercaseLetters = true
      }
      if (isNaN(parseInt(letter, 10)) && letter.toLowerCase() === letter) {
        hasLowercaseLetters = true
      }
    }
  }

  return {
    length: passwordLength >= minimumPasswordLength ? 1 : 0,
    letters: hasUppercaseLetters && hasLowercaseLetters ? 1 : 0,
    numbers: numberPattern.test(password) ? 1 : 0
  }
}

export function validatePassword(value) {
  const passwordErrors = checkPasswordStrength(value)
  const spacesPattern = /\s/
  const notAllowedSymbolsPattern = /[^\w!"#$%&'()*+,\-.:;<=>?@[\\\]^_`{|}~]/
  let passwordError = ''

  if (!passwordErrors.numbers) {
    passwordError = messages.containNumber
  }
  if (!passwordErrors.letters) {
    passwordError = messages.containLetter
  }
  if (!passwordErrors.length) {
    passwordError = messages.shortPassword
  }
  if (!value) {
    passwordError = messages.emptyField
  }
  if (notAllowedSymbolsPattern.test(value)) {
    passwordError = messages.cyrillicPattern
  }
  if (spacesPattern.test(value)) {
    passwordError = messages.spacesPattern
  }
  return passwordError
}

export function validateConfirmPassword(value, password) {
  let confirmPasswordError = value !== password ? messages.passwordNotMatch : ''
  if (!value) {
    confirmPasswordError = messages.emptyField
  }
  return confirmPasswordError
}

/**
 * @param {string} value
 * @returns {string}
 */
export function validateForNotEmpty(value) {
  let nameError = ''
  if (!value.trim()) {
    nameError = messages.emptyField
  }
  return nameError
}

export function validateArrayNotEmpty(value) {
  let nameError = ''
  if (!value.length) {
    nameError = messages.emptyField
  }
  return nameError
}

export function validateJiraUrl(value) {
  let urlError = ''
  const urlPattern = /^https:\/\/((([^<>()[\]\\,;:\s@"]+)*)|(".+"))$/
  if (!urlPattern.test(value)) {
    urlError = messages.incorrectJiraUrl
  }
  if (!value) {
    urlError = messages.emptyField
  }
  return urlError
}

export function validateUrl(value) {
  let urlError = ''
  const urlPattern = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/
  if (!urlPattern.test(value)) {
    urlError = messages.incorrectLink
  }
  return validateForNotEmpty(value) || urlError
}

export function validateNumber(value) {
  let valueError = ''
  const numberPattern = /^(?:\d*[.,])?\d+$/
  if (!numberPattern.test(value)) {
    valueError = messages.validateNumber
  }
  if (!value) {
    valueError = messages.emptyField
  }
  return valueError
}

export function validateOrganizationName(value) {
  const minLength = 3
  let valueError = ''
  const notAllowedSymbolsPattern = /[^a-zA-Z0-9 ._-]/
  if (notAllowedSymbolsPattern.test(value)) {
    valueError = messages.validateOrganizationName
  }
  if (value.length < minLength) {
    valueError = messages.organizationNameMinLength
  }
  if (!value) {
    valueError = messages.emptyField
  }
  return valueError
}

export const validateLongTextInputs = ({ meta, fieldKey, input }) => {
  let error = {}

  if (meta[fieldKey] && meta[fieldKey].length) {
    meta[fieldKey].forEach(string => {
      if (typeof string === 'object' && !input[string.fieldName]) {
        error = { [string.fieldName]: string.fieldName }
      }
    })
  }

  return error
}

export function validateVideoLink(value) {
  let error = ''
  const youtubeEmbedPattern =
    /^(https:\/\/)((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/gi
  const vimeoEmbedPattern = /^(https:\/\/)(www\.)?vimeo.com\/(\d+)(?:|\/\?)/gi

  if (!youtubeEmbedPattern.test(value) && !vimeoEmbedPattern.test(value)) {
    error = messages.INCORRECT_VIDEO_LINK
  }

  if (!value) {
    error = messages.emptyField
  }
  return error
}

const HTML_REGEXP = /<[^>]*>/g
export function validateFileTemplateInfo(fileTemplateInfo) {
  if (fileTemplateInfo?.file) {
    const description = fileTemplateInfo.description?.replace(HTML_REGEXP, '')
    const fileTemplateInfoErrors = {
      description: validateForNotEmpty(description || '')
    }
    return Object.values(fileTemplateInfoErrors).some(error => !!error) && fileTemplateInfoErrors
  }
  return null
}

export function validateTrialPeriod(value) {
  let valueError = ''
  const trialPeriod = parseInt(value, 10)
  if (trialPeriod > 99) {
    valueError = messages.validateTrialPeriod
  }
  return valueError
}

export function validatePurchaseInfo(appMeta) {
  if (appMeta?.isPurchaseEnabled) {
    const purchaseInfoErrors = appMeta?.purchaseInfo.reduce((errors, item, itemIndex) => {
      const itemError = {
        purchaseId: validateForNotEmpty(item.purchaseId),
        price: validateForNotEmpty(item.price ? item.price.toString() : ''),
        trialInfo: validateTrialPeriod(item.trialInfo?.trialPeriod)
      }
      const hasErrors = Object.values(itemError).some(error => error)
      return {
        ...errors,
        [itemIndex]: hasErrors ? itemError : null
      }
    }, {})
    return Object.values(purchaseInfoErrors).some(error => !!error) && purchaseInfoErrors
  }
  return null
}

export function validateTrialInfo(appMeta) {
  if (appMeta?.isPurchaseEnabled) {
    const trialInfo = appMeta.purchaseInfo?.trialInfo
    const isTrialEnabled = trialInfo?.isTrialEnabled
    if (isTrialEnabled) {
      return validateTrialPeriod(trialInfo?.trialPeriod)
    }
  }
  return null
}
