import { type ReactNode, type SVGProps, forwardRef } from 'react'
import { FormControl, FormHelperText, FormLabel } from '@mui/material'
import MUISelect, { type SelectProps } from '@mui/material/Select'
import classNames from 'classnames'
import ArrowIcon from 'assets/images/icons/ic_arrow_bottom_394549.svg?react'
import { MenuItem } from '../menu/menu'
import './select.scss'

const Icon = (props: SVGProps<SVGSVGElement>) => (
  <div className="praxie-select-icon">
    <ArrowIcon {...props} />
  </div>
)

export type TSelectSize = 24 | 28 | 34 | 40 | 60 | 'custom'

type TOption = {
  value: string | number
  label: string | ReactNode
  className?: string
}

export type TSelectProps = Omit<SelectProps, 'size'> & {
  size?: TSelectSize
  label?: ReactNode
  helperText?: string
  error?: boolean
  options?: TOption[]
  children?: ReactNode
  menuItemClassName?: string
}

export const Select = forwardRef<HTMLElement, TSelectProps>(
  (
    {
      className,
      size = 34,
      label,
      value = '',
      helperText = '',
      error = false,
      options,
      children,
      placeholder,
      menuItemClassName,
      MenuProps,
      ...props
    },
    ref
  ) => {
    const shouldDisplayPlaceholder = !!placeholder && !value

    const renderPlaceholder = () => <span className="select-placeholder">{placeholder}</span>

    return (
      <FormControl className={classNames('praxie-select', className)} error={error}>
        {!!label && (
          <FormLabel className="select-label" component="legend">
            {label}
          </FormLabel>
        )}
        <MUISelect
          ref={ref}
          className={`size-${size}`}
          value={value ?? ''}
          MenuProps={{
            ...MenuProps,
            className: classNames('praxie-menu', 'praxie-select-menu', MenuProps?.className)
          }}
          IconComponent={Icon}
          // Material UI doesn't support placeholder for <Select /> directly
          displayEmpty={shouldDisplayPlaceholder}
          renderValue={shouldDisplayPlaceholder ? renderPlaceholder : undefined}
          {...props}
        >
          {options?.map(({ label: _label, value: _value, className: optionClassName }) => (
            <MenuItem
              key={_value}
              value={_value}
              className={classNames(menuItemClassName, optionClassName)}
            >
              {_label}
            </MenuItem>
          )) ?? children}
        </MUISelect>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    )
  }
)
