export const TOGGLE_DRAWER = 'TOGGLE_DRAWER'

export const GET_DRAWER_CARDS_REQUEST = 'GET_DRAWER_CARDS_REQUEST'
export const GET_DRAWER_CARDS_RECEIVE = 'GET_DRAWER_CARDS_RECEIVE'
export const GET_DRAWER_CARDS_RECEIVE_ERROR = 'GET_DRAWER_CARDS_RECEIVE_ERROR'

export const TOGGLE_DRAWER_PAGINATION_END = 'TOGGLE_DRAWER_PAGINATION_END'
export const GET_SEARCH_DRAWER_CARDS_RECEIVE = 'GET_SEARCH_DRAWER_CARDS_RECEIVE'

export const TOGGLE_DRAWER_DRAG = 'TOGGLE_DRAWER_DRAG'

export const TOGGLE_DRAWER_CARD_PREVIEW = 'TOGGLE_DRAWER_CARD_PREVIEW'
