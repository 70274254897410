import { createReducer } from 'redux-create-reducer'
import * as ActionTypes from '../constants/actionTypes'
import messages from '../constants/messages'

export const auth = createReducer(
  {},
  {
    [ActionTypes.REQUEST_LOGIN](state) {
      return { ...state, loginErrorMessage: '' }
    },

    [ActionTypes.RECEIVE_LOGIN](state) {
      return { ...state, loginErrorMessage: '' }
    },

    [ActionTypes.RECEIVE_LOGIN_ERROR](state, action) {
      return { ...state, loginErrorMessage: action.payload.message }
    },

    [ActionTypes.REQUEST_REGISTRATION](state) {
      return { ...state, isNewUserDataSent: true, registrationErrorMessage: '' }
    },

    [ActionTypes.RECEIVE_REGISTRATION](state) {
      return { ...state, isNewUserDataSent: false }
    },

    [ActionTypes.RECEIVE_REGISTRATION_ERROR](state, action) {
      return {
        ...state,
        isNewUserDataSent: false,
        registrationErrorMessage: action.payload.message
      }
    },

    [ActionTypes.REQUEST_RESET_LINK](state) {
      return { ...state, isResetLinkRequested: true }
    },

    [ActionTypes.RECEIVE_RESET_LINK](state) {
      return { ...state, isResetLinkRequested: false }
    },

    [ActionTypes.RECEIVE_RESET_LINK_ERROR](state) {
      return { ...state, isResetLinkRequested: false }
    },

    [ActionTypes.REQUEST_CHANGE_PASSWORD](state) {
      return { ...state, isResetPasswordRequested: true, resetLinkErrorMessage: '' }
    },

    [ActionTypes.RECEIVE_CHANGE_PASSWORD](state) {
      return { ...state, isResetPasswordRequested: false, resetLinkErrorMessage: '' }
    },

    [ActionTypes.RECEIVE_CHANGE_PASSWORD_ERROR](state) {
      return {
        ...state,
        isResetPasswordRequested: false,
        resetLinkErrorMessage: messages.RESET_PASSWORD_ERROR_MESSAGE
      }
    },

    [ActionTypes.TOGGLE_PASSWORD_CHANGED_MODAL](state, action) {
      return { ...state, isPasswordChangedShown: action.payload }
    },

    [ActionTypes.SET_SIGNED_UP_EMAIL](state, action) {
      return { ...state, signedUpEmail: action.payload }
    },

    [ActionTypes.SET_SIGNED_IN_EMAIL](state, action) {
      return { ...state, signedInEmail: action.payload }
    },

    [ActionTypes.SET_KEEP_LOGGED_IN](state, action) {
      return { ...state, keepLoggedIn: action.payload }
    },

    [ActionTypes.RECEIVE_ALL_ORGANIZATIONS](state, action) {
      return { ...state, allOrganizations: action.payload }
    }
  }
)

export default auth
