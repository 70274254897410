import { combineReducers } from 'redux'
import { auth } from './authReducer'
import { filters } from './filtersReducer'
import { profile } from './profileReducer'
import { board } from './boardReducer'
import { spinner } from './spinnerReducer'
import { builder } from './builderReducer'
import { widget } from './widgetReductors'
import { teamAdministration } from './teamAdministrationReducer'
import { notifications } from './notificationsReducer'
import { workspacesAdministration } from './workspacesAdministrationReducer'
import { drawer } from './drawerReducer'
import { socket } from './socketReducer'
import { workflow } from './workflowReducer'
import { clickWorkflow } from './clickWorkflowReducer'
import { workspaceMembers } from './workspaceMembersReducer'
import { detailed } from './detailedViewReducer'
import { ribbon } from './ribbonReducer'
import { app } from './appReducer'
import { appBuilder } from './appBuilderReducer'
import { variables } from './variablesReducer'

const rootReducer = combineReducers({
  app,
  appBuilder,
  auth,
  profile,
  board,
  spinner,
  builder,
  widget,
  teamAdministration,
  notifications,
  drawer,
  socket,
  workspacesAdministration,
  workflow,
  clickWorkflow,
  filters,
  workspaceMembers,
  detailed,
  ribbon,
  variables
})

export default rootReducer
