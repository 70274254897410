import React from 'react'
import classNames from 'classnames'

import LogicalOperator from 'components/filters/conditionsModal/LogicalOperator'
import FilterCondition from '../filterPreview/FilterCondition'

import 'scss/filters/filterConfiguration/condition.scss'

const Condition = props => {
  const {
    isLast,
    rule,
    operator,
    queryObject,
    rulesConfig,
    deleteRule,
    toggleRuleEdit,
    isReadOnly
  } = props

  const handleEditConditionClick = () => {
    if (isReadOnly) return

    toggleRuleEdit(rule.uuid)
  }

  return (
    <div className={classNames('filter-configuration-condition', { 'read-only': isReadOnly })}>
      <div className="condition-rule">
        <div className="condition-rule-content">
          {rule.conditions.map((condition, index) => (
            <FilterCondition
              key={condition.uuid}
              isLast={index === rule.conditions.length - 1}
              rule={condition}
              rulesConfig={rulesConfig}
              operator={rule.conditionsOperator}
              onClick={handleEditConditionClick}
              object={queryObject}
            />
          ))}
        </div>
        {!isReadOnly && (
          <div className="condition-delete" onClick={() => deleteRule(rule.uuid)}>
            <i className="icon icon-close" />
          </div>
        )}
      </div>
      {!isLast && <LogicalOperator operator={operator} />}
    </div>
  )
}

export default Condition
