import { EFieldKeys } from '../../constants/workflowBuilder/blocksFieldsKeys'

export const metaToStaticConnectionMapper = (block, inputName) => {
  // params should start from 0
  if (inputName === EFieldKeys.PARAMS && block.meta[inputName]) {
    const params = {}
    Object.keys(block.meta[inputName]).map(key => (params[key] = block.meta[inputName][key] - 1))
    return params
  }

  return block.meta[inputName]
}

export const staticConnectionToMetaMapper = (inputName, data) => {
  // params should start from 1 on UI
  if (inputName === EFieldKeys.PARAMS) {
    const params = {}
    Object.keys(data).map(key => (params[key] = data[key] + 1))
    return params
  }

  return data
}
