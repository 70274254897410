/* eslint-disable import/no-cycle */
import {
  GenerateSignedLinkRequestSignedLinkType,
  UploadAvatarRequestAvatarType,
  GenerateSignedLinkRequest,
  UploadAvatarRequest,
  UploadTemplateRequest
} from '_proto/uploader/v1/uploader.pb'
import * as actions from 'constants/actionTypes'
import * as appAPI from 'api/appAPI'
import * as boundAppAPI from 'api/bindAppAPI'
import { getBoardsRequest } from 'api/boardAPI'
import {
  APP_BUILDER_TAB,
  APP_DOWNLOAD_TEMPLATE_STATUS
} from 'constants/appBuilder/appBuilderConstants'
import messages from 'constants/messages'
import {
  getNewApp,
  resolveAppBoardsFromMenu,
  resolveBoardCards,
  setBoardCardsLoading
} from 'helpers/appBuilder/appBuilderHelpers'
import { validateApp } from 'helpers/appBuilder/appValidation'
import { sortNavItems } from 'helpers/appBuilder/navItemsPositionHelpers'
import { getNewAppMeta, parseAppPrices } from 'helpers/appStore/appMetaHelpers'
import { exitFromAppBuilder, navigateToRecentBoard } from 'helpers/routesHelpers'
import { getAppStoreConfig } from 'api/appStoreAPI'
import { orgPermissionsSelector } from 'selectors/profileSelectors'
import { EFileUploaderFileTypes, FileUploaderGrpcService } from 'services/fileUploader.service'
import { addTimestampToUrl } from 'helpers/fetchHelpers'
import { boardMenuReceive, boardMenuRequest } from './profileActions'
import { validateAllAppMeta } from './appBuilderPublishActions'
import { hideToastMessage, showToastMessage } from './boardActions'

export const setApp = payload => ({
  type: actions.SET_APP,
  payload
})

export const changeApp = payload => ({
  type: actions.CHANGE_APP,
  payload
})

export const toggleAppBuilderLoader = payload => ({
  type: actions.TOGGLE_APP_BUILDER_LOADER,
  payload
})

export const toggleAppSaving = payload => ({
  type: actions.TOGGLE_APP_SAVING,
  payload
})

export const toggleHasChanges = payload => ({
  type: actions.TOGGLE_HAS_CHANGES,
  payload
})

export const toggleAppBuilderConfirmation = payload => ({
  type: actions.TOGGLE_APP_BUILDER_CONFIRMATION,
  payload
})

export const setAppBoards = payload => ({
  type: actions.SET_APP_BOARDS,
  payload
})

export const setAppCards = payload => ({
  type: actions.SET_APP_CARDS,
  payload
})

export const clearAllAppCards = payload => ({
  type: actions.CLEAR_ALL_APP_CARDS,
  payload
})

export const setAppCardsLoading = payload => ({
  type: actions.TOGGLE_APP_CARDS_LOADING,
  payload
})

export const toggleAppCreationMode = payload => ({
  type: actions.TOGGLE_APP_CREATION_MODE,
  payload
})

export const setAppLogo = payload => ({ type: actions.SET_APP_LOGO, payload })

export const setClearLogo = payload => ({ type: actions.SET_CLEAR_LOGO, payload })

export const setAppErrors = payload => ({ type: actions.SET_APP_ERRORS, payload })

export const clearAppErrors = payload => ({ type: actions.CLEAR_APP_ERRORS, payload })

export const setNavItems = payload => ({ type: actions.SET_NAV_ITEMS, payload })

export const addNavItems = payload => ({ type: actions.ADD_NAV_ITEMS, payload })

export const changeTab = payload => ({ type: actions.CHANGE_APP_BUILDER_TAB, payload })

export const setAppMeta = payload => ({ type: actions.SET_APP_META, payload })

export const setAppMetaPurchaseInfo = payload => ({
  type: actions.SET_APP_META_PURCHASE_INFO,
  payload
})

export const setAppMetaConfig = payload => ({ type: actions.SET_APP_META_CONFIG, payload })

export const setAppTemplateDownloadProgress = payload => ({
  type: actions.SET_APP_TEMPLATE_DOWNLOAD_PROGRESS,
  payload
})

export const changeAppMeta = payload => dispatch => {
  const { purchaseInfo, ...appMeta } = payload

  dispatch(toggleHasChanges(true))
  dispatch({ type: actions.CHANGE_APP_META, payload: appMeta })

  if (purchaseInfo) {
    dispatch(setAppMetaPurchaseInfo(purchaseInfo))
  }
}

export const setAppMetaErrors = payload => ({ type: actions.SET_APP_META_ERRORS, payload })

export const clearAppMetaErrors = payload => ({ type: actions.CLEAR_APP_META_ERRORS, payload })

export const updateNavItems = payload => dispatch => {
  dispatch(toggleHasChanges(true))
  dispatch({ type: actions.UPDATE_NAV_ITEMS, payload })
}

export const deleteNavItems = payload => dispatch => {
  dispatch(toggleHasChanges(true))
  dispatch({ type: actions.DELETE_NAV_ITEMS, payload })
}

export const toggleNavItemConfigurationModal = payload => ({
  type: actions.TOGGLE_NAV_ITEM_CONFIGURATION_MODAL,
  payload
})

export const clearAppCards = payload => ({
  type: actions.CLEAR_APP_CARDS,
  payload
})

export const toggleSnapshotModal = payload => ({
  type: actions.TOGGLE_SNAPSHOT_MODAL,
  payload
})

export const appMetaImages = {
  set: payload => dispatch => {
    dispatch(toggleHasChanges(true))
    dispatch({ type: actions.APP_META_IMAGES.SET, payload })
  },
  add: payload => dispatch => {
    dispatch(toggleHasChanges(true))
    dispatch({ type: actions.APP_META_IMAGES.ADD, payload })
  },
  remove: payload => dispatch => {
    dispatch(toggleHasChanges(true))
    dispatch({ type: actions.APP_META_IMAGES.REMOVE, payload })
  },
  clear: () => dispatch => {
    dispatch(toggleHasChanges(true))
    dispatch({ type: actions.APP_META_IMAGES.CLEAR })
  }
}

export const appMetaTemplate = {
  setFile: payload => dispatch => {
    dispatch(toggleHasChanges(true))
    dispatch({ type: actions.APP_META_TEMPLATE.SET_FILE, payload })
  },
  deleteFile: payload => dispatch => {
    dispatch(toggleHasChanges(true))
    dispatch({ type: actions.APP_META_TEMPLATE.DELETE_FILE, payload })
  },
  changeDescription: payload => dispatch => {
    dispatch(toggleHasChanges(true))
    dispatch({ type: actions.APP_META_TEMPLATE.CHANGE_DESCRIPTION, payload })
  },
  setFilePreview: payload => dispatch => {
    dispatch(toggleHasChanges(true))
    dispatch({ type: actions.APP_META_TEMPLATE.SET_FILE_PREVIEW, payload })
  },
  deleteFilePreview: payload => dispatch => {
    dispatch(toggleHasChanges(true))
    dispatch({ type: actions.APP_META_TEMPLATE.DELETE_FILE_PREVIEW, payload })
  }
}

export const resolveAppBoards = payload => (dispatch, getState) => {
  const {
    profile: {
      boardMenu: { boards }
    }
  } = getState()

  dispatch(
    setAppBoards(
      resolveAppBoardsFromMenu({
        tenantId: payload.tenantId,
        boardIds: payload.boardIds,
        boards
      })
    )
  )
}

export const resolveAppCardsLoading =
  ({ boardIds, loading }) =>
  dispatch => {
    dispatch(
      setAppCardsLoading(
        setBoardCardsLoading({
          boardIds,
          loading
        })
      )
    )
  }

export const resolveAppCards = boards => dispatch => {
  dispatch(setAppCards(resolveBoardCards(boards)))
}

export const getAppCards =
  ({ tenantId, boardIds }) =>
  async (dispatch, getState) => {
    const {
      profile: {
        activeOrganization: { organizationId }
      }
    } = getState()
    let result
    dispatch(
      resolveAppCardsLoading({
        loading: true,
        boardIds
      })
    )
    try {
      const { data: boards } = await getBoardsRequest({
        expand: { cards: true },
        organizationId,
        tenantId,
        boardIds
      })
      dispatch(resolveAppCards(boards))
    } catch (err) {
      console.error(err)
    }

    dispatch(
      resolveAppCardsLoading({
        loading: false,
        boardIds
      })
    )

    return result
  }

export const getAppConfig = () => async dispatch => {
  try {
    const { data } = await getAppStoreConfig()

    if (data) {
      dispatch(setAppMetaConfig(data))
    }
  } catch (err) {
    console.error(err)
  }
}

export const getApp = payload => async dispatch => {
  dispatch(toggleAppBuilderLoader(true))
  try {
    const [{ navItems, ...app }, _appMeta] = await Promise.all([
      dispatch(
        boundAppAPI.getApp({
          variables: payload,
          expand: { navItems: true }
        })
      ),
      appAPI.getAppStoreInfo({
        variables: payload,
        expand: { appStoreInfo: true }
      })
    ])

    if (app.isLocked) {
      exitFromAppBuilder()
      return
    }

    dispatch(
      getAppCards({
        ...payload,
        boardIds: app.boardIds
      })
    )

    const { purchaseInfo, ...appMeta } = _appMeta || getNewAppMeta()

    parseAppPrices(appMeta)

    dispatch(setApp(app))
    dispatch(setAppMeta(appMeta))
    dispatch(setAppMetaPurchaseInfo(purchaseInfo))

    dispatch(setNavItems(navItems || []))
    dispatch(resolveAppBoards(app))
  } catch (err) {
    console.error(err)

    navigateToRecentBoard()
  }
  dispatch(toggleAppBuilderLoader(false))
}

export const setNewApp = payload => dispatch => {
  dispatch(toggleHasChanges(true))
  dispatch(toggleAppCreationMode(true))
  dispatch(setApp(getNewApp(payload)))
  dispatch(setAppMeta(getNewAppMeta()))
}

export const clearAppBuilder = () => dispatch => {
  dispatch(toggleHasChanges(false))
  dispatch(toggleAppCreationMode(false))
  dispatch(setApp({}))
  dispatch(clearAppErrors())
  dispatch(clearAllAppCards({}))
  dispatch(setAppCardsLoading({}))
  dispatch(setAppBoards([]))
  dispatch(setAppLogo(null))
  dispatch(setNavItems([]))
  dispatch(setClearLogo(false))
  dispatch(changeTab(APP_BUILDER_TAB.content))
  dispatch(setAppMeta({}))
  dispatch(clearAppMetaErrors())
}

export const saveAppMeta = () => async (dispatch, getState) => {
  const {
    appBuilder: {
      appMeta,
      app: { tenantId, id: appId }
    },
    profile: {
      activeOrganization: { organizationPermissions = {} }
    }
  } = getState()

  if (!organizationPermissions.isAppstoreEnabled) {
    return
  }

  let linksCount
  let newAppMetaFiles = []
  let newFileTemplate = appMeta.fileTemplateInfo?.file
  let newLogoTemplate = appMeta.fileTemplateInfo?.logo
  let newImages = appMeta.images.filter(image => image.file)
  newAppMetaFiles.push(...newImages)
  linksCount = newImages.length

  if (newFileTemplate?.file) {
    const request = UploadTemplateRequest.create({
      key: { scope: { tenantId } },
      info: {
        name: newFileTemplate.file.name,
        mimeType: newFileTemplate.file.type
      },
      appId,
      data: await FileUploaderGrpcService.convertFileToUint8Array(newFileTemplate.file)
    })

    await FileUploaderGrpcService.uploadTemplate({ request })

    const { fileName, size, extension, uploadDate } = appMeta.fileTemplateInfo?.file || {}

    newFileTemplate = {
      ...newFileTemplate,
      file: undefined,
      fileName,
      size,
      extension,
      uploadDate
    }
  }

  if (newLogoTemplate?.file) {
    linksCount += 1
    newAppMetaFiles.push(newLogoTemplate)
  }

  if (linksCount) {
    const linkType = newLogoTemplate?.file
      ? GenerateSignedLinkRequestSignedLinkType.FOR_TEMPLATE_LOGO
      : GenerateSignedLinkRequestSignedLinkType.FOR_APP_STORE_INFO

    const request = GenerateSignedLinkRequest.create({
      key: { scope: { tenantId } },
      appId,
      countLink: linksCount,
      linkType
    })

    const { results } = await FileUploaderGrpcService.generateSignedLink({ request })

    const filesUploadUrls = results.map(url => ({
      url: url.signedLink,
      fields: url.fields
    }))

    newAppMetaFiles = await Promise.all(
      filesUploadUrls.map((uploadData, index) =>
        appAPI.uploadAppMetaFile({
          id: newAppMetaFiles[index].id,
          uploadData,
          file: newAppMetaFiles[index].file
        })
      )
    )
  }

  newImages = newImages.length
    ? appMeta.images.map(image => {
        const savedImage = newAppMetaFiles.find(item => image.id === item.id)

        if (savedImage) {
          return {
            ...image,
            file: undefined,
            id: savedImage.fields.key,
            url: `${savedImage.url}${savedImage.fields.key}`
          }
        }

        return image
      })
    : appMeta.images

  const _newLogoTemplate =
    newLogoTemplate && newAppMetaFiles.find(item => newLogoTemplate.id === item.id)
  if (_newLogoTemplate) {
    newLogoTemplate = {
      ..._newLogoTemplate,
      file: undefined,
      id: _newLogoTemplate.fields.key,
      url: `${_newLogoTemplate.url}${_newLogoTemplate.fields.key}`
    }
  }

  // save app with updated files
  const newFileTemplateInfo = {
    ...(appMeta.fileTemplateInfo || {}),
    file: newFileTemplate || appMeta.fileTemplateInfo?.file,
    logo: newLogoTemplate || appMeta.fileTemplateInfo?.logo
  }
  let { appMeta: newAppMeta } = { ...getState().appBuilder }

  const newPurchaseInfo = appMeta?.purchaseInfo?.map(purchaseInfo => {
    const { price, ...rest } = purchaseInfo
    purchaseInfo.price = +price

    const hasPriceValue = price ? !!price.toString().length : false
    if (!hasPriceValue) return rest
    return purchaseInfo
  })
  newAppMeta = {
    ...newAppMeta,
    images: newImages,
    fileTemplateInfo: newFileTemplateInfo,
    purchaseInfo: newPurchaseInfo
  }

  const { data: savedAppMeta } = appMeta.id
    ? await appAPI.updateAppMeta({ tenantId, appId, data: newAppMeta })
    : await appAPI.createAppMeta({ tenantId, appId, data: newAppMeta })

  parseAppPrices(savedAppMeta)

  dispatch(
    setAppMeta({
      ...savedAppMeta,
      previousVersion: appMeta.previousVersion
    })
  )
}

export const saveAppLogo = app => async (dispatch, getState) => {
  const { newLogo, clearLogo } = getState().appBuilder

  if (!newLogo && !clearLogo) return Promise.resolve(app.logo)

  const file = clearLogo ? null : newLogo

  const request = UploadAvatarRequest.create({
    key: {
      scope: {
        tenantId: app.tenantId
      }
    },
    appId: app.id,
    type: UploadAvatarRequestAvatarType.FOR_APP,
    info: {
      name: file?.name || 'avatar',
      mimeType: file?.type || 'image/png'
    },
    data: file ? await FileUploaderGrpcService.convertFileToUint8Array(file) : undefined,
    clear: clearLogo
  })

  await FileUploaderGrpcService.uploadAvatar({ request })

  const logoLink = clearLogo
    ? null
    : FileUploaderGrpcService.getFileLink(EFileUploaderFileTypes.APP_LOGO, {
        tenantID: app.tenantId,
        appID: app.id
      })

  dispatch(setClearLogo(false))

  return logoLink
}

export const saveApp = payload => async (dispatch, getState) => {
  const { requestBoardMenu = true } = payload

  const { isAppCreationMode, app, navItems } = getState().appBuilder

  const sortedNavItems = sortNavItems(navItems)

  let homepage = navItems.find(item => item.isHomepage)
  // if no homepage, set first nav item as homepage
  if (!homepage && sortedNavItems.length) {
    if (sortedNavItems[0].isGroup) {
      homepage = sortedNavItems[0].children[0]
    } else {
      homepage = sortedNavItems[0]
    }
  }

  const { showBoardsInMenu, showExportTools, ...appToSave } = app

  const data = {
    ...appToSave,
    homepage: {
      tenantId: homepage?.tenantId ?? null,
      boardId: homepage?.boardId ?? null,
      cardUuid: homepage?.cardUuid ?? null,
      cardOpeningMode: homepage?.cardOpeningMode ?? null
    },
    menu: navItems
  }

  const orgPermissions = orgPermissionsSelector(getState())
  const isAppstoreEnabled = orgPermissions?.isAppstoreEnabled

  if (isAppstoreEnabled) {
    // save these flags only if user has an access to app store
    data.showBoardsInMenu = showBoardsInMenu
    data.showExportTools = showExportTools
  }

  const errors = validateApp(app)

  if (errors) {
    dispatch(setAppErrors(errors))
    return Promise.reject(errors)
  }

  dispatch(toggleAppSaving(true))

  let logoLink

  try {
    if (isAppCreationMode) {
      // When creating new app with some logo, first we need to get app ID.
      const { data: createdApp } = await appAPI.createApp({ tenantId: app.tenantId, data })

      // Once we have app ID we are good to upload the app logo to Uploader service.
      logoLink = await dispatch(saveAppLogo(createdApp))

      // After logo is uploaded we want to upload the created app with the logo.
      const { data: updatedApp } = await appAPI.updateApp({
        tenantId: createdApp.tenantId,
        appId: createdApp.id,
        data: { ...data, logo: logoLink }
      })

      dispatch(setApp(updatedApp))
      dispatch(toggleAppCreationMode(false))
    } else {
      logoLink = await dispatch(saveAppLogo(app))

      await appAPI.updateApp({
        tenantId: app.tenantId,
        appId: app.id,
        data: { ...data, logo: logoLink }
      })
    }

    const localLogoData = { logo: logoLink && addTimestampToUrl(logoLink, true) }

    dispatch(changeApp(localLogoData))
    await dispatch(saveAppMeta())

    if (requestBoardMenu) {
      const { tenants, boards, apps = [] } = await dispatch(boardMenuRequest())

      const localApps = apps.map(el =>
        el.id === app.id ? { ...el, logo: localLogoData.logo } : el
      )

      dispatch(
        boardMenuReceive({
          tenants,
          boards,
          apps: localApps
        })
      )
    }

    dispatch(toggleHasChanges(false))
    dispatch(toggleAppSaving(false))

    return Promise.resolve()
  } catch (err) {
    dispatch(
      showToastMessage({
        text: messages.APP_SERVER_ERROR_TITLE,
        size: 'M'
      })
    )
    dispatch(toggleAppSaving(false))

    return Promise.reject(err)
  }
}

export const deleteBoardFromApp = payload => (dispatch, getState) => {
  const {
    appBuilder: { app, navItems }
  } = getState()

  const boardIds = app.boardIds.filter(boardId => boardId !== payload.boardId)

  const navItemsToDelete = navItems.reduce((acc, navItem) => {
    if (navItem.boardId === payload.boardId) {
      acc.push(navItem.id)
    }

    return acc
  }, [])

  dispatch(toggleHasChanges(true))
  dispatch(changeApp({ boardIds }))

  dispatch(
    resolveAppBoards({
      tenantId: app.tenantId,
      boardIds
    })
  )
  dispatch(clearAppCards(payload))

  dispatch(deleteNavItems(navItemsToDelete))
}

export const addBoardsToApp = payload => (dispatch, getState) => {
  const {
    appBuilder: { app }
  } = getState()

  const boardIds = app.boardIds.concat(payload.boardIds)

  dispatch(toggleHasChanges(true))
  dispatch(changeApp({ boardIds }))

  dispatch(
    resolveAppBoards({
      tenantId: app.tenantId,
      boardIds
    })
  )

  dispatch(
    getAppCards({
      tenantId: app.tenantId,
      boardIds: payload.boardIds
    })
  )
}

export const changeAppLogo = payload => dispatch => {
  dispatch(toggleHasChanges(true))
  dispatch(changeApp({ logo: null }))
  dispatch(setAppLogo(payload))
  dispatch(setClearLogo(false))
}

export const clearAppLogo = () => dispatch => {
  dispatch(toggleHasChanges(true))
  dispatch(changeApp({ logo: null }))
  dispatch(setAppLogo(null))
  dispatch(setClearLogo(true))
}

export const toggleAppPlacementModal = payload => (dispatch, getState) => {
  const {
    appBuilder: { appMeta }
  } = getState()

  const hasErrors = dispatch(validateAllAppMeta(appMeta))

  if (hasErrors) {
    return
  }
  dispatch({
    type: actions.TOGGLE_APP_PLACEMENT_MODAL,
    payload
  })
}

export function updateAppTemplateDownloadState(payload) {
  return (dispatch, getState) => {
    const { appTemplateDownloadProgress } = getState().appBuilder
    const templateProgress = appTemplateDownloadProgress.find(
      progress => progress.fileName === payload.fileName
    )

    const updatedProgress = templateProgress
      ? appTemplateDownloadProgress.map(progress =>
          progress.fileName === payload.fileName ? { ...progress, ...payload } : progress
        )
      : [...appTemplateDownloadProgress, payload]
    dispatch(setAppTemplateDownloadProgress(updatedProgress))
  }
}

export function toggleAppTemplateDownloadIndicator(payload, isDownloadIndicatorVisible) {
  return (dispatch, getState) => {
    const { toastMessages } = getState().board
    const templateDownloadMessage = toastMessages.filter(
      message => message.type === actions.APP_TEMPLATE_DOWNLOAD_INDICATOR_TYPE
    )[0]
    if (isDownloadIndicatorVisible) {
      dispatch(
        updateAppTemplateDownloadState({
          fileName: payload.fileName,
          status: APP_DOWNLOAD_TEMPLATE_STATUS.LOADING
        })
      )
      if (!templateDownloadMessage) {
        dispatch(
          showToastMessage(
            { size: 'S', text: payload.fileName },
            actions.APP_TEMPLATE_DOWNLOAD_INDICATOR_TYPE,
            Infinity
          )
        )
      }
    } else if (!isDownloadIndicatorVisible) {
      dispatch(hideToastMessage(templateDownloadMessage.id))
      dispatch(setAppTemplateDownloadProgress([]))
    }
  }
}
