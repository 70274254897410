export const REQUEST_WIDGET_CLASSES_LIST = 'REQUEST_WIDGET_CLASSES_LIST'
export const RECEIVE_WIDGET_CLASSES_LIST = 'RECEIVE_WIDGET_CLASSES_LIST'
export const RECEIVE_WIDGET_CLASSES_LIST_ERROR = 'RECEIVE_WIDGET_CLASSES_LIST_ERROR'

export const REQUEST_UPDATE_WIDGET_MENU_ITEM = 'REQUEST_UPDATE_WIDGET_MENU_ITEM'

export const RECEIVE_UPLOAD_WIDGET = 'RECEIVE_UPLOAD_WIDGET'

export const TOGGLE_UPLOADED_IMAGE_DELETE_MODAL = 'TOGGLE_UPLOADED_IMAGE_DELETE_MODAL'

export const RECEIVE_DELETE_UPLOADED_WIDGET = 'RECEIVE_DELETE_UPLOADED_WIDGET'
export const RECEIVE_DELETE_UPLOADED_WIDGET_ERROR_MESSAGE =
  'RECEIVE_DELETE_UPLOADED_WIDGET_ERROR_MESSAGE'

export const UPDATE_CURRENT_UPLOADING_DATA = 'UPDATE_CURRENT_UPLOADING_DATA'
export const TOGGLE_FILES_UPLOADING_FLAG = 'TOGGLE_FILES_UPLOADING_FLAG'

export const SET_UPLOAD_FILES_WIDGET_DATA = 'SET_UPLOAD_FILES_WIDGET_DATA'

export const TOGGLE_GOOGLE_PICKER = 'TOGGLE_GOOGLE_PICKER'
export const TOGGLE_ONE_DRIVE_PICKER = 'TOGGLE_ONE_DRIVE_PICKER'

export const TOGGLE_WIDGET_FULLSCREEN_MODAL = 'TOGGLE_WIDGET_FULLSCREEN_MODAL'

export const TOGGLE_WIDGET_TOAST_ACTION_MESSAGE = 'TOGGLE_WIDGET_TOAST_ACTION_MESSAGE'
