export const CLEAR_WORKSPACE_MEMBERS = 'CLEAR_WORKSPACE_MEMBERS'
export const APPEND_WORKSPACE_MEMBERS = 'APPEND_WORKSPACE_MEMBERS'
export const TOGGLE_WORKSPACE_MEMBERS_FETCHING = 'TOGGLE_WORKSPACE_MEMBERS_FETCHING'

export const TOGGLE_MANAGER_ASSIGNMENT_MODAL = 'TOGGLE_MANAGER_ASSIGNMENT_MODAL'
export const TOGGLE_DELETE_WORKSPACE_MEMBER_CONFIRMATION =
  'TOGGLE_DELETE_WORKSPACE_MEMBER_CONFIRMATION'
export const TOGGLE_DELETE_WORKSPACE_MEMBER_REJECTION_MODAL =
  'TOGGLE_DELETE_WORKSPACE_MEMBER_REJECTION_MODAL'

export const UPDATE_WORKSPACE_MEMBER = 'UPDATE_WORKSPACE_MEMBER'
