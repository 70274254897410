export const SELECT_WIDGET_PANEL = 'SELECT_WIDGET_PANEL'

export const SET_CURRENT_CARD = 'SET_CURRENT_CARD'

export const REQUEST_CARD = 'REQUEST_CARD'
export const RECEIVE_CARD = 'RECEIVE_CARD'
export const RECEIVE_CARD_ERROR = 'RECEIVE_CARD_ERROR'

export const TOGGLE_EMPTY_NAME_VALIDATION = 'TOGGLE_EMPTY_NAME_VALIDATION'

export const TOGGLE_EDIT_LOCKED_MODAL = 'TOGGLE_EDIT_LOCKED_MODAL'
export const TOGGLE_UNSAVED_CHANGES_MODAL = 'TOGGLE_UNSAVED_CHANGES_MODAL'

export const TOGGLE_UNSAVED_CHANGES_FLAG = 'TOGGLE_UNSAVED_CHANGES_FLAG'
export const TOGGLE_SAVE_TO_BOARD_FLAG = 'TOGGLE_SAVE_TO_BOARD_FLAG'

export const CARD_UPDATE = 'CARD_UPDATE'
export const CARD_SNAPSHOT_UPDATE = 'CARD_SNAPSHOT_UPDATE'
export const CARD_CREATE = 'CARD_CREATE'
export const WIDGET_UPDATE = 'WIDGET_UPDATE'
export const WIDGET_DELETE = 'WIDGET_DELETE'
export const WIDGET_CREATE = 'WIDGET_CREATE'
export const BULK_WIDGETS_CREATE = 'BULK_WIDGETS_CREATE'

export const AUTO_SAVE_START = 'AUTO_SAVE_START'
export const AUTO_SAVE_END = 'AUTO_SAVE_END'

export const START_EDIT_REQUEST = 'START_EDIT_REQUEST'
export const START_EDIT_RECEIVE = 'START_EDIT_RECEIVE'
export const START_EDIT_RECEIVE_ERROR = 'START_EDIT_RECEIVE_ERROR'

export const SET_ACTIVE_REQUEST = 'SET_ACTIVE_REQUEST'
export const SET_ACTIVE_RECEIVE = 'SET_ACTIVE_RECEIVE'
export const SET_ACTIVE_RECEIVE_ERROR = 'SET_ACTIVE_RECEIVE_ERROR'

export const RECEIVE_CARD_UPDATE = 'RECEIVE_CARD_UPDATE'
export const RECEIVE_WIDGET_CREATE = 'RECEIVE_WIDGET_CREATE'
export const RECEIVE_WIDGET_UPDATE = 'RECEIVE_WIDGET_UPDATE'
export const RECEIVE_WIDGET_DELETE = 'RECEIVE_WIDGET_DELETE'
export const BULK_WIDGETS_UPDATE = 'BULK_WIDGETS_UPDATE'

export const UPDATE_RESOLVED_DRAFT_WIDGETS = 'UPDATE_RESOLVED_DRAFT_WIDGETS'

export const SET_SELECTED_WIDGETS = 'SET_SELECTED_WIDGETS'
export const SET_EDITING_WIDGET = 'SET_EDITING_WIDGET'

export const TOGGLE_BUILDER_CREATE_MODE = 'TOGGLE_BUILDER_CREATE_MODE'

export const TOGGLE_BOARD_VIEW_MODE = 'TOGGLE_BOARD_VIEW_MODE'

export const SET_WIDGETS_TO_BUFFER = 'SET_WIDGETS_TO_BUFFER'

export const SET_UNSAVED_CHANGES_CALLBACK = 'SET_UNSAVED_CHANGES_CALLBACK'

export const TOGGLE_CHARTS_MODAL = 'TOGGLE_CHARTS_MODAL'
export const TOGGLE_ISSUE_COUNTER_MODAL = 'TOGGLE_ISSUE_COUNTER_MODAL'

export const TOGGLE_POPUP_NOTIFICATION = 'TOGGLE_POPUP_NOTIFICATION'

export const TOGGLE_DATA_LINKING = 'TOGGLE_DATA_LINKING'

export const TOGGLE_SMART_LINK_MODAL = 'TOGGLE_SMART_LINK_MODAL'

export const SET_AUTOSAVE_INTERVAL_ID = 'SET_AUTOSAVE_INTERVAL_ID'

export const SET_ACTIVE_SECTION = 'SET_ACTIVE_SECTION'

export const SET_LEFT_MENU_CARDS = 'SET_LEFT_MENU_CARDS'
export const REPLACE_CARD_IN_LEFT_MENU = 'REPLACE_CARD_IN_LEFT_MENU'

export const TOGGLE_CB_SERVER_ERROR = 'TOGGLE_CB_SERVER_ERROR'

export const SET_ACTIVE_WIDGET_UPDATED = 'SET_ACTIVE_WIDGET_UPDATED'
