export const TOGGLE_BOARD_SPINNER = 'TOGGLE_BOARD_SPINNER'

export const SHOW_FULL_BOARD_PAGE_SPINNER = 'SHOW_FULL_BOARD_PAGE_SPINNER'

export const TOGGLE_TOP_MENU_LOADER = 'TOGGLE_TOP_MENU_LOADER'

export const TOGGLE_CARD_NAME_SPINNER = 'TOGGLE_CARD_NAME_SPINNER'
export const TOGGLE_COLUMN_LOADING = 'TOGGLE_COLUMN_LOADING'
export const TOGGLE_COPYING_COLUMN_SPINNER = 'TOGGLE_COPYING_COLUMN_SPINNER'

export const SHOW_MODAL_WINDOW_SPINNER = 'SHOW_MODAL_WINDOW_SPINNER'
export const HIDE_MODAL_WINDOW_SPINNER = 'HIDE_MODAL_WINDOW_SPINNER'

export const TOGGLE_CARD_BUILDER_SPINNER = 'TOGGLE_CARD_BUILDER_SPINNER'

export const TOGGLE_UNSAVED_CHANGES_SPINNER = 'TOGGLE_UNSAVED_CHANGES_SPINNER'
export const TOGGLE_TEAM_ADMINISTRATION_PAGE_SPINNER = 'TOGGLE_TEAM_ADMINISTRATION_PAGE_SPINNER'

export const TOGGLE_COMMENTS_SPINNER = 'TOGGLE_COMMENTS_SPINNER'
export const TOGGLE_AUTH_LOADER = 'TOGGLE_AUTH_LOADER'
