import messages from '../../constants/messages'
import { EFieldKeys } from '../../constants/workflowBuilder/blocksFieldsKeys'

export const DEFINE_IN_TRIGGER = 'default'
export const DISPLAY_CARD = 'displayCard'
export const DISPLAY_BOARD = 'displayBoard'

export const FINISH_BLOCK_OPTIONS = {
  [DEFINE_IN_TRIGGER]: {
    value: DEFINE_IN_TRIGGER,
    label: messages.DEFINE_IN_TRIGGER_OBJECT_SETTINGS
  },
  [DISPLAY_CARD]: { value: DISPLAY_CARD, label: messages.DISPLAY_CARD_WHEN_WF_FINISHED },
  [DISPLAY_BOARD]: { value: DISPLAY_BOARD, label: messages.DISPLAY_BOARD_WHEN_WF_FINISHED }
}

export const FINISH_BLOCK_OPTIONS_LIST = Object.values(FINISH_BLOCK_OPTIONS)

export const validateFinishBlock = ({ block }) => {
  const error = {}

  if (
    !block.meta ||
    (block.meta[EFieldKeys.TYPE] === DISPLAY_CARD && !block.input[EFieldKeys.SOURCE_INPUT]) ||
    (block.meta[EFieldKeys.TYPE] === DISPLAY_BOARD && !block.input[EFieldKeys.SOURCE_INPUT])
  ) {
    error[EFieldKeys.SOURCE_INPUT] = messages.emptyField

    return { error }
  }

  return { error }
}

export const isDefaultFinish = block => block.meta[EFieldKeys.TYPE] === DEFINE_IN_TRIGGER

export const DEFAULT_FINISH_BLOCK_DATA = {
  meta: { type: DEFINE_IN_TRIGGER },
  input: { [EFieldKeys.SOURCE_INPUT]: null },
  error: {}
}
