import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ActionsToastMessage from 'components/common/actionsToastMessage'
import * as actions from '../../actions'
import { cancelCardEditing } from '../../api/cardAPI'
import messages from '../../constants/messages'

class UnsavedDraftMessageContainer extends Component {
  deleteDraft() {
    const { draftInfo } = this.props

    if (draftInfo) {
      const payload = {
        tenantId: draftInfo.tenantId,
        boardId: draftInfo.boardId,
        itemID: draftInfo.cardUuid
      }

      cancelCardEditing(payload).catch(err => console.error(err))
    }

    this.props.onMessageHide()
    this.props.clearDraft()
  }

  openCardBuilder() {
    const { draftInfo } = this.props

    if (draftInfo) {
      const payload = {
        tenantId: draftInfo.tenantId,
        boardId: draftInfo.boardId,
        cardID: draftInfo.cardUuid
      }

      this.props.openCardBuilder(payload)
    }

    this.props.onMessageHide()
    this.props.clearDraft()
  }

  render() {
    const {
      isCardBuilderContainerLoading,
      isBoardContainerLoading,
      draftInfo,
      visible,
      height,
      width,
      transform
    } = this.props

    const cardName = draftInfo ? draftInfo.cardName : ''

    const loading = isCardBuilderContainerLoading || isBoardContainerLoading

    return (
      <ActionsToastMessage
        isVisible={!loading && visible}
        height={height}
        width={width}
        transform={transform}
        onDiscard={this.deleteDraft.bind(this)}
        onContinue={this.openCardBuilder.bind(this)}
      >
        <div>
          {messages.UNSAVED_DRAFT_1}
          <div className="card-name">{cardName || messages.UNTITLED_CARD}.</div>
          {messages.DO_YOU_WANT_TO} <span className="bold">{messages.CONTINUE}</span>
          &nbsp;{messages.EDITING_OR}&nbsp;
          <span className="bold">{messages.DISCARD_ALL_CHANGES}</span>?
        </div>
      </ActionsToastMessage>
    )
  }
}

function mapStateToProps(state) {
  return {
    draftInfo: state.profile.user.draftInfo,
    isCardBuilderContainerLoading: state.spinner.isCardBuilderContainerLoading,
    isBoardContainerLoading: state.spinner.isBoardContainerLoading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    clearDraft: bindActionCreators(actions.clearDraft, dispatch),
    openCardBuilder: bindActionCreators(actions.openCardBuilder, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UnsavedDraftMessageContainer)
