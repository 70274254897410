export enum EFieldKeys {
  SOURCE_INPUT = 'source_input',
  STRING_INPUT = 'string_input',
  SUBJECT_INPUT = 'subject_input',
  BOARD_INPUT = 'board_input',
  CARD_INPUT = 'card_input',
  USER_INPUT = 'user_input',
  TYPE = 'type',
  HIDE = 'hide',
  NAME = 'name',
  NAMES = 'names',
  SECTIONS = 'sections',
  ACTIONS = 'actions',
  TEXT_POSITION = 'position',
  ENTITY_INPUT = 'entity_input',
  COLUMN_NUMBER = 'number',
  RELATIVE_COLUMN_NUMBER = 'relative_column_number',
  ATTRIBUTE = 'attribute',
  PARAMS = 'params',
  IS_LINK = 'is_link',
  TEXT = 'text',
  SUBJECT = 'subject',
  FLAGGED = 'flagged',
  PREDICATE = 'predicate',
  RULE = 'rule',
  FILTER_UUID = 'filterUuid',
  FILTER_UUID_SNAKE = 'filter_uuid',
  EVENT_OBJECT = 'eventObject',
  EVENT_TYPE = 'eventType',
  ROLES = 'roles',
  UBF_CELL = 'ubf_cell',
  SUBJECT_UBF_CELL = 'subject_ubf_cell',
  FORMULA = 'formula',
  DATA_TYPE = 'data_type',
  TEMPLATE_ID = 'template_id',
  TEMPLATE_OBJECT = 'template_object',
  URL_TARGET = 'url_target',
  METHOD_TYPE = 'method_type',
  AUTHORIZATION_TYPE = 'authorization_type',
  CREDENTIAL_ID = 'credential_id',
  IS_RETRY_ENABLED = 'is_retry_enabled',
  TIMEOUT = 'timeout',
  RETRIES = 'retries',
  RETRY_TIMEOUT_FACTOR = 'retry_timeout_factor',
  HEADERS = 'headers',
  KEY = 'key',
  BODY = 'body',
  VALUE = 'value',
  FILE_INPUT = 'file_input',
  FILE_INPUT_NUMBER = 'file_input_number'
}
