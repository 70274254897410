import { type MouseEventHandler, type ReactElement, useMemo } from 'react'
import classNames from 'classnames'
import { type TPopperProps, Popper } from 'components/common/popper/popper'
import { type TFilterRuleConditionAttributeOption } from 'features/filter/filterRuleConditions/filterRuleConditions.types'
import './attribute-options-menu.scss'

type TAttributeOptionProps = {
  option: TFilterRuleConditionAttributeOption
  onSelect: (value: TFilterRuleConditionAttributeOption['value']) => void
  isActive?: boolean
}

const AttributeOption = ({ option, onSelect, isActive }: TAttributeOptionProps) => {
  const handleClick: MouseEventHandler = event => {
    event.stopPropagation()

    onSelect(option.value)
  }

  return (
    <>
      <li
        value={option.value}
        className={classNames('menu-option', {
          active: isActive
        })}
        onClick={handleClick}
      >
        {option.label}
      </li>
      {option.isBottomDivider && <div className="menu-divider" />}
    </>
  )
}

type TProps = {
  attributeValue: TFilterRuleConditionAttributeOption['value'] | undefined
  attributeOptions: TFilterRuleConditionAttributeOption[]
  onSelect: (value: TFilterRuleConditionAttributeOption['value']) => void
} & Pick<TPopperProps, 'open' | 'anchorEl' | 'onClose' | 'style'>

export const AttributeOptionsMenu = ({
  attributeValue,
  attributeOptions,
  onSelect,
  ...popperProps
}: TProps) => {
  const renderedAttributeOptions = useMemo(
    () =>
      attributeOptions.reduce<ReactElement[]>((options, option) => {
        if (!option.isUsed) {
          options.push(
            <AttributeOption
              key={option.value}
              option={option}
              isActive={attributeValue === option.value}
              onSelect={onSelect}
            />
          )
        }

        return options
      }, []),
    [attributeOptions, attributeValue, onSelect]
  )

  return (
    <Popper
      placement="bottom-start"
      {...popperProps}
      modifiers={[
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            altAxis: true,
            altBoundary: true,
            tether: true,
            padding: 8
          }
        },
        {
          name: 'offset',
          options: { offset: [0, 4] }
        }
      ]}
      autoFocus={false}
    >
      <ul className="filter-rule-condition-attribute-menu">{renderedAttributeOptions}</ul>
    </Popper>
  )
}
