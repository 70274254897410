export const extractBoard = ({ data }) => {
  const {
    organization: {
      tenant: { board }
    }
  } = data
  return board
}

export const extractBoards = ({ data }) => {
  const {
    organization: {
      tenant: { boards }
    }
  } = data
  return boards
}
