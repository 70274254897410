import { createReducer } from 'redux-create-reducer'
import * as ActionTypes from '../constants/actionTypes'
import {
  updateOutputName,
  changeOutputNamesFromMeta
} from '../helpers/workflowBuilder/outputsNamesHelpers'
import { getInitialOutputs } from '../helpers/workflowBuilder/outputsOperations'
import { clearSelectedOutput } from '../helpers/workflowBuilder/blocksOperations'
import {
  createNewBlock,
  deleteBlock,
  changeInput,
  toggleOpenState,
  clearStoppedInputs,
  changeOutputType,
  updateWorkflowOutputs
} from '../helpers/workflowBuilder/workflowOperations'
import { validateBlock } from '../helpers/workflowBuilder/blocksValidation'

export const workflow = createReducer(
  {},
  {
    [ActionTypes.SET_WORKFLOW_DATA](state, action) {
      return {
        ...state,
        ...action.payload
      }
    },

    [ActionTypes.CLEAR_WORKFLOW_DATA](state) {
      return {
        ...state,
        workflowDefinition: {},
        workflowBlocks: [],
        workflowBlocksMap: {},
        outputs: getInitialOutputs(),
        outputsHistory: {},
        outputsNames: {},
        outputsStoppers: {},

        invalidBlocksMap: {},
        isWorkflowMandatoryError: false,
        isValidationErrorShown: false,

        activeBlockId: -1,

        isWorkflowConfirmationShown: false,
        isWorkflowLoading: false
      }
    },

    [ActionTypes.CREATE_WORKFLOW_BLOCK](state, action) {
      return {
        ...state,
        ...createNewBlock({
          payload: action.payload,
          outputsMap: state.outputs,
          outputsHistory: state.outputsHistory,
          outputsNames: state.outputsNames,
          workflowBlocks: state.workflowBlocks,
          workflowBlocksMap: state.workflowBlocksMap
        })
      }
    },

    [ActionTypes.DELETE_WORKFLOW_BLOCK](state, action) {
      return {
        ...state,
        ...deleteBlock({
          blockId: action.payload,
          outputsMap: state.outputs,
          outputsHistory: state.outputsHistory,
          workflowBlocks: state.workflowBlocks,
          outputsStoppers: state.outputsStoppers,
          workflowBlocksMap: state.workflowBlocksMap
        })
      }
    },

    [ActionTypes.UPDATE_WORKFLOW_BLOCK](state, action) {
      const workflowBlocksMap = { ...state.workflowBlocksMap }
      const { id, data } = action.payload
      workflowBlocksMap[id] = { ...workflowBlocksMap[id], ...data }

      return {
        ...state,
        workflowBlocksMap,
        outputsNames: {
          ...state.outputsNames,
          ...changeOutputNamesFromMeta(workflowBlocksMap[id], data.meta || {})
        }
      }
    },

    [ActionTypes.CHANGE_WORKFLOW_BLOCK_OUTPUT](state, action) {
      const { id, data } = action.payload
      const updatedBlock = { ...state.workflowBlocksMap[id], ...data }
      const updatedWorkflowBlocksMap = { ...state.workflowBlocksMap, [id]: updatedBlock }

      // Filter out outputs that were removed
      const removedOutputs = state.workflowBlocksMap[id].output.filter(
        oldOutput => !data.output.some(newOutput => oldOutput.outputName === newOutput.outputName)
      )

      // Update workflow blocks with removed outputs
      const updatedWorkflowBlocks = clearSelectedOutput({
        workflowBlocks: state.workflowBlocks,
        workflowBlocksMap: updatedWorkflowBlocksMap,
        output: removedOutputs
      })

      return {
        ...state,
        workflowBlocksMap: updatedWorkflowBlocks,
        ...updateWorkflowOutputs({
          block: updatedBlock,
          outputsMap: state.outputs,
          outputsHistory: state.outputsHistory,
          outputsNames: {
            ...state.outputsNames,
            ...changeOutputNamesFromMeta(updatedBlock, data.meta || {})
          }
        })
      }
    },

    [ActionTypes.CHANGE_WORKFLOW_BLOCK_INPUT](state, action) {
      return {
        ...state,
        ...changeInput({
          payload: action.payload,
          outputsNames: state.outputsNames,
          outputsHistory: state.outputsHistory,
          outputsStoppers: state.outputsStoppers,
          workflowBlocksMap: state.workflowBlocksMap
        })
      }
    },

    [ActionTypes.CHANGE_WORKFLOW_BLOCK_OUTPUT_TYPE](state, action) {
      return {
        ...state,
        ...changeOutputType({
          ...action.payload,
          outputsMap: state.outputs,
          outputsNames: state.outputsNames,
          outputsHistory: state.outputsHistory,
          workflowBlocks: state.workflowBlocks,
          workflowBlocksMap: state.workflowBlocksMap
        })
      }
    },

    [ActionTypes.CLEAR_WORKFLOW_BLOCKS_STOPPED_INPUTS](state) {
      return {
        ...state,
        ...clearStoppedInputs({
          workflowBlocks: state.workflowBlocks,
          outputsHistory: state.outputsHistory,
          outputsStoppers: state.outputsStoppers,
          workflowBlocksMap: state.workflowBlocksMap
        })
      }
    },

    [ActionTypes.TOGGLE_VALIDATION_ERROR](state, action) {
      return { ...state, isValidationErrorShown: action.payload }
    },

    [ActionTypes.TOGGLE_WORKFLOW_MANDATORY_ERROR](state, action) {
      return { ...state, isWorkflowMandatoryError: action.payload }
    },

    [ActionTypes.TOGGLE_ALL_CHANGES_SAVED](state, action) {
      return { ...state, isAllChangesSaved: action.payload }
    },

    [ActionTypes.UPDATE_WORKFLOW_DEFINITION](state, action) {
      return {
        ...state,
        workflowDefinition: {
          ...state.workflowDefinition,
          ...action.payload
        }
      }
    },

    [ActionTypes.UPDATE_OUTPUT_NAME](state, action) {
      return {
        ...state,
        ...updateOutputName({
          payload: action.payload,
          outputsNames: state.outputsNames
        })
      }
    },

    [ActionTypes.TOGGLE_WORKFLOW_OPEN_STATE](state, action) {
      const workflowBlocksMap = { ...state.workflowBlocksMap }
      const { id, open } = action.payload
      workflowBlocksMap[id] = { ...workflowBlocksMap[id], open }

      return {
        ...state,
        workflowBlocksMap
      }
    },

    [ActionTypes.SET_ACTIVE_WORKFLOW](state, action) {
      return { ...state, activeBlockId: action.payload }
    },

    [ActionTypes.TOGGLE_WORKFLOW_CONFIRMATION_SHOWN](state, action) {
      return { ...state, isWorkflowConfirmationShown: action.payload }
    },

    [ActionTypes.SET_INVALID_BLOCKS](state, action) {
      return { ...state, invalidBlocksMap: action.payload }
    },

    [ActionTypes.VALIDATE_BLOCK](state, action) {
      const workflowBlocksMap = { ...state.workflowBlocksMap }
      const id = action.payload
      const block = workflowBlocksMap[id]
      const { error, warn } = validateBlock({ block, outputs: state.outputs, workflowBlocksMap })
      workflowBlocksMap[id] = { ...workflowBlocksMap[id], error, warn }
      return { ...state, workflowBlocksMap }
    },

    [ActionTypes.VALIDATE_ALL_BLOCK](state) {
      const { workflowBlocks, workflowBlocksMap, outputs } = state

      const blocksMap = workflowBlocks.reduce((acc, blockId) => {
        const block = workflowBlocksMap[blockId]
        const { error, warn } = validateBlock({ block, outputs, workflowBlocksMap })

        acc[blockId] = {
          ...workflowBlocksMap[blockId],
          error,
          warn
        }

        return acc
      }, {})

      return { ...state, workflowBlocksMap: blocksMap }
    },

    [ActionTypes.TOGGLE_WORKFLOW_LOADER](state, action) {
      return {
        ...state,
        isWorkflowLoading: action.payload
      }
    },

    [ActionTypes.TOGGLE_BLOCK_ERROR](state, action) {
      const { id, error } = action.payload
      const invalidBlocksMap = { ...state.invalidBlocksMap }
      if (error) {
        invalidBlocksMap[id] = error
      } else {
        delete invalidBlocksMap[id]
      }
      return { ...state, invalidBlocksMap }
    },

    [ActionTypes.TOGGLE_INVALID_BLOCKS_OPEN_STATE](state) {
      return {
        ...state,
        ...toggleOpenState({
          workflowBlocksMap: state.workflowBlocksMap,
          invalidBlocksMap: state.invalidBlocksMap
        })
      }
    },

    [ActionTypes.TOGGLE_WORKFLOW_CREATION_MODE](state, action) {
      return {
        ...state,
        isWorkflowCreation: action.payload
      }
    }
  }
)

export default workflow
