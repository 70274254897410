export const REACT_ROOT_DOM_ID = 'root'

export const MAX_WIDGET_COUNT = 300

export const IMPORT_BUNDLE_DROPZONE_ID = 'import-bundle-dropzone'

export const ICONS_PATH = '/assets/images/icons'

export const BUY_WORKSPACE_SUBSCRIPTION_URL = 'https://praxie.com/pricing/'

export const ENVIRONMENTS = {
  development: {
    id: 'development',
    name: 'dev',
    domain: 'lxupboarddev'
  },
  stage: {
    id: 'stage',
    name: 'stage',
    domain: 'lxupboardstage'
  },
  production: {
    id: 'production',
    name: 'prod',
    domain: 'boards'
  }
}

export const ENVIRONMENTS_LIST = Object.values(ENVIRONMENTS)

export const TODAY_FORMULA = 'TODAY()'
export const UPDATE_FREQUENCY_IN_HOURS = 2

export enum EHttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE'
}

export enum ERequestHeaders {
  ContentLength = 'Content-Length',
  Host = 'Host',
  UserAgent = 'User-Agent',
  Accept = 'Accept',
  AcceptEncoding = 'Accept-Encoding',
  ContentType = 'Content-Type'
}

export enum EKeyCode {
  LEFT_ARROW = 'ArrowLeft',
  UP_ARROW = 'ArrowUp',
  RIGHT_ARROW = 'ArrowRight',
  DOWN_ARROW = 'ArrowDown',
  TAB = 'Tab',
  DEL = 'Delete',
  BACKSPACE = 'Backspace',
  LEFT_BRACKET = '[',
  SPACE = ' ',
  D = 'D',
  F = 'F',
  A = 'A',
  Q = 'Q',
  S = 'S',
  ENTER = 'Enter'
}

export enum EDataType {
  text = 'upboard.text',
  numeric = 'upboard.numeric',
  hyperLink = 'upboard.hyperLink',
  smartLink = 'upboard.smartLink',
  user = 'upboard.user'
}
