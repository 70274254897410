import { getInitialOutputs } from 'helpers/workflowBuilder/outputsOperations'

const workflow = {
  workflowDefinition: {},
  workflowBlocks: [],
  workflowBlocksMap: {},
  outputs: getInitialOutputs(),
  outputsHistory: {},
  outputsNames: {},
  /*
   * Concept of outputs stoppers was entered with introduction of delete block:
   * in case an output is chosen as input in delete block it should become unavailable
   * for blocks which placed below this delete block.
   * So outputsStoppers declares key-value pair where key stands for output id
   * and value is id of block where this output was stopped.
   */
  outputsStoppers: {},

  invalidBlocksMap: {},
  isWorkflowMandatoryError: false,
  isValidationErrorShown: false,

  isAllChangesSaved: true,
  activeBlockId: -1,

  isWorkflowConfirmationShown: false,
  isWorkflowLoading: false,
  isWorkflowCreation: false
}

export default workflow
