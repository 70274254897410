import { useCallback } from 'react'
import AsyncSelect from 'react-select/async'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import messages from 'constants/messages'
import colors from 'scss/colors.module.scss'

import 'scss/common/multi-select.scss'
import 'scss/filters/conditionsModal/multi-search-field.scss'

const Option = ({ option }) => {
  const { boardName, cardUuid, name } = option

  const isError = !name

  const isCard = !isError && !!cardUuid
  const title = `${name}${isCard ? ` (${boardName})` : ''}`

  return (
    <div
      className={classNames('search-field-option', {
        'has-error': isError
      })}
      title={title}
    >
      <p className="search-item-name">
        {name || messages.DELETED}
        {isCard && <span className="search-item-attribute">{boardName}</span>}
      </p>
    </div>
  )
}

const colourStyles = {
  multiValue: (styles, { data }) => {
    const isInvalid = !data.name

    const invalidStyles = {
      backgroundColor: `${colors.grey100} !important`,
      borderColor: `${colors.red500} !important`
    }

    if (isInvalid) {
      return {
        ...styles,
        ...invalidStyles
      }
    }

    return styles
  },
  multiValueRemove: (styles, { data }) => {
    const isInvalid = !data.name

    if (isInvalid) {
      return {
        ...styles,
        color: `${colors.red500} !important`
      }
    }

    return styles
  }
}

const getOptionValue = ({ tenantId, boardId, cardUuid }) =>
  `${tenantId}/${boardId}${cardUuid ? `/${cardUuid}` : ''}`

const MultiSearchField = ({
  values,
  placeholder,
  onChange: propsOnChange,
  onClick,
  loadSuggestion,
  error
}) => {
  // react-select v3 begin to set values to null
  // in case user clears all values in multiselect field
  const onChange = useCallback(
    (value, ...args) => propsOnChange(value || [], ...args),
    [propsOnChange]
  )

  const getFormattedLabel = useCallback(option => <Option option={option} />, [])

  return (
    <div className="multi-search-field-wrapper" onClick={onClick}>
      <AsyncSelect
        className={classNames('upboard-react-select', 'medium', 'multi-search-field', {
          'error-state': !!error
        })}
        classNamePrefix="upboard-rs"
        placeholder={placeholder}
        value={values}
        loadOptions={loadSuggestion}
        formatOptionLabel={getFormattedLabel}
        getOptionValue={getOptionValue}
        getOptionLabel={option => option.name}
        styles={colourStyles}
        isClearable
        isMulti
        defaultOptions
        onChange={onChange}
      />
      {!!error && <span className="error-text">{error}</span>}
    </div>
  )
}

MultiSearchField.propTypes = {
  values: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  placeholder: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  loadSuggestion: PropTypes.func
}

MultiSearchField.defaultProps = {
  values: null,
  placeholder: '',
  error: '',
  onChange: null,
  loadSuggestion: null
}

export default MultiSearchField
