import { getVariables, getVariableConnections } from 'api/bindVariablesAPI'
import { duplicateVariable as duplicateVariableAPI } from 'api/variablesAPI'

import {
  TOGGLE_VARIABLE_CONFIGURATION,
  TOGGLE_VARIABLE_DELETE_CONFIGURATION,
  TOGGLE_VARIABLES_FETCHING,
  CLEAR_VARIABLES,
  SET_VARIABLES,
  APPEND_VARIABLES
} from 'constants/actionTypes'

export const toggleVariableConfiguration = payload => ({
  type: TOGGLE_VARIABLE_CONFIGURATION,
  payload
})

export const toggleVariableDeleteConfirmation = payload => ({
  type: TOGGLE_VARIABLE_DELETE_CONFIGURATION,
  payload
})

export const toggleVariablesFetching = payload => ({
  type: TOGGLE_VARIABLES_FETCHING,
  payload
})

export const clearVariables = () => ({ type: CLEAR_VARIABLES })

export const setVariables = payload => ({
  type: SET_VARIABLES,
  payload
})

export const appendVariables = payload => ({
  type: APPEND_VARIABLES,
  payload
})

export const fetchVariables = payload => async dispatch => {
  dispatch(toggleVariablesFetching(true))

  try {
    if (!payload.after) {
      dispatch(clearVariables())
    }

    const { variables, pageInfo, filterTotal, total } = await dispatch(getVariables(payload))

    // Append variables if cursor to next page exists, otherwise set them
    if (payload.after) {
      dispatch(appendVariables(variables))
    } else {
      dispatch(setVariables(variables))
    }

    dispatch(toggleVariablesFetching(false))

    return {
      variables,
      nextCursor: pageInfo.endCursor,
      filterTotal,
      total
    }
  } catch (err) {
    dispatch(toggleVariablesFetching(false))

    return Promise.reject(err)
  }
}

export const duplicateVariable = payload => async dispatch => {
  dispatch(toggleVariablesFetching(true))
  try {
    await duplicateVariableAPI(payload)
    dispatch(toggleVariablesFetching(false))
    return Promise.resolve()
  } catch (err) {
    dispatch(toggleVariablesFetching(false))
    return Promise.reject(err)
  }
}

export const fetchVariableConnections = payload => dispatch =>
  dispatch(getVariableConnections(payload))
