import React from 'react'
import UserLogo from 'components/common/UserLogo'
import messages from 'constants/messages'

const UserRepresentation = props => (
  <div
    className="user-representation extra-small"
    title={props.title}
    style={{ justifyContent: props.justifyContent }}
  >
    <UserLogo avatarImage={props.avatarImage} username={props.username} size={props.size} />
    <div className="user-data-wrapper">
      <p className="name bold" style={{ ...props.fontStyle }}>
        {props.username ? props.username : `[${messages.REMOVED_USER}]`}
      </p>
      {props.current && <span className="current">{`(${messages.YOU})`}</span>}
      {props.email && <p className="email">{props.email}</p>}
    </div>
  </div>
)

export default UserRepresentation
