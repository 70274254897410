import { type ReactNode } from 'react'
import MUIClickAwayListener, { type ClickAwayListenerProps } from '@mui/material/ClickAwayListener'
import MUIFade from '@mui/material/Fade'
import MUIPaper from '@mui/material/Paper'
import MUIPopper, { type PopperPlacementType, type PopperProps } from '@mui/material/Popper'
import classNames from 'classnames'
import './popper.scss'

const DEFAULT_MODIFIERS = [{ name: 'offset', options: { offset: [0, 4] } }]

type TPopperChildren = (props: {
  placement: PopperPlacementType
  TransitionProps?: {
    in: boolean
    onEnter: () => void
    onExited: () => void
  }
}) => ReactNode

export type TPopperProps = Omit<PopperProps, 'children'> & {
  children: TPopperChildren | ClickAwayListenerProps['children']
  onClose?: ClickAwayListenerProps['onClickAway']
}

// Use only if you don't want the dropdown component to cover the entire app with a backdrop.
export const Popper = ({
  className,
  onClose,
  children,
  modifiers = DEFAULT_MODIFIERS,
  ...props
}: TPopperProps) => {
  const isChildrenRenderFunc = typeof children === 'function'

  return (
    <MUIPopper
      className={classNames('praxie-popper', className)}
      modifiers={modifiers}
      transition
      {...props}
    >
      {isChildrenRenderFunc
        ? children
        : ({ TransitionProps }) => (
            <MUIFade {...TransitionProps} timeout={350}>
              <MUIPaper className="praxie-popper-paper">
                {onClose ? (
                  <MUIClickAwayListener onClickAway={onClose}>{children}</MUIClickAwayListener>
                ) : (
                  children
                )}
              </MUIPaper>
            </MUIFade>
          )}
    </MUIPopper>
  )
}
