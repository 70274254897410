import gql from 'graphql-tag'

const variableRuleFragmentName = 'VariableRuleFragment'

export const VariableRuleFragment = {
  name: variableRuleFragmentName,
  fragment: gql`
        fragment ${variableRuleFragmentName} on VariableRule {
            object
            predicate
            value
        }
    `
}

export default VariableRuleFragment
