import { EDropdownOptionsSource } from './dropdownContentSetup.types'

export const DROPDOWN_OPTIONS_SOURCES = {
  [EDropdownOptionsSource.manual]: {
    label: 'Manual',
    value: EDropdownOptionsSource.manual
  },
  [EDropdownOptionsSource.list]: {
    label: 'List',
    value: EDropdownOptionsSource.list
  },
  [EDropdownOptionsSource.query]: {
    label: 'Query from this card',
    value: EDropdownOptionsSource.query
  }
}

export const DROPDOWN_OPTIONS_SOURCES_LIST = Object.values(DROPDOWN_OPTIONS_SOURCES)
