import messages from 'constants/messages'
import {
  type IDictionaryData,
  type TDictionaryError,
  type TOption
} from './dictionariesSettings.types'

export const validateDictionaryData = ({
  name,
  options,
  hint
}: {
  name: string
  options: TOption[]
  hint: IDictionaryData['hint']
}) => {
  let isNameError = false
  let isHintError = false
  let isValid = true

  if (!name.trim()) {
    isNameError = true
    isValid = false
  }

  const { optionsError, hasDuplicatedOptions } = options.reduce<{
    optionsValues: string[]
    optionsError: boolean[]
    hasDuplicatedOptions: boolean
  }>(
    (acc, option) => {
      const isDuplicated = acc.optionsValues.includes(option.value)

      acc.optionsValues.push(option.value)
      acc.hasDuplicatedOptions = isDuplicated ? true : acc.hasDuplicatedOptions

      if (!option.value.trim() || isDuplicated) {
        acc.optionsError.push(true)
        isValid = false
      } else {
        acc.optionsError.push(false)
      }

      return acc
    },
    {
      optionsValues: [],
      optionsError: [],
      hasDuplicatedOptions: false
    }
  )

  if (hint?.flag && !hint.textMessage.trim().length) {
    isHintError = true
    isValid = false
  }

  return {
    errors: {
      options: optionsError,
      name: isNameError,
      hintMessage: isHintError,
      hasDuplicatedOptions
    } as TDictionaryError,
    isValid
  }
}

export const parseServerErrorsToMessage = (err: { message: string }) => {
  if (err.message.includes('name is not unique')) {
    return messages.DICTIONARY_NAMES_DUPLICATES
  }

  if (err.message.includes('name has invalid format')) {
    return messages.DICTIONARY_NAME_RESTRICTION_MESSAGE
  }

  if (err.message.includes('queryWidgetQueryId')) {
    return messages.DICTIONARY_QUERY_RESTRICTION_MESSAGE
  }

  return ''
}
