import { type KeyboardEvent, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import { DateTime } from 'luxon'
import CalendarIcon from 'assets/images/icons/calendar.svg?react'
import { DateService } from 'services/common/date.service.ts'
import { Calendar } from 'components/common/calendar/calendar'
import { Menu } from 'components/common/menu/menu'
import { getFormattedDatePredicateValue } from 'features/filter/filterRuleConditions/filterRuleConditions.helpers'
import './date-field.scss'

type TProps = {
  className?: string
  value: number | undefined
  onChange: (value: number) => void
  onFocus: () => void
  onBlur: () => void
}

export const DateField = ({ className, value, onChange, onFocus, onBlur }: TProps) => {
  const anchorEl = useRef<HTMLDivElement>(null)

  const [isOpen, setIsOpen] = useState(false)

  const date = useMemo(() => {
    if (!value) return null

    return DateTime.fromMillis(value).toJSDate()
  }, [value])

  const openCalendar = () => {
    setIsOpen(true)
  }

  const closeCalendar = () => {
    setIsOpen(false)
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.stopPropagation()

      openCalendar()
    }
  }

  const handleDateChange = (date: Date) => {
    closeCalendar()

    onChange(
      DateService.getStartOfDayUTCDate(DateTime.fromJSDate(date), {
        keepLocalTime: true
      }).toMillis()
    )
  }

  return (
    <div
      ref={anchorEl}
      className={classNames('filter-rule-conditions-date-field', className, {
        open: isOpen,
        empty: !date
      })}
      tabIndex={0}
      onClick={openCalendar}
      onKeyDown={handleKeyDown}
      onBlur={onBlur}
      onFocus={onFocus}
    >
      <span className="date">{value ? getFormattedDatePredicateValue(value) : 'Select date'}</span>
      <CalendarIcon className="icon" />
      <Menu
        className="filter-rule-conditions-date-field-calendar-menu"
        open={isOpen}
        anchorEl={anchorEl.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        disableAutoFocus
        onClick={e => e.stopPropagation()}
        onKeyDown={e => e.stopPropagation()}
        onClose={closeCalendar}
      >
        <Calendar date={date} onChange={handleDateChange} />
      </Menu>
    </div>
  )
}
