import type { CallOptions } from 'nice-grpc-common'
import { GrpcService } from 'services/grpc.service'
import {
  type CardCommentsServiceClient,
  type Comment,
  type GetRequest,
  type ListenRequest,
  CardCommentsServiceDefinition,
  QueryRequest,
  CreateRequest,
  DeleteRequest,
  UpdateRequest,
  CountRequest
} from '_proto/comments/v1/comments.pb'

export class _CardCommentsGrpcService {
  private _client: CardCommentsServiceClient = GrpcService.createClient(
    CardCommentsServiceDefinition
  )

  addedComments = new Set<string>()
  updatedComments = new Set<string>()
  deletedComments = new Set<string>()

  parseComment = (comment: Comment) => {
    const key = comment.key
    const scope = key?.scope

    return {
      commentId: key?.id,
      tenantId: scope?.tenantId,
      boardId: scope?.boardId,
      cardUuid: scope?.cardId,
      authorUserId: comment.authorId,
      parentCommentId: comment.parentId,
      text: comment.text,
      date: key?.createdAt,
      mentionedUsersIds: comment.mentions,
      createdByWorkflow: comment.createdByWorkflow,
      isWorkspaceUser: comment.isWorkspaceUser
    }
  }

  fetchComment = async (config: { request: GetRequest }) => {
    return this._client.get(config.request)
  }

  fetchCommentList = async (config: { request: QueryRequest }) => {
    return this._client.query(config.request)
  }

  fetchCommentCount = async (config: { request: CountRequest }) => {
    return this._client.count(config.request)
  }

  createComments = async (config: { request: CreateRequest }) => {
    return this._client.create(config.request)
  }

  updateComments = async (config: { request: UpdateRequest }) => {
    return this._client.update(config.request)
  }

  deleteComments = async (config: { request: DeleteRequest }) => {
    return this._client.delete(config.request)
  }

  listenForChanges = (config: { request: ListenRequest; options?: CallOptions }) => {
    return this._client.listen(config.request, config.options)
  }
}

export const CardCommentsGrpcService = new _CardCommentsGrpcService()
