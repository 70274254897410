import { Component } from 'react'
import PropTypes from 'prop-types'
import interact from 'interactjs'
import classNames from 'classnames'
import messages from 'constants/messages'
import {
  DEFAULT_CARD_HEIGHT,
  updateWidgetPositionWithSection,
  moveWidgetInsideAuthoringArea,
  isWidgetIntersectsWithHiddenSections,
  getWidgetDeltaAuthoringArea
} from 'helpers/builderHelpers'
import { isFileManager } from 'helpers/widget/widgetDataHelpers'
import shallowEqual from 'helpers/shallowEqual'
import Interactive from 'components/common/Interactable'
import WidgetItem from 'components/widgets/WidgetItem'
import '../../scss/widget.scss'

const backendURL = import.meta.env.VITE_BACKEND_URL

export const url = `${backendURL}api`

const initialMultiSelectDimension = {
  wrapperTop: 0,
  wrapperLeft: 0,
  wrapperWidth: 20,
  wrapperHeight: 20,
  section: 0
}

const restrictLeavingAuthoringArea = ({ targetWidth, targetHeight, x, y, section }) => {
  const cardContent = document.getElementById('card-content-area')
  const cardRect = cardContent.getBoundingClientRect()
  const cardRight = cardRect.x + cardRect.width
  const cardBottom = cardRect.y + cardRect.height
  const cardLeft = cardRect.x
  const cardTop = cardRect.y
  const sectionOffset = section * DEFAULT_CARD_HEIGHT
  return (
    sectionOffset + y < 0 ||
    y + targetHeight > cardBottom - cardTop ||
    x < 0 ||
    x + targetWidth > cardRight - cardLeft
  )
}

const getCardAuthoring = () => document.querySelector('.card-authoring')

const getSelectedWidgetList = () => {
  const cardAuthoring = getCardAuthoring()
  return cardAuthoring.querySelectorAll('.selected-state')
}

const resizeMultiSelectRect = (target, event) => {
  if (!event.shiftKey) {
    const x = parseFloat(target.getAttribute('data-x')) || 0
    const y = parseFloat(target.getAttribute('data-y')) || 0
    const targetY = y + event.deltaRect.top
    const targetX = x + event.deltaRect.left
    // update the element's style

    const objectBoundaries = {
      targetWidth: event.rect.width,
      targetHeight: event.rect.height,
      x: targetX,
      y: targetY
    }
    if (restrictLeavingAuthoringArea(objectBoundaries)) {
      return
    }
    target.style.top = `${targetY}px`
    target.style.left = `${targetX}px`
    target.setAttribute('data-x', targetX)
    target.setAttribute('data-y', targetY)
    const percentOfWidthChange = (event.rect.width * 100) / parseFloat(target.style.width)
    const percentOfHeightChange = (event.rect.height * 100) / parseFloat(target.style.height)
    target.style.width = `${event.rect.width}px`
    target.style.height = `${event.rect.height}px`
    const selectedWidgetList = getSelectedWidgetList()
    for (let k = 0; k < selectedWidgetList.length; k += 1) {
      const sectionOffset =
        parseFloat(selectedWidgetList[k].getAttribute('data-section')) * DEFAULT_CARD_HEIGHT
      const widgetTop =
        targetY +
        ((parseFloat(selectedWidgetList[k].getAttribute('data-y')) + sectionOffset - y) *
          percentOfHeightChange) /
          100
      selectedWidgetList[k].style.top = `${widgetTop - sectionOffset}px`
      const widgetLeft =
        targetX +
        ((parseFloat(selectedWidgetList[k].getAttribute('data-x')) - x) * percentOfWidthChange) /
          100
      selectedWidgetList[k].style.left = `${widgetLeft}px`
      selectedWidgetList[k].setAttribute('data-x', selectedWidgetList[k].style.left)
      selectedWidgetList[k].setAttribute('data-y', selectedWidgetList[k].style.top)
      selectedWidgetList[k].style.width = `${
        (parseFloat(selectedWidgetList[k].style.width) * percentOfWidthChange) / 100
      }px`
      selectedWidgetList[k].style.height = `${
        (parseFloat(selectedWidgetList[k].style.height) * percentOfHeightChange) / 100
      }px`
    }
  } else {
    const x = parseFloat(target.getAttribute('data-x')) || 0
    const y = parseFloat(target.getAttribute('data-y')) || 0
    const origWidth = target.offsetWidth
    const origHeight = target.offsetHeight
    // coeff for saving aspect ratio
    const resizeStartAspectRatio = origWidth / origHeight
    const { edges } = event
    const { deltaRect } = event

    // delta values
    let dWidth = deltaRect.width
    let { dx } = event
    let dHeight = deltaRect.height
    let { dy } = event
    if ((edges.left && edges.bottom) || (edges.right && edges.top)) {
      dy = -dx / resizeStartAspectRatio
      dHeight = dWidth / resizeStartAspectRatio
    } else if (edges.left || edges.right) {
      dy = dx / resizeStartAspectRatio
      dHeight = dWidth / resizeStartAspectRatio
    } else if (edges.top || edges.bottom) {
      dx = dy * resizeStartAspectRatio
      dWidth = dHeight * resizeStartAspectRatio
    }

    const targetWidth = origWidth + dWidth
    const targetHeight = origHeight + dHeight
    let targetY = y
    let targetX = x
    const objectBoundaries = {
      targetWidth,
      targetHeight,
      x: edges.left ? targetX + dx : targetX,
      y: edges.top ? targetY + dy : targetY
    }
    if (restrictLeavingAuthoringArea(objectBoundaries)) {
      return
    }
    if (edges.top) {
      targetY += dy
      target.style.top = `${targetY}px`
      target.setAttribute('data-y', targetY)
    }
    if (edges.left) {
      targetX += dx
      target.style.left = `${targetX}px`
      target.setAttribute('data-x', targetX)
    }
    const percentOfWidthChange = (targetWidth * 100) / parseFloat(target.style.width)
    const percentOfHeightChange = (targetHeight * 100) / parseFloat(target.style.height)
    target.style.width = `${targetWidth}px`
    target.style.height = `${targetHeight}px`
    const selectedWidgetList = getSelectedWidgetList()
    for (let k = 0; k < selectedWidgetList.length; k += 1) {
      const origWidgetWidth = parseFloat(selectedWidgetList[k].style.width)
      const origWidgetHeight = parseFloat(selectedWidgetList[k].style.height)
      const targetWidgetWidth = (origWidgetWidth * percentOfWidthChange) / 100
      const targetWidgetHeight = (origWidgetHeight * percentOfHeightChange) / 100
      selectedWidgetList[k].style.width = `${targetWidgetWidth}px`
      selectedWidgetList[k].style.height = `${targetWidgetHeight}px`
      const widgetTop =
        targetY +
        ((parseFloat(selectedWidgetList[k].getAttribute('data-y')) - y) * percentOfHeightChange) /
          100
      selectedWidgetList[k].style.top = `${widgetTop}px`
      selectedWidgetList[k].setAttribute('data-y', selectedWidgetList[k].style.top)
      const widgetLeft =
        targetX +
        ((parseFloat(selectedWidgetList[k].getAttribute('data-x')) - x) * percentOfWidthChange) /
          100
      selectedWidgetList[k].style.left = `${widgetLeft}px`
      selectedWidgetList[k].setAttribute('data-x', selectedWidgetList[k].style.left)
    }
  }
}

const resizeDots = [
  'top-resize',
  'top-left-resize',
  'top-right-resize',
  'bottom-left-resize',
  'bottom-right-resize',
  'right-resize',
  'bottom-resize',
  'left-resize'
]

class WidgetContainer extends Component {
  renderedWidgets = []

  constructor(props) {
    super(props)
    this.state = {
      widgets: this.props.widgets,
      resizableOptions: {
        edges: {
          // selectors for resizable elements
          left: '.left-resize,.top-left-resize,.bottom-left-resize',
          right: '.right-resize,.top-right-resize,.bottom-right-resize',
          bottom: '.bottom-resize,.bottom-right-resize,.bottom-left-resize',
          top: '.top-resize,.top-right-resize,.top-left-resize'
        },
        onmove: event => {
          const { target } = event
          resizeMultiSelectRect(target, event)
        },
        onend: () => {
          this.endResizeMultiSelectRect()
        },
        onstart: event => {
          // prevent click event on authoring area
          event.stopPropagation()
        },
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: '.card-content',
            endOnly: true
          })
        ]
      },
      draggableOptions: {
        onmove: event => {
          const { target } = event
          this.moveMultiSelectRect(target, event)
        },
        onend: () => {
          this.endMoveMultiSelectRect()
        },
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: '.card-content',
            endOnly: true,
            elementRect: { top: 0, left: 0, bottom: 1, right: 1 }
          })
        ]
      },
      initialMultiSelectDimension: {}
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!shallowEqual(this.props, nextProps) || !shallowEqual(this.state, nextState)) {
      return !window.isDragging
    }
    return false
  }

  onMultiSelectMoveStart() {
    // save start multiselect position for constrain movement
    this.setState({
      initialMultiSelectDimension: this.getRectCoords(this.props.selectedWidgets)
    })
  }

  getRectCoords(selectedWidgets) {
    const cardAuthoring = getCardAuthoring()
    const widgetWrapperParams = cardAuthoring.querySelector('.card-content').getBoundingClientRect()
    const widgetsList = selectedWidgets
      .map(id => cardAuthoring.querySelector(`#id_${id}`))
      .filter(item => !!item) // to exclude empty values
    if (!widgetsList.length) {
      return initialMultiSelectDimension
    }
    const cordsXArray = []
    const cordsYArray = []
    for (let i = 0; i < widgetsList.length; i += 1) {
      const selectedWidgetParams = widgetsList[i].getBoundingClientRect()
      cordsXArray.push(selectedWidgetParams.left - widgetWrapperParams.left)
      cordsXArray.push(selectedWidgetParams.right - widgetWrapperParams.left)
      cordsYArray.push(selectedWidgetParams.top - widgetWrapperParams.top)
      cordsYArray.push(selectedWidgetParams.bottom - widgetWrapperParams.top)
    }

    const maxX = cordsXArray.reduce((a, b) => Math.max(a, b))
    const minX = cordsXArray.reduce((a, b) => Math.min(a, b))
    const maxY = cordsYArray.reduce((a, b) => Math.max(a, b))
    const minY = cordsYArray.reduce((a, b) => Math.min(a, b))

    return {
      wrapperTop: minY - 2,
      wrapperLeft: minX - 2,
      wrapperWidth: maxX - minX + 4,
      wrapperHeight: maxY - minY + 4
    }
  }

  updateWidgetsPosition = widgetsData => {
    // method is needed to update section for grouped files
    // is corresonding file manager was moved
    const widgetsToUpdate = widgetsData.reduce((acc, widget) => {
      acc.push(widget)
      if (isFileManager(widget)) {
        const groupedFiles = this.props
          .getGroupedFiles(widget.uuid)
          .map(file => ({ ...file, section: widget.section }))
        acc.push(...groupedFiles)
      }
      return acc
    }, [])
    this.props.updateWidget(widgetsToUpdate)
  }

  endMoveMultiSelectRect() {
    const selectedWidgetList = getSelectedWidgetList()
    const widgetsToSave = []
    let disableMove = false
    for (let k = 0; k < selectedWidgetList.length; k += 1) {
      const widgetItemParams = this.props.getWidgetsDataByIds([
        selectedWidgetList[k].id.split('_')[1]
      ])[0]
      const { y, section } = updateWidgetPositionWithSection({
        origY: parseInt(selectedWidgetList[k].style.top, 10),
        origSection: parseInt(selectedWidgetList[k].getAttribute('data-section'), 10) || 0
      })
      const x = parseInt(selectedWidgetList[k].style.left, 10)
      if (
        this.isWidgetIntersectsWithHiddenSections({
          x,
          y: y + section * DEFAULT_CARD_HEIGHT,
          width: selectedWidgetList[k].offsetWidth,
          height: selectedWidgetList[k].offsetHeight,
          transform: selectedWidgetList[k].style.transform
        })
      ) {
        disableMove = true
        break
      }

      const updatedWidgetParams = moveWidgetInsideAuthoringArea({
        x,
        y,
        section,
        widget: selectedWidgetList[k],
        widgetParams: widgetItemParams
      })

      widgetsToSave.push(updatedWidgetParams)
    }
    if (disableMove) {
      this.returnWidgetsToOriginal(selectedWidgetList)
      this.props.showToastMessage({
        text: messages.ACTION_IS_UNAVAILABLE,
        size: 'M'
      })
    } else {
      this.updateWidgetsPosition(widgetsToSave)
    }

    this.setState({
      initialMultiSelectDimension: {}
    })
  }

  resolveConstrainMovement({ event, widgetData, restrictedDirection }) {
    const { target } = event
    const { direction, dx, dy } = restrictedDirection
    const moveVertically = Math.abs(dy) > Math.abs(dx)
    const moveHorizontally = Math.abs(dx) > Math.abs(dy)
    const { positionX, positionY } = widgetData
    if (moveVertically && !direction) {
      // if shift was pressed in the first time
      // and widget should be moved vertically
      this.props.setWidgetPosition(target, positionX, positionY + dy)
      return 'V'
    }
    if (moveHorizontally && !direction) {
      // if shift was pressed in the first time
      // and widget should be moved horizontally
      this.props.setWidgetPosition(target, positionX + dx, positionY)
      return 'H'
    }
    if (moveVertically && direction === 'H') {
      // widget should be moved vertically
      // and it was moved horizontally before
      this.props.setWidgetPosition(target, positionX, positionY)
      this.props.moveWidgetRect(target, 0, dy)
      return 'V'
    }
    if (moveVertically) {
      // widget should be moved vertically
      this.props.moveWidgetRect(target, 0, event.dy)
      return 'V'
    }
    if (moveHorizontally && direction === 'V') {
      // widget should be moved horizontally
      // and it was moved vertically before
      this.props.setWidgetPosition(target, positionX, positionY)
      this.props.moveWidgetRect(target, dx, 0)
      return 'H'
    }
    if (moveHorizontally) {
      // widget should be moved horizontally
      this.props.moveWidgetRect(target, event.dx, 0)
      return 'H'
    }
    return restrictedDirection.direction
  }

  moveMultiSelectRect(target, event, dx, dy, restrictedDirection = {}) {
    // if mouse dragging or arrows dragging
    if (!event.shiftKey || event.keyCode) {
      const deltaX = !isNaN(dx) ? dx : event.dx
      const deltaY = !isNaN(dy) ? dy : event.dy
      // keep the dragged position in the data-x/data-y attributes
      const x = (parseFloat(target.getAttribute('data-x')) || 0) + deltaX
      const y = (parseFloat(target.getAttribute('data-y')) || 0) + deltaY
      // translate the element
      target.style.top = `${y}px`
      target.style.left = `${x}px`
      // update the posiion attributes
      target.setAttribute('data-x', x)
      target.setAttribute('data-y', y)
      const selectedWidgetList = getSelectedWidgetList()
      for (let k = 0; k < selectedWidgetList.length; k += 1) {
        selectedWidgetList[k].style.top = `${
          parseFloat(selectedWidgetList[k].getAttribute('data-y')) + deltaY
        }px`
        selectedWidgetList[k].style.left = `${
          parseFloat(selectedWidgetList[k].getAttribute('data-x')) + deltaX
        }px`
        selectedWidgetList[k].setAttribute('data-x', selectedWidgetList[k].style.left)
        selectedWidgetList[k].setAttribute('data-y', selectedWidgetList[k].style.top)
      }
    } else {
      const selectedWidgetList = getSelectedWidgetList()
      let direction = this.resolveConstrainMovement({
        event: {
          dx: event.dx,
          dy: event.dy,
          target
        },
        widgetData: {
          positionX: this.state.initialMultiSelectDimension.wrapperLeft,
          positionY: this.state.initialMultiSelectDimension.wrapperTop
        },
        restrictedDirection
      })
      for (let k = 0; k < selectedWidgetList.length; k += 1) {
        const widgetData = this.props.getWidgetsDataByIds([
          selectedWidgetList[k].id.split('_')[1]
        ])[0]
        direction = this.resolveConstrainMovement({
          event: {
            dx: event.dx,
            dy: event.dy,
            target: selectedWidgetList[k]
          },
          widgetData,
          restrictedDirection
        })
      }
      restrictedDirection.direction = direction
    }
  }

  isWidgetIntersectsWithHiddenSections({ x, y, width, height, transform }) {
    const { hiddenSections } = this.props
    return isWidgetIntersectsWithHiddenSections({
      x,
      y,
      width,
      height,
      transform,
      hiddenSections
    })
  }

  returnWidgetsToOriginal(widgets) {
    widgets.forEach(widget => {
      const widgetItemParams = this.props.getWidgetsDataByIds([widget.id.split('_')[1]])[0]
      this.returnWidgetToOriginal(widget, widgetItemParams)
    })
  }

  returnWidgetToOriginal(target, widgetData) {
    const { positionY, positionX, section, height, width, rotation } = widgetData
    target.style.top = `${positionY}px`
    target.style.left = `${positionX}px`
    target.style.width = `${width}px`
    target.style.height = `${height}px`
    target.style.transform = rotation
    // update the posiion attributes
    target.setAttribute('data-x', positionX)
    target.setAttribute('data-y', positionY)
    target.setAttribute('data-section', section)
  }

  endResizeMultiSelectRect() {
    const selectedWidgetList = getSelectedWidgetList()
    const widgetsToSave = []
    let disableResize = false
    for (let k = 0; k < selectedWidgetList.length; k += 1) {
      const widgetItemParams = this.props.getWidgetsDataByIds([
        selectedWidgetList[k].id.split('_')[1]
      ])[0]

      const { y, section } = updateWidgetPositionWithSection({
        origY: parseFloat(selectedWidgetList[k].style.top) || 0,
        origSection: parseFloat(selectedWidgetList[k].getAttribute('data-section')) || 0
      })

      widgetItemParams.positionY = y
      widgetItemParams.section = section
      widgetItemParams.positionX = parseFloat(selectedWidgetList[k].style.left)
      widgetItemParams.width = parseFloat(selectedWidgetList[k].style.width)
      widgetItemParams.height = parseFloat(selectedWidgetList[k].style.height)
      if (
        this.isWidgetIntersectsWithHiddenSections({
          x: widgetItemParams.positionX,
          y: y + section * DEFAULT_CARD_HEIGHT,
          width: widgetItemParams.width,
          height: widgetItemParams.height,
          transform: selectedWidgetList[k].style.transform
        })
      ) {
        disableResize = true
        break
      }
      widgetsToSave.push(widgetItemParams)
    }
    if (disableResize) {
      this.returnWidgetsToOriginal(selectedWidgetList)
      this.props.showToastMessage({
        text: messages.ACTION_IS_UNAVAILABLE,
        size: 'M'
      })
    } else {
      this.updateWidgetsPosition(widgetsToSave)
    }
  }

  activateWidget(widgetID, isCtrl) {
    this.props.activateWidget(widgetID, isCtrl, this.props.section)
  }

  widgetRendered(widgetID) {
    const { widgets, afterWidgetsRendered } = this.props

    this.renderedWidgets.push(widgetID)
    if (widgets.length === this.renderedWidgets.length) {
      afterWidgetsRendered()
    }
  }

  render() {
    const {
      isRenderLazily,
      hasRibbonSupport,
      rotatingWidgetUuid,
      selectedWidgets,
      busy,
      mode,
      isOnMainBoard,
      isEditingDisabledOnDetailed,
      tenantId,
      boardId,
      cardUuid,
      section,
      toggleKeysListening,
      maxZIndex,
      getGroupedFiles,
      createWidgetsAfterFilesUploading,
      deleteWidget,
      widgets,
      isPDFGeneration,
      cardOwners,
      isRenderedOnSettingModal,
      cardData
    } = this.props
    const isMultiSelect = selectedWidgets && selectedWidgets.length > 1
    let multiSelectDimension = initialMultiSelectDimension
    if (isMultiSelect) {
      multiSelectDimension = this.getRectCoords(selectedWidgets)
    }

    return (
      <div
        className={classNames(
          'widget-list',
          isMultiSelect && 'widget-multiselect',
          busy && 'hidden'
        )}
      >
        {mode === 'edit' && isMultiSelect && section === 0 && (
          <Interactive
            resizable
            draggable
            resizableOptions={this.state.resizableOptions}
            draggableOptions={this.state.draggableOptions}
          >
            <div
              className="widget-multiselect-wrapper"
              style={{
                top: `${multiSelectDimension.wrapperTop}px`,
                left: `${multiSelectDimension.wrapperLeft}px`,
                width: `${multiSelectDimension.wrapperWidth}px`,
                height: `${multiSelectDimension.wrapperHeight}px`
              }}
              data-x={`${multiSelectDimension.wrapperLeft}px`}
              data-y={`${multiSelectDimension.wrapperTop}px`}
            >
              {resizeDots.map(dot => (
                <div key={dot} className={`widget-resizer ${dot}`} />
              ))}
            </div>
          </Interactive>
        )}
        {widgets.map(widget => (
          <WidgetItem
            // BE doesn't change widget uuid while card/board copying.
            key={`${widget.cardUuid}-${widget.uuid}`}
            hasRibbonSupport={hasRibbonSupport}
            setRotatingWidgetUuid={this.props.setRotatingWidgetUuid}
            cardContentMouseMove={this.props.cardContentMouseMove}
            tenantId={tenantId}
            boardId={boardId}
            cardUuid={cardUuid}
            cardOwners={cardOwners}
            widgetData={widget}
            cardData={cardData}
            updateWidget={this.props.updateWidget}
            updateWidgetsPosition={this.updateWidgetsPosition}
            mode={this.props.mode}
            activateWidget={this.activateWidget.bind(this, widget.uuid)}
            rotatingWidgetUuid={rotatingWidgetUuid}
            setGetSettingsComponentCallback={this.props.setGetSettingsComponentCallback}
            isMultiSelect={isMultiSelect}
            moveMultiSelectRect={this.moveMultiSelectRect.bind(this)}
            endMoveMultiSelectRect={this.endMoveMultiSelectRect.bind(this)}
            onMultiSelectMoveStart={this.onMultiSelectMoveStart.bind(this)}
            resizeMultiSelectRect={resizeMultiSelectRect}
            endResizeMultiSelectRect={this.endResizeMultiSelectRect.bind(this)}
            resolveConstrainMovement={this.resolveConstrainMovement.bind(this)}
            restrictLeavingAuthoringArea={restrictLeavingAuthoringArea}
            isThumbnailView={this.props.isThumbnailView}
            isRenderLazily={isRenderLazily}
            isSnapshotPreview={this.props.isSnapshotPreview}
            isPDFGeneration={isPDFGeneration}
            isOnMainBoard={isOnMainBoard}
            isEditingDisabledOnDetailed={isEditingDisabledOnDetailed}
            moveWidgetRect={this.props.moveWidgetRect}
            endMoveWidgetRect={this.props.endMoveWidgetRect}
            startMoveWidgetRect={this.props.startMoveWidgetRect}
            isWidgetIntersectsWithHiddenSections={this.isWidgetIntersectsWithHiddenSections.bind(
              this
            )}
            getWidgetDeltaAuthoringArea={getWidgetDeltaAuthoringArea}
            returnWidgetToOriginal={this.returnWidgetToOriginal.bind(this)}
            toggleKeysListening={toggleKeysListening}
            maxZIndex={maxZIndex}
            getGroupedFiles={getGroupedFiles}
            createWidgetsAfterFilesUploading={createWidgetsAfterFilesUploading}
            deleteWidget={deleteWidget}
            widgetRendered={this.widgetRendered.bind(this)}
            isRenderedOnSettingModal={isRenderedOnSettingModal}
          />
        ))}
      </div>
    )
  }
}

WidgetContainer.propTypes = {
  afterWidgetsRendered: PropTypes.func
}

WidgetContainer.defaultProps = {
  afterWidgetsRendered: () => null
}

export default WidgetContainer
