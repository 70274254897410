import gql from 'graphql-tag'

const variableAttributeFragmentName = 'VariableAttributeFragment'

export const VariableAttributeFragment = {
  name: variableAttributeFragmentName,
  fragment: gql`
        fragment ${variableAttributeFragmentName} on VariableAttribute {
            propertyName
            x
            y
        }
    `
}

export default VariableAttributeFragment
