/* eslint-disable import/no-cycle */
import io from 'socket.io-client'
import { disableSocketFallback } from '../actions'
import * as types from '../constants/actionTypes'
import {
  resolveSocketAction,
  resolveSocketConnection,
  resolveSocketDisconnection
} from '../actions/socketActions'

const backendUrl = import.meta.env.VITE_BACKEND_URL
let requestTimeout = null

const socket = io.connect(backendUrl, {
  autoConnect: false,
  path: '/api-socket',
  transports: ['websocket'],
  maxHttpBufferSize: 1e7,
  reconnection: true,
  reconnectionDelay: 500,
  reconnectionDelayMax: 1000,
  reconnectionAttempts: 99999,
  randomizationFactor: 0
})

export const getSocket = () => socket

export function emitAction(actionCreator) {
  return (...args) => {
    const result = actionCreator.apply(this, args)
    socket.emit(result.type, result.payload, result.callback)
    return result
  }
}

export const openSocketConnection = () => {
  console.info('open socket')
  socket.open()
}

export const closeSocketConnection = () => {
  console.info('disconnect socket')
  socket.disconnect()
}

export function registerListenersSocket(store) {
  socket.on('connect', () => {
    console.info('connected to server')
    resolveSocketConnection(store)
  })
  socket.on('disconnect', reason => {
    console.info('disconnected from server', reason)
    if (reason !== 'io client disconnect') {
      // Don't call /api/jobs request immediately after disconnect
      requestTimeout = setTimeout(() => {
        resolveSocketDisconnection(store)
        requestTimeout = null
      }, 5000)
    }
  })
  socket.on('connect_error', () => {
    console.info('connection error')
    resolveSocketDisconnection(store)
  })
  socket.on('reconnect_error', () => {
    console.info('try to reconnect - ERROR')
  })
  socket.on('reconnect', () => {
    console.info('reconnected to server')
    if (!requestTimeout) store.dispatch(disableSocketFallback())
    clearTimeout(requestTimeout)
  })
  socket.on('error', err => {
    console.error(err)
  })

  socket.on(types.CARD_CREATE, payload => {
    resolveSocketAction(store, payload, types.CARD_CREATE)
  })
  socket.on(types.CARD_UPDATE, payload => {
    resolveSocketAction(store, payload, types.CARD_UPDATE)
  })
  socket.on(types.CARD_REORDER, payload => {
    resolveSocketAction(store, payload, types.CARD_REORDER)
  })
  socket.on(types.CARD_SNAPSHOT_UPDATE, payload => {
    resolveSocketAction(store, payload, types.CARD_SNAPSHOT_UPDATE)
  })
  socket.on(types.CARD_DELETE, payload => {
    resolveSocketAction(store, payload, types.CARD_DELETE)
  })
  socket.on(types.COLUMN_CREATE, payload => {
    resolveSocketAction(store, payload, types.COLUMN_CREATE)
  })
  socket.on(types.COMMENT_CREATE, payload => {
    resolveSocketAction(store, payload, types.COMMENT_CREATE)
  })
  socket.on(types.COMMENT_UPDATE, payload => {
    resolveSocketAction(store, payload, types.COMMENT_UPDATE)
  })
  socket.on(types.COMMENT_DELETE, payload => {
    resolveSocketAction(store, payload, types.COMMENT_DELETE)
  })
  socket.on(types.BOARD_UPDATE, payload => {
    resolveSocketAction(store, payload, types.BOARD_UPDATE)
  })
  socket.on(types.TENANT_UPDATE, payload => {
    resolveSocketAction(store, payload, types.TENANT_UPDATE)
  })
  socket.on(types.ORGANIZATION_UPDATE, payload => {
    resolveSocketAction(store, payload, types.ORGANIZATION_UPDATE)
  })
  socket.on(types.BOARD_WATCHERS_UPDATE, payload => {
    resolveSocketAction(store, payload, types.BOARD_WATCHERS_UPDATE)
  })
  socket.on(types.CARD_START_EDIT, payload => {
    resolveSocketAction(store, payload, types.CARD_START_EDIT)
  })
  socket.on(types.CARD_END_EDIT, payload => {
    resolveSocketAction(store, payload, types.CARD_END_EDIT)
  })
  socket.on(types.WIDGET_UPDATE, payload => {
    resolveSocketAction(store, payload, types.WIDGET_UPDATE)
  })
  socket.on(types.WIDGET_DELETE, payload => {
    resolveSocketAction(store, payload, types.WIDGET_DELETE)
  })
  socket.on(types.BULK_WIDGETS_CREATE, payload => {
    resolveSocketAction(store, payload, types.BULK_WIDGETS_CREATE)
  })
  socket.on(types.JOB_COMPLETED, payload => {
    resolveSocketAction(store, payload, types.JOB_COMPLETED)
  })
  socket.on(types.BOARD_LOCKED, payload => {
    resolveSocketAction(store, payload, types.BOARD_LOCKED)
  })
  socket.on(types.BOARD_UNLOCKED, payload => {
    resolveSocketAction(store, payload, types.BOARD_UNLOCKED)
  })
  socket.on(types.MONITOR_UPDATE, payload => {
    resolveSocketAction(store, payload, types.MONITOR_UPDATE)
  })
  socket.on(types.APP_PUBLISH_FAILED, payload => {
    resolveSocketAction(store, payload, types.APP_PUBLISH_FAILED)
  })
  socket.on(types.APP_PUBLISH_COMPLETED, payload => {
    resolveSocketAction(store, payload, types.APP_PUBLISH_COMPLETED)
  })
}

export const getSubscriptionTarget = options => {
  const {
    organizationId,
    tenantId,
    boardId,
    cardUuid,
    widgetUuid,
    commentId,
    userId,
    jobId,
    monitorId,
    appId,
    versionId
  } = options

  const target = []
  if (userId) {
    target.push(`userId:${userId}`)
  }
  if (organizationId) {
    target.push(`organizationId:${organizationId}`)
  }
  if (tenantId) {
    target.push(`tenantId:${tenantId}`)
  }
  if (boardId) {
    target.push(`boardId:${boardId}`)
  }
  if (cardUuid) {
    target.push(`cardUuid:${cardUuid}`)
  }
  if (widgetUuid) {
    target.push(`widgetUuid:${widgetUuid}`)
  }
  if (commentId) {
    target.push(`commentId:${commentId}`)
  }
  if (jobId) {
    target.push(`jobId:${jobId}`)
  }
  if (monitorId) {
    target.push(`monitorId:${monitorId}`)
  }
  if (appId) {
    target.push(`appId:${appId}`)
  }
  if (versionId) {
    target.push(`appVersionId:${versionId}`)
  }
  return target.join('/')
}

export const getCardsSubscriptionPayload = ({ cards, tenantId, boardId }) => {
  const payload = []
  cards.forEach(card => {
    const cardUuid = card.uuid
    const cardTarget = getSubscriptionTarget({
      tenantId,
      boardId,
      cardUuid
    })
    payload.push({
      type: types.WIDGET_UPDATE,
      target: cardTarget
    })
    payload.push({
      type: types.BULK_WIDGETS_CREATE,
      target: cardTarget
    })
    payload.push({
      type: types.WIDGET_DELETE,
      target: cardTarget
    })

    payload.push({
      type: types.CARD_SNAPSHOT_UPDATE,
      target: cardTarget
    })

    if (card.isLinked && card.linkToSrcCard.boardId !== boardId) {
      const cardLinkTarget = getSubscriptionTarget(card.linkToSrcCard)
      payload.push({
        type: types.CARD_UPDATE,
        target: cardLinkTarget
      })
      payload.push({
        type: types.CARD_DELETE,
        target: cardLinkTarget
      })
      payload.push({
        type: types.WIDGET_UPDATE,
        target: cardLinkTarget
      })
      payload.push({
        type: types.BULK_WIDGETS_CREATE,
        target: cardLinkTarget
      })
      payload.push({
        type: types.WIDGET_DELETE,
        target: cardLinkTarget
      })
      payload.push({
        type: types.CARD_SNAPSHOT_UPDATE,
        target: cardLinkTarget
      })
    }
  })

  return payload
}
