export const INVITATION_MODAL_MODES = {
  CREATE: 'create',
  CREATE_FROM_BOARD: 'create_from_board',
  EDIT: 'edit'
}

export const aggregateBoardsByRole = boards => {
  const roles = []
  const res = []

  boards.forEach(board => {
    if (!roles[board.role]) {
      roles[board.role] = []
    }
    roles[board.role].push({
      label: board.name,
      value: board.boardId
    })
  })

  Object.keys(roles).forEach(key =>
    res.push({
      role: key,
      boards: roles[key]
    })
  )
  return res
}

export const prepareBoards = boards =>
  boards.map(board => ({
    label: board.name,
    value: board.boardId,
    role: board.role,
    application: board.appId
  }))

export const prepareApplications = applications =>
  applications.map(application => ({
    label: application.name,
    value: application.id
  }))

export const prepareMembers = members =>
  members.map(member => ({
    label: member,
    value: member
  }))
