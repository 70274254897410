import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTeamStore } from 'features/team/team.store'
import { sortTeamMemberList } from 'features/team/team.helpers'

const withBoardMembers = WrappedComponent => {
  const WithBoardMembers = props => {
    const { selectedUsers, ...rest } = props

    const filterList = useCallback(
      (list, search, { filter = [] } = {}) => {
        const predicates = []

        if (filter.includes('selectedUsers') && selectedUsers.length) {
          predicates.push(item => !selectedUsers.some(({ userId }) => item.userId === userId))
        }

        if (search) {
          const lowerCaseSearch = search.toLowerCase()
          predicates.push(
            item =>
              (item.username && item.username.toLowerCase().includes(lowerCaseSearch)) ||
              (item.email && item.email.toLowerCase().includes(lowerCaseSearch))
          )
        }

        return list.filter(item => predicates.every(predicate => predicate(item)))
      },
      [selectedUsers]
    )

    const teamMembers = useTeamStore.getState().getActiveMembers()

    const members = useMemo(() => sortTeamMemberList(teamMembers), [teamMembers])

    return (
      <WrappedComponent
        filterList={filterList}
        selectedUsers={selectedUsers}
        members={members}
        {...rest}
      />
    )
  }

  WithBoardMembers.propTypes = {
    tenantID: PropTypes.string.isRequired,
    selectedUsers: PropTypes.arrayOf(PropTypes.shape({ userId: PropTypes.string }))
  }

  WithBoardMembers.defaultProps = {
    selectedUsers: []
  }

  return WithBoardMembers
}

export default withBoardMembers
