import { type ChangeEventHandler, type FocusEventHandler } from 'react'
import classNames from 'classnames'
import { TextField } from 'components/common/textField/textField'
import {
  type IFilterRuleCondition,
  type IFilterRulesConfigAttributeParam,
  EFilterRuleConditionPredicate,
  EFilterRuleConditionPredicateParam
} from 'features/filter/filter.types'
import './predicate-params.scss'

type TParamsProps = {
  ruleCondition: IFilterRuleCondition
  params: Record<EFilterRuleConditionPredicateParam, IFilterRulesConfigAttributeParam>
  onChange: (param: IFilterRulesConfigAttributeParam['name'], value: number | string) => void
  onFocus: () => void
  onBlur: () => void
}

const MIN_COORDINATE_VALUE = 1
const MAX_COORDINATE_VALUE = 999

export const PredicateParams = ({
  ruleCondition,
  params,
  onChange,
  onFocus,
  onBlur
}: TParamsProps) => {
  const isCoordinates = ruleCondition.predicate === EFilterRuleConditionPredicate.cellCoordinates

  const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
    let value: string | number = event.target.value

    if (isCoordinates && value.length) {
      value = parseInt(value, 10)

      if (Number.isNaN(value)) return

      if (value > MAX_COORDINATE_VALUE) {
        value = MAX_COORDINATE_VALUE
      }
    }

    onChange(event.target.name, value)
  }

  const handleBlur: FocusEventHandler<HTMLInputElement> = event => {
    const value = event.target.value.trim()

    if (isCoordinates) {
      if (!value || parseInt(value, 10) < MIN_COORDINATE_VALUE) {
        onChange(event.target.name, MIN_COORDINATE_VALUE)
      }
    } else {
      onChange(event.target.name, value)
    }

    onBlur()
  }

  return (
    <div className="filter-rule-condition-attribute-params">
      {(Object.keys(params) as EFilterRuleConditionPredicateParam[]).map(param => (
        <TextField
          key={param}
          name={param}
          size={34}
          className={classNames('param-input', {
            'column-name': param === EFilterRuleConditionPredicateParam.columnName
          })}
          value={ruleCondition[param]}
          placeholder={params[param].name}
          onClick={event => event.stopPropagation()}
          onKeyDown={event => event.stopPropagation()}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={onFocus}
        />
      ))}
    </div>
  )
}
