/* eslint-disable import/no-cycle */
import { EntitlementStatus, QueryRequest } from '_proto/entitlement/v1/entitlement.pb'
import { UploadAvatarRequest, UploadAvatarRequestAvatarType } from '_proto/uploader/v1/uploader.pb'
import {
  fetchOrganizationCollections,
  getAuthenticatedOrganizations,
  getOrganizationPermissionsRequest,
  saveOrganizationProfile
} from 'api/organizationAPI'
import {
  getBoardMenuRequest,
  getHomePageConfigRequest,
  getJiraComponentsRequest,
  getJiraFiltersRequest,
  getJiraIssueTypesRequest,
  getJiraProjectsRequest,
  getJiraStatusesRequest,
  getJiraTestRequest,
  getJiraUsersRequest,
  getProfileNotificationsRequest,
  saveUserProfile
} from 'api/profileAPI'
import { getTeamSettingsRequest, getTenantPermissions, renameTeamRequest } from 'api/tenantAPI'

// eslint-disable-next-line import/no-namespace
import * as actions from 'constants/actionTypes'
import { IMPORT_BUNDLE_DROPZONE_ID } from 'constants/common'
import messages from 'constants/messages'
import { getRecentBoard } from 'helpers/boardMenuHelpers'
import { addTimestampToUrl } from 'helpers/fetchHelpers'
import dataURItoBlob from 'helpers/filesHelpers'
import { navigateToBoard, redirectToEmptyTenant, navToOpenedFrom } from 'helpers/routesHelpers'
import { openSocketConnection } from 'helpers/socketHelpers'
import { getActiveOrganization } from 'selectors/profile.selectors'
import { EntitlementsGrpcService } from 'features/entitlements/entitlements.grpc.service'
import { useTeamStore } from 'features/team/team.store'
import { EFileUploaderFileTypes, FileUploaderGrpcService } from 'services/fileUploader.service'
import { showToastMessage } from './boardActions'

// eslint-disable-next-line import/no-namespace
import * as spinnerActions from './spinnerActions'
import { updateCurrentTenant } from './teamAdministrationActions'
import { widgetClassesListReceive } from './widgetsActions'

export const updateBoardName = payload => {
  return { type: actions.UPDATE_BOARD_NAME, payload }
}

// update board menu when team name is changed
export function updateBoardMenuTeamName(payload) {
  return { type: actions.UPDATE_BOARD_MENU_TEAM_NAME, payload }
}

// update team settings
export function updateTeamSettings(payload) {
  return { type: actions.UPDATE_TEAM_SETTINGS, payload }
}

export function clearTeamSettings(payload) {
  return { type: actions.CLEAR_TEAM_SETTINGS, payload }
}

export function renameTeam(payload) {
  return (dispatch, getState) =>
    renameTeamRequest(payload)
      .then(({ data: { name, id: tenantId } }) => {
        const { teamSettings } = getState().profile

        if (teamSettings.key?.namespace === tenantId) {
          dispatch(updateTeamSettings({ name }))
        }
        dispatch(updateCurrentTenant({ name }))
        dispatch(updateBoardMenuTeamName({ name, tenantId }))
      })
      .catch(err => console.error(err))
}

export function getTeamSettings(payload) {
  return dispatch =>
    Promise.all([getTeamSettingsRequest(payload), getTenantPermissions(payload)])
      .then(async ([teamResponse, permissionsResponse]) => {
        const data = {
          ...teamResponse.data,
          teamPermissions: permissionsResponse.data.permissions,
          role: permissionsResponse.data.role
        }

        if (teamResponse.data.settings) {
          data.dateFormat = teamResponse.data.settings.dateFormat
        }
        dispatch(updateTeamSettings(data))
        dispatch(widgetClassesListReceive([]))
        useTeamStore.setState({ id: payload.tenantId })

        await useTeamStore.getState().fetchMembers()

        return teamResponse.data
      })
      .catch(err => console.error(err))
}

export function userReceive(payload) {
  return { type: actions.RECEIVE_USER, payload }
}

// Clear information about draft
export function clearDraft() {
  return { type: actions.CLEAR_DRAFT }
}

export function boardMenuRequestStart() {
  return { type: actions.REQUEST_BOARD_MENU }
}

export function boardMenuReceive(payload) {
  return { type: actions.RECEIVE_BOARD_MENU, payload }
}

export function homePageConfigRequestStart() {
  return { type: actions.REQUEST_HOME_PAGE_CONFIG }
}

export function homePageConfigReceive(payload) {
  return { type: actions.RECEIVE_HOME_PAGE_CONFIG, payload }
}

export function receiveHomePageConfigRequestError(payload) {
  return { type: actions.RECEIVE_HOME_PAGE_CONFIG_ERROR, payload }
}

export function receiveOrganizations(payload) {
  return { type: actions.RECEIVE_ORGANIZATIONS, payload }
}

export function clearOrganizations(payload) {
  return { type: actions.CLEAR_ORGANIZATIONS, payload }
}

export function receiveActiveOrganization(payload) {
  return { type: actions.RECEIVE_ACTIVE_ORGANIZATION, payload }
}

export function receiveBoardMenuError(payload) {
  return { type: actions.RECEIVE_BOARD_MENU_ERROR, payload }
}

export function boardMenuRequest(goToRecentBoard) {
  return dispatch => {
    dispatch(boardMenuRequestStart())
    return getBoardMenuRequest()
      .then(response => {
        const { organizations, tenants, boards, apps = [] } = response.data

        dispatch(boardMenuReceive({ tenants, boards, apps }))
        dispatch(receiveOrganizations({ organizations }))
        dispatch(spinnerActions.toggleTopMenuLoader(false))

        if (goToRecentBoard) {
          const recentBoards = getRecentBoard(boards)
          navigateToBoard({
            tenantId: recentBoards.tenantId,
            boardId: recentBoards.boardId
          })
        }
        return response.data
      })
      .catch(err => dispatch(receiveBoardMenuError(err)))
  }
}

export function homePageConfigRequest() {
  return async dispatch => {
    dispatch(homePageConfigRequestStart())
    try {
      const response = await getHomePageConfigRequest()
      dispatch(homePageConfigReceive(response))
    } catch (err) {
      dispatch(receiveHomePageConfigRequestError(err))
    }
  }
}

// go to recent board from team setting.
// In case no boards in this team - redirect to empty team page
export function goBackToBoard(fromTenantId) {
  return (dispatch, getState) => {
    const {
      boardMenu: { boards = [] }
    } = getState().profile

    const hasBoardInCurrentTenant = boards.some(board => board.tenantId === fromTenantId)

    if (navToOpenedFrom()) {
      return
    }

    if (hasBoardInCurrentTenant) {
      navigateToBoard({ isRecent: true })
    } else {
      redirectToEmptyTenant(fromTenantId)
    }
  }
}

// go to recent board from org setting.
// In case no boards in whole org - redirect to empty team page
export function goBackFromOrgSetting() {
  return (dispatch, getState) => {
    const {
      boardMenu: { boards = [], tenants }
    } = getState().profile

    if (navToOpenedFrom()) {
      return
    }

    if (boards.length) {
      navigateToBoard({ isRecent: true })
    } else {
      redirectToEmptyTenant(tenants[0] && tenants[0].tenantId)
    }
  }
}

export function toggleJiraConnectionModal(payload) {
  return { type: actions.TOGGLE_JIRA_CONNECTION_MODAL, payload }
}

export function saveUserProfileRequestStart() {
  return { type: actions.REQUEST_SAVE_USER_PROFILE }
}

export function saveUserProfileReceive(payload) {
  return { type: actions.RECEIVE_SAVE_USER_PROFILE, payload }
}

export function receiveSaveUserProfileErrorMessage(payload) {
  return { type: actions.RECEIVE_SAVE_USER_PROFILE_ERROR, payload }
}

export function saveUserProfileRequest(payload) {
  return async dispatch => {
    let avatar

    dispatch(spinnerActions.showModalWindowSpinner())
    dispatch(saveUserProfileRequestStart())
    const {
      data: { avatarImage, clearAvatar, ...restData }
    } = payload

    if (avatarImage || clearAvatar) {
      try {
        const file = avatarImage && dataURItoBlob(avatarImage, true)

        const request = UploadAvatarRequest.create({
          info: {
            name: file?.name || 'avatar',
            mimeType: file?.type || 'image/png'
          },
          userId: payload.userId,
          type: UploadAvatarRequestAvatarType.FOR_USER,
          data: file ? await FileUploaderGrpcService.convertFileToUint8Array(file) : undefined,
          clear: clearAvatar
        })

        await FileUploaderGrpcService.uploadAvatar({ request })

        avatar = clearAvatar
          ? null
          : FileUploaderGrpcService.getFileLink(EFileUploaderFileTypes.USER_AVATAR, {
              userID: payload.userId
            })
      } catch (e) {
        dispatch(
          showToastMessage({
            text: 'Error while user avatar uploading'
          })
        )
      }
    }

    try {
      const avatarData = avatar || clearAvatar ? { avatarImage: avatar } : {}
      const userPayload = {
        ...payload,
        data: {
          ...restData,
          ...avatarData
        }
      }

      const { data } = await saveUserProfile(userPayload)

      const localAvatarData =
        avatar || clearAvatar ? { avatarImage: avatar && addTimestampToUrl(avatar, true) } : {}
      const localUserPayload = {
        ...data,
        ...localAvatarData
      }

      dispatch(saveUserProfileReceive(localUserPayload))
      dispatch(boardMenuRequest())
    } catch (err) {
      dispatch(receiveSaveUserProfileErrorMessage(err))
      if (err.errorCode === 413) {
        dispatch(
          showToastMessage({
            text: err.message || messages.FILE_UPLOAD_ERROR
          })
        )
      }
    } finally {
      dispatch(spinnerActions.hideModalWindowSpinner())
    }
  }
}

export function updateUserRequest(payload) {
  return dispatch => {
    dispatch(saveUserProfileRequestStart())
    return saveUserProfile(payload)
      .then(response => {
        dispatch(saveUserProfileReceive(response.data))
      })
      .catch(err => dispatch(receiveSaveUserProfileErrorMessage(err)))
  }
}

export const updateUserColorsSet = payload => {
  return dispatch => {
    const {
      data: { colorsSet }
    } = payload

    dispatch(saveUserProfileRequestStart())

    return saveUserProfile(payload)
      .then(() => dispatch(saveUserProfileReceive({ colorsSet })))
      .catch(err => dispatch(receiveSaveUserProfileErrorMessage(err)))
  }
}

export const getIsFirstVisitHomepage =
  ({ userId, tenantId, openedItems }) =>
  dispatch => {
    if (!openedItems?.tenants?.length) {
      const updateOpenedItems = _tenantId => {
        const newTenantSection = {
          id: _tenantId,
          isAppsSectionOpen: true,
          isBoardsSectionOpen: false
        }
        const data = { openedItems: { tenants: [newTenantSection] } }

        dispatch(saveUserProfileReceive({ userId, ...data }))
        saveUserProfile({ userId, data })
      }

      updateOpenedItems(tenantId)
    }
  }

export function getUserJiraCredentials(organizationId) {
  const str = localStorage.getItem('jiraCredentials')
  const jiraCredentials = str ? JSON.parse(str) : {}
  return {
    type: actions.GET_USER_JIRA_CREDENTIALS,
    payload: jiraCredentials[organizationId] || {}
  }
}

export function setUserJiraCredentials(key, value, organizationId) {
  const str = localStorage.getItem('jiraCredentials')
  const jiraCredentials = str ? JSON.parse(str) : {}
  if (!jiraCredentials[organizationId]) {
    jiraCredentials[organizationId] = {}
  }
  jiraCredentials[organizationId][key] = value
  localStorage.setItem('jiraCredentials', JSON.stringify(jiraCredentials))
  return { type: actions.GET_USER_JIRA_CREDENTIALS, payload: jiraCredentials }
}

export function jiraProjectsRequestStart() {
  return { type: actions.JIRA_PROJECTS_REQUEST }
}

export function jiraProjectsReceive(payload) {
  return { type: actions.JIRA_PROJECTS_RECEIVE, payload }
}

export function jiraProjectsReceiveError(payload) {
  return { type: actions.JIRA_PROJECTS_RECEIVE_ERROR, payload }
}

export function getJiraProjects(payload) {
  return dispatch => {
    dispatch(jiraProjectsRequestStart())
    return getJiraProjectsRequest(payload)
      .then(response => {
        dispatch(jiraProjectsReceive(response.data))
        return response.data
      })
      .catch(err => {
        dispatch(jiraProjectsReceiveError(err))
        return err
      })
  }
}

export function jiraIssueTypesRequestStart() {
  return { type: actions.JIRA_ISSUE_TYPES_REQUEST }
}

export function jiraIssueTypesReceive(payload) {
  return { type: actions.JIRA_ISSUE_TYPES_RECEIVE, payload }
}

export function jiraIssueTypesReceiveError(payload) {
  return { type: actions.JIRA_ISSUE_TYPES_RECEIVE_ERROR, payload }
}

export function getJiraIssueTypes(payload) {
  return dispatch => {
    dispatch(jiraIssueTypesRequestStart())
    return getJiraIssueTypesRequest(payload)
      .then(response => dispatch(jiraIssueTypesReceive(response.data)))
      .catch(err => dispatch(jiraIssueTypesReceiveError(err)))
  }
}

export function jiraStatusesRequestStart() {
  return { type: actions.JIRA_STATUSES_REQUEST }
}

export function jiraStatusesReceive(payload) {
  return { type: actions.JIRA_STATUSES_RECEIVE, payload }
}

export function jiraStatusesReceiveError(payload) {
  return { type: actions.JIRA_STATUSES_RECEIVE_ERROR, payload }
}

export function getJiraStatuses(payload) {
  return dispatch => {
    dispatch(jiraStatusesRequestStart())
    return getJiraStatusesRequest(payload)
      .then(response => dispatch(jiraStatusesReceive(response.data)))
      .catch(err => dispatch(jiraStatusesReceiveError(err)))
  }
}

export function jiraUsersRequestStart() {
  return { type: actions.JIRA_USERS_REQUEST }
}

export function jiraUsersReceive(payload) {
  return { type: actions.JIRA_USERS_RECEIVE, payload }
}

export function jiraUsersReceiveError(payload) {
  return { type: actions.JIRA_USERS_RECEIVE_ERROR, payload }
}

export function getJiraUsers(payload) {
  return dispatch => {
    dispatch(jiraUsersRequestStart())
    return getJiraUsersRequest(payload)
      .then(response => dispatch(jiraUsersReceive(response.data)))
      .catch(err => dispatch(jiraUsersReceiveError(err)))
  }
}

export function jiraComponentsRequestStart() {
  return { type: actions.JIRA_COMPONENTS_REQUEST }
}

export function jiraComponentsReceive(payload) {
  return { type: actions.JIRA_COMPONENTS_RECEIVE, payload }
}

export function jiraComponentsReceiveError(payload) {
  return { type: actions.JIRA_COMPONENTS_RECEIVE_ERROR, payload }
}

export function getJiraComponents(payload) {
  return dispatch => {
    dispatch(jiraComponentsRequestStart())
    return getJiraComponentsRequest(payload)
      .then(response => dispatch(jiraComponentsReceive(response.data)))
      .catch(err => dispatch(jiraComponentsReceiveError(err)))
  }
}

export function jiraFiltersRequestStart() {
  return { type: actions.JIRA_FILTERS_REQUEST }
}

export function jiraFiltersReceive(payload) {
  return { type: actions.JIRA_FILTERS_RECEIVE, payload }
}

export function jiraFiltersReceiveError(payload) {
  return { type: actions.JIRA_FILTERS_RECEIVE_ERROR, payload }
}

export function getJiraFavouriteFilters(payload) {
  return dispatch => {
    dispatch(jiraFiltersRequestStart())
    return getJiraFiltersRequest(payload)
      .then(response => dispatch(jiraFiltersReceive(response.data)))
      .catch(err => dispatch(jiraFiltersReceiveError(err)))
  }
}

export function jiraTestRequestStart() {
  return { type: actions.JIRA_FILTERS_REQUEST }
}

export function jiraTestReceive(payload) {
  return { type: actions.JIRA_FILTERS_RECEIVE, payload }
}

export function jiraTestReceiveError(payload) {
  return { type: actions.JIRA_FILTERS_RECEIVE_ERROR, payload }
}

export function getJiraTest(payload) {
  return dispatch => {
    dispatch(jiraTestRequestStart())
    return getJiraTestRequest(payload)
      .then(response => {
        dispatch(jiraTestReceive())
        return response
      })
      .catch(err => {
        dispatch(jiraTestReceiveError(err))
        return err
      })
  }
}

export function reorderRecentBoards(payload) {
  return (dispatch, getState) => {
    const {
      profile: { boardMenu },
      spinner: { isBoardMenuLoading }
    } = getState()

    const isMenuExist = boardMenu.boards && boardMenu.boards.length
    if (isMenuExist && !isBoardMenuLoading) {
      dispatch({ type: actions.REORDER_RECENT_BOARDS, payload })
    }
  }
}

export function profileNotificationsRequest() {
  return getProfileNotificationsRequest().then(response => response.data)
}

export function activeOrganizationsRequestStart() {
  return { type: actions.REQUEST_ACTIVE_ORGANIZATIONS }
}

export function activeOrganizationsErrorMessage(payload) {
  return { type: actions.RECEIVE_ACTIVE_ORGANIZATIONS_ERROR, payload }
}

export const setWorkspaceSubscriptions = subscriptions => ({
  type: actions.SET_WORKSPACE_SUBSCRIPTIONS,
  data: { subscriptions }
})

export const fetchWorkspaceSubscriptions = workspace => async (dispatch, getState) => {
  const _workspace = workspace

  if (!_workspace) {
    workspace = getActiveOrganization(getState())
  }

  const request = QueryRequest.create({
    scope: {
      organizationId: workspace.organizationId
    },
    filter: {
      organizationId: workspace.organizationId,
      status: EntitlementStatus.ENT_STATUS_ACTIVE
    },
    page: {
      limit: 500
    }
  })

  const res = await EntitlementsGrpcService.fetchEntitlementList({ request })

  const subscriptions = EntitlementsGrpcService.parseEntitlementList(res.entitlements)
  dispatch(setWorkspaceSubscriptions(subscriptions))
}

export const setWorkspaceCollections = collections => ({
  type: actions.SET_WORKSPACE_COLLECTIONS,
  data: { collections }
})

export const fetchWorkspaceCollections = workspace => (dispatch, getState) => {
  const _workspace = workspace

  if (!_workspace) {
    workspace = getActiveOrganization(getState())
  }

  return fetchOrganizationCollections({ variables: { organizationId: workspace.organizationId } })
    .then(res => {
      dispatch(setWorkspaceCollections(res))
    })
    .catch(e => {
      dispatch(showToastMessage({ text: messages.ORGANIZATION_COLLECTIONS_FETCH_ERROR, size: 'M' }))

      console.error(e)
    })
}

export function getActiveOrganizations() {
  return dispatch => {
    dispatch(activeOrganizationsRequestStart())

    return getAuthenticatedOrganizations()
      .then(response => {
        dispatch(
          receiveOrganizations({
            organizations: response.data.map(org => ({ ...org, authenticated: true })),
            replaceData: true
          })
        )

        openSocketConnection()

        dispatch(receiveActiveOrganization({ organization: response.data[0] }))
        dispatch(fetchWorkspaceSubscriptions(response.data[0]))
        dispatch(fetchWorkspaceCollections(response.data[0]))

        return response.data
      })
      .catch(err => {
        dispatch(activeOrganizationsErrorMessage(err))
        console.error(err)
      })
  }
}

export function organizationPermissionsRequest() {
  return { type: actions.REQUEST_ORGANIZATION_PERMISSIONS }
}

export function organizationPermissionsReceive(payload) {
  return { type: actions.RECEIVE_ORGANIZATION_PERMISSIONS, payload }
}

export function organizationPermissionsReceiveErrorMessage(payload) {
  return { type: actions.RECEIVE_ORGANIZATION_PERMISSIONS_ERROR, payload }
}

export function getOrganizationPermissions() {
  return dispatch => {
    dispatch(organizationPermissionsRequest())
    return getOrganizationPermissionsRequest()
      .then(response => dispatch(organizationPermissionsReceive(response.data)))
      .catch(err => {
        dispatch(organizationPermissionsReceiveErrorMessage(err))
      })
  }
}

export function saveOrganizationProfileRequestStart() {
  return { type: actions.REQUEST_SAVE_ORGANIZATION_PROFILE }
}

export function saveOrganizationProfileReceive(payload) {
  return { type: actions.RECEIVE_SAVE_ORGANIZATION_PROFILE, payload }
}

export function receiveSaveOrganizationProfileErrorMessage(payload) {
  return { type: actions.RECEIVE_SAVE_ORGANIZATION_PROFILE_ERROR, payload }
}

export function saveOrganizationProfileRequest(payload) {
  return async dispatch => {
    let avatar
    dispatch(saveOrganizationProfileRequestStart())
    const {
      data: { avatarImage, clearAvatar, ...restData }
    } = payload

    if (avatarImage || clearAvatar) {
      try {
        const file = avatarImage && dataURItoBlob(avatarImage, true)

        const request = UploadAvatarRequest.create({
          key: {
            scope: {
              organizationId: restData.organizationId
            }
          },
          info: {
            name: file?.name || 'avatar',
            mimeType: file?.type || 'image/png'
          },
          type: UploadAvatarRequestAvatarType.FOR_ORGANISATION,
          data: file ? await FileUploaderGrpcService.convertFileToUint8Array(file) : undefined,
          clear: clearAvatar
        })

        await FileUploaderGrpcService.uploadAvatar({ request })

        avatar = clearAvatar
          ? null
          : FileUploaderGrpcService.getFileLink(EFileUploaderFileTypes.ORG_AVATAR, {
              organisationID: restData.organizationId
            })
      } catch (e) {
        dispatch(
          showToastMessage({
            text: 'Error while organization avatar uploading'
          })
        )
      }
    }

    const avatarData = avatar || clearAvatar ? { avatarImage: avatar } : {}
    const orgPayload = {
      ...payload,
      data: {
        ...restData,
        ...avatarData
      }
    }

    try {
      const { data } = await saveOrganizationProfile(orgPayload)

      const localAvatarData =
        avatar || clearAvatar ? { avatarImage: avatar && addTimestampToUrl(avatar, true) } : {}
      const localOrgPayload = {
        ...data,
        ...localAvatarData
      }

      dispatch(saveOrganizationProfileReceive(localOrgPayload))
      dispatch(boardMenuRequest())
      return Promise.resolve()
    } catch (err) {
      dispatch(receiveSaveOrganizationProfileErrorMessage(err))
      return Promise.reject(err)
    }
  }
}

export function toggleIsOnBoardSettings(payload) {
  return { type: actions.TOGGLE_IS_ONBOARD_SETTINGS, payload }
}

export const toggleBoardImport = payload => dispatch => {
  const dropzone = document.getElementById(IMPORT_BUNDLE_DROPZONE_ID)

  try {
    if (payload) {
      dropzone.click()
    }
  } catch (err) {
    console.error(err)
  }

  return dispatch({ type: actions.TOGGLE_BOARD_IMPORT, payload })
}

export const updateNewImportedApps = payload => ({
  type: actions.UPDATE_NEW_IMPORTED_APPS,
  payload
})
