export const getNotificationsList = ({ data }) => {
  const {
    user: {
      feedNotifications: {
        nodes,
        totals,
        pageInfo: { endCursor, hasNextPage }
      }
    }
  } = data
  return {
    cursor: endCursor,
    hasNextPage,
    notifications: nodes,
    totals
  }
}

export const getNotificationsTotals = ({ data }) => {
  const {
    user: {
      feedNotifications: { totals }
    }
  } = data
  return {
    totals
  }
}
