import {
  getApp as getAppRequest,
  getPublishedApp as getPublishedAppRequest,
  getPublishedApps as getPublishedAppsRequest,
  getPublishedCategoryApps as getPublishedCategoryAppsRequest,
  getFilteredCategoryApps as getFilteredCategoryAppsRequest,
  getAllCategoriesApps as getAllCategoriesAppsRequest,
  getFilteredCollectionsApps as getFilteredCollectionsAppsRequest
} from 'api/appAPI'
import { bindOrganizationId } from '../store/storeBindings/organizationBindings'

export const getApp = bindOrganizationId(getAppRequest)

export const getPublishedApps = bindOrganizationId(getPublishedAppsRequest)

export const getAllCategoriesApps = bindOrganizationId(getAllCategoriesAppsRequest)

export const getPublishedCategoriesApps = bindOrganizationId(getPublishedCategoryAppsRequest)

export const getPublishedApp = bindOrganizationId(getPublishedAppRequest)

export const getFilteredCategoryApps = bindOrganizationId(getFilteredCategoryAppsRequest)

export const getFilteredCollectionsApps = bindOrganizationId(getFilteredCollectionsAppsRequest)
