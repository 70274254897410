import React, { useState } from 'react'
import { connect } from 'react-redux'
import { deleteVariable } from 'api/variablesAPI'
import { toggleVariableDeleteConfirmation } from 'actions'
import { ConfirmationModal } from 'components/modals/confirmation/confirmationModal'

const VariableDeleteConfirmation = props => {
  const {
    variableDeleteConfirmation: { state: isShown, tenantId, variableId, onConfirm }
  } = props

  const [isLoading, setLoading] = useState(false)

  if (!isShown) {
    return null
  }

  const onCancel = () => {
    props.toggleVariableDeleteConfirmation(false)
  }

  const onModalConfirm = async () => {
    setLoading(true)

    try {
      await deleteVariable({ tenantId, variableId })

      if (onConfirm) {
        onConfirm()
      }

      onCancel()
    } catch (err) {
      console.error(err)
    }

    setLoading(false)
  }

  return (
    <ConfirmationModal
      show={isShown}
      busy={isLoading}
      onModalConfirm={onModalConfirm}
      onModalCancel={onCancel}
      title="Are you sure you want to delete this Variable?"
      description="If you delete this Variable, it will be removed for all team members and cannot be restored."
    />
  )
}

const mapStateToProps = state => ({
  variableDeleteConfirmation: state.variables.variableDeleteConfirmation
})

const mapDispatchToProps = {
  toggleVariableDeleteConfirmation
}

export default connect(mapStateToProps, mapDispatchToProps)(VariableDeleteConfirmation)
