import { pdfLogo } from 'constants/pdfConstants'
import './widget-pdf-template.scss'

export const WidgetPdfTemplate = ({
  pageNum,
  totalPages
}: {
  pageNum: number
  totalPages: number
}) => (
  <div className="page-widget-template">
    <div className="header">
      <div className="data-container image">
        <img className="image" src={pdfLogo} alt="pdf logo" />
      </div>
    </div>
    <div className="footer">
      <span>{`Page ${pageNum} of ${totalPages}`}</span>
    </div>
  </div>
)
