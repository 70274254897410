import React from 'react'
import classNames from 'classnames'

import ConnectionsListWrapper from './ConnectionsListWrapper'
import ConnectionsEmptyList from './ConnectionsEmptyList'

import '../../../scss/filters/connections/connections-list-group.scss'

const ConnectionsListGroup = ({ className, children }) => {
  return <div className={classNames('connections-list-group', className)}>{children}</div>
}

ConnectionsListGroup.ConnectionsListWrapper = ConnectionsListWrapper
ConnectionsListGroup.ConnectionsEmptyList = ConnectionsEmptyList

export default ConnectionsListGroup
