/* eslint-disable import/no-cycle */
import { getWorkflowExecutionMonitorStatus, triggerClickEvent } from 'api/clickWorkflowAPI'
import { getDetailedViewRenamingCardUuid } from 'helpers/detailedView/headerHelpers'
import { EJobStatuses } from 'features/jobs/jobs.types'
import {
  navigateToLink,
  navigateToLinkInEmbedMode,
  navigateToDetailedView,
  navigateToBoard,
  navigateToEmbedCard,
  parseSmartLink,
  getAppViewRoute
} from 'helpers/routesHelpers'
import {
  sendFinishWorkflowGAAction,
  sendTriggerWorkflowGAAction
} from 'helpers/workflowExecution/workflowGAevents'
import {
  getWorkflowPayloadByWidgetId,
  getWorkflowPayloadErrors
} from 'helpers/workflowExecution/workflowPayloadHelpers'
import {
  WORKFLOW_FINAL_STEPS,
  getFinishData,
  OPEN_CARD_MODES
} from 'helpers/workflowExecution/workflowMetaHelpers'
import { history } from 'services/common/history.service'
import {
  TOGGLE_CLICK_WORKFLOW_META_MODAL,
  TOGGLE_CLICK_WORKFLOW_OVERLAY,
  SET_CLICK_WORKFLOW_LOADING,
  SET_CLICK_WORKFLOW_RUNNING,
  SET_CLICK_WORKFLOW_RUNNING_META,
  TOGGLE_CLICK_WORKFLOW_SUCCESS_MESSAGE
} from '../constants/actionTypes'
import messages from '../constants/messages'
import {
  showToastMessage,
  toggleCardTitleEditing,
  toggleWorkflowExecutionIndicator
} from './boardActions'
import { togglePresentationMode, reloadCardLoadedSections } from './detailedViewActions'
import {
  addNewJob,
  removeJob,
  workflowJobCompleted,
  subscribeToMonitor,
  unsubscribeFromMonitor
} from './socketActions'
import { disableWorkflowSocketFallback, enableWorkflowSocketFallback } from './socketPollingActions'

export function toggleClickWorkflowMetaModal(payload) {
  return { type: TOGGLE_CLICK_WORKFLOW_META_MODAL, payload }
}

export function toggleClickWorkflowOverlay(payload) {
  return { type: TOGGLE_CLICK_WORKFLOW_OVERLAY, payload }
}

export function setClickWorkflowLoading(payload) {
  return { type: SET_CLICK_WORKFLOW_LOADING, payload }
}

export function setClickWorkflowRunning(payload) {
  return { type: SET_CLICK_WORKFLOW_RUNNING, payload }
}

export function setClickWorkflowRunningMeta(payload) {
  return { type: SET_CLICK_WORKFLOW_RUNNING_META, payload }
}

export function toggleClickWorkflowSuccessMessage(payload) {
  return { type: TOGGLE_CLICK_WORKFLOW_SUCCESS_MESSAGE, payload }
}

export function performFinalStep(payload) {
  return (dispatch, getState) => {
    const {
      runningWorkflowMeta: { finalStep, finalStepLink: link, cardMode }
    } = getState().clickWorkflow

    switch (finalStep) {
      case WORKFLOW_FINAL_STEPS.stay_on_current_card:
        dispatch(
          reloadCardLoadedSections({
            tenantId: payload.tenantId,
            boardId: payload.boardId,
            cardUuid: payload.cardUuid
          })
        )
        break
      case WORKFLOW_FINAL_STEPS.close_detailed_view:
        navigateToBoard({
          boardId: payload.boardId,
          tenantId: payload.tenantId
        })
        break
      case WORKFLOW_FINAL_STEPS.smart_link:
        if (cardMode === OPEN_CARD_MODES.fullScreen) {
          dispatch(togglePresentationMode(true))
        }

        if (cardMode === OPEN_CARD_MODES.embed) {
          navigateToLinkInEmbedMode({ link })
        } else if (cardMode === OPEN_CARD_MODES.appView) {
          const ids = link.split('/')
          const [tenantId, boardId, cardUuid] = ids

          history.push(getAppViewRoute({ tenantId, boardId, cardUuid }))
        } else {
          navigateToLink({
            tenantId: payload.tenantId,
            boardId: payload.boardId,
            cardID: payload.cardUuid,
            link
          })

          const { cardUuid } = parseSmartLink(link)

          dispatch(toggleCardTitleEditing(getDetailedViewRenamingCardUuid(cardUuid)))
        }

        break
      case WORKFLOW_FINAL_STEPS.card_from_workflow: {
        const { finishData } = payload
        if (finishData?.boardId) {
          if (!finishData.cardUuid) {
            navigateToBoard(finishData)
            break
          }
          if (cardMode === OPEN_CARD_MODES.fullScreen) {
            dispatch(togglePresentationMode(true))
          }

          if (cardMode === OPEN_CARD_MODES.embed) {
            navigateToEmbedCard(finishData)
          } else if (cardMode === OPEN_CARD_MODES.appView) {
            history.push(getAppViewRoute(finishData))
          } else {
            navigateToDetailedView(finishData)

            dispatch(toggleCardTitleEditing(getDetailedViewRenamingCardUuid(finishData.cardUuid)))
          }
        }
        break
      }
      case WORKFLOW_FINAL_STEPS.web_link:
        window.open(link, '_blank')
        break
      default:
    }
  }
}

export function endClickWorkflowExecution(payload) {
  return (dispatch, getState) => {
    const {
      runningWorkflowMeta: { isSuccessMessageEnabled }
    } = getState().clickWorkflow

    dispatch(setClickWorkflowRunning(false))
    dispatch(toggleClickWorkflowOverlay(false))
    dispatch(performFinalStep(payload))

    // if success message is enabled - show it and clean runningWorkflowMeta
    // otherwise just clean runningWorkflowMeta
    if (isSuccessMessageEnabled) {
      dispatch(toggleClickWorkflowSuccessMessage(true))
    } else {
      dispatch(setClickWorkflowRunningMeta({}))
    }
  }
}

export function poleWorkflowExecutionJobs(payload) {
  return (dispatch, getState) => {
    const { subscribedJobs, subscribedMonitor } = getState().socket
    return getWorkflowExecutionMonitorStatus(payload)
      .then(response => {
        const monitor = response.data[0]
        const finishedJobs = monitor.monitors.filter(job => job.status !== EJobStatuses.PENDING)

        finishedJobs.forEach(job => dispatch(workflowJobCompleted(job)))

        const isExecutionFinished = monitor.status !== EJobStatuses.PENDING
        if (isExecutionFinished) {
          disableWorkflowSocketFallback()
          dispatch(unsubscribeFromMonitor(payload))
          dispatch(
            endClickWorkflowExecution({
              ...subscribedMonitor,
              finishData: getFinishData(finishedJobs)
            })
          )

          sendFinishWorkflowGAAction(monitor.monitors)

          // clear finished jobs through 10 seconds
          setTimeout(() => {
            subscribedJobs.forEach(job => dispatch(removeJob(job)))
          }, 10 * 1000)
        }
      })
      .catch(err => console.error(err))
  }
}

export function startMonitorWorkflowExecution(payload) {
  return dispatch => {
    const hasJobsToShow = payload.jobs.some(job => job.data.isExecutionShown)

    // if no jobs with isExecutionShown === true - don't show indicator
    if (hasJobsToShow) {
      dispatch(toggleWorkflowExecutionIndicator(true))
    }

    payload.jobs.forEach(job => dispatch(addNewJob(job)))
  }
}

function canExecuteWorkflow(currentBoard) {
  const { boardPermissions = {} } = currentBoard

  return !!boardPermissions.executeWorkflow
}

export function startClickWorkflowExecution(payload) {
  return (dispatch, getState) => {
    if (!canExecuteWorkflow(getState().board.currentBoard)) {
      return null
    }

    dispatch(toggleClickWorkflowOverlay(true))
    dispatch(setClickWorkflowLoading(true))

    return triggerClickEvent(payload)
      .then(response => {
        dispatch(setClickWorkflowLoading(false))

        const jobs = response.data.monitors

        sendTriggerWorkflowGAAction(jobs)

        dispatch(
          subscribeToMonitor({
            ...payload,
            monitorId: response.data.id,
            jobs
          })
        )

        dispatch(setClickWorkflowRunningMeta(payload.workflowMeta))
        dispatch(setClickWorkflowRunning(true))
        dispatch(
          startMonitorWorkflowExecution({
            tenantId: payload.tenantId,
            boardId: payload.boardId,
            cardUuid: payload.cardUuid,
            jobs
          })
        )
        dispatch(enableWorkflowSocketFallback())

        return null
      })
      .catch(err => {
        console.error(err)

        if (err.message && err.errorCode < 500) {
          dispatch(
            showToastMessage({
              text: err.message,
              size: 'M'
            })
          )
        } else {
          dispatch(
            showToastMessage({
              text: messages.TRY_WORKFLOW_AGAIN,
              size: 'M'
            })
          )
        }

        dispatch(toggleClickWorkflowOverlay(false))
        dispatch(setClickWorkflowLoading(false))
      })
  }
}

export function startClickWorkflowByWidgetId(payload) {
  return dispatch => {
    const [error, workflowPayload] = getWorkflowPayloadByWidgetId(payload)

    if (error) {
      dispatch(
        showToastMessage({
          text: getWorkflowPayloadErrors(error),
          size: 'M'
        })
      )

      return Promise.reject(error)
    }

    return dispatch(startClickWorkflowExecution(workflowPayload))
  }
}
