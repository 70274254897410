export interface IDropdownManualOption {
  label: string
  value: string
  isHint?: boolean
}

export enum EDropdownOptionsSource {
  manual = 'manual',
  list = 'list',
  query = 'query'
}
