import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import CheckBoxField from 'components/common/CheckBoxField'

import 'scss/ribbonTools/ribbon-checkbox.scss'

const RibbonCheckBox = React.forwardRef((props, ref) => {
  const {
    id,
    label,
    onClick,
    onMouseEnter,
    onMouseLeave,
    className,
    disabled,
    readOnly,
    checked,
    indeterminate,
    onChange
  } = props

  return (
    <CheckBoxField
      id={id}
      fieldRef={ref}
      className={classNames('ribbon-checkbox', className, { disabled })}
      label={label}
      checked={checked}
      onClick={onClick}
      onChange={onChange}
      disabled={disabled}
      readOnly={readOnly}
      indeterminate={indeterminate}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  )
})

RibbonCheckBox.defaultProps = {
  readOnly: false,
  indeterminate: false,
  className: '',
  disabled: false,
  onClick: null,
  onMouseEnter: null,
  onMouseLeave: null,
  onChange: null
}

RibbonCheckBox.propTypes = {
  id: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  indeterminate: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string
}

export default RibbonCheckBox
