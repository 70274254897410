import gql from 'graphql-tag'

const widgetFragmentName = 'WidgetFragment'

const WidgetFragment = {
  name: widgetFragmentName,
  fragment: gql`
        fragment ${widgetFragmentName} on Widget {
            organizationId
            tenantId
            boardId
            cardUuid
            uuid: id
            widgetTitle
            widgetClassName
        }
    `
}

export default WidgetFragment
