import { useCallback, useMemo, useState } from 'react'
import { useMount } from 'react-use'
import { useParams } from 'react-router-dom'
import { getQueryWidgetsFromAllTenants } from 'api/tenantAPI'
import { processWidgetLinkingDataRequest } from 'api/widgetAPI'
import type { IWidgetPickerItem } from 'components/widgetPickerDropdown/widgetPickerDropdown.types'
import { WidgetPickerDropdown } from 'components/widgetPickerDropdown/widgetPickerDropdown'
import { Switcher } from 'components/common/switcher/switcher'
import type { IDictionaryData, TDictionaryError, TQueryWidget } from '../dictionariesSettings.types'
import './query-section.scss'

type TProps = {
  isQueryEnabled: boolean
  queryWidget: IDictionaryData['queryWidget']
  onChangeQueryEnabled: () => void
  setError: (error: TDictionaryError | null) => void
  queryError?: boolean
  onChangeQueryValue: (payload: { queryId: string; cardId: string; boardId: string }) => void
  fetchQueryOptions: (payload: TQueryWidget) => Promise<unknown>
  isQueryWidgetLoading: boolean
  setIsQueryWidgetLoading: (value: boolean) => void
}

type TState = {
  queryWidgets: Array<{
    board: { boardName: string; boardId: string }
    widgets: IWidgetPickerItem[]
  }>
}

export const QuerySection = ({
  isQueryEnabled,
  setError,
  queryWidget,
  onChangeQueryEnabled,
  queryError,
  fetchQueryOptions,
  onChangeQueryValue,
  isQueryWidgetLoading,
  setIsQueryWidgetLoading
}: TProps) => {
  const [queryWidgets, setQueryWidgets] = useState<TState['queryWidgets']>([])

  const { tenantId }: { tenantId: string } = useParams()

  const selectedWidget = useMemo(() => {
    let result

    queryWidgets.some(
      ({ widgets }) => (result = widgets.find(widget => widget.widgetUuid === queryWidget?.queryId))
    )

    if (result) void fetchQueryOptions(result)

    return result
  }, [queryWidgets, queryWidget?.queryId, fetchQueryOptions]) as TQueryWidget | undefined

  const fetchSelectedQueryWidget = useCallback(async () => {
    if (!queryWidget?.queryId) return Promise.resolve([])

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { data }: { data: TState['queryWidgets'] } = await processWidgetLinkingDataRequest({
      tenantId,
      boardId: queryWidget?.boardId,
      cardID: queryWidget?.cardId,
      widgetID: queryWidget?.queryId,
      isRestrictByTenant: true
    })

    return data
  }, [tenantId, queryWidget])

  const fetchQueryWidgets = useCallback(
    async (search?: string, isForceRequest?: boolean) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { data }: { data: TState['queryWidgets'] } = await getQueryWidgetsFromAllTenants({
        tenantId,
        search
      })

      if (!isForceRequest) setQueryWidgets(data)

      return data
    },
    [tenantId]
  )

  useMount(() => {
    if (queryWidget?.queryId && isQueryEnabled) {
      setIsQueryWidgetLoading(true)

      void Promise.all([fetchSelectedQueryWidget(), fetchQueryWidgets('', true)])
        .then(([currentWidgetResponse, queryWidgetsResponse]) => {
          if (!currentWidgetResponse.length) setError({ query: true })

          setQueryWidgets([...currentWidgetResponse, ...queryWidgetsResponse])
        })
        .finally(() => setIsQueryWidgetLoading(false))
    }
  })

  return (
    <div className="dictionary-query-section">
      <div className="query-section-header">
        <Switcher
          className="query-switcher"
          checked={isQueryEnabled}
          disabled={!onChangeQueryEnabled}
          onChange={onChangeQueryEnabled}
        />
        <span>Use query for dropdown list options</span>
      </div>
      {isQueryEnabled && (
        <div className="query-picker-wrapper">
          <span className="query-picker-title">Select query widget</span>
          <WidgetPickerDropdown
            options={queryWidgets}
            // @ts-ignore
            selectedWidget={selectedWidget || {}}
            hasWidgetError={queryError}
            clearWidget={() => onChangeQueryValue({ queryId: '', cardId: '', boardId: '' })}
            searchDataWidgets={fetchQueryWidgets}
            selectWidget={({ boardId, cardUuid, widgetUuid }) =>
              onChangeQueryValue({ boardId, cardId: cardUuid, queryId: widgetUuid })
            }
            isWidgetLoading={isQueryWidgetLoading}
            shouldOpenAfterMount={false}
          />
        </div>
      )}
    </div>
  )
}
