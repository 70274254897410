import { type ReactElement, useState } from 'react'
import classNames from 'classnames'
import TrashIcon from 'assets/images/icons/trash-can.svg?react'
import RoundIconButton from 'components/buttons/RoundIconButton'
import {
  type IFilterRuleCondition,
  type IFilterRulePredicateConfig,
  type TFilterRulesConfig,
  EFilterRuleCondition,
  EFilterRuleConditionPredicate,
  EFilterRuleConditionPredicateName
} from 'features/filter/filter.types'
import { type TFilterRuleConditionsProps } from '../filterRuleConditions'
import { getNextPredicateName } from '../filterRuleConditions.helpers'
import { ConditionPredicateAttribute } from './components/conditionPredicateAttribute/conditonPredicateAttribute'
import { ConditionValueTypeAttribute } from './components/conditionValueTypeAttribute/conditionValueTypeAttribute'
import { UbfCoordinatesHint } from './components/ubfCoordinatesHint/ubfCoordinatesHint'
import './filter-rule-condition.scss'

type TProps = Pick<
  TFilterRuleConditionsProps,
  | 'rulesConfig'
  | 'isSupportVariables'
  | 'valueFieldRenderer'
  | 'onParamsChange'
  | 'onVariableUpdate'
  | 'onConditionDelete'
  | 'onConditionSelect'
  | 'onPredicateSelect'
  | 'onValueTypeSelect'
  | 'onVariablesChange'
  | 'onEntityIdsChange'
  | 'onValuesChange'
  | 'onLoadSuggestion'
  | 'onLoadVariableSuggestion'
> & {
  ruleCondition: IFilterRuleCondition
}

export const FilterRuleCondition = ({
  rulesConfig,
  ruleCondition,
  isSupportVariables,
  valueFieldRenderer,
  onParamsChange,
  onVariableUpdate,
  onConditionDelete,
  onConditionSelect,
  onPredicateSelect,
  onValueTypeSelect,
  onVariablesChange,
  onEntityIdsChange,
  onValuesChange,
  onLoadSuggestion,
  onLoadVariableSuggestion
}: TProps) => {
  const [isActive, setIsActive] = useState(false)

  const isShowUbfCoordinatesHint =
    ruleCondition.condition === EFilterRuleCondition.UBF &&
    ruleCondition.predicate === EFilterRuleConditionPredicate.cellCoordinates

  const handleRuleConditionDelete = () => {
    onConditionDelete(ruleCondition.uuid)
  }

  const handleRuleConditionFocus = () => {
    setIsActive(true)
  }

  const handleRuleConditionBlur = () => {
    setIsActive(false)
  }

  const renderConditionAttributes = (
    attributesConfig: TFilterRulesConfig<IFilterRulePredicateConfig>,
    attributeName: EFilterRuleConditionPredicateName
  ): ReactElement | null => {
    const attributeValue = ruleCondition[attributeName] as string

    const nextAttributeName = getNextPredicateName(attributeName)
    const attributeConfig = attributesConfig[attributeValue]

    return (
      <>
        <ConditionPredicateAttribute
          ruleCondition={ruleCondition}
          predicateName={attributeName}
          predicatesConfig={attributesConfig}
          onParamsChange={onParamsChange}
          onConditionSelect={onConditionSelect}
          onPredicateSelect={onPredicateSelect}
          onFocus={handleRuleConditionFocus}
          onBlur={handleRuleConditionBlur}
        />
        {attributeConfig?.type && (
          <ConditionValueTypeAttribute
            rulesConfig={rulesConfig}
            ruleCondition={ruleCondition}
            attributeName={attributeName}
            attributesConfig={attributesConfig}
            isSupportVariables={isSupportVariables}
            valueFieldRenderer={valueFieldRenderer}
            onVariableUpdate={onVariableUpdate}
            onValueTypeSelect={onValueTypeSelect}
            onVariablesChange={onVariablesChange}
            onEntityIdsChange={onEntityIdsChange}
            onValuesChange={onValuesChange}
            onLoadSuggestion={onLoadSuggestion}
            onLoadVariableSuggestion={onLoadVariableSuggestion}
            onFocus={handleRuleConditionFocus}
            onBlur={handleRuleConditionBlur}
          />
        )}
        {nextAttributeName &&
          attributeConfig?.predicates &&
          renderConditionAttributes(attributeConfig.predicates, nextAttributeName)}
      </>
    )
  }

  return (
    <div
      className={classNames('filter-rule-condition', {
        active: isActive
      })}
    >
      <div className="condition-attributes">
        {renderConditionAttributes(rulesConfig, EFilterRuleConditionPredicateName.condition)}
      </div>
      <div
        className="condition-actions"
        onFocus={handleRuleConditionFocus}
        onBlur={handleRuleConditionBlur}
      >
        {isShowUbfCoordinatesHint && <UbfCoordinatesHint className="action" />}
        {/*@ts-expect-error*/}
        <RoundIconButton
          as="button"
          className="action"
          appearance="light-bg"
          onClick={handleRuleConditionDelete}
        >
          <TrashIcon className="icon" />
        </RoundIconButton>
      </div>
    </div>
  )
}
