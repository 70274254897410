import { Component } from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { Button } from '@praxie/shared'
import { toggleVariableConfiguration, toggleVariableDeleteConfirmation } from 'actions'
import { Header } from 'components/variables/variableConfiguration/Header'
import { Rules } from 'components/variables/variableConfiguration/Rules'
import { Tags } from 'components/variables/variableConfiguration/Tags'
import { Tooltip } from 'components/common/tooltip/tooltip'
import { VariableConnections } from 'components/variables/variableConnections/variableConnections'
import messages from 'constants/messages'
import { validateForNotEmpty } from 'helpers/validationHelpers'
import { keyDownEventListener } from 'helpers/board/boardHelpers'
import { Dialog, DialogActions, DialogContent } from 'components/common/dialog/dialog'
import {
  createVariable,
  getDefaultFilter,
  validateRules
} from 'helpers/variables/variableConfiguration'
import { editVariable, createVariable as createVariableAPI } from 'api/variablesAPI'
import { VariableUpdateConfirmation } from 'components/variables/variableUpdateConfirmation/variableUpdateConfirmation'
import 'scss/variables/variableConfiguration/modal.scss'

const ConnectionsToggle = ({ onToggle, isActive, connections }) => (
  <div
    className={classNames('variable-connections-toggle', {
      disabled: !isActive
    })}
    onClick={onToggle}
  >
    <span>{messages.CONNECTIONS}</span>
    <span className="connection-text">{connections}</span>
    <i className="up-font-ic-arrow-e908" />
  </div>
)

class VariableConfigurationModal extends Component {
  state = {
    variable: null,
    error: {},
    rulesError: {},
    isSaving: false,
    isUpdateConfirmationShown: false
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      variableConfiguration: { variable, state }
    } = nextProps

    if (state && !prevState.variable) {
      return {
        variable: variable || createVariable({})
      }
    }

    return null
  }

  shouldComponentUpdate(nextProps) {
    const {
      variableConfiguration: { state: isOpen }
    } = nextProps

    const isModalWasClosed = !isOpen && this.props.variableConfiguration.state

    return isOpen || isModalWasClosed
  }

  componentDidUpdate(prevProps) {
    const {
      variableConfiguration: { state: isOpen }
    } = prevProps

    const isModalWasOpen = !isOpen && this.props.variableConfiguration.state
    const isModalWasClosed = isOpen && !this.props.variableConfiguration.state

    if (isModalWasOpen) {
      document.addEventListener('keydown', this.keyDownEventListener)
    }

    if (isModalWasClosed) {
      document.removeEventListener('keydown', this.keyDownEventListener)
    }
  }

  onNameChange = name => {
    this.setState(state => ({
      variable: {
        ...state.variable,
        name
      },
      error: {
        ...state.error,
        name: validateForNotEmpty(name)
      }
    }))
  }

  onObjectChange = ({ value }) => {
    this.setState(state => ({
      variable: {
        ...state.variable,
        object: value,
        filter: getDefaultFilter({ variableObject: value })
      },
      rulesError: {}
    }))
  }

  onChangeRule = newRule => {
    this.setState(({ variable, rulesError }) => ({
      variable: {
        ...variable,
        filter: {
          ...variable.filter,
          rules: variable.filter.rules.map(rule => {
            if (rule.object === newRule.object) {
              return { ...rule, ...newRule }
            }

            return rule
          })
        }
      },
      rulesError: { ...rulesError, [newRule.object]: '' }
    }))
  }

  onChangeAttribute = newAttribute => {
    this.setState(({ variable }) => ({
      variable: {
        ...variable,
        filter: {
          ...variable.filter,
          attribute: {
            ...variable.filter.attribute,
            ...newAttribute
          }
        }
      }
    }))
  }

  changeTags = tags => {
    this.setState(({ variable }) => ({
      variable: {
        ...variable,
        tags: tags.map(tag => tag.value)
      }
    }))
  }

  toggleUpdateConfirmation = isOpen => {
    this.setState({ isUpdateConfirmationShown: isOpen })
  }

  onUpdateConfirm = async () => {
    const {
      variableConfiguration: { tenantId, onConfirm }
    } = this.props

    this.toggleUpdateConfirmation(false)

    try {
      this.setState({ isSaving: true })

      await editVariable({ tenantId, data: this.state.variable })

      this.onCancel()

      if (onConfirm) {
        onConfirm()
      }
    } catch (err) {
      if (err.errorCode === 409) {
        this.setState({ error: { name: messages.PLEASE_PROVIDE_A_UNIQUE_NAME } })
      }
    } finally {
      this.setState({ isSaving: false })
    }
  }

  onCancel = () => {
    this.props.toggleVariableConfiguration(false)

    this.setState({
      variable: null,
      error: {},
      rulesError: {},
      isSaving: false
    })
  }

  onSave = async () => {
    const {
      variableConfiguration: { tenantId, onConfirm }
    } = this.props
    const { variable } = this.state

    const isValid = this.validate()

    if (!isValid) return

    try {
      if (variable.id && variable.connectionsNumber > 0) {
        this.toggleUpdateConfirmation(true)
        return
      }

      this.setState({ isSaving: true })

      const request = variable.id ? editVariable : createVariableAPI

      await request({ tenantId, data: variable })

      if (onConfirm) onConfirm()

      this.onCancel()
    } catch (err) {
      console.error(err)

      if (err.errorCode === 409) {
        this.setState({ error: { name: messages.PLEASE_PROVIDE_A_UNIQUE_NAME } })
      }
    } finally {
      this.setState({ isSaving: false })
    }
  }

  validate = () => {
    const {
      variable: {
        name,
        filter: { rules }
      }
    } = this.state

    const state = {
      error: {
        name: validateForNotEmpty(name)
      },
      rulesError: validateRules(rules)
    }

    this.setState(state)

    const hasErrors = Object.values(state.error).some(err => !!err)
    const hasRuleErrors = Object.values(state.rulesError).some(err => !!err)

    return !hasErrors && !hasRuleErrors
  }

  deleteVariable = () => {
    const {
      variableConfiguration: { onConfirm, tenantName }
    } = this.props
    const {
      variable: { id, name, tenantId }
    } = this.state

    this.props.toggleVariableDeleteConfirmation({
      state: true,
      tenantId,
      tenantName,
      variableId: id,
      variableName: name,
      onConfirm
    })

    this.onCancel()
  }

  keyDownEventListener = event => {
    const {
      variableConfiguration: { state: isOpen }
    } = this.props

    setTimeout(() => {
      keyDownEventListener(event, isOpen, this.onSave, this.onCancel)
    }, 7)
  }

  render() {
    const {
      variableConfiguration: { state: isShown, tenantId }
    } = this.props

    if (!isShown) return null

    const {
      variable: { id, name, object, filter, tags, connectionsNumber },
      error,
      rulesError,
      isSaving,
      isUpdateConfirmationShown
    } = this.state

    return (
      <>
        <Dialog
          open={isShown}
          className="variable-configuration-modal"
          type="default"
          variant="filled"
          title={
            <>
              <i className="icon icon-variable-20-color" />
              {messages.VARIABLE_CONFIGURATION}
            </>
          }
          disableBackdropClickClose
          onClose={this.onCancel}
        >
          <DialogContent className="content">
            <Header
              isSaving={isSaving}
              name={name}
              object={object}
              nameError={error.name}
              onNameChange={this.onNameChange}
              onObjectChange={this.onObjectChange}
            />
            <Rules
              isSaving={isSaving}
              tenantId={tenantId}
              rules={filter.rules}
              attribute={filter.attribute}
              variableObject={object}
              rulesError={rulesError}
              onChangeRule={this.onChangeRule}
              onChangeAttribute={this.onChangeAttribute}
            />
            <Tags
              isSaving={isSaving}
              tenantId={tenantId}
              tags={tags}
              changeTags={this.changeTags}
            />
          </DialogContent>
          <DialogActions className="footer">
            <VariableConnections
              Toggle={ConnectionsToggle}
              variableId={id}
              tenantId={tenantId}
              placement="top"
              connectionsNumber={connectionsNumber || 0}
            />
            {id && (
              <Tooltip
                id="delete-variable-tooltip"
                placement="top"
                title={connectionsNumber ? messages.DELETE_VARIABLE_TOOLTIP : messages.DELETE}
                shouldWrapChildrenWithDiv
              >
                <Button
                  appearance="secondary"
                  className="delete-variable"
                  disabled={isSaving || !!connectionsNumber}
                  onClick={this.deleteVariable}
                >
                  <i className="up-font-ic-trashcan-16" />
                </Button>
              </Tooltip>
            )}
            <Button
              appearance="secondary"
              className="cancel-btn"
              disabled={isSaving}
              onClick={this.onCancel}
            >
              {messages.CANCEL}
            </Button>
            <Button className="confirm-btn" busy={isSaving} onClick={this.onSave}>
              {messages.SAVE}
            </Button>
          </DialogActions>
        </Dialog>
        <VariableUpdateConfirmation
          isOpen={isUpdateConfirmationShown}
          tenantId={tenantId}
          variableId={id}
          connectionsNumber={connectionsNumber}
          onConfirm={this.onUpdateConfirm}
          onCancel={() => this.toggleUpdateConfirmation(false)}
        />
      </>
    )
  }
}

const mapStateToProps = state => ({
  variableConfiguration: state.variables.variableConfiguration
})

const mapDispatchToProps = {
  toggleVariableConfiguration,
  toggleVariableDeleteConfirmation
}

export default connect(mapStateToProps, mapDispatchToProps)(VariableConfigurationModal)
