import React from 'react'
import ActionsToastMessage from 'components/common/actionsToastMessage'

const WidgetActionToastMessage = props => {
  const onContinue = () => {
    props.onMessageContinue()
    props.onMessageHide()
  }

  const { text, onMessageHide, visible, height, width, transform, continueText } = props
  return (
    <ActionsToastMessage
      isVisible={visible}
      height={height}
      width={width}
      transform={transform}
      onDiscard={onMessageHide}
      onContinue={onContinue}
      continueText={continueText}
    >
      <div>{text}</div>
    </ActionsToastMessage>
  )
}

export default WidgetActionToastMessage
