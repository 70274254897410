import messages from 'constants/messages'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { validateForNotEmpty } from 'helpers/validationHelpers'
import { type IWorkflowBlock, type TWorkflowBlockError } from 'features/workflow/workflow.types'

export const validateConvertWidgetDataBlock = ({
  block
}: {
  block: IWorkflowBlock
}): { error: TWorkflowBlockError } => {
  const params = (block.meta[EFieldKeys.PARAMS] || []) as string[]
  const formula = (block.meta[EFieldKeys.FORMULA] as string) || ''

  const paramsError = params.reduce((acc: Record<number, string>, param: string, index: number) => {
    if (!param) {
      acc[index] = messages.emptyField
    }
    return acc
  }, {})

  const formulaError = { [EFieldKeys.FORMULA]: validateForNotEmpty(formula) }

  return {
    error: { ...paramsError, ...formulaError }
  }
}
