import InputAdornment from '@mui/material/InputAdornment'
import cn from 'classnames'
import { motion } from 'framer-motion'
import CrossIcon from 'assets/images/icons/cross.svg?react'
import Search from 'assets/images/icons/search.svg?react'
import RoundIconButton from 'components/buttons/RoundIconButton'
import { type TTextFieldProps, TextField } from '../textField'
import classes from './search-input.module.scss'

type TProps = TTextFieldProps & {
  clearSearch: () => void
}

export const SearchInput = ({ className, clearSearch, InputProps, ...props }: TProps) => (
  <TextField
    className={cn(classes.praxieSearchInput, className)}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <Search className={classes.searchIcon} />
        </InputAdornment>
      ),
      endAdornment: !!props.value && (
        <InputAdornment position="end">
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            {/* @ts-expect-error */}
            <RoundIconButton className={classes.clearButton} size="small-20" onClick={clearSearch}>
              <CrossIcon className={classes.crossIcon} />
            </RoundIconButton>
          </motion.div>
        </InputAdornment>
      ),
      ...InputProps
    }}
    {...props}
  />
)
