import { ENTITIES, ORGANIZATION_TYPE } from '../../constants/schemaConstants'
import { formatId } from '../../common/commonHelpers'

export const getOrganizationPayload = ({ id }) => {
  const entity = ENTITIES[ORGANIZATION_TYPE]
  const alias = `${entity}${formatId(id)}`
  return `${alias}: ${entity}(id: "${id}"){name,organizationId:id`
}

export const getOrganizationResponse = ({ data, organizationId }) => {
  const alias = `${ENTITIES[ORGANIZATION_TYPE]}${formatId(organizationId)}`
  return data[alias] || null
}
