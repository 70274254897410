import { mapSelectedValuesFromLabel } from 'helpers/fields/selectHelpers'
import { FIELD_TYPES } from 'helpers/filters/conditionsHelpers'
import MultiCreatable from 'components/fields/MultiCreatable'
import MultiCreatableSelect from 'components/fields/MultiCreatableSelect'
import MultiSelect from 'components/fields/MultiSelect'
import { EFilterRuleConditionValueType } from 'features/filter/filter.types'
import { type TFilterRuleConditionValueFieldProps } from 'features/filter/filterRuleConditions/filterRuleConditions'

const getPredicateOptions = (options: string[] = []) =>
  options.map(label => ({
    label,
    value: label
  }))

const ValueField = ({
  fieldType,
  valueType,
  hasAutoComplete,
  values,
  options,
  ...rest
}: TFilterRuleConditionValueFieldProps) => {
  if (
    valueType === EFilterRuleConditionValueType.variable ||
    valueType === EFilterRuleConditionValueType.entityIds
  ) {
    return null
  }

  if (fieldType === FIELD_TYPES.MULTI_INPUT && hasAutoComplete) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    return <MultiCreatableSelect values={mapSelectedValuesFromLabel(values)} {...rest} />
  }

  if (fieldType === FIELD_TYPES.MULTI_INPUT && !hasAutoComplete) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    return <MultiCreatable values={mapSelectedValuesFromLabel(values)} {...rest} />
  }

  if (fieldType === FIELD_TYPES.MULTI_SELECT && !hasAutoComplete) {
    return (
      <MultiSelect
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        values={mapSelectedValuesFromLabel(values)}
        selectOptions={getPredicateOptions(options)}
        {...rest}
      />
    )
  }

  return null
}

export default ValueField
