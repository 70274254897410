import React, { useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Menu } from 'components/common/menu/menu'
import { getFullFiltersConfig } from 'api/filtersAPI'
import FilterDetailsPreview from 'components/filters/filterPreview/FilterDetailsPreview'
import DotsSpinner from 'components/spinners/DotsSpinner'
import messages from 'constants/messages'
import * as actions from 'actions'

import 'scss/filters/filterPreview/filter-preview-dropdown.scss'

export const DefaultToggle = props => {
  const { isOpen, onToggle } = props

  return (
    <div
      className={classNames('filter-preview-dropdown-default-toggle', {
        'is-open': isOpen
      })}
      onClick={onToggle}
    >
      {messages.TRIGGER_RULE}
    </div>
  )
}

export const FilterPreviewDropdownContainer = props => {
  const { tenantId, filterUuid, getFilterData, Toggle } = props

  const anchorElRef = useRef(null)

  const [isOpen, setIsOpen] = useState(false)

  const [isFilterFetching, setIsFilterFetching] = useState(false)

  const [filter, setFilter] = useState(null)

  const [filtersConfig, setFiltersConfig] = useState({})

  const getData = () => {
    if (!filterUuid) {
      return null
    }

    setIsFilterFetching(true)

    return Promise.all([
      getFullFiltersConfig({ tenantId }),
      getFilterData({ tenantId, filterUuid })
    ])
      .then(([responseFiltersConfig, responseFilter]) => {
        setFiltersConfig(responseFiltersConfig)
        setFilter(responseFilter)
      })
      .finally(() => setIsFilterFetching(false))
      .catch(err => console.error(err))
  }

  const openDropdown = () => {
    setIsOpen(true)

    if (!filter || !filtersConfig) {
      getData()
    }
  }

  const closeDropdown = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    setFilter(null)
  }, [filterUuid])

  return (
    <div
      ref={anchorElRef}
      id="filter-preview-dropdown"
      className="column-dropdown filter-preview-dropdown"
    >
      <Toggle isOpen={isOpen} onToggle={openDropdown} />
      <Menu
        className="filter-preview-dropdown-menu"
        open={isOpen}
        onClose={closeDropdown}
        anchorEl={anchorElRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: -5,
          horizontal: 'right'
        }}
      >
        <div className="scrollable-overlay">
          {isFilterFetching ? (
            <DotsSpinner color="dark" />
          ) : (
            filter && <FilterDetailsPreview filter={filter} filtersConfig={filtersConfig} />
          )}
        </div>
      </Menu>
    </div>
  )
}

FilterPreviewDropdownContainer.propTypes = {
  Toggle: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  filterUuid: PropTypes.string.isRequired,
  tenantId: PropTypes.string.isRequired,
  getFilterData: PropTypes.func.isRequired
}

FilterPreviewDropdownContainer.defaultProps = {
  Toggle: DefaultToggle
}

const mapDispatchToProps = {
  getFilterData: actions.getFilterData
}

export default connect(null, mapDispatchToProps)(FilterPreviewDropdownContainer)
