import type { IMyTasksTableFilters } from 'features/tasks/myTasksTable/components/filters/myTasksTableFilters.types'

type TUserPreferences = {
  [key: string]: {
    boardsWithUnhiddenColumns: string[]
    isSwipeNavigationHintShown: boolean
    isHelpSectionCollapsed: boolean
    isBundlesSwimSectionCollapsed: boolean
    presentationIntervalValue: number
    myTasksTableFilters: IMyTasksTableFilters
  }
}

export const getUserPreferences = () => {
  return JSON.parse(localStorage.getItem('userPreferences') || '{}') as TUserPreferences
}

export const setUserPreferences = ({
  userId,
  preferences
}: {
  userId: string
  preferences: { [key: string]: unknown }
}) => {
  const userPreferences = getUserPreferences()

  userPreferences[userId] = {
    ...userPreferences[userId],
    ...preferences
  }

  localStorage.setItem('userPreferences', JSON.stringify(userPreferences))
}
