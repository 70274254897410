import classNames from 'classnames'
import { Button } from '@praxie/shared'
import messages from 'constants/messages'
import { EWidgetModes } from 'features/widgets/widgets.types'
import WidgetContainer from 'containers/common/WidgetContainer'
import { ColorPicker } from 'components/common/colorPicker/colorPicker'
import { Dialog, DialogActions, DialogContent, DialogTitle } from 'components/common/dialog/dialog'
import RoundIconButton from 'components/buttons/RoundIconButton'
import { TextField } from 'components/common/textField/textField'
import CloseIcon from 'assets/images/icons/cross.svg?react'
import Tabs from '../common/Tabs'
import JiraConnectionForm from '../jira/JiraConnectionForm'
import 'scss/modals/issue-counter-modal.scss'

const MANUAL_DATA_MODE = 'manual'
// const JIRA_DATA_MODE = 'jira';

const IssueCounter = ({
  show,
  isLinked,
  onModalCancel,
  jiraData,
  widgetData,
  onInputChange,
  onCounterFieldChange,
  onToggleColorPicker,
  isOpenColorPicker,
  changeHeaderColor,
  tabs,
  onFilterTabClick,
  authError,
  jiraCredentials,
  jiraUrl,
  jiraProjects,
  jiraIssueTypes,
  jiraStatuses,
  jiraUsers,
  jiraComponents,
  jiraFavFilters,
  setUserJiraCredentials,
  onJiraConnectionLogIn,
  onProjectFilterChange,
  onFilterChange,
  onFavFilterChange,
  selectedJiraProjects,
  selectedJiraIssueTypes,
  selectedJiraStatuses,
  selectedJiraUsers,
  selectedJiraComponents,
  selectedJiraFavFilter,
  teamName,
  tenantId,
  mode,
  unlinkWidget,
  onModalConfirm,
  onTabClick,
  goToLinking
}) => {
  return (
    <Dialog
      open={show}
      className="issue-counter-modal"
      disableBackdropClickClose
      onClose={onModalCancel}
    >
      <DialogTitle className="header">
        <div className="left">
          <TextField
            size={28}
            inputProps={{ maxLength: 30 }}
            placeholder={messages.WIDGET_NAME}
            value={jiraData.widgetTitle}
            onChange={e => onInputChange('widgetTitle', e)}
          />
          <TextField
            size={28}
            inputProps={{ maxLength: 30 }}
            placeholder={messages.TOP_LABEL}
            value={jiraData.topLabel}
            onChange={e => onInputChange('topLabel', e)}
          />
          <TextField
            size={28}
            inputProps={{ maxLength: 30 }}
            placeholder={messages.BOTTOM_LABEL}
            value={jiraData.bottomLabel}
            onChange={e => onInputChange('bottomLabel', e)}
          />
          <ColorPicker
            isShown={isOpenColorPicker}
            toggleButtonIcon={<i className="up-font-ic-fill" />}
            color={jiraData.headerColor}
            adjusted
            onColorChange={changeHeaderColor}
            onToggleColorPicker={onToggleColorPicker}
          />
        </div>
        <div className="right">
          {mode === 'edit' && jiraData.dataMode === MANUAL_DATA_MODE && (
            <Button
              appearance="secondary"
              className="go-to-linking-btn"
              size="small"
              onClick={goToLinking}
            >
              {messages.GO_TO_LINKING}
            </Button>
          )}
          <RoundIconButton className="close" onClick={onModalCancel}>
            <CloseIcon className="close-icon" title={messages.CLOSE} />
          </RoundIconButton>
        </div>
      </DialogTitle>
      <DialogContent className="content">
        <div className="left">
          <WidgetContainer
            widgets={[
              {
                ...widgetData,
                ...jiraData
              }
            ]}
            mode={EWidgetModes.PREVIEW}
            tenantId={tenantId}
            getGroupedFiles={() => []}
          />
        </div>
        <div className="right">
          <Tabs tabs={tabs} className="issue-counter-tabs" onTabClick={onTabClick} />
          {jiraData.dataMode === MANUAL_DATA_MODE ? (
            <div
              className={classNames({
                'form-field': true,
                'counter-container': true,
                'has-error': jiraData.counterError
              })}
            >
              <span className="counter-label bold">{messages.COUNTER}</span>
              <TextField
                inputProps={{ maxLength: 10 }}
                value={jiraData.counter}
                placeholder={messages.COUNTER_PLACEHOLDER}
                disabled={isLinked}
                onChange={onCounterFieldChange}
              />
              {isLinked && (
                <Button appearance="secondary" className="unlink-btn" onClick={unlinkWidget}>
                  {messages.UNLINK}
                </Button>
              )}
              <span className="field-error">{jiraData.counterError}</span>
            </div>
          ) : (
            <JiraConnectionForm
              jiraCredentials={jiraCredentials}
              jiraUrl={jiraUrl}
              jiraProjects={jiraProjects}
              jiraIssueTypes={jiraIssueTypes}
              jiraStatuses={jiraStatuses}
              jiraUsers={jiraUsers}
              jiraComponents={jiraComponents}
              jiraFavFilters={jiraFavFilters}
              setUserJiraCredentials={setUserJiraCredentials}
              jiraFilters={widgetData.jiraFilters}
              selectedJiraProjects={selectedJiraProjects}
              selectedJiraIssueTypes={selectedJiraIssueTypes}
              selectedJiraStatuses={selectedJiraStatuses}
              selectedJiraUsers={selectedJiraUsers}
              selectedJiraComponents={selectedJiraComponents}
              selectedJiraFavFilter={selectedJiraFavFilter}
              showFiltersBlock={jiraUrl && jiraCredentials.email && jiraCredentials.password}
              showCredentialsBlock={
                !jiraCredentials.email || !jiraCredentials.password || authError
              }
              showFooterBlock={
                !jiraUrl || !jiraCredentials.email || !jiraCredentials.password || authError
              }
              filterMode={jiraData.filterMode}
              authenticationFailed={authError}
              teamName={teamName}
              showLoginPasswordFields
              onModalConfirm={onJiraConnectionLogIn}
              onProjectFilterChange={onProjectFilterChange}
              onFilterChange={onFilterChange}
              onFavFilterChange={onFavFilterChange}
              onFilterTabClick={onFilterTabClick}
            />
          )}
        </div>
      </DialogContent>
      <DialogActions className="footer">
        <Button appearance="secondary" className="cancel-btn" onClick={onModalCancel}>
          {messages.CANCEL}
        </Button>
        <Button onClick={onModalConfirm}>{messages.UPDATE_WIDGET}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default IssueCounter
