import messages from '../../constants/messages'

const GET_WIDGET_DATA_BLOCKS_CONFIG = {
  cardName: { name: messages.WIDGET_DATA_CARD_NAME, value: 'cardName', card: true, widget: true },
  UBF: {
    value: 'UBF',
    name: messages.WIDGET_DATA_UBF,
    widget: true,
    params: { x: { name: 'X' }, y: { name: 'Y' } }
  }
}

export default GET_WIDGET_DATA_BLOCKS_CONFIG
