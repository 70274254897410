export const TOGGLE_PRESENTATION_MODE = 'TOGGLE_PRESENTATION_MODE'
export const TOGGLE_SLIDE_SHOW = 'TOGGLE_SLIDE_SHOW'

export const SET_SECTIONS_LOADING = 'SET_SECTIONS_LOADING'
export const REPLACE_SECTIONS_LOADING = 'REPLACE_SECTIONS_LOADING'
export const CLEAR_SECTIONS_LOADING = 'CLEAR_SECTIONS_LOADING'
export const ADD_RENDERED_SECTION = 'ADD_RENDERED_SECTION'
export const CLEAR_RENDERED_SECTIONS = 'CLEAR_RENDERED_SECTIONS'

export const SET_DETAILED_VIEW_MODAL_READY = 'SET_DETAILED_VIEW_MODAL_READY'

export const SET_PDF_GENERATION = 'SET_PDF_GENERATION'

export const SET_PDF_GENERATION_PROGRESS = 'SET_PDF_GENERATION_PROGRESS'
export const SET_PDF_WIDGET_GENERATION = 'SET_PDF_WIDGET_GENERATION'

export const SET_COPY_CARD_SNAPSHOT_IN_PROGRESS = 'SET_COPY_CARD_SNAPSHOT_IN_PROGRESS'

export const SET_SAVING_IN_PROGRESS = 'SET_SAVING_IN_PROGRESS'
