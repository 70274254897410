import gql from 'graphql-tag'
import VariableFilterFragment from './variableFilterFragment'

const variableFragmentName = 'VariableFragment'

export const VariableFragment = {
  name: variableFragmentName,
  fragment: gql`
        fragment ${variableFragmentName} on Variable {
            id
            name
            tenantId
            filter {
                ...${VariableFilterFragment.name}
            }
            creationDate
            createdBy
            tags
            object
            connectionsNumber
        }
        ${VariableFilterFragment.fragment}
    `
}

export default VariableFragment
