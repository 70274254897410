import { ENTITIES } from '../../constants/schemaConstants'
import { OBJECT_TYPES } from '../../../constants/workflows'
import { formatId } from '../../common/commonHelpers'

export const getCardPayload = ({ id }) => {
  const entity = ENTITIES[OBJECT_TYPES.CARD]
  const alias = `${entity}${formatId(id)}`
  return `${alias}: ${entity}(uuid: "${id}"){name,cardUuid:uuid`
}

export const getCardResponse = ({ board, cardUuid }) => {
  const alias = `${ENTITIES[OBJECT_TYPES.CARD]}${formatId(cardUuid)}`
  return board[alias] || null
}
