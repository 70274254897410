const spinner = {
  isOverlayShowing: false,
  isBoardContainerLoading: false,
  isCardBuilderContainerLoading: false,
  isTopMenuLoading: false,
  isBoardMenuLoading: false,
  isBoardNameLoading: false,

  cardWithNameSpinner: null, // uuid of card which name has spinner
  copyingColumnIndex: null, // uuid of column when it's copying (or it's cards is copying)
  loadingColumnUuid: null, // uuid of column when it's creating (or card is creating in it)

  isModalWindowBusy: false,

  isCardRequested: false,

  isStartEditRequested: false,
  isAutoSaveWorking: false,
  isSetActiveRequested: false,

  isTeamAdministrationPageLoading: false,
  isAuthLoading: false
}

export default spinner
