import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import messages from 'constants/messages'
import { Menu } from 'components/common/menu/menu'
import { Tooltip } from 'components/common/tooltip/tooltip'
import DotsSpinner from 'components/spinners/DotsSpinner'
import { debounce } from 'helpers/common/timeoutHelpers'
import ArrowIcon from 'assets/images/icons/ic_arrow_bottom_394549.svg?react'
import MultipleTeamsIcon from 'assets/images/icons/ic_team_18_8EA3B1.svg?react'
import WarningIcon from 'assets/images/icons/warning.svg?react'
import 'scss/filters/filterSelector/filter-search-dropdown.scss'

const TIMEOUT = 300

const ToggleInput = props => {
  const { value, placeholder, onChange, onToggle } = props

  const inputRef = useRef(null)

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  const handleFocus = () => {
    onToggle(true)
  }

  const handleBlur = () => {
    onToggle(false)
  }

  return (
    <div className="filter-search-dropdown-toggle" onClick={handleClick}>
      <input
        ref={inputRef}
        className="search-field"
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <ArrowIcon className="arrow-icon" />
    </div>
  )
}

const FilterSearchDropdown = props => {
  const { loading, filters, placeholder, onSearch, applyFilter, error } = props

  const anchorElRef = useRef(null)

  const [isOpen, setIsOpen] = useState(false)

  const [search, setSearch] = useState('')

  const debouncedSearch = debounce(onSearch, TIMEOUT)

  const toggleDropdown = state => {
    if (isOpen !== state) {
      setIsOpen(state)

      if (state) {
        onSearch()
      }
    }
  }

  const openDropdown = () => {
    toggleDropdown(true)
  }

  const closeDropdown = () => {
    toggleDropdown(false)
  }

  const onInputChange = event => {
    const value = event.target.value

    if (value !== search) {
      setSearch(value)
      openDropdown()
      debouncedSearch(value)
    }
  }

  const onSelect = filterUuid => {
    applyFilter(filterUuid)
    closeDropdown()
    setSearch('')
  }

  return (
    <div
      ref={anchorElRef}
      id="filter-search-dropdown"
      className={classNames('filter-search-dropdown', { open: isOpen, error })}
    >
      <ToggleInput
        value={search}
        placeholder={placeholder}
        onChange={onInputChange}
        onToggle={toggleDropdown}
      />
      {error && <p className="filter-search-dropdown-error-hint">{error}</p>}
      <Menu
        className="filter-search-dropdown-menu"
        open={isOpen}
        onClose={closeDropdown}
        anchorEl={anchorElRef.current}
        disableAutoFocus
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: -10,
          horizontal: 'left'
        }}
      >
        {loading && <DotsSpinner color="dark" size="lg" customClass="filters-search-loader" />}
        {!filters.length && !loading && (
          <div className="empty-state">{messages.NO_FILTERS_FOUND}</div>
        )}
        {filters.length > 0 && !loading && (
          <div className="filters-dropdown-list">
            {filters.map(item => (
              <div
                key={item.uuid}
                onClick={() => onSelect(item.uuid)}
                className="filters-dropdown-list-item"
              >
                <p>{item.name}</p>
                <div className="filter-indicators">
                  {item.isOpenScope && (
                    <Tooltip
                      placement="top"
                      title={
                        <center>
                          Heads up! System performance may slow by using this open scope filter.
                        </center>
                      }
                      shouldWrapChildrenWithDiv
                    >
                      <WarningIcon className="filter-indicator-icon" />
                    </Tooltip>
                  )}
                  {item.isCrossTeam && (
                    <Tooltip
                      placement="top"
                      title="This filter uses data from multiple teams"
                      shouldWrapChildrenWithDiv
                    >
                      <MultipleTeamsIcon className="filter-indicator-icon" />
                    </Tooltip>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </Menu>
    </div>
  )
}

FilterSearchDropdown.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  onSearch: PropTypes.func.isRequired,
  applyFilter: PropTypes.func.isRequired,
  error: PropTypes.string
}

FilterSearchDropdown.defaultProps = {
  filters: [],
  placeholder: '',
  loading: false
}

export default FilterSearchDropdown
