import { createReducer } from 'redux-create-reducer'
import * as ActionTypes from '../constants/actionTypes'
import { getDefaultWorkflowMeta } from '../helpers/workflowExecution/workflowMetaHelpers'

export const clickWorkflow = createReducer(
  {},
  {
    [ActionTypes.TOGGLE_CLICK_WORKFLOW_META_MODAL](state, action) {
      const { payload } = action

      return {
        ...state,
        workflowMetaModalData: {
          state: payload ? payload.state : false,
          tenantId: payload ? payload.tenantId : null,
          boardId: payload ? payload.boardId : null,
          cardUuid: payload ? payload.cardUuid : null,
          widgetUuid: payload ? payload.widgetUuid : null,
          workflowMeta: payload ? payload.workflowMeta : getDefaultWorkflowMeta(),
          script: payload ? payload.script : null
        }
      }
    },

    [ActionTypes.TOGGLE_CLICK_WORKFLOW_OVERLAY](state, action) {
      return {
        ...state,
        isWorkflowOverlayShown: action.payload
      }
    },

    [ActionTypes.SET_CLICK_WORKFLOW_LOADING](state, action) {
      return {
        ...state,
        isWorkflowExecutionLoading: action.payload
      }
    },

    [ActionTypes.SET_CLICK_WORKFLOW_RUNNING](state, action) {
      return {
        ...state,
        isWorkflowRunning: action.payload || false
      }
    },

    [ActionTypes.SET_CLICK_WORKFLOW_RUNNING_META](state, action) {
      return {
        ...state,
        runningWorkflowMeta: action.payload
      }
    },

    [ActionTypes.TOGGLE_CLICK_WORKFLOW_SUCCESS_MESSAGE](state, action) {
      return {
        ...state,
        isWorkflowSuccessMessageShown: action.payload || false
      }
    }
  }
)

export default clickWorkflow
