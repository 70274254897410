import React from 'react'
import PropTypes from 'prop-types'
import '../../scss/spinners/dots-spinner.scss'

const DotsSpinner = props => {
  const { size, color, customClass, position } = props

  return (
    <section className={`dots-spinner_wrapper ${customClass} position-${position}`}>
      <div className={`dots-spinner ${color} ${size}`}>
        <div className="dots-spinner__item1" />
        <div className="dots-spinner__item2" />
        <div className="dots-spinner__item3" />
      </div>
    </section>
  )
}

DotsSpinner.defaultProps = {
  color: 'light',
  size: 'sm',
  customClass: '',
  position: 'center'
}

DotsSpinner.propTypes = {
  color: PropTypes.oneOf(['light', 'dark', 'very-dark']),
  size: PropTypes.oneOf(['sm', 'lg']),
  customClass: PropTypes.string,
  position: PropTypes.oneOf(['start', 'center'])
}

export default DotsSpinner
