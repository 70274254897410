import { ENTITIES } from '../../constants/schemaConstants'
import { OBJECT_TYPES } from '../../../constants/workflows'
import { formatId } from '../../common/commonHelpers'

export const getBoardPayload = ({ id }) => {
  const entity = ENTITIES[OBJECT_TYPES.BOARD]
  const alias = `${entity}${formatId(id)}`
  return `${alias}: ${entity}(id: "${id}"){name,boardId:id`
}

export const getBoardResponse = ({ tenant, boardId }) => {
  const alias = `${ENTITIES[OBJECT_TYPES.BOARD]}${formatId(boardId)}`
  return tenant[alias] || null
}
