import React from 'react'

import { Switcher } from 'components/common/switcher/switcher'

import messages from '../../../constants/messages'
import { OPERATOR } from '../../../constants/filters/filtersConstants'

import '../../../scss/filters/filterConfiguration/conditions-block-header.scss'

const ConditionsBlockHeader = props => {
  const { operator, changeOperator, ConditionsInfo, isReadOnly } = props

  return (
    <header className="conditions-block-header">
      <ConditionsInfo />
      <div className="operator-wrapper">
        <div className="switcher-label">{messages.COMBINE_WITH}</div>
        <Switcher
          id="conditions-operator"
          className="conditions-operator-switcher"
          checked={operator === OPERATOR.AND}
          onChange={changeOperator}
          onLabel="OR"
          offLabel="AND"
          disabled={isReadOnly}
        />
      </div>
    </header>
  )
}

export default ConditionsBlockHeader
