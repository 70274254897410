import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import '../../scss/buttons/up-text-button.scss'

const TextButton = props => {
  const {
    as: Component,
    id,
    type,
    size,
    children,
    className,
    appearance,
    onClick,
    disabled,
    ...rest
  } = props

  return (
    <Component
      id={id}
      type={type}
      className={classNames('up-text-button', appearance, size, className, { disabled })}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {children}
    </Component>
  )
}

TextButton.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  href: PropTypes.string,
  target: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.string]).isRequired,
  className: PropTypes.string,
  appearance: PropTypes.oneOf(['primary', 'secondary']),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func
}

TextButton.defaultProps = {
  as: 'button',
  type: 'button',
  size: 'medium',
  className: '',
  appearance: 'primary',
  onClick: null,
  disabled: false
}

export default TextButton
