export const SET_WORKFLOW_DATA = 'SET_WORKFLOW_DATA'
export const CLEAR_WORKFLOW_DATA = 'CLEAR_WORKFLOW_DATA'

export const CREATE_WORKFLOW_BLOCK = 'CREATE_WORKFLOW_BLOCK'
export const DELETE_WORKFLOW_BLOCK = 'DELETE_WORKFLOW_BLOCK'
export const UPDATE_WORKFLOW_BLOCK = 'UPDATE_WORKFLOW_BLOCK'

export const CHANGE_WORKFLOW_BLOCK_INPUT = 'CHANGE_WORKFLOW_BLOCK_INPUT'
export const CHANGE_WORKFLOW_BLOCK_OUTPUT = 'CHANGE_WORKFLOW_BLOCK_OUTPUT'
export const CHANGE_WORKFLOW_BLOCK_OUTPUT_TYPE = 'CHANGE_WORKFLOW_BLOCK_OUTPUT_TYPE'
export const CLEAR_WORKFLOW_BLOCKS_STOPPED_INPUTS = 'CLEAR_WORKFLOW_BLOCKS_STOPPED_INPUTS'

export const UPDATE_OUTPUT_NAME = 'UPDATE_OUTPUT_NAME'

export const TOGGLE_VALIDATION_ERROR = 'TOGGLE_VALIDATION_ERROR'
export const TOGGLE_BLOCK_ERROR = 'TOGGLE_BLOCK_ERROR'
export const SET_INVALID_BLOCKS = 'SET_INVALID_BLOCKS'
export const TOGGLE_WORKFLOW_MANDATORY_ERROR = 'TOGGLE_WORKFLOW_MANDATORY_ERROR'
export const TOGGLE_ALL_CHANGES_SAVED = 'TOGGLE_ALL_CHANGES_SAVED'
export const TOGGLE_WORKFLOW_CONFIRMATION_SHOWN = 'TOGGLE_WORKFLOW_CONFIRMATION_SHOWN'

export const UPDATE_WORKFLOW_DEFINITION = 'UPDATE_WORKFLOW_DEFINITION'

export const TOGGLE_WORKFLOW_OPEN_STATE = 'TOGGLE_WORKFLOW_OPEN_STATE'
export const TOGGLE_INVALID_BLOCKS_OPEN_STATE = 'TOGGLE_INVALID_BLOCKS_OPEN_STATE'

export const SET_ACTIVE_WORKFLOW = 'SET_ACTIVE_WORKFLOW'

export const VALIDATE_BLOCK = 'VALIDATE_BLOCK'
export const VALIDATE_ALL_BLOCK = 'VALIDATE_ALL_BLOCK'
export const TOGGLE_WORKFLOW_LOADER = 'TOGGLE_WORKFLOW_LOADER'

export const TOGGLE_WORKFLOW_CREATION_MODE = 'TOGGLE_WORKFLOW_CREATION_MODE'
