export enum EVariableConnectionObjectType {
  FILTER = 'filter',
  WIDGET = 'widget',
  LOGICAL_RULE = 'logicalRule'
}

export interface IConnectionObject {
  type: EVariableConnectionObjectType
}

export interface IFilterConnectionObject extends IConnectionObject {
  uuid: string
  name: string
  object: string
  creationDate: number
  ownerId: string
}

export interface IWidgetConnectionObject extends IConnectionObject {
  tenantId: string
  boardId: string
  widgetUuid: string
  cardUuid: string
  widgetTitle: string
  cardName: string
  widgetClassName: string
}

export interface ILogicalRuleConnectionObject extends IConnectionObject {
  logicalRuleName: string
  tenantId: string
}

export type TVariableConnection = {
  id: string
  object: IConnectionObject
}
