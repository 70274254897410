import React, { Component } from 'react'
import PropTypes from 'prop-types'

import PlainInput from 'components/fields/PlainInput'

import messages from '../../../constants/messages'

import '../../../scss/workflowBuilder/common/number-field.scss'

class NumberFieldContainer extends Component {
  constructor(props) {
    super(props)

    this.blurNumericField = this.blurNumericField.bind(this)
    this.changeNumericValue = this.changeNumericValue.bind(this)
  }

  blurNumericField(event) {
    const { value } = event.target

    // because coordinates start from 1
    if (!value || (value && parseInt(value, 10) === 0)) {
      this.props.onChange(1)
    }
  }

  changeNumericValue(event) {
    const { value } = event.target
    const digitsPattern = /^[0-9]*$/gi

    if (digitsPattern.test(value)) {
      this.props.onChange(value)
    }
  }

  render() {
    const { value, disabled, maxLength } = this.props

    return (
      <PlainInput
        className="input-field number-field"
        placeholder={messages.SECTION_NUMBER}
        maxLength={maxLength}
        value={value}
        onBlur={this.blurNumericField}
        onChange={this.changeNumericValue}
        disabled={disabled}
      />
    )
  }
}

NumberFieldContainer.propTypes = {
  value: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  maxLength: PropTypes.number
}

NumberFieldContainer.defaultProps = {
  maxLength: 2,
  disabled: false
}

export default NumberFieldContainer
