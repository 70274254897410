/* eslint-disable import/no-cycle */
import * as appAPI from 'api/appAPI'

import * as actions from 'constants/actionTypes'
import messages from 'constants/messages'
import { EJobStatuses, EJobTypes } from 'features/jobs/jobs.types'
import { exitFromAppBuilder } from 'helpers/routesHelpers'
import {
  validateArrayNotEmpty,
  validateEmail,
  validateForNotEmpty,
  validatePurchaseInfo,
  validateVideoLink,
  validateFileTemplateInfo,
  validateTrialInfo
} from 'helpers/validationHelpers'
import { saveApp, setAppMeta, setAppMetaErrors, toggleAppSaving } from './appBuilderActions'
import { showToastMessage, toggleAppPublishingIndicator } from './boardActions'
import { boardMenuRequest } from './profileActions'
import { addNewJob, jobCompleted } from './socketActions'

export const toggleAppPublish = payload => ({
  type: actions.TOGGLE_APP_PUBLISH,
  payload
})

export const toggleAppUnpublish = payload => ({
  type: actions.TOGGLE_APP_UNPUBLISH,
  payload
})

export const validateAllAppMeta = appMeta => dispatch => {
  const errors = {
    purchaseInfo: validatePurchaseInfo(appMeta),
    fileTemplateInfo: validateFileTemplateInfo(appMeta.fileTemplateInfo),
    authorName: validateForNotEmpty(appMeta.authorName || ''),
    authorEmail: appMeta.authorEmail ? validateEmail(appMeta.authorEmail) : '',
    videoLink: appMeta.videoLink ? validateVideoLink(appMeta.videoLink) : '',
    version: validateForNotEmpty(appMeta.version || ''),
    categoryIds: validateArrayNotEmpty(appMeta.categoryIds),
    trialInfo: validateTrialInfo(appMeta)
  }

  dispatch(setAppMetaErrors(errors))

  return Object.values(errors).some(error => !!error)
}

export const updateAppPublishingStatus = payload => async (dispatch, getState) => {
  const { jobs } = getState().socket

  const { intervalId, appId, versionId } = payload

  try {
    const { history: newVersion } = await appAPI.getAppStoreInfo({
      expand: {
        appStoreInfo: false,
        history: true
      },
      variables: {
        appId,
        versionId,
        historySize: null
      }
    })

    const currentJob = jobs.find(job => job.id === versionId)

    // if publishing is longer than 2 min, set error state
    if (currentJob && Date.now() - currentJob.createdAt > 2 * 60 * 1000) {
      dispatch(
        jobCompleted({
          id: versionId,
          data: {},
          status: EJobStatuses.ERROR,
          progress: { current: null, finishedAt: null, startedAt: null, total: 100 },
          type: EJobTypes.PUBLISH_APP_JOB
        })
      )
      clearInterval(intervalId)
      dispatch(boardMenuRequest())
    }

    if (newVersion.length) {
      dispatch(
        jobCompleted({
          id: versionId,
          data: {},
          status: EJobStatuses.DONE,
          progress: { current: null, finishedAt: null, startedAt: null, total: 100 },
          type: EJobTypes.PUBLISH_APP_JOB
        })
      )
      clearInterval(intervalId)
      dispatch(boardMenuRequest())
    }
  } catch (err) {
    console.error(err)
  }
}

const addPublishJob = payload => dispatch => {
  const { appId, versionId, name } = payload

  dispatch(
    addNewJob({
      data: {
        name,
        appId
      },
      createdAt: Date.now(),
      id: versionId,
      status: EJobStatuses.RUNNING,
      progress: { current: null, finishedAt: null, startedAt: null, total: 0 },
      type: EJobTypes.PUBLISH_APP_JOB
    })
  )
  dispatch(toggleAppPublishingIndicator(true))

  const intervalId = setInterval(() => {
    dispatch(
      updateAppPublishingStatus({
        intervalId,
        appId,
        versionId
      })
    )
  }, 5 * 1000)
}

export const publishApp = () => async (dispatch, getState) => {
  const {
    appBuilder: { hasChanges, appMeta }
  } = getState()

  const hasErrors = dispatch(validateAllAppMeta(appMeta))

  if (hasErrors) {
    return
  }

  dispatch(toggleAppPublish(true))

  try {
    if (hasChanges) {
      await dispatch(saveApp({ requestBoardMenu: false }))
    }

    dispatch(toggleAppSaving(true))

    const {
      app: { id: appId, tenantId, name }
    } = getState().appBuilder

    const {
      data: { versionId }
    } = await appAPI.publishApp({ tenantId, appId })

    dispatch(addPublishJob({ appId, name, versionId }))

    await dispatch(boardMenuRequest())

    dispatch(toggleAppSaving(false))
    dispatch(toggleAppPublish(false))

    exitFromAppBuilder()
  } catch (err) {
    console.error(err)
    const appNameError = err?.name && messages.PUBLISH_APP_NAME_ERROR_MESSAGE
    dispatch(
      showToastMessage({
        text: err?.message || appNameError || messages.PUBLISH_ERROR_MESSAGE,
        size: 'M'
      })
    )
    dispatch(toggleAppPublish(false))
    dispatch(toggleAppSaving(false))
  }
}

export const unpublishApp = () => async (dispatch, getState) => {
  const {
    app: { tenantId, id: appId },
    appMeta
  } = getState().appBuilder

  dispatch(toggleAppUnpublish(true))

  try {
    await appAPI.unpublishApp({ tenantId, appId })

    dispatch(setAppMeta({ ...appMeta, isPublished: false }))
    dispatch(toggleAppUnpublish(false))
  } catch (err) {
    console.error(err)

    dispatch(toggleAppUnpublish(false))
  }
}
