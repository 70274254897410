import classNames from 'classnames'
import React from 'react'

import DataProvidersListContainer from 'containers/dataLinking/DataProvidersListContainer'
import { isTablet } from 'helpers/responsiveHelpers'

import {
  isInside,
  getOverlayPosition,
  isPullRight
} from 'helpers/detailedView/widgetOverlayHelpers'

const WidgetOverlay = props => {
  const { isPencilShown, isProvidersListShown, widgetData, onWidgetClick } = props

  if (isTablet()) {
    return null
  }

  return (
    <div
      className={classNames('widget-overlay-on-detailed', {
        'has-pencil': isPencilShown,
        inside: isProvidersListShown && isInside(widgetData)
      })}
      style={getOverlayPosition(widgetData)}
    >
      {isPencilShown && (
        <span className="action-on-detailed-icon edit-icon" onClick={onWidgetClick} />
      )}
      {isProvidersListShown && (
        <DataProvidersListContainer
          dataLinks={widgetData.dataLink}
          pullRight={isPullRight(widgetData)}
        />
      )}
    </div>
  )
}

export default WidgetOverlay
