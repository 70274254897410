import { useEffect, useMemo } from 'react'
import CircleSpinner from 'components/spinners/CircleSpinner'
import { type TOption, EQueryOutputTypes } from 'features/dictionaries/dictionariesSettings.types'
import { OptionsTable } from 'features/dictionaries/optionsTable/optionsTable'
import { useDropdownContentSetupStore } from '../dropdownContentSetup.store'
import { EDropdownOptionsSource } from '../dropdownContentSetup.types'
import './dropdown-options-table.scss'

const HINT_TEXT = 'Select item'

const ManualOptionsTable = () => {
  const manualOptions = useDropdownContentSetupStore(state => state.manualOptions)
  const manualOptionsHint = useDropdownContentSetupStore(state => state.manualOptionsHint)
  const errors = useDropdownContentSetupStore(state => state.errors)
  const addManualOption = useDropdownContentSetupStore(state => state.addManualOption)
  const deleteManualOption = useDropdownContentSetupStore(state => state.deleteManualOption)
  const updateManualOption = useDropdownContentSetupStore(state => state.updateManualOption)
  const updateHint = useDropdownContentSetupStore(state => state.updateHint)
  const validateDropdownContent = useDropdownContentSetupStore(
    state => state.validateDropdownContent
  )

  const handleOptionsUpdate = (updatedOptions: TOption[]) => {
    useDropdownContentSetupStore.setState({ manualOptions: updatedOptions })
    validateDropdownContent()
  }

  const handleGetOptionErrorText = ({ index, value }: { index: number; value: string }) => {
    if (errors.optionsError?.[index]) {
      return value ? '' : "You can't leave this empty."
    }
    return ''
  }

  return (
    <OptionsTable
      options={manualOptions}
      isHintEnabled={!!manualOptionsHint.flag}
      hintMessage={manualOptionsHint.textMessage || HINT_TEXT}
      addNewOption={addManualOption}
      deleteOption={deleteManualOption}
      changeOptionValue={updateManualOption}
      updateOptions={handleOptionsUpdate}
      changeHintValue={value => updateHint(true, value)}
      optionsError={errors.optionsError}
      getOptionErrorText={handleGetOptionErrorText}
      hintError={errors.hintError}
      onChangeHintEnabled={() =>
        updateHint(!manualOptionsHint.flag, manualOptionsHint.textMessage || HINT_TEXT)
      }
    />
  )
}

const DictionaryOptionsTable = ({ tenantId }: { tenantId: string }) => {
  const selectedDictionaryId = useDropdownContentSetupStore(state => state.selectedDictionaryId)
  const dictionaries = useDropdownContentSetupStore(state => state.dictionaries)
  const areDictionariesLoading = useDropdownContentSetupStore(state => state.areDictionariesLoading)
  const options = useDropdownContentSetupStore(state => state.options)
  const selectedQueryOutputType = useDropdownContentSetupStore(
    state => state.selectedQueryOutputType
  )
  const areOptionsLoading = useDropdownContentSetupStore(state => state.areOptionsLoading)
  const fetchQueryOptions = useDropdownContentSetupStore(state => state.fetchQueryOptions)

  const selectedDictionary = useMemo(() => {
    if (!selectedDictionaryId) return null

    return dictionaries.find(dictionary => dictionary.value === selectedDictionaryId)
  }, [dictionaries, selectedDictionaryId])

  const isQueryWidget = selectedDictionary?.queryWidget?.flag

  const handleOptionsUpdate = (updatedOptions: TOption[]) => {
    const { updateDictionary } = useDropdownContentSetupStore.getState()

    if (!selectedDictionary) return

    updateDictionary({ ...selectedDictionary, options: updatedOptions })
  }

  useEffect(() => {
    if (isQueryWidget) {
      void fetchQueryOptions({
        tenantId,
        boardId: selectedDictionary.queryWidget?.boardId || '',
        cardUuid: selectedDictionary.queryWidget?.cardId || '',
        widgetUuid: selectedDictionary.queryWidget?.queryId || ''
      })
    }
  }, [selectedDictionary, isQueryWidget, fetchQueryOptions, tenantId])

  useEffect(() => {
    if (!selectedDictionary && !areDictionariesLoading) {
      useDropdownContentSetupStore.setState({ selectedDictionaryId: null })
    }
  }, [selectedDictionary, areDictionariesLoading])

  if (areDictionariesLoading) {
    return <CircleSpinner className="dictionary-options-table-loader" />
  }

  return (
    <OptionsTable
      hasOptionsSource={!!selectedDictionary}
      options={isQueryWidget ? options : selectedDictionary?.options || []}
      updateOptions={handleOptionsUpdate}
      hintMessage={selectedDictionary?.hint?.textMessage || HINT_TEXT}
      areOptionsFromPlainList={selectedQueryOutputType === EQueryOutputTypes.plainList}
      isLoading={areOptionsLoading}
      isHintEnabled={!!selectedDictionary?.hint?.flag}
    />
  )
}

const QueryOptionsTable = () => {
  const selectedQueryId = useDropdownContentSetupStore(state => state.selectedQueryId)
  const selectedQueryOutputType = useDropdownContentSetupStore(
    state => state.selectedQueryOutputType
  )
  const options = useDropdownContentSetupStore(state => state.options)
  const areOptionsLoading = useDropdownContentSetupStore(state => state.areOptionsLoading)
  const manualOptionsHint = useDropdownContentSetupStore(state => state.manualOptionsHint)
  const isQueryLoading = useDropdownContentSetupStore(state => state.isQueryLoading)
  const updateHint = useDropdownContentSetupStore(state => state.updateHint)

  if (isQueryLoading) {
    return <CircleSpinner className="query-options-table-loader" />
  }

  return (
    <OptionsTable
      options={selectedQueryId ? options : []}
      hasOptionsSource={!!selectedQueryId}
      areOptionsFromPlainList={selectedQueryOutputType === EQueryOutputTypes.plainList}
      isLoading={areOptionsLoading}
      isHintEnabled={!!manualOptionsHint.flag}
      hintMessage={manualOptionsHint.textMessage || HINT_TEXT}
      changeHintValue={value => updateHint(true, value)}
      onChangeHintEnabled={() =>
        updateHint(!manualOptionsHint.flag, manualOptionsHint.textMessage || HINT_TEXT)
      }
    />
  )
}

export const DropdownOptionsTable = ({ tenantId }: { tenantId: string }) => {
  const optionsSource = useDropdownContentSetupStore(state => state.optionsSource)

  const getOptionsTable = () => {
    switch (optionsSource) {
      case EDropdownOptionsSource.list:
        return <DictionaryOptionsTable tenantId={tenantId} />
      case EDropdownOptionsSource.query:
        return <QueryOptionsTable />
      default:
        return <ManualOptionsTable />
    }
  }

  return <div className="dropdown-options-table">{getOptionsTable()}</div>
}
