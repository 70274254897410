import { useMemo } from 'react'
import {
  type IFilterRuleCondition,
  type IFilterRulePredicateConfig,
  type IFilterRulesConfigAttributeParam,
  type TFilterRulesConfig,
  EFilterRuleConditionPredicateName
} from 'features/filter/filter.types'
import { type TFilterRuleConditionsProps } from 'features/filter/filterRuleConditions/filterRuleConditions'
import { getPredicatesConfigOptions } from 'features/filter/filterRuleConditions/filterRuleConditions.helpers'
import { ConditionAttribute } from '../conditionAttribute/conditionAttribute'
import { PredicateParams } from './predicateParams/predicateParams'

type TProps = Pick<
  TFilterRuleConditionsProps,
  'onParamsChange' | 'onConditionSelect' | 'onPredicateSelect'
> & {
  ruleCondition: IFilterRuleCondition
  predicateName: EFilterRuleConditionPredicateName
  predicatesConfig: TFilterRulesConfig<IFilterRulePredicateConfig>
  onFocus: () => void
  onBlur: () => void
}

export const ConditionPredicateAttribute = ({
  ruleCondition,
  predicateName,
  predicatesConfig,
  onParamsChange,
  onConditionSelect,
  onPredicateSelect,
  onFocus,
  onBlur
}: TProps) => {
  const predicateValue = ruleCondition[predicateName] as string
  const predicateConfig = predicateValue ? predicatesConfig[predicateValue] : null

  const predicateConfigOptions = useMemo(
    () => getPredicatesConfigOptions(predicateName, predicatesConfig),
    [predicateName, predicatesConfig]
  )

  const handleParamsChange = (param: IFilterRulesConfigAttributeParam['name'], value: unknown) => {
    if (onParamsChange) {
      onParamsChange(ruleCondition.uuid, param, value)
    }
  }

  const handleAttributeSelect = (attribute: string) => {
    if (ruleCondition[predicateName] === attribute) return

    if (predicateName === EFilterRuleConditionPredicateName.condition) {
      onConditionSelect(ruleCondition.uuid, attribute)
      return
    }

    const selectedPredicateConfig = predicatesConfig[attribute]

    if (selectedPredicateConfig) {
      onPredicateSelect(ruleCondition, {
        predicateConfig: selectedPredicateConfig,
        predicateName,
        predicate: attribute
      })
    }
  }

  return (
    <ConditionAttribute
      attributeValue={predicateValue}
      attributeOptions={predicateConfigOptions}
      onAttributeSelect={handleAttributeSelect}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      {predicateConfig?.params && (
        <PredicateParams
          ruleCondition={ruleCondition}
          params={predicateConfig.params}
          onChange={handleParamsChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      )}
    </ConditionAttribute>
  )
}
