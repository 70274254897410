import classNames from 'classnames'
import messages from 'constants/messages'
import { type IJob, EJobStatuses } from 'features/jobs/jobs.types'
import CircleSpinner from 'components/spinners/CircleSpinner'

type TProps = { job: IJob }

export const WorkflowExecutionIndicatorItem = ({ job }: TProps) => (
  <div className="board_item">
    <div className="board_data">
      <i
        className={classNames('icon', {
          'icon-workflow': job.status !== EJobStatuses.DONE,
          'icon-workflow-colored': job.status === EJobStatuses.DONE
        })}
      />
      <div className="board_content">
        <span className="board_name bold">{job.data.name}</span>
      </div>
    </div>
    <span className="board_progress">
      {job.status !== EJobStatuses.DONE && job.status !== EJobStatuses.ERROR && (
        <CircleSpinner className="copied-board__spinner" />
      )}
      {job.status === EJobStatuses.DONE && (
        <span className="blue-link static">{messages.FINISHED}</span>
      )}
      {job.status === EJobStatuses.ERROR && (
        <span className="blue-link static failed">{messages.FAILED}</span>
      )}
    </span>
  </div>
)
