import { type KeyboardEvent, type ReactElement, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import { type TFilterRuleConditionAttributeOption } from 'features/filter/filterRuleConditions/filterRuleConditions.types'
import { AttributeOptionsMenu } from './attributeOptionsMenu/attributeOptionsMenu'
import './condition-attribute.scss'

type TProps = {
  attributeValue: TFilterRuleConditionAttributeOption['value'] | undefined
  attributeOptions: TFilterRuleConditionAttributeOption[]
  onAttributeSelect: (value: TFilterRuleConditionAttributeOption['value']) => void
  onFocus: () => void
  onBlur: () => void
  className?: string
  placeholder?: string
  children?: ReactElement | null
}

export const ConditionAttribute = ({
  attributeValue,
  attributeOptions,
  onAttributeSelect,
  onFocus,
  onBlur,
  className,
  placeholder = 'Select attribute',
  children
}: TProps) => {
  const anchorEl = useRef<HTMLDivElement>(null)

  const [isOpen, setIsOpen] = useState(false)

  const attribute = useMemo(
    () => attributeOptions.find(option => option.value === attributeValue),
    [attributeOptions, attributeValue]
  )

  const openAttributeMenu = () => {
    setIsOpen(true)
  }

  const closeAttributeMenu = () => {
    setIsOpen(false)
  }

  const getAttributeMenuStyle = () => {
    if (!anchorEl.current) return {}

    return {
      minWidth: anchorEl.current.offsetWidth
    }
  }

  const handleAttributeSelect = (value: string) => {
    onAttributeSelect(value)
    closeAttributeMenu()
  }

  const handleAttributeBlur = () => {
    closeAttributeMenu()
    onBlur()
  }

  const handleAttributeKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (isOpen) {
        closeAttributeMenu()

        return
      }

      openAttributeMenu()
    }
  }

  return (
    <div
      ref={anchorEl}
      className={classNames('filter-rule-condition-attribute', className, {
        open: isOpen
      })}
      tabIndex={0}
      onClick={openAttributeMenu}
      onBlur={handleAttributeBlur}
      onKeyDown={handleAttributeKeyDown}
      onFocus={onFocus}
    >
      <div className="attribute">{attribute?.label ?? placeholder}</div>
      {children}
      <AttributeOptionsMenu
        open={isOpen}
        anchorEl={anchorEl.current}
        attributeValue={attributeValue}
        attributeOptions={attributeOptions}
        style={getAttributeMenuStyle()}
        onSelect={handleAttributeSelect}
        onClose={closeAttributeMenu}
      />
    </div>
  )
}
