import { createReducer } from 'redux-create-reducer'
import * as ActionTypes from '../constants/actionTypes'

export const workspacesAdministration = createReducer(
  {},
  {
    [ActionTypes.WORKSPACES_RECEIVE](state, action) {
      return { ...state, workspacesList: action.payload }
    },
    [ActionTypes.WORKSPACES_RECEIVE_ERROR](state) {
      return { ...state, workspacesList: [] }
    }
  }
)

export default workspacesAdministration
