import { generateGUID } from 'helpers/common/commonHelpers'
import { EWorkflowBlockTypes } from 'constants/workflowBuilder/blocksTypes'
import { BLOCKS_DEFINITION } from 'features/workflow/workflowBuilder/model/workflowBuilder.constants'
import {
  addBlockIndex,
  addBlockToMap,
  clearSelectedOutput,
  createBlock,
  recalculatedBlocksIndexes,
  removeBlockFromMap,
  removeBlockIndex
} from './blocksOperations'
import { getFilledInputs, updateBlockInput } from './inputOperations'
import {
  addBlockToHistory,
  removeBlockFromHistory,
  removeOutputsHistory,
  updateOutputsHistory,
  updateOutputsHistoryBlock
} from './outputsHistoryOperations'
import {
  addDefaultOutputsNames,
  changeOutputNamesFromInput,
  changeOutputNamesFromMeta,
  updateOutputNames
} from './outputsNamesHelpers'
import { addOutputs, getInitialOutputs, removeOutputs, updateOutputs } from './outputsOperations'
import { isBlockHasStaticErrors } from './blocksValidation'
import {
  isOutputStopped,
  addOutputStopper,
  removeOutputStopper,
  removeOutputStopperByBlockId
} from './outputsStoppersHelpers'

export const createNewBlock = args => {
  const { payload } = args

  // create block from definition
  const block = createBlock(payload)

  // add block index
  const workflowBlocks = addBlockIndex({
    workflowBlocks: args.workflowBlocks,
    index: block.index,
    blockId: block.id
  })

  let workflowBlocksMap = addBlockToMap({
    workflowBlocksMap: args.workflowBlocksMap,
    block
  })

  // recalculate indexes inside of all blocks
  workflowBlocksMap = recalculatedBlocksIndexes({ workflowBlocks, workflowBlocksMap })
  // if block just pipe inputs ot output - no reason to create outputs and history
  if (!block.isCreateOutput) {
    return {
      workflowBlocks,
      workflowBlocksMap,
      // set created block active
      activeBlockId: block.id
    }
  }

  // update global outputs map
  const outputs = addOutputs({
    outputsMap: args.outputsMap,
    outputs: block.output
  })

  // update global outputs history
  const outputsHistory = updateOutputsHistory({
    outputsHistory: args.outputsHistory,
    outputs: block.output,
    blockId: block.id
  })

  // set default outputs names if present
  const outputsNames = addDefaultOutputsNames({
    outputsNames: args.outputsNames,
    outputs: block.output
  })

  return {
    workflowBlocks,
    workflowBlocksMap,
    outputs,
    outputsHistory,
    outputsNames,
    // set created block active
    activeBlockId: block.id
  }
}

export const createInitialBlocks = () => {
  const initialState = createNewBlock({
    payload: { configuration: BLOCKS_DEFINITION[EWorkflowBlockTypes.TRIGGER], index: 0 },
    outputsMap: getInitialOutputs(),
    outputsHistory: {},
    outputsNames: {},
    workflowBlocks: [],
    workflowBlocksMap: {}
  })

  return {
    ...initialState,
    ...createNewBlock({
      payload: { configuration: BLOCKS_DEFINITION[EWorkflowBlockTypes.FINISH], index: 1 },
      outputsMap: initialState.outputs,
      outputsHistory: initialState.outputsHistory,
      outputsNames: initialState.outputsNames,
      workflowBlocks: initialState.workflowBlocks,
      workflowBlocksMap: initialState.workflowBlocksMap
    }),
    activeBlockId: initialState.activeBlockId // to make trigger block active
  }
}

export const deleteBlock = args => {
  const { blockId } = args
  const block = args.workflowBlocksMap[blockId]

  let { outputsHistory } = args
  let outputs = args.outputsMap
  let { workflowBlocksMap } = args

  // if block can create output need to delete all block outputs
  // and history related to this outputs
  if (block.isCreateOutput) {
    // remove outputs history
    outputsHistory = removeOutputsHistory({
      outputsHistory: args.outputsHistory,
      outputs: block.output
    })

    // delete block outputs
    outputs = removeOutputs({ outputsMap: args.outputsMap, blockId })

    // need to clear all inputs in all blocks which have as value
    // one of outputs from current block
    workflowBlocksMap = clearSelectedOutput({
      workflowBlocks: args.workflowBlocks,
      workflowBlocksMap: args.workflowBlocksMap,
      output: block.output
    })
  } else {
    // otherwise need to remove blockId from history of chosen input
    // (only for input which can modify history)
    const filledInputs = getFilledInputs({ block })

    filledInputs.forEach(value => {
      outputsHistory = removeBlockFromHistory({
        outputsHistory,
        outputId: value,
        blockId
      })
    })
  }

  // delete block
  workflowBlocksMap = removeBlockFromMap({ workflowBlocksMap, blockId })

  // delete block index
  const workflowBlocks = removeBlockIndex({ workflowBlocks: args.workflowBlocks, blockId })

  // recalculate indexes inside of all blocks
  workflowBlocksMap = recalculatedBlocksIndexes({ workflowBlocks, workflowBlocksMap })

  // remove output stopper if this block stops it
  const outputsStoppers = removeOutputStopperByBlockId({
    outputsStoppers: args.outputsStoppers,
    blockId
  })

  return {
    workflowBlocks,
    workflowBlocksMap,
    outputs,
    outputsHistory,
    outputsStoppers,
    activeBlockId: -1 // reset active block
  }
}

export const changeInput = args => {
  const {
    payload: {
      addToOutputsStoppers = false,
      fieldName,
      blockId,
      outputId,
      oldOutputId,
      outputType,
      error
    }
  } = args

  // update block
  const workflowBlocksMap = updateBlockInput({
    workflowBlocksMap: args.workflowBlocksMap,
    blockId,
    input: { [fieldName]: outputId },
    outputType,
    error
  })

  let { outputsStoppers } = args

  // update outputs stoppers for old and new outputs if it's needed
  if (outputId && addToOutputsStoppers) {
    outputsStoppers = addOutputStopper({
      outputsStoppers,
      outputId,
      blockId
    })
  }

  if (oldOutputId && addToOutputsStoppers) {
    outputsStoppers = removeOutputStopper({
      outputsStoppers,
      outputId: oldOutputId
    })
  }

  const block = workflowBlocksMap[blockId]

  const { canUpdateHistory } = block.inputDefinition[fieldName]

  if (!canUpdateHistory) {
    return {
      workflowBlocksMap,
      outputsNames: {
        ...args.outputsNames,
        ...changeOutputNamesFromInput(block)
      },
      outputsStoppers
    }
  }

  let { outputsHistory } = args

  // if input marked as "canUpdateHistory" needs to update outputs history for chosen output
  if (outputId) {
    outputsHistory = addBlockToHistory({
      workflowBlocksMap,
      outputsHistory,
      outputId,
      blockId
    })
  }

  // also need to clear history of previous selected output
  if (oldOutputId) {
    outputsHistory = removeBlockFromHistory({
      outputsHistory,
      outputId: oldOutputId,
      blockId
    })
  }

  return {
    workflowBlocksMap,
    outputsHistory,
    outputsStoppers
  }
}

export const toggleOpenState = args => {
  const { invalidBlocksMap } = args

  const workflowBlocksMap = Object.entries(args.workflowBlocksMap).reduce(
    (blocksMap, [key, block]) => {
      blocksMap[key] = {
        ...block,
        open: isBlockHasStaticErrors(block) || !!invalidBlocksMap[key]
      }

      return blocksMap
    },
    {}
  )

  return { workflowBlocksMap }
}

// clear stopped outputs which selected as inputs in blocks below blocks where outputs ware stopped
export const clearStoppedInputs = args => {
  const { workflowBlocks, workflowBlocksMap, outputsStoppers } = args

  const blocksMap = { ...workflowBlocksMap }
  let { outputsHistory } = args

  workflowBlocks.forEach(blockId => {
    const block = blocksMap[blockId]

    const input = Object.entries(block.input).reduce((acc, [fieldName, outputId]) => {
      const isStopped = isOutputStopped({
        outputId,
        blockIndex: block.index,
        workflowBlocksMap,
        outputsStoppers
      })

      acc[fieldName] = isStopped ? null : outputId

      if (isStopped) {
        // if stopped input was used in modified block
        // need to delete this block from output history
        const { canUpdateHistory } = block.inputDefinition[fieldName]
        if (canUpdateHistory) {
          outputsHistory = removeBlockFromHistory({
            outputsHistory: args.outputsHistory,
            outputId,
            blockId
          })
        }
      }

      return acc
    }, {})

    blocksMap[blockId] = {
      ...blocksMap[blockId],
      input
    }
  })

  return { workflowBlocksMap: blocksMap, outputsHistory }
}

export const changeOutputType = args => {
  const { blockId, type, defaultPresentationName, workflowBlocks, outputsNames } = args

  const block = args.workflowBlocksMap[blockId]
  const newOutput = block.output.map(output => ({
    ...output,
    defaultPresentationName,
    id: generateGUID(),
    type
  }))

  let outputsHistory = removeOutputsHistory({
    outputsHistory: args.outputsHistory,
    outputs: block.output
  })

  // delete block outputs at first
  let outputs = removeOutputs({ outputsMap: args.outputsMap, blockId })

  // need to clear all inputs in all blocks which have as value
  // one of outputs from current block
  const workflowBlocksMap = clearSelectedOutput({
    workflowBlocks,
    workflowBlocksMap: args.workflowBlocksMap,
    output: block.output
  })

  // then create the new output
  outputs = addOutputs({
    outputsMap: outputs,
    outputs: newOutput
  })

  outputsHistory = updateOutputsHistory({
    outputsHistory,
    outputs: newOutput,
    blockId
  })

  workflowBlocksMap[blockId] = {
    ...block,
    output: newOutput
  }

  return {
    workflowBlocksMap,
    outputs,
    outputsHistory,
    outputsNames: {
      ...outputsNames,
      ...changeOutputNamesFromMeta(workflowBlocksMap[blockId], workflowBlocksMap[blockId].meta)
    }
  }
}

export const updateWorkflowOutputs = ({ block, outputsMap, outputsHistory, outputsNames }) => {
  const updatedOutputs = updateOutputs({ outputsMap, outputs: block.output, blockId: block.id })

  const updatedOutputsHistory = updateOutputsHistoryBlock({
    outputsHistory,
    outputsMap: updatedOutputs,
    blockId: block.id
  })

  const updatedOutputNames = updateOutputNames({
    outputsNames,
    outputsHistory: updatedOutputsHistory,
    outputs: block.output
  })

  return {
    outputs: updatedOutputs,
    outputsHistory: updatedOutputsHistory,
    outputsNames: updatedOutputNames
  }
}
