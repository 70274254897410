export const extractApp = ({ data }) => {
  const {
    organization: {
      tenant: { app }
    }
  } = data

  return app
}

export const extractPurchasedApp = ({ data }) => {
  const {
    organization: { purchasedApps }
  } = data

  return purchasedApps
}

export const extractPurchasedAppsAvailableToAdd = ({ data }) => {
  const {
    organization: { purchasedAppsForBanner }
  } = data

  return purchasedAppsForBanner
}

export const extractAppStoreInfo = ({ data }) => {
  const { appStoreInfo } = data

  return appStoreInfo
}

export const extractRelatedApps = ({ data }) =>
  data.organization.tenants.reduce((acc, { appToAppStoreRel }) => {
    appToAppStoreRel.forEach(({ app }) => {
      if (!app) return

      acc.push(app)
    })

    return acc
  }, [])

export const extractAppsLibrary = ({ data }) => {
  const {
    organization: { library }
  } = data

  return library
}

export const extractAppsCategories = ({ data }) => {
  const {
    organization: { categoriesApps }
  } = data

  return categoriesApps
}

export const extractFilteredAppsCategories = ({ data }) => {
  const {
    organization: { paginatedLibrary }
  } = data

  return paginatedLibrary
}

export const extractFilteredAppsCollections = ({ data }) => {
  const {
    organization: { paginatedCollections }
  } = data

  return paginatedCollections
}
