import { generateGUID } from '../common/commonHelpers'
import { createBlockOutput } from './outputsOperations'

export const addBlockToMap = ({ workflowBlocksMap, block }) => ({
  ...workflowBlocksMap,
  [block.id]: block
})

export const removeBlockFromMap = ({ workflowBlocksMap, blockId }) => {
  const blocksMap = { ...workflowBlocksMap }

  delete blocksMap[blockId]

  return blocksMap
}

export const addBlockIndex = ({ workflowBlocks, index, blockId }) => {
  const blocksIndexes = workflowBlocks.slice(0)

  blocksIndexes.splice(index, 0, blockId)

  return blocksIndexes
}

export const removeBlockIndex = ({ workflowBlocks, blockId }) =>
  workflowBlocks.filter(id => id !== blockId)

export const createBlock = ({ configuration, index }) => {
  // to avoid unexpectancy with modifying configuration by link
  const pureConfiguration = JSON.parse(JSON.stringify(configuration))

  const blockId = generateGUID()
  const output = createBlockOutput({ configuration: pureConfiguration.output, blockId })

  return {
    ...pureConfiguration,
    id: blockId,
    output,
    index,
    // block is open right after creation
    open: true,
    error: {},
    warn: {}
  }
}

export const recalculatedBlocksIndexes = ({ workflowBlocks, workflowBlocksMap }) => {
  const blocksMap = { ...workflowBlocksMap }

  workflowBlocks.forEach((blockId, index) => {
    const block = blocksMap[blockId]

    if (block.index !== index) {
      blocksMap[blockId] = {
        ...block,
        index
      }
    }
  })

  return blocksMap
}

// set null to block input which have one of output as selected value
export const clearSelectedOutput = ({ workflowBlocks, workflowBlocksMap, output }) => {
  const blocksMap = { ...workflowBlocksMap }

  workflowBlocks.forEach(blockId => {
    const block = blocksMap[blockId]

    const input = Object.entries(block.input).reduce((acc, [fieldName, outputId]) => {
      const hasOutputId = output.find(item => item.id === outputId)

      acc[fieldName] = hasOutputId ? null : outputId

      return acc
    }, {})

    blocksMap[blockId] = {
      ...blocksMap[blockId],
      input
    }
  })

  return blocksMap
}
