import messages from 'constants/messages'

export const DEFAULT_VARIABLE_OBJECT = 'widget'

export const CURRENT_PREDICATE = 'current'

export const VARIABLES_CONFIG = {
  objects: {
    widget: {
      name: messages.WIDGET_ATTRIBUTE,
      defaultAttribute: 'boardName'
    },
    card: {
      name: messages.CARD_ATTRIBUTE,
      defaultAttribute: 'boardName'
    }
  },
  objectFields: {
    card: ['board', 'card'],
    widget: ['board', 'card', 'widget']
  },
  attributes: {
    boardId: { name: messages.DEFINE_STRING_OPTION_BOARD_ID_NAME, card: true, widget: true },
    boardName: { name: messages.DEFINE_STRING_OPTION_BOARD_NAME, card: true, widget: true },
    cardUuid: { name: messages.DEFINE_STRING_OPTION_CARD_ID, card: true, widget: true },
    cardName: { name: messages.DEFINE_STRING_OPTION_CARD_NAME, card: true, widget: true },
    columnName: { name: messages.DEFINE_STRING_OPTION_COLUMN_NAME, widget: true },
    cardCreatedAt: { name: messages.CARD_CREATED_DATE, card: true, widget: true },
    cardUpdatedAt: { name: messages.CARD_UPDATED_DATE, card: true, widget: true },
    cardOwnersIds: { name: messages.CARD_OWNERS, card: true, widget: true },
    widgetClassName: { name: messages.DEFINE_STRING_OPTION_WIDGET_CLASS, widget: true },
    widgetTitle: { name: messages.DEFINE_STRING_OPTION_WIDGET_NAME, widget: true },
    createdAt: { name: messages.WIDGET_CREATED_DATE, widget: true },
    updatedAt: { name: messages.WIDGET_UPDATED_DATE, widget: true },
    widgetUuid: { name: messages.DEFINE_STRING_OPTION_WIDGET_ID, widget: true },
    UBF: {
      name: messages.DEFINE_STRING_OPTION_WIDGET_DATA,
      widget: true,
      withCoordinates: true
    }
  },
  rules: {
    board: {
      predicates: {
        current: { type: null, name: messages.CURRENT },
        isEqual: {
          type: 'input',
          hasAutoComplete: true,
          name: messages.WITH_NAME_IS_EQUAL_TO
        }
      },
      defaultPredicate: 'current'
    },
    card: {
      predicates: {
        current: { type: null, name: messages.CURRENT },
        isEqual: {
          type: 'input',
          hasAutoComplete: true,
          name: messages.WITH_NAME_IS_EQUAL_TO
        }
      },
      defaultPredicate: 'current'
    },
    widget: {
      predicates: {
        isEqual: { type: 'input', hasAutoComplete: true, name: messages.IS_EQUAL_PREDICATE }
      },
      defaultPredicate: 'isEqual'
    }
  }
}
