import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import messages from 'constants/messages'
import arrowIcon from 'assets/images/icons/ic_arrow_dr.svg?url'
import crossIcon from 'assets/images/icons/ic_cross.svg?url'
import { Tooltip } from 'components/common/tooltip/tooltip'
import 'scss/boards/jobs-indicator.scss'

const JobsIndicator = props => {
  const {
    children,
    title,
    closeTooltipText,
    visible,
    invisible,
    width,
    transform,
    onCloseJobsIndicator,
    onToggleHideJobsIndicator,
    hidden
  } = props

  const tooltipText = hidden ? messages.SHOW_WINDOW : messages.HIDE_WINDOW

  return (
    <section
      className={classNames('jobs-indicator', {
        visible,
        'not-visible': invisible
      })}
      style={{
        width,
        transform
      }}
    >
      <div className="indicator-header">
        <p className="copy-progress">{title}</p>
        <div className="controls">
          <Tooltip id="hide-window-tooltip" title={tooltipText} placement="top">
            <img
              className={classNames('button-control', {
                expand: hidden
              })}
              alt="hide"
              src={arrowIcon}
              onClick={onToggleHideJobsIndicator}
            />
          </Tooltip>
          <Tooltip id="close-window-tooltip" title={closeTooltipText} placement="top">
            <img
              className="button-control close"
              alt="close"
              src={crossIcon}
              onClick={onCloseJobsIndicator}
            />
          </Tooltip>
        </div>
      </div>
      {!hidden && <div className="indicator-content">{children}</div>}
    </section>
  )
}

JobsIndicator.propTypes = {
  onCloseJobsIndicator: PropTypes.func.isRequired,
  onToggleHideJobsIndicator: PropTypes.func.isRequired,
  hidden: PropTypes.bool.isRequired
}

export default JobsIndicator
