import { EJobStatuses } from '../../features/jobs/jobs.types'

export const sendTriggerWorkflowGAAction = monitors => {
  monitors.forEach(monitor => {
    ga('send', 'event', {
      eventCategory: 'Workflow',
      eventAction: 'Trigger',
      eventLabel: `${monitor.data.name} (TID: ${monitor.tenantId}, WID: ${monitor.id})`
    })
  })
}

export const sendFinishWorkflowGAAction = monitors => {
  monitors.forEach(monitor => {
    if (![EJobStatuses.ERROR, EJobStatuses.DONE].includes(monitor.status)) {
      return
    }

    const status = monitor.status === EJobStatuses.DONE ? 'success' : 'error'

    ga('send', 'event', {
      eventCategory: 'Workflow',
      eventAction: 'Finish',
      eventLabel: `Trigger: ${monitor.data.name}; TID: ${monitor.tenantId}; WID: ${monitor.id}; Status: ${status}`,
      eventValue: monitor.finishDate - monitor.createdAt
    })
  })
}
