/*
It's advanced feature, so please make sure you really need it.
This is an action that helps access store in case you need some things from store but
you don't want these things will trigger update of component in case they updated.
Please take a look at example:
class SomeComponent extends Component {
    methodThatNeedsSmtFromStore = () => {
        const getState = this.props.getState();

        // getState is same getState func that passed as second argument in any thunk action
        const store = getState();

        console.log(store.board.cards);
    };

    render() {
        return (
            <div />
        );
    }
}

const mapDispatchToProps = () => ({
    getState // map this action as any other
});

export default connect(null, mapDispatchToProps)(SomeComponent);
*/
const getState = () => (dispatch, _getState) => _getState

export default getState
