import { OBJECT_TYPES } from '../../constants/workflows'
import { generateGUID } from '../common/commonHelpers'

// to make map with empty array for each object type
export const getInitialOutputs = () =>
  Object.values(OBJECT_TYPES).reduce((acc, type) => {
    acc[type] = []

    return acc
  }, {})

export const createBlockOutput = ({ configuration, blockId }) =>
  configuration.map(output => ({
    ...output,
    id: generateGUID(),
    blockId
  }))

export const addOutputs = ({ outputsMap, outputs }) =>
  outputs.reduce(
    (acc, output) => {
      acc[output.type] = [...outputsMap[output.type], output]

      return acc
    },
    { ...outputsMap }
  )

export const removeOutputs = ({ outputsMap, blockId }) =>
  Object.entries(outputsMap).reduce((acc, [key, outputList]) => {
    acc[key] = outputList.filter(output => output.blockId !== blockId)

    return acc
  }, {})

export const updateOutputs = ({ outputsMap, outputs, blockId }) => {
  const updatedOutputs = Object.entries(outputsMap).reduce((acc, [key, outputList]) => {
    acc[key] = outputList.filter(output => output.blockId !== blockId)

    return acc
  }, {})

  outputs.forEach(output => updatedOutputs[output.type].push(output))

  return updatedOutputs
}
