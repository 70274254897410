import { MAX_WIDGET_COUNT } from 'constants/common'
import { DATA_LINKING_OPERATION } from 'constants/cardBuilder/widgetsConstants'
import { getCardSectionCount } from 'features/cards/cards.helpers'
import { CARD_UPDATE, WIDGET_CREATE, WIDGET_DELETE, WIDGET_UPDATE } from '../constants/actionTypes'
import { SimpleIDB } from '../store/indexDBSetup'
import { generateGUID, getNumberedArray } from './common/commonHelpers'
import {
  flagsList,
  getGroupedFiles,
  getWidgetsDataByIds,
  hasFlag,
  isFileManager
} from './widget/widgetDataHelpers'

export const DEFAULT_CARD_HEIGHT = 555
export const DEFAULT_CARD_WIDTH = 1145
export const MAX_GROUPED_FILES_COUNT = 50

const STACK_REQUESTS_TO_IDB = []
let stackRequestInProgress = false

export async function setDataToLocalStorage(changesStack, originalCard) {
  if (changesStack !== null) {
    await SimpleIDB.set('undoStack', changesStack.length > 0 ? JSON.stringify(changesStack) : '')
  }
  if (originalCard) {
    await SimpleIDB.set('originalCard', JSON.stringify(originalCard))
  }
}

export function getChangesStack(changesStack) {
  return changesStack ? JSON.parse(changesStack) : []
}

const saveCardChangesToLocalStorage = async value => {
  stackRequestInProgress = true
  const savedStack = await SimpleIDB.get('undoStack')
  const changesStack = getChangesStack(savedStack)

  changesStack.push(value)
  await setDataToLocalStorage(changesStack, null)
  stackRequestInProgress = false
}

export const addRequestToStack = draft => {
  STACK_REQUESTS_TO_IDB.push(draft)
}

export const callRequestFromStack = async () => {
  if (!stackRequestInProgress && STACK_REQUESTS_TO_IDB.length) {
    const first = STACK_REQUESTS_TO_IDB.shift()

    await saveCardChangesToLocalStorage(first)
    await callRequestFromStack()
  }
}

export function getBoardViewArray(widgets) {
  return widgets
    .filter(widget => widget.showOnBoardView)
    .sort((a, b) => a.boardViewY - b.boardViewY)
    .map(widget => widget.uuid)
}

export function updateBoardViewIndexes(widgets, boardViewArray) {
  return widgets.reduce((acc, item) => {
    const indexInBoardView = boardViewArray.indexOf(item.uuid)

    // recalculate board view coordinates
    if (indexInBoardView !== -1) {
      // skip not changed widgets
      if (item.showOnBoardView && item.boardViewY === indexInBoardView) {
        return acc
      }

      acc.push({
        ...item,
        showOnBoardView: true,
        boardViewY: indexInBoardView
      })

      return acc
    }

    // clear old board view widgets
    if (item.showOnBoardView) {
      acc.push({
        ...item,
        showOnBoardView: false
      })

      return acc
    }

    return acc
  }, [])
}

// filter list of widgets by type and return widgets data
const filterWidgetsByType = (list, type) =>
  list.filter(widget => widget.type === type).map(widget => widget.data)

// get list of the last action of each widget
export function getLastChangesOfCard(stack) {
  const changesStack = stack.filter(item => !item.saved)

  let cardUpdatePayload = { data: {} }
  const widgets = {}

  changesStack.forEach(item => {
    // for card update type need to extract the last value
    if (item.type === CARD_UPDATE) {
      cardUpdatePayload = {
        ...cardUpdatePayload,
        ...item,
        data: { ...cardUpdatePayload.data, ...item.data }
      }
    } else {
      // make actions stack for each widget
      item.data.forEach(widget => {
        const widgetID = widget.uuid
        if (!widgets[widgetID]) {
          widgets[widgetID] = []
        }
        widgets[widgetID].push({ type: item.type, data: widget })
      })
    }
  })

  const widgetsKeys = Object.keys(widgets)
  // array of last state of each widget
  const widgetsLastState = []

  widgetsKeys.forEach(widgetID => {
    const widgetActionsStack = widgets[widgetID]
    const stackLength = widgetActionsStack.length
    const widgetLastState = widgetActionsStack[stackLength - 1]
    let widgetType = WIDGET_UPDATE

    let firstOperationType = ''
    let lastOperationType = ''

    widgetActionsStack.forEach(widget => {
      if (widget.type === WIDGET_CREATE || widget.type === WIDGET_DELETE) {
        if (!firstOperationType) {
          firstOperationType = widget.type
        }
        lastOperationType = widget.type
      }
    })

    // Legend:
    // C - created
    // D - deleted
    // | - auto save to back-end
    // To determine finally type of the updating widget we should consider following cases:
    if (firstOperationType === WIDGET_CREATE) {
      widgetType = WIDGET_CREATE
    }
    if (lastOperationType === WIDGET_DELETE) {
      widgetType = WIDGET_DELETE
    }

    // cases: (D | C D) and (C D) - should nothing happens
    // if widget was created and deleted during session we shouldn't remember it.
    if (firstOperationType === WIDGET_CREATE && lastOperationType === WIDGET_DELETE) {
      return
    }

    // cases: (D | C D C) and (C D C) - should be 'created' type
    if (firstOperationType === WIDGET_CREATE && lastOperationType === WIDGET_CREATE) {
      widgetType = WIDGET_CREATE
    }

    // case: (C | D C D) - should be 'deleted' type
    if (firstOperationType === WIDGET_DELETE && lastOperationType === WIDGET_DELETE) {
      widgetType = WIDGET_DELETE
    }

    // case: (C | D C) - should be 'update' type
    if (firstOperationType === WIDGET_DELETE && lastOperationType === WIDGET_CREATE) {
      widgetType = WIDGET_UPDATE
    }

    widgetsLastState.push({ ...widgetLastState, type: widgetType })
  })

  const widgetsDeletePayload = filterWidgetsByType(widgetsLastState, WIDGET_DELETE)
  const widgetsUpdatePayload = filterWidgetsByType(widgetsLastState, WIDGET_UPDATE)
  const widgetsCreatePayload = filterWidgetsByType(widgetsLastState, WIDGET_CREATE)

  return { cardUpdatePayload, widgetsDeletePayload, widgetsUpdatePayload, widgetsCreatePayload }
}

export async function markHistorySaving() {
  const savedStack = await SimpleIDB.get('undoStack')
  const changesStack = getChangesStack(savedStack)

  if (!changesStack.length) {
    return
  }

  changesStack.forEach(item => {
    if (!item.saved) {
      item.saved = true
      item.saving = true
    }
  })

  SimpleIDB.set('undoStack', JSON.stringify(changesStack))
}

export async function revertHistorySaving() {
  const savedStack = await SimpleIDB.get('undoStack')
  const changesStack = getChangesStack(savedStack)

  if (!changesStack.length) {
    return
  }

  changesStack.forEach(item => {
    if (item.saving) {
      item.saved = false
      item.saving = false
    }
  })

  SimpleIDB.set('undoStack', JSON.stringify(changesStack))
}

export async function markHistorySaved() {
  const savedStack = await SimpleIDB.get('undoStack')
  const changesStack = getChangesStack(savedStack)

  const CHANGES_STACK_LENGTH = 10

  // get rid of saved changes to make stack length less then CHANGES_STACK_LENGTH
  changesStack.reduce((acc, item, index) => {
    if (item.saved && index < changesStack.length - CHANGES_STACK_LENGTH) {
      return acc
    }

    item.saving = false
    acc.push(item)

    return acc
  }, [])

  SimpleIDB.set('undoStack', JSON.stringify(changesStack))
}

export function getUndoStack(changesStack) {
  // only 'original' actions and actions that was redo can be undo
  return changesStack.filter(item => !item.undid && !item.reversed)
}

export function getRedoStack(changesStack) {
  const changesStackLength = changesStack.length - 1
  const redoStack = []

  let undidActionFound = false
  let redidActionCount = 0
  // search actions that can be redo
  for (let i = changesStackLength; i >= 0; i -= 1) {
    const item = changesStack[i]
    // search till first 'original' action is found or all redo actions is found
    const isOriginalAction = !item.undid && !item.redid && !item.reversed
    const isFoundAllUndidActions = undidActionFound && !item.undid
    if (isOriginalAction || isFoundAllUndidActions) {
      break
    }
    // only actions that was undo can be redo
    if (changesStack[i].undid) {
      undidActionFound = true
      redoStack.push(changesStack[i])
    }
    if (changesStack[i].redid) {
      redidActionCount += 1
    }
  }
  // exclude actions that already was redo
  redoStack.splice(-redidActionCount, redidActionCount)
  return redoStack
}

export function setSelectionOnWidgets(widgetID, widgets, selectedWidgets, isCtrl) {
  let selectedWidgetsIds = selectedWidgets.slice(0)
  if (widgetID) {
    const groupIndex = widgets.filter(widget => widget.uuid === widgetID)[0].groupIndex
    let widgetsInGroup = []

    if (groupIndex) {
      widgetsInGroup = widgets
        .filter(item => item.uuid !== widgetID && item.groupIndex === groupIndex)
        .map(item => item.uuid)
    }

    if (!isCtrl) {
      selectedWidgetsIds = [widgetID, ...widgetsInGroup]
    } else {
      const elementPosition = selectedWidgetsIds.indexOf(widgetID)
      if (elementPosition !== -1) {
        selectedWidgetsIds.splice(elementPosition, 1)
        widgetsInGroup.forEach(itemID => {
          const groupWidgetPosition = selectedWidgetsIds.indexOf(itemID)
          if (elementPosition !== -1) {
            selectedWidgetsIds.splice(groupWidgetPosition, 1)
          }
        })
      } else {
        selectedWidgetsIds = [...selectedWidgetsIds, widgetID, ...widgetsInGroup]
      }
    }
  }
  return selectedWidgetsIds
}

export function addNewColorToUser(colors, color) {
  let colorIndex = -1
  colors.forEach((item, i) => {
    if (item.toLowerCase() === color.toLowerCase()) {
      colorIndex = i
    }
  })
  if (colorIndex === -1) {
    const MAX_COLORS_COUNT = 16
    if (colors.length >= MAX_COLORS_COUNT) {
      colors.pop()
    }
  } else {
    colors.splice(colorIndex, 1)
  }

  colors.unshift(color)
  return colors
}

export const defaultColorSet = [
  '#f50006',
  '#F5A623',
  '#F8E71C',
  '#8B572A',
  '#7ED321',
  '#417505',
  '#BD10E0',
  '#9013FE',
  '#0c0fe2',
  '#4A90E2',
  '#50E3C2',
  '#B8E986',
  '#000000',
  '#4A4A4A',
  '#9B9B9B',
  '#FFFFFF'
]

export function sanitize(url) {
  let resultUrl = url
  if (url && url.indexOf('https://') === -1 && url.indexOf('http://') === -1) {
    resultUrl = `https://${url}`
  }
  return resultUrl
}

/*
 *
 @param a, b, c, d - points {x: ..., y:... }
 a -> b - first line
 c -> d - second line
 */
export function isLinesIntersect(a, b, c, d) {
  const parallelCoeff = (b.x - a.x) * (d.y - c.y) - (b.y - a.y) * (d.x - c.x)
  // if coeff = 0 -> lines are parallel
  if (parallelCoeff === 0) {
    return false
  }
  const rCoeff = (a.y - c.y) * (d.x - c.x) - (a.x - c.x) * (d.y - c.y)
  const sCoeff = (a.y - c.y) * (b.x - a.x) - (a.x - c.x) * (b.y - a.y)

  const r = rCoeff / parallelCoeff
  const s = sCoeff / parallelCoeff
  return r >= 0 && r <= 1 && s >= 0 && s <= 1
}

export function arrayUnique(array) {
  return array.reduce((acc, item) => {
    if (acc.indexOf(item) === -1) {
      acc.push(item)
    }
    return acc
  }, [])
}

export function getDegreesFromTransform(transform) {
  if (transform && transform.startsWith('rotate')) {
    const startPos = transform.indexOf('(')
    const endPos = transform.indexOf('deg')
    return parseFloat(transform.substring(startPos + 1, endPos))
  }
  return 0
}

export function toRadians(angle) {
  return angle * (Math.PI / 180)
}

export function getCoordsOfRotatedRect(point0, width, height, angle) {
  const x1 = point0.x + width * Math.cos(toRadians(angle))
  const y1 = point0.y + width * Math.sin(toRadians(angle))
  const point1 = { x: x1, y: y1 }

  const x2 = point0.x + width * Math.cos(toRadians(angle)) - height * Math.sin(toRadians(angle))
  const y2 = point0.y + width * Math.sin(toRadians(angle)) + height * Math.cos(toRadians(angle))
  const point2 = { x: x2, y: y2 }

  const x3 = point0.x - height * Math.sin(toRadians(angle))
  const y3 = point0.y + height * Math.cos(toRadians(angle))
  const point3 = { x: x3, y: y3 }
  const rect = [point0, point1, point2, point3]
  const center1 = {
    x: point0.x + width / 2,
    y: point0.y + height / 2
  }

  const center2 = {
    x: (point0.x + point2.x) / 2,
    y: (point0.y + point2.y) / 2
  }

  for (let i = 0; i < rect.length; i += 1) {
    rect[i].x += center1.x - center2.x
    rect[i].y += center1.y - center2.y
  }

  return rect
}

export function checkIfPointInsideRect(point, rectPoints) {
  // if true Rectangles are intersected
  const count = rectPoints.length
  let j = count - 1
  let result = false
  for (let i = 0; i < count; i += 1) {
    if (
      ((rectPoints[i].y <= point.y && point.y < rectPoints[j].y) ||
        (rectPoints[j].y <= point.y && point.y < rectPoints[i].y)) &&
      point.x >
        ((rectPoints[j].x - rectPoints[i].x) * (point.y - rectPoints[i].y)) /
          (rectPoints[j].y - rectPoints[i].y) +
          rectPoints[i].x
    ) {
      result = !result
    }
    j = i
  }
  return result
}

export function checkDistanceBeetweenRects(rect1, rect2) {
  const diagonal1 = {
    x: (rect1[0].x + rect1[2].x) / 2,
    y: (rect1[0].y + rect1[2].y) / 2
  }
  const diagonal2 = {
    x: (rect2[0].x + rect2[2].x) / 2,
    y: (rect2[0].y + rect2[2].y) / 2
  }

  const diagonalLength1 = Math.sqrt((rect1[1].x - rect1[0].x) ** 2 + (rect1[2].y - rect1[1].y) ** 2)
  const diagonalLength2 = Math.sqrt((rect2[1].x - rect2[0].x) ** 2 + (rect2[2].y - rect2[1].y) ** 2)
  const distanceBetweenCenters = Math.sqrt(
    (diagonal1.x - diagonal2.x) ** 2 + (diagonal1.y - diagonal2.y) ** 2
  )

  // if false Rectangles are not intersected
  return diagonalLength1 / 2 + diagonalLength2 / 2 >= distanceBetweenCenters
}

/*
    @param rectPoints - coords of lasso
    @param widgetPoints - coords of widget
 */
export function checkIfRectanglesIntersect(rectPoints, widgetPoints, origWidgetPoints) {
  let isIntersects

  // if false - rectangles don't exactly intersect
  // if true - can intersect or not
  isIntersects = checkDistanceBeetweenRects(rectPoints, origWidgetPoints)
  if (!isIntersects) {
    return isIntersects
  }

  const count = rectPoints.length
  for (let j = 0; j < count; j += 1) {
    // check if a corner point of widget is inside lasso
    isIntersects = checkIfPointInsideRect(widgetPoints[j], rectPoints)
    if (isIntersects) {
      return isIntersects
    }
  }

  // if lasso and widget can intersect check lines intersection
  for (let j = 0; j < count; j += 1) {
    // line from widget (start & end points)
    const line1start = widgetPoints[j]
    const line1end = widgetPoints[j < count - 1 ? j + 1 : 0]
    for (let k = 0; k < count; k += 1) {
      // line from lasso (start & end points)
      const line2start = rectPoints[k]
      const line2end = rectPoints[k < count - 1 ? k + 1 : 0]
      // check if they intersect
      isIntersects = isLinesIntersect(line1start, line1end, line2start, line2end)
      if (isIntersects) {
        return isIntersects
      }
    }
  }

  return isIntersects
}

// to check strict intersection
// if widget is fully inside rectangle, it will be valid
export function checkIfRectanglesIntersectStrictly(rectPoints, widgetPoints) {
  let allPointsInside = true
  let allPointsOutside = true

  const count = rectPoints.length
  const outsidePoints = []
  for (let j = 0; j < count; j += 1) {
    // check if a corner point of widget is inside rectangle
    const isIntersects = checkIfPointInsideRect(widgetPoints[j], rectPoints)
    allPointsInside = allPointsInside && isIntersects
    allPointsOutside = allPointsOutside && !isIntersects
    // save point which is ouside
    if (!isIntersects) {
      outsidePoints.push(widgetPoints[j])
    }
  }

  return {
    intersection: !allPointsInside && !allPointsOutside,
    outsidePoints
  }
}

// unlink all linked cells
export const clearLinks = data =>
  data.map(row =>
    row.map(cell => {
      if (typeof cell === 'object' && cell && cell.dataLink) {
        return { value: null, operation: DATA_LINKING_OPERATION.clear }
      }
      return cell
    })
  )

export function createWidgetFromSource(widgetSource) {
  // For create operation by drag and drop we should calculate
  // new zIndex based on count of existing widgets. So we need to delete default zIndex
  const newWidget = {
    ...widgetSource,
    uuid: generateGUID()
  }
  delete newWidget.zIndex
  delete newWidget.boardViewY
  delete newWidget.showOnBoardView
  delete newWidget.key
  delete newWidget.uploadedBy

  return newWidget
}

const getPosition = (
  width,
  height,
  currentX,
  currentY,
  stepX,
  stepY,
  maxSizeX,
  maxSizeY,
  initialY,
  initialX
) => {
  if (width > maxSizeX || height > maxSizeY) {
    return { x: 0, y: 0 }
  }

  if (currentY + stepY + height > maxSizeY) {
    if (currentX + stepX + width > maxSizeX) {
      return { x: initialX, y: initialY }
    }
    return { x: currentX + stepX, y: initialY }
  }

  return { x: currentX, y: currentY + stepY }
}

export function widgetPositionPad(
  widgets,
  { stepX, stepY } = { stepX: 100, stepY: 40 },
  { initialX, initialY } = { initialX: 10, initialY: 10 }
) {
  const AUTHORING_AREA_WIDTH = 1145
  const AUTHORING_AREA_HEIGHT = 558

  let x = initialX
  let y = initialY

  return widgets.map((widget, index) => {
    if (index !== 0) {
      const width = widget.width
      const height = widget.height

      const position = getPosition(
        width,
        height,
        x,
        y,
        stepX,
        stepY,
        AUTHORING_AREA_WIDTH,
        AUTHORING_AREA_HEIGHT,
        initialY,
        initialX
      )

      x = position.x
      y = position.y
    }

    widget.positionX = x
    widget.positionY = y

    return widget
  })
}

export const getDownloadLink = (link, url) => {
  if (!link) {
    return link
  }

  let decodedLink = link

  try {
    decodedLink = decodeURIComponent(decodedLink)
  } catch (e) {
    // if there is an error to decode url, it means that input parameter wasn't encoded
    // so we should work with initial value
    console.info('Link cannot be decoded')
  }

  return decodedLink.startsWith('https://') ? decodedLink : `${url}${decodedLink}`
}

export const updateWidgetPositionWithSection = ({ origY, origSection }) => {
  let y = origY
  let section = origSection
  if (y > DEFAULT_CARD_HEIGHT) {
    const deltaSection = Math.floor(y / DEFAULT_CARD_HEIGHT)
    section += deltaSection
    y -= deltaSection * DEFAULT_CARD_HEIGHT
  } else if (y < 0) {
    const deltaSection = Math.floor(Math.abs(y) / DEFAULT_CARD_HEIGHT) + 1
    section -= deltaSection
    y = deltaSection * DEFAULT_CARD_HEIGHT + y
  }
  if (section < 0) {
    section = 0
    y = DEFAULT_CARD_HEIGHT - y
  }
  return { y, section }
}

// To fix issue https://leverxeu.atlassian.net/browse/EUPBOARD01-12585
// Fix the ability to move a group of widgets outside the Cards area.
export const moveWidgetInsideAuthoringArea = ({ x, y, section, widget, widgetParams }) => {
  const widgetsWrapper = document.querySelector('.widget-multiselect-wrapper')
  const cardContentRect = document.getElementById('card-content-area').getBoundingClientRect()

  const isWidgetLeavingToLeft = x < 0
  const isWidgetLeavingToRight = x > cardContentRect.width - widgetParams.width
  const isWidgetLeavingToBottom =
    y + section * DEFAULT_CARD_HEIGHT > cardContentRect.height - widgetParams.height

  if (isWidgetLeavingToLeft) {
    widget.style.left = 0
    widgetsWrapper.style.left = 0
  } else if (isWidgetLeavingToRight) {
    widget.style.left = `${cardContentRect.width - widgetParams.width}px`
    widgetsWrapper.style.left = cardContentRect.width - widgetParams.width
  }

  if (isWidgetLeavingToBottom) {
    widget.style.top = `${DEFAULT_CARD_HEIGHT - widgetParams.height}px`
    widgetsWrapper.style.top = DEFAULT_CARD_HEIGHT - widgetParams.height
  }

  widget.setAttribute('data-x', widget.style.left)
  widget.setAttribute('data-y', widget.style.top)
  widgetsWrapper.setAttribute('data-x', widgetsWrapper.style.left)
  widgetsWrapper.setAttribute('data-y', widgetsWrapper.style.top)

  widgetParams.positionY = isWidgetLeavingToBottom ? parseInt(widget.style.top, 10) : y
  widgetParams.positionX =
    isWidgetLeavingToLeft || isWidgetLeavingToRight ? parseInt(widget.style.left, 10) : x
  widgetParams.section = section

  return widgetParams
}

export const getActiveSection = ({ scrollTop, height, isDown }) => {
  const minVisibleSection = Math.floor(scrollTop / DEFAULT_CARD_HEIGHT)
  const maxVisibleSection = Math.floor((scrollTop + height) / DEFAULT_CARD_HEIGHT)
  for (let i = minVisibleSection; i <= maxVisibleSection; i += 1) {
    const minY = i * DEFAULT_CARD_HEIGHT
    const maxY = (i + 1) * DEFAULT_CARD_HEIGHT
    // check if section is fully visible
    if (minY >= scrollTop && maxY <= scrollTop + height) {
      return i
    }
  }
  return isDown ? minVisibleSection : maxVisibleSection
}

export const getWidgetPoints = ({ x, y, width, height, transform }) => {
  const angle = getDegreesFromTransform(transform)

  return getCoordsOfRotatedRect({ x, y }, width, height, angle)
}

export const checkIntersectionWithHiddenSections = ({
  cardWidth,
  hiddenSections,
  widgetPoints
}) => {
  let isIntersects = false
  hiddenSections.forEach(section => {
    const rightTopPoint = {
      x: cardWidth,
      y: section * DEFAULT_CARD_HEIGHT
    }
    const rightBottomPoint = {
      x: cardWidth,
      y: (section + 1) * DEFAULT_CARD_HEIGHT
    }
    const leftTopPoint = {
      x: 0,
      y: section * DEFAULT_CARD_HEIGHT
    }
    const leftBottomPoint = {
      x: 0,
      y: (section + 1) * DEFAULT_CARD_HEIGHT
    }

    const rectPoints = [leftTopPoint, rightTopPoint, rightBottomPoint, leftBottomPoint]

    const { intersection } = checkIfRectanglesIntersectStrictly(rectPoints, widgetPoints)

    isIntersects = isIntersects || intersection

    const rightTopWidgetPoint = widgetPoints[0]?.y
    const rightBottomWidgetPoint = widgetPoints[2]?.y

    // To fix: https://leverxeu.atlassian.net/browse/EUPBOARD01-12462
    // Check if the hidden section is between the top and bottom endpoints of the selected widget.
    if (rightTopWidgetPoint < rightTopPoint.y && rightBottomWidgetPoint > rightBottomPoint.y) {
      isIntersects = true
    }
  })

  return isIntersects
}

export const checkWidgetsPositionChange = (updatedWidgets, prevWidgets = []) => {
  // Find a widget with changed position
  return updatedWidgets.filter(widget => {
    const prevWidget = prevWidgets.find(el => el.uuid === widget.uuid)
    if (!prevWidget) return false

    return (
      widget.positionX !== prevWidget.positionX ||
      widget.positionY !== prevWidget.positionY ||
      widget.height !== prevWidget.height ||
      widget.width !== prevWidget.width ||
      widget.rotation !== prevWidget.rotation
    )
  })
}
export const checkWidgetIntersectionWithAuthoringArea = ({
  widgetPoints,
  cardHeight,
  cardWidth
}) => {
  const rightTopPoint = {
    x: cardWidth,
    y: 0
  }
  const rightBottomPoint = {
    x: cardWidth,
    y: cardHeight
  }
  const leftTopPoint = {
    x: 0,
    y: 0
  }
  const leftBottomPoint = {
    x: 0,
    y: cardHeight
  }
  const rectPoints = [leftTopPoint, rightTopPoint, rightBottomPoint, leftBottomPoint]
  const { intersection, outsidePoints } = checkIfRectanglesIntersectStrictly(
    rectPoints,
    widgetPoints
  )
  let deltaX = 0
  let deltaY = 0
  if (intersection) {
    outsidePoints.forEach(point => {
      let diffX = 0
      if (point.x > cardWidth) {
        diffX = cardWidth - point.x
      } else if (point.x < 0) {
        diffX = -point.x
      }
      if (Math.abs(diffX) > Math.abs(deltaX)) {
        deltaX = diffX
      }
      let diffY = 0
      if (point.y > cardHeight) {
        diffY = cardHeight - point.y
      } else if (point.y < 0) {
        diffY = -point.y
      }
      if (Math.abs(diffY) > Math.abs(deltaY)) {
        deltaY = diffY
      }
    })
  }
  return { intersection, deltaX, deltaY }
}

export const recalculateInitialCoordinates = (positionX, positionY, width, height, cardHeight) => {
  const CARD_BUILDER_WIDTH = 1143
  const CARD_BUILDER_HEIGHT = cardHeight || DEFAULT_CARD_HEIGHT
  const recalculatedCoordinates = {
    positionX: positionX > 0 ? positionX : 0,
    positionY: positionY > 0 ? positionY : 0,
    section: 0
  }

  if (width > CARD_BUILDER_WIDTH || height > CARD_BUILDER_HEIGHT) {
    return recalculatedCoordinates
  }
  const widthDiff = CARD_BUILDER_WIDTH - (positionX + width)
  const heightDiff = CARD_BUILDER_HEIGHT - (positionY + height)
  if (widthDiff < 0) {
    recalculatedCoordinates.positionX += widthDiff
  }

  if (heightDiff < 0) {
    recalculatedCoordinates.positionY += heightDiff
  }

  // set section and update position
  const section = Math.floor(recalculatedCoordinates.positionY / DEFAULT_CARD_HEIGHT)
  recalculatedCoordinates.positionY -= section * DEFAULT_CARD_HEIGHT
  recalculatedCoordinates.section = section

  return recalculatedCoordinates
}

const getIntersectedSections = ({ x, y, width, height, transform, sections }) => {
  const widgetPoints = getWidgetPoints({ x, y, width, height, transform })

  const intersectedSections = []

  // For each section, check if the widget fits within this section
  sections.forEach(section => {
    const rightTopPoint = {
      x: DEFAULT_CARD_WIDTH,
      y: section * DEFAULT_CARD_HEIGHT
    }
    const rightBottomPoint = {
      x: DEFAULT_CARD_WIDTH,
      y: (section + 1) * DEFAULT_CARD_HEIGHT
    }
    const leftTopPoint = {
      x: 0,
      y: section * DEFAULT_CARD_HEIGHT
    }
    const leftBottomPoint = {
      x: 0,
      y: (section + 1) * DEFAULT_CARD_HEIGHT
    }

    const rectPoints = [leftTopPoint, rightTopPoint, rightBottomPoint, leftBottomPoint]

    // Check if the widget's rectangle and the section's dividers intersect
    const { intersection } = checkIfRectanglesIntersectStrictly(rectPoints, widgetPoints)

    if (intersection) intersectedSections.push(section)

    const rightTopWidgetPoint = widgetPoints[0]?.y
    const rightBottomWidgetPoint = widgetPoints[2]?.y

    if (rightTopWidgetPoint < rightTopPoint.y && rightBottomWidgetPoint > rightBottomPoint.y) {
      intersectedSections.push(section)
    }
  })

  return intersectedSections
}

export const getIntersectedDividers = (widgets, cardHeight) =>
  widgets.reduce(
    (result, { uuid, positionX = 0, positionY = 0, rotation, section, width, height }) => {
      const intersectedSections = getIntersectedSections({
        x: positionX,
        y: positionY + section * DEFAULT_CARD_HEIGHT,
        width,
        height,
        transform: rotation,
        sections: getNumberedArray(getCardSectionCount(cardHeight))
      })

      result[uuid] = intersectedSections.slice(1)

      return result
    },
    {}
  )

export const isWidgetIntersectsWithHiddenSections = ({
  x,
  y,
  width,
  height,
  transform,
  hiddenSections
}) => {
  const widgetPoints = getWidgetPoints({ x, y, width, height, transform })
  return checkIntersectionWithHiddenSections({
    cardWidth: DEFAULT_CARD_WIDTH,
    hiddenSections,
    widgetPoints
  })
}

export const getWidgetDeltaAuthoringArea = ({ x, y, width, height, transform, cardHeight }) => {
  const widgetPoints = getWidgetPoints({ x, y, width, height, transform })
  const { intersection, deltaX, deltaY } = checkWidgetIntersectionWithAuthoringArea({
    widgetPoints,
    cardHeight,
    cardWidth: DEFAULT_CARD_WIDTH
  })
  if (intersection) {
    // try to move widget and check intersection again
    const updatedWidgetPoints = getWidgetPoints({
      x: x + deltaX,
      y: y + deltaY,
      width,
      height,
      transform
    })
    const isIntersection = checkWidgetIntersectionWithAuthoringArea({
      widgetPoints: updatedWidgetPoints,
      cardHeight,
      cardWidth: DEFAULT_CARD_WIDTH
    })
    if (isIntersection.intersection && (isIntersection.deltaX || isIntersection.deltaY)) {
      // prevent moving if widget still intersects
      return { intersection: true }
    }
  }
  return { deltaX, deltaY }
}

export const restrictWidgetsCount = (newCount, existingCount) => {
  // count of widgets which could be added to card
  const allowedCount = MAX_WIDGET_COUNT - existingCount
  return {
    allowedCount,
    limitReached: existingCount + newCount >= MAX_WIDGET_COUNT,
    restricted: newCount > allowedCount
  }
}

export const getSectionsCountToAdd = ({ y, height, cardHeight }) => {
  const sectionsCount = getCardSectionCount(cardHeight)
  let sectionsNeeded = Math.floor((y + height) / DEFAULT_CARD_HEIGHT)
  if ((y + height) % DEFAULT_CARD_HEIGHT) {
    sectionsNeeded += 1
  }
  return sectionsNeeded - sectionsCount
}

export const getFirstVisibleCardSection = (cardHeight, hiddenSections) => {
  const sectionsCount = cardHeight ? cardHeight / DEFAULT_CARD_HEIGHT : 1
  // get first visible section
  let section = 0
  if (hiddenSections && hiddenSections.length) {
    for (let i = 0; i < sectionsCount; i += 1) {
      if (hiddenSections.indexOf(i) === -1) {
        section = i
        break
      }
    }
  }
  return section
}

export const getCell = (rows, row, col) =>
  rows && rows[row] && (rows[row][col] || rows[row][col] === 0) ? rows[row][col] : null

// check if at least one side of one widget sit outside of section rectangle
export const hasWidgetsOutsideSection = (widgets, section) =>
  widgets.some(widget => {
    const absolutePositionY = widget.positionY + widget.section * DEFAULT_CARD_HEIGHT

    return isWidgetIntersectsWithHiddenSections({
      x: widget.positionX,
      y: absolutePositionY,
      width: widget.width,
      height: widget.height,
      transform: widget.rotation,
      hiddenSections: [section]
    })
  })

export const isFilesCouldBeGrouped = selectedWidgetsData => {
  const state = {
    isGroupFilesShown: !!selectedWidgetsData.length,
    uploadFilesCount: 0, // count of upload files widgets
    filesCount: 0, // file widgets count
    section: 0, // section where files will be moved to
    uploadFilesUuid: ''
  }

  selectedWidgetsData.forEach(widget => {
    if (isFileManager(widget)) {
      state.uploadFilesCount += 1
      state.uploadFilesUuid = widget.uuid
      state.section = widget.section
    } else {
      const isWidgetCouldBeGrouped = hasFlag(widget.flags, flagsList.groupToUploadFilesWidget)
      state.isGroupFilesShown = state.isGroupFilesShown && isWidgetCouldBeGrouped
      if (isWidgetCouldBeGrouped) {
        state.filesCount += 1
        state.section = widget.section
      }
    }
  })

  state.isGroupFilesShown =
    state.isGroupFilesShown && state.uploadFilesCount <= 1 && state.filesCount > 0
  return state
}

export const isGroupedFilesNotExceedMax = (selectedWidgets, widgets, uploadFilesUuid) => {
  const groupedFiles = uploadFilesUuid ? getGroupedFiles(uploadFilesUuid, widgets).length : 0
  const filesToGroup = uploadFilesUuid ? selectedWidgets.length - 1 : selectedWidgets.length
  return groupedFiles + filesToGroup <= MAX_GROUPED_FILES_COUNT
}

export const isWidgetHiddenInCardBuilder = widget => !!widget.uploadFilesUuid

const cellMaxWidth = 300
export const restrictCellMaxWidth = width => (width > cellMaxWidth ? cellMaxWidth : width)

// used to find all grouped files inside upload files widget
export const getSelectedWidgets = (_selectedWidgets, currentCard) => {
  let selectedWidgets = _selectedWidgets
  const { widgets } = currentCard
  const selectedWidgetsData = getWidgetsDataByIds(widgets, selectedWidgets)
  selectedWidgetsData.forEach(selectedWidget => {
    if (isFileManager(selectedWidget)) {
      const groupedWidgets = getGroupedFiles(selectedWidget.uuid, widgets)
      selectedWidgets = selectedWidgets.concat(groupedWidgets.map(widget => widget.uuid))
    }
  })
  return { selectedWidgets }
}

export const getDeleteWidgetsPayload = (_selectedWidgets, currentCard) => {
  const { selectedWidgets } = getSelectedWidgets(_selectedWidgets, currentCard)
  return {
    type: WIDGET_DELETE,
    widgetIds: selectedWidgets,
    data: getWidgetsDataByIds(currentCard.widgets, selectedWidgets),
    tenantId: currentCard.board.tenantId,
    boardId: currentCard.board.boardId,
    itemID: currentCard.uuid
  }
}

// start coord needs to be smaller than end
export const getTableRightRange = range => {
  if (!range) {
    // if there is no range return it
    return range
  }
  let [rowStart, colStart, rowEnd, colEnd] = range

  if (rowStart > rowEnd) {
    ;[rowStart, rowEnd] = [rowEnd, rowStart]
  }
  if (colStart > colEnd) {
    ;[colStart, colEnd] = [colEnd, colStart]
  }
  if (rowStart < 0) {
    rowStart = 0
  }
  if (colStart < 0) {
    colStart = 0
  }
  return [rowStart, colStart, rowEnd, colEnd]
}

export const updateWidgetsDimensions = (widgets, cardHeight) => {
  return widgets.map(widget => {
    const { height, width, positionX, positionY, section } = widget
    const exceedingWidth = positionX + width - DEFAULT_CARD_WIDTH
    const exceedingHeight = section * DEFAULT_CARD_HEIGHT + positionY + height - cardHeight
    return {
      ...widget,
      height: exceedingHeight > 0 ? height - exceedingHeight : height,
      width: exceedingWidth > 0 ? width - exceedingWidth : width
    }
  })
}

export const shiftWidgetsSection = (widgets, activeSection, newSectionIndex) => {
  return widgets.reduce((acc, widget) => {
    const section = widget.section

    if (section > activeSection && section >= newSectionIndex) {
      acc.push({
        ...widget,
        section: section + 1
      })

      return acc
    }

    return acc
  }, [])
}

export const shiftHiddenSections = (hiddenSections, activeSection, newSectionIndex) => {
  return hiddenSections.map(section => {
    if (section > activeSection && section >= newSectionIndex) {
      return section + 1
    }

    return section
  })
}
