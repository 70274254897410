import React from 'react'

const headerConfig = {
  widget: {
    leftIconClass: 'icon-widget',
    leftTitle: 'Widgets',
    rightIconClass: 'icon-item-card',
    rightTitle: 'Card'
  },
  workflow: {
    leftIconClass: 'icon-workflow',
    leftTitle: 'Workflow',
    rightIconClass: 'icon-item-board',
    rightTitle: 'Board'
  }
}

const ConnectionsListHeader = ({ activeKey, count }) => {
  return (
    <div className="list-header">
      <span className="icon-wrapper">
        <i className={`icon ${headerConfig[activeKey].leftIconClass}`} />
        {`${headerConfig[activeKey].leftTitle} (${count})`}
      </span>
      <span className="icon-wrapper">
        <i className={`icon ${headerConfig[activeKey].rightIconClass}`} />
        {headerConfig[activeKey].rightTitle}
      </span>
    </div>
  )
}

export default ConnectionsListHeader
