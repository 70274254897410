import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Tooltip } from 'components/common/tooltip/tooltip'
import 'scss/ribbonTools/ribbon-button.scss'

const RibbonButton = React.forwardRef((props, ref) => {
  const {
    as: Wrapper,
    id,
    icon,
    children,
    className,
    disabled,
    active,
    tooltipText,
    tooltipPlacement,
    onClick,
    ...rest
  } = props

  return (
    <Tooltip
      id={`${id}-tooltip`}
      placement={tooltipPlacement}
      title={tooltipText}
      shouldWrapChildrenWithDiv
    >
      <Wrapper
        id={id}
        ref={ref}
        className={classNames('ribbon-button', className, {
          active,
          disabled
        })}
        onClick={disabled ? null : onClick}
        {...rest}
      >
        {icon && <div className="icon-wrapper">{icon}</div>}
        {children}
      </Wrapper>
    </Tooltip>
  )
})

RibbonButton.propTypes = {
  id: PropTypes.string.isRequired,
  as: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
  icon: PropTypes.element,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  tooltipText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  tooltipPlacement: PropTypes.oneOf(['bottom', 'top', 'right', 'left'])
}

RibbonButton.defaultProps = {
  as: 'button',
  className: '',
  icon: null,
  onClick: () => null,
  disabled: false,
  active: false,
  tooltipText: '',
  tooltipPlacement: 'bottom'
}

export default RibbonButton
