import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import messages from 'constants/messages'
import { getUserRole } from 'helpers/rolesHelpers'
import UserLogo from 'components/common/UserLogo'
import { Tooltip } from 'components/common/tooltip/tooltip'
import 'scss/filters/filterPreview/filter-details-preview.scss'
import FilterRulesPreview from './FilterRulesPreview'

const boardLevelRoles = ['Member']

const getOwnerPermission = role => {
  if (boardLevelRoles.includes(role)) {
    return messages.BOARDS_LIMITED_ACCESS
  }

  return getUserRole(role)
}

const FilterDetailsPreview = props => {
  const {
    className,
    filter: { filters, object, owner },
    filtersConfig
  } = props

  return (
    <div className={classNames('filter-details-preview', className)}>
      <div className="details-column owner-permission-column">
        <span className="column-label">
          {messages.PERMISSIONS}
          <Tooltip
            id="filter-owner-permission-tooltip"
            placement="top"
            shouldWrapChildrenWithDiv
            title={messages.FILTER_WORKS_ON_OWNER_PERMISSIONS}
          >
            <i className="icon icon-hint" />
          </Tooltip>
        </span>
        <span className="bold">{getOwnerPermission(owner.role)}</span>
      </div>
      <div className="details-column filter-owner-column">
        <span className="column-label">{messages.OWNER}</span>
        <div className="filter-owner">
          <UserLogo
            avatarImage={owner.avatarImage || ''}
            username={owner.username}
            size="extra-small-20px"
          />
          <span className="user-name bold" title={owner.username}>
            {owner.username}
          </span>
        </div>
      </div>
      <div className="details-column filter-rules-column">
        <span className="column-label">{messages.RULES}</span>
        <FilterRulesPreview filters={filters} rulesConfig={filtersConfig} object={object} />
      </div>
    </div>
  )
}

FilterDetailsPreview.propTypes = {
  className: PropTypes.string,
  filter: PropTypes.shape({
    uuid: PropTypes.string,
    filters: PropTypes.object,
    owner: PropTypes.object
  }).isRequired,
  filtersConfig: PropTypes.object.isRequired
}

FilterDetailsPreview.defaultProps = {
  className: ''
}

export default FilterDetailsPreview
