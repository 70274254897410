// mark output as stopped
export const addOutputStopper = ({ outputsStoppers, outputId, blockId }) => ({
  ...outputsStoppers,
  [outputId]: blockId
})

// remove output from stopped outputs list in case it was stopped
export const removeOutputStopper = ({ outputsStoppers, outputId }) => {
  if (outputId in outputsStoppers) {
    // eslint-disable-next-line no-unused-vars
    const { [outputId]: removed, ...rest } = outputsStoppers

    return rest
  }

  return outputsStoppers
}

export const removeOutputStopperByBlockId = ({ outputsStoppers, blockId }) =>
  Object.entries(outputsStoppers).reduce((acc, [outputId, id]) => {
    if (blockId === id) {
      return acc
    }

    acc[outputId] = id

    return acc
  }, {})

// Check whether output is stopped by block which indexes less than above blockIndex.
export const isOutputStopped = args => {
  const { outputId, blockIndex, workflowBlocksMap, outputsStoppers } = args

  const isStopped = outputId && outputId in outputsStoppers

  if (!isStopped) {
    return false
  }

  const stopBlockId = outputsStoppers[outputId]
  const stopBlockIndex = workflowBlocksMap[stopBlockId].index

  return stopBlockIndex < blockIndex
}
