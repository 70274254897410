export const getSortedOrganizations = (organizations, fieldToSort = 'name') =>
  organizations?.sort((org1, org2) => {
    if (org1[fieldToSort]?.toLowerCase() > org2[fieldToSort]?.toLowerCase()) {
      return 1
    }
    if (org1[fieldToSort]?.toLowerCase() < org2[fieldToSort]?.toLowerCase()) {
      return -1
    }
    return 0
  }) || []
