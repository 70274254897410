export enum EFilterRuleConditionValueType {
  text = 'text',
  variable = 'variable',
  entityIds = 'entityIds'
}

export enum EFilterRuleConditionPredicateType {
  multiSelect = 'multiSelect',
  multiInput = 'multiInput',
  input = 'input'
}

export enum EFilterRuleCondition {
  UBF = 'UBF',
  card = 'card',
  board = 'board',
  dataRecordColumn = 'dataRecordColumn',
  tenants = 'tenants',
  team = 'team'
}

export enum EFilterRuleConditionPredicateParam {
  columnName = 'columnName',
  x = 'x',
  y = 'y'
}

export enum EFilterRuleConditionPredicateName {
  condition = 'condition',
  predicate = 'predicate',
  cellPredicate = 'cellPredicate',
  datePredicate = 'datePredicate'
}

export enum EFilterRuleConditionPredicate {
  is = 'is',
  current = 'current',
  cellCoordinates = 'cellCoordinates',
  managerOfCurrentCardsOwner = 'managerOfCurrentCardsOwner',
  byColumnName = 'byColumnName',
  all = 'all',
  exactDate = 'exactDate'
}

export interface IFilter {
  name: string
  uuid: string
  filters: {
    rules: IFilterRule[]
  }
  isCrossTeam?: boolean
  isOpenScope?: boolean
  object: string
  tenantId: string
  createdBy: string
  creationDate: number
}

export interface IFilterRule {
  uuid: string
  conditions: IFilterRuleCondition[]
}

export interface IFilterRuleEntity {
  name: string
  boardId: string
  tenantId: string
}

export interface IFilterRuleVariable {
  id: string
  name: string
  tags: string[]
  filter?: {
    attribute: {
      propertyName: string
      x: number
      y: number
    }
  }
  object: string
  tenantId: string
  createdBy: string
  creationDate: number
}

export type IFilterRuleValue = unknown

export interface IFilterRuleCondition extends Record<string, unknown> {
  uuid: string
  valueType: EFilterRuleConditionValueType
  variables: IFilterRuleVariable[]
  entityIds: IFilterRuleEntity[]
  values: IFilterRuleValue[]
  x: number
  y: number
}

export interface IFilterRulesConfigAttributeParam {
  name: string
  type?: string
}

export interface IFilterRulePredicateConfig {
  name: string
  type?: EFilterRuleConditionPredicateType
  params?: Record<EFilterRuleConditionPredicateParam, IFilterRulesConfigAttributeParam>
  predicates?: TFilterRulesConfig<IFilterRulePredicateConfig>
  hasAutoComplete?: boolean
  defaultPredicate?: EFilterRuleConditionPredicate
  used?: boolean
}

export interface IFilterRuleConditionConfig extends IFilterRulePredicateConfig {
  filterObjects: string[]
  isSortable: boolean
  isEditable: boolean
  isFilterable: boolean
  isWidgetAttribute: boolean
  withCoordinates?: boolean
  options?: string[]
}

export type TFilterRulesConfig<T extends IFilterRulePredicateConfig> = Record<string, T>
