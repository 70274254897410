import React, { Component } from 'react'
import classNames from 'classnames'
import { Button } from '@praxie/shared'
import messages from 'constants/messages'
import { keyDownEventListener } from 'helpers/board/boardHelpers'
import { validateForNotEmpty, validateJiraUrl } from 'helpers/validationHelpers'
import jiraLogoFullIcon from 'assets/images/icons/ic_jira_logo_full.svg?url'
import jiraLogoIcon from 'assets/images/icons/ic_jira_logo.svg?url'
import MultiSelect from '../fields/MultiSelect'
import 'scss/jira/jira-connection-form.scss'

const defaultFilter = {
  label: messages.ALL,
  value: 'all',
  className: 'not-clear'
}

const BASIC_FILTER_MODE = 'basic'
const ADVANCED_FILTER_MODE = 'advanced'

export default class JiraConnectionForm extends Component {
  constructor(props) {
    super(props)

    let selectedProjectFilter = props.selectedJiraProjects || []
    if (!Array.isArray(props.selectedJiraProjects)) {
      selectedProjectFilter = []
    }
    const selectedIssueTypeFilter = this.formatDefaultSelectedFilter(props.selectedJiraIssueTypes)
    const selectedStatusFilter = this.formatDefaultSelectedFilter(props.selectedJiraStatuses)
    const selectedAssigneeFilter = this.formatDefaultSelectedFilter(props.selectedJiraUsers)
    const selectedComponentFilter = this.formatDefaultSelectedFilter(props.selectedJiraComponents)

    this.state = {
      urlError: '',
      emailError: '',
      passwordError: '',

      selectedProjectFilter,
      selectedIssueTypeFilter,
      selectedStatusFilter,
      selectedAssigneeFilter,
      selectedComponentFilter,

      projects: [],
      issueTypes: [],
      statuses: [],
      users: [],
      components: [],
      favFilters: []
    }

    this.keyDownEventListener = this.keyDownEventListener.bind(this)
  }

  componentDidMount() {
    if (this.props.jiraProjects) {
      this.processProjects(this.props.jiraProjects || [])
    }
    if (this.props.jiraIssueTypes) {
      this.processIssueTypes(this.props.jiraIssueTypes || [])
    }
    if (this.props.jiraStatuses) {
      this.processStatuses(this.props.jiraStatuses || [])
    }
    if (this.props.jiraUsers) {
      this.processUsers(this.props.jiraUsers || [])
    }
    if (this.props.jiraComponents) {
      this.processComponents(this.props.jiraComponents || [])
    }
    if (this.props.jiraFavFilters) {
      this.processFavFilters(this.props.jiraFavFilters || [])
    }

    document.addEventListener('keydown', this.keyDownEventListener)
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.jiraProjects) {
      this.processProjects(props.jiraProjects || [])
    }
    if (props.jiraIssueTypes) {
      this.processIssueTypes(props.jiraIssueTypes || [])
    }
    if (props.jiraStatuses) {
      this.processStatuses(props.jiraStatuses || [])
    }
    if (props.jiraUsers) {
      this.processUsers(props.jiraUsers || [])
    }
    if (props.jiraComponents) {
      this.processComponents(props.jiraComponents || [])
    }
    if (props.jiraFavFilters) {
      this.processFavFilters(props.jiraFavFilters || [])
    }

    const newState = {}
    if (
      JSON.stringify(props.selectedJiraProjects) !== JSON.stringify(this.props.selectedJiraProjects)
    ) {
      let selectedProjectFilter = props.selectedJiraProjects || []
      if (!Array.isArray(props.selectedJiraProjects)) {
        selectedProjectFilter = []
      }
      newState.selectedProjectFilter = selectedProjectFilter
    }
    if (
      JSON.stringify(props.selectedJiraIssueTypes) !==
      JSON.stringify(this.props.selectedJiraIssueTypes)
    ) {
      newState.selectedIssueTypeFilter = this.formatDefaultSelectedFilter(
        props.selectedJiraIssueTypes
      )
    }
    if (
      JSON.stringify(props.selectedJiraStatuses) !== JSON.stringify(this.props.selectedJiraStatuses)
    ) {
      newState.selectedStatusFilter = this.formatDefaultSelectedFilter(props.selectedJiraStatuses)
    }
    if (JSON.stringify(props.selectedJiraUsers) !== JSON.stringify(this.props.selectedJiraUsers)) {
      newState.selectedAssigneeFilter = this.formatDefaultSelectedFilter(props.selectedJiraUsers)
    }
    if (
      JSON.stringify(props.selectedJiraComponents) !==
      JSON.stringify(this.props.selectedJiraComponents)
    ) {
      newState.selectedComponentFilter = this.formatDefaultSelectedFilter(
        props.selectedJiraComponents
      )
    }
    this.setState(newState)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyDownEventListener)
  }

  onUrlFieldBlur(event) {
    const value = event.target.value
    this.validateUrlField(value)
  }

  onEmailFieldBlur(event) {
    const value = event.target.value
    this.validateEmailField(value)
  }

  onPasswordFieldBlur(event) {
    const value = event.target.value
    this.validatePasswordField(value)
  }

  onModalConfirm() {
    const payload = {}
    let emailError = ''
    let urlError = ''
    let passwordError = ''

    if (this.urlField) {
      const url = this.urlField.value
      payload.url = url
      urlError = validateJiraUrl(url)
    }
    if (this.emailField) {
      const email = this.emailField.value
      payload.email = email
      emailError = validateForNotEmpty(email)
    }
    if (this.passwordField) {
      const password = this.passwordField.value
      payload.password = btoa(password)
      passwordError = validateForNotEmpty(password)
    }

    if (urlError || emailError || passwordError) {
      this.setState({ emailError, passwordError, urlError })
    } else {
      this.props.onModalConfirm(payload)
    }
  }

  onProjectFilterChange(newValues) {
    const selectedProjectFilter = [...newValues]
    if (this.props.onProjectFilterChange) {
      this.props.onProjectFilterChange(selectedProjectFilter)
    }
    this.setState({ selectedProjectFilter })
  }

  onIssueTypeFilterChange(newValues) {
    const selectedIssueTypeFilter = this.getSelectedFilter(newValues)
    if (this.props.onFilterChange) {
      this.props.onFilterChange(selectedIssueTypeFilter, 'issueTypes')
    }
    this.setState({ selectedIssueTypeFilter })
  }

  onStatusFilterChange(newValues) {
    const selectedStatusFilter = this.getSelectedFilter(newValues)
    if (this.props.onFilterChange) {
      this.props.onFilterChange(selectedStatusFilter, 'statuses')
    }
    this.setState({ selectedStatusFilter })
  }

  onAssigneeFilterChange(newValues) {
    const selectedAssigneeFilter = this.getSelectedFilter(newValues)
    if (this.props.onFilterChange) {
      this.props.onFilterChange(selectedAssigneeFilter, 'users')
    }
    this.setState({ selectedAssigneeFilter })
  }

  onComponentFilterChange(newValues) {
    const selectedComponentFilter = this.getSelectedFilter(newValues)
    if (this.props.onFilterChange) {
      this.props.onFilterChange(selectedComponentFilter, 'components')
    }
    this.setState({ selectedComponentFilter })
  }

  getSelectedFilter(newValues) {
    let selectedFilter = [...newValues]
    // if some values were selected, 'all' option should be removed
    if (selectedFilter.length > 1 && selectedFilter[0].value === 'all') {
      selectedFilter.splice(0, 1)
    } else if (!selectedFilter.length) {
      // if no options selected, select 'all'
      selectedFilter = [defaultFilter]
    }
    return selectedFilter
  }

  setOptionsChecked() {
    this.state.projects.forEach(option => {
      const values = this.state.selectedProjectFilter.filter(
        project => project.value === option.value
      )
      option.checked = !!values.length
    })
    this.state.issueTypes.forEach(option => {
      const values = this.state.selectedIssueTypeFilter.filter(
        issueType => issueType.value === option.value
      )
      option.checked = !!values.length
    })
    this.state.statuses.forEach(option => {
      const values = this.state.selectedStatusFilter.filter(status => status.value === option.value)
      option.checked = !!values.length
    })
    this.state.users.forEach(option => {
      const values = this.state.selectedAssigneeFilter.filter(user => user.value === option.value)
      option.checked = !!values.length
    })
    this.state.components.forEach(option => {
      const values = this.state.selectedComponentFilter.filter(
        component => component.value === option.value
      )
      option.checked = !!values.length
    })
  }

  formatDefaultSelectedFilter(selectedJiraFilters) {
    let selectedFilter =
      selectedJiraFilters && selectedJiraFilters.length ? selectedJiraFilters : [defaultFilter]
    if (!Array.isArray(selectedJiraFilters)) {
      selectedFilter = [defaultFilter]
    }

    return selectedFilter
  }

  processProjects(jiraProjects) {
    const projects = jiraProjects.map(project => ({
      label: project.name,
      value: project.key,
      projectId: project.id
    }))
    this.setState({ projects })
  }

  processIssueTypes(jiraIssueTypes) {
    const issueTypes = jiraIssueTypes.map(issueType => ({
      label: issueType.name,
      value: issueType.id
    }))
    this.setState({ issueTypes })
  }

  processStatuses(jiraStatuses) {
    const statuses = jiraStatuses.map(jiraStatus => ({
      label: jiraStatus.name,
      value: jiraStatus.id
    }))
    this.setState({ statuses })
  }

  processUsers(jiraUsers) {
    const users = jiraUsers.map(jiraUser => ({ label: jiraUser.displayName, value: jiraUser.key }))
    this.setState({ users })
  }

  processComponents(jiraComponents) {
    const components = jiraComponents.map(jiraComponent => ({
      label: jiraComponent.name,
      value: jiraComponent.key
    }))
    this.setState({ components })
  }

  processFavFilters(jiraFavFilters) {
    const favFilters = jiraFavFilters.map(filter => ({
      label: filter.name,
      value: filter.id,
      jql: filter.jql
    }))
    this.setState({ favFilters })
  }

  validateUrlField(value) {
    const urlError = validateJiraUrl(value)

    if (urlError !== this.state.urlError) {
      this.setState({ urlError })
    }

    return !urlError
  }

  validateEmailField(value) {
    const emailError = validateForNotEmpty(value)

    if (emailError !== this.state.emailError) {
      this.setState({ emailError })
    }

    return !emailError
  }

  validatePasswordField(value) {
    const passwordError = validateForNotEmpty(value)

    if (passwordError !== this.state.passwordError) {
      this.setState({ passwordError })
    }

    return !passwordError
  }

  keyDownEventListener(event) {
    const { showFooterBlock, authenticationFailed } = this.props
    const isInput = event.target && event.target.tagName === 'INPUT'

    setTimeout(() => {
      keyDownEventListener(
        event,
        (showFooterBlock || authenticationFailed) && !isInput,
        this.onModalConfirm.bind(this),
        this.props.onModalConfirm.bind(null, '')
      )
    }, 7)
  }

  render() {
    const {
      jiraCredentials,
      jiraUrl,
      showCredentialsBlock,
      showFiltersBlock,
      showUrlActionsButtons,
      showFooterBlock,
      onEditUrl,
      onDeleteUrl,
      onFavFilterChange,
      editingMode,
      authenticationFailed,

      onFilterTabClick,
      filterMode,
      selectedJiraFavFilter,
      teamName
    } = this.props

    // validate
    let selectedFavFilter = {}
    if (selectedJiraFavFilter) {
      selectedFavFilter = {
        label: typeof selectedJiraFavFilter.label === 'string' ? selectedJiraFavFilter.label : '',
        value: typeof selectedJiraFavFilter.value === 'string' ? selectedJiraFavFilter.value : '',
        jql: typeof selectedJiraFavFilter.jql === 'string' ? selectedJiraFavFilter.jql : ''
      }
    }

    const { email, password } = jiraCredentials
    const {
      urlError,
      emailError,
      passwordError,

      selectedProjectFilter,
      selectedIssueTypeFilter,
      selectedStatusFilter,
      selectedAssigneeFilter,
      selectedComponentFilter,
      projects,
      issueTypes,
      statuses,
      users,
      components,
      favFilters
    } = this.state

    // show url block when there is no url, url is editing or error while authenticating
    const showSpecifyInstanceBlock = !jiraUrl || editingMode || authenticationFailed

    this.setOptionsChecked()

    const disabledFilters = !selectedProjectFilter.length
    const urlPlaceholder = 'https://'
    return (
      <section
        className={classNames({
          'jira-connection-form': true,
          'white-background': showFooterBlock || authenticationFailed
        })}
      >
        {showSpecifyInstanceBlock ? (
          <div>
            <div className="jira-message">
              <img alt="jira-logo" src={jiraLogoFullIcon} width="90px" height="35px" />
              <span> {messages.SPECIFY_JIRA_INSTANCE(teamName)} </span>
            </div>
            <div
              className={classNames({
                'form-field': true,
                'has-error': urlError,
                'url-field': true
              })}
            >
              <label className="form-field-label" htmlFor="url">
                {messages.URL}
              </label>
              <input
                ref={urlField => (this.urlField = urlField)}
                className="input-field input-field-medium"
                id="url"
                type="text"
                maxLength={100}
                placeholder="sitename.atlassian.net"
                defaultValue={jiraUrl || urlPlaceholder}
                onBlur={this.onUrlFieldBlur.bind(this)}
              />
              <span className="field-error">{urlError}</span>
            </div>
          </div>
        ) : (
          <div className="jira-message url-container">
            <div className="logo-wrapper">
              <img alt="jira-logo" className="jira-logo" src={jiraLogoIcon} />
              <div className="jira-name">
                <span> {messages.CONNECTED_JIRA} </span>
                <span className="bold jira-url"> {jiraUrl.replace('https://', '')} </span>
              </div>
            </div>
            {showUrlActionsButtons && (
              <div className="actions-container">
                <i className="icon icon-edit edit-row" onClick={onEditUrl} />
                <i className="fa fa-trash-o" onClick={onDeleteUrl} />
              </div>
            )}
          </div>
        )}
        {showCredentialsBlock && (!email || !password || authenticationFailed) && (
          <div>
            <span
              className={classNames({
                'auth-error': true,
                hidden: !authenticationFailed
              })}
            >
              {messages.AUTHENTICATION_FAILED}
            </span>
            <div
              className={classNames({
                'form-field': true,
                'has-error': emailError,
                'email-field': true
              })}
            >
              <label className="form-field-label" htmlFor="email">
                {messages.LOGIN}
              </label>
              <input
                ref={emailField => (this.emailField = emailField)}
                className="input-field input-field-medium"
                id="email"
                type="email"
                defaultValue={email}
                maxLength={50}
                placeholder={messages.JIRA_LOGIN_PLACEHOLDER}
                onBlur={this.onEmailFieldBlur.bind(this)}
              />
              <span className="field-error">{emailError}</span>
            </div>
            <div
              className={classNames({
                'form-field': true,
                'has-error': passwordError,
                'password-field': true
              })}
            >
              <label className="form-field-label" htmlFor="password">
                {messages.PASSWORD}
              </label>
              <input
                ref={passwordField => (this.passwordField = passwordField)}
                className="input-field input-field-medium"
                id="password"
                type="password"
                defaultValue={password}
                placeholder={messages.JIRA_PASSWORD_PLACEHOLDER}
                maxLength={50}
                onBlur={this.onPasswordFieldBlur.bind(this)}
              />
              <span className="field-error">{passwordError}</span>
            </div>
          </div>
        )}
        {(showFooterBlock || authenticationFailed) && (
          <div className="jira-connection__footer">
            <span className="footer-text">{messages.JIRA_CONNECTION_CONFIGURATION_TEXT}</span>
            <Button onClick={this.onModalConfirm.bind(this)}>
              {!showSpecifyInstanceBlock ? messages.LOG_IN : messages.ADD_LINK}
            </Button>
          </div>
        )}
        {showFiltersBlock && !authenticationFailed && jiraUrl && email && password && (
          <div>
            <div className="navigation-tabs">
              <span
                className={classNames({
                  tab: true,
                  active: filterMode !== BASIC_FILTER_MODE
                })}
                onClick={onFilterTabClick ? onFilterTabClick.bind(null, BASIC_FILTER_MODE) : null}
              >
                {messages.BASIC}
              </span>
              <span
                className={classNames({
                  tab: true,
                  active: filterMode !== ADVANCED_FILTER_MODE
                })}
                onClick={
                  onFilterTabClick ? onFilterTabClick.bind(null, ADVANCED_FILTER_MODE) : null
                }
              >
                {messages.ADVANCED}
              </span>
            </div>
            <span className="specify-filters-text">{messages.SPECIFY_FILTERS}</span>
            {filterMode === BASIC_FILTER_MODE ? (
              <div className="filters-container">
                <label
                  className={classNames({
                    'form-field-label': true
                  })}
                  htmlFor="project-select"
                >
                  {messages.PROJECT}
                </label>
                <MultiSelect
                  isSearchable
                  selectOptions={projects}
                  values={selectedProjectFilter}
                  onChange={this.onProjectFilterChange.bind(this)}
                  placeholder={messages.FIND_PROJECTS}
                />
                <label
                  className={classNames({
                    'form-field-label': true,
                    disabled: disabledFilters
                  })}
                  htmlFor="issue-type-select"
                >
                  {messages.ISSUE_TYPE}
                </label>
                <MultiSelect
                  isSearchable
                  selectOptions={issueTypes}
                  values={selectedIssueTypeFilter}
                  onChange={this.onIssueTypeFilterChange.bind(this)}
                  placeholder={messages.FIND_ISSUE_TYPES}
                  isDisabled={disabledFilters}
                />
                <label
                  className={classNames({
                    'form-field-label': true,
                    disabled: disabledFilters
                  })}
                  htmlFor="status-select"
                >
                  {messages.STATUS}
                </label>
                <MultiSelect
                  isSearchable
                  selectOptions={statuses}
                  values={selectedStatusFilter}
                  onChange={this.onStatusFilterChange.bind(this)}
                  placeholder={messages.FIND_STATUSES}
                  isDisabled={disabledFilters}
                />
                <label
                  className={classNames({
                    'form-field-label': true,
                    disabled: disabledFilters
                  })}
                  htmlFor="assignee-select"
                >
                  {messages.ASSIGNEE}
                </label>
                <MultiSelect
                  isSearchable
                  selectOptions={users}
                  values={selectedAssigneeFilter}
                  onChange={this.onAssigneeFilterChange.bind(this)}
                  placeholder={messages.FIND_USERS}
                  isDisabled={disabledFilters}
                />
                <label
                  className={classNames({
                    'form-field-label': true,
                    disabled: disabledFilters
                  })}
                  htmlFor="component-select"
                >
                  {messages.COMPONENT}
                </label>
                <MultiSelect
                  isSearchable
                  selectOptions={components}
                  values={selectedComponentFilter}
                  onChange={this.onComponentFilterChange.bind(this)}
                  placeholder={messages.FIND_COMPONENTS}
                  isDisabled={disabledFilters}
                />
              </div>
            ) : (
              <div className="filters-container">
                <label
                  className={classNames({
                    'form-field-label': true
                  })}
                  htmlFor="filter-select"
                >
                  {messages.FAVOURITE_FILTER}
                </label>
                <MultiSelect
                  isSearchable
                  selectOptions={favFilters}
                  values={selectedFavFilter}
                  onChange={onFavFilterChange}
                  placeholder={messages.FIND_FILTERS}
                />
              </div>
            )}
          </div>
        )}
      </section>
    )
  }
}
