import MultiCreatableSelect from 'components/fields/MultiCreatableSelect'
import FormField from 'components/common/FormField'
import { mapSelectedValuesFromLabel } from 'helpers/fields/selectHelpers'
import messages from 'constants/messages'
import { loadSuggestion } from 'helpers/autosuggestHelpers'
import { getTagsSuggestions } from 'api/variablesAPI'
import 'scss/variables/variableConfiguration/tags.scss'

export const Tags = ({ isSaving, tenantId, tags, changeTags }) => {
  const onLoadSuggestion = inputValue =>
    loadSuggestion({
      tenantId,
      inputValue,
      getSuggestionsRequest: getTagsSuggestions
    })

  const isValidNewOption = inputValue => {
    if (!inputValue) {
      return false
    }

    if (!inputValue.trim()) {
      return false
    }

    return true
  }
  return (
    <div className="variable-configuration-tags">
      <FormField id="variable-tags" className="variable-tags" label={messages.VARIABLE_TAGS}>
        <MultiCreatableSelect
          className="tags-field"
          size="large"
          values={mapSelectedValuesFromLabel(tags)}
          placeholder={messages.START_TO_TYPE_TAG_NAME}
          maxLength={30}
          loadSuggestion={onLoadSuggestion}
          isDisabled={isSaving}
          isValidNewOption={isValidNewOption}
          menuPlacement="top"
          isClearable
          onChange={changeTags}
        />
      </FormField>
    </div>
  )
}


