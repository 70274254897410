import React, { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'

import IssueCounter from '../../components/modals/IssueCounter'

import * as actions from '../../actions'
import messages from '../../constants/messages'
import { WIDGET_UPDATE } from '../../constants/actionTypes'
import { keyDownEventListener } from '../../helpers/board/boardHelpers'
import { validateNumber } from '../../helpers/validationHelpers'

const MANUAL_DATA_MODE = 'manual'
const JIRA_DATA_MODE = 'jira'

const BASIC_FILTER_MODE = 'basic'

class IssueCounterContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      widget: {},
      isOpenColorPicker: false,
      headerColor: '',
      topLabel: '',
      bottomLabel: '',
      widgetTitle: '',
      counter: 0,
      counterError: '',
      jiraFilters: {},
      dataMode: MANUAL_DATA_MODE,
      filterMode: BASIC_FILTER_MODE,
      favFilter: {},

      jiraCredentials: {},

      authError: false,
      sourceCardData: null
    }

    this.updateWidget = this.updateWidget.bind(this)
    this.keyDownEventListener = this.keyDownEventListener.bind(this)
  }

  componentDidMount() {
    document.addEventListener('keydown', this.keyDownEventListener)
  }

  UNSAFE_componentWillReceiveProps(props) {
    const jiraCredentials = this.readUserJiraCredentials()
    this.setState({ jiraCredentials })

    const { issueCounterData, currentCard, cards, widgetsMap } = props

    if (issueCounterData.state) {
      let currentWidget = {}
      let sourceCardData = null

      if (issueCounterData.mode === 'edit') {
        currentWidget = currentCard.widgets?.find(
          widget => widget.uuid === issueCounterData.widgetID
        )
      } else if (issueCounterData.mode === 'detailed') {
        const card = cards.find(item => item.uuid === issueCounterData.cardID)

        if (card.isLinked) {
          sourceCardData = {
            tenantId: card.linkToSrcCard.tenantId,
            boardId: card.linkToSrcCard.boardId,
            itemID: card.linkToSrcCard.cardUuid
          }
        }

        const widgets = widgetsMap[issueCounterData.cardID] || []

        currentWidget = widgets.find(widget => widget.uuid === issueCounterData.widgetID)
      }
      let newState = {}

      if (currentWidget) {
        if (this.props.issueCounterData.widgetID !== issueCounterData.widgetID) {
          newState = {
            widget: currentWidget,
            topLabel: currentWidget.topLabel,
            bottomLabel: currentWidget.bottomLabel,
            headerColor: currentWidget.headerColor,
            widgetTitle: currentWidget.widgetTitle,
            counter: currentWidget.counter,
            jiraFilters: currentWidget.jiraFilters || {},
            dataMode: currentWidget.dataMode || MANUAL_DATA_MODE,
            filterMode: currentWidget.filterMode || BASIC_FILTER_MODE,
            favFilter: currentWidget.favFilter,
            sourceCardData
          }
        } else {
          newState = {
            widget: currentWidget,
            topLabel: this.state.topLabel ? this.state.topLabel : currentWidget.topLabel,
            bottomLabel: this.state.bottomLabel
              ? this.state.bottomLabel
              : currentWidget.bottomLabel,
            headerColor: this.state.headerColor
              ? this.state.headerColor
              : currentWidget.headerColor,
            widgetTitle: this.state.widgetTitle
              ? this.state.widgetTitle
              : currentWidget.widgetTitle,
            counter: this.state.counter ? this.state.counter : currentWidget.counter,
            jiraFilters: this.state.jiraFilters
              ? this.state.jiraFilters || {}
              : currentWidget.jiraFilters || {},
            dataMode: this.state.dataMode
              ? this.state.dataMode
              : currentWidget.dataMode || MANUAL_DATA_MODE,
            filterMode: this.state.filterMode
              ? this.state.filterMode
              : currentWidget.filterMode || BASIC_FILTER_MODE,
            favFilter: this.state.favFilter ? this.state.favFilter : currentWidget.favFilter
          }
        }

        this.setState(newState)
      }
    }
    // if popup was opened
    if (!this.props.issueCounterData.state && props.issueCounterData.state) {
      const { email, password } = jiraCredentials
      try {
        atob(password) // if password was decoded
        if (email && password && this.props.jiraUrl) {
          const payload = { email, password, url: this.props.jiraUrl }
          this.requestFiltersData(payload)
        }
      } catch (err) {
        this.setState({
          authError: true
        })
      }
    } else if (this.props.issueCounterData.state && !props.issueCounterData.state) {
      this.setState({ isOpenColorPicker: false, sourceCardData: null })
    }
  }

  shouldComponentUpdate(props) {
    const isModalOpen = props.issueCounterData.state
    const isModalWasClosed = !props.issueCounterData.state && this.props.issueCounterData.state
    return isModalOpen || isModalWasClosed
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyDownEventListener)
  }

  onModalCancel() {
    this.props.toggleIssueCounterModal({
      state: false,
      cardID: undefined,
      widgetID: undefined,
      mode: ''
    })
    this.props.setEditingWidget('')
    this.setState({
      authError: false,
      counterError: ''
    })
  }

  onInputChange(prop, event) {
    const value = event.target.value
    this.setState({ [prop]: value })
  }

  onCounterFieldChange(event) {
    const counter = event.target.value
    const counterError = validateNumber(counter)
    this.setState({
      counter,
      counterError
    })
  }

  onModalConfirm() {
    const { counterError } = this.state
    if (!counterError) {
      this.updateWidget()
    }
  }

  onTabClick(dataMode) {
    this.setState({
      dataMode: dataMode === 0 ? MANUAL_DATA_MODE : JIRA_DATA_MODE
    })
    const jiraCredentials = this.readUserJiraCredentials()
    if (dataMode === 1) {
      const { email, password } = jiraCredentials
      try {
        atob(password) // try to decode password
        if (email && password && this.props.jiraUrl) {
          const payload = { email, password, url: this.props.jiraUrl }
          this.requestFiltersData(payload)
        }
      } catch (err) {
        this.setState({
          authError: true
        })
      }
    }
  }

  onFilterTabClick(filterMode) {
    const newState = { filterMode }
    if (filterMode === BASIC_FILTER_MODE) {
      newState.favFilter = {}
    }
    this.setState(newState)
  }

  onToggleColorPicker(isOpenColorPicker) {
    this.setState({ isOpenColorPicker })
  }

  onJiraConnectionLogIn({ email, password, url }) {
    const {
      updateUserRequest,
      user,
      organizationId,
      setUserJiraCredentials,
      getJiraTest,
      jiraCredentials
    } = this.props

    const jiraPassword = password || jiraCredentials.password
    const jiraEmail = email || jiraCredentials.email
    getJiraTest({
      url: url || user.jiraUrl,
      email: jiraEmail,
      password: jiraPassword
    }).then(response => {
      if (!response.errorCode && response.type !== 'error') {
        // set email & password to local storage
        if (email && password) {
          setUserJiraCredentials('email', email, organizationId)
          setUserJiraCredentials('password', password, organizationId)
        }

        const payload = {
          email: jiraEmail,
          password: jiraPassword,
          url: url || user.jiraUrl
        }
        if (url) {
          // set jira url to user
          updateUserRequest({
            data: { jiraUrl: url },
            userId: user.id
          })
        }
        this.requestFiltersData(payload)
        this.setState({
          authError: false
        })
      } else {
        this.setState({
          authError: true
        })
      }
    })
  }

  onProjectFilterChange(projectFilters) {
    const projectId = projectFilters[0] ? projectFilters[0].projectId : null
    if (projectId) {
      const payload = {
        ...this.state.jiraCredentials,
        url: this.props.jiraUrl,
        projectId
      }
      this.props.getJiraUsers(payload)
      this.props.getJiraComponents(payload)
    }
    if (projectFilters.length) {
      this.updateJiraFilters('projects', projectFilters)
    } else {
      // if no projects, clear all other filters
      this.setState({
        jiraFilters: {
          projects: [],
          users: [],
          statuses: [],
          components: [],
          issueTypes: []
        }
      })
    }
  }

  onFilterChange(filters, filterType) {
    this.updateJiraFilters(
      filterType,
      filters.filter(item => item.value !== 'all')
    )
  }

  onFavFilterChange(favFilter) {
    this.setState({
      favFilter
    })
  }

  requestFiltersData(payload) {
    const that = this
    this.props.getJiraProjects(payload).then(response => {
      if (response.type !== 'error') {
        const projectId = response[0] ? response[0].id : null
        if (projectId) {
          that.props.getJiraIssueTypes(payload)
          that.props.getJiraStatuses(payload)
          that.props.getJiraUsers({ ...payload, projectId })
          that.props.getJiraComponents({ ...payload, projectId })
        }
        that.setState({
          authError: false
        })
      } else {
        that.setState({
          authError: true
        })
      }
    })
    this.props.getJiraFavouriteFilters(payload)
  }

  changeHeaderColor(color) {
    const colorCode = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
    this.setState({ headerColor: colorCode })
  }

  updateJiraFilters(prop, value) {
    const { jiraFilters } = this.state

    const newJiraFilters = JSON.parse(JSON.stringify(jiraFilters))
    newJiraFilters[prop] = value
    this.setState({
      jiraFilters: newJiraFilters
    })
  }

  updateWidget() {
    const {
      currentCard,
      issueCounterData,
      currentBoard: { tenantId, boardId }
    } = this.props
    const {
      topLabel,
      bottomLabel,
      counter,
      widgetTitle,
      headerColor,
      widget,
      jiraFilters,
      dataMode,
      filterMode,
      favFilter,
      sourceCardData
    } = this.state

    let saveChangesFunction = this.props.saveChanges

    const widgetDataToSave = {
      ...widget,
      topLabel,
      bottomLabel,
      counter,
      widgetTitle,
      headerColor,
      jiraFilters,
      dataMode,
      filterMode,
      favFilter
    }

    const payload = {
      type: WIDGET_UPDATE,
      data: [widgetDataToSave],
      itemID: issueCounterData.cardID
    }

    if (sourceCardData) {
      payload.sourceCardData = sourceCardData
    }

    if (issueCounterData.mode === 'edit') {
      payload.tenantId = currentCard.board.tenantId
      payload.boardId = currentCard.board.boardId
    } else if (issueCounterData.mode === 'detailed') {
      payload.tenantId = tenantId
      payload.boardId = boardId

      saveChangesFunction = this.props.updateWidgetOnDetailedView
    }

    this.onModalCancel()

    return saveChangesFunction(payload)
  }

  goToLinking() {
    const { widget, counterError } = this.state
    if (!counterError) {
      const updater = this.updateWidget()
      if (updater) {
        updater.then(() =>
          this.props.toggleDataLinking({
            state: true,
            widgetID: widget.uuid
          })
        )
      }
    }
  }

  readUserJiraCredentials() {
    const { user } = this.props
    if (user.id) {
      const organizationId = user.organizationId
      const str = localStorage.getItem('jiraCredentials')
      return str ? JSON.parse(str)[organizationId] || {} : {}
    }
    return {}
  }

  unlinkWidget() {
    this.setState(prevState => ({
      widget: {
        ...prevState.widget,
        dataLink: {},
        chartData: null
      },
      counter: ''
    }))
  }

  keyDownEventListener(event) {
    const isInput = event.target && event.target.tagName === 'INPUT'

    setTimeout(() => {
      keyDownEventListener(event, this.props.issueCounterData.state && !isInput, this.updateWidget)
    }, 7)
  }

  render() {
    const {
      issueCounterData,
      // jiraCredentials,
      jiraProjects,
      jiraIssueTypes,
      jiraStatuses,
      jiraUsers,
      jiraComponents,
      jiraFavFilters,
      setUserJiraCredentials,
      jiraUrl,
      teamName,
      currentCard,
      currentBoard
    } = this.props
    const {
      widget,
      isOpenColorPicker,
      topLabel,
      bottomLabel,
      counter,
      counterError,
      widgetTitle,
      headerColor,
      jiraFilters,
      dataMode,
      filterMode,
      favFilter,
      jiraCredentials,
      authError
    } = this.state

    const jiraData = {
      topLabel,
      bottomLabel,
      counter,
      counterError,
      widgetTitle,
      headerColor,
      jiraFilters,
      filterMode,
      favFilter,
      dataMode
    }

    if (!issueCounterData.state) {
      return null
    }

    const isLinked = widget.dataLink && !!Object.keys(widget.dataLink).length

    const tabs = [
      {
        text: (
          <span>
            <i
              className={classNames({
                'icon icon-custom-data': true,
                active: dataMode === MANUAL_DATA_MODE
              })}
            />
            {messages.CUSTOM_DATA}
          </span>
        ),
        active: dataMode === MANUAL_DATA_MODE
      },
      {
        text: (
          <span>
            <i
              className={classNames({
                'icon icon-jira-data': true,
                active: dataMode === JIRA_DATA_MODE
              })}
            />
            {messages.JIRA_DATA}
          </span>
        ),
        active: dataMode === JIRA_DATA_MODE,
        disabled: isLinked
      }
    ]

    // const jiraFilters = widget.jiraFilters || {};

    let tenantId

    if (issueCounterData.mode === 'edit') {
      tenantId = currentCard.board.tenantId
    } else if (issueCounterData.mode === 'detailed') {
      tenantId = currentBoard.tenantId
    }

    return (
      <IssueCounter
        show={issueCounterData.state}
        isLinked={isLinked}
        onModalCancel={this.onModalCancel.bind(this)}
        onModalConfirm={this.onModalConfirm.bind(this)}
        goToLinking={this.goToLinking.bind(this)}
        unlinkWidget={this.unlinkWidget.bind(this)}
        onInputChange={this.onInputChange.bind(this)}
        onCounterFieldChange={this.onCounterFieldChange.bind(this)}
        widgetData={widget}
        jiraData={jiraData}
        tabs={tabs}
        onTabClick={this.onTabClick.bind(this)}
        onFilterTabClick={this.onFilterTabClick.bind(this)}
        onToggleColorPicker={this.onToggleColorPicker.bind(this)}
        changeHeaderColor={this.changeHeaderColor.bind(this)}
        isOpenColorPicker={isOpenColorPicker}
        jiraCredentials={jiraCredentials}
        jiraUrl={jiraUrl}
        setUserJiraCredentials={setUserJiraCredentials}
        onJiraConnectionLogIn={this.onJiraConnectionLogIn.bind(this)}
        onProjectFilterChange={this.onProjectFilterChange.bind(this)}
        onFilterChange={this.onFilterChange.bind(this)}
        onFavFilterChange={this.onFavFilterChange.bind(this)}
        jiraProjects={jiraProjects}
        jiraIssueTypes={jiraIssueTypes}
        jiraStatuses={jiraStatuses}
        jiraUsers={jiraUsers}
        jiraComponents={jiraComponents}
        jiraFavFilters={jiraFavFilters}
        selectedJiraProjects={jiraFilters.projects || []}
        selectedJiraIssueTypes={jiraFilters.issueTypes || []}
        selectedJiraStatuses={jiraFilters.statuses || []}
        selectedJiraUsers={jiraFilters.users || []}
        selectedJiraComponents={jiraFilters.components || []}
        selectedJiraFavFilter={favFilter || {}}
        authError={authError}
        teamName={teamName}
        tenantId={tenantId}
        mode={issueCounterData.mode}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    issueCounterData: state.builder.issueCounterData,
    currentCard: state.builder.currentCard,
    cards: state.board.cards,
    widgetsMap: state.board.widgetsMap,
    currentBoard: state.board.currentBoard,
    user: state.profile.user,
    organizationId: state.profile.activeOrganization.organizationId,
    teamName: state.profile.teamSettings.name,
    jiraUrl: state.profile.user.jiraUrl,
    jiraProjects: state.profile.jiraProjects,
    jiraIssueTypes: state.profile.jiraIssueTypes,
    jiraStatuses: state.profile.jiraStatuses,
    jiraUsers: state.profile.jiraUsers,
    jiraComponents: state.profile.jiraComponents,
    jiraFavFilters: state.profile.jiraFavFilters,
    jiraCredentials: state.profile.jiraCredentials
  }
}

const mapDispatchToProps = {
  toggleIssueCounterModal: actions.toggleIssueCounterModal,
  updateWidgetOnDetailedView: actions.updateWidgetOnDetailedView,
  saveChanges: actions.saveChanges,
  setUserJiraCredentials: actions.setUserJiraCredentials,
  toggleJiraConnectionModal: actions.toggleJiraConnectionModal,
  getJiraProjects: actions.getJiraProjects,
  getJiraIssueTypes: actions.getJiraIssueTypes,
  getJiraStatuses: actions.getJiraStatuses,
  getJiraUsers: actions.getJiraUsers,
  getJiraComponents: actions.getJiraComponents,
  getJiraFavouriteFilters: actions.getJiraFavouriteFilters,
  updateUserRequest: actions.updateUserRequest,
  setEditingWidget: actions.setEditingWidget,
  getJiraTest: actions.getJiraTest,
  toggleDataLinking: actions.toggleDataLinking
}

export default connect(mapStateToProps, mapDispatchToProps)(IssueCounterContainer)
