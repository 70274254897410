import { createReducer } from 'redux-create-reducer'
import * as ActionTypes from '../constants/actionTypes'

export const filters = createReducer(
  {},
  {
    [ActionTypes.TOGGLE_FILTERS_LIST_LOADING](state, action) {
      return {
        ...state,
        isFiltersListLoading: action.payload
      }
    },

    [ActionTypes.SET_FILTERS_CONFIG](state, action) {
      return {
        ...state,
        filtersConfig: action.payload
      }
    }
  }
)

export default filters
