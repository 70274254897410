import classNames from 'classnames'
import MUITooltip, { type TooltipProps } from '@mui/material/Tooltip'
import './tooltip.scss'

export type TTooltip = TooltipProps & {
  wrapperClassName?: string
  shouldWrapChildrenWithDiv?: boolean
}

export const Tooltip = ({
  className,
  children,
  PopperProps,
  wrapperClassName,
  shouldWrapChildrenWithDiv,
  ...props
}: TTooltip) => {
  const content = shouldWrapChildrenWithDiv ? (
    <div className={wrapperClassName}>{children}</div>
  ) : (
    children
  )

  return (
    <MUITooltip
      arrow
      PopperProps={{ className: classNames('praxie-tooltip', className), ...PopperProps }}
      enterDelay={1000}
      {...props}
    >
      {content}
    </MUITooltip>
  )
}
