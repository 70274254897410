export enum EWorkflowBlockTypes {
  TRIGGER = 'TriggerComponent',
  FINISH = 'FinishComponent',
  GET_BOARD = 'GetBoardComponent',
  GET_CARD = 'GetCardComponent',
  GET_WIDGET = 'GetWidgetComponent',
  GET_WIDGET_DATA = 'GetWidgetDataComponent',
  GET_COLUMN = 'GetColumnComponent',
  MOVE_COLUMN = 'MoveColumnComponent',
  FIND_BOARD = 'FindBoardComponent',
  FIND_WIDGET = 'FindWidgetComponent',
  FIND_CARD = 'FindCardComponent',
  HIDE_WIDGET = 'HideWidgetComponent',
  HIDE_CARD_SECTION = 'HideCardSectionComponent',
  HIDE_CARD_ACTION = 'HideCardActionComponent',
  HIDE_WIDGETS_BUNDLE_COMPONENT = 'HideWidgetsBundleComponent',
  DEFINE_STRING_FROM_BOARD = 'DefineStringFromBoardComponent',
  DEFINE_STRING_FROM_CARD = 'DefineStringFromCardComponent',
  DEFINE_STRING_FROM_WIDGET = 'DefineStringFromWidgetComponent',
  DEFINE_FILE_FROM_WIDGET = 'DefineFileFromWidgetComponent',
  DEFINE_STRING_FROM_COLUMN = 'DefineStringFromColumnComponent',
  DEFINE_STRING_FROM_JSON_PAYLOAD = 'DefineStringFromJSONComponent',
  DEFINE_STRING_FROM_USER = 'DefineStringFromUserComponent',
  MOVE_CARD = 'MoveCardComponent',
  MOVE_CARD_TO_BOARD = 'MoveCardToBoardComponent',
  COPY_CARD = 'CopyCardComponent',
  COPY_WIDGETS_BUNDLE = 'CopyWidgetsBundleComponent',
  COPY_BOARD = 'CopyBoardComponent',
  RENAME_BOARD = 'RenameBoardComponent',
  RENAME_CARD = 'RenameCardComponent',
  SET_CARD_OWNER = 'SetCardOwnerComponent',
  DELETE_CARD = 'DeleteCardComponent',
  DELETE_CARD_BUNDLE = 'DeleteCardsBundleComponent',
  DELETE_WIDGET = 'DeleteWidgetComponent',
  DELETE_WIDGETS_BUNDLE = 'DeleteWidgetsBundleComponent',
  DELETE_COLUMN = 'DeleteColumnComponent',
  FIND_WIDGETS_BUNDLE = 'FindWidgetsBundleComponent',
  SWITCH_STAGE_WIDGET = 'SwitchStageWidgetComponent',
  ADD_CARD_COMMENT = 'AddCardCommentComponent',
  GET_USER = 'GetUserComponent',
  FIND_COLUMN = 'FindColumnComponent',
  CREATE_COLUMN = 'CreateColumnComponent',
  FIND_USER = 'FindUserComponent',
  LOCK_WIDGET = 'LockWidgetComponent',
  LOCK_WIDGETS_BUNDLE_COMPONENT = 'LockWidgetsBundleComponent',
  HIDE_COLUMN = 'HideColumnComponent',
  RENAME_COLUMN = 'RenameColumnComponent',
  SEND_EMAIL = 'SendEmailComponent',
  COPY_COLUMN = 'CopyColumnComponent',
  PUBLISH_FEED_NOTIFICATION = 'PublishFeedNotificationComponent',
  SELECT_WIDGETS_FROM_FILTER = 'SelectWidgetsFromFilterComponent',
  FIND_CARD_BUNDLE_FROM_FILTER = 'FindCardsBundleComponent',
  UPDATE_WIDGET_DATA = 'UpdateWidgetDataComponent',
  CONVERT_WIDGET_DATA = 'ConvertWidgetDataComponent',
  CONVERT_WIDGET_DATA_INTO_FILE = 'ConvertWidgetDataIntoFileComponent',
  SEND_PROMPT_TO_AI = 'SendPromptComponent',
  DEFINE_JSON_WITH_AI = 'DefineJSONWithAIComponent',
  GENERATE_FILE_WITH_AI = 'GenerateFileWithAIComponent',
  REST_API_CLIENT = 'RestApiClientComponent',
  UPDATE_WIDGET_WITH_FILE = 'UpdateWidgetWithFileComponent'
}
