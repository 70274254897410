import messages from 'constants/messages'
import { OBJECT_TYPES } from 'constants/workflows'

export const CONVERT_DATA_INPUT_COUNT = 20

export enum EOutputFormatType {
  text = 'text',
  date = 'date',
  unformatted = 'unformatted',
  standard = 'standard',
  percent = 'percent',
  usd = 'usd',
  euro = 'euro',
  gbp = 'gbp'
}

export const outputDataTypeOptions = [
  {
    label: messages.STRING_DATA_TYPE,
    value: OBJECT_TYPES.STRING
  },
  {
    label: messages.DEFINE_STRING_OPTION_WIDGET_DATA,
    value: OBJECT_TYPES.WIDGET_DATA
  }
]

export const formatTypeOptions = [
  {
    label: messages.TEXT,
    value: EOutputFormatType.text
  },
  {
    label: messages.DATE,
    value: EOutputFormatType.date
  },
  {
    label: messages.UNFORMATTED,
    value: EOutputFormatType.unformatted
  },
  {
    label: messages.STANDARD,
    value: EOutputFormatType.standard
  },
  {
    label: messages.PERCENT,
    value: EOutputFormatType.percent
  },
  {
    label: messages.USD,
    value: EOutputFormatType.usd
  },
  {
    label: messages.EURO,
    value: EOutputFormatType.euro
  },
  {
    label: messages.GBP,
    value: EOutputFormatType.gbp
  }
]
