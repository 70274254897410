import { navigateToLoginPage } from '../../helpers/routesHelpers'
import {
  GRAPHQL_ERROR_CODES,
  GRAPHQL_REST_ERROR_CODES_MAP
} from '../constants/errorHandlerConstants'

const transformToRestError = error => {
  const { errorCode } = error
  if (errorCode) {
    const restErrorCode = GRAPHQL_REST_ERROR_CODES_MAP[errorCode] || errorCode
    return {
      ...error,
      errorCode: restErrorCode
    }
  }
  return error
}

export const GRAPHQL_UNEXPECTED_RESPONSE_TEXT = 'Unexpected response'
export class GraphQlError {
  constructor({ message = '', errorCode = '' }) {
    this.message = message
    this.errorCode = errorCode
  }
}

const processBatchErrors = errors => {
  let result = {}
  const firstError = errors[0]
  result = {
    message: firstError.message,
    errorCode: firstError.extensions.code
  }
  return result
}

const parseNetworkErrors = networkError => {
  let result = {}
  try {
    const { errors } = networkError.result
    result = processBatchErrors(errors)
  } catch (err) {
    result = {
      message: networkError.toString(),
      errorCode: networkError.statusCode
    }
  }
  return result
}

export const parseGraphQLErrors = graphQLErrors => {
  let result = {}

  const shouldAuth = graphQLErrors.some(error => {
    if (error.extensions.code === GRAPHQL_ERROR_CODES.UNAUTHENTICATED) {
      result = {
        message: error.message,
        errorCode: error.extensions.code
      }
      return true
    }
    return false
  })

  if (!shouldAuth) {
    result = processBatchErrors(graphQLErrors)
  }
  return result
}

export const handleError = response => {
  const { networkError, graphQLErrors, message } = response
  let result = {}

  if (networkError && networkError) {
    result = parseNetworkErrors(networkError)
  } else if (graphQLErrors && graphQLErrors.length) {
    result = parseGraphQLErrors(graphQLErrors)
  } else {
    result = { message }
  }

  if (result.errorCode === GRAPHQL_ERROR_CODES.UNAUTHENTICATED) {
    navigateToLoginPage()
  }

  return transformToRestError(result)
}
