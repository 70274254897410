import {
  navigateToLoginPage,
  navigateToServerErrorState,
  navigateToLoginPageAfterSessionExpires
} from './routesHelpers'

const backendUrl = import.meta.env.VITE_BACKEND_URL

export const url = `${backendUrl}api/`
const UNAUTHORIZED_ERROR_MESSAGE = 'UNAUTHORIZED'

export const FetchCache = new Map()

export function serializeURLParams(data) {
  let params = ''
  return Object.keys(data)
    .map(k => {
      params = `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`
      return params
    })
    .join('&')
}

export const invalidateFetchCacheItem = _url => {
  FetchCache.delete(`${url}${_url}`)
}

export function requestWrapper(options) {
  const {
    rUrl,
    type,
    payload,
    contentType,
    authorization,
    positionsETag,
    withCanceler,
    withCredentials = true,
    progressDataCallback,
    hasServerErrorHandling = false,
    cache = false
  } = options

  let canceler
  let urlParams = ''

  if (type === 'GET' && payload && Object.keys(payload).length) {
    urlParams = '?'
    urlParams += serializeURLParams(payload)
  }

  const requestUrl = `${rUrl}${urlParams}`

  if (cache && FetchCache.has(requestUrl)) return Promise.resolve(FetchCache.get(requestUrl))

  const promise = new Promise((resolve, reject) => {
    const request = new XMLHttpRequest()

    request.open(type, requestUrl)
    if (contentType !== 'multipart/form-data') {
      request.setRequestHeader('Content-Type', 'application/json')
    }
    if (authorization) {
      request.setRequestHeader('Authorization', authorization)
    }
    if (positionsETag) {
      request.setRequestHeader('x-board-cards-positions-etag', positionsETag)
    }
    request.onload = () => {
      let result

      if (hasServerErrorHandling && request.status >= 500) {
        navigateToServerErrorState()
        result = { message: request.responseText, errorCode: request.status }
        reject(result)
        return
      }

      if (request.status >= 200 && request.status < 400) {
        if (request.responseText) {
          const response = JSON.parse(request.responseText)

          let cardsPositionsETag = null

          const headers = request.getAllResponseHeaders()

          // Check for the 'x-board-cards-positions-etag' header is present in the response headers.
          if (headers.includes('x-board-cards-positions-etag')) {
            cardsPositionsETag = request.getResponseHeader('x-board-cards-positions-etag')
          }

          const resolvedResponse = { data: response, cardsPositionsETag }

          if (cache) {
            FetchCache.set(requestUrl, resolvedResponse)
          }

          resolve(resolvedResponse)
        } else {
          resolve({})
        }
      } else {
        try {
          result = JSON.parse(request.responseText)
          result.errorCode = request.status
          const isLoggedUser = result.errorCode !== 401
          if (!isLoggedUser) {
            // https://leverxeu.atlassian.net/browse/EUPBOARD01-13710
            // When the user token has expired,BE returns an "Unauthorized" error message, in which case we need to show a notification
            const errorMessage = result.message
            const isSessionExpired = errorMessage
              ?.toUpperCase()
              .includes(UNAUTHORIZED_ERROR_MESSAGE)

            if (isSessionExpired) {
              navigateToLoginPageAfterSessionExpires()
            } else {
              navigateToLoginPage()
            }
          }
        } catch (e) {
          console.error(e)
          result = { message: request.responseText, errorCode: request.status }
        }
        if (reject) {
          reject(result)
        } else {
          throw new Error(result.message)
        }
      }
    }
    if (progressDataCallback) {
      request.upload.onprogress = event => {
        if (event.lengthComputable) {
          progressDataCallback(Math.floor((event.loaded / event.total) * 100))
        }
      }
    }
    if (withCanceler) {
      canceler = () => {
        request.abort()
      }
    }
    request.onerror = err => {
      if (hasServerErrorHandling) {
        navigateToServerErrorState()
      }

      if (reject) {
        reject(err)
      } else {
        throw new Error(err)
      }
    }
    request.withCredentials = withCredentials
    if (payload && contentType === 'multipart/form-data') {
      request.send(payload)
    } else if (payload && type !== 'GET') {
      request.send(JSON.stringify(payload))
    } else {
      request.send()
    }
  })

  if (withCanceler) {
    return { promise, canceler }
  }
  return promise
}

export const throttleRequest = (request, cancelers, cancelerProp, delay = 400) => {
  const canceler = cancelers[cancelerProp]
  if (canceler) {
    canceler()
  }
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => resolve(), delay)
    cancelers[cancelerProp] = () => {
      clearTimeout(timeout)
      reject(Error('cancelled'))
    }
  })
    .then(() => request())
    .then(response => {
      cancelers[cancelerProp] = null
      return response
    })
}

export const makeCancelable = promise => {
  let hasCanceled = false

  const wrappedPromise = new Promise((resolve, reject) =>
    // eslint-disable-next-line no-promise-executor-return
    promise.then(
      result => (hasCanceled ? reject(new Error('isCanceled')) : resolve(result)),
      error => (hasCanceled ? reject(new Error('isCanceled')) : reject(error))
    )
  )

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true
    }
  }
}

export const automaticallyDownloadFile = (link, content) => {
  const element = document.createElement('a')
  element.setAttribute('href', link)
  element.setAttribute('download', content)
  document.body.appendChild(element)
  element.click()
  if (element.parentNode) {
    element.parentNode.removeChild(element)
  }
}

export const addTimestampToUrl = (path, useIstioHost = false) => {
  if (!path) {
    return path
  }

  const rootURL = useIstioHost ? window.istioHost : backendUrl
  const rUrl = new URL(path, rootURL)
  rUrl.searchParams.set('timestamp', Date.now())

  return rUrl.toString()
}

export const getSnapshotUrl = snapshot => {
  const _url = snapshot.startsWith('api') ? window.istioHost : ''
  return snapshot.includes('uploader') ? `${_url}${snapshot}` : `${backendUrl}${snapshot}`
}

export const getUrlParam = (search, param) => {
  if (!window.URLSearchParams) {
    console.error("URLSearchParams doesn't supported by your browser")
    return null
  }

  const searchParams = new URLSearchParams(search)

  return searchParams.get(param)
}
