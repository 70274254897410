import { APP_BUILDER_TAB } from 'constants/appBuilder/appBuilderConstants'

const appBuilder = {
  app: {},
  appErrors: {},
  // set newLogo to save new app logo
  newLogo: null,
  // set clearLogo to remove app logo
  clearLogo: false,
  appBoards: [],
  appCards: {},
  appCardsLoading: {},

  isSnapshotModalShown: false,
  isAppBuilderLoading: false,
  isAppSaving: false,
  hasChanges: false,
  isAppCreationMode: false,
  isAppBuilderConfirmationShown: false,

  navItems: [],
  navItemConfigurationModalData: {
    state: false,
    isEdit: false,
    navItems: null,
    group: null
  },

  tab: APP_BUILDER_TAB.content,
  appMeta: {},
  appMetaErrors: {},
  appMetaConfigs: {},
  isAppPublishing: false,
  isAppUnpublishing: false,

  appPlacementModalData: {
    state: false
  },
  appTemplateDownloadProgress: []
}

export default appBuilder
