import classNames from 'classnames'
import type { SelectChangeEvent } from '@mui/material/Select'
import { Select } from 'components/common/select/select'
import { Tooltip } from 'components/common/tooltip/tooltip'
import HintIcon from 'assets/images/icons/ic_hint_c4cfd7.svg?react'
import { useDropdownContentSetupStore } from '../dropdownContentSetup.store'
import { EDropdownOptionsSource } from '../dropdownContentSetup.types'
import { DROPDOWN_OPTIONS_SOURCES_LIST } from '../dropdownContentSetup.constants'
import './dropdown-source-selector.scss'

const TooltipContent = () => (
  <ul className="tooltip-content">
    <li>
      <span className="source-title">Manual</span> - set all dropdown options manually{' '}
    </li>
    <li>
      <span className="source-title">List</span> - select list from Team settings
    </li>
    <li>
      <span className="source-title">Query</span> - use query as source of dropdown options
    </li>
  </ul>
)

type TProps = {
  isDisabled: boolean
}

export const DropdownSourceSelector = ({ isDisabled }: TProps) => {
  const optionsSource = useDropdownContentSetupStore(state => state.optionsSource)

  const handleSourceChange = (event: SelectChangeEvent<unknown>) => {
    const selectedDataSource = event.target.value as EDropdownOptionsSource

    useDropdownContentSetupStore.setState(state => ({
      optionsSource: selectedDataSource,
      selectedDictionaryId:
        selectedDataSource !== EDropdownOptionsSource.list ? null : state.selectedDictionaryId,
      selectedQueryId:
        selectedDataSource !== EDropdownOptionsSource.query ? null : state.selectedQueryId
    }))
  }

  return (
    <div className={classNames('dropdown-source-selector', { disabled: isDisabled })}>
      <Select
        className="source-select"
        value={optionsSource}
        size={40}
        options={DROPDOWN_OPTIONS_SOURCES_LIST}
        label={
          <div className="dropdown-source-selector-label">
            Select dropdown data source
            <Tooltip
              id="source-selector-hint-tooltip"
              className="source-selector-hint-tooltip"
              title={<TooltipContent />}
              placement="top"
              wrapperClassName="source-selector-hint"
              shouldWrapChildrenWithDiv
            >
              <HintIcon />
            </Tooltip>
          </div>
        }
        MenuProps={{ className: 'dropdown-source-selector-menu' }}
        onChange={handleSourceChange}
      />
    </div>
  )
}
