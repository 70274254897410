import messages from '../../constants/messages'
import { EFieldKeys } from '../../constants/workflowBuilder/blocksFieldsKeys'

export const STRING_INPUT = 'stringInput'
export const PLAIN_STRING = 'plainString'
export const COMBINED_STRING = 'combinedString'

export const RENAMING_OPTIONS = {
  [STRING_INPUT]: { value: STRING_INPUT, label: messages.USE_STRING_OUTPUT },
  [COMBINED_STRING]: { value: COMBINED_STRING, label: messages.USE_STRING_OUTPUT_CUSTOM_TEXT },
  [PLAIN_STRING]: { value: PLAIN_STRING, label: messages.ENTER_CUSTOM_TEXT }
}

export const RENAMING_OPTIONS_LIST = Object.values(RENAMING_OPTIONS)

export const AFTER_STRING = 'afterString'
export const BEFORE_STRING = 'beforeString'

export const CUSTOM_TEXT_POSITION = {
  [AFTER_STRING]: { value: AFTER_STRING, label: messages.AFTER_STRING },
  [BEFORE_STRING]: { value: BEFORE_STRING, label: messages.BEFORE_STRING }
}

export const CUSTOM_TEXT_POSITION_OPTIONS = Object.values(CUSTOM_TEXT_POSITION)

export const validateRenameBlock = ({ block }) => {
  const error = {}
  if (!block.input || !block.input[EFieldKeys.SOURCE_INPUT]) {
    error[EFieldKeys.SOURCE_INPUT] = messages.emptyField
    return { error }
  }

  if (
    !block.meta ||
    (block.meta[EFieldKeys.TYPE] === STRING_INPUT && !block.input[EFieldKeys.STRING_INPUT])
  ) {
    error[EFieldKeys.STRING_INPUT] = messages.emptyField
    return { error }
  }

  if (block.meta[EFieldKeys.TYPE] === PLAIN_STRING && !block.meta[EFieldKeys.NAME]) {
    error[EFieldKeys.NAME] = messages.emptyField
    return { error }
  }

  if (block.meta[EFieldKeys.TYPE] === COMBINED_STRING) {
    if (!block.input[EFieldKeys.STRING_INPUT]) {
      error[EFieldKeys.STRING_INPUT] = messages.emptyField
      return { error }
    }
    if (!block.meta[EFieldKeys.NAME]) {
      error[EFieldKeys.NAME] = messages.emptyField
      return { error }
    }
  }

  return { error }
}
