import React, { Component } from 'react'
import classNames from 'classnames'
import ArrowIcon from 'assets/images/icons/ic_arrow_bottom_394549.svg?react'
import messages from 'constants/messages'
import splitArrayByType from 'helpers/splitArrayByType'
import { Button } from '@praxie/shared'
import { Menu } from 'components/common/menu/menu'
import ConnectionsListGroup from 'components/filters/connections/ConnectionsListGroup'
import 'scss/filters/connections/connections-dropdown-container.scss'

const Toggle = ({ number, isOpen, isLoading, onToggle }) => (
  <Button
    className={classNames('connections-button-wrapper', { active: isOpen })}
    appearance="secondary"
    size="small"
    busy={isLoading}
    onClick={onToggle}
  >
    <span className="connections-text">{messages.CONNECTIONS}:</span>
    <span className={classNames('connections-number', { 'no-connections': !number })}>
      {number}
    </span>
    <ArrowIcon className="connections-icon" />
  </Button>
)

class ConnectionsDropdownContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false
    }

    this.toggle = this.toggle.bind(this)
    this.menuAnchor = React.createRef()
  }

  toggle(state) {
    this.setState({ isOpen: state })
  }

  render() {
    const { isOpen } = this.state
    const { tenantId, connections } = this.props

    const { workflow, widget } = splitArrayByType(connections, 'connectionType')

    return (
      <div ref={this.menuAnchor} id="connections-dropdown" className="connections-dropdown">
        <Toggle
          isOpen={isOpen}
          number={connections.length}
          isLoading={this.props.isLoading}
          onToggle={this.toggle.bind(this, true)}
        />
        <Menu
          id="connections-dropdown-menu"
          className={classNames('connections-dropdown-menu', {
            'no-connections': !connections.length > 0
          })}
          open={isOpen}
          onClose={this.toggle.bind(this, false)}
          anchorEl={this.menuAnchor.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <ConnectionsListGroup>
            <ConnectionsListGroup.ConnectionsEmptyList isShown={!connections.length > 0} />
            <ConnectionsListGroup.ConnectionsListWrapper
              tenantId={tenantId}
              workflowList={workflow}
              widgetList={widget}
            />
          </ConnectionsListGroup>
        </Menu>
      </div>
    )
  }
}

export default ConnectionsDropdownContainer
