import PropTypes from 'prop-types'
import classNames from 'classnames'
import UserLogo from './UserLogo'
import 'scss/common/user-representation.scss'

const UserRepresentation = ({
  isOrganizationPlaceholder,
  showTitles,
  size,
  className,
  email,
  username,
  userRole,
  avatarImage
}) => {
  return (
    <div className={classNames('user-representation', size, className)}>
      <UserLogo
        avatarImage={avatarImage}
        isOrganizationPlaceholder={isOrganizationPlaceholder}
        username={username || '-'}
        size={size}
      />
      <div className="user-data-wrapper">
        <p className="name bold" title={showTitles ? username : ''}>
          {username ? username : 'Removed user'}
        </p>
        {userRole && (
          <p className="role bold" title={showTitles ? userRole : ''}>
            {userRole}
          </p>
        )}
        {email && (
          <p className="email" title={showTitles ? email : ''}>
            {email}
          </p>
        )}
      </div>
    </div>
  )
}

UserRepresentation.defaultProps = {
  isOrganizationPlaceholder: false,
  showTitles: false,
  size: '',
  className: '',
  email: '',
  username: '',
  userRole: '',
  avatarImage: ''
}

UserRepresentation.propTypes = {
  isOrganizationPlaceholder: PropTypes.bool,
  showTitles: PropTypes.bool,
  size: PropTypes.oneOf([
    '',
    'small',
    'extra-small',
    'extra-small-24px',
    'extra-small-20px',
    'big',
    'extra-big'
  ]),
  className: PropTypes.string,
  email: PropTypes.string,
  username: PropTypes.string,
  userRole: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  avatarImage: PropTypes.string
}

export default UserRepresentation
