const CARD_WIDTH = 1145

// check whether data providers modal should be opened on the right or left side
export const isPullRight = widgetData => {
  const PROVIDER_LIST_WIDTH = 310

  return widgetData.positionX + widgetData.width > CARD_WIDTH - PROVIDER_LIST_WIDTH
}

export const getOverlayPosition = widgetData => ({
  top: widgetData.positionY,
  left: widgetData.positionX + widgetData.width
})

// show providers list tool inside of the widget in case there is not enough space on the right
export const isInside = widgetData => {
  const LINKING_ICON_WIDTH = 48

  if (widgetData.positionX + widgetData.width > CARD_WIDTH - LINKING_ICON_WIDTH) {
    return 'inside'
  }

  return ''
}
