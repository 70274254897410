export class _ShimsService {
  /*
   * @see https://developers.google.com/web/updates/2015/08/using-requestidlecallback
   */
  shimRequestIdleCallback() {
    if (!('requestIdleCallback' in window)) {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/ban-types
      window.requestIdleCallback = (callback: Function) => {
        const start = Date.now()

        return setTimeout(() => {
          callback({
            didTimeout: false,
            timeRemaining: () => Math.max(0, 50 - (Date.now() - start))
          })
        }, 1)
      }
    }

    if (!('cancelIdleCallback' in window)) {
      // @ts-ignore
      window.cancelIdleCallback = (id: number) => clearTimeout(id)
    }
  }
}

export const ShimsService = new _ShimsService()
