export default class EventManager {
  list = new Map()

  subscribe(event, callback) {
    if (!this.list.has(event)) {
      this.list.set(event, [callback])
    } else {
      this.list.get(event).push(callback)
    }

    return () => this._removeListener(event, callback)
  }

  unsubscribe(event, callback) {
    if (callback) {
      this._removeListener(event, callback)
    } else {
      this.list.delete(event)
    }
  }

  emit(event, args) {
    if (this.list.has(event)) this.list.get(event).forEach(callback => callback(args))
  }

  _removeListener(event, callback) {
    const listeners = this.list.get(event).filter(listener => listener !== callback)
    this.list.set(event, listeners)
  }
}
