const teamAdministration = {
  // pay attention that this object relevant ONLY on team settings page
  currentTeam: {
    permissions: {}
  },

  deactivateTenantMemberData: { state: false, member: {}, tenant: {}, onConfirm: null },
  invitationModalState: {},
  isInvitationRequested: false,
  isInvitationModalShown: false,

  isChangePasswordRequested: false,
  changePasswordErrorMessage: '',
  isTerminateUserModalShown: false,
  terminateUserErrorMessage: '',
  isDeleteTeamMemberModalShown: false,
  deleteTeamMemberErrorMessage: '',
  isDeleteTeamModalShown: false,
  deleteTeamErrorMessage: '',
  isDeleteTeamMemberRejectionModalShown: false,
  isDeleteOrganizationModalShown: false,
  deleteOrganizationErrorMessage: ''
}

export default teamAdministration
