import { useMemo } from 'react'
import { UpdateCommandRequest } from '_proto/dictionary/v1/dictionary.pb'
import type { TDictionaryModalData } from '../../dictionariesSettings.types'
import { DictionariesGrpcService } from '../../dictionaries.grpc.service'
import { DictionaryModal } from '../../dictionaryModal/dictionaryModal'
import { useDropdownContentSetupStore } from '../dropdownContentSetup.store'

type TProps = {
  tenantId: string
}

export const EditDictionaryModal = ({ tenantId }: TProps) => {
  const dictionaries = useDropdownContentSetupStore(state => state.dictionaries)
  const selectedDictionaryId = useDropdownContentSetupStore(state => state.selectedDictionaryId)
  const isDictionaryEditModalOpen = useDropdownContentSetupStore(
    state => state.isDictionaryEditModalOpen
  )
  const setIsDictionaryEditModalOpen = useDropdownContentSetupStore(
    state => state.setIsDictionaryEditModalOpen
  )
  const updateDictionary = useDropdownContentSetupStore(state => state.updateDictionary)

  const selectedDictionary = useMemo(() => {
    if (!isDictionaryEditModalOpen) return undefined

    const _selectedDictionary = dictionaries.find(
      dictionary => dictionary.value === selectedDictionaryId
    )

    if (!_selectedDictionary) return undefined

    return {
      id: _selectedDictionary.value,
      name: _selectedDictionary.label,
      options: _selectedDictionary.options,
      hint: _selectedDictionary.hint,
      queryWidget: _selectedDictionary.queryWidget
    }
  }, [isDictionaryEditModalOpen, selectedDictionaryId, dictionaries])

  if (!isDictionaryEditModalOpen) return null

  const handleSave = async (dictionaryData: Omit<TDictionaryModalData, 'id'>) => {
    const request = UpdateCommandRequest.create({
      tenantId,
      dictionaryId: selectedDictionaryId!,
      name: dictionaryData.name,
      options: dictionaryData.options.map(option => ({ name: option.value.toString() }))
    })

    if (dictionaryData.hint?.flag) request.hint = dictionaryData.hint
    if (dictionaryData.queryWidget?.flag) request.queryWidget = dictionaryData.queryWidget

    await DictionariesGrpcService.updateDictionary({ request })

    updateDictionary({
      value: selectedDictionaryId!,
      label: dictionaryData.name,
      options: dictionaryData.options,
      hint: dictionaryData.hint,
      queryWidget: dictionaryData.queryWidget
    })
  }

  return (
    <DictionaryModal
      isOpen={isDictionaryEditModalOpen}
      dictionaryData={selectedDictionary}
      onClose={() => setIsDictionaryEditModalOpen(false)}
      onSave={handleSave}
    />
  )
}
