import messages from '../../constants/messages'
import { EFieldKeys } from '../../constants/workflowBuilder/blocksFieldsKeys'
import { STRING_INPUT_EQUAL, INPUT_FIELD_EQUAL } from './switchWidgetConfig'

export const STAGE_TRACKER_WIDGET = 'StageTrackerWidget'

export const validateSwitchWidget = ({ block, outputs, workflowBlocksMap }) => {
  const error = {}
  const warn = {}

  if (!block.input[EFieldKeys.SOURCE_INPUT]) {
    error[EFieldKeys.SOURCE_INPUT] = messages.emptyField
    return { error }
  }

  if (
    !block.meta ||
    (block.meta[EFieldKeys.TYPE] === STRING_INPUT_EQUAL && !block.input[EFieldKeys.STRING_INPUT])
  ) {
    error[EFieldKeys.STRING_INPUT] = messages.emptyField
    return { error }
  }

  if (
    !block.meta ||
    (block.meta[EFieldKeys.TYPE] === INPUT_FIELD_EQUAL && !block.meta[EFieldKeys.NAME])
  ) {
    error[EFieldKeys.NAME] = messages.emptyField
    return { error }
  }

  const output = outputs.widget.find(item => item.id === block.input[EFieldKeys.SOURCE_INPUT])

  const outputEntity = workflowBlocksMap[output.blockId].meta[EFieldKeys.ENTITY_INPUT]
  if (!outputEntity) {
    warn[EFieldKeys.SOURCE_INPUT] = messages.UNDEFINED_WIDGET_TYPE_WARN

    return { warn }
  }
  const widgetClassName = outputEntity && outputEntity.widgetClassName

  if (block.meta && widgetClassName !== STAGE_TRACKER_WIDGET) {
    error[EFieldKeys.SOURCE_INPUT] = messages.INCORRECT_WIDGET_TYPE
    return { error }
  }

  return { error }
}
