export const pdfFontsConstants = `
/* fontName: Alex Brush */
@font-face {
  font-family: "Alex Brush";
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/AlexBrush/AlexBrush-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Alex Brush";
  font-style: italic;
  font-weight: normal;
  src: url("/fonts/AlexBrush/AlexBrush-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Alex Brush";
  font-style: normal;
  font-weight: bold;
  src: url("/fonts/AlexBrush/AlexBrush-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Alex Brush";
  font-style: italic;
  font-weight: bold;
  src: url("/fonts/AlexBrush/AlexBrush-Regular.ttf") format("truetype");
}
/* fontName: Allura */
@font-face {
  font-family: "Allura";
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/Allura/Allura-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Allura";
  font-style: italic;
  font-weight: normal;
  src: url("/fonts/Allura/Allura-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Allura";
  font-style: normal;
  font-weight: bold;
  src: url("/fonts/Allura/Allura-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Allura";
  font-style: italic;
  font-weight: bold;
  src: url("/fonts/Allura/Allura-Regular.ttf") format("truetype");
}
/* fontName: Amatic */
@font-face {
  font-family: "Amatic";
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/Amatic/Amatic-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Amatic";
  font-style: italic;
  font-weight: normal;
  src: url("/fonts/Amatic/Amatic-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Amatic";
  font-style: normal;
  font-weight: bold;
  src: url("/fonts/Amatic/Amatic-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Amatic";
  font-style: italic;
  font-weight: bold;
  src: url("/fonts/Amatic/Amatic-Bold.ttf") format("truetype");
}
/* fontName: Chunk Five */
@font-face {
  font-family: "Chunk Five";
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/ChunkFive/ChunkFive-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Chunk Five";
  font-style: italic;
  font-weight: normal;
  src: url("/fonts/ChunkFive/ChunkFive-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Chunk Five";
  font-style: normal;
  font-weight: bold;
  src: url("/fonts/ChunkFive/ChunkFive-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Chunk Five";
  font-style: italic;
  font-weight: bold;
  src: url("/fonts/ChunkFive/ChunkFive-Regular.ttf") format("truetype");
}
/* fontName: Cicle */
@font-face {
  font-family: "Cicle";
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/Cicle/Cicle-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Cicle";
  font-style: italic;
  font-weight: normal;
  src: url("/fonts/Cicle/Cicle-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Cicle";
  font-style: normal;
  font-weight: bold;
  src: url("/fonts/Cicle/Cicle-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Cicle";
  font-style: italic;
  font-weight: bold;
  src: url("/fonts/Cicle/Cicle-ItalicBold.ttf") format("truetype");
}
/* fontName: Colaborate */
@font-face {
  font-family: "Colaborate";
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/Colaborate/Colaborate-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Colaborate";
  font-style: italic;
  font-weight: normal;
  src: url("/fonts/Colaborate/Colaborate-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Colaborate";
  font-style: normal;
  font-weight: bold;
  src: url("/fonts/Colaborate/Colaborate-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Colaborate";
  font-style: italic;
  font-weight: bold;
  src: url("/fonts/Colaborate/Colaborate-Bold.ttf") format("truetype");
}
/* fontName: Comfortaa */
@font-face {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/Comfortaa/Comfortaa-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Comfortaa";
  font-style: italic;
  font-weight: normal;
  src: url("/fonts/Comfortaa/Comfortaa-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: bold;
  src: url("/fonts/Comfortaa/Comfortaa-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Comfortaa";
  font-style: italic;
  font-weight: bold;
  src: url("/fonts/Comfortaa/Comfortaa-Bold.ttf") format("truetype");
}
/* fontName: Concert One */
@font-face {
  font-family: "Concert One";
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/ConcertOne/ConcertOne-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Concert One";
  font-style: italic;
  font-weight: normal;
  src: url("/fonts/ConcertOne/ConcertOne-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Concert One";
  font-style: normal;
  font-weight: bold;
  src: url("/fonts/ConcertOne/ConcertOne-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Concert One";
  font-style: italic;
  font-weight: bold;
  src: url("/fonts/ConcertOne/ConcertOne-Regular.ttf") format("truetype");
}
/* fontName: Dekar */
@font-face {
  font-family: "Dekar";
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/Dekar/Dekar-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Dekar";
  font-style: italic;
  font-weight: normal;
  src: url("/fonts/Dekar/Dekar-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Dekar";
  font-style: normal;
  font-weight: bold;
  src: url("/fonts/Dekar/Dekar-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Dekar";
  font-style: italic;
  font-weight: bold;
  src: url("/fonts/Dekar/Dekar-Regular.ttf") format("truetype");
}
/* fontName: Exo */
@font-face {
  font-family: "Exo";
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/Exo/Exo-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Exo";
  font-style: italic;
  font-weight: normal;
  src: url("/fonts/Exo/Exo-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Exo";
  font-style: normal;
  font-weight: bold;
  src: url("/fonts/Exo/Exo-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Exo";
  font-style: italic;
  font-weight: bold;
  src: url("/fonts/Exo/Exo-ItalicBold.ttf") format("truetype");
}
/* fontName: Fira Sans */
@font-face {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/FiraSans/FiraSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Fira Sans";
  font-style: italic;
  font-weight: normal;
  src: url("/fonts/FiraSans/FiraSans-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: bold;
  src: url("/fonts/FiraSans/FiraSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Fira Sans";
  font-style: italic;
  font-weight: bold;
  src: url("/fonts/FiraSans/FiraSans-ItalicBold.ttf") format("truetype");
}
/* fontName: Kaushan Script */
@font-face {
  font-family: "Kaushan Script";
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/KaushanScript/KaushanScript-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Kaushan Script";
  font-style: italic;
  font-weight: normal;
  src: url("/fonts/KaushanScript/KaushanScript-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Kaushan Script";
  font-style: normal;
  font-weight: bold;
  src: url("/fonts/KaushanScript/KaushanScript-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Kaushan Script";
  font-style: italic;
  font-weight: bold;
  src: url("/fonts/KaushanScript/KaushanScript-Regular.ttf") format("truetype");
}
/* fontName: Lato */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/Lato/Lato-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: normal;
  src: url("/fonts/Lato/Lato-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  src: url("/fonts/Lato/Lato-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: bold;
  src: url("/fonts/Lato/Lato-ItalicBold.ttf") format("truetype");
}
/* fontName: Lobster */
@font-face {
  font-family: "Lobster";
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/Lobster/Lobster-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Lobster";
  font-style: italic;
  font-weight: normal;
  src: url("/fonts/Lobster/Lobster-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Lobster";
  font-style: normal;
  font-weight: bold;
  src: url("/fonts/Lobster/Lobster-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Lobster";
  font-style: italic;
  font-weight: bold;
  src: url("/fonts/Lobster/Lobster-Regular.ttf") format("truetype");
}
/* fontName: Molot */
@font-face {
  font-family: "Molot";
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/Molot/Molot-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Molot";
  font-style: italic;
  font-weight: normal;
  src: url("/fonts/Molot/Molot-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Molot";
  font-style: normal;
  font-weight: bold;
  src: url("/fonts/Molot/Molot-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Molot";
  font-style: italic;
  font-weight: bold;
  src: url("/fonts/Molot/Molot-Regular.ttf") format("truetype");
}
/* fontName: Mukta */
@font-face {
  font-family: "Mukta";
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/Mukta/Mukta-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Mukta";
  font-style: italic;
  font-weight: normal;
  src: url("/fonts/Mukta/Mukta-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Mukta";
  font-style: normal;
  font-weight: bold;
  src: url("/fonts/Mukta/Mukta-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Mukta";
  font-style: italic;
  font-weight: bold;
  src: url("/fonts/Mukta/Mukta-Bold.ttf") format("truetype");
}
/* fontName: Oleo Script */
@font-face {
  font-family: "Oleo Script";
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/OleoScript/OleoScript-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Oleo Script";
  font-style: italic;
  font-weight: normal;
  src: url("/fonts/OleoScript/OleoScript-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Oleo Script";
  font-style: normal;
  font-weight: bold;
  src: url("/fonts/OleoScript/OleoScript-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Oleo Script";
  font-style: italic;
  font-weight: bold;
  src: url("/fonts/OleoScript/OleoScript-Bold.ttf") format("truetype");
}
/* fontName: Open Sans */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/OpenSans/OpenSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: normal;
  src: url("/fonts/OpenSans/OpenSans-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  src: url("/fonts/OpenSans/OpenSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: bold;
  src: url("/fonts/OpenSans/OpenSans-ItalicBold.ttf") format("truetype");
}
/* fontName: Pacifico */
@font-face {
  font-family: "Pacifico";
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/Pacifico/Pacifico-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Pacifico";
  font-style: italic;
  font-weight: normal;
  src: url("/fonts/Pacifico/Pacifico-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Pacifico";
  font-style: normal;
  font-weight: bold;
  src: url("/fonts/Pacifico/Pacifico-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Pacifico";
  font-style: italic;
  font-weight: bold;
  src: url("/fonts/Pacifico/Pacifico-Regular.ttf") format("truetype");
}
/* fontName: Playfair Display */
@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/PlayfairDisplay/PlayfairDisplay-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Playfair Display";
  font-style: italic;
  font-weight: normal;
  src: url("/fonts/PlayfairDisplay/PlayfairDisplay-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: bold;
  src: url("/fonts/PlayfairDisplay/PlayfairDisplay-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Playfair Display";
  font-style: italic;
  font-weight: bold;
  src: url("/fonts/PlayfairDisplay/PlayfairDisplay-ItalicBold.ttf") format("truetype");
}
/* fontName: PT Sans */
@font-face {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/PTSans/PTSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "PT Sans";
  font-style: italic;
  font-weight: normal;
  src: url("/fonts/PTSans/PTSans-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: bold;
  src: url("/fonts/PTSans/PTSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "PT Sans";
  font-style: italic;
  font-weight: bold;
  src: url("/fonts/PTSans/PTSans-ItalicBold.ttf") format("truetype");
}
/* fontName: Quicksand */
@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/Quicksand/Quicksand-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Quicksand";
  font-style: italic;
  font-weight: normal;
  src: url("/fonts/Quicksand/Quicksand-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: bold;
  src: url("/fonts/Quicksand/Quicksand-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Quicksand";
  font-style: italic;
  font-weight: bold;
  src: url("/fonts/Quicksand/Quicksand-ItalicBold.ttf") format("truetype");
}
/* fontName: Roboto */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: normal;
  src: url("/fonts/Roboto/Roboto-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  src: url("/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: bold;
  src: url("/fonts/Roboto/Roboto-ItalicBold.ttf") format("truetype");
}
/* fontName: Rubik */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/Rubik/Rubik-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: normal;
  src: url("/fonts/Rubik/Rubik-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: bold;
  src: url("/fonts/Rubik/Rubik-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: bold;
  src: url("/fonts/Rubik/Rubik-ItalicBold.ttf") format("truetype");
}
/* fontName: Source Sans Pro */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/SourceSansPro/SourceSansPro-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: normal;
  src: url("/fonts/SourceSansPro/SourceSansPro-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: bold;
  src: url("/fonts/SourceSansPro/SourceSansPro-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: bold;
  src: url("/fonts/SourceSansPro/SourceSansPro-ItalicBold.ttf") format("truetype");
}
`
