import { Button } from '@praxie/shared'
import messages from 'constants/messages'
import { useDropdownContentSetupStore } from '../dropdownContentSetup.store'
import './dropdown-content-linked-overlay.scss'

export const DropdownContentLinkedOverlay = (props: {
  onClose: () => void
  goToLinking: () => void
}) => {
  const setIsDataLinked = useDropdownContentSetupStore(state => state.setIsDataLinked)

  const goToLinking = () => {
    props.goToLinking()
    props.onClose()
  }

  const unlink = () => setIsDataLinked(false)

  return (
    <div className="dropdown-content-linked-overlay">
      <span className="overlay-title">Widget uses linked data from other widgets</span>
      <span className="overlay-description">
        You can UNLINK widget to manually configure widget or GO TO LINKING to view and edit linked
        data
      </span>
      <div className="overlay-actions">
        <Button className="unlink-button" size="medium" appearance="secondary" onClick={unlink}>
          {messages.UNLINK}
        </Button>
        <Button size="medium" appearance="secondary" onClick={goToLinking}>
          {messages.GO_TO_LINKING}
        </Button>
      </div>
    </div>
  )
}
