import { getEntityQuery } from 'graphql/entities'
import graphqlRequestWrapper from 'graphql/common/requestHelpers'
import { requestWrapper, url } from 'helpers/fetchHelpers'

// workflow list
export function processGetWorkflowList(payload) {
  const queryParams = payload.eventType ? `?eventType=${payload.eventType}` : ''

  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/workflow${queryParams}`,
    type: 'GET',
    payload: {}
  })
}

// create workflow
export function processCreateWorkflow(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/workflow`,
    type: 'POST',
    payload: {
      workflow: payload.data.workflow,
      flow: { payload: payload.data.flow }
    }
  })
}

// get workflow parts
export function processGetWorkflow(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/workflow/${payload.workflowId}`,
    type: 'GET',
    payload: {}
  })
}

export function processGetFlow(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/flow/${payload.flowId}`,
    type: 'GET',
    payload: {}
  })
}

// delete workflow
export function processDeleteWorkflow(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/workflow/${payload.id}`,
    type: 'DELETE',
    payload: {}
  })
}

// update workflow parts
export function processUpdateWorkflow(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/workflow/${payload.id}`,
    type: 'PUT',
    payload: payload.data
  })
}

export function processUpdateFlow(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/flow/${payload.id}`,
    type: 'PUT',
    payload: { payload: payload.data }
  })
}

export function processCopyWorkflow(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/workflow/${payload.id}/copy`,
    type: 'POST',
    payload: payload.data
  })
}

// search items in get blocks
export const searchEntities = ({ tenantId, type, payload }) => {
  const api = requestWrapper({
    rUrl: `${url}tenant/${tenantId}/workflow/${type}/search`,
    type: 'GET',
    payload
  })

  return api
}

export const getResourcesData = ({ organizationId, tenantId, payload }) => {
  const query = getEntityQuery({ organizationId, tenantId, payload })
  return graphqlRequestWrapper({ query })
}
