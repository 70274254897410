import { GoogleService } from 'services/common/Google.service'
import { getWidgetSource } from 'features/widgets/widgets.helpers'
import { initializeMSApi } from '../microsoftAuthHelpers'

const backendUrl = import.meta.env.VITE_BACKEND_URL

const resolutionTools = {
  initializeGoogleApi: GoogleService.init,
  initializeMSApi,
  url: backendUrl
}

export const resolveThirdPartyWidget = (
  widgetSource,
  widget,
  targetFormat = 'io.upboard.common.ubf'
) =>
  widgetSource
    .getWidgetData(targetFormat, widget, resolutionTools)
    .then(({ widgetData }) => widgetData)

export const resolveUnresolvedProviders = (widgets, tenantId) =>
  Promise.all(
    widgets.map(data => {
      const { targetFormat, widget } = data

      return getWidgetSource(widget)
        .then(widgetSource => resolveThirdPartyWidget(widgetSource, widget, targetFormat))
        .catch(err => console.error(err))
    })
  )

export const resolveLinking = options => (payload, sideEffect) => {
  const { request, requestComplete, process } = options

  return request(payload).then(response => {
    const { unconvertedProviders } = response.data

    if (sideEffect) {
      sideEffect(response.data)
    }

    if (unconvertedProviders.length) {
      return resolveUnresolvedProviders(unconvertedProviders, payload.tenantId).then(
        resolvedProviders =>
          requestComplete({
            ...payload,
            data: {
              checksum: response.data.checksum,
              state: response.data.state,
              resolvedProviders: resolvedProviders.filter(widget => !!widget)
            }
          })
      )
    }

    return process(response)
  })
}
