import { OBJECT_TYPES } from '../../constants/workflows'
import { getWidgetPayload, getWidgetResponse } from './widget/widgetQueryHelpers'
import { getCardPayload, getCardResponse } from './card/cardQueryHelpers'
import { getBoardPayload, getBoardResponse } from './board/boardQueryHelpers'
import { getTenantPayload, getTenantResponse } from './tenant/tenantQueryHelpers'
import {
  getOrganizationPayload,
  getOrganizationResponse
} from './organization/organizationQueryHelpers'
import { ORGANIZATION_TYPE, TENANT_TYPE } from '../constants/schemaConstants'
import { getUserPayload, getUserResponse } from './user/userQueryHelpers'
import { createEntitiesTree } from './helpers'

const PAYLOAD_GETTERS = {
  [ORGANIZATION_TYPE]: getOrganizationPayload,
  [TENANT_TYPE]: getTenantPayload,
  [OBJECT_TYPES.BOARD]: getBoardPayload,
  [OBJECT_TYPES.CARD]: getCardPayload,
  [OBJECT_TYPES.WIDGET]: getWidgetPayload,
  [OBJECT_TYPES.USER]: getUserPayload
}

const RESPONSE_GETTERS = {
  [ORGANIZATION_TYPE]: getOrganizationResponse,
  [TENANT_TYPE]: getTenantResponse,
  [OBJECT_TYPES.BOARD]: getBoardResponse,
  [OBJECT_TYPES.CARD]: getCardResponse,
  [OBJECT_TYPES.WIDGET]: getWidgetResponse,
  [OBJECT_TYPES.USER]: getUserResponse
}

export const getEntityFromResponse = ({ organizationId, data, entity }) => {
  const { tenantId, boardId, cardUuid, widgetUuid, userId } = entity
  const resource = {
    organizationId,
    tenantId,
    boardId,
    cardUuid,
    widgetUuid,
    userId
  }

  const organization = RESPONSE_GETTERS[ORGANIZATION_TYPE]({ data, organizationId })
  if (organization) {
    if (userId) {
      const user = RESPONSE_GETTERS[OBJECT_TYPES.USER]({ organization, userId })
      if (user) {
        return {
          ...resource,
          ...user,
          exists: true
        }
      }
      return {
        ...entity,
        exists: false
      }
    }
    const tenant = RESPONSE_GETTERS[TENANT_TYPE]({ organization, tenantId })
    if (tenant) {
      if (!boardId) {
        return {
          ...resource,
          exists: true,
          name: tenant.name
        }
      }
      const board = RESPONSE_GETTERS[OBJECT_TYPES.BOARD]({ tenant, boardId })
      if (!board) {
        return { ...entity, exists: false }
      }
      if (!cardUuid) {
        return {
          ...resource,
          exists: true,
          tenantName: tenant.name,
          name: board.name
        }
      }
      const card = RESPONSE_GETTERS[OBJECT_TYPES.CARD]({ board, cardUuid })
      if (!card) {
        return { ...entity, exists: false }
      }
      if (!widgetUuid) {
        return {
          ...resource,
          exists: true,
          tenantName: tenant.name,
          boardName: board.name,
          name: card.name
        }
      }
      const widget = RESPONSE_GETTERS[OBJECT_TYPES.WIDGET]({ card, widgetUuid })
      if (!widget) {
        return { ...entity, exists: false }
      }
      return {
        ...resource,
        exists: true,
        tenantName: tenant.name,
        boardName: board.name,
        cardName: card.name,
        widgetClassName: entity.widgetClassName,
        widgetTitle: widget.widgetTitle
      }
    }
  }
  return { ...entity, exists: false }
}

const getEntitiesQuery = object => {
  let query = ''
  if (!object) {
    return ''
  }
  const { id, children, type } = object
  query += PAYLOAD_GETTERS[type]({ id })
  if (!children || !Object.keys(children).length) {
    query += '}'
    return query
  }
  query += ','
  Object.values(children).forEach(child => {
    query += getEntitiesQuery(child)
  })
  query += '}'
  return query
}

export const getEntityQuery = ({ organizationId, tenantId, payload }) => {
  const tree = createEntitiesTree({ organizationId, tenantId, payload })
  return `{${getEntitiesQuery(tree)}}`
}
