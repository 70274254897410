import React from 'react'
import messages from 'constants/messages'
import { Tooltip } from 'components/common/tooltip/tooltip'
import 'scss/filters/filterConfiguration/connections-warning.scss'

const ConnectionsWarning = () => (
  <Tooltip
    id="board-name-error-tooltip"
    placement="top"
    title={messages.CHANGES_WILL_BE_APPLIED_TO_ALL_CONNECTIONS}
  >
    <i className="icon icon-warning connections-warning" />
  </Tooltip>
)

export default ConnectionsWarning
