export const getGCD = (a, b) => {
  if (b === 0) {
    return a
  }

  return getGCD(b, a % b)
}

// get element of Farey sequence based on prev and next elements
// numerator/denominator - posN/posD
export const getPosition = ({ prevPos = { posN: 1, posD: 1 }, nextPos = { posN: 0, posD: 1 } }) => {
  const posN = prevPos.posN + nextPos.posN
  const posD = prevPos.posD + nextPos.posD

  const gcd = getGCD(posN, posD)

  return {
    posN: Math.floor(posN / gcd),
    posD: Math.floor(posD / gcd)
  }
}

export const sortByPositions = (a, b) => {
  const aPos = a.posN / a.posD
  const bPos = b.posN / b.posD

  return bPos - aPos
}

const sortByPositionsDEV = (a, b) => {
  const aPos = a.posN / a.posD
  const bPos = b.posN / b.posD

  if (a.posN === b.posN && a.posD === b.posD) {
    console.error('Cards(Columns) positions collision', a, b)
  }

  return bPos - aPos
}

export function getMatrixFromList(list) {
  // to catch positions collision on dev env and send trace on the server
  const sortFunc = import.meta.env.NODE_ENV === 'development' ? sortByPositionsDEV : sortByPositions
  const map = {}

  list.forEach(item => {
    if (item.uuid && item.isCol) {
      map[item.uuid] = {
        immutableIndex: item.uuid,
        header: item,
        cards: []
      }
    }
  })

  list.forEach(item => {
    if (item.uuid && !item.isCol && map[item.columnUuid]) {
      map[item.columnUuid].cards.push(item)
    }
  })

  const matrix = Object.values(map)

  matrix.forEach(column => {
    column.cards.sort(sortFunc)
  })

  matrix.sort((a, b) => sortFunc(a.header, b.header))

  matrix.push({
    cards: []
  })

  return matrix
}
