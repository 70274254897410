export const SOCKET_PREFIX = 'socket/'

export const SUBSCRIBE_EVENT = 'subscribe'
export const UNSUBSCRIBE_EVENT = 'unsubscribe'
export const UNSUBSCRIBE_ALL_EVENT = 'unsubscribeAll'
export const RESUBSCRIBE_EVENT = 'resubscribe'

export const ORGANIZATION_LEVEL = 'ORGANIZATION'
export const TENANT_LEVEL = 'TENANT'
export const BOARD_LEVEL = 'BOARD'
export const CARD_LEVEL = 'CARD'
export const WIDGET_LEVEL = 'WIDGET'

export const CARD_CREATE_SOCKET = `${SOCKET_PREFIX}CARD_CREATE`

export const COLUMN_CREATE = 'COLUMN_CREATE'
export const COLUMN_CREATE_SOCKET = `${SOCKET_PREFIX}COLUMN_CREATE`

export const CARDBUILDER_SOURCE = 'cardBuilder'
export const WORKFLOW_SOURCE = 'workflow'
export const BOARDVIEW_SOURCE = 'boardView'

export const CARD_UPDATE_IN_CARD_BUILDER_SOCKET = `${SOCKET_PREFIX}CARD_UPDATE_IN_CARD_BUILDER_SOCKET`
export const CARD_UPDATE_IN_BOARD_VIEW_SOCKET = `${SOCKET_PREFIX}CARD_UPDATE_IN_BOARD_VIEW_SOCKET`
export const CARD_SNAPSHOT_UPDATE_SOCKET = `${SOCKET_PREFIX}CARD_SNAPSHOT_UPDATE`

export const CARD_REORDER = 'CARD_REORDER'
export const CARD_REORDER_SOCKET = `${SOCKET_PREFIX}CARD_REORDER_SOCKET`

export const CARD_DELETE = 'CARD_DELETE'
export const CARD_DELETE_SOCKET = `${SOCKET_PREFIX}CARD_DELETE`

export const BOARD_UPDATE = 'BOARD_UPDATE'
export const BOARD_UPDATE_SOCKET = `${SOCKET_PREFIX}BOARD_UPDATE`

export const TENANT_UPDATE = 'TENANT_UPDATE'
export const TENANT_UPDATE_SOCKET = `${SOCKET_PREFIX}TENANT_UPDATE`

export const ORGANIZATION_UPDATE = 'ORGANIZATION_UPDATE'
export const ORGANIZATION_UPDATE_SOCKET = `${SOCKET_PREFIX}ORGANIZATION_UPDATE`

export const TOGGLE_BOARD_SUBSCRIPTION = 'TOGGLE_BOARD_SUBSCRIPTION'

export const COMMENT_CREATE = 'COMMENT_CREATE'
export const COMMENT_CREATE_SOCKET = `${SOCKET_PREFIX}COMMENT_CREATE`

export const COMMENT_UPDATE = 'COMMENT_UPDATE'
export const COMMENT_UPDATE_SOCKET = `${SOCKET_PREFIX}COMMENT_UPDATE`

export const COMMENT_DELETE = 'COMMENT_DELETE'
export const COMMENT_DELETE_SOCKET = `${SOCKET_PREFIX}COMMENT_DELETE`

export const TOGGLE_COMMENTS_SUBSCRIPTION = 'TOGGLE_COMMENTS_SUBSCRIPTION'

export const BOARD_START_WATCH = 'BOARD_START_WATCH'
export const BOARD_START_WATCH_SOCKET = `${SOCKET_PREFIX}BOARD_START_WATCH`

export const BOARD_END_WATCH = 'BOARD_END_WATCH'

export const BOARD_WATCHERS_UPDATE = 'BOARD_WATCHERS_UPDATE'
export const BOARD_WATCHERS_UPDATE_SOCKET = `${SOCKET_PREFIX}BOARD_WATCHERS_UPDATE`

export const TOGGLE_BOARD_WATCHING = 'TOGGLE_BOARD_WATCHING'

export const CARD_START_EDIT = 'CARD_START_EDIT'
export const CARD_START_EDIT_SOCKET = `${SOCKET_PREFIX}CARD_START_EDIT`
export const CARD_END_EDIT = 'CARD_END_EDIT'
export const CARD_END_EDIT_SOCKET = `${SOCKET_PREFIX}CARD_END_EDIT`

export const WIDGET_UPDATE_SOCKET = `${SOCKET_PREFIX}WIDGET_UPDATE`
export const BULK_WIDGETS_CREATE_SOCKET = `${SOCKET_PREFIX}BULK_WIDGETS_CREATE`
export const WIDGET_DELETE_SOCKET = `${SOCKET_PREFIX}WIDGET_DELETE`

export const BOARD_LOCKED = 'BOARD_LOCKED'
export const BOARD_LOCKED_SOCKET = `${SOCKET_PREFIX}BOARD_LOCKED`
export const BOARD_UNLOCKED = 'BOARD_UNLOCKED'
export const BOARD_UNLOCKED_SOCKET = `${SOCKET_PREFIX}BOARD_UNLOCKED`
export const JOB_COMPLETED = 'JOB_COMPLETED'
export const APP_SCHEMA_IMPORTED = 'APP_SCHEMA_IMPORTED'
export const JOB_COMPLETED_SOCKET = `${SOCKET_PREFIX}JOB_COMPLETED`

export const ADD_NEW_JOB = 'ADD_NEW_JOB'
export const REMOVE_JOB = 'REMOVE_JOB'
export const TOGGLE_BOARD_COPYING = 'TOGGLE_BOARD_COPYING'

export const MONITOR_UPDATE = 'MONITOR_UPDATE'
export const MONITOR_UPDATE_SOCKET = `${SOCKET_PREFIX}MONITOR_UPDATE`

export const TOGGLE_MONITOR_SUBSCRIPTION = 'TOGGLE_MONITOR_SUBSCRIPTION'

export const APP_PUBLISH_COMPLETED = 'APP_PUBLISH_COMPLETED'
export const APP_PUBLISH_FAILED = 'APP_PUBLISH_FAILED'
