import { type ZoneOptions, DateTime } from 'luxon'
import { requestWrapper, url } from 'helpers/fetchHelpers'

export interface ITimeZone {
  name: string
  alternativeName: string
  abbreviation: string
  group: string[]
  countryName: string
  continentCode: string
  continentName: string
  mainCities: string[]
  rawOffsetInMinutes: number
  rawFormat: string
  currentTimeOffsetInMinutes: number
  currentTimeFormat: string
}

export class _DateService {
  fetchTimeZoneList = () => {
    return requestWrapper({
      rUrl: `${url}configs/time-zones`,
      type: 'GET',
      payload: {}
    }) as Promise<{ data: ITimeZone[] }>
  }

  getFormattedTimeZoneName = (timeZoneName: string) => {
    const dt = DateTime.now().setZone(timeZoneName)
    return `(GMT${dt.toFormat('Z')}) ${dt.toFormat('z').replace(/_/g, ' ')}`
  }

  getStartOfDayUTCDate = (date: DateTime, options: ZoneOptions) => {
    return date.startOf('day').setZone('utc', options)
  }
}

export const DateService = new _DateService()
