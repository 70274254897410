import { type FunctionComponent, type ReactNode } from 'react'
import classNames from 'classnames'
import EmptyState from 'components/common/EmptyState'
import {
  type IFilterRuleCondition,
  type IFilterRuleConditionConfig,
  type IFilterRulePredicateConfig,
  type IFilterRuleValue,
  type IFilterRuleVariable,
  type IFilterRulesConfigAttributeParam,
  type TFilterRulesConfig,
  EFilterRuleConditionPredicateName
} from '../filter.types'
import { FilterRuleCondition } from './filterRuleCondition/filterRuleCondition'
import './filter-rule-conditions.scss'

export type TFilterRuleConditionValueFieldProps = {
  className?: string
  placeholder?: string
  values: IFilterRuleCondition['values']
  valueType: IFilterRuleCondition['valueType']
  field: string
  fieldType: string
  options: string[]
  hasAutoComplete: boolean
  loadSuggestion: (value: string) => void
  onChangeVariables: (variables: IFilterRuleCondition['variables']) => void
  onChange: (values: Array<{ value: IFilterRuleValue }>) => void
  onFocus: () => void
  onBlur: () => void
}

export type TFilterRuleConditionsProps = {
  className?: string
  isSupportVariables?: boolean
  valueFieldRenderer?: (
    condition: IFilterRuleCondition
  ) => FunctionComponent<TFilterRuleConditionValueFieldProps>
  rulesConfig: TFilterRulesConfig<IFilterRuleConditionConfig>
  conditions: IFilterRuleCondition[]
  emptyStateIcon?: ReactNode
  emptyStateFirstRow?: ReactNode
  emptyStateSecondRow?: ReactNode
  onConditionDelete: (uuid: IFilterRuleCondition['uuid']) => void
  onConditionSelect: (uuid: IFilterRuleCondition['uuid'], condition: string) => void
  onPredicateSelect: (
    ruleCondition: IFilterRuleCondition,
    predicate: {
      predicateConfig: IFilterRulePredicateConfig
      predicateName: EFilterRuleConditionPredicateName
      predicate: string
    }
  ) => void
  onParamsChange?: (
    uuid: IFilterRuleCondition['uuid'],
    paramName: IFilterRulesConfigAttributeParam['name'],
    value: unknown
  ) => void
  onValueTypeSelect?: (
    uuid: IFilterRuleCondition['uuid'],
    valueType: IFilterRuleCondition['valueType']
  ) => void
  onValuesChange?: (
    uuid: IFilterRuleCondition['uuid'],
    values: Array<{ value: IFilterRuleValue }>
  ) => void
  onVariablesChange?: (
    uuid: IFilterRuleCondition['uuid'],
    variables: IFilterRuleCondition['variables']
  ) => void
  onEntityIdsChange?: (
    uuid: IFilterRuleCondition['uuid'],
    entityIds: IFilterRuleCondition['entityIds']
  ) => void
  onVariableUpdate?: (variable: IFilterRuleVariable) => void
  onLoadSuggestion?: (ruleCondition: IFilterRuleCondition, input: string) => Promise<unknown[]>
  onLoadVariableSuggestion?: (input: string) => Promise<unknown[]>
}
export const FilterRuleConditions = ({
  className,
  rulesConfig,
  conditions,
  isSupportVariables,
  valueFieldRenderer,
  emptyStateIcon,
  emptyStateFirstRow,
  emptyStateSecondRow,
  onVariableUpdate,
  onConditionDelete,
  onConditionSelect,
  onPredicateSelect,
  onParamsChange,
  onValueTypeSelect,
  onVariablesChange,
  onEntityIdsChange,
  onValuesChange,
  onLoadSuggestion,
  onLoadVariableSuggestion
}: TFilterRuleConditionsProps) => {
  return (
    <div className={classNames('filter-rule-conditions', className)}>
      {conditions.map(condition => (
        <FilterRuleCondition
          key={condition.uuid}
          rulesConfig={rulesConfig}
          ruleCondition={condition}
          isSupportVariables={isSupportVariables}
          valueFieldRenderer={valueFieldRenderer}
          onParamsChange={onParamsChange}
          onVariableUpdate={onVariableUpdate}
          onConditionDelete={onConditionDelete}
          onConditionSelect={onConditionSelect}
          onPredicateSelect={onPredicateSelect}
          onValueTypeSelect={onValueTypeSelect}
          onVariablesChange={onVariablesChange}
          onEntityIdsChange={onEntityIdsChange}
          onValuesChange={onValuesChange}
          onLoadSuggestion={onLoadSuggestion}
          onLoadVariableSuggestion={onLoadVariableSuggestion}
        />
      ))}
      <EmptyState
        as="div"
        className="empty-state"
        show={!conditions.length}
        firstRow={emptyStateFirstRow}
        secondRow={emptyStateSecondRow}
        icon={emptyStateIcon}
      />
    </div>
  )
}
