import { getDefaultWorkflowMeta } from 'helpers/workflowExecution/workflowMetaHelpers'

const clickWorkflow = {
  workflowMetaModalData: {
    state: false,
    tenantId: null,
    boardId: null,
    cardUuid: null,
    widgetUuid: null,
    workflowMeta: getDefaultWorkflowMeta(),
    script: ''
  },

  isWorkflowOverlayShown: false,
  isWorkflowExecutionLoading: false,
  isWorkflowRunning: false,
  isWorkflowSuccessMessageShown: false,

  runningWorkflowMeta: {}
}

export default clickWorkflow
