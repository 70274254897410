import { type Component, type ReactNode } from 'react'
import { Button } from '@praxie/shared'
import classNames from 'classnames'
import messages from 'constants/messages'
import { Dialog, DialogActions, DialogContent, DialogTitle } from 'components/common/dialog/dialog'
import './confirmation-modal.scss'

type TProps = {
  descriptionAs?: typeof Component | string
  'data-testid'?: string
  show: boolean
  description: string | ReactNode
  size?: string
  title?: string | ReactNode
  className?: string
  hideBackdrop?: boolean
  showCancelButton?: boolean
  showConfirmButton?: boolean
  confirmButtonLabel?: string
  cancelButtonLabel?: string
  onModalCancel?: () => void
  onModalConfirm?: () => void
  busy?: boolean
  error?: string
}

export const ConfirmationModal = ({
  descriptionAs: Component = 'p',
  'data-testid': dataTestId,
  size = 'sm',
  description,
  show,
  title = '',
  className = '',
  hideBackdrop,
  showCancelButton = true,
  showConfirmButton = true,
  cancelButtonLabel = messages.CANCEL,
  confirmButtonLabel = messages.DELETE,
  onModalCancel = () => null,
  onModalConfirm = () => null,
  busy = false,
  error
}: TProps) => (
  <Dialog
    data-testid={dataTestId}
    open={show}
    className={classNames(`confirmation size-${size}`, className)}
    hideBackdrop={hideBackdrop}
    onClose={onModalCancel}
  >
    <DialogTitle className="title-container">
      <div className="title">{title}</div>
    </DialogTitle>
    <DialogContent className="dialog-content-container">
      <Component>{description}</Component>
    </DialogContent>
    <DialogActions className="footer">
      {error && <div className="error">{error}</div>}
      {showCancelButton && (
        <Button className="cancel" disabled={busy} onClick={onModalCancel}>
          {cancelButtonLabel}
        </Button>
      )}
      {showConfirmButton && (
        <Button appearance="secondary" className="save" busy={busy} onClick={onModalConfirm}>
          {confirmButtonLabel}
        </Button>
      )}
    </DialogActions>
  </Dialog>
)
