import { createReducer } from 'redux-create-reducer'
import { setSectionsLoading } from 'helpers/cards/cardsOperations'
import {
  TOGGLE_PRESENTATION_MODE,
  TOGGLE_SLIDE_SHOW,
  SET_SECTIONS_LOADING,
  REPLACE_SECTIONS_LOADING,
  CLEAR_SECTIONS_LOADING,
  ADD_RENDERED_SECTION,
  CLEAR_RENDERED_SECTIONS,
  SET_DETAILED_VIEW_MODAL_READY,
  SET_PDF_GENERATION,
  SET_PDF_WIDGET_GENERATION,
  SET_PDF_GENERATION_PROGRESS,
  SET_COPY_CARD_SNAPSHOT_IN_PROGRESS,
  SET_SAVING_IN_PROGRESS
} from '../constants/actionTypes'

export const detailed = createReducer(
  {},
  {
    [TOGGLE_PRESENTATION_MODE](state, action) {
      return { ...state, isPresentationModeEnabled: action.payload }
    },

    [TOGGLE_SLIDE_SHOW](state, action) {
      return { ...state, isSlideshowPlaying: action.payload }
    },

    [SET_SECTIONS_LOADING](state, action) {
      return {
        ...state,
        sectionsLoading: setSectionsLoading({
          sectionsLoading: state.sectionsLoading,
          cards: action.payload
        })
      }
    },

    [REPLACE_SECTIONS_LOADING](state, action) {
      return {
        ...state,
        sectionsLoading: setSectionsLoading({
          sectionsLoading: state.sectionsLoading,
          cards: action.payload,
          options: { replace: true }
        })
      }
    },

    [CLEAR_SECTIONS_LOADING](state) {
      return {
        ...state,
        sectionsLoading: {}
      }
    },

    [ADD_RENDERED_SECTION](state, action) {
      return {
        ...state,
        renderedSections: [...state.renderedSections, action.payload]
      }
    },

    [CLEAR_RENDERED_SECTIONS](state) {
      return {
        ...state,
        renderedSections: []
      }
    },

    [SET_DETAILED_VIEW_MODAL_READY](state, action) {
      return { ...state, isDetailedViewModalReady: action.payload }
    },

    [SET_PDF_GENERATION](state, action) {
      return {
        ...state,
        isPDFGeneration: action.payload.isPDFGeneration,
        pdfCardLayout: action.payload.pdfCardLayout,
        pdfGenerationProgress: action.payload.allSectionsRendered ? 100 : 0
      }
    },

    [SET_PDF_WIDGET_GENERATION](state, action) {
      return {
        ...state,
        isWidgetPDFGeneration: action.payload
      }
    },

    [SET_PDF_GENERATION_PROGRESS](state, action) {
      return {
        ...state,
        pdfGenerationProgress: action.payload
      }
    },

    [SET_COPY_CARD_SNAPSHOT_IN_PROGRESS](state, action) {
      return {
        ...state,
        copyCardSnapshotInProgress: action.payload
      }
    },

    [SET_SAVING_IN_PROGRESS](state, action) {
      return {
        ...state,
        isSavingInProgress: action.payload
      }
    }
  }
)
