import messages from '../messages'

export const SORTING_FIELDS = {
  name: 'filterName',
  object: 'object',
  creationDate: 'creationDate',
  owner: 'ownerId'
}

export const SORTING_DIRECTION = {
  ASC: 'asc',
  DESC: 'desc'
}

export const PREDICATES = {
  isEqual: 'isEqual',
  isNotEqual: 'isNotEqual'
}

export const FILTERS_OBJECTS = {
  // 'all' here just for filtering purposes - it isn't real filter object
  ALL: 'all',
  CARD: 'card',
  WIDGET: 'widget',
  DATA_RECORD: 'data_record'
}

export const FILTERS_ACCESSORY = {
  ALL: 'all',
  MY: 'my'
}

export const OPERATOR = {
  AND: 'AND',
  OR: 'OR'
}

export const RULES_MODAL_MODES = {
  EDIT: 'edit',
  CREATE: 'create'
}

export const CLASS_NAMES_CONDITION = 'widgetClassName'

export const SORTING_FIELDS_LIST = [
  {
    name: messages.FILTER_NAME,
    value: SORTING_FIELDS.name,
    className: 'filter-name'
  },
  {
    name: messages.FILTERS_OBJECT,
    value: SORTING_FIELDS.object,
    className: 'filter-object'
  },
  {
    name: messages.OWNER,
    value: SORTING_FIELDS.owner,
    className: 'owner'
  },
  {
    name: messages.CREATION_DATE,
    value: SORTING_FIELDS.creationDate,
    className: 'creation-date'
  }
]
