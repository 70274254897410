import messages from '../constants/messages'

// org level roles
const ORGANIZATION_ADMIN = 'OrganizationAdmin'

// team level roles
const TEAM_ADMIN = 'Admin'
const BOARD_MANAGER = 'Editor'

// board level roles
const CARD_BUILDER = 'BoardAdmin'
const COLLABORATOR = 'Collaborator'
const VIEWER = 'BoardViewer'

// not sure does this role stands for
export const MEMBER_ROLE = 'Member'

export const ORG_ROLES_MAP = {
  ORGANIZATION_ADMIN
}

export const TENANT_ROLES_MAP = {
  TEAM_ADMIN,
  BOARD_MANAGER
}

export const BOARD_ROLES_MAP = {
  CARD_BUILDER,
  COLLABORATOR,
  VIEWER
}

export const ALL_ROLES_MAP = {
  ...TENANT_ROLES_MAP,
  ...BOARD_ROLES_MAP
}

export const getUserRole = role => {
  switch (role) {
    case TEAM_ADMIN:
      return messages.TEAM_ADMIN
    case BOARD_MANAGER:
      return messages.BOARD_MANAGER
    case CARD_BUILDER:
      return messages.CARD_BUILDER
    case VIEWER:
      return messages.VIEWER
    case COLLABORATOR:
      return messages.COLLABORATOR
    case ORGANIZATION_ADMIN:
      return messages.ORGANIZATION_ADMIN
    default:
      return role
  }
}
