import { EFileUploaderFileTypes, FileUploaderGrpcService } from 'services/fileUploader.service'

export const findCardIndexByID = (cards, cardID) => cards.findIndex(card => card.uuid === cardID)

export const findWidgetIndexByID = (widgets, widgetUuid) =>
  widgets.findIndex(widget => widget.uuid === widgetUuid)

export const findAllLinkedCards = (cards, cardUuid) =>
  cards.filter(card => card.isLinked && card.linkToSrcCard.cardUuid === cardUuid)

export const setCardLockOwner = ({ cards, cardUuid, lockOwner }) => {
  const newCards = cards.slice(0)

  const cardIndex = findCardIndexByID(newCards, cardUuid)

  if (cardIndex !== -1) {
    newCards.splice(cardIndex, 1, {
      ...newCards[cardIndex],
      lockOwner
    })
  }

  return newCards
}

// modify widgets on card and on all cards linked to that card if exist
const bulkWidgetsModify = args => {
  const { cardUuid, cards, widgetsMap, widgets, widgetModifyFn } = args

  let cardWidgets = [...(widgetsMap[cardUuid] || [])]

  const linkedCards = findAllLinkedCards(cards, cardUuid)
  const linkedCardsWidgets = linkedCards.reduce((acc, linkedCard) => {
    acc[linkedCard.uuid] = [...(widgetsMap[linkedCard.uuid] || [])]

    return acc
  }, {})

  if (widgets) {
    widgets?.forEach(widget => {
      cardWidgets = widgetModifyFn({ widgets: cardWidgets, widget })

      linkedCards.forEach(linkedCard => {
        const linkedCardWidgets = linkedCardsWidgets[linkedCard.uuid]

        linkedCardsWidgets[linkedCard.uuid] = widgetModifyFn({
          widgets: linkedCardWidgets,
          widget
        })
      })
    })
  }

  return {
    ...widgetsMap,
    [cardUuid]: cardWidgets,
    ...linkedCardsWidgets
  }
}

export const createWidgetOnCard = ({ widgets, widget }) => {
  const widgetIndex = findWidgetIndexByID(widgets, widget.uuid)

  if (widgetIndex === -1) {
    widgets.push(widget)
  } else {
    const prevWidget = widgets[widgetIndex]

    if (prevWidget.version <= widget.version) {
      widgets.splice(widgetIndex, 1, widget)
    }
  }

  return [...widgets]
}

export const deleteWidgetOnCard = ({ widgets, widget: widgetToDelete }) =>
  widgets.filter(widget => widget.uuid !== widgetToDelete.widgetUuid)

export const bulkWidgetsCreate = args =>
  bulkWidgetsModify({
    ...args,
    widgetModifyFn: createWidgetOnCard
  })

export const bulkWidgetsUpdate = args =>
  bulkWidgetsModify({
    ...args,
    widgetModifyFn: createWidgetOnCard
  })

export const bulkWidgetsDelete = args =>
  bulkWidgetsModify({
    ...args,
    widgetModifyFn: deleteWidgetOnCard
  })

export const updateCardLinks = args => {
  const { cardUuid, cards, widgetsMap = {}, cardModifyFn, widgetsModifyFn } = args

  const linkedCards = []

  const newCards = cards.map(card => {
    if (card.isLinked && card.linkToSrcCard.cardUuid === cardUuid) {
      linkedCards.push(card.uuid)

      return cardModifyFn(card)
    }

    return card
  })

  if (!widgetsModifyFn) {
    return {
      cards: newCards,
      widgetsMap
    }
  }

  linkedCards.forEach(linkedCardUuid => {
    widgetsMap[linkedCardUuid] = widgetsModifyFn(linkedCardUuid)
  })

  return {
    cards: newCards,
    widgetsMap
  }
}

export const updateSnapshotsOnCards = payload => {
  return payload.reduce((acc, el) => {
    if (!el.isCol && el.snapshot !== null) {
      const cardData = el.isLinked ? el.linkToSrcCard : el
      const updatedSnapshot = FileUploaderGrpcService.getFileLink(EFileUploaderFileTypes.SNAPSHOT, {
        tenantID: cardData.tenantId,
        cardID: cardData.uuid || cardData.cardUuid,
        boardID: cardData.boardId
      })

      acc.push({
        ...el,
        snapshot: updatedSnapshot
      })
      return acc
    }

    acc.push(el)
    return acc
  }, [])
}
