/* eslint-disable import/no-cycle */
import * as actions from 'constants/actionTypes'
import * as appAPI from 'api/appAPI'
import * as boundAppAPI from 'api/bindAppAPI'
import { navigateToRecentBoard } from 'helpers/routesHelpers'
import { boardMenuRequest } from './profileActions'
import { importBundle } from './boardActions'

export const toggleAppDeleteModal = payload => ({
  type: actions.TOGGLE_APP_DELETE_MODAL,
  payload
})

export const deleteApp = payload => async (dispatch, getState) => {
  await appAPI.deleteApp(payload)
  await dispatch(boardMenuRequest())
  const {
    board: { currentBoard }
  } = getState()

  if (payload.appId === currentBoard.appId) {
    navigateToRecentBoard()
  }
}

export const setCurrentApp = payload => ({ type: actions.SET_CURRENT_APP, payload })

export const toggleCurrentAppLoading = payload => ({
  type: actions.TOGGLE_CURRENT_APP_LOADING,
  payload
})

export const getCurrentApp = payload => async dispatch => {
  try {
    dispatch(toggleCurrentAppLoading(true))

    const app = await dispatch(
      boundAppAPI.getApp({
        variables: payload,
        expand: { navItems: true, app: false }
      })
    )

    if (app) {
      const {
        navItems,
        showCardName,
        showExportTools,
        showBoardsInMenu,
        allowToEditCardInAppView
      } = app

      dispatch(
        setCurrentApp({
          navItems,
          showCardName,
          showExportTools,
          showBoardsInMenu,
          allowToEditCardInAppView
        })
      )
    }
  } catch (err) {
    console.error(err)
  }

  dispatch(toggleCurrentAppLoading(false))
}

export const getPublishedApps = payload => async dispatch => {
  const apps = await dispatch(
    boundAppAPI.getPublishedApps({
      variables: payload
    })
  )
  return apps
}

export const getAllCategoriesApps = payload => async dispatch => {
  const categoryApps = await dispatch(
    boundAppAPI.getAllCategoriesApps({
      variables: payload
    })
  )
  return categoryApps
}

export const getPublishedCategoriesApps = payload => async dispatch => {
  const categoryApps = await dispatch(
    boundAppAPI.getPublishedCategoriesApps({
      variables: payload
    })
  )
  return categoryApps
}

export const getFilteredCategoryApps = payload => async dispatch => {
  const filteredCategoryApps = await dispatch(
    boundAppAPI.getFilteredCategoryApps({
      variables: payload
    })
  )
  return filteredCategoryApps
}

export const getFilteredCollectionsApps = payload => async dispatch => {
  const filteredCollectionsApps = await dispatch(
    boundAppAPI.getFilteredCollectionsApps({
      variables: payload
    })
  )
  return filteredCollectionsApps
}

export const toggleAppHistoryModal = payload => ({
  type: actions.TOGGLE_APP_HISTORY_MODAL,
  payload
})
export const toggleTopMenuOpened = payload => ({
  type: actions.TOGGLE_TOP_MENU_OPENED,
  payload
})

export const importApp = payload => {
  return importBundle(appAPI.processImportApp, actions.IMPORT_APP_INDICATOR_TYPE)(payload)
}
