import gql from 'graphql-tag'

import CardLockOwnerFragment from './cardLockOwnerFragment'
import WidgetFragment from './widgetFragment'

const cardFragmentName = 'CardFragment'

export const CardFragment = {
  name: cardFragmentName,
  fragment: (expand, filters = {}) => {
    const {
      lockOwner = false,
      widgets = false,
      widgetsJSON = false,
      widgetsToShowNumber = false
    } = expand

    return gql`
                fragment ${cardFragmentName} on Card {
                    uuid
                    boardId
                    tenantId
                    organizationId
                    name
                    posD
                    posN
                    snapshot
                    isLinked
                    columnUuid
                    isHidden
                    isCol
                    isDividersShown
                    height
                    errorCode
                    sourceBoardName
                    isEditableForCurrentUser
                    isSortedColumn
                    owners
                    participants
                    showCardThumbnail
                    version
                    isIgnoredInKanban
                    linkToSrcCard {
                       tenantId
                       boardId
                       cardUuid
                    }
                    isWorkflowModeEnabled
                    workflowConnections {
                       id
                       boardId
                       cardUuid
                       workflowName
                    }
                    isActionsEnabled
                    actions {
                       actionName
                       workflowName
                       isVisible
                    }
                    hiddenSections
                    ${widgetsToShowNumber ? 'widgetsToShowNumber' : ''}
                    ${widgetsJSON ? `widgets: widgetsJSON${filters.widgetsJSON || ''}` : ''}
                    ${lockOwner ? `lockOwner {...${CardLockOwnerFragment.name}}` : ''}
                    ${widgets ? `widgets {... ${WidgetFragment.name}}` : ''}
                }
                ${lockOwner ? CardLockOwnerFragment.fragment : ''}
                ${widgets ? WidgetFragment.fragment : ''}
            `
  }
}

export default CardFragment
