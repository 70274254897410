import type { ComponentType } from 'react'
import type { TLoadedWidget } from 'features/widgets/widgetsRequire.service.ts'
import { WIDGETS_IN_SYSTEM } from './widgets.constants'
import type { IWidget, TSystemWidgetInfo } from './widgets.types'

type TLoadedSystemWidget = {
  default: ComponentType
  getWidgetData?: unknown
  adapter?: unknown
  reverseAdapter?: unknown
}

// Override paths for widgets where widgetClassName is not the same as bundle name.
const WIDGET_PATH_OVERRIDES: Record<string, string> = {
  HighchartsBubbleChartWidget: '/widgets/BubbleChartWidget',
  CsvWidget: '/widgets/CSVWidget',
  IframeWidget: '/widgets/IFrameWidget',
  MilestoneCheckMark: '/widgets/MilestoneCheckMarkWidget',
  MilestoneProgressBar: '/widgets/MilestoneProgressBarWidget',
  MilestoneTrafficLight: '/widgets/MilestoneTrafficLightWidget',
  HighchartsScatterChartWidget: '/widgets/ScatterChartWidget',
  HsTableWidget: '/widgets/TableWidget',
  ExcelWidget: '/widgets/FileWidget'
}

export const getWidgetPath = (widgetClassName: string) => {
  return WIDGET_PATH_OVERRIDES[widgetClassName] || `/widgets/${widgetClassName}`
}

export const importWidgetFromSystem = async (widgetInfo: TSystemWidgetInfo) => {
  const modules = import.meta.glob(`/src/widgets/*/*.tsx`)

  const widgetImport = modules[`/src/widgets/${widgetInfo.folder}/${widgetInfo.file}`]

  if (!widgetImport) throw new Error('Widget not found')

  try {
    return (await widgetImport()) as TLoadedSystemWidget
  } catch (err) {
    throw err
  }
}

export const importWidgetBundle = async (widgetClassName: string) => {
  const widgetPath = getWidgetPath(widgetClassName)

  try {
    return (await import(
      /* @vite-ignore */
      `${widgetPath}.js`
    )) as TLoadedWidget['module']
  } catch (err) {
    throw err
  }
}

// TODO: Fix import of legacy ExcelWidget.
export const getWidgetSource = (currentWidget: IWidget) => {
  const { widgetClassName } = currentWidget

  if (WIDGETS_IN_SYSTEM[widgetClassName]) {
    return importWidgetFromSystem(WIDGETS_IN_SYSTEM[widgetClassName])
  }

  return importWidgetBundle(widgetClassName)
}
