import messages from 'constants/messages'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { EWorkflowBlockTypes } from 'constants/workflowBuilder/blocksTypes'
import { OBJECT_TYPES } from 'constants/workflows'
import GET_WIDGET_DATA_BLOCKS_CONFIG from 'helpers/workflowWidgetDataBlock/widgetAttributesConfig'
import { REST_API_CLIENT_BLOCK_OUTPUTS } from 'components/workflowBuilder/systemActions/restApiClientBlock/restApiClientBlock.constants'
import { EAuthorizationType } from 'components/workflowBuilder/systemActions/restApiClientBlock/restApiClientBlock.types'
import { type IWorkflowBlock, type TWorkflowBlockInput } from 'features/workflow/workflow.types'
import { getFieldNamesItems } from '../workflowBuilder/inputOperations'
import { DEFAULT_NAME } from '../workflowCreateColumnBlocks/workflowCreateColumnBlocksHelpers'
import DEFINE_STRING_BLOCKS_CONFIG from '../workflowDefineStringBlocks/defineStringAttributesConfig'
import { PLAIN_STRING, STRING_INPUT } from '../workflowFindBlock/workflowFindBloksHelpers'

/**
 * Each block has different rules of setting name to outputs which it produces
 * all rules stored here: https://leverxeu.atlassian.net/wiki/spaces/EUPBOARD01/pages/1678377353/4.+T+A+Blocks+components+inputs+outputs
 */

const GET_BLOCKS = {
  [EFieldKeys.ENTITY_INPUT]: (
    entity: { widgetTitle?: string; username?: string; name?: string } | null
  ) => {
    if (!entity) return ''

    // In case of widget entity name stored in "widgetTitle", otherwise just in "name"
    return entity.widgetTitle || entity.username || entity.name
  }
}

const FIND_BLOCKS = (blockType: EWorkflowBlockTypes) => ({
  [EFieldKeys.TYPE]: (value: string, meta: IWorkflowBlock['meta'], block: IWorkflowBlock) => {
    if (value === STRING_INPUT) {
      const messagesByBlockType = {
        [EWorkflowBlockTypes.FIND_CARD]: messages.CARD_WITH_STRING_NAME,
        [EWorkflowBlockTypes.FIND_WIDGET]: messages.WIDGET_WITH_STRING_NAME,
        [EWorkflowBlockTypes.FIND_COLUMN]: messages.COLUMN_WITH_STRING_NAME
      } as Record<EWorkflowBlockTypes, string>

      return block.input[EFieldKeys.STRING_INPUT] ? messagesByBlockType[blockType] : null
    }

    if (value === PLAIN_STRING) {
      return (meta[EFieldKeys.NAME] as string) || null
    }

    return null
  }
})

const FIND_BOARD = {
  [EFieldKeys.TYPE]: (value: string, meta: IWorkflowBlock['meta'], block: IWorkflowBlock) => {
    if (value === PLAIN_STRING) {
      const names = meta[EFieldKeys.NAMES] as string[]

      return (names.length && names[0]) || null
    }

    if (value === STRING_INPUT) {
      const stringInputsNames = getFieldNamesItems(EFieldKeys.STRING_INPUT, block.inputDefinition)

      return block.input[stringInputsNames[0]!] ? messages.BOARD_WITH_STRING_NAME : null
    }

    return messages.FOUND_BOARD
  }
}

const DEFINE_STRING_BLOCKS = {
  [EFieldKeys.ATTRIBUTE]: (value: string) => {
    const stringBlock =
      DEFINE_STRING_BLOCKS_CONFIG[value as keyof typeof DEFINE_STRING_BLOCKS_CONFIG]

    return value ? `${stringBlock.name} string` : null
  },
  [EFieldKeys.KEY]: (value: string) => (value ? `${value} string` : null)
}

const DEFINE_JSON_BLOCKS = {
  [EFieldKeys.TEMPLATE_ID]: (value: string) => (value ? 'JSON' : null)
}

const DEFINE_FILE_FROM_WIDGET = {
  [EFieldKeys.SOURCE_INPUT]: (value: string) => (value ? 'File' : null)
}

const GENERATE_FILE_BLOCKS = {
  [EFieldKeys.TEMPLATE_ID]: (value: string) => (value ? 'File' : null)
}

const REST_API_CLIENT = {
  [EFieldKeys.URL_TARGET]: (value: unknown[], meta: IWorkflowBlock['meta']) => {
    const outputType = meta[EFieldKeys.DATA_TYPE] as OBJECT_TYPES.FILE | OBJECT_TYPES.JSON

    return value.length ? REST_API_CLIENT_BLOCK_OUTPUTS[outputType].title : null
  },
  [EFieldKeys.CREDENTIAL_ID]: (value: string, meta: IWorkflowBlock['meta']) => {
    const outputType = meta[EFieldKeys.DATA_TYPE] as OBJECT_TYPES.FILE | OBJECT_TYPES.JSON

    if (meta[EFieldKeys.AUTHORIZATION_TYPE] === EAuthorizationType.BASIC) {
      return value ? REST_API_CLIENT_BLOCK_OUTPUTS[outputType].title : null
    }

    const urlTarget = meta[EFieldKeys.URL_TARGET] as string[]
    return urlTarget.length ? REST_API_CLIENT_BLOCK_OUTPUTS[outputType].title : null
  },
  [EFieldKeys.DATA_TYPE]: (
    value: OBJECT_TYPES.FILE | OBJECT_TYPES.JSON,
    meta: IWorkflowBlock['meta']
  ) => {
    const urlTarget = meta[EFieldKeys.URL_TARGET] as string[]

    return urlTarget.length ? REST_API_CLIENT_BLOCK_OUTPUTS[value].title : null
  }
}

const GET_WIDGET_DATA = {
  [EFieldKeys.ATTRIBUTE]: (
    value: string,
    meta: IWorkflowBlock['meta'] | null,
    block: IWorkflowBlock
  ) => {
    if (block.input[EFieldKeys.SOURCE_INPUT] && value) {
      if (value === GET_WIDGET_DATA_BLOCKS_CONFIG.cardName.value) {
        return messages.GET_WIDGET_CARD_DATA_OUTPUT
      }

      const { x, y } = meta?.[EFieldKeys.PARAMS] as { x: string; y: string }

      return messages.GET_WIDGET_DATA_OUTPUT(x, y)
    }

    return null
  }
}

const UPDATE_WIDGET_DATA = {
  [EFieldKeys.SOURCE_INPUT]: (value: { name: string } | null) => (value ? value.name : null)
}

const CONVERT_WIDGET_DATA = {
  [EFieldKeys.DATA_TYPE]: (value: OBJECT_TYPES) =>
    value === OBJECT_TYPES.WIDGET_DATA ? messages.WIDGET_DATA_OUTPUT : messages.STRING_DATA_OUTPUT
}

const FIND_WIDGETS_BUNDLE = {
  [EFieldKeys.NAMES]: (value: unknown[] | null) => (value?.length ? messages.WIDGETS_BUNDLE : null)
}

const CREATE_COLUMN = {
  [EFieldKeys.TYPE]: (value: string, meta: IWorkflowBlock['meta'], block: IWorkflowBlock) => {
    if (meta[EFieldKeys.TYPE] === DEFAULT_NAME) {
      return messages.UNTITLED_COLUMN
    }

    if (value === STRING_INPUT) {
      return block.input[EFieldKeys.STRING_INPUT] ? messages.COLUMN_WITH_STRING_NAME : null
    }

    if (value === PLAIN_STRING) {
      return (meta[EFieldKeys.NAME] as string) || null
    }

    return null
  }
}

const SELECT_WIDGETS_FROM_FILTER = {
  [EFieldKeys.FILTER_UUID_SNAKE]: (value: string | null) => (value ? messages.WIDGETS_BUNDLE : null)
}

const FIND_CARD_BUNDLE_FROM_FILTER = {
  [EFieldKeys.FILTER_UUID_SNAKE]: (value: string | null) => (value ? messages.CARD_BUNDLE : null)
}

const CONVERT_WIDGET_DATA_INTO_FILE = {
  [EFieldKeys.SOURCE_INPUT]: (value: string | null) => (value ? 'File' : null)
}

// Block where output name come from meta
export const OUTPUT_NAMES_FROM_META_MAPPERS = {
  [EWorkflowBlockTypes.GET_BOARD]: GET_BLOCKS,
  [EWorkflowBlockTypes.GET_COLUMN]: GET_BLOCKS,
  [EWorkflowBlockTypes.GET_CARD]: GET_BLOCKS,
  [EWorkflowBlockTypes.GET_WIDGET]: GET_BLOCKS,
  [EWorkflowBlockTypes.GET_USER]: GET_BLOCKS,
  [EWorkflowBlockTypes.FIND_BOARD]: FIND_BOARD,
  [EWorkflowBlockTypes.FIND_WIDGET]: FIND_BLOCKS(EWorkflowBlockTypes.FIND_WIDGET),
  [EWorkflowBlockTypes.FIND_CARD]: FIND_BLOCKS(EWorkflowBlockTypes.FIND_CARD),
  [EWorkflowBlockTypes.FIND_COLUMN]: FIND_BLOCKS(EWorkflowBlockTypes.FIND_COLUMN),
  [EWorkflowBlockTypes.DEFINE_STRING_FROM_CARD]: DEFINE_STRING_BLOCKS,
  [EWorkflowBlockTypes.DEFINE_STRING_FROM_BOARD]: DEFINE_STRING_BLOCKS,
  [EWorkflowBlockTypes.DEFINE_STRING_FROM_WIDGET]: DEFINE_STRING_BLOCKS,
  [EWorkflowBlockTypes.DEFINE_STRING_FROM_USER]: DEFINE_STRING_BLOCKS,
  [EWorkflowBlockTypes.DEFINE_STRING_FROM_COLUMN]: DEFINE_STRING_BLOCKS,
  [EWorkflowBlockTypes.DEFINE_STRING_FROM_JSON_PAYLOAD]: DEFINE_STRING_BLOCKS,
  [EWorkflowBlockTypes.DEFINE_JSON_WITH_AI]: DEFINE_JSON_BLOCKS,
  [EWorkflowBlockTypes.GENERATE_FILE_WITH_AI]: GENERATE_FILE_BLOCKS,
  [EWorkflowBlockTypes.DEFINE_FILE_FROM_WIDGET]: DEFINE_FILE_FROM_WIDGET,
  [EWorkflowBlockTypes.FIND_WIDGETS_BUNDLE]: FIND_WIDGETS_BUNDLE,
  [EWorkflowBlockTypes.FIND_CARD_BUNDLE_FROM_FILTER]: FIND_CARD_BUNDLE_FROM_FILTER,
  [EWorkflowBlockTypes.CREATE_COLUMN]: CREATE_COLUMN,
  [EWorkflowBlockTypes.SELECT_WIDGETS_FROM_FILTER]: SELECT_WIDGETS_FROM_FILTER,
  [EWorkflowBlockTypes.REST_API_CLIENT]: REST_API_CLIENT,
  [EWorkflowBlockTypes.GET_WIDGET_DATA]: GET_WIDGET_DATA,
  [EWorkflowBlockTypes.UPDATE_WIDGET_DATA]: UPDATE_WIDGET_DATA,
  [EWorkflowBlockTypes.CONVERT_WIDGET_DATA]: CONVERT_WIDGET_DATA,
  [EWorkflowBlockTypes.CONVERT_WIDGET_DATA_INTO_FILE]: CONVERT_WIDGET_DATA_INTO_FILE
}

const COPY_BOARD = ({ [EFieldKeys.SOURCE_INPUT]: sourceInput }: TWorkflowBlockInput) =>
  sourceInput ? messages.BOARD_COPY : null

const COPY_CARD = ({
  [EFieldKeys.SOURCE_INPUT]: sourceInput,
  [EFieldKeys.BOARD_INPUT]: boardInput
}: TWorkflowBlockInput) => {
  const allInputsFilled = sourceInput && boardInput

  return allInputsFilled ? messages.CARD_COPY : null
}

const COPY_WIDGETS_BUNDLE = ({
  [EFieldKeys.SOURCE_INPUT]: sourceInput,
  [EFieldKeys.CARD_INPUT]: cardInput
}: TWorkflowBlockInput) => {
  const allInputsFilled = sourceInput && cardInput

  return allInputsFilled ? messages.WIDGETS_BUNDLE_COPY : null
}

const COPY_COLUMN = ({
  [EFieldKeys.SOURCE_INPUT]: sourceInput,
  [EFieldKeys.BOARD_INPUT]: boardInput
}: TWorkflowBlockInput) => {
  const allInputsFilled = sourceInput && boardInput

  return allInputsFilled ? messages.COLUMN_COPY : null
}

const FIND_USER = ({ [EFieldKeys.STRING_INPUT]: stringInput }: TWorkflowBlockInput) =>
  stringInput ? messages.USER_FOUND_BY_STRING : null

// Block where output name come from input
export const OUTPUT_NAMES_FROM_INPUT_MAPPERS = {
  [EWorkflowBlockTypes.COPY_BOARD]: COPY_BOARD,
  [EWorkflowBlockTypes.COPY_CARD]: COPY_CARD,
  [EWorkflowBlockTypes.COPY_WIDGETS_BUNDLE]: COPY_WIDGETS_BUNDLE,
  [EWorkflowBlockTypes.COPY_COLUMN]: COPY_COLUMN,
  [EWorkflowBlockTypes.FIND_USER]: FIND_USER
}
