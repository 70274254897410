import messages from '../../constants/messages'

const DEFINE_STRING_BLOCKS_CONFIG = {
  boardId: {
    name: messages.DEFINE_STRING_OPTION_BOARD_ID_NAME,
    board: true,
    card: true,
    widget: true,
    column: true
  },
  boardName: {
    name: messages.DEFINE_STRING_OPTION_BOARD_NAME,
    board: true,
    card: true,
    widget: true,
    column: true
  },
  cardName: { name: messages.DEFINE_STRING_OPTION_CARD_NAME, card: true, widget: true },
  cardUuid: { name: messages.DEFINE_STRING_OPTION_CARD_ID, card: true, widget: true },
  widgetClassName: { name: messages.DEFINE_STRING_OPTION_WIDGET_CLASS, widget: true },
  widgetTitle: { name: messages.DEFINE_STRING_OPTION_WIDGET_NAME, widget: true },
  widgetUuid: { name: messages.DEFINE_STRING_OPTION_WIDGET_ID, widget: true },
  UBF: {
    name: messages.DEFINE_STRING_OPTION_WIDGET_DATA,
    widget: true,
    params: { x: { name: 'X' }, y: { name: 'Y' } }
  },
  userId: { name: messages.DEFINE_STRING_OPTION_USER_ID, user: true },
  userName: { name: messages.DEFINE_STRING_OPTION_USER_NAME, user: true },
  userDisplayName: { name: messages.DEFINE_STRING_OPTION_USER_DISPLAY_NAME, user: true },
  managerName: { name: messages.DEFINE_STRING_OPTION_USER_MANAGER_NAME, user: true },
  cardOwnerId: { name: messages.DEFINE_STRING_OPTION_OWNER_ID, card: true },
  cardOwnerEmail: { name: messages.DEFINE_STRING_OPTION_OWNER_EMAIL, card: true },
  columnName: { name: messages.DEFINE_STRING_OPTION_COLUMN_NAME, column: true },
  columnUuid: { name: messages.DEFINE_STRING_OPTION_COLUMN_ID, column: true }
}

export default DEFINE_STRING_BLOCKS_CONFIG
