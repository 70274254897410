export const getBoard = state => state.board

export const getCurrentBoard = state => getBoard(state).currentBoard
export const getBoardCards = state => getBoard(state).cards
export const getBoardSettings = state => getBoard(state).boardSettings
export const getBoardThirdPartyWidgets = state => getBoard(state).thirdPartyWidgets
export const getRenamingCardUuid = state => getBoard(state).renamingCardUuid
export const getBoardComments = state => getBoard(state).comments
export const getBoardAllCommentsLoaded = state => getBoard(state).areAllCommentsLoaded

export const cardWidgetsSelector = cardUuid => state => ({
  widgets: state.board.widgetsMap[cardUuid]
})

export const isBoardLoadingErrorSelector = state => state.board.isBoardLoadingError
