import { bindOrganizationId } from 'store/storeBindings/organizationBindings'
import {
  getCardRequest,
  getColumnRequest,
  getCardSectionDataRequest,
  getCardsRequest,
  getWidgets
} from 'api/cardAPI'

export const getCard = bindOrganizationId(getCardRequest)
export const getCardSectionData = bindOrganizationId(getCardSectionDataRequest)

export const getCardsList = bindOrganizationId(getCardsRequest)

export const getColumn = bindOrganizationId(getColumnRequest)

export const getWidgetsRequest = bindOrganizationId(getWidgets)
