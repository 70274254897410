import React, { useRef, useState } from 'react'
import classNames from 'classnames'
import messages from 'constants/messages'
import { Menu } from 'components/common/menu/menu'
import './add-file-dropdown.scss'

const ListItem = ({ text, tooltip, icon, onClick, showSeparator, value }) => (
  <li
    onClick={onClick}
    className={classNames({
      'list-item': true,
      'title-item': value === 'typeTitle'
    })}
  >
    {icon}
    <span className="text-value" title={!tooltip ? text : tooltip}>
      {text}
    </span>
    {showSeparator ? <hr className="separator" /> : ''}
  </li>
)

const AddFileDropdown = ({ items, toolbar, toggleButton, className, id, disabled }) => {
  const [isOpen, setIsOpen] = useState(false)

  const anchorEl = useRef(null)

  const onToggle = (state, event) => {
    if (event) event.stopPropagation()

    setIsOpen(state)
  }

  const onClick = (callback, event) => {
    if (event) event.stopPropagation()
    if (callback) callback()

    onToggle(false)
  }

  const componentItems = items.map(item => (
    <ListItem
      onClick={() => onClick(item.onClick)}
      icon={item.icon}
      text={item.text}
      tooltip={item.tooltip}
      showSeparator={item.showSeparator}
      key={item.value}
      value={item.value}
    />
  ))

  return (
    <div ref={anchorEl} id={id} className={classNames('add-file-dropdown', className)}>
      <div
        className={classNames('add-file-dropdown-toggle', className, {
          open: isOpen,
          disabled: !!disabled
        })}
        onClick={() => onToggle(true)}
      >
        {toggleButton}
      </div>
      <Menu
        className={classNames('add-file-dropdown-menu', className)}
        open={isOpen}
        onClose={() => onToggle(false)}
        anchorEl={anchorEl.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: -5,
          horizontal: 'left'
        }}
      >
        {toolbar || ''}
        {componentItems.length ? (
          componentItems
        ) : (
          <div className="empty-block">{messages.NO_RESULTS}</div>
        )}
      </Menu>
    </div>
  )
}

export default AddFileDropdown
