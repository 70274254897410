import { createReducer } from 'redux-create-reducer'
import * as ActionTypes from '../constants/actionTypes'

export const workspaceMembers = createReducer(
  {},
  {
    [ActionTypes.TOGGLE_WORKSPACE_MEMBERS_FETCHING](state, action) {
      return { ...state, isWorkspaceMembersFetching: action.payload }
    },

    [ActionTypes.CLEAR_WORKSPACE_MEMBERS](state) {
      return { ...state, members: [] }
    },

    [ActionTypes.APPEND_WORKSPACE_MEMBERS](state, action) {
      return { ...state, members: [...state.members, ...action.payload] }
    },

    [ActionTypes.TOGGLE_MANAGER_ASSIGNMENT_MODAL](state, action) {
      return {
        ...state,
        managerAssignmentModalData: {
          state: action.payload.state,
          data: action.payload.data || {},
          onConfirm: action.payload.onConfirm || null
        }
      }
    },

    [ActionTypes.TOGGLE_DELETE_WORKSPACE_MEMBER_CONFIRMATION](state, action) {
      return {
        ...state,
        workspaceMemberDeleteConfirmationData: {
          state: action.payload.state,
          data: action.payload.data || {},
          onConfirm: action.payload.onConfirm || null
        }
      }
    },

    [ActionTypes.TOGGLE_DELETE_WORKSPACE_MEMBER_REJECTION_MODAL](state, action) {
      return {
        ...state,
        deleteWorkspaceMemberRejectionData: action.payload
      }
    },

    [ActionTypes.UPDATE_WORKSPACE_MEMBER](state, action) {
      const members = state.members.map(member => {
        if (member.userId === action.payload.userId) {
          return action.payload
        }
        return member
      })
      return { ...state, members }
    }
  }
)
