import { TeamService } from 'features/team/team.service'
import { deriveMemoizedActiveTeamMemberMap } from 'features/team/team.helpers'

export const mapAuthorDataFromComment = (comment, membersMap) => {
  const { authorUserId, mentionedUsersIds } = comment
  const userData = membersMap?.[authorUserId]
  if (userData) {
    const { avatarImage, email, username } = userData

    const mentionedUsers = mentionedUsersIds?.reduce((result, mentionId) => {
      const member = membersMap[mentionId]

      if (member) {
        result.push(member)
      }

      return result
    }, [])

    return {
      ...comment,
      avatarImage,
      email,
      username,
      mentionedUsers
    }
  }
  return comment
}

export const mapAuthorDataFromCommentList = (comments, membersMap, tenantId) => {
  if (Object.keys(membersMap).length) {
    return comments.map(comment => mapAuthorDataFromComment(comment, membersMap))
  }

  return TeamService.getTeamMemberList(tenantId).then(({ data }) => {
    const activeMembersMap = deriveMemoizedActiveTeamMemberMap(data)

    return comments.map(comment => mapAuthorDataFromComment(comment, activeMembersMap))
  })
}

export const replaceTempComment = (comments, tempId, commentId) =>
  comments.map(comment => {
    if (comment.commentId === tempId) {
      return {
        ...comment,
        commentId,
        isTemp: false
      }
    }
    const replyComment =
      comment.replies && comment.replies.find(reply => reply.commentId === tempId)
    if (replyComment) {
      return {
        ...comment,
        replies: replaceTempComment(comment.replies, tempId, commentId)
      }
    }
    return comment
  })
