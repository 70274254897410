import { type CUDEvent, CUDEventEventType } from '_proto/common/event.pb'
import { ListenRequest } from '_proto/comments/v1/comments.pb'
import { COMMENTS_EVENT_TYPES, commentsEventManager } from 'services/comments/CommentsEventManager'
import { CardCommentsGrpcService } from 'features/cards/comments/cardComments.grpc.service'

export const handleChangesReceive = (event: CUDEvent, callback: () => void) => {
  switch (event.type) {
    case CUDEventEventType.EVENT_TYPE_CREATED: {
      commentsEventManager.emit(COMMENTS_EVENT_TYPES.CREATE_COMMENT, { key: event.key })

      callback()

      break
    }

    case CUDEventEventType.EVENT_TYPE_UPDATED: {
      commentsEventManager.emit(COMMENTS_EVENT_TYPES.UPDATE_COMMENT, { key: event.key })

      break
    }

    case CUDEventEventType.EVENT_TYPE_DELETED: {
      commentsEventManager.emit(COMMENTS_EVENT_TYPES.DELETE_COMMENT, { key: event.key })

      callback()

      break
    }

    default: {
      break
    }
  }
}

class CommentListeners {
  _abortController: AbortController | undefined = undefined

  cancelCardsCommentUpdateListener() {
    if (this._abortController) {
      this._abortController.abort()
      this._abortController = undefined
    }
  }

  async createCardsCommentUpdateListener(
    cards: Array<{ tenantId: string; boardId: string; uuid: string }>,
    organizationId: string,
    commonCallback: () => void
  ) {
    if (this._abortController) {
      this.cancelCardsCommentUpdateListener()
    }

    const keys = cards.map(card => ({
      scope: {
        organizationId,
        tenantId: card.tenantId,
        boardId: card.boardId,
        cardId: card.uuid
      }
    }))

    this._abortController = new AbortController()

    const request = ListenRequest.create({ keyOnly: { keys } })
    const client = CardCommentsGrpcService.listenForChanges({
      request,
      options: { signal: this._abortController.signal }
    })

    try {
      for await (const response of client) {
        handleChangesReceive(response, commonCallback)
      }
    } catch (error) {
      if (error instanceof Error && error.name !== 'AbortError') {
        throw error
      }
    }
  }
}

export const CommentListenerService = new CommentListeners()
