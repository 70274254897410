import Select, { components } from 'react-select'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import 'scss/common/multi-select.scss'

const Option = props => {
  const { data, ...rest } = props

  return (
    <>
      <components.Option data={data} {...rest} />
      {data.bottomDivider && <div className="option-divider" />}
    </>
  )
}

const ReactSelect = ({ menuPortalEnabled, menuPortalTarget, ...props }) => {
  const portalTarget = menuPortalEnabled
    ? (menuPortalTarget ?? document.getElementById('portal-container') ?? document.body)
    : null

  return (
    <Select
      ref={props.controlRef}
      id={props.id}
      styles={props.styles}
      className={classNames('upboard-react-select plain-select', props.size, props.className)}
      classNamePrefix="upboard-rs"
      placeholder={props.placeholder}
      value={props.value}
      options={props.options}
      isSearchable={props.isSearchable}
      autoFocus={props.autoFocus}
      isDisabled={props.isDisabled}
      formatOptionLabel={props.formatOptionLabel}
      getOptionValue={props.getOptionValue}
      getOptionLabel={props.getOptionLabel}
      hideSelectedOptions={props.hideSelectedOptions}
      menuPlacement={props.menuPlacement}
      menuPortalTarget={portalTarget}
      defaultMenuIsOpen={props.defaultMenuIsOpen}
      menuPosition={props.menuPosition}
      components={{ Option }}
      closeMenuOnScroll
      onChange={props.onChange}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      onMenuOpen={props.onMenuOpen}
      onMenuClose={props.onMenuClose}
    />
  )
}

ReactSelect.propTypes = {
  id: PropTypes.string,
  value: PropTypes.object,
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  isSearchable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  formatOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  hideSelectedOptions: PropTypes.bool,
  menuPlacement: PropTypes.oneOf(['auto', 'bottom', 'top']),
  onMenuOpen: PropTypes.func,
  onMenuClose: PropTypes.func,
  styles: PropTypes.object,
  // to enable portaling on menu
  menuPortalEnabled: PropTypes.bool,
  // custom portal target for menu, if portaling enabled, but target is not specified
  // default portal target (#portal-container) will be used
  menuPortalTarget: PropTypes.instanceOf(Element),
  menuPosition: PropTypes.oneOf(['absolute', 'fixed']),
  controlRef: PropTypes.object
}

ReactSelect.defaultProps = {
  id: undefined,
  value: null,
  className: '',
  size: 'medium',
  placeholder: '',
  onChange: null,
  onFocus: null,
  onBlur: null,
  isSearchable: false,
  isDisabled: false,
  formatOptionLabel: undefined,
  getOptionValue: undefined,
  getOptionLabel: undefined,
  hideSelectedOptions: false,
  menuPlacement: 'bottom',
  onMenuOpen: () => undefined,
  onMenuClose: () => undefined,
  styles: {},
  menuPortalEnabled: false,
  menuPortalTarget: null,
  menuPosition: 'absolute',
  controlRef: null
}

export default ReactSelect
