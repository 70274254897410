import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import messages from '../../constants/messages'

import CheckBoxField from '../common/CheckBoxField'
import UserRepresentation from '../common/UserRepresentation'

import '../../scss/usersList/users-list.scss'

const removedUserName = `[${messages.REMOVED_USER}]`

const UsersList = props => {
  const { isChecked, users, className, disabled, onUserClick, isSelectionSupported } = props

  return (
    <ul className={classNames('users-list', className)}>
      {users.map(item => (
        <li
          key={item.userId}
          className={classNames('user-item', { disabled })}
          onClick={!disabled ? onUserClick.bind(null, item) : null}
        >
          {isSelectionSupported && (
            <CheckBoxField
              label=""
              id={`user-item-${item.userId}`}
              checked={isChecked}
              disabled={disabled}
              readOnly
            />
          )}
          {item.username && (
            <UserRepresentation
              showTitles
              username={item.username}
              email={item.email || ''}
              size="extra-small-24px"
              avatarImage={item.avatarImage}
            />
          )}
          {!item.username && <span className="removed-user">{removedUserName}</span>}
        </li>
      ))}
    </ul>
  )
}

UsersList.propTypes = {
  isChecked: PropTypes.bool,
  users: PropTypes.arrayOf(PropTypes.shape({ userId: PropTypes.string })),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onUserClick: PropTypes.func,
  isSelectionSupported: PropTypes.bool
}

UsersList.defaultProps = {
  isChecked: false,
  users: [],
  className: '',
  disabled: false,
  onUserClick: null,
  isSelectionSupported: true
}

export default UsersList
