import { getCell } from './builderHelpers'
import { convertTimestampToExcelDate, isDateFormat } from './common/datesHelpers'

export const dataGridToMatrix = (rows, skipEmptyValues) => {
  const rowsCount = rows.length

  const data = [[]]
  for (let rowIndex = 0; rowIndex < rowsCount; rowIndex += 1) {
    const row = rows[rowIndex] || []
    for (let columnIndex = 0; columnIndex < row.length; columnIndex += 1) {
      const cell = row[columnIndex]

      const isObjectCell =
        typeof cell === 'object' &&
        cell &&
        (!!cell.value || cell.value === 0 || (!skipEmptyValues && cell.dataLink))
      const isPlainCell = typeof cell !== 'object' && cell
      if (isPlainCell || isObjectCell) {
        if (!data[rowIndex]) {
          data[rowIndex] = []
        }
        data[rowIndex][columnIndex] = cell
      }
    }
  }

  return data
}

export const tableDataGrid = rows => {
  const maxCols = rows.reduce((max, row) => Math.max(max, row.length), 0)
  return rows.reduce((arr, _row) => {
    const row = _row
    if (row.length) {
      for (let i = 0; i < maxCols; i += 1) {
        if (!row[i]) {
          row[i] = { value: '' }
        }
      }
      arr.push(row)
    }
    return arr
  }, [])
}

export const padSpreadsheetToInitialSize = (rows, dimension) => {
  const _rows = JSON.parse(JSON.stringify(rows))

  for (let row = 0; row < dimension.rows; row += 1) {
    if (!_rows[row]) {
      _rows[row] = []
    }

    for (let col = 0; col < dimension.cols; col += 1) {
      if (!_rows[row][col]) {
        _rows[row][col] = ''
      }
    }
  }

  return _rows
}

const isEmpty = value => value === null || value === undefined

const getCellValue = cell => {
  if (isEmpty(cell)) {
    return null
  }
  if (!cell || Array.isArray(cell)) {
    return cell
  }
  if (typeof cell === 'object') {
    const { value, meta, dataLink } = cell
    if (value && meta?.format && isDateFormat(meta.format)) {
      return convertTimestampToExcelDate(value)
    }
    if (value !== undefined || dataLink !== undefined) {
      return value
    }
  }
  return cell
}

const getCellCalculatedValue = cell => {
  if (cell && typeof cell === 'object' && cell.calculatedValue !== undefined) {
    return cell.calculatedValue
  }
  return null
}

// from chartData ( [[[{ value, calculatedValue }]] ) to [[value]]
export const prepareDataForHotTable = (rawData, rowsCount, colsCount) => {
  const data = []
  const calculatedData = []
  for (let row = 0; row < rowsCount; row += 1) {
    if (!data[row]) {
      data.push([])
    }
    if (!calculatedData[row]) {
      calculatedData.push([])
    }
    for (let col = 0; col < colsCount; col += 1) {
      const cell = getCell(rawData, row, col)
      data[row][col] = getCellValue(cell)
      calculatedData[row][col] = getCellCalculatedValue(cell)
    }
  }
  return { data, calculatedData }
}

export const parseRows = data => {
  if (data) {
    return JSON.parse(data)
  }

  return undefined
}

// from 10000000 bytes to '10 Mb'
export const fileSize = bytes => {
  if (!bytes) return '0 B'
  const exp = Math.floor(Math.log(bytes) / Math.log(1024))
  const result = (bytes / 1024 ** exp).toFixed()
  const letters = 'KMGTPEZY'
  const size = exp === 0 ? 'B' : `${letters[exp - 1]}b`

  return `${result} ${size}`
}

export const getCellAlphaNum = cell => {
  const num = cell.match(/\d+$/)
  const alpha = cell.replace(num, '')

  return {
    alpha,
    num: parseInt(num[0], 10)
  }
}

export const changeRowIndex = (cell, counter) => {
  const alphaNum = getCellAlphaNum(cell)
  const col = alphaNum.alpha
  let row = parseInt(alphaNum.num + counter, 10)

  if (row < 1) {
    row = 1
  }

  return `${col}${row}`
}

export const toChar = _num => {
  let s = ''
  let num = _num

  while (num >= 0) {
    s = String.fromCharCode((num % 26) + 97) + s
    num = Math.floor(num / 26) - 1
  }

  return s.toUpperCase()
}

export const clearFormula = formula => formula.replace(/\$/g, '')

export const toNum = _chr => {
  const chr = clearFormula(_chr)
  const base = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  let result = 0

  for (let i = 0, j = chr.length - 1; i < chr.length; i += 1, j -= 1) {
    result += base.length ** j * (base.indexOf(chr[i]) + 1)
  }

  if (result) {
    result -= 1
  }

  return result
}

export const changeColIndex = (cell, counter) => {
  const alphaNum = getCellAlphaNum(cell)
  const { alpha } = alphaNum
  let col = toChar(parseInt(toNum(alpha) + counter, 10))
  let row = alphaNum.num

  if (!col || col.length === 0) {
    col = 'A'
  }

  const fixedCol = alpha[0] === '$' || false
  const fixedRow = alpha[alpha.length - 1] === '$' || false

  col = (fixedCol ? '$' : '') + col
  row = (fixedRow ? '$' : '') + row

  return `${col}${row}`
}

export const number = num => {
  switch (typeof num) {
    case 'number':
      return num
    case 'string':
      if (!isNaN(num)) {
        return num.indexOf('.') > -1 ? parseFloat(num) : parseInt(num, 10)
      }
      break
    default:
      return num
  }

  return num
}

export const updateFormula = (formula, direction, delta) => {
  let type
  let counter

  // left, right -> col
  if (['left', 'right'].indexOf(direction) !== -1) {
    type = 'col'
  } else if (['up', 'down'].indexOf(direction) !== -1) {
    type = 'row'
  }

  // down, up -> row
  if (['down', 'right'].indexOf(direction) !== -1) {
    counter = delta * 1
  } else if (['up', 'left'].indexOf(direction) !== -1) {
    counter = delta * -1
  }

  if (type && counter) {
    return formula.replace(/(\$?[A-Za-z]+\$?[0-9]+)/g, _match => {
      const match = _match.toUpperCase()
      const { alpha } = getCellAlphaNum(match)

      const fixedCol = alpha[0] === '$' || false
      const fixedRow = alpha[alpha.length - 1] === '$' || false

      if (type === 'row' && fixedRow) {
        return match
      }

      if (type === 'col' && fixedCol) {
        return match
      }

      return type === 'row' ? changeRowIndex(match, counter) : changeColIndex(match, counter)
    })
  }

  return formula
}

export const parseOptionsFromQuery = queries => {
  // If Query has been removed, BE returns an empty array
  if (queries.some(el => !el[0])) return []

  return queries.map(el => el[0])
}
