import { FileFolder, UploadFileRequest } from '_proto/uploader/v1/uploader.pb'
import { requestWrapper, url } from 'helpers/fetchHelpers'
import { FileUploaderGrpcService } from 'services/fileUploader.service'

const cleanupDataLink = dataLink => {
  if (!dataLink || !Object.keys(dataLink).length) {
    return {}
  }
  return Object.keys(dataLink).reduce((linksObj, key) => {
    // get rid of error codes and sanitize coordinates
    const { errorCode, row, col, ...rest } = dataLink[key]
    linksObj[key] = {
      ...rest,
      row: row > 0 ? row : 0,
      col: col > 0 ? col : 0
    }
    return linksObj
  }, {})
}

// remove data because BE can't support array in array
export const getRidOfData = widgets =>
  widgets.map(widget => {
    const { data, dataLink, ...rest } = widget

    return {
      ...rest,
      dataLink: cleanupDataLink(dataLink)
    }
  })

export function bulkWidgetsUpdate(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/card/${payload.itemID}/widget${
      payload.target === 'active' ? '?target=active' : ''
    }`,
    type: 'PUT',
    payload: getRidOfData(payload.data)
  })
}

export function bulkWidgetsCreate(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/card/${payload.itemID}/widget${
      payload.target === 'active' ? '?target=active' : ''
    }`,
    type: 'POST',
    payload: getRidOfData(payload.data)
  })
}

export function widgetDelete(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/card/${
      payload.itemID
    }/widget/${payload.widgetUuid}${payload.target === 'active' ? '?target=active' : ''}`,
    type: 'DELETE',
    payload: {}
  })
}

export function processWidgetLinkingDataRequest(payload) {
  let queryString = ''
  if (payload.search) {
    queryString = `?text=${payload.search}`
  } else if (payload.widgetID) {
    queryString = `?getSingle=${
      payload.widgetID
    }&isRestrictByTenant=${!!payload.isRestrictByTenant}`
  }

  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/card/${payload.cardID}/search${queryString}`,
    type: 'GET',
    payload: {}
  })
}

export function checkLinkingLoopDetectionRequest(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/card/${payload.cardUuid}/widget/resolveDataLinks`,
    type: 'POST',
    payload: payload.widgets
  })
}

export function getWidgetsMenu(payload, menuFromLocalStorage, forceRequest) {
  if (menuFromLocalStorage && !forceRequest) {
    return new Promise(resolve => {
      resolve({ data: menuFromLocalStorage })
    })
  }

  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/widget/menu`,
    type: 'GET',
    payload: {}
  })
}

export function updateWidgetMenuItemRequest(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/widget/menu/${payload.widgetID}`,
    type: 'PUT',
    payload: payload.data
  })
}

export function getWidget(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/card/${payload.cardID}/widget/${payload.widgetUuid}`,
    type: 'GET',
    payload: {}
  })
}

// custom files copying
export function copyWidget(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/card/${payload.cardID}/widget/${payload.widgetID}/copy?resourcesOnly=true`,
    type: 'POST',
    progressDataCallback: payload.dataCallback,
    payload: {
      targetTenantId: payload.targetTenantID,
      targetBoardId: payload.targetBoardID
    }
  })
}

export function deleteWidgetMenuItem(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/widget/menu/${payload.widgetID}`,
    type: 'DELETE',
    payload: {}
  })
}

// get related cards for menu widget
export function getRelatedCards(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/widget/menu/${payload.widgetID}/relations`,
    type: 'GET',
    payload: {}
  })
}

export function processUploadWidget(payload) {
  let data
  const requestObject = {
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/upload/${payload.type}`,
    type: 'POST',
    withCanceler: true,
    progressDataCallback: payload.dataCallback
  }

  if (payload.type === 'file') {
    data = new FormData()
    data.append('file', payload.file)
    requestObject.contentType = 'multipart/form-data'
  } else if (payload.type === 'link' || payload.type === 'cloud/link') {
    data = payload.data
  }
  requestObject.payload = data
  return requestWrapper(requestObject)
}

export const processUploadFile = async ({ tenantId, boardId, file }) => {
  const data = await FileUploaderGrpcService.convertFileToUint8Array(file)

  const request = UploadFileRequest.create({
    key: {
      scope: { tenantId, boardId }
    },
    info: {
      name: file.name,
      mimeType: file.type
    },
    folder: FileFolder.WIDGET_FOLDER,
    data
  })

  const abortController = new AbortController()

  const promise = FileUploaderGrpcService.uploadFile({
    request,
    options: {
      signal: abortController.signal
    }
  })

  return { promise, canceler: () => abortController.abort() }
}

export const processUploadCloudFile = async ({ tenantId, boardId, data }) => {
  return requestWrapper({
    rUrl: `${url}tenant/${tenantId}/board/${boardId}/upload/cloud/link`,
    type: 'POST',
    withCanceler: true,
    payload: data
  })
}

export const updateWidgetName = async (name, scopeData) => {
  const { tenantId, boardId, cardUuid, widgetId } = scopeData

  return requestWrapper({
    rUrl: `${url}tenant/${tenantId}/board/${boardId}/card/${cardUuid}/widget/${widgetId}/rename`,
    type: 'PUT',
    payload: { name }
  })
}

export const createWidgetMenu = ({ tenantId, boardId, data }) => {
  return requestWrapper({
    rUrl: `${url}tenant/${tenantId}/board/${boardId}/widget/menu`,
    type: 'POST',
    payload: data
  })
}
