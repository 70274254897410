import React, { Component } from 'react'
import PropTypes from 'prop-types'

import UsersSelector from '../../components/usersList/UsersSelector'

import messages from '../../constants/messages'

class UsersSelectorContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      search: ''
    }

    this.changeSearch = this.changeSearch.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
    this.selectUser = this.selectUser.bind(this)
    this.unselectUser = this.unselectUser.bind(this)
    this.unselectAll = this.unselectAll.bind(this)
  }

  changeSearch(event) {
    const { search } = this.state
    const value = event.target.value

    if (value !== search) {
      this.setState({ search: value })
    }
  }

  clearSearch() {
    this.setState({ search: '' })
  }

  unselectUser(participant) {
    this.props.removeUser([participant.userId])
  }

  unselectAll() {
    const { selectedUsers } = this.props

    this.props.removeUser(selectedUsers.map(user => user.userId))
  }

  selectUser(user) {
    this.props.addUser(user)
  }

  render() {
    const { search } = this.state
    const {
      isFetching,
      canSelectUser,
      canUnselectUser,
      selectedUsersTitle,
      restUsersTitle,
      members: allUsers,
      selectedUsers,
      filterList,
      isRemoveAllDisabled
    } = this.props

    const filteredSelectedUsers = filterList(selectedUsers, search)
    const restUsers = filterList(allUsers, search, { filter: ['selectedUsers'] })

    return (
      <UsersSelector
        isFetching={isFetching}
        canSelectUser={canSelectUser}
        canUnselectUser={canUnselectUser}
        selectedUsersTitle={selectedUsersTitle}
        restUsersTitle={restUsersTitle}
        search={search}
        restUsers={restUsers}
        selectedUsers={filteredSelectedUsers}
        selectUser={this.selectUser}
        unselectAll={this.unselectAll}
        unselectUser={this.unselectUser}
        changeSearch={this.changeSearch}
        clearSearch={this.clearSearch}
        isRemoveAllDisabled={isRemoveAllDisabled}
      />
    )
  }
}

UsersSelectorContainer.propTypes = {
  isFetching: PropTypes.bool,
  members: PropTypes.arrayOf(PropTypes.shape({ userId: PropTypes.string })),
  filterList: PropTypes.func.isRequired,

  canSelectUser: PropTypes.bool,
  canUnselectUser: PropTypes.bool,
  selectedUsersTitle: PropTypes.string,
  restUsersTitle: PropTypes.string,
  selectedUsers: PropTypes.arrayOf(PropTypes.shape({ userId: PropTypes.string })),
  addUser: PropTypes.func,
  removeUser: PropTypes.func,
  isRemoveAllDisabled: PropTypes.bool
}

UsersSelectorContainer.defaultProps = {
  isFetching: false,
  members: [],

  canSelectUser: false,
  canUnselectUser: false,
  selectedUsersTitle: messages.USERS,
  restUsersTitle: messages.SUGGESTED_USERS,
  selectedUsers: [],
  removeUser: null,
  addUser: null,
  isRemoveAllDisabled: false
}

export default UsersSelectorContainer
