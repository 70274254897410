import {
  OUTPUT_NAMES_FROM_INPUT_MAPPERS,
  OUTPUT_NAMES_FROM_META_MAPPERS
} from '../workflowBuilderMappers/outputsNamesMappers'

export const updateOutputName = args => {
  const {
    payload: { outputId, name, error },
    outputsNames
  } = args

  return {
    outputsNames: {
      ...outputsNames,
      [outputId]: { name, error }
    }
  }
}

// for blocks where outputs names depends on one of static input
export const changeOutputNamesFromMeta = (block, meta) => {
  const { type, output } = block

  const blockMappers = OUTPUT_NAMES_FROM_META_MAPPERS[type]

  if (!meta || !blockMappers) {
    return {}
  }

  return Object.entries(meta).reduce((acc, [key, value]) => {
    if (!blockMappers[key]) {
      return acc
    }
    const error = (value && value.error) || ''
    acc[output[0].id] = { name: blockMappers[key](value, meta, block), error }
    return acc
  }, {})
}

// for blocks where outputs names depends on dynamic inputs
export const changeOutputNamesFromInput = block => {
  const { type, output, input } = block

  const blockMapper = OUTPUT_NAMES_FROM_INPUT_MAPPERS[type]

  if (!blockMapper) {
    return {}
  }

  return {
    [output[0].id]: { name: blockMapper(input), error: '' }
  }
}

// some blocks (i.e. trigger block) has default outputs name which can't be changed by any operation
// and need to be defined when block is created
export const addDefaultOutputsNames = ({ outputsNames, outputs }) =>
  outputs.reduce(
    (acc, output) => {
      if (output.defaultPresentationName) {
        acc[output.id] = {
          name: output.defaultPresentationName,
          error: ''
        }
      }

      return acc
    },
    { ...outputsNames }
  )

export const updateOutputNames = ({ outputsNames, outputsHistory, outputs }) => {
  const blockIds = Object.keys(outputsHistory)

  return blockIds.reduce((acc, id) => {
    if (outputsNames[id]) {
      acc[id] = outputsNames[id]
    } else {
      const newOutput = outputs.find(el => el.id === id)

      if (newOutput) {
        acc[id] = {
          name: newOutput.defaultPresentationName,
          error: ''
        }
      }
    }

    return acc
  }, {})
}
