import { memoize } from 'helpers/common/memoize'
import type { ITeamMember, TTeamMemberMap } from 'features/team/team.types'

export const deriveMemoizedActiveTeamMemberList = memoize((memberList: ITeamMember[]) =>
  memberList.filter(member => !member.acl.tenant.isInactive)
) as (memberList: ITeamMember[]) => ITeamMember[]

export const deriveMemoizedActiveTeamMemberMap = memoize((memberList: ITeamMember[]) =>
  memberList.reduce(
    (result, member) => {
      if (member.acl.tenant.isInactive) return result

      result[member.userId] = member

      return result
    },
    {} as { [userId: ITeamMember['userId']]: ITeamMember }
  )
) as (memberList: ITeamMember[]) => { [userId: ITeamMember['userId']]: ITeamMember }

export const sortTeamMemberList = (
  memberList: ITeamMember[],
  sortBy: keyof Omit<ITeamMember, 'acl'> = 'username'
) => {
  return [...memberList].sort((m1, m2) => {
    if (!m1[sortBy] && m2[sortBy]) return 1
    if (m2[sortBy] && !m1[sortBy]) return -1
    if (!m2[sortBy] && !m1[sortBy]) return 0

    if (m1[sortBy].toLowerCase() < m2[sortBy].toLowerCase()) return -1
    if (m1[sortBy].toLowerCase() > m2[sortBy].toLowerCase()) return 1

    return 0
  })
}

export const mapTeamMembersByIds = (
  userIds: ITeamMember['userId'][],
  membersMap?: TTeamMemberMap
) => {
  if (!membersMap || !Object.keys(membersMap).length) {
    return userIds.map(id => ({
      userId: id,
      username: '-'
    })) as Pick<ITeamMember, 'userId' | 'username'>[]
  }

  return userIds.map(id => membersMap[id] || { userId: id, username: '-' })
}
