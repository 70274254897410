export const APP_BUILDER_TAB = {
  content: 'content',
  appStore: 'appStore',
  licenses: 'licenses'
}

export const PURCHASE_ITEM = {
  purchaseId: '',
  price: '',
  description: '',
  isDefault: false,
  isPromotionCodeEnabled: false,
  trialInfo: {
    trialPeriod: null,
    isTrialEnabled: false
  }
}

export const APP_DOWNLOAD_TEMPLATE_STATUS = {
  LOADING: 'LOADING',
  FAILED: 'FAILED',
  DONE: 'DONE'
}

export const INVALID_CHARS_FOR_PRICE = ['-', '+', 'e']
export const INVALID_CHARS_FOR_TRIAL = ['-', '+', 'e', '.']
