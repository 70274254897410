import {
  type TVariableConnection,
  EVariableConnectionObjectType
} from '../variableConnections.types'

type TGroupedConnections = {
  [key in EVariableConnectionObjectType]: TVariableConnection[]
}

const compareConnections = (connection: TVariableConnection) =>
  connection.object.type === EVariableConnectionObjectType.FILTER ? -1 : 1

export const groupConnectionsByType = (connections: TVariableConnection[]) =>
  connections.sort(compareConnections).reduce((grouped, connection) => {
    const connectionType = connection.object.type

    if (grouped[connectionType]) {
      grouped[connectionType].push(connection)
    } else {
      grouped[connectionType] = [connection]
    }

    return grouped
  }, {} as TGroupedConnections)
