// eslint-disable-next-line import/no-cycle
import { toggleFilesUploadingFlag, updateCurrentUploadingData, showToastMessage } from '../actions'
import messages from '../constants/messages'
import * as types from '../constants/actionTypes'

let FilesUploader // eslint-disable-line import/no-mutable-exports

class FilesUploaderDataProvider {
  constructor() {
    this._dispatch = null
    this.uploader = null
    this.callback = null

    this.queue = []
    this.index = -1

    this.canceler = null

    this.responses = []
  }

  setDispatch(dispatch) {
    this._dispatch = dispatch
  }

  setUploader(uploader) {
    this.uploader = uploader
  }

  setCallback(callback) {
    this.callback = callback
  }

  addToQueue(array) {
    this.queue = [...this.queue, ...array]

    const filesLength = this.queue.length
    this._dispatch(updateCurrentUploadingData({ filesLength }))
  }

  setCanceler(canceler) {
    this.canceler = canceler
  }

  setIndex(index) {
    this.index = index
  }

  setInitialResponse(response) {
    this.responses = response
  }

  async upload() {
    const dispatch = this._dispatch
    this.setIndex(this.index + 1)

    const file = this.queue[this.index]

    if (file) {
      const { promise, canceler } = await dispatch(this.uploader(file))
      this.setCanceler(canceler)

      dispatch(
        updateCurrentUploadingData({
          index: this.index,
          name: file.loader?.name || '',
          size: file.loader?.size || 0
        })
      )

      return promise.then(createFileResponse => {
        if (!createFileResponse.uploadingError) {
          this.responses.push({
            createFileResponse,
            fileData: {
              size: file.loader?.size || file.size || 0,
              fileName: file.loader?.name || '',
              fileUuid: file.uuid
            }
          })
        }
        this.upload()
      })
    }
    this.endUploading()

    return Promise.resolve()
  }

  cancel() {
    if (this.canceler) {
      this.canceler()
    }
    const count = this.queue.length - this.index
    this._dispatch(
      showToastMessage(
        {
          text: messages.CANCEL_FILE_UPLOADING(count)
        },
        types.TOAST_MESSAGE_TYPE,
        7000
      )
    )
    this.endUploading()
  }

  endUploading() {
    this._dispatch(toggleFilesUploadingFlag(false))
    this._dispatch(this.callback(this.responses))

    this._dispatch(
      updateCurrentUploadingData({
        index: -1,
        name: '',
        size: 0,
        filesLength: 0
      })
    )

    this.clear()
  }

  clear() {
    this._dispatch = null
    this.uploader = null
    this.callback = null

    this.queue = []
    this.index = -1

    this.canceler = null

    this.responses = []
  }
}

if (!FilesUploader) {
  FilesUploader = new FilesUploaderDataProvider()
}

export default FilesUploader
