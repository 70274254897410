import gql from 'graphql-tag'

const navItemFragmentName = 'NavItemFragment'

export const NavItemFragment = {
  name: navItemFragmentName,
  fragment: gql`
        fragment ${navItemFragmentName} on AppMenuItem  {
            id
            name
            posN
            posD
            groupId
            type
            isGroup
            isHomepage
            tenantId
            boardId
            cardUuid
            cardOpeningMode
            errorCode
            externalLink
            externalOpenMode
        }
    `
}

const appFragmentName = 'AppFragment'

export const AppFragment = {
  name: appFragmentName,
  fragment: gql`
        fragment ${appFragmentName} on App {
            tenantId
            isLocked
            name
            creationDate
            updatedAt
            logo
            boardIds
            homepage {
                tenantId
                boardId
                cardUuid
                cardOpeningMode
            }
        }
    `
}

const appStoreInfoHistoryFragmentName = 'AppStoreHistoryFragment'

export const AppStoreHistoryFragment = {
  name: appStoreInfoHistoryFragmentName,
  fragment: gql`
        fragment ${appStoreInfoHistoryFragmentName} on AppStoreVersion {
            id
            version
            whatsNew
            publishDate
        }
    `
}

const appStoreInfoFragmentName = 'AppStoreInfoFragment'

export const AppStoreInfoFragment = {
  name: appStoreInfoFragmentName,
  fragment: gql`
        fragment ${appStoreInfoFragmentName} on AppStoreInfo {
            id
            isPublished
            version
            industry
            categoryIds
            authorName
            authorEmail
            videoLink
            tags
            images {
                ... on AppStoreInfoSnapshotImage {
                    id
                    type
                    tenantId
                    boardId
                    cardUuid
                }
                ... on AppStoreInfoUploadImage {
                    id
                    type
                    url
                }
            }
            description
            whatsNew
            previousVersion: history(id: $versionId, size: $historySize) {...${AppStoreHistoryFragment.name}}
            order {
                categoryId
                position
            }
            fileTemplateInfo {
              logo {
                id
                url
              }
              file {
                id
                fileName
                size
                extension
                uploadDate
              }
              description
            }
            isPurchaseEnabled
            collectionIds
            isPremium
            purchaseInfo {
              purchaseId
              price
              description
              isDefault
              isPromotionCodeEnabled
              trialInfo {
                isTrialEnabled
                trialPeriod
              }
            }
        }
        ${AppStoreHistoryFragment.fragment}
    `
}

const publishedAppsFragmentName = 'PublishedAppsFragment'

export const PublishedAppsFragment = {
  name: publishedAppsFragmentName,
  fragment: gql`
        fragment ${publishedAppsFragmentName} on AppStoreVersion {
            id
            appStoreId
            appToAppStoreRelCount
            tenantId
            name
            version
            authorName
            authorEmail
            industry
            categoryIds
            description
            publishDate
            whatsNew
            appLogo
            boards {
                id
            }
            tags
            fileTemplateInfo {
                logo
                description
                file {
                  url
                  fileName
                  size
                  extension
                  uploadDate
                }
            }
            isPurchaseEnabled
            collectionIds
            isPremium
            purchaseInfo {
              purchaseId
              price
              description
              isDefault
              isPromotionCodeEnabled
              trialInfo {
                isTrialEnabled
                trialPeriod
              }
            }
        }
    `
}

const publishedAppFragmentName = 'PublishedAppFragment'

export const PublishedAppFragment = {
  name: publishedAppFragmentName,
  fragment: gql`
        fragment ${publishedAppFragmentName} on AppStoreVersion {
            id
            appStoreId
            appToAppStoreRelCount
            tenantId
            logo: appLogo
            name
            version
            authorName
            authorEmail
            industry
            categoryIds
            videoLink
            images
            description
            publishDate
            whatsNew
            tags
            fileTemplateInfo {
                logo
                description
                file {
                    url
                    fileName
                    size
                    extension
                    uploadDate
                }
            }
            isPurchaseEnabled
            collectionIds
            isPremium
            purchaseInfo {
              purchaseId
              price
              description
              isDefault
              isPromotionCodeEnabled
              trialInfo {
                isTrialEnabled
                trialPeriod
              }
            }
        }
    `
}
