export const setSectionsLoading = args => {
  const { sectionsLoading, cards, options = { replace: false } } = args

  return Object.entries(cards).reduce(
    (acc, [cardUuid, cardSectionsLoading]) => {
      if (acc[cardUuid]) {
        acc[cardUuid] = options?.replace
          ? { ...cardSectionsLoading }
          : { ...acc[cardUuid], ...cardSectionsLoading }

        return acc
      }

      acc[cardUuid] = cardSectionsLoading

      return acc
    },
    { ...sectionsLoading }
  )
}
