import InfoIcon from 'assets/images/icons/info.svg?react'
import DataIcon from 'assets/images/icons/queryBuilderWidget/ic_data_filled_white.svg?react'
import { Tooltip } from 'components/common/tooltip/tooltip'
import './ubf-coordinates-hint.scss'

type TProps = {
  className?: string
}

export const UbfCoordinatesHint = ({ className }: TProps) => (
  <Tooltip
    id="filter-rule-condition-ubf-coordinates-hint"
    placement="top"
    title={
      <span className="filter-rule-condition-ubf-coordinates-hint">
        Data contained by widgets is displayed in a&nbsp;
        <b>table</b>. To query this data, specify the&nbsp;
        <b>coordinates</b> of the desired entry.
        <hr />
        View the coordinates of widget data in the&nbsp;
        <b>
          <DataIcon className="icon" />
          Data Linking&nbsp;
        </b>
        dialog box.
      </span>
    }
  >
    <div className={className}>
      <InfoIcon className="icon" />
    </div>
  </Tooltip>
)
