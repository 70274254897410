import {
  FILTERS_ACCESSORY,
  FILTERS_OBJECTS,
  OPERATOR
} from '../../constants/filters/filtersConstants'
import messages from '../../constants/messages'

export const FILTERING_OBJECTS_OPTIONS = [
  {
    value: FILTERS_OBJECTS.ALL,
    text: messages.ALL
  },
  {
    value: FILTERS_OBJECTS.CARD,
    text: messages.CARDS
  },
  {
    value: FILTERS_OBJECTS.DATA_RECORD,
    text: messages.DATA_RECORDS
  },
  {
    value: FILTERS_OBJECTS.WIDGET,
    text: messages.WIDGETS
  }
]

export const FILTERS_ACCESSORY_OPTIONS = [
  {
    value: FILTERS_ACCESSORY.ALL,
    text: messages.ALL_FILTERS
  },
  {
    value: FILTERS_ACCESSORY.MY,
    text: messages.MY_FILTERS
  }
]

export const FILTERS_OBJECTS_OPTIONS = [
  {
    label: messages.CARDS,
    value: FILTERS_OBJECTS.CARD
  },
  {
    label: messages.WIDGETS,
    value: FILTERS_OBJECTS.WIDGET
  },
  {
    label: messages.DATA_RECORDS,
    value: FILTERS_OBJECTS.DATA_RECORD
  }
]

export const OPERATOR_TEXTS = {
  [OPERATOR.AND]: 'And',
  [OPERATOR.OR]: 'Or'
}
