import { useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'
import { matchPath, useLocation } from 'react-router-dom'
import { PATHS, PATHS_LIST } from 'constants/paths.constants'
import { navigateToHomePage } from 'helpers/routesHelpers'
import { getAreSubscriptionsLoaded, getOrgActiveCollections } from 'selectors/profile.selectors'

const AVAILABLE_ORGANIZATION_TABS_WITHOUT_SUBSCRIPTION = [
  'organization-settings',
  'billing-and-purchases'
]

export const useOrganizationSubscriptionAccess = shouldSkipSubscriptionCheck => {
  const activeSubscription = useSelector(getOrgActiveCollections)
  const areSubsLoaded = useSelector(getAreSubscriptionsLoaded)

  const location = useLocation()

  useLayoutEffect(() => {
    if (shouldSkipSubscriptionCheck || !areSubsLoaded || activeSubscription) return

    const currentRoute = PATHS_LIST.find(path =>
      matchPath(location.pathname, { path: path.routerPath, exact: true })
    )

    if (currentRoute?.id === PATHS.organizationAdministration.id) {
      const query = new URLSearchParams(location.search)

      if (
        query.get('tab') &&
        !AVAILABLE_ORGANIZATION_TABS_WITHOUT_SUBSCRIPTION.includes(query.get('tab'))
      ) {
        navigateToHomePage()
      }
    } else if (!currentRoute?.isAvailableWithoutSubscription) {
      navigateToHomePage()
    }
  }, [
    activeSubscription,
    location.pathname,
    location.search,
    areSubsLoaded,
    shouldSkipSubscriptionCheck
  ])
}
