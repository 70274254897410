import {
  EntitlementStatus,
  EntitlementType,
  QueryRequestOrderDirection
} from '_proto/entitlement/v1/entitlement.pb'

export const ENTITLEMENT_STATUSES = {
  unknown: {
    id: 'unknown',
    name: 'Unknown',
    grpcId: EntitlementStatus.ENT_STATUS_UNKNOWN
  },
  active: {
    id: 'active',
    name: 'Active',
    grpcId: EntitlementStatus.ENT_STATUS_ACTIVE
  },
  inactive: {
    id: 'inactive',
    name: 'Inactive',
    grpcId: EntitlementStatus.ENT_STATUS_INACTIVE
  }
}

export const ENTITLEMENT_STATUSES_LIST = Object.values(ENTITLEMENT_STATUSES)

export const ENTITLEMENTS_SORT_DIRECTIONS = {
  asc: {
    id: 'asc',
    grpcOrderDirectionId: QueryRequestOrderDirection.DirectionASC
  },
  desc: {
    id: 'desc',
    grpcOrderDirectionId: QueryRequestOrderDirection.DirectionDESC
  }
}

export const ENTITLEMENT_TYPES = {
  premium: {
    id: 'premium',
    name: 'Premium',
    grpcId: EntitlementType.ENT_TYPE_PREMIUM
  },
  templatesPro: {
    id: 'templatesPro',
    name: 'Templates Pro',
    grpcId: EntitlementType.ENT_TYPE_TEMPLATES_PRO
  },
  processPro: {
    id: 'processPro',
    name: 'Process Pro',
    grpcId: EntitlementType.ENT_TYPE_PROCESS_PRO
  }
}

export const ENTITLEMENT_TYPES_LIST = Object.values(ENTITLEMENT_TYPES)

export const ENTITLEMENT_ITEMS_TYPES = {
  app: {
    id: 'app',
    name: 'App'
  },
  collection: {
    id: 'collection',
    name: 'Collection'
  }
}

export const ENTITLEMENT_SOURCES = {
  manual: {
    id: 'manual',
    name: 'Manual'
  },
  api: {
    id: 'api',
    name: 'API'
  }
}

export const MANUAL_ENTITLEMENT_CUSTOMER_ID = 'SYSTEM'
