import { isEmptyObject } from 'helpers/common/commonHelpers'
import * as actions from '../constants/actionTypes'

export const setRibbonState = payload => (dispatch, getState) => {
  const {
    ribbon: { ribbonState }
  } = getState()

  // skip action dispatching when nothing was changed
  if (isEmptyObject(ribbonState) && isEmptyObject(payload)) {
    return
  }

  dispatch({ type: actions.SET_RIBBON_STATE, payload })
}

export const setRibbonCallback = payload => (dispatch, getState) => {
  const {
    ribbon: { ribbonCallback }
  } = getState()

  // skip action dispatching when nothing was changed
  if (!ribbonCallback && !payload) {
    return
  }

  dispatch({ type: actions.SET_RIBBON_CALLBACK, payload })
}
