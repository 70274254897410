import React from 'react'
import { Button } from '@praxie/shared'
import messages from 'constants/messages'
import ConnectionsDropdownContainer from 'containers/workflowBuilder/ConnectionsDropdownContainer'
import ConnectionsWarning from 'components/filters/filterConfiguration/ConnectionsWarning'
import { Error } from 'components/filters/connections/ConnectionsLoadingError'

export const FilterConnections = ({
  filterId,
  hasConnections,
  fetchConnections,
  connections,
  isLoading,
  isError,
  tenantId
}) => {
  if (!connections.length && hasConnections) {
    return (
      <>
        {isError && <Error className="connections-fetch-error" />}
        <Button
          className="load-connections"
          appearance="secondary"
          size="small"
          busy={isLoading}
          onClick={fetchConnections}
        >
          {messages.LOAD_CONNECTIONS}
        </Button>
      </>
    )
  }

  return (
    <>
      {isError && <Error className="connections-fetch-error" />}
      {!isLoading && hasConnections && <ConnectionsWarning />}
      <ConnectionsDropdownContainer
        tenantId={tenantId}
        connections={connections}
        isLoading={isLoading}
      />
      {!!filterId && (
        <Button
          className="reload-connections"
          appearance="secondary"
          size="small"
          title="Reload Connections"
          disabled={isLoading}
          onClick={fetchConnections}
        >
          <i className="up-font-ic-reset" />
        </Button>
      )}
    </>
  )
}
