export const workspaceMembersExtractor = ({ data }) => {
  const {
    organization: {
      users: { nodes: members, pageInfo, total, filterTotal }
    }
  } = data

  return {
    members,
    pageInfo,
    total,
    filterTotal
  }
}

export const workspaceMemberExtractor = ({ data }) => {
  const {
    organization: { user: member }
  } = data

  return { member }
}

export const workspaceManagersExtractor = ({ data }) => {
  const {
    organization: { organizationManagers: members }
  } = data

  return {
    members
  }
}

export const workspaceManagersToAssignExtractor = ({ data }) => {
  const {
    organization: { assigneeUsers: members }
  } = data

  return {
    members
  }
}

export const possibleSubordinatesExtractor = ({ data }) => {
  const {
    organization: {
      user: { possibleSubordinates: members }
    }
  } = data

  return {
    members
  }
}
