import { getWidgetsRequest } from 'api/bindCardAPI'
import { SECTION_STATUSES } from 'constants/detailedView/sectionsLoading'
import {
  filterSectionsToFetch,
  getInitialSectionsLoading,
  getSectionsLoading
} from 'helpers/detailedView/sectionsLoading'
import { cardSectionsLoadingSelector } from 'selectors/detailedViewSelectors'
import { getCardSectionCount } from 'features/cards/cards.helpers'
import * as actions from '../constants/actionTypes'
import { cardWidgetsQuery } from '../graphql/queries/cardQueries'
// eslint-disable-next-line import/no-cycle
import { appendCardWidgets } from './boardActions'
import { handleSectionLoaded } from '../helpers/detailedView/sectionsLoading'
import { getIsPDFGeneration } from '../selectors/detailedViewSelectors'

export const togglePresentationMode = payload => ({
  type: actions.TOGGLE_PRESENTATION_MODE,
  payload
})

export const toggleSlideShow = payload => ({
  type: actions.TOGGLE_SLIDE_SHOW,
  payload
})

export const setSectionsLoading = payload => ({
  type: actions.SET_SECTIONS_LOADING,
  payload
})

export const replaceSectionsLoading = payload => ({
  type: actions.REPLACE_SECTIONS_LOADING,
  payload
})

export const setSavingInProgress = payload => ({
  type: actions.SET_SAVING_IN_PROGRESS,
  payload
})

export const clearSectionsLoading = () => ({ type: actions.CLEAR_SECTIONS_LOADING })

export const addRenderedSection = payload => ({
  type: actions.ADD_RENDERED_SECTION,
  payload
})

export const clearRenderedSections = () => ({ type: actions.CLEAR_RENDERED_SECTIONS })

export const fetchWidgetsBySections = payload => async (dispatch, getState) => {
  const state = getState()

  const { tenantId, boardId, cardUuid, sections } = payload

  const sectionsLoading = cardSectionsLoadingSelector(cardUuid)(state)

  const sectionsToFetch = filterSectionsToFetch({ sections, sectionsLoading })

  // skip when no sections to fetch
  if (!sectionsToFetch?.length || !cardUuid) return

  dispatch(
    setSectionsLoading(
      getSectionsLoading({
        cardUuid,
        sections: sectionsToFetch,
        status: SECTION_STATUSES.LOADING
      })
    )
  )

  try {
    const { data: widgets } = await dispatch(
      getWidgetsRequest({
        tenantId,
        boardId,
        queries: [
          cardWidgetsQuery({
            card: { uuid: cardUuid },
            sections: sectionsToFetch
          })
        ]
      })
    )

    dispatch(appendCardWidgets({ cardUuid, widgets: widgets[cardUuid] }))

    dispatch(
      setSectionsLoading(
        getSectionsLoading({
          cardUuid,
          sections: sectionsToFetch,
          status: SECTION_STATUSES.LOADED
        })
      )
    )
  } catch (err) {
    console.error(err)

    dispatch(
      setSectionsLoading(
        getSectionsLoading({
          cardUuid,
          sections: sectionsToFetch,
          status: SECTION_STATUSES.ERROR
        })
      )
    )
  }
}

export function setPDFGeneration(payload) {
  return { type: actions.SET_PDF_GENERATION, payload }
}

export function setPDFWidgetGeneration(payload) {
  return { type: actions.SET_PDF_WIDGET_GENERATION, payload }
}

export const setCopyCardSnapshotInProgress = payload => ({
  type: actions.SET_COPY_CARD_SNAPSHOT_IN_PROGRESS,
  payload
})

export function setPDFGenerationProgress(payload) {
  return { type: actions.SET_PDF_GENERATION_PROGRESS, payload }
}

export const loadAllSections =
  ({ cardHeight, cardUuid }) =>
  async (dispatch, getState) => {
    const sectionsCount = getCardSectionCount(cardHeight)
    const range = {
      from: 0,
      to: sectionsCount - 1,

      [Symbol.asyncIterator]() {
        return {
          current: this.from,
          last: this.to,

          async next() {
            await handleSectionLoaded(this.current, getState, cardUuid)
            if (this.current <= this.last) {
              return { done: false, value: (this.current += 1) }
            }
            return { done: true }
          }
        }
      }
    }

    // eslint-disable-next-line no-restricted-syntax
    for await (const value of range) {
      const progress = Math.floor((value / sectionsCount).toFixed(2) * 100)
      if (progress > getState().detailed.pdfGenerationProgress) {
        dispatch(setPDFGenerationProgress(progress))
      }
    }

    if (!getIsPDFGeneration(getState())) {
      return Promise.reject()
    }

    return Promise.resolve()
  }

export const setIsDetailedViewModalReady = payload => (dispatch, getState) => {
  const isDetailedViewModalReady = getState().detailed.isDetailedViewModalReady

  if (isDetailedViewModalReady !== payload) {
    dispatch({
      type: actions.SET_DETAILED_VIEW_MODAL_READY,
      payload
    })
  }
}

export const setLoadedCardsSections = cards => dispatch => {
  const loadedCardsSections = Object.entries(cards).reduce((result, [cardId, widgets]) => {
    if (!widgets?.length) return result

    const loadedSection = widgets[0].section

    result[cardId] = { [loadedSection]: { status: SECTION_STATUSES.LOADED } }

    return result
  }, {})

  dispatch(replaceSectionsLoading(loadedCardsSections))
}

export const reloadCardLoadedSections = payload => (dispatch, getState) => {
  const state = getState()

  const cardToReload = {
    uuid: payload.cardUuid
  }

  const cardSectionsLoading = cardSectionsLoadingSelector(cardToReload.uuid)(state)
  const sectionsToReload = Object.keys(cardSectionsLoading)

  dispatch(replaceSectionsLoading(getInitialSectionsLoading([cardToReload])))
  dispatch(fetchWidgetsBySections({ ...payload, sections: sectionsToReload }))
}
