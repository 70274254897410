import * as actions from 'constants/actionTypes'
import { requestWrapper, url } from 'helpers/fetchHelpers'

export function workspacesRequestStart() {
  return { type: actions.WORKSPACES_REQUEST }
}

export function processWorkspacesRequest(payload) {
  if (!payload.text) delete payload.text
  return requestWrapper({
    rUrl: `${url}admin/organization/list`,
    type: 'GET',
    payload
  })
}

export function workspacesReceive(payload) {
  return { type: actions.WORKSPACES_RECEIVE, payload }
}

export function receiveWorkspacesErrorMessage(payload) {
  if (payload.statusCode === 403 || payload.statusCode === 401) {
    window.location.href = `${window.location.origin}/api/admin/auth`
  }
  return { type: actions.WORKSPACES_RECEIVE_ERROR, payload }
}

export function workspacesRequest(payload) {
  return dispatch => {
    dispatch(workspacesRequestStart())
    return processWorkspacesRequest(payload)
      .then(response => dispatch(workspacesReceive(response.data)))
      .catch(err => dispatch(receiveWorkspacesErrorMessage(err)))
  }
}
