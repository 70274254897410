import messages from '../../constants/messages'
import { EFieldKeys } from '../../constants/workflowBuilder/blocksFieldsKeys'

export const VERY_LEFT = 'veryLeft'
export const VERY_RIGHT = 'veryRight'
export const PREV_COLUMN = 'toLeftFromCurrent'
export const NEXT_COLUMN = 'toRightFromCurrent'
export const COLUMN_POSITION_FROM_LEFT = 'positionFromLeft'
export const COLUMN_POSITION_FROM_RIGHT = 'positionFromRight'

export const COLUMN_TYPES = {
  [VERY_LEFT]: { value: VERY_LEFT, label: messages.VERY_LEFT_POSITION },
  [VERY_RIGHT]: { value: VERY_RIGHT, label: messages.VERY_RIGHT_POSITION },
  [PREV_COLUMN]: { value: PREV_COLUMN, label: messages.LEFT_CURRENT_COLUMN },
  [NEXT_COLUMN]: { value: NEXT_COLUMN, label: messages.RIGHT_CURRENT_COLUMN },
  [COLUMN_POSITION_FROM_LEFT]: {
    value: COLUMN_POSITION_FROM_LEFT,
    label: messages.COLUMN_POSITION_FROM_LEFT
  },
  [COLUMN_POSITION_FROM_RIGHT]: {
    value: COLUMN_POSITION_FROM_RIGHT,
    label: messages.COLUMN_POSITION_FROM_RIGHT
  }
}

export const COLUMN_TYPES_OPTIONS = Object.values(COLUMN_TYPES)

export const validateMoveColumnBlock = ({ block }) => {
  const error = {}
  if (!block.input || !block.input[EFieldKeys.SOURCE_INPUT]) {
    error[EFieldKeys.SOURCE_INPUT] = messages.emptyField
    return { error }
  }

  if (
    block.meta[EFieldKeys.TYPE] === EFieldKeys.RELATIVE_COLUMN_NUMBER &&
    !block.meta[EFieldKeys.COLUMN_NUMBER]
  ) {
    error[EFieldKeys.COLUMN_NUMBER] = messages.emptyField
    return { error }
  }

  return { error }
}
