import React, { Component } from 'react'
import { connect } from 'react-redux'
import messages from 'constants/messages'
import {
  SORTING_DIRECTION,
  SORTING_FIELDS,
  FILTERS_ACCESSORY,
  FILTERS_OBJECTS,
  OPERATOR
} from 'constants/filters/filtersConstants'
import { getFiltersList, deleteFilter } from 'api/filtersAPI'
import * as actions from 'actions'
import FilterConfigurationModalContainer from 'containers/filters/filterConfiguration/FilterConfigurationModalContainer'
import FilterDeleteConfirmation from 'components/filters/FilterDeleteConfirmation'
import FilterSelector from 'components/filters/filterSelector/FilterSelector'
import { getTeamRole } from 'selectors'

const FILTERS_PER_PAGE = 5

class FilterSelectorContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isFiltersListLoading: false,
      isFilterPreviewLoading: false,
      filterPreview: null,
      filters: [],
      configurationModalState: {
        show: false,
        filter: {}
      },
      deleteConfirmationState: {
        show: false,
        filterUuid: null,
        connections: [],
        isLoading: false
      }
    }
  }

  componentDidMount() {
    const { selectedFilter, tenantId, teamId } = this.props
    if (selectedFilter) {
      this.getFilterPreview(selectedFilter)
    }
    this.props.getFiltersConfig({ tenantId })

    if (!teamId) {
      this.props.getTeamSettings({ tenantId })
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedFilter } = this.props

    if (prevProps.selectedFilter && !selectedFilter) {
      this.getFilterPreview(selectedFilter)
    }
  }

  onSearch = search => {
    const { tenantId, filterObject } = this.props

    this.toggleFiltersListLoading(true)

    const payload = {
      tenantId,
      query: {
        sortBy: SORTING_FIELDS.creationDate,
        sortDirection: SORTING_DIRECTION.DESC,
        accessory: FILTERS_ACCESSORY.ALL,
        search,
        object: filterObject,
        offset: 0,
        limit: FILTERS_PER_PAGE
      }
    }
    getFiltersList(payload)
      .then(response => {
        this.setState({
          filters: response.data.filters
        })
      })
      .catch(() =>
        this.setState({
          filters: []
        })
      )
      .finally(() => this.toggleFiltersListLoading(false))
  }

  getFilterPreview(filterUuid) {
    const { tenantId } = this.props

    if (!filterUuid) {
      this.setState({ filterPreview: null })

      return null
    }

    this.toggleFilterPreviewLoading(true)
    return this.props
      .getFilterData({ tenantId, filterUuid })
      .then(filterPreview => this.setState({ filterPreview }))
      .finally(() => this.toggleFilterPreviewLoading(false))
      .catch(err => console.error(err))
  }

  openFiltersList = () => {
    const { tenantId } = this.props
    const newPath = `/team-administration/${tenantId}?tab=filters`
    window.open(`${window.location.origin}${newPath}`, '_blank')
  }

  createFilter = () => {
    this.toggleFilterConfigurationModal(true)
  }

  editFilter = () => {
    this.toggleFilterConfigurationModal(true)
  }

  openDeleteConfirmation = uuid => {
    const { filterPreview } = this.state

    this.setState({
      deleteConfirmationState: {
        show: true,
        filterUuid: uuid,
        connections: filterPreview.connections || []
      }
    })
  }

  closeDeleteConfirmation = () => {
    this.setState({
      deleteConfirmationState: {
        show: false,
        filterUuid: null,
        connections: [],
        isLoading: false
      }
    })
  }

  toggleFilterConfigurationModal = show => {
    const { filterObject } = this.props
    const { filterPreview } = this.state

    if (show && !filterPreview) {
      this.setState({
        configurationModalState: {
          show,
          defaultObject: filterObject,
          filter: {
            filters: {
              rulesOperator: OPERATOR.OR,
              rules: []
            },
            object: filterObject || FILTERS_OBJECTS.WIDGET,
            name: '',
            connections: []
          }
        }
      })
      return
    }
    if (show && filterPreview) {
      this.setState({
        configurationModalState: {
          show,
          defaultObject: filterObject,
          filter: filterPreview
        }
      })
      return
    }

    if (filterPreview) {
      this.getFilterPreview(filterPreview.uuid)
    }

    this.setState({
      configurationModalState: {
        show: false,
        defaultObject: '',
        filter: {}
      }
    })
  }

  applyFilter = (filterUuid = null) => {
    this.props.changeFilter(filterUuid)
    this.getFilterPreview(filterUuid)
  }

  updateFilter = () => {
    const {
      filterPreview: { uuid }
    } = this.state
    this.props.changeFilter(uuid)
    this.getFilterPreview(uuid)
  }

  // delete filter without connections
  deleteFilter = () => {
    const {
      deleteConfirmationState: { filterUuid }
    } = this.state
    const { tenantId } = this.props

    this.setState(state => ({
      deleteConfirmationState: {
        ...state.deleteConfirmationState,
        isLoading: true
      }
    }))

    deleteFilter({ tenantId, uuid: filterUuid })
      .then(() => {
        this.applyFilter(null)
        this.toggleFilterConfigurationModal(false)
        this.closeDeleteConfirmation()
      })
      .catch(err => {
        console.error(err)
        this.props.showToastMessage({
          text: messages.CANT_DELETE_FILTER_WITH_CONNECTIONS,
          size: 'M'
        })
      })
  }

  toggleFiltersListLoading(state) {
    this.setState({ isFiltersListLoading: state })
  }

  toggleFilterPreviewLoading(state) {
    this.setState({ isFilterPreviewLoading: state })
  }

  render() {
    const {
      tenantId,
      placeholder,
      filtersConfig,
      tenantName,
      teamPermissions,
      currentUserId,
      teamRole,
      error
    } = this.props

    const {
      isFiltersListLoading,
      isFilterPreviewLoading,
      filterPreview,
      filters,
      configurationModalState,
      deleteConfirmationState
    } = this.state

    const canReadVariable = teamPermissions.readVariable
    const isFilterEditDisabled =
      !teamPermissions.updateFilter && currentUserId !== filterPreview?.owner.userId

    return (
      <>
        <FilterSelector
          isFilterPreviewLoading={isFilterPreviewLoading}
          isFiltersListLoading={isFiltersListLoading}
          createFilter={this.createFilter}
          editFilter={this.editFilter}
          clearFilter={this.applyFilter.bind(this, null)}
          filterPreview={filterPreview}
          placeholder={placeholder}
          filters={filters}
          onSearch={this.onSearch}
          openFiltersList={this.openFiltersList}
          applyFilter={this.applyFilter}
          tenantId={tenantId}
          isFilterEditDisabled={isFilterEditDisabled}
          error={error}
        />
        <FilterConfigurationModalContainer
          canApplyFilter={!filterPreview}
          canReadVariable={canReadVariable}
          filtersConfig={filtersConfig}
          configurationModalState={configurationModalState}
          toggleFilterConfigurationModal={this.toggleFilterConfigurationModal}
          deleteFilter={this.openDeleteConfirmation}
          tenantId={tenantId}
          tenantName={tenantName}
          applyFilter={this.applyFilter}
          updateFilter={this.updateFilter}
          teamRole={teamRole}
        />
        <FilterDeleteConfirmation
          tenantId={tenantId}
          deleteConfirmationState={deleteConfirmationState}
          onDeleteConfirm={this.deleteFilter}
          onDeleteCancel={this.closeDeleteConfirmation}
        />
      </>
    )
  }
}

const mapStateToProps = state => ({
  filtersConfig: state.filters.filtersConfig,
  tenantName: state.teamAdministration.currentTeam.name,
  teamPermissions: state.profile.teamSettings.teamPermissions,
  teamId: state.profile.teamSettings.id,
  currentUserId: state.profile.user.id,
  teamRole: getTeamRole(state)
})

const mapDispatchToProps = {
  showToastMessage: actions.showToastMessage,
  getFiltersConfig: actions.getFiltersConfig,
  getFilterData: actions.getFilterData,
  getTeamSettings: actions.getTeamSettings
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterSelectorContainer)
