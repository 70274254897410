import * as actions from '../constants/actionTypes'

export function toggleBoardSpinner(payload) {
  return { type: actions.TOGGLE_BOARD_SPINNER, payload }
}

export function toggleCardBuilderSpinner(payload) {
  return { type: actions.TOGGLE_CARD_BUILDER_SPINNER, payload }
}

export function toggleTopMenuLoader(payload) {
  return { type: actions.TOGGLE_TOP_MENU_LOADER, payload }
}

export function showBoardPageSpinner() {
  return { type: actions.SHOW_FULL_BOARD_PAGE_SPINNER }
}

export function toggleCardNameSpinner(payload) {
  return { type: actions.TOGGLE_CARD_NAME_SPINNER, payload }
}

export function toggleColumnLoading(payload) {
  return { type: actions.TOGGLE_COLUMN_LOADING, payload }
}

export function toggleNewColumnSpinner(payload) {
  return { type: actions.TOGGLE_COPYING_COLUMN_SPINNER, payload }
}

export function showModalWindowSpinner() {
  return { type: actions.SHOW_MODAL_WINDOW_SPINNER }
}

export function hideModalWindowSpinner() {
  return { type: actions.HIDE_MODAL_WINDOW_SPINNER }
}

export function toggleUnsavedChangesSpinner(payload) {
  return { type: actions.TOGGLE_UNSAVED_CHANGES_SPINNER, payload }
}

export function toggleTeamAdministrationPageSpinner(payload) {
  return { type: actions.TOGGLE_TEAM_ADMINISTRATION_PAGE_SPINNER, payload }
}

export function toggleCommentsSpinner(payload) {
  return { type: actions.TOGGLE_COMMENTS_SPINNER, payload }
}

export function toggleAuthLoader(payload) {
  return { type: actions.TOGGLE_AUTH_LOADER, payload }
}
