export const SET_APP = 'SET_APP'
export const CHANGE_APP = 'CHANGE_APP'

export const TOGGLE_APP_BUILDER_LOADER = 'TOGGLE_APP_BUILDER_LOADER'
export const TOGGLE_APP_SAVING = 'TOGGLE_APP_SAVING'

export const TOGGLE_HAS_CHANGES = 'TOGGLE_HAS_CHANGES'
export const TOGGLE_APP_BUILDER_CONFIRMATION = 'TOGGLE_APP_BUILDER_CONFIRMATION'
export const TOGGLE_APP_CREATION_MODE = 'TOGGLE_APP_CREATION_MODE'

export const SET_APP_BOARDS = 'SET_APP_BOARDS'
export const SET_APP_CARDS = 'SET_APP_CARDS'
export const CLEAR_ALL_APP_CARDS = 'CLEAR_ALL_APP_CARDS'
export const CLEAR_APP_CARDS = 'CLEAR_APP_CARDS'
export const TOGGLE_APP_CARDS_LOADING = 'TOGGLE_APP_CARDS_LOADING'

export const SET_APP_ERRORS = 'SET_APP_ERRORS'
export const CLEAR_APP_ERRORS = 'CLEAR_APP_ERRORS'

export const SET_APP_LOGO = 'SET_APP_LOGO'
export const SET_CLEAR_LOGO = 'SET_CLEAR_LOGO'

export const SET_NAV_ITEMS = 'SET_NAV_ITEMS'
export const ADD_NAV_ITEMS = 'ADD_NAV_ITEMS'
export const UPDATE_NAV_ITEMS = 'UPDATE_NAV_ITEMS'
export const DELETE_NAV_ITEMS = 'DELETE_NAV_ITEMS'

export const TOGGLE_NAV_ITEM_CONFIGURATION_MODAL = 'TOGGLE_NAV_ITEM_CONFIGURATION_MODAL'

export const CHANGE_APP_BUILDER_TAB = 'CHANGE_APP_BUILDER_TAB'

export const SET_APP_META = 'SET_APP_META'
export const SET_APP_META_PURCHASE_INFO = 'SET_APP_META_PURCHASE_INFO'
export const SET_APP_META_CONFIG = 'SET_APP_META_CONFIG'
export const CHANGE_APP_META = 'CHANGE_APP_META'

export const SET_APP_TEMPLATE_DOWNLOAD_PROGRESS = 'SET_APP_TEMPLATE_DOWNLOAD_PROGRESS'

export const SET_APP_META_ERRORS = 'SET_APP_META_ERRORS'
export const CLEAR_APP_META_ERRORS = 'CLEAR_APP_META_ERRORS'

export const TOGGLE_SNAPSHOT_MODAL = 'TOGGLE_SNAPSHOT_MODAL'

export const APP_META_IMAGES = {
  SET: 'SET_APP_META_IMAGES',
  ADD: 'ADD_APP_META_IMAGES',
  REMOVE: 'REMOVE_APP_META_IMAGES',
  CLEAR: 'CLEAR_APP_META_IMAGES'
}

export const TOGGLE_APP_PUBLISH = 'TOGGLE_APP_PUBLISH'
export const TOGGLE_APP_UNPUBLISH = 'TOGGLE_APP_UNPUBLISH'
export const TOGGLE_APP_PLACEMENT_MODAL = 'TOGGLE_APP_PLACEMENT_MODAL'

export const APP_META_TEMPLATE = {
  SET_FILE: 'APP_META_TEMPLATE_SET_FILE',
  DELETE_FILE: 'APP_META_TEMPLATE_DELETE_FILE',
  CHANGE_DESCRIPTION: 'APP_META_TEMPLATE_CHANGE_DESCRIPTION',
  SET_FILE_PREVIEW: 'APP_META_TEMPLATE_SET_FILE_PREVIEW',
  DELETE_FILE_PREVIEW: 'APP_META_TEMPLATE_DELETE_FILE_PREVIEW'
}
