export const WIDGET_MENU_CATEGORIES = {
  JIRA: 'jira',
  MEDIA: 'media',
  MANAGEMENT: 'management',
  ADVANCED: 'advanced',
  UPLOAD: 'upload',
  FORM: 'form'
}

export const SMART_LINK_MODES = {
  WEB_LINK: 'web-link',
  CARD_LINK: 'card-link',
  BOARD_LINK: 'board-link',
  OTHER_MODE: 'other-link'
}

export const OPEN_MODES = {
  CURRENT_CARD: 'current_card',
  DETAILED_VIEW: 'detailed_view',
  PRESENTATION_MODE: 'presentation_mode',
  FULLSCREEN_MODE: 'fullscreen_mode',
  APP_VIEW: 'app_view'
}

export const OPEN_MODES_LINK = {
  [OPEN_MODES.CURRENT_CARD]: '',
  [OPEN_MODES.DETAILED_VIEW]: 'board/',
  [OPEN_MODES.PRESENTATION_MODE]: 'board/',
  [OPEN_MODES.FULLSCREEN_MODE]: 'embed/',
  [OPEN_MODES.APP_VIEW]: 'app/'
}

export const BUTTON_OTHER_MODES = {
  BACK_MODE: 'back_mode',
  INVITE_MODE: 'invite_mode'
}

export const FORMULAS_PLUGIN = {
  sheetId: 0,
  namedExpressions: [
    {
      name: 'TRUE',
      expression: '=TRUE()'
    },
    {
      name: 'FALSE',
      expression: '=FALSE()'
    }
  ]
}

export const DATA_LINKING_OPERATION = {
  change: 'change',
  clear: 'clear'
}

export const GOOGLE = 'google'
export const OFFICE = 'office'

export const extensionsMap = {
  gdocs: GOOGLE,
  gsheets: GOOGLE,
  gslides: GOOGLE,
  onedocs: OFFICE,
  onesheets: OFFICE,
  oneslides: OFFICE
}
