import classNames from 'classnames'
import MUIMenu, { type MenuProps as MUIMenuProps } from '@mui/material/Menu'
import MUIMenuItem, { type MenuItemProps } from '@mui/material/MenuItem'

import './menu.scss'

export interface IMenuProps extends MUIMenuProps {}

export const Menu = ({ className, ...props }: IMenuProps) => (
  <MUIMenu
    className={classNames('praxie-menu', className)}
    PaperProps={{ className: 'praxie-menu-paper' }}
    MenuListProps={{ className: 'praxie-menu-list' }}
    {...props}
  />
)

export const MenuItem = ({ className, ...props }: MenuItemProps) => (
  <MUIMenuItem className={classNames('praxie-menu-item', className)} disableRipple {...props} />
)
