import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const RadioField = props => (
  <div className={classNames('radio-field', props.className)}>
    <input
      className="radio-custom"
      type="radio"
      id={props.id}
      ref={props.fieldRef}
      disabled={props.disabled}
      checked={props.checked}
      onChange={props.onChange}
      onClick={props.onClick}
    />
    <label htmlFor={props.id} className="radio-custom-label">
      {props.label}
    </label>
  </div>
)

RadioField.defaultProps = {
  fieldRef: null,
  className: '',
  disabled: false,
  onChange: null,
  onClick: null
}

RadioField.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  fieldRef: PropTypes.func
}

export default RadioField
