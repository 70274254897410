import gql from 'graphql-tag'
import { getFirstVisibleCardSection } from 'helpers/builderHelpers'

import CardFragment from '../fragments/cardFragment'

const makeWidgetsFilter = params => {
  const { sections, showOnBoardView, fromFirstVisibleSection } = params

  const filterArray = []

  if (typeof showOnBoardView === 'boolean') {
    filterArray.push(`showOnBoardView: ${showOnBoardView}`)
  }
  if (typeof fromFirstVisibleSection === 'boolean') {
    filterArray.push(`fromFirstVisibleSection: ${fromFirstVisibleSection}`)
  }
  if (Array.isArray(sections)) {
    filterArray.push(`sections: [${sections.join()}]`)
  }
  if (filterArray.length) {
    return `(filter: { ${filterArray.join(' ')} })`
  }
  return ''
}

export const cardWidgetsQuery = ({ card, sections, showOnBoardView }) => {
  const { uuid, height, hiddenSections } = card

  const uuidAlias = uuid.replace(/-/gi, '_')
  const filterString = makeWidgetsFilter({
    sections: sections || [getFirstVisibleCardSection(height, hiddenSections)],
    showOnBoardView
  })
  return `
        card${uuidAlias}: card(uuid: "${uuid}") {
            uuid
            widgets: widgetsJSON${filterString}
        }
    `
}

export const getWidgetsQuery = queries =>
  gql`
        query getWidgetsQuery ($organizationId: ID!, $tenantId: ID!, $boardId: ID!) {
            organization(id: $organizationId) {
                tenant(id: $tenantId) {
                    board(id: $boardId) {
                        ${queries.join('')}
                    }
                }
            }
        }
    `

export const getCardDetailsQuery = (expand, filters, options = {}) => {
  const filtersString = {
    widgetsJSON: makeWidgetsFilter(filters.widgetsJSON || {})
  }

  const calledFrom = options.calledFrom ? `_${options.calledFrom}` : ''

  return gql`
        query getCardDetails${calledFrom} ($organizationId: ID!, $tenantId: ID!, $boardId: ID!, $cardUuid: ID!) {
            organization(id: $organizationId) {
                tenant(id: $tenantId) {
                    board(id: $boardId) {
                        card(uuid: $cardUuid) {
                            ...${CardFragment.name}
                        }
                    }
                }
            }
        }
        ${CardFragment.fragment(expand, filtersString)}
    `
}

export const getCardSectionDataQuery = () => {
  return gql`
    query getCardDetails($organizationId: ID!, $tenantId: ID!, $boardId: ID!, $cardUuid: ID!) {
      organization(id: $organizationId) {
        tenant(id: $tenantId) {
          board(id: $boardId) {
            card(uuid: $cardUuid) {
              height
              hiddenSections
            }
          }
        }
      }
    }
  `
}

export const getCardLogsQuery = () => {
  return gql`
    query getLogsPage(
      $organizationId: ID!
      $tenantId: ID!
      $boardId: ID!
      $cardUuid: ID!
      $offset: Int = 0
      $limit: Int = 10
    ) {
      organization(id: $organizationId) {
        tenant(id: $tenantId) {
          board(id: $boardId) {
            card(uuid: $cardUuid) {
              logs(offset: $offset, limit: $limit) {
                logEntries {
                  logId
                  entityType
                  type
                  timestamp
                  userId
                  data {
                    cardId
                  }
                }
                count
              }
            }
          }
        }
      }
    }
  `
}

export const getCardsWithActionsQuery = groups => {
  return gql`
      query getCardsActions(
        $organizationId: ID!
        ${groups
          .map(
            (_, index) => `
          $tenantId${index + 1}: ID!
          $boardId${index + 1}: ID!
          $cardsUuids${index + 1}: [ID!]
        `
          )
          .join('\n')}
      ) {
        ${groups
          .map(
            (group, index) =>
              `group${index + 1}: organization(id: $organizationId) {
               tenant(id: $tenantId${index + 1}) {
                 board(id: $boardId${index + 1}) {
                   cards(uuids: $cardsUuids${index + 1}) {
                     uuid
                     boardId
                     tenantId
                     isActionsEnabled
                     actions {
                       actionName
                       workflowName
                       isVisible
                     }
                   }
                 }
               }
             }
            `
          )
          .join('\n')}
      }
    `
}
