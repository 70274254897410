import { Calendar as BaseCalendar } from 'react-date-range'
import './calendar.scss'

type TProps = {
  date: Date | null
  onChange: (date: Date) => void
}
export const Calendar = ({ date, onChange }: TProps) => {
  return (
    <div className="praxie-calendar">
      {/*@ts-expect-error*/}
      <BaseCalendar
        date={date}
        monthDisplayFormat="MMMM YYYY"
        showDateDisplay={false}
        showPreview={false}
        direction="horizontal"
        onChange={onChange}
      />
    </div>
  )
}
