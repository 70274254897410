const detailed = {
  isPresentationModeEnabled: false,
  isSlideshowPlaying: false,
  sectionsLoading: {},
  renderedSections: [],
  isDetailedViewModalReady: false,
  isPDFGeneration: false,
  pdfGenerationProgress: 0,
  copyCardSnapshotInProgress: false,
  isSavingInProgress: false,
  isWidgetPDFGeneration: false
}

export default detailed
