import messages from 'constants/messages'
import { BLOCKS_VALIDATION_LIST } from 'constants/workflowBuilder/blocksValidationList'

const hasTrulyProps = object => object && Object.values(object).some(prop => !!prop)

export const isBlockHasStaticErrors = block => hasTrulyProps(block.error)

export const isBlockHasWarns = block => hasTrulyProps(block.warn)

const mandatoryValidation = value => {
  if (Array.isArray(value) && !value.length) {
    return messages.emptyField
  }
  if (typeof value === 'string' && !value.trim()) {
    return messages.emptyField
  }
  if (!value) {
    return messages.emptyField
  }
  return ''
}

export const validateBlock = ({ block, outputs, workflowBlocksMap }) => {
  const validationObject = BLOCKS_VALIDATION_LIST[block.type] || {}
  if (typeof validationObject === 'function') {
    const { error = {}, warn = {} } = validationObject({ block, outputs, workflowBlocksMap })

    return { error, warn }
  }

  const error = {}
  Object.keys(validationObject).forEach(propKey => {
    Object.keys(validationObject[propKey]).forEach(key => {
      if (validationObject[propKey][key].mandatory) {
        error[key] = mandatoryValidation(block[propKey] && block[propKey][key])
      }
    })
  })

  return { error, warn: {} }
}

export const checkStaticErrors = blocksMap =>
  Object.values(blocksMap).some(block => isBlockHasStaticErrors(block))

const getBlockError = ({ block, invalidOutputs }) => {
  let error = null
  invalidOutputs.forEach(outputId => {
    const invalidOutput = block.output.find(item => item.id === outputId)
    if (invalidOutput) {
      error = { type: invalidOutput.type, index: block.index, name: block.name }
    } else {
      Object.entries(block.input).forEach(([key, inputId]) => {
        if (inputId === outputId) {
          const input = block.inputDefinition[key]
          error = { type: input.type, index: block.index, name: block.name }
        }
      })
    }
  })

  return error
}

export const getInvalidBlocks = ({ workflowBlocksMap, invalidOutputs }) => {
  const invalidBlocks = {}
  if (invalidOutputs.length) {
    Object.entries(workflowBlocksMap).reduce((acc, [blockId, block]) => {
      if (!invalidBlocks[blockId]) {
        const error = getBlockError({ block, invalidOutputs })
        if (error) {
          invalidBlocks[blockId] = error
        }
      }
      return acc
    }, [])
  }
  return invalidBlocks
}

// eslint-disable-next-line func-names
export const ValidationException = function (error) {
  this.error = error
}

export const clearBlockError = (error, field) =>
  Object.keys(error)
    .filter(key => key !== field)
    .reduce((acc, key) => {
      acc[key] = error[key]
      return acc
    }, {})
