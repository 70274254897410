import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'

import PlainInput from 'components/fields/PlainInput'

import 'scss/ribbonTools/ribbon-input-field.scss'

const RibbonPlainInput = React.forwardRef((props, ref) => {
  const {
    id,
    name,
    className,
    defaultValue,
    value,
    placeholder,
    units,
    title,
    disabled,
    maxLength,
    onBlur,
    onFocus,
    onChange,
    onKeyPress,
    onKeyUp
  } = props

  return (
    <div className="ribbon-input-field-wrapper">
      <PlainInput
        id={id}
        ref={ref}
        name={name}
        className={classNames('ribbon-input-field', {
          [className]: className
        })}
        maxLength={maxLength}
        defaultValue={defaultValue}
        value={value}
        placeholder={placeholder}
        title={title}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyPress={onKeyPress}
        onKeyUp={onKeyUp}
      />
      {units && <div className="units">{units}</div>}
    </div>
  )
})

RibbonPlainInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  placeholder: PropTypes.string,
  units: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  onKeyUp: PropTypes.func
}

RibbonPlainInput.defaultProps = {
  id: undefined,
  name: undefined,
  value: undefined,
  defaultValue: undefined,
  className: '',
  placeholder: '',
  units: '',
  title: '',
  disabled: false,
  maxLength: 100,
  onChange: null,
  onFocus: null,
  onBlur: null,
  onKeyPress: null,
  onKeyUp: null
}

export default RibbonPlainInput
