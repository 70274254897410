import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { getCapitalLetter } from 'helpers/common/commonHelpers'
import { getImageUrl } from 'helpers/files/filesHelpers'
import '../../scss/common/user-logo.scss'

const Avatar = props => {
  const {
    isAvatarLoadingFailed,
    isOrganizationPlaceholder,
    avatarImage,
    username,
    onAvatarLoadingError
  } = props

  if (avatarImage && !isAvatarLoadingFailed) {
    return (
      <img
        src={getImageUrl(avatarImage)}
        alt="avatar"
        className="user-avatar"
        onError={onAvatarLoadingError}
        data-html2canvas-ignore
      />
    )
  }

  if (isOrganizationPlaceholder) {
    return <i className="icon icon_workspace" />
  }

  return <span>{getCapitalLetter(username)}</span>
}

class UserLogo extends React.Component {
  state = {
    isAvatarLoadingFailed: false
  }

  render() {
    const { className, size, username, avatarImage, isOrganizationPlaceholder } = this.props

    return (
      <div
        className={classNames('user-logo circle', size, className, {
          'organization-logo': !avatarImage && isOrganizationPlaceholder
        })}
      >
        <Avatar
          avatarImage={avatarImage}
          username={username}
          isOrganizationPlaceholder={isOrganizationPlaceholder}
          isAvatarLoadingFailed={this.state.isAvatarLoadingFailed}
          onAvatarLoadingError={() => this.setState({ isAvatarLoadingFailed: true })}
        />
      </div>
    )
  }
}

UserLogo.propTypes = {
  className: PropTypes.string,
  isOrganizationPlaceholder: PropTypes.bool,
  size: PropTypes.oneOf([
    'extra-small-20px',
    'extra-small-24px',
    'extra-small',
    'small',
    '',
    'big',
    'extra-big'
  ]),
  username: PropTypes.string,
  avatarImage: PropTypes.string
}

UserLogo.defaultProps = {
  className: '',
  isOrganizationPlaceholder: false,
  size: '',
  username: '',
  avatarImage: ''
}

export default UserLogo
