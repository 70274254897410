import { Component, cloneElement } from 'react'
import { findDOMNode } from 'react-dom'

import interact from 'interactjs'

export default class Interactable extends Component {
  componentDidMount() {
    // eslint-disable-next-line react/no-find-dom-node
    this.interact = interact(findDOMNode(this.node))
    this.setInteractions()
  }

  componentDidUpdate() {
    this.updateInteractions()
  }

  componentWillUnmount() {
    this.interact.unset()
  }

  setInteractions() {
    const { draggable, resizable, draggableOptions, resizableOptions } = this.props

    this.interact.styleCursor(false)

    if (draggable) {
      this.interact.draggable(draggableOptions)
    } else {
      this.interact.draggable({
        enabled: false
      })
    }

    if (resizable) {
      this.interact.resizable(resizableOptions)
    } else {
      this.interact.resizable({
        enabled: false
      })
    }
  }

  updateInteractions() {
    const { draggable, resizable } = this.props

    this.interact.draggable({
      enabled: draggable
    })
    this.interact.resizable({
      enabled: resizable
    })
  }

  render() {
    return cloneElement(this.props.children, {
      ref: node => {
        this.node = node
      },
      draggable: false
    })
  }
}

Interactable.defaultProps = {
  draggable: false,
  resizable: false,
  draggableOptions: {},
  resizableOptions: {}
}
