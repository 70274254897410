import { getSuggestions } from 'api/filtersAPI'
import { getVariablesSuggestions } from 'api/variablesAPI'
import { searchEntities } from 'api/workflowAPI'

export const loadSuggestion = args => {
  const { type, tenantId, inputValue, getSuggestionsRequest = getSuggestions } = args

  const minInputLength = 3

  const value = inputValue ? inputValue.trim() : ''

  if (!value || value.length < minInputLength) {
    return Promise.resolve([])
  }

  return getSuggestionsRequest({
    type,
    tenantId,
    query: value
  })
    .then(response => response.data.map(label => ({ label, value: label })))
    .catch(err => console.error(err))
}

export const loadSearchSuggestion = args => {
  const { type, tenantId, inputValue, getSuggestionsRequest = searchEntities } = args

  const minInputLength = 2

  const text = inputValue ? inputValue.trim() : ''

  if (text && text.length < minInputLength) {
    return Promise.resolve([])
  }

  const payload = text ? { text } : {}

  return getSuggestionsRequest({
    type,
    tenantId,
    payload
  })
    .then(response => response.data.result)
    .catch(err => console.error(err))
}

export const loadVariableSuggestion = async ({ tenantId, inputValue }) => {
  const minInputLength = 3

  const value = inputValue ? inputValue.trim() : ''

  if (!value || value.length < minInputLength) {
    return null
  }

  try {
    const variables = await getVariablesSuggestions({ tenantId, query: inputValue })

    return variables.data
  } catch (err) {
    console.error(err)

    return []
  }
}
