import client, { batchClient } from '../client'
import getGqlQuery from './getGqlQuery'
import { handleError } from './errorHandler'

/**
 * GraphQl query wrapper. This resolves a single query according to the options
 * specified and returns a Promise which is either resolved with the resulting
 * data or rejected with an error.
 * Apollo Client also distinguishes between two kinds of errors in the GraphQL
 * response— graphQLErrors and networkError.
 * networkError: Errors that are thrown outside of your resolvers. If networkError
 * is present in the response, it means entire query was rejected, and therefore
 * no data was returned.
 * graphQLErrors: Any error that is thrown within resolvers.
 */
const graphqlRequestWrapper = args => {
  const {
    query,
    variables,
    errorPolicy = 'none',
    fetchPolicy = 'network-only',
    useBatch = false
  } = args
  const apiClient = useBatch ? batchClient : client
  return new Promise((resolve, reject) => {
    apiClient
      .query({
        query: getGqlQuery(query),
        fetchPolicy,
        variables,
        errorPolicy
      })
      .then(response => {
        const { errors, data } = response
        const errorData = errors && handleError({ graphQLErrors: errors })
        if (errorData && errorData.errorCode === 401) {
          reject(errorData)
        }
        resolve({ errors, data })
      })
      .catch(response => {
        const result = handleError(response)
        reject(result)
      })
  })
}

export default graphqlRequestWrapper
