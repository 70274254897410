import { generateGUID } from 'helpers/common/commonHelpers'

export const EXTERNAL_OPEN_MODES = {
  NEW_TAB: '_blank',
  SAME_TAB: ''
}

export const EXTERNAL_OPEN_MODES_SELECT_OPTIONS = [
  {
    label: 'Same tab',
    value: EXTERNAL_OPEN_MODES.SAME_TAB
  },
  {
    label: 'New tab',
    value: EXTERNAL_OPEN_MODES.NEW_TAB
  }
]

export const createNewNavItem = args => {
  const {
    name = '',
    posN,
    posD,
    type = 'board',
    groupId = null,
    isGroup = false,
    isHomepage = true
  } = args

  return {
    id: generateGUID(),
    name,
    posN,
    posD,
    groupId,
    type,
    isGroup,
    isHomepage,
    externalLink: '',
    externalOpenMode: EXTERNAL_OPEN_MODES.NEW_TAB,
    tenantId: null,
    boardId: null,
    cardUuid: null,
    cardOpeningMode: null,
    errorCode: null
  }
}

export const deleteEmptyGroups = navItems => {
  const groupsWithItems = navItems.reduce((acc, navItem) => {
    if (navItem.groupId) {
      acc[navItem.groupId] = true
    }

    return acc
  }, {})

  return navItems.filter(navItem => {
    if (navItem.isGroup) {
      return !!groupsWithItems[navItem.id]
    }

    return true
  })
}
