import gql from 'graphql-tag'

const LogicalRuleConnectionObjectFragmentName = 'LogicalRuleConnectionObjectFragment'

export const LogicalRuleConnectionObjectFragment = {
  name: LogicalRuleConnectionObjectFragmentName,
  fragment: gql`
        fragment ${LogicalRuleConnectionObjectFragmentName} on LogicalRuleConnectionObject {
            logicalRuleName
            tenantId
            type
        }
    `
}
