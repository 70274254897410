import { createSelector } from 'reselect'
import { ECollectionStatus } from 'constants/appStore/appStore.types'
import { ENTITLEMENT_TYPES } from 'features/entitlements/entitlements.constants'
import { ORG_ROLES_MAP } from 'helpers/rolesHelpers'

export const getProfile = state => state.profile
export const getCurrentUser = state => getProfile(state).user
export const getOrganizations = state => getProfile(state).organizations
export const getActiveOrganization = state => getProfile(state).activeOrganization
export const getBoardMenu = state => getProfile(state).boardMenu
export const getTeamSettings = state => getProfile(state).teamSettings
export const getTeamPermissions = state => getTeamSettings(state).teamPermissions
export const getHomePageOpenedItems = state => getCurrentUser(state).openedItems
export const getCurrentUserOrgId = state => getCurrentUser(state).organizationId

export const getBoardMenuTenants = state => getBoardMenu(state).tenants
export const getBoardMenuTenant = tenantId => state =>
  getBoardMenuTenants(state).find(tenant => tenant.tenantId === tenantId)
export const getBoardMenuApps = state => getBoardMenu(state).apps
export const getBoardMenuApp = appId => state =>
  getBoardMenuApps(state).find(app => app.id === appId)
export const getBoardMenuBoards = state => getBoardMenu(state).boards

export const getOrgSubscriptions = state => getActiveOrganization(state).subscriptions
export const getAreSubscriptionsLoaded = state =>
  getActiveOrganization(state).areSubscriptionsLoaded
export const getOrgCollections = state => getActiveOrganization(state).collections
export const getTeamRole = state => getTeamSettings(state).role
export const getTaskDueSoonThreshold = state => getTeamSettings(state).taskDueSoonThreshold
export const getOrganizationRole = state => getActiveOrganization(state).organizationRole
export const getNewImportedApps = state => getProfile(state).newImportedApps

export const getTeamsThatCanImportBundle = createSelector(getBoardMenuTenants, teams =>
  teams.filter(team => team.accessInfo?.permissions?.importBundle)
)

// Figure out workspace subscription. Trying to find the most valuable.
// Process Pro > Templates Pro > Premium.
// If workspace has no subs at all, treat workspace as locked.
export const getOrgActiveCollections = createSelector(getOrgSubscriptions, orgSubscriptions => {
  if (!orgSubscriptions?.length) return null

  return orgSubscriptions.reduce((result, sub) => {
    // Filter out individual app subscription.
    if (sub.appStoreIds?.length) return result

    sub?.collectionIds.forEach(collectionId => {
      const currentCollectionSubscription = result[collectionId]

      if (currentCollectionSubscription) {
        if (currentCollectionSubscription === ENTITLEMENT_TYPES.templatesPro.id) {
          result[collectionId] = sub
        }
      } else {
        result[collectionId] = sub
      }
    })

    return result
  }, {})
})

export const getAvailableCollections = createSelector(
  [getOrgSubscriptions, getOrgCollections],
  (orgSubscriptions, orgCollections) => {
    if (!orgCollections?.length) return null

    const collectionIds = orgSubscriptions?.reduce((set, sub) => {
      if (sub.collectionIds.length) {
        sub.collectionIds.forEach(id => set.add(id))
      }

      return set
    }, new Set())

    return orgCollections.filter(col => {
      return (
        col.status === ECollectionStatus.active || Array.from(collectionIds || []).includes(col.id)
      )
    })
  }
)

export const getAvailableNotArchivedCollections = state =>
  getAvailableCollections(state)?.filter(col => col.status !== ECollectionStatus.archived) || []

export const getIsOrgAdmin = createSelector(
  getOrganizationRole,
  role => role === ORG_ROLES_MAP.ORGANIZATION_ADMIN
)

export const getIsLastSubscription = createSelector(
  getOrgSubscriptions,
  subscriptions => subscriptions?.length === 1
)

export const getLastTrialSubscription = createSelector(getOrgSubscriptions, subscriptions => {
  if (subscriptions?.length === 1 && !!subscriptions?.[0].isHasTrialPeriodEnd) {
    return subscriptions?.[0]
  }

  return null
})

export const getOrgSubscriptionsMapByAppId = createSelector(
  getOrgSubscriptions,
  subscriptions =>
    subscriptions?.reduce((result, sub) => {
      if (sub.appStoreIds.length) {
        sub.appStoreIds.forEach(appStoreId => (result[appStoreId] = sub))
      }
      return result
    }, {}) || {}
)
