import type { PropsWithChildren } from 'react'
import BoardsBundleIcon from 'assets/images/icons/ic_boards_bundle.svg?react'
import messages from 'constants/messages'
import { type IJob, EJobStatuses } from 'features/jobs/jobs.types'
import CircleSpinner from 'components/spinners/CircleSpinner'

const getBundleName = (bundleName: string) => {
  if (!bundleName) return ''
  const nameMatch = bundleName.match(/(?:_uuid_)[^\\]*\.(\w+)$/)
  return nameMatch?.[0] ? nameMatch[0].replace('_uuid_', '') : bundleName
}

const CompleteIndicator = () => <span className="blue-link static">{messages.IMPORTED}</span>

type TProps = { job: IJob }

export const ImportIndicatorItem = ({ job, children }: PropsWithChildren<TProps>) => (
  <div className="board_item">
    <div className="board_data">
      <BoardsBundleIcon />
      <div className="board_content">
        <span className="board_name bold">
          {getBundleName(job.data.presentationBundleName || job.data.bundleName)}
        </span>
        {job.status === EJobStatuses.ERROR && (
          <span className="error-text">{messages.OPERATION_FAILED}</span>
        )}
      </div>
    </div>
    <span className="board_progress">
      {job.status !== EJobStatuses.DONE && job.status !== EJobStatuses.ERROR && (
        <CircleSpinner className="copied-board__spinner" />
      )}
      {job.status === EJobStatuses.DONE && (children || <CompleteIndicator />)}
    </span>
  </div>
)
