import { useCallback, useMemo, useState } from 'react'
import classNames from 'classnames'
import { useMount } from 'react-use'
import { useParams } from 'react-router-dom'
import { processWidgetLinkingDataRequest } from 'api/widgetAPI'
import { getQueryWidgetsFromCard } from 'api/cardAPI'
import { WidgetPickerDropdown } from 'components/widgetPickerDropdown/widgetPickerDropdown'
import { type IWidgetPickerItem } from 'components/widgetPickerDropdown/widgetPickerDropdown.types'
import { type TQueryWidget } from '../../dictionariesSettings.types'
import { useDropdownContentSetupStore } from '../dropdownContentSetup.store'
import './dropdown-query-selector.scss'

type TState = {
  queryWidgets: Array<{
    board: { boardName: string; boardId: string }
    widgets: IWidgetPickerItem[]
  }>
}

type TProps = {
  isDisabled: boolean
}

export const DropdownQuerySelector = ({ isDisabled }: TProps) => {
  const [queryWidgets, setQueryWidgets] = useState<TState['queryWidgets']>([])
  const [queryWidgetError, setQueryWidgetError] = useState(false)

  const selectedQueryId = useDropdownContentSetupStore(state => state.selectedQueryId)
  const fetchQueryOptions = useDropdownContentSetupStore(state => state.fetchQueryOptions)
  const isQueryLoading = useDropdownContentSetupStore(state => state.isQueryLoading)
  const errors = useDropdownContentSetupStore(state => state.errors)

  const { tenantId, boardId, cardID }: { tenantId?: string; boardId?: string; cardID?: string } =
    useParams()

  const fetchSelectedQueryWidget = useCallback(async () => {
    if (!selectedQueryId) return Promise.resolve([])

    const { data } = (await processWidgetLinkingDataRequest({
      tenantId,
      boardId,
      cardID,
      widgetID: selectedQueryId,
      isRestrictByTenant: true
    })) as { data: TState['queryWidgets'] }

    return data
  }, [tenantId, boardId, cardID, selectedQueryId])

  const fetchQueryWidgets = useCallback(
    async (_: string, isForceRequest?: boolean) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      const { data } = (await getQueryWidgetsFromCard({
        tenantId,
        boardId,
        cardUuid: cardID
      })) as { data: IWidgetPickerItem[] }

      if (!data.length) return []

      const fetchedQueryWidgets = { board: { boardId: boardId!, boardName: '' }, widgets: data }

      if (!isForceRequest) setQueryWidgets([fetchedQueryWidgets])

      return [fetchedQueryWidgets]
    },
    [tenantId, boardId, cardID]
  )

  useMount(() => {
    if (!selectedQueryId) return

    useDropdownContentSetupStore.setState({ isQueryLoading: true })

    void Promise.all([fetchSelectedQueryWidget(), fetchQueryWidgets('', true)])
      .then(([currentWidgetResponse, queryWidgetsResponse]) => {
        if (!currentWidgetResponse.length) setQueryWidgetError(true)

        setQueryWidgets([...queryWidgetsResponse])
      })
      .finally(() => useDropdownContentSetupStore.setState({ isQueryLoading: false }))
  })

  const onChangeQueryValue = (queryId: string) => {
    useDropdownContentSetupStore.setState({
      selectedQueryId: queryId,
      errors: { query: '', isValid: true }
    })

    setQueryWidgetError(false)
  }

  const selectedWidget = useMemo(() => {
    let result

    queryWidgets.some(
      ({ widgets }) => (result = widgets.find(widget => widget.widgetUuid === selectedQueryId))
    )

    if (result) void fetchQueryOptions(result)

    return result
  }, [queryWidgets, fetchQueryOptions, selectedQueryId]) as TQueryWidget | undefined

  return (
    <div className={classNames('dropdown-query-selector', { disabled: isDisabled })}>
      <span className="query-picker-title">Select query from this card for dropdown options</span>
      <WidgetPickerDropdown
        options={queryWidgets}
        selectedWidget={(selectedWidget || {}) as IWidgetPickerItem}
        placeholder="Select widget to link"
        hasWidgetError={queryWidgetError}
        error={!!errors?.query}
        clearWidget={() => onChangeQueryValue('')}
        searchDataWidgets={fetchQueryWidgets}
        selectWidget={({ widgetUuid }) => onChangeQueryValue(widgetUuid)}
        isWidgetLoading={isQueryLoading}
        isSearchable={false}
        shouldOpenAfterMount={false}
      />
      {!!errors?.query && <div className="error-message">{errors.query}</div>}
    </div>
  )
}
