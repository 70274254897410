import { useSelector } from 'react-redux'
import { getCurrentUser } from 'selectors'
import { CreateCommandRequest } from '_proto/dictionary/v1/dictionary.pb'
import { type TDictionaryModalData } from '../../dictionariesSettings.types'
import { DictionariesGrpcService } from '../../dictionaries.grpc.service'
import { DictionaryModal } from '../../dictionaryModal/dictionaryModal'
import { useDropdownContentSetupStore } from '../dropdownContentSetup.store'

type TProps = {
  tenantId: string
}

export const CreateDictionaryModal = ({ tenantId }: TProps) => {
  const { id: userId } = useSelector(getCurrentUser) as { id: string }

  const isDictionaryCreateModalOpen = useDropdownContentSetupStore(
    state => state.isDictionaryCreateModalOpen
  )

  if (!isDictionaryCreateModalOpen) return null

  const handleSave = async (dictionaryData: Omit<TDictionaryModalData, 'id'>) => {
    const request = CreateCommandRequest.create({
      tenantId,
      name: dictionaryData.name,
      createdBy: userId,
      options: dictionaryData.options.map(option => ({ name: option.value }))
    })

    if (dictionaryData.hint?.flag) request.hint = dictionaryData.hint
    if (dictionaryData.queryWidget?.flag) request.queryWidget = dictionaryData.queryWidget

    const res = await DictionariesGrpcService.createDictionary({ request })

    useDropdownContentSetupStore.getState().addDictionary({
      value: res.dictionaryId,
      label: dictionaryData.name,
      options: dictionaryData.options,
      hint: dictionaryData.hint,
      queryWidget: dictionaryData.queryWidget
    })

    useDropdownContentSetupStore.setState({ selectedDictionaryId: res.dictionaryId })
  }

  return (
    <DictionaryModal
      isOpen={isDictionaryCreateModalOpen}
      onClose={() => useDropdownContentSetupStore.setState({ isDictionaryCreateModalOpen: false })}
      onSave={handleSave}
      saveBtnText="SAVE AND SELECT"
    />
  )
}
