/* eslint-disable import/no-cycle */
import { getWorkspaceMembers } from 'api/organizationAPI'
import { saveUserProfile } from 'api/profileAPI'
import * as actions from 'constants/actionTypes'
import messages from 'constants/messages'
import { showToastMessage } from './boardActions'

export const toggleWorkspaceMembersFetching = payload => ({
  type: actions.TOGGLE_WORKSPACE_MEMBERS_FETCHING,
  payload
})

export const clearWorkspaceMembers = () => ({ type: actions.CLEAR_WORKSPACE_MEMBERS })

export const appendWorkspaceMembers = payload => ({
  type: actions.APPEND_WORKSPACE_MEMBERS,
  payload
})

export const fetchWorkspaceMembers = payload => async dispatch => {
  dispatch(toggleWorkspaceMembersFetching(true))

  try {
    if (!payload.variables.after) {
      dispatch(clearWorkspaceMembers())
    }

    const { members, pageInfo, filterTotal, total } = await getWorkspaceMembers(payload)

    dispatch(appendWorkspaceMembers(members))
    dispatch(toggleWorkspaceMembersFetching(false))

    return {
      members,
      nextCursor: pageInfo.endCursor,
      filterTotal,
      total
    }
  } catch (err) {
    dispatch(toggleWorkspaceMembersFetching(false))

    return Promise.reject(err)
  }
}

export function toggleManagerAssignmentModal(payload) {
  return { type: actions.TOGGLE_MANAGER_ASSIGNMENT_MODAL, payload }
}

export function toggleDeleteWorkspaceMemberConfirmation(payload) {
  return { type: actions.TOGGLE_DELETE_WORKSPACE_MEMBER_CONFIRMATION, payload }
}

export function toggleDeleteWorkspaceMemberRejectionModal(payload) {
  return { type: actions.TOGGLE_DELETE_WORKSPACE_MEMBER_REJECTION_MODAL, payload }
}

export const updateWorkspaceMember = payload => ({ type: actions.UPDATE_WORKSPACE_MEMBER, payload })

export const renameWorkspaceMember = payload => async dispatch => {
  try {
    const { data: member } = await saveUserProfile(payload)
    dispatch(updateWorkspaceMember(member))
  } catch (err) {
    console.error(err)
    dispatch(
      showToastMessage({
        text: messages.SAVE_ERROR,
        size: 'M'
      })
    )
  }
}
