import {
  type DictionaryDetails,
  type OrderBy,
  type OrderDirection
} from '_proto/dictionary/v1/dictionary.pb'
import { type IUser } from '../tasks/tasks.types'

export enum EDictionarySortDirections {
  asc = 'asc',
  desc = 'desc'
}

export enum EDictionariesColumnIds {
  createdAt = 'createdAt',
  name = 'name',
  dataSource = 'dataSource',
  createdBy = 'createdBy'
}

export enum EQueryOutputTypes {
  plainList = 'plainList',
  pivotTable = 'pivotTable',
  rangePivot = 'rangePivot'
}

export type TDictionariesSortDirection = {
  id: EDictionarySortDirections
  grpcOrderDirectionId: OrderDirection
}

export type TDictionariesSortDirections = {
  [value in EDictionarySortDirections]: TDictionariesSortDirection
}

export interface IDictionaryData {
  id: DictionaryDetails['id']
  tenantId: DictionaryDetails['tenantId']
  name: DictionaryDetails['name']
  createdBy: DictionaryDetails['createdBy']
  options: TOption[]
  createdAt: DictionaryDetails['creationDate']
  hint: DictionaryDetails['hint']
  queryWidget: DictionaryDetails['queryWidget']
  userInfo?: IUser
}

export type TDictionaryModalData = Omit<
  IDictionaryData,
  'tenantId' | 'createdAt' | 'createdBy' | 'userInfo'
>

export type TOption = {
  value: string
  isHint?: boolean
}

export type TDictionariesState = {
  dictionariesList: IDictionaryData[]
  totalCount: number
  page: number
  pageCursor: string
  sortDirection: EDictionarySortDirections
  sortBy: EDictionariesColumnIds
  createDictionary: (payload: {
    name: string
    hint: IDictionaryData['hint']
    queryWidget: IDictionaryData['queryWidget']
    optionsList: TOption[]
  }) => Promise<unknown>
  deleteDictionary: (payload: string) => Promise<unknown>
  copyDictionary: (payload: {
    dictionaryId: string
    targetTenantId: string
    dictionaryName: string
  }) => Promise<unknown>
  editDictionary: (payload: {
    name: string
    dictionaryId: string
    hint: IDictionaryData['hint']
    queryWidget: IDictionaryData['queryWidget']
    optionsList: TOption[]
  }) => Promise<unknown>
  isLoading: boolean
  isConfigurationModalShown: boolean
  isDeleteModalShown: boolean
  isCopyModalShown: boolean
  dictionaryModalData: TDictionaryModalData | null
  search: string
}

export enum EDictionariesReducerActionTypes {
  setPage = 'setPage',
  setSearch = 'setSearch',
  changeSort = 'changeSort',
  setIsLoading = 'setIsLoading',
  setIsConfigurationModalShown = 'setIsConfigurationModalShown',
  setDictionariesList = 'setDictionariesList',
  setIsDeleteModalShown = 'setIsDeleteModalShown',
  setIsCopyModalShown = 'setIsCopyModalShown'
}

export type TDictionariesColumn = {
  id: EDictionariesColumnIds
  name: string
  value: EDictionariesColumnIds
  className?: string
  isSortable?: boolean
  grpcOrderingId?: OrderBy
}

export type TDictionariesColumns = {
  [value in EDictionariesColumnIds]: TDictionariesColumn
}

export interface IDictionariesReducerAction {
  type: EDictionariesReducerActionTypes
  payload: Partial<TDictionariesState>
}

export type TDictionaryError = {
  name?: boolean
  hintMessage?: boolean
  hasDuplicatedOptions?: boolean
  query?: boolean
  serverError?: string
  options?: boolean[]
}

export type TQueryWidget = {
  tenantId: string
  boardId: string
  cardUuid: string
  widgetUuid: string
}

export interface IQueryWidgetResponse {
  data: {
    data: Array<Array<{ value: string }>>
    outputType: EQueryOutputTypes
  }
}
