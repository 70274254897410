import classNames from 'classnames'
import messages from 'constants/messages'
import TripleLogo from './TripleLogo'
import UserRepresentation from './UserRepresentation'
import './users-cell.scss'

const DEFAULT_PLACEHOLDER = messages.SELECT_USER

// Component is used in widgets. Don't use hooks, as it will brake the usage.
// Needs to be investigated further.
const UsersCell = ({
  isMembersFetching,
  width,
  users,
  members,
  placeholder = DEFAULT_PLACEHOLDER,
  toggleUserPicker,
  disabled,
  isReadOnly,
  className,
  // eslint-disable-next-line react/no-object-type-as-default-prop
  keyDownHandler = () => {},
  dataNavigation = '',
  justifyContent,
  // eslint-disable-next-line react/no-object-type-as-default-prop
  fontStyle = {}
}) => {
  const usersTitle = []

  const resolvedUsers = users.map(user => {
    const member = members[user.userId]

    usersTitle.push(member?.username ?? '-')

    return member || { userId: user.userId, username: '-' }
  })

  return (
    <div
      className={classNames(className, 'users-cell', { disabled }, isReadOnly && 'read-only')}
      data-navigation={dataNavigation}
      style={{ width: `${width}%`, justifyContent }}
      title={usersTitle.join(', ')}
      onClick={toggleUserPicker}
      onKeyDown={keyDownHandler}
    >
      {isMembersFetching && !!users.length && (
        <div className="users-loading">{`${messages.LOADING}...`}</div>
      )}
      {!isMembersFetching && (
        <>
          {resolvedUsers.length === 0 && <div className="placeholder">{placeholder}</div>}
          {resolvedUsers.length === 1 && (
            <UserRepresentation
              size="extra-small-24px"
              username={resolvedUsers[0].username}
              title={resolvedUsers[0].username}
              avatarImage={resolvedUsers[0].avatarImage || ''}
              justifyContent={justifyContent}
              fontStyle={fontStyle}
            />
          )}
          {resolvedUsers.length > 1 && <TripleLogo users={resolvedUsers} size="extra-small-24px" />}
        </>
      )}
      <i className="icon up-font-ic-arrow-e908" />
    </div>
  )
}

export default UsersCell
