export function debounce(func, timeout = 100) {
  let timer
  return (...args) => {
    const next = () => func(...args)
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(next, timeout)
  }
}

export function throttle(func, timeout = 100) {
  let timer = null
  return (...args) => {
    if (timer === null) {
      timer = setTimeout(() => {
        func.apply(this, args)
        timer = null
      }, timeout)
    }
  }
}

export const asyncInterval = async (callback, ms, triesLeft = 5, waitUntilDone) =>
  new Promise((resolve, reject) => {
    const interval = setInterval(async () => {
      try {
        if (await callback()) {
          resolve()
          clearInterval(interval)
        } else if (triesLeft <= 1 && !waitUntilDone) {
          reject(new Error('tries expired'))
          clearInterval(interval)
        }
        // eslint-disable-next-line no-param-reassign,no-plusplus
        triesLeft--
      } catch (e) {
        reject(e)
        clearInterval(interval)
      }
    }, ms)
  })

export const delay = async ms =>
  new Promise(resolve => {
    setTimeout(resolve, ms)
  })
