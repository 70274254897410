import React from 'react'

import messages from 'constants/messages'
import { CURRENT_PREDICATE } from 'constants/variables/variableConfiguration/constants'

const space = ' '

const BoardRulePreview = props => {
  const { rule } = props

  if (rule.predicate === CURRENT_PREDICATE) {
    return (
      <span className="variable-rule">
        {messages.FROM}
        {space}
        <span className="bold text-lowercase">
          {messages.CURRENT} {rule.object}
        </span>
      </span>
    )
  }

  return (
    <span className="variable-rule">
      {messages.FROM} {rule.object}
      {space}
      <span className="bold">{rule.value}</span>
    </span>
  )
}

const WidgetRulePreview = props => {
  const { rule } = props

  return (
    <span className="variable-rule">
      <span className="text-lowercase">
        {messages.WITH}
        {space}
      </span>
      <span className="bold">
        {rule.value}
        {space}
      </span>
      <span className="text-lowercase">{messages.NAME}</span>
    </span>
  )
}

const VariableRulePreview = props => {
  const { rule } = props

  const components = {
    board: BoardRulePreview,
    card: BoardRulePreview,
    widget: WidgetRulePreview
  }

  const Component = components[rule.object]

  return <Component {...props} />
}

export default VariableRulePreview
