import type { ReactNode, ChangeEventHandler } from 'react'
import classNames from 'classnames'
import './switcher.scss'

type TProps = {
  id?: string
  'data-testid'?: string
  className?: string
  onLabel?: ReactNode
  offLabel?: ReactNode
  checked?: boolean
  disabled?: boolean
  onChange?: ChangeEventHandler<HTMLInputElement>
}

export const Switcher = ({
  id,
  'data-testid': dataTestId,
  className,
  onLabel = 'On',
  offLabel = 'Off',
  checked,
  disabled,
  onChange
}: TProps) => (
  <label htmlFor={id} className={classNames('switcher', className, disabled && 'disabled')}>
    <input
      id={id}
      data-testid={dataTestId}
      type="checkbox"
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    />
    <span className="slider round" />
    <span className="slider-title on">{onLabel}</span>
    <span className="slider-title off">{offLabel}</span>
  </label>
)
