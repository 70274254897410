import { EFieldKeys } from '../../constants/workflowBuilder/blocksFieldsKeys'

export const metaToStaticConnectionMapper = (block, inputName) => {
  // column name should starts from 0 under the hood
  if (inputName === EFieldKeys.COLUMN_NUMBER) {
    return parseInt(block.meta[inputName], 10) - 1
  }

  // relative column number should be number
  if (inputName === EFieldKeys.RELATIVE_COLUMN_NUMBER) {
    return parseInt(block.meta[inputName], 10)
  }

  return block.meta[inputName]
}

export const staticConnectionToMetaMapper = (inputName, data) => {
  // column name should starts from 1 on UI
  if (inputName === EFieldKeys.COLUMN_NUMBER) {
    return parseInt(data, 10) + 1
  }

  return data
}
