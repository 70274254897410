import { requestWrapper, url } from '../helpers/fetchHelpers'

export const processGetUserManager = () =>
  requestWrapper({
    rUrl: `${url}user/manager`,
    type: 'GET',
    payload: {}
  })

export const processGetUserSubordinates = () =>
  requestWrapper({
    rUrl: `${url}user/subordinates`,
    type: 'GET',
    payload: {}
  })

// deactivate tenant members
export function deactivateUser(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/user/deactivate`,
    type: 'POST',
    payload: {
      userToDeactivate: payload.userId
    }
  })
}

export function inviteUser(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/user/invite`,
    type: 'POST',
    payload: payload.data
  })
}

export function editUserRoles(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/user/editRoles`,
    type: 'PUT',
    payload: payload.data
  })
}

export function changeUserPassword(payload) {
  return requestWrapper({
    rUrl: `${url}user/password`,
    type: 'PUT',
    payload
  })
}

export function terminateUser(payload) {
  return requestWrapper({
    rUrl: `${url}user/terminate`,
    type: 'POST',
    payload
  })
}
