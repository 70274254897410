import { OBJECT_TYPES } from '../../constants/workflows'

export const ORGANIZATION_TYPE = 'organization'
export const TENANT_TYPE = 'tenant'

export const ENTITIES = {
  [ORGANIZATION_TYPE]: 'organization',
  [TENANT_TYPE]: 'tenant',
  [OBJECT_TYPES.BOARD]: 'board',
  [OBJECT_TYPES.CARD]: 'card',
  [OBJECT_TYPES.WIDGET]: 'widget',
  [OBJECT_TYPES.USER]: 'user'
}
