import React from 'react'

const WIDGET = 'widget'

const bodyConfig = {
  widget: {
    leftName: 'widgetName',
    rightName: 'cardName',
    leftClassName: 'widgetClassName',
    id: 'widgetUuid'
  },
  workflow: {
    leftName: 'workflowName',
    rightName: 'boardName',
    id: 'triggerId'
  }
}

const ConnectionsListBody = ({ activeKey, list, tenantId }) => {
  const currentConfig = bodyConfig[activeKey]

  return (
    <ul className="list-body-ul">
      {list.map(connection => {
        const link = `/board/${tenantId}/${connection.boardId}/${
          activeKey === WIDGET ? connection.cardUuid : ''
        }`
        const id = `${connection.boardId}-${connection.cardUuid}-${connection[currentConfig.id]}`

        return (
          <li key={id} className="list-body-li">
            <div className="list-body-left">
              {activeKey === WIDGET && (
                <i className={`icon icon-${connection[currentConfig.leftClassName]}`} />
              )}
              <span title={connection[currentConfig.leftName]}>
                {connection[currentConfig.leftName]}
              </span>
            </div>
            <div className="list-body-right">
              <a
                title={connection[currentConfig.rightName]}
                className="blue-link"
                target="_blank"
                rel="noopener noreferrer"
                href={link}
              >
                {connection[currentConfig.rightName]}
              </a>
            </div>
          </li>
        )
      })}
    </ul>
  )
}

export default ConnectionsListBody
