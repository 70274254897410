import gql from 'graphql-tag'
import VariableAttributeFragment from './variableAttributeFragment'
import VariableRuleFragment from './variableRuleFragment'

const variableFilterFragmentName = 'VariableFilterFragment'

export const VariableFilterFragment = {
  name: variableFilterFragmentName,
  fragment: gql`
        fragment ${variableFilterFragmentName} on VariableFilter {
            attribute {
                ...${VariableAttributeFragment.name}
            }
            rules {
                ...${VariableRuleFragment.name}
            }
        }
        ${VariableAttributeFragment.fragment}
        ${VariableRuleFragment.fragment}
    `
}

export default VariableFilterFragment
