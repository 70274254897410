import messages from '../../constants/messages'
import { EFieldKeys } from '../../constants/workflowBuilder/blocksFieldsKeys'

export const STRING_INPUT = 'stringInput'
export const PLAIN_STRING = 'plainString'
export const DEFAULT_NAME = 'defaultName'

export const COLUMN_CREATION_OPTIONS = {
  [PLAIN_STRING]: { value: PLAIN_STRING, label: messages.ENTER_CUSTOM_TEXT },
  [STRING_INPUT]: { value: STRING_INPUT, label: messages.USE_STRING_OUTPUT },
  [DEFAULT_NAME]: { value: DEFAULT_NAME, label: messages.DEFAULT_NAME }
}

export const COLUMN_CREATION_OPTIONS_LIST = Object.values(COLUMN_CREATION_OPTIONS)

export const validateCreateColumnBlock = ({ block }) => {
  const error = {}
  if (!block.input || !block.input[EFieldKeys.SOURCE_INPUT]) {
    error[EFieldKeys.SOURCE_INPUT] = messages.emptyField
    return { error }
  }

  if (
    !block.meta ||
    (block.meta[EFieldKeys.TYPE] === STRING_INPUT && !block.input[EFieldKeys.STRING_INPUT])
  ) {
    error[EFieldKeys.STRING_INPUT] = messages.emptyField
    return { error }
  }

  if (block.meta[EFieldKeys.TYPE] === PLAIN_STRING && !block.meta[EFieldKeys.NAME]) {
    error[EFieldKeys.NAME] = messages.emptyField
    return { error }
  }

  return { error }
}
