import { OrderBy, OrderDirection } from '_proto/dictionary/v1/dictionary.pb'
import messages from 'constants/messages'
import {
  EDictionariesColumnIds,
  EDictionarySortDirections,
  type TDictionariesColumns,
  type TDictionariesSortDirections
} from './dictionariesSettings.types'

export const DICTIONARIES_SORT_DIRECTIONS: TDictionariesSortDirections = {
  [EDictionarySortDirections.asc]: {
    id: EDictionarySortDirections.asc,
    grpcOrderDirectionId: OrderDirection.ASC
  },
  [EDictionarySortDirections.desc]: {
    id: EDictionarySortDirections.desc,
    grpcOrderDirectionId: OrderDirection.DESC
  }
}

export const DICTIONARIES_COLUMNS: TDictionariesColumns = {
  [EDictionariesColumnIds.name]: {
    id: EDictionariesColumnIds.name,
    name: messages.NAME,
    value: EDictionariesColumnIds.name,
    grpcOrderingId: OrderBy.Name,
    className: 'name',
    isSortable: true
  },
  [EDictionariesColumnIds.createdBy]: {
    id: EDictionariesColumnIds.createdBy,
    name: messages.CREATED_BY,
    value: EDictionariesColumnIds.createdBy,
    className: 'created-by',
    isSortable: false
  },
  [EDictionariesColumnIds.dataSource]: {
    id: EDictionariesColumnIds.dataSource,
    name: messages.DATA_SOURCE,
    value: EDictionariesColumnIds.dataSource,
    className: 'data-source',
    isSortable: false
  },
  [EDictionariesColumnIds.createdAt]: {
    id: EDictionariesColumnIds.createdAt,
    name: messages.CREATION_DATE,
    value: EDictionariesColumnIds.createdAt,
    grpcOrderingId: OrderBy.CreationDate,
    className: 'creation-date',
    isSortable: true
  }
}

export const DICTIONARIES_LIST_COLUMNS = Object.values(DICTIONARIES_COLUMNS)

export const DICTIONARIES_PER_PAGE = 10

export const DICTIONARIES_DEBOUNCE_TIMEOUT_MS = 600

export const MAX_DICTIONARY_MANUAL_OPTION_LENGTH = 350
export const MAX_DICTIONARY_MANUAL_OPTIONS = 300
export const PLAIN_LIST_OPTIONS_LIMIT = 50

export const MIN_DICTIONARY_COLUMN_WIDTH = 200

export const DEFAULT_OPTIONS = [{ value: 'Option 1' }, { value: 'Option 2' }, { value: 'Option 3' }]

export const DICTIONARIES_DATA_SOURCES = {
  MANUAL: 'Manual',
  QUERY_WIDGET: 'Query widget'
} as const
