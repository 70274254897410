import { useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useRegisterSW } from 'virtual:pwa-register/react'
import { NEW_VERSION_RELEASED } from 'constants/appActionsTypes'
import { showToastMessage } from 'actions/boardActions'

export const useServiceWorker = () => {
  const dispatch = useDispatch()
  const intervalRef = useRef<NodeJS.Timeout>()

  const handleRegisteredSW = useCallback(
    (swUrl: string, sw: ServiceWorkerRegistration | undefined) => {
      intervalRef.current && clearInterval(intervalRef.current)

      if (!sw || !!sw.installing) return
      if ('connection' in navigator && !navigator.onLine) return

      // Check for new app version every hour.
      intervalRef.current = setInterval(() => {
        const request = async () => {
          try {
            const res = await fetch(swUrl, {
              cache: 'no-store',
              headers: {
                cache: 'no-store',
                'cache-control': 'no-cache'
              }
            })

            if (res.status === 200) await sw.update()
          } catch (error) {}
        }

        void request()
      }, 3600000)
    },
    []
  )

  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker
  } = useRegisterSW({
    onRegisteredSW: handleRegisteredSW
  })

  // Show "new version released" toast message.
  useEffect(() => {
    if (needRefresh) {
      const metadata = {
        onUpdate: updateServiceWorker,
        onDismiss: () => setNeedRefresh(false)
      }

      // @ts-expect-error Redux and showToastMessage are not typed.
      dispatch(showToastMessage(metadata, NEW_VERSION_RELEASED, Infinity))
    }
  }, [needRefresh, updateServiceWorker, setNeedRefresh, dispatch])
}
