import { produce } from 'immer'
import { createReducer } from 'redux-create-reducer'
import { getSortedOrganizations } from 'helpers/organizationsHelpers'
import {
  TOGGLE_JIRA_CONNECTION_MODAL,
  GET_USER_JIRA_CREDENTIALS,
  RECEIVE_LOGIN,
  REQUEST_SAVE_USER_PROFILE,
  RECEIVE_SAVE_USER_PROFILE,
  RECEIVE_SAVE_USER_PROFILE_ERROR,
  RECEIVE_USER,
  REQUEST_BOARD_MENU,
  RECEIVE_BOARD_MENU,
  RECEIVE_ORGANIZATIONS,
  CLEAR_ORGANIZATIONS,
  RECEIVE_ACTIVE_ORGANIZATION,
  RECEIVE_BOARD_MENU_ERROR,
  CLEAR_DRAFT,
  JIRA_PROJECTS_REQUEST,
  JIRA_PROJECTS_RECEIVE,
  JIRA_PROJECTS_RECEIVE_ERROR,
  JIRA_ISSUE_TYPES_REQUEST,
  JIRA_ISSUE_TYPES_RECEIVE,
  JIRA_ISSUE_TYPES_RECEIVE_ERROR,
  JIRA_STATUSES_REQUEST,
  JIRA_STATUSES_RECEIVE,
  JIRA_STATUSES_RECEIVE_ERROR,
  JIRA_USERS_REQUEST,
  JIRA_USERS_RECEIVE,
  JIRA_USERS_RECEIVE_ERROR,
  JIRA_COMPONENTS_REQUEST,
  JIRA_COMPONENTS_RECEIVE,
  JIRA_COMPONENTS_RECEIVE_ERROR,
  JIRA_FILTERS_REQUEST,
  JIRA_FILTERS_RECEIVE,
  JIRA_FILTERS_RECEIVE_ERROR,
  RECEIVE_DEACTIVATE_MEMBERS,
  UPDATE_TEAM_SETTINGS,
  CLEAR_TEAM_SETTINGS,
  UPDATE_BOARD_MENU_TEAM_NAME,
  REORDER_RECENT_BOARDS,
  REQUEST_SAVE_ORGANIZATION_PROFILE,
  RECEIVE_SAVE_ORGANIZATION_PROFILE,
  RECEIVE_SAVE_ORGANIZATION_PROFILE_ERROR,
  REQUEST_ORGANIZATION_PERMISSIONS,
  RECEIVE_ORGANIZATION_PERMISSIONS,
  RECEIVE_ORGANIZATION_PERMISSIONS_ERROR,
  TOGGLE_IS_ONBOARD_SETTINGS,
  TENANT_UPDATE_SOCKET,
  ORGANIZATION_UPDATE_SOCKET,
  REQUEST_HOME_PAGE_CONFIG,
  RECEIVE_HOME_PAGE_CONFIG,
  RECEIVE_HOME_PAGE_CONFIG_ERROR,
  TOGGLE_BOARD_IMPORT,
  SET_WORKSPACE_SUBSCRIPTIONS,
  SET_WORKSPACE_COLLECTIONS,
  UPDATE_NEW_IMPORTED_APPS,
  UPDATE_BOARD_NAME
} from 'constants/actionTypes'

const initialState = {
  user: {
    openedItems: {
      tenants: []
    }
  }
}

export const profile = createReducer(initialState, {
  [TOGGLE_JIRA_CONNECTION_MODAL](state, action) {
    return { ...state, isJiraConnectionModalShown: action.payload }
  },

  [GET_USER_JIRA_CREDENTIALS](state, action) {
    return { ...state, jiraCredentials: action.payload }
  },

  [RECEIVE_LOGIN](state, action) {
    return { ...state, user: action.payload }
  },

  [REQUEST_SAVE_USER_PROFILE](state) {
    return { ...state, isSaveUserProfileRequested: true }
  },

  [RECEIVE_SAVE_USER_PROFILE](state, action) {
    const userData = {
      ...action.payload,
      openedItems: action.payload?.openedItems || { tenants: [] }
    }
    const newUser = { ...state.user, ...userData }

    return { ...state, isSaveUserProfileRequested: false, user: newUser }
  },
  [RECEIVE_SAVE_USER_PROFILE_ERROR](state, action) {
    return {
      ...state,
      isSaveUserProfileRequested: false,
      saveUserProfileErrorMessage: action.payload.message
    }
  },

  [RECEIVE_USER](state, action) {
    const { data } = action.payload
    const userData = {
      ...data,
      openedItems: data?.openedItems || { tenants: [] }
    }
    const { user } = state
    return {
      ...state,
      user: {
        ...user,
        ...userData
      }
    }
  },

  [REQUEST_BOARD_MENU](state) {
    return { ...state, isBoardMenuRequested: true }
  },

  [RECEIVE_BOARD_MENU](state, action) {
    return { ...state, isBoardMenuRequested: false, boardMenu: action.payload }
  },

  [RECEIVE_ORGANIZATIONS](state, action) {
    if (action.payload.replaceData) {
      return { ...state, organizations: getSortedOrganizations(action.payload.organizations) }
    }

    const organizationsMap = state.organizations.reduce((acc, organization) => {
      acc[organization.organizationId] = organization

      return acc
    }, {})

    action.payload.organizations.forEach(org => {
      const organization = organizationsMap[org.organizationId]

      organizationsMap[org.organizationId] = {
        ...organization,
        ...org
      }
    })

    return { ...state, organizations: getSortedOrganizations(Object.values(organizationsMap)) }
  },

  [CLEAR_ORGANIZATIONS](state) {
    return {
      ...state,
      organizations: [],
      activeOrganization: {
        organizationPermissions: {}
      }
    }
  },

  [RECEIVE_ACTIVE_ORGANIZATION](state, action) {
    return {
      ...state,
      activeOrganization: {
        ...state.activeOrganization,
        ...action.payload.organization
      }
    }
  },

  [RECEIVE_BOARD_MENU_ERROR](state, action) {
    return {
      ...state,
      isBoardMenuRequested: false,
      boardMenuErrorMessage: action.payload.message
    }
  },

  [CLEAR_DRAFT](state) {
    return {
      ...state,
      user: {
        ...state.user,
        draftInfo: null,
        hasDraft: false
      }
    }
  },

  [JIRA_PROJECTS_REQUEST](state) {
    return {
      ...state,
      isJiraProjectsRequested: true
    }
  },

  [JIRA_PROJECTS_RECEIVE](state, action) {
    return {
      ...state,
      jiraProjects: action.payload,
      isJiraProjectsRequested: false
    }
  },

  [JIRA_PROJECTS_RECEIVE_ERROR](state) {
    return {
      ...state,
      isJiraProjectsRequested: false
    }
  },

  [JIRA_ISSUE_TYPES_REQUEST](state) {
    return {
      ...state,
      isJiraIssueTypesRequested: true
    }
  },

  [JIRA_ISSUE_TYPES_RECEIVE](state, action) {
    return {
      ...state,
      jiraIssueTypes: action.payload,
      isJiraIssueTypesRequested: false
    }
  },

  [JIRA_ISSUE_TYPES_RECEIVE_ERROR](state) {
    return {
      ...state,
      isJiraIssueTypesRequested: false
    }
  },

  [JIRA_STATUSES_REQUEST](state) {
    return {
      ...state,
      isJiraStatusesRequested: true
    }
  },

  [JIRA_STATUSES_RECEIVE](state, action) {
    return {
      ...state,
      jiraStatuses: action.payload,
      isJiraStatusesRequested: false
    }
  },

  [JIRA_STATUSES_RECEIVE_ERROR](state) {
    return {
      ...state,
      isJiraStatusesRequested: false
    }
  },

  [JIRA_USERS_REQUEST](state) {
    return {
      ...state,
      isJiraUsersRequested: true
    }
  },

  [JIRA_USERS_RECEIVE](state, action) {
    return {
      ...state,
      jiraUsers: action.payload.users.users,
      isJiraUsersRequested: false
    }
  },

  [JIRA_USERS_RECEIVE_ERROR](state) {
    return {
      ...state,
      isJiraUsersRequested: false
    }
  },

  [JIRA_COMPONENTS_REQUEST](state) {
    return {
      ...state,
      isJiraComponentsRequested: true
    }
  },

  [JIRA_COMPONENTS_RECEIVE](state, action) {
    return {
      ...state,
      jiraComponents: action.payload,
      isJiraComponentsRequested: false
    }
  },

  [JIRA_COMPONENTS_RECEIVE_ERROR](state) {
    return {
      ...state,
      isJiraComponentsRequested: false
    }
  },

  [JIRA_FILTERS_REQUEST](state) {
    return {
      ...state,
      isJiraFavouriteFiltersRequested: true
    }
  },

  [JIRA_FILTERS_RECEIVE](state, action) {
    return {
      ...state,
      jiraFavFilters: action.payload,
      isJiraFavouriteFiltersRequested: false
    }
  },

  [JIRA_FILTERS_RECEIVE_ERROR](state) {
    return {
      ...state,
      isJiraFavouriteFiltersRequested: false
    }
  },

  [RECEIVE_DEACTIVATE_MEMBERS](state, action) {
    const { tenants, boards, apps } = state.boardMenu
    const newTenants = tenants.slice(0)

    newTenants.forEach(tenant => {
      if (!action.payload.isInactive && action.payload.tenantId === tenant.tenantId) {
        tenant.usersNumber -= 1
      }
    })

    return {
      ...state,
      boardMenu: {
        tenants: newTenants,
        boards,
        apps
      }
    }
  },

  [UPDATE_TEAM_SETTINGS](state, action) {
    const teamSettings = { ...state.teamSettings, ...action.payload }
    return { ...state, teamSettings }
  },

  [CLEAR_TEAM_SETTINGS](state) {
    return {
      ...state,
      teamSettings: {
        name: '',
        dateFormat: 'en-US',
        teamPermissions: {}
      }
    }
  },

  [UPDATE_BOARD_NAME](state, action) {
    const { tenants, boards, apps } = state.boardMenu

    const updatedBoards = produce(boards, draft => {
      const board = draft.find(board => board.boardId === action.payload.boardId)
      if (board) board.name = action.payload.data.name
    })

    return {
      ...state,
      boardMenu: {
        tenants,
        boards: updatedBoards,
        apps
      }
    }
  },

  [UPDATE_BOARD_MENU_TEAM_NAME](state, action) {
    const { tenants, boards, apps } = state.boardMenu
    const newTenants = tenants.slice(0)

    newTenants.forEach(tenant => {
      if (action.payload.tenantId === tenant.tenantId) {
        tenant.name = action.payload.name
      }
    })

    return {
      ...state,
      boardMenu: {
        tenants: newTenants,
        boards,
        apps
      }
    }
  },

  [REORDER_RECENT_BOARDS](state, action) {
    const { tenants, boards, apps } = state.boardMenu

    const { tenantId, boardId } = action.payload

    const newBoards = boards.map(board => {
      if (board.tenantId === tenantId && board.boardId === boardId) {
        return { ...board, timestamp: Date.now() }
      }

      return board
    })

    return {
      ...state,
      boardMenu: {
        tenants,
        boards: newBoards,
        apps
      }
    }
  },

  [REQUEST_SAVE_ORGANIZATION_PROFILE](state) {
    return { ...state, isSaveOrganizationProfileRequested: true }
  },

  [RECEIVE_SAVE_ORGANIZATION_PROFILE](state, action) {
    const newActiveOrganization = {
      ...state.activeOrganization,
      allowPublicBoards: action.payload.allowPublicBoards,
      allowPublicBoardsCopy: action.payload.allowPublicBoardsCopy,
      name: action.payload.name,
      avatarImage: action.payload.avatarImage || '',
      sessionDurationConfig: action.payload.sessionDurationConfig,
      timeZone: action.payload.timeZone
    }
    return {
      ...state,
      isSaveOrganizationProfileRequested: false,
      activeOrganization: newActiveOrganization
    }
  },

  [RECEIVE_SAVE_ORGANIZATION_PROFILE_ERROR](state, action) {
    return {
      ...state,
      isSaveOrganizationProfileRequested: false,
      saveOrganizationProfileErrorMessage: action.payload.message
    }
  },

  [REQUEST_ORGANIZATION_PERMISSIONS](state) {
    return {
      ...state,
      isOrganizationPermissionsRequested: true
    }
  },

  [RECEIVE_ORGANIZATION_PERMISSIONS](state, action) {
    const activeOrganization = { ...state.activeOrganization }

    activeOrganization.organizationPermissions = action.payload.permissions || {}
    activeOrganization.organizationRole = action.payload.role
    return {
      ...state,
      isOrganizationPermissionsRequested: false,
      activeOrganization
    }
  },

  [RECEIVE_ORGANIZATION_PERMISSIONS_ERROR](state) {
    return {
      ...state,
      isOrganizationPermissionsRequested: false
    }
  },

  [TOGGLE_IS_ONBOARD_SETTINGS](state, action) {
    return {
      ...state,
      isOnBoardSettings: action.payload
    }
  },

  [TENANT_UPDATE_SOCKET](state, action) {
    const teamSettings = { ...state.teamSettings, ...action.payload }
    return { ...state, teamSettings }
  },

  [ORGANIZATION_UPDATE_SOCKET](state, action) {
    const activeOrganization = { ...state.activeOrganization, ...action.payload }
    return { ...state, activeOrganization }
  },

  [REQUEST_HOME_PAGE_CONFIG](state) {
    return { ...state, homePageConfigRequesting: true }
  },

  [RECEIVE_HOME_PAGE_CONFIG](state, action) {
    const homePageConfig = { ...state.homePageConfig, ...action.payload }
    return { ...state, homePageConfig, homePageConfigRequesting: false }
  },

  [RECEIVE_HOME_PAGE_CONFIG_ERROR](state) {
    return { ...state, homePageConfigRequesting: false }
  },

  [TOGGLE_BOARD_IMPORT](state, action) {
    return { ...state, bundleImportTenantId: action.payload }
  },

  [SET_WORKSPACE_SUBSCRIPTIONS](state, action) {
    return {
      ...state,
      activeOrganization: {
        ...state.activeOrganization,
        subscriptions: action.data.subscriptions,
        areSubscriptionsLoaded: true
      }
    }
  },

  [SET_WORKSPACE_COLLECTIONS](state, action) {
    return {
      ...state,
      activeOrganization: {
        ...state.activeOrganization,
        collections: action.data.collections ?? []
      }
    }
  },

  [UPDATE_NEW_IMPORTED_APPS](state, action) {
    if (action.payload) {
      const app = state.newImportedApps.find(({ jobId }) => jobId === action.payload.jobId)
      if (app) {
        return {
          ...state,
          newImportedApps: state.newImportedApps.map(_app => {
            if (_app.jobId === action.payload.jobId) {
              return {
                ..._app,
                ...action.payload
              }
            }
            return _app
          })
        }
      }
      return {
        ...state,
        newImportedApps: [...state.newImportedApps, action.payload]
      }
    }
    return {
      ...state,
      newImportedApps: []
    }
  }
})

export default profile
