import { savePDF } from '@progress/kendo-react-pdf'
import { commonPDFWidth } from 'constants/pdfConstants'
import { WidgetPdfTemplate } from './WidgetPdfTemplate'

export const generateWidgetPdf = (
  {
    selector,
    widgetName
  }: {
    selector: string
    widgetName: string
  },
  setPDFWidgetGeneration?: (isGeneration: boolean) => void
) => {
  const element = document.querySelector(selector) as HTMLElement
  if (!element) return

  savePDF(
    element,
    {
      margin: {
        top: 35,
        left: 0,
        right: 0,
        bottom: 20
      },
      paperSize: [commonPDFWidth, 340],
      scale: 0.4,
      landscape: true,
      avoidLinks: true,
      fileName: `${widgetName}.pdf`,
      pageTemplate: ({ pageNum, totalPages }: { pageNum: number; totalPages: number }) => (
        <WidgetPdfTemplate pageNum={pageNum} totalPages={totalPages} />
      )
    },
    () => {
      if (setPDFWidgetGeneration) setPDFWidgetGeneration(false)
    }
  )
}
