import { getCardsList } from '../api/bindCardAPI'
import { processSearchDrawerRequest } from '../api/cardAPI'
import * as actions from '../constants/actionTypes'

export function toggleDrawer(payload) {
  return { type: actions.TOGGLE_DRAWER, payload }
}

export function getDrawerCardsRequestStart() {
  return { type: actions.GET_DRAWER_CARDS_REQUEST }
}

export function getDrawerCardsReceive(payload) {
  return { type: actions.GET_DRAWER_CARDS_RECEIVE, payload }
}

export function getDrawerCardsReceiveError(payload) {
  return { type: actions.GET_DRAWER_CARDS_RECEIVE_ERROR, payload }
}

export function getDrawerCards(payload) {
  return dispatch => {
    dispatch(getDrawerCardsRequestStart())
    return dispatch(
      getCardsList({
        ...payload,
        expand: {
          lockOwner: true
        }
      })
    )
      .then(response => dispatch(getDrawerCardsReceive(response.data)))
      .catch(err => dispatch(getDrawerCardsReceiveError(err)))
  }
}

export function getSearchDrawerCardsReceive(payload) {
  return { type: actions.GET_SEARCH_DRAWER_CARDS_RECEIVE, payload }
}

export function searchDrawerCards(payload) {
  return (dispatch, getState) => {
    const state = getState()
    if (state.drawer.paginationEnd) {
      return Promise.resolve()
    }
    dispatch(getDrawerCardsRequestStart())
    return processSearchDrawerRequest(payload)
      .then(response =>
        dispatch(
          getSearchDrawerCardsReceive({
            cards: response.data,
            limit: payload.limit,
            offset: payload.offset
          })
        )
      )
      .catch(err => dispatch(getDrawerCardsReceiveError(err)))
  }
}

export function toggleDrawerPaginationEnd(payload) {
  return { type: actions.TOGGLE_DRAWER_PAGINATION_END, payload }
}

export function toggleDrawerDrag(payload) {
  return { type: actions.TOGGLE_DRAWER_DRAG, payload }
}

// toggle drawer card preview
export function toggleDrawerPreview(payload) {
  return { type: actions.TOGGLE_DRAWER_CARD_PREVIEW, payload }
}
