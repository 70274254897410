// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.0.3
//   protoc               v3.19.1
// source: common/page.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";

export const protobufPackage = "upboard.io.common";

export interface PageOptions {
  limit: number;
  /** start_cursor specifies position to start reading from */
  startCursor: Uint8Array;
}

export interface PageInfo {
  /** end_cursor specifies position after returned data block */
  endCursor: Uint8Array;
}

export interface PageInfoWithTotal {
  /** end_cursor specifies position after returned data block */
  endCursor: Uint8Array;
  total: number;
}

function createBasePageOptions(): PageOptions {
  return { limit: 0, startCursor: new Uint8Array(0) };
}

export const PageOptions = {
  encode(message: PageOptions, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.limit !== 0) {
      writer.uint32(8).uint64(message.limit);
    }
    if (message.startCursor.length !== 0) {
      writer.uint32(26).bytes(message.startCursor);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PageOptions {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePageOptions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.limit = longToNumber(reader.uint64());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.startCursor = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<PageOptions>, I>>(base?: I): PageOptions {
    return PageOptions.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PageOptions>, I>>(object: I): PageOptions {
    const message = createBasePageOptions();
    message.limit = object.limit ?? 0;
    message.startCursor = object.startCursor ?? new Uint8Array(0);
    return message;
  },
};

function createBasePageInfo(): PageInfo {
  return { endCursor: new Uint8Array(0) };
}

export const PageInfo = {
  encode(message: PageInfo, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.endCursor.length !== 0) {
      writer.uint32(18).bytes(message.endCursor);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PageInfo {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePageInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.endCursor = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<PageInfo>, I>>(base?: I): PageInfo {
    return PageInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PageInfo>, I>>(object: I): PageInfo {
    const message = createBasePageInfo();
    message.endCursor = object.endCursor ?? new Uint8Array(0);
    return message;
  },
};

function createBasePageInfoWithTotal(): PageInfoWithTotal {
  return { endCursor: new Uint8Array(0), total: 0 };
}

export const PageInfoWithTotal = {
  encode(message: PageInfoWithTotal, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.endCursor.length !== 0) {
      writer.uint32(18).bytes(message.endCursor);
    }
    if (message.total !== 0) {
      writer.uint32(24).int64(message.total);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PageInfoWithTotal {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePageInfoWithTotal();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.endCursor = reader.bytes();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.total = longToNumber(reader.int64());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<PageInfoWithTotal>, I>>(base?: I): PageInfoWithTotal {
    return PageInfoWithTotal.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PageInfoWithTotal>, I>>(object: I): PageInfoWithTotal {
    const message = createBasePageInfoWithTotal();
    message.endCursor = object.endCursor ?? new Uint8Array(0);
    message.total = object.total ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}
