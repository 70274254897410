import gql from 'graphql-tag'
import { FilterConnectionObjectFragment } from './filterConnectionObjectFragment'
import { WidgetConnectionObjectFragment } from './widgetConnectionObjectFragment'
import { LogicalRuleConnectionObjectFragment } from './logicalRuleConnectionObjectFragment'

const variableConnectionFragmentName = 'VariableConnectionFragment'

export const VariableConnectionFragment = {
  name: variableConnectionFragmentName,
  fragment: gql`
        fragment ${variableConnectionFragmentName} on VariableConnection {
            id
            object {
                ...${FilterConnectionObjectFragment.name}
                ...${WidgetConnectionObjectFragment.name}
                ...${LogicalRuleConnectionObjectFragment.name}
            }
        }
        ${FilterConnectionObjectFragment.fragment}
        ${WidgetConnectionObjectFragment.fragment}
        ${LogicalRuleConnectionObjectFragment.fragment}
    `
}

export default VariableConnectionFragment
