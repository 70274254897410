import React from 'react'
import classNames from 'classnames'

import ConnectionsList from './ConnectionsList'

const WORKFLOW = 'workflow'
const WIDGET = 'widget'

const ConnectionsListWrapper = ({ tenantId, workflowList, widgetList, className }) => {
  return (
    <div className={classNames('connections-list-wrapper', className)}>
      <ConnectionsList activeKey={WORKFLOW} list={workflowList} tenantId={tenantId} />
      <ConnectionsList activeKey={WIDGET} list={widgetList} tenantId={tenantId} />
    </div>
  )
}

ConnectionsListWrapper.defaultProps = {
  className: '',
  workflowList: [],
  widgetList: []
}

export default ConnectionsListWrapper
