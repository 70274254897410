import { useMemo } from 'react'
import messages from 'constants/messages'
import { VARIABLES_CONFIG } from 'constants/variables/variableConfiguration/constants'
import { findSelectedOptionByKey } from 'helpers/fields/selectHelpers'
import { formatAttribute } from 'helpers/variables/variableConfiguration'
import PlainInput from 'components/fields/PlainInput'
import { Select } from 'components/common/select/select'
import 'scss/variables/variableConfiguration/attribute.scss'

const getAttributesByObject = (attributes, object) =>
  Object.entries(attributes).reduce((acc, [key, attribute]) => {
    if (attribute[object]) {
      acc.push({
        value: key,
        label: attribute.name
      })
    }

    return acc
  }, [])

export const Attribute = ({ isSaving, attribute, variableObject, onChangeAttribute }) => {
  const currentAttribute = VARIABLES_CONFIG.attributes[attribute.propertyName]

  const attributeOptions = getAttributesByObject(VARIABLES_CONFIG.attributes, variableObject)

  const changeAttribute = ({ value: propertyName }) => {
    const newAttribute = { propertyName }

    if (!VARIABLES_CONFIG.attributes[propertyName].withCoordinates) {
      newAttribute.x = 0
      newAttribute.y = 0
    }

    onChangeAttribute(newAttribute)
  }

  const changeCoordinate = (propName, event) => {
    const { value } = event.target
    const digitsPattern = /^[0-9]*$/gi

    if (digitsPattern.test(value)) {
      onChangeAttribute({
        [propName]: value ? parseInt(value, 10) - 1 : ''
      })
    }
  }

  const blurCoordinateFields = (propName, event) => {
    const { value } = event.target

    if (!value || (value && parseInt(value, 10) === 0)) {
      onChangeAttribute({ [propName]: 0 })
    }
  }

  const selectedOption = useMemo(() => {
    const selectedOption = findSelectedOptionByKey(attribute.propertyName, attributeOptions)

    return selectedOption?.value
  }, [attribute.propertyName, attributeOptions])

  return (
    <div className="variable-configuration-attribute-row">
      <Select
        className="variable-attribute"
        label={messages.GET_ATTRIBUTE}
        MenuProps={{ className: 'variable-attribute-select-menu' }}
        size={40}
        value={selectedOption}
        options={attributeOptions}
        disabled={isSaving}
        onChange={event => changeAttribute(event.target)}
      />
      {currentAttribute.withCoordinates && (
        <>
          <PlainInput
            className="attribute-coordinate"
            placeholder="X"
            maxLength={3}
            value={formatAttribute(attribute.x)}
            onChange={changeCoordinate.bind(null, 'x')}
            onBlur={blurCoordinateFields.bind(null, 'x')}
            disabled={isSaving}
          />
          <PlainInput
            className="attribute-coordinate"
            placeholder="Y"
            maxLength={3}
            value={formatAttribute(attribute.y)}
            onChange={changeCoordinate.bind(null, 'y')}
            onBlur={blurCoordinateFields.bind(null, 'y')}
            disabled={isSaving}
          />
        </>
      )}
    </div>
  )
}
