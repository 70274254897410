import { requestWrapper, url } from '../helpers/fetchHelpers'

export function triggerClickEvent(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/card/${payload.cardUuid}/widget/${payload.widgetUuid}/trigger/event`,
    type: 'POST',
    payload: {}
  })
}

export function getWorkflowExecutionMonitorStatus(payload) {
  return requestWrapper({
    rUrl: `${url}taa/tenant/${payload.tenantId}/monitor`,
    type: 'GET',
    payload: {
      ids: payload.monitorId
    }
  })
}
