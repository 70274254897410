import { createReducer } from 'redux-create-reducer'
import { generateGUID, makeObjectFromArray } from 'helpers/common/commonHelpers'
import { deleteEmptyGroups } from 'helpers/appBuilder/navItemsHelpers'
import * as ActionTypes from '../constants/actionTypes'

export const appBuilder = createReducer(
  {},
  {
    [ActionTypes.SET_APP]: (state, action) => ({ ...state, app: action.payload }),

    [ActionTypes.CHANGE_APP]: (state, action) => ({
      ...state,
      app: { ...state.app, ...action.payload }
    }),

    [ActionTypes.TOGGLE_APP_BUILDER_LOADER]: (state, action) => ({
      ...state,
      isAppBuilderLoading: action.payload
    }),

    [ActionTypes.TOGGLE_APP_SAVING]: (state, action) => ({
      ...state,
      isAppSaving: action.payload
    }),

    [ActionTypes.TOGGLE_HAS_CHANGES]: (state, action) => ({
      ...state,
      hasChanges: action.payload
    }),

    [ActionTypes.TOGGLE_APP_BUILDER_CONFIRMATION]: (state, action) => ({
      ...state,
      isAppBuilderConfirmationShown: action.payload
    }),

    [ActionTypes.TOGGLE_APP_CREATION_MODE]: (state, action) => ({
      ...state,
      isAppCreationMode: action.payload
    }),

    [ActionTypes.SET_APP_BOARDS]: (state, action) => ({
      ...state,
      appBoards: action.payload
    }),

    [ActionTypes.TOGGLE_APP_CARDS_LOADING]: (state, action) => ({
      ...state,
      appCardsLoading: { ...state.appCardsLoading, ...action.payload }
    }),

    [ActionTypes.SET_APP_CARDS]: (state, action) => ({
      ...state,
      appCards: { ...state.appCards, ...action.payload }
    }),

    [ActionTypes.CLEAR_ALL_APP_CARDS]: state => ({
      ...state,
      appCards: {}
    }),

    [ActionTypes.CLEAR_APP_CARDS]: (state, action) => {
      const appCards = { ...state.appCards }

      delete appCards[action.payload.boardId]

      return { ...state, appCards }
    },

    [ActionTypes.SET_APP_LOGO]: (state, action) => ({
      ...state,
      newLogo: action.payload
    }),

    [ActionTypes.SET_CLEAR_LOGO]: (state, action) => ({
      ...state,
      clearLogo: action.payload
    }),

    [ActionTypes.SET_APP_ERRORS]: (state, action) => ({
      ...state,
      appErrors: { ...state.appErrors, ...action.payload }
    }),

    [ActionTypes.CLEAR_APP_ERRORS]: state => ({
      ...state,
      appErrors: {}
    }),

    [ActionTypes.SET_NAV_ITEMS]: (state, action) => ({
      ...state,
      navItems: action.payload
    }),

    [ActionTypes.ADD_NAV_ITEMS]: (state, action) => ({
      ...state,
      navItems: [...state.navItems, ...action.payload]
    }),

    [ActionTypes.UPDATE_NAV_ITEMS]: (state, action) => {
      const updatedNavItemsMap = makeObjectFromArray(action.payload, navItem => navItem.id)

      return {
        ...state,
        navItems: state.navItems.map(nav => {
          if (nav.id in updatedNavItemsMap) {
            return updatedNavItemsMap[nav.id]
          }

          return nav
        })
      }
    },

    [ActionTypes.DELETE_NAV_ITEMS]: (state, action) => ({
      ...state,
      navItems: deleteEmptyGroups(
        state.navItems.filter(
          ({ id, groupId }) => !action.payload.includes(id) && !action.payload.includes(groupId)
        )
      )
    }),

    [ActionTypes.TOGGLE_NAV_ITEM_CONFIGURATION_MODAL]: (state, action) => ({
      ...state,
      navItemConfigurationModalData: {
        state: action.payload ? action.payload.state : false,
        isEdit: action.payload?.isEdit ? action.payload.isEdit : false,
        group: action.payload?.group ? action.payload.group : null,
        navItems: action.payload?.navItems ? action.payload.navItems : null
      }
    }),

    [ActionTypes.CHANGE_APP_BUILDER_TAB]: (state, action) => ({
      ...state,
      tab: action.payload
    }),

    [ActionTypes.SET_APP_META]: (state, action) => ({
      ...state,
      appMeta: action.payload
    }),

    [ActionTypes.SET_APP_META_PURCHASE_INFO]: (state, action) => {
      const purchaseInfo = action.payload.map(_purchaseInfo =>
        _purchaseInfo.uuid
          ? _purchaseInfo
          : {
              ..._purchaseInfo,
              uuid: generateGUID()
            }
      )

      return {
        ...state,
        appMeta: { ...state.appMeta, purchaseInfo }
      }
    },

    [ActionTypes.SET_APP_META_CONFIG]: (state, action) => ({
      ...state,
      appMetaConfigs: action.payload
    }),

    [ActionTypes.SET_APP_TEMPLATE_DOWNLOAD_PROGRESS]: (state, action) => ({
      ...state,
      appTemplateDownloadProgress: action.payload
    }),

    [ActionTypes.CHANGE_APP_META]: (state, action) => ({
      ...state,
      appMeta: { ...state.appMeta, ...action.payload }
    }),

    [ActionTypes.SET_APP_META_ERRORS]: (state, action) => ({
      ...state,
      appMetaErrors: { ...state.appMetaErrors, ...action.payload }
    }),

    [ActionTypes.CLEAR_APP_META_ERRORS]: state => ({
      ...state,
      appMetaErrors: {}
    }),

    [ActionTypes.APP_META_IMAGES.ADD]: (state, action) => ({
      ...state,
      appMeta: { ...state.appMeta, images: [...(state.appMeta.images || []), ...action.payload] }
    }),

    [ActionTypes.APP_META_IMAGES.SET]: (state, action) => ({
      ...state,
      appMeta: { ...state.appMeta, images: action.payload }
    }),

    [ActionTypes.APP_META_IMAGES.REMOVE]: (state, action) => ({
      ...state,
      appMeta: {
        ...state.appMeta,
        images: (state.appMeta.images || []).filter(image => !action.payload.includes(image.id))
      }
    }),

    [ActionTypes.APP_META_IMAGES.CLEAR]: state => ({
      ...state,
      appMeta: {
        ...state.appMeta,
        images: []
      }
    }),

    [ActionTypes.APP_META_TEMPLATE.SET_FILE]: (state, action) => ({
      ...state,
      appMeta: {
        ...state.appMeta,
        fileTemplateInfo: {
          ...(state.appMeta?.fileTemplateInfo || {}),
          file: action.payload
        }
      }
    }),

    [ActionTypes.APP_META_TEMPLATE.DELETE_FILE]: state => ({
      ...state,
      appMeta: {
        ...state.appMeta,
        fileTemplateInfo: {
          ...(state.appMeta?.fileTemplateInfo || {}),
          file: null
        }
      }
    }),

    [ActionTypes.APP_META_TEMPLATE.SET_FILE_PREVIEW]: (state, action) => ({
      ...state,
      appMeta: {
        ...state.appMeta,
        fileTemplateInfo: {
          ...(state.appMeta?.fileTemplateInfo || {}),
          logo: action.payload
        }
      }
    }),

    [ActionTypes.APP_META_TEMPLATE.DELETE_FILE_PREVIEW]: state => ({
      ...state,
      appMeta: {
        ...state.appMeta,
        fileTemplateInfo: {
          ...(state.appMeta?.fileTemplateInfo || {}),
          logo: null
        }
      }
    }),

    [ActionTypes.APP_META_TEMPLATE.CHANGE_DESCRIPTION]: (state, action) => ({
      ...state,
      appMeta: {
        ...state.appMeta,
        fileTemplateInfo: {
          ...(state.appMeta?.fileTemplateInfo || {}),
          description: action.payload
        }
      }
    }),

    [ActionTypes.TOGGLE_SNAPSHOT_MODAL]: (state, action) => ({
      ...state,
      isSnapshotModalShown: action.payload
    }),

    [ActionTypes.TOGGLE_APP_PUBLISH]: (state, action) => ({
      ...state,
      isAppPublishing: action.payload
    }),

    [ActionTypes.TOGGLE_APP_UNPUBLISH]: (state, action) => ({
      ...state,
      isAppUnpublishing: action.payload
    }),

    [ActionTypes.TOGGLE_APP_PLACEMENT_MODAL](state, action) {
      return {
        ...state,
        appPlacementModalData: {
          state: action.payload ? action.payload.state : false
        }
      }
    }
  }
)

export default appBuilder
