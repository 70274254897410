import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import MultipleTeamsIcon from 'assets/images/icons/ic_team_18_8EA3B1.svg?react'
import PlusIcon from 'assets/images/icons/plus-rounded.svg?react'
import WarningIcon from 'assets/images/icons/warning.svg?react'
import messages from 'constants/messages'
import IconButton from 'components/buttons/IconButton'
import RoundIconButton from 'components/buttons/RoundIconButton'
import LinePlaceholder from 'components/spinners/LinePlaceholder'
import { Tooltip } from 'components/common/tooltip/tooltip'
import FilterPreviewDropdownContainer from 'containers/filters/filterPreview/FilterPreviewDropdownContainer'
import FilterSearchDropdown from './FilterSearchDropdown'
import 'scss/filters/filterSelector/filter-selector.scss'

const FilterDropdownToggle = React.forwardRef((props, ref) => {
  const { isOpen, onToggle } = props

  return (
    <Tooltip
      id="filters-info-toggle-tooltip"
      shouldWrapChildrenWithDiv
      placement="top"
      title={messages.FILTER_DETAILS}
    >
      <RoundIconButton
        onClick={onToggle}
        className={classNames('filter-selector-dropdown-toggle', {
          active: isOpen
        })}
      >
        <i className="up-font-ic-add-hint" ref={ref} />
      </RoundIconButton>
    </Tooltip>
  )
})

const CreateNewFilterButton = ({ onClick }) => (
  <IconButton
    appearance="secondary"
    className="filter-selector-create-filter-button"
    icon={<PlusIcon className="icon" />}
    iconSeparated={false}
    onClick={onClick}
  >
    {messages.CREATE_NEW}
  </IconButton>
)

const FilterSelector = props => {
  const {
    isFiltersListLoading,
    isFilterPreviewLoading,
    isFilterEditDisabled,
    tenantId,
    filterPreview,
    createFilter,
    editFilter,
    placeholder,
    onSearch,
    filters,
    openFiltersList,
    clearFilter,
    applyFilter,
    error
  } = props

  const hasFilterIndicators = filterPreview?.isCrossTeam || filterPreview?.isOpenScope

  return (
    <div className="filter-selector-wrapper">
      <div className={classNames('filter-selector', { error })}>
        {(filterPreview || isFilterPreviewLoading) && (
          <div className="selected-filter">
            {!isFilterPreviewLoading && (
              <span className="filter-name" title={filterPreview.name}>
                {filterPreview.name}
              </span>
            )}
            {isFilterPreviewLoading && <LinePlaceholder height={10} />}
            {hasFilterIndicators && (
              <div className="filter-indicators">
                {filterPreview?.isOpenScope && (
                  <Tooltip
                    placement="top"
                    title={
                      <center>
                        Heads up! System performance may slow by using this open scope filter.
                      </center>
                    }
                    shouldWrapChildrenWithDiv
                  >
                    <WarningIcon className="filter-indicator-icon" />
                  </Tooltip>
                )}
                {filterPreview?.isCrossTeam && (
                  <Tooltip
                    placement="top"
                    title="This filter uses data from multiple teams"
                    shouldWrapChildrenWithDiv
                  >
                    <MultipleTeamsIcon className="filter-indicator-icon" />
                  </Tooltip>
                )}
                <div className="vertical-divider" />
              </div>
            )}
            <Tooltip
              id="filters-edit-tooltip"
              placement="top"
              shouldWrapChildrenWithDiv
              title={isFilterEditDisabled ? messages.FILTER_EDIT_RESTRICTION_MESSAGE : ''}
            >
              <RoundIconButton
                onClick={editFilter}
                disabled={isFilterEditDisabled}
                className="change-filter-button"
              >
                <i className="up-font-ic-pencil-16" />
              </RoundIconButton>
            </Tooltip>
            <FilterPreviewDropdownContainer
              Toggle={FilterDropdownToggle}
              tenantId={tenantId}
              filterUuid={filterPreview ? filterPreview.uuid : ''}
            />
            <RoundIconButton onClick={clearFilter}>
              <i className="up-font-ic-cross-16" />
            </RoundIconButton>
          </div>
        )}
        {!filterPreview && !isFilterPreviewLoading && (
          <div className="filter-placeholder">
            <FilterSearchDropdown
              filters={filters}
              placeholder={placeholder}
              onSearch={onSearch}
              loading={isFiltersListLoading}
              applyFilter={applyFilter}
              error={error}
            />
            <p className="or-text">{messages.OR}</p>
            <CreateNewFilterButton onClick={createFilter} />
          </div>
        )}
        <p className="filters-list-hint">
          {messages.FILTERS_LIST_HINT}
          <span className="link-to-filters-list" onClick={openFiltersList}>
            {messages.LIST_OF_ALL_FILTERS}
          </span>
        </p>
      </div>
    </div>
  )
}

FilterSelector.propTypes = {
  isFilterPreviewLoading: PropTypes.bool,
  isFiltersListLoading: PropTypes.bool,
  placeholder: PropTypes.string,
  createFilter: PropTypes.func,
  editFilter: PropTypes.func,
  clearFilter: PropTypes.func,
  openFiltersList: PropTypes.func,
  onSearch: PropTypes.func,
  applyFilter: PropTypes.func,
  tenantId: PropTypes.string.isRequired,
  filterPreview: PropTypes.shape({
    name: PropTypes.string,
    uuid: PropTypes.string
  }),
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      uuid: PropTypes.string
    })
  ),
  error: PropTypes.string
}

FilterSelector.defaultProps = {
  isFilterPreviewLoading: false,
  isFiltersListLoading: false,
  placeholder: messages.FILTERS_SEARCH_PLACEHOLDER,
  createFilter: null,
  clearFilter: null,
  editFilter: null,
  onSearch: null,
  applyFilter: null,
  openFiltersList: null,
  filterPreview: null,
  filters: []
}

export default FilterSelector
