import { useCallback, useMemo } from 'react'
import classNames from 'classnames'
import AsyncSelect from 'react-select/async'
import { VARIABLES_CONFIG } from 'constants/variables/variableConfiguration/constants'
import { debounce } from 'helpers/common/timeoutHelpers'
import RoundIconButton from 'components/buttons/RoundIconButton'
import {
  type IFilterRuleConditionConfig,
  type IFilterRuleVariable
} from 'features/filter/filter.types'
import './variables-field.scss'

const MAX_VARIABLES_COUNT = 20

type TOptionProps = {
  variable: IFilterRuleVariable
  editVariable?: (variable: IFilterRuleVariable) => void
}

const VariableOption = ({ variable, editVariable }: TOptionProps) => {
  const { name, filter } = variable

  const attributeConfig = useMemo(
    () =>
      filter
        ? // @ts-expect-error
          (VARIABLES_CONFIG.attributes[filter.attribute.propertyName] as IFilterRuleConditionConfig)
        : null,
    [filter]
  )

  const getFormattedCoordinates = useCallback(() => {
    if (!attributeConfig?.withCoordinates || !filter) return null

    return `[${String(filter.attribute.x + 1)},${String(filter.attribute.y + 1)}]`
  }, [attributeConfig?.withCoordinates, filter])

  const handleVariableClick = (event: MouseEvent) => {
    event.stopPropagation()

    if (editVariable) {
      editVariable(variable)
    }
  }

  return (
    <div className="filter-variable-field-option" title={name}>
      <div className="variable-name">{name}</div>
      {attributeConfig && (
        <div className="variable-attribute">
          {attributeConfig.name}
          {getFormattedCoordinates()}
        </div>
      )}
      {editVariable && (
        <div className="variable-controls-overlay">
          {/* @ts-expect-error*/}
          <RoundIconButton
            className="edit-variable-button"
            appearance="gray-bg"
            onClick={handleVariableClick}
          >
            <i className="icon icon-long-arrow-6c7e8a" />
          </RoundIconButton>
        </div>
      )}
    </div>
  )
}

const GroupLabel = () => (
  <div className="filter-variable-field-options-label">
    <div className="variable-name">Name</div>
    <div className="variable-attribute">V=Attribute</div>
  </div>
)

type TProps = {
  className?: string
  variables: IFilterRuleVariable[]
  isMulti: boolean
  errorMessage?: string
  hasError?: boolean
  isDisabled?: boolean
  editVariable?: (variable: IFilterRuleVariable) => void
  onLoadOptions?: (inputValue: string) => Promise<unknown[]>
  onChange: (variables: IFilterRuleVariable[]) => void
  onFocus?: () => void
  onBlur?: () => void
}

export const VariablesField = ({
  className,
  variables,
  errorMessage,
  hasError,
  isMulti,
  isDisabled,
  onChange,
  onFocus,
  onBlur,
  editVariable,
  onLoadOptions
}: TProps) => {
  const isVariablesLimitReached = useMemo(
    () => variables.length >= MAX_VARIABLES_COUNT,
    [variables.length]
  )

  const handleLoadOptions = useCallback(
    (inputValue: string, callback: (options: unknown[]) => void) => {
      if (isVariablesLimitReached || !onLoadOptions) {
        callback([])
        return
      }

      return onLoadOptions(inputValue).then(_options => callback(_options))
    },
    [isVariablesLimitReached, onLoadOptions]
  )

  const debouncedLoadOptions = useMemo(() => debounce(handleLoadOptions, 300), [handleLoadOptions])

  const getFormattedOptionLabel = useCallback(
    (variable: IFilterRuleVariable) => (
      <VariableOption variable={variable} editVariable={editVariable} />
    ),
    [editVariable]
  )

  const getEmptyStateMessage = useCallback(
    () => (isVariablesLimitReached ? 'You have reached the max variables limit' : 'No options'),
    [isVariablesLimitReached]
  )

  const handleChange = (values?: IFilterRuleVariable[]) => {
    onChange(values ?? [])
  }

  return (
    <div className={classNames('filter-variables-field', className)}>
      <AsyncSelect
        classNamePrefix="upboard-rs"
        className={classNames('upboard-react-select without-arrow medium', {
          'has-error': hasError
        })}
        isMulti={isMulti}
        placeholder="Find variable"
        value={isMulti ? variables : variables[0]}
        isDisabled={isDisabled}
        loadOptions={debouncedLoadOptions}
        noOptionsMessage={getEmptyStateMessage}
        formatOptionLabel={getFormattedOptionLabel}
        formatGroupLabel={GroupLabel}
        getOptionValue={option => option.id}
        getOptionLabel={option => option.name}
        isClearable
        // @ts-expect-error
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {hasError && <div className="error-message">{errorMessage}</div>}
    </div>
  )
}
