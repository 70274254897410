import {
  CLASS_NAMES_CONDITION,
  FILTERS_ACCESSORY,
  FILTERS_OBJECTS
} from 'constants/filters/filtersConstants'
import { requestWrapper, url } from 'helpers/fetchHelpers'

// get suggestions for filters
export const getSuggestions = ({ tenantId, type, query }) =>
  requestWrapper({
    rUrl: `${url}tenant/${tenantId}/query/suggestion?type=${type}&query=${query}`,
    type: 'GET',
    payload: {}
  })

export const getFilter = ({ tenantId, uuid }) =>
  requestWrapper({
    rUrl: `${url}tenant/${tenantId}/filter/${uuid}`,
    type: 'GET',
    payload: {}
  })

export const getFiltersList = ({ tenantId, query: payload }) => {
  const query = {
    sortBy: payload.sortBy,
    sortDirection: payload.sortDirection,
    offset: payload.offset,
    limit: payload.limit
  }

  if (payload.search) {
    query.name = payload.search
  }

  if (payload.object !== FILTERS_OBJECTS.ALL) {
    query.object = payload.object
  }

  if (payload.accessory !== FILTERS_ACCESSORY.ALL) {
    query.accessory = payload.accessory
  }

  return requestWrapper({
    rUrl: `${url}tenant/${tenantId}/filter`,
    type: 'GET',
    payload: query
  })
}

export const createFilter = ({ tenantId, payload }) =>
  requestWrapper({
    rUrl: `${url}tenant/${tenantId}/filter`,
    type: 'POST',
    payload
  })

export const editFilter = ({ tenantId, payload }) =>
  requestWrapper({
    rUrl: `${url}tenant/${tenantId}/filter/${payload.uuid}`,
    type: 'PUT',
    payload
  })

export const deleteFilter = ({ tenantId, uuid }) =>
  requestWrapper({
    rUrl: `${url}tenant/${tenantId}/filter/${uuid}`,
    type: 'DELETE',
    payload: {}
  })

export const getRulesConfig = ({ tenantId }) =>
  requestWrapper({
    rUrl: `${url}tenant/${tenantId}/query/settings`,
    type: 'GET',
    payload: {}
  })

export const getFiltersClassNamesOptions = ({ tenantId }) =>
  requestWrapper({
    rUrl: `${url}tenant/${tenantId}/query/classname`,
    type: 'GET',
    payload: {}
  })

export const getFullFiltersConfig = payload =>
  Promise.all([getRulesConfig(payload), getFiltersClassNamesOptions(payload)]).then(
    ([{ data: config }, { data: classNamesList }]) => ({
      ...Object.entries(config.attributes).reduce((acc, [key, value]) => {
        if (!value.isFilterable) {
          return acc
        }

        acc[key] = value

        return acc
      }, {}),
      [CLASS_NAMES_CONDITION]: {
        ...config.attributes[CLASS_NAMES_CONDITION],
        options: classNamesList
      }
    })
  )

export const copyFilter = payload =>
  requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/filter/${payload.uuid}/copy`,
    type: 'POST',
    payload: {
      name: payload.name,
      targetTenantId: payload.targetTenantId
    }
  })

export const fetchFilterConnections = (filterId, { tenantId }) =>
  requestWrapper({
    rUrl: `${url}tenant/${tenantId}/filter/${filterId}/connections`,
    type: 'GET'
  })
