export const SET_BOARD_CARDS = 'SET_BOARD_CARDS'
export const SET_BOARD_CARDS_COMMENTS_COUNT = 'SET_BOARD_CARDS_COMMENTS_COUNT'
export const ADD_NEW_CARDS = 'ADD_NEW_CARDS'
export const RELOAD_BOARD_CARDS = 'RELOAD_BOARD_CARDS'

export const SET_BOARD_CARDS_WIDGETS = 'SET_BOARD_CARDS_WIDGETS'
export const UPDATE_BOARD_CARDS_WIDGETS = 'UPDATE_BOARD_CARDS_WIDGETS'
export const APPEND_CARD_WIDGETS = 'APPEND_CARD_WIDGETS'
export const TOGGLE_BOARD_WIDGETS_LOADING = 'TOGGLE_BOARD_WIDGETS_LOADING'

export const APPLY_CARDS_OPERATIONS = 'APPLY_CARDS_OPERATIONS'

export const RECEIVE_BOARD_MENU_ERROR = 'RECEIVE_BOARD_MENU_ERROR'

export const REQUEST_BOARD_MENU = 'REQUEST_BOARD_MENU'
export const RECEIVE_BOARD_MENU = 'RECEIVE_BOARD_MENU'
export const REORDER_RECENT_BOARDS = 'REORDER_RECENT_BOARDS'

export const RECEIVE_BOARD = 'RECEIVE_BOARD'
export const TOGGLE_BOARD_NAME_LOADER = 'TOGGLE_BOARD_NAME_LOADER'
export const RECEIVE_UPDATE_BOARD = 'RECEIVE_UPDATE_BOARD'
export const CLEAR_CURRENT_BOARD = 'CLEAR_CURRENT_BOARD'

// state of 'rename board' input
export const RENAME_BOARD_TOGGLE = 'RENAME_BOARD_TOGGLE'
export const TOGGLE_RENAME_BOARD_MODAL = 'TOGGLE_RENAME_BOARD_MODAL'

export const TOGGLE_CARD_TITLE_EDITING = 'TOGGLE_CARD_TITLE_EDITING'

export const TOGGLE_BOARD_CREATION_MODAL = 'TOGGLE_BOARD_CREATION_MODAL'
export const TOGGLE_BOARD_COPY_MODAL = 'TOGGLE_BOARD_COPY_MODAL'
export const TOGGLE_BOARD_DELETE_MODAL = 'TOGGLE_BOARD_DELETE_MODAL'

export const RECEIVE_COLUMN_UPDATE = 'RECEIVE_COLUMN_UPDATE'

export const REQUEST_DELETE_CARD = 'REQUEST_DELETE_CARD'
export const RECEIVE_DELETE_CARD = 'RECEIVE_DELETE_CARD'
export const RECEIVE_DELETE_CARD_ERROR = 'RECEIVE_DELETE_CARD_ERROR'

export const TOGGLE_COLUMN_DELETE_MODAL = 'TOGGLE_COLUMN_DELETE_MODAL'
export const TOGGLE_CARD_DELETE_MODAL = 'TOGGLE_CARD_DELETE_MODAL'

export const RECEIVE_BOARD_PERMISSIONS = 'RECEIVE_BOARD_PERMISSIONS'

export const RECEIVE_WIDGET_UPDATE_ON_DETAILED_VIEW = 'RECEIVE_WIDGET_UPDATE_ON_DETAILED_VIEW'
export const RECEIVE_WIDGET_CREATE_ON_DETAILED_VIEW = 'RECEIVE_WIDGET_CREATE_ON_DETAILED_VIEW'
export const RECEIVE_WIDGET_DELETE_ON_DETAILED_VIEW = 'RECEIVE_WIDGET_DELETE_ON_DETAILED_VIEW'

export const SET_CARDS_POSITION_ETAG = 'SET_CARDS_POSITION_ETAG'

export const UPDATE_CARD_OWNERS = 'UPDATE_CARD_OWNERS'
export const ADD_CARD_PARTICIPANTS = 'ADD_CARD_PARTICIPANTS'
export const REMOVE_CARD_PARTICIPANTS = 'REMOVE_CARD_PARTICIPANTS'

export const ADD_CARD_COMMENT = 'ADD_CARD_COMMENT'
export const EDIT_CARD_COMMENT = 'EDIT_CARD_COMMENT'
export const DELETE_CARD_COMMENT = 'DELETE_CARD_COMMENT'
export const SET_CARD_COMMENTS = 'SET_CARD_COMMENTS'
export const CLEAN_UP_CARD_COMMENTS = 'CLEAN_UP_COMMENTS'
export const SET_CARD_COMMENTS_LIST_OFFSET = 'SET_CARD_COMMENTS_LIST_OFFSET'
export const SET_CARD_COMMENTS_LOADED = 'SET_CARD_COMMENTS_LOADED'

export const ADD_TOAST_MESSAGE = 'ADD_TOAST_MESSAGE'
export const TOGGLE_TOAST_MESSAGE_VISIBLE = 'TOGGLE_TOAST_MESSAGE_VISIBLE'
export const HIDE_TOAST_MESSAGE = 'HIDE_TOAST_MESSAGE'

export const TOAST_MESSAGE_TYPE = 'TOAST_MESSAGE_TYPE'
export const DRAFT_MESSAGE_TYPE = 'DRAFT_MESSAGE_TYPE'
export const MS_AUTH_MESSAGE_TYPE = 'MS_AUTH_MESSAGE_TYPE'
export const COPY_BOARD_INDICATOR_TYPE = 'COPY_BOARD_INDICATOR_TYPE'
export const EXPORT_BUNDLE_INDICATOR_TYPE = 'EXPORT_BUNDLE_INDICATOR_TYPE'
export const IMPORT_BUNDLE_INDICATOR_TYPE = 'IMPORT_BUNDLE_INDICATOR_TYPE'
export const IMPORT_APP_INDICATOR_TYPE = 'IMPORT_APP_INDICATOR_TYPE'
export const WORKFLOW_EXECUTION_INDICATOR_TYPE = 'WORKFLOW_EXECUTION_INDICATOR_TYPE'
export const APP_PUBLISHING_INDICATOR_TYPE = 'APP_PUBLISHING_INDICATOR_TYPE'
export const APP_TEMPLATE_DOWNLOAD_INDICATOR_TYPE = 'APP_TEMPLATE_DOWNLOAD_INDICATOR_TYPE'

export const REQUEST_BOARD_SETTINGS = 'REQUEST_BOARD_SETTINGS'
export const RECEIVE_BOARD_SETTINGS = 'RECEIVE_BOARD_SETTINGS'
export const RECEIVE_BOARD_SETTINGS_ERROR = 'RECEIVE_BOARD_SETTINGS_ERROR'

export const DISABLE_BOARD_DRAG = 'DISABLE_BOARD_DRAG'

export const TOGGLE_BOARDS_EXPORT_MODAL = 'TOGGLE_BOARDS_EXPORT_MODAL'

export const TOGGLE_TENANT_CREATION_MODAL = 'TOGGLE_TENANT_CREATION_MODAL'

export const CLEAR_CARD_SNAPSHOT = 'CLEAR_CARD_SNAPSHOT'

export const TOGGLE_WELCOME_CARD_PREVIEW = 'TOGGLE_WELCOME_CARD_PREVIEW'

export const SET_BOARD_LOADING_ERROR = 'SET_BOARD_LOADING_ERROR'

export const SET_BOARD_THIRD_PARTY_WIDGETS = 'SET_BOARD_THIRD_PARTY_WIDGETS'
