import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import RibbonIconButton from 'components/ribbonTools/RibbonIconButton'

import 'scss/ribbonTools/ribbon-clearable-button.scss'

const RibbonClearableButton = props => {
  const {
    id,
    children,
    className,
    wrapperClassName,
    clearClassName,
    disabled,
    clearDisabled,
    active,
    tooltipText,
    clearTooltipText,
    tooltipPlacement,
    onClick,
    onClearClick
  } = props

  return (
    <div className={classNames('ribbon-clearable-button', wrapperClassName)}>
      <RibbonIconButton
        id={id}
        className={classNames('ribbon-clearable-primary-button', className)}
        tooltipPlacement={tooltipPlacement}
        tooltipText={tooltipText}
        onClick={onClick}
        active={active}
        disabled={disabled}
      >
        {children}
      </RibbonIconButton>
      {active && (
        <RibbonIconButton
          id={`${id}-clear-button`}
          className={classNames('ribbon-clearable-clear-button', clearClassName)}
          tooltipPlacement={tooltipPlacement}
          tooltipText={clearTooltipText}
          onClick={onClearClick}
          disabled={clearDisabled}
        >
          <i className="up-font-ic-cross-xs" />
        </RibbonIconButton>
      )}
    </div>
  )
}

RibbonClearableButton.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  clearClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  onClick: PropTypes.func,
  onClearClick: PropTypes.func,
  disabled: PropTypes.bool,
  clearDisabled: PropTypes.bool,
  active: PropTypes.bool,
  tooltipText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  clearTooltipText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  tooltipPlacement: PropTypes.oneOf(['bottom', 'top', 'right', 'left'])
}

RibbonClearableButton.defaultProps = {
  className: '',
  clearClassName: '',
  wrapperClassName: '',
  onClick: null,
  onClearClick: null,
  disabled: false,
  clearDisabled: false,
  active: false,
  tooltipText: null,
  clearTooltipText: null,
  tooltipPlacement: 'bottom'
}

export default RibbonClearableButton
