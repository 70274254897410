import { requestWrapper, url } from 'helpers/fetchHelpers'

export const getAppStoreConfig = () =>
  requestWrapper({
    rUrl: `${url}app-store/configs`,
    type: 'GET',
    cache: true
  })

export const requestSendEmailToAdmin = appId =>
  requestWrapper({
    rUrl: `${url}appstore/${appId}/admin/notification`,
    type: 'POST'
  })

export const requestSendEmailToAdminCollection = collectionId =>
  requestWrapper({
    rUrl: `${url}appstore/collection/${collectionId}/admin/notification`,
    type: 'POST'
  })
