import messages from '../../constants/messages'
import { validateLongTextInputs } from '../validationHelpers'
import { EFieldKeys } from '../../constants/workflowBuilder/blocksFieldsKeys'
import { WF_USERS } from '../../constants/workflows'

export const DEFAULT_INPUT_COUNT = 100
export const COMMENT_BODY_MAX_LENGTH = 1000

export const isInputToken = part => typeof part === 'object' && part.fieldName

export const isNodeAnyLevelDropdownInput = node =>
  node.classList.value === 'any-level-dropdown__input'
export const AUTHOR_FOR_COMMENT_OPTIONS = {
  [WF_USERS.CURRENT_USER]: {
    value: WF_USERS.CURRENT_USER,
    label: messages.USER_WHO_TRIGGERED_WORKFLOW_LABEL
  },
  [WF_USERS.WORKSPACE_USER]: {
    value: WF_USERS.WORKSPACE_USER,
    label: messages.WORKSPACE_NAME_LABEL
  }
}

export const AUTHOR_FOR_COMMENT_OPTIONS_LIST = Object.values(AUTHOR_FOR_COMMENT_OPTIONS)

export const validateCardCommentBlock = ({ block }) => {
  let error = {}
  const longTextError = validateLongTextInputs({
    meta: block.meta,
    fieldKey: EFieldKeys.TEXT,
    input: block.input
  })
  if (!block.input || !block.input[EFieldKeys.SOURCE_INPUT]) {
    error[EFieldKeys.SOURCE_INPUT] = messages.emptyField
    return { error }
  }

  if (block.meta && !block.meta[EFieldKeys.TEXT].length) {
    error[EFieldKeys.TEXT] = messages.emptyField
    return { error }
  }

  if (block.meta && block.meta[EFieldKeys.TEXT].length) {
    error = { ...error, ...longTextError }
  }

  return { error }
}
