import { history } from 'services/common/history.service'
import { getAppHomepageRoute, navigateToBoard } from 'helpers/routesHelpers'

export const handleGoBack = ({ boardAppId, tenantId, boardId, navItems, boardIds }) => {
  if (history.length > 1) {
    history.goBack()
  } else if (boardAppId) {
    const appHomePage = navItems?.find(item => item.isHomepage)
    if (appHomePage) {
      history.push(getAppHomepageRoute({ homepage: appHomePage, boardIds }))
    } else {
      navigateToBoard({ tenantId, boardId })
    }
  }
}
