import messages from '../../constants/messages'
import { getFieldNamesItems } from '../workflowBuilder/inputOperations'
import { validateLongTextInputs } from '../validationHelpers'
import { EFieldKeys } from '../../constants/workflowBuilder/blocksFieldsKeys'
import { WF_USERS } from '../../constants/workflows'

export const SUBJECT_INPUTS_COUNT = 2
export const BODY_INPUTS_COUNT = 20
export const USER_EMAIL_INPUTS_COUNT = 10
export const MAX_TOKENS_COUNT = 20
export const BODY_MAX_LENGTH = 2000
export const EMAIL_SUBJECT_MAX_LENGTH = 100

const validateRecipientPresence = block => {
  const areSelectedRoles = block.meta[EFieldKeys.ROLES] && block.meta[EFieldKeys.ROLES].length

  if (areSelectedRoles) {
    return null
  }

  let error
  const fieldNames = getFieldNamesItems(EFieldKeys.USER_INPUT, block.inputDefinition)

  if (block.meta[EFieldKeys.COLUMN_NUMBER]) {
    fieldNames.forEach((name, index) => {
      if (!block.input[name] && index < block.meta[EFieldKeys.COLUMN_NUMBER]) {
        error = { ...error, [name]: messages.emptyField }
      }
    })
  }
  return error
}

export const validateFeedNotificationBlock = ({ block }) => {
  let error = {}
  const longBodyError = validateLongTextInputs({
    meta: block.meta,
    fieldKey: EFieldKeys.TEXT,
    input: block.input
  })
  const recipientPresence = validateRecipientPresence(block)

  if (block.meta && !block.meta[EFieldKeys.TEXT].length) {
    error[EFieldKeys.TEXT] = messages.emptyField
  }

  if (block.meta && block.meta[EFieldKeys.TYPE] === WF_USERS.CURRENT_USER) {
    error = {
      ...error,
      ...longBodyError
    }
  }
  error = {
    ...error,
    ...recipientPresence
  }

  return { error }
}
