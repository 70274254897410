import { GrpcService } from 'services/grpc.service'
import {
  type EntitlementServiceClient,
  type Entitlement,
  type QueryRequest,
  type CreateRequest,
  type DeployRequest,
  type CancelRequest,
  type UpdateDescRequest,
  EntitlementServiceDefinition
} from '_proto/entitlement/v1/entitlement.pb'
import {
  ENTITLEMENT_SOURCES,
  ENTITLEMENT_STATUSES_LIST,
  ENTITLEMENT_TYPES_LIST,
  MANUAL_ENTITLEMENT_CUSTOMER_ID
} from './entitlements.constants'

export class _EntitlementsGrpcService {
  private _client: EntitlementServiceClient = GrpcService.createClient(EntitlementServiceDefinition)

  parseEntitlementList = (entitlements: Entitlement[]) => {
    return entitlements.map(ent => ({
      id: ent.key?.id,
      workspaceId: ent.key?.scope?.organizationId,
      appStoreIds: ent.appStoreIds,
      customerId: ent.customerId,
      customerEmail: ent.customerEmail,
      name: ent.name,
      notes: ent.description,
      type: ENTITLEMENT_TYPES_LIST.find(type => type.grpcId === ent.type),
      status: ENTITLEMENT_STATUSES_LIST.find(status => status.grpcId === ent.status),
      stripeProductId: ent.stripeProductId,
      subscriptionId: ent.subscriptionId,
      deploymentDate: ent.deploymentAt,
      expiryDate: ent.expiredAt,
      verifyLink: ent.verifyLink,
      collectionIds: ent.collectionIds,
      creationDate: ent.key?.createdAt,
      trialPeriodEnd: ent.trialPeriodEnd,
      source:
        ent.customerId === MANUAL_ENTITLEMENT_CUSTOMER_ID
          ? ENTITLEMENT_SOURCES.manual.id
          : ENTITLEMENT_SOURCES.api.id,
      isCancellationPending: ent.isCancellationPending,
      isApplication: ent.appStoreIds.length > 0,
      isHasTrialPeriodEnd: !!ent.trialPeriodEnd
    }))
  }

  fetchEntitlementList = async (config: { request: QueryRequest }) => {
    return this._client.query(config.request)
  }

  createEntitlements = async (config: { request: CreateRequest }) => {
    return this._client.create(config.request)
  }

  deployEntitlements = async (config: { request: DeployRequest }) => {
    return this._client.deploy(config.request)
  }

  cancelEntitlements = async (config: { request: CancelRequest }) => {
    return this._client.cancel(config.request)
  }

  updateEntitlementsDescription = async (config: { request: UpdateDescRequest }) => {
    return this._client.updateDescription(config.request)
  }
}

export const EntitlementsGrpcService = new _EntitlementsGrpcService()
