export const TOGGLE_JIRA_CONNECTION_MODAL = 'TOGGLE_JIRA_CONNECTION_MODAL'

export const GET_USER_JIRA_CREDENTIALS = 'GET_USER_JIRA_CREDENTIALS'

export const REQUEST_SAVE_USER_PROFILE = 'REQUEST_SAVE_USER_PROFILE'
export const RECEIVE_SAVE_USER_PROFILE = 'RECEIVE_SAVE_USER_PROFILE'
export const RECEIVE_SAVE_USER_PROFILE_ERROR = 'RECEIVE_SAVE_USER_PROFILE_ERROR'

export const RECEIVE_USER = 'RECEIVE_USER'

export const CLEAR_DRAFT = 'CLEAR_DRAFT'

export const JIRA_PROJECTS_REQUEST = 'JIRA_PROJECTS_REQUEST'
export const JIRA_PROJECTS_RECEIVE = 'JIRA_PROJECTS_RECEIVE'
export const JIRA_PROJECTS_RECEIVE_ERROR = 'JIRA_PROJECTS_RECEIVE_ERROR'

export const JIRA_ISSUE_TYPES_REQUEST = 'JIRA_ISSUE_TYPES_REQUEST'
export const JIRA_ISSUE_TYPES_RECEIVE = 'JIRA_ISSUE_TYPES_RECEIVE'
export const JIRA_ISSUE_TYPES_RECEIVE_ERROR = 'JIRA_ISSUE_TYPES_RECEIVE_ERROR'

export const JIRA_STATUSES_REQUEST = 'JIRA_STATUSES_REQUEST'
export const JIRA_STATUSES_RECEIVE = 'JIRA_STATUSES_RECEIVE'
export const JIRA_STATUSES_RECEIVE_ERROR = 'JIRA_STATUSES_RECEIVE_ERROR'

export const JIRA_USERS_REQUEST = 'JIRA_USERS_REQUEST'
export const JIRA_USERS_RECEIVE = 'JIRA_USERS_RECEIVE'
export const JIRA_USERS_RECEIVE_ERROR = 'JIRA_USERS_RECEIVE_ERROR'

export const JIRA_COMPONENTS_REQUEST = 'JIRA_COMPONENTS_REQUEST'
export const JIRA_COMPONENTS_RECEIVE = 'JIRA_COMPONENTS_RECEIVE'
export const JIRA_COMPONENTS_RECEIVE_ERROR = 'JIRA_COMPONENTS_RECEIVE_ERROR'

export const JIRA_FILTERS_REQUEST = 'JIRA_FILTERS_REQUEST'
export const JIRA_FILTERS_RECEIVE = 'JIRA_FILTERS_RECEIVE'
export const JIRA_FILTERS_RECEIVE_ERROR = 'JIRA_FILTERS_RECEIVE_ERROR'

export const UPDATE_TEAM_SETTINGS = 'UPDATE_TEAM_SETTINGS'
export const CLEAR_TEAM_SETTINGS = 'CLEAR_TEAM_SETTINGS'
export const UPDATE_BOARD_MENU_TEAM_NAME = 'UPDATE_BOARD_MENU_TEAM_NAME'

export const UPDATE_BOARD_NAME = 'UPDATE_BOARD_NAME'

export const REQUEST_ACTIVE_ORGANIZATIONS = 'REQUEST_ACTIVE_ORGANIZATIONS'
export const RECEIVE_ORGANIZATIONS = 'RECEIVE_ORGANIZATIONS'
export const RECEIVE_ACTIVE_ORGANIZATION = 'RECEIVE_ACTIVE_ORGANIZATION'
export const RECEIVE_ACTIVE_ORGANIZATIONS_ERROR = 'RECEIVE_ACTIVE_ORGANIZATIONS_ERROR'
export const CLEAR_ORGANIZATIONS = 'CLEAR_ORGANIZATIONS'

export const REQUEST_ORGANIZATION_PERMISSIONS = 'REQUEST_ORGANIZATION_PERMISSIONS'
export const RECEIVE_ORGANIZATION_PERMISSIONS = 'RECEIVE_ORGANIZATION_PERMISSIONS'
export const RECEIVE_ORGANIZATION_PERMISSIONS_ERROR = 'RECEIVE_ORGANIZATION_PERMISSIONS_ERROR'

export const REQUEST_SAVE_ORGANIZATION_PROFILE = 'REQUEST_SAVE_ORGANIZATION_PROFILE'
export const RECEIVE_SAVE_ORGANIZATION_PROFILE = 'RECEIVE_SAVE_ORGANIZATION_PROFILE'
export const RECEIVE_SAVE_ORGANIZATION_PROFILE_ERROR = 'RECEIVE_SAVE_ORGANIZATION_PROFILE_ERROR'

export const TOGGLE_IS_ONBOARD_SETTINGS = 'TOGGLE_IS_ONBOARD_SETTINGS'

export const REQUEST_HOME_PAGE_CONFIG = 'REQUEST_HOME_PAGE_CONFIG'
export const RECEIVE_HOME_PAGE_CONFIG = 'RECEIVE_HOME_PAGE_CONFIG'
export const RECEIVE_HOME_PAGE_CONFIG_ERROR = 'RECEIVE_HOME_PAGE_CONFIG_ERROR'

export const TOGGLE_BOARD_IMPORT = 'TOGGLE_BOARD_IMPORT'

export const SET_WORKSPACE_SUBSCRIPTIONS = 'SET_WORKSPACE_SUBSCRIPTIONS'
export const SET_WORKSPACE_COLLECTIONS = 'SET_WORKSPACE_COLLECTIONS'

export const UPDATE_NEW_IMPORTED_APPS = 'UPDATE_NEW_IMPORTED_APPS'
