import { getFilter, getFullFiltersConfig } from 'api/filtersAPI'
import { getResourcesData } from 'api/workflowAPI'
import { mapRulesToResourceData, mapResourceDataToRules } from 'helpers/filters/conditionsHelpers'
import * as actions from '../constants/actionTypes'
import { organizationIdSelector } from '../selectors'

export function toggleFiltersListLoading(payload) {
  return { type: actions.TOGGLE_FILTERS_LIST_LOADING, payload }
}

export function setFiltersConfig(payload) {
  return { type: actions.SET_FILTERS_CONFIG, payload }
}

export function getFiltersConfig(payload) {
  return dispatch => {
    dispatch(toggleFiltersListLoading(true))

    return getFullFiltersConfig(payload)
      .then(config => dispatch(setFiltersConfig(config)))
      .finally(() => dispatch(toggleFiltersListLoading(false)))
      .catch(err => console.error(err))
  }
}

export const getFilterRulesResourceNames =
  (tenantId, { filters }) =>
  (dispatch, getState) => {
    const organizationId = organizationIdSelector(getState())
    const payload = mapRulesToResourceData(filters)
    if (!payload.length) {
      return Promise.resolve(filters.rules)
    }
    return getResourcesData({ organizationId, tenantId, payload }).then(response => {
      const { data } = response
      return mapResourceDataToRules(filters, data, organizationId)
    })
  }

export const getFilterPreview =
  ({ tenantId, filter }) =>
  async dispatch => {
    const rules = await dispatch(getFilterRulesResourceNames(tenantId, filter))
    return {
      ...filter,
      filters: {
        ...filter.filters,
        rules
      }
    }
  }

export const getFilterData =
  ({ tenantId, filterUuid }) =>
  dispatch =>
    getFilter({ tenantId, uuid: filterUuid }).then(async response => {
      const filterPreview = await dispatch(
        getFilterPreview({
          tenantId,
          filter: response.data
        })
      )
      return filterPreview
    })
