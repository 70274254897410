import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import '../../scss/spinners/line-placeholder.scss'

const LinePlaceholder = props => {
  const { as: Component, height, className, appearance } = props

  return (
    <Component
      className={classNames('line-placeholder', appearance, className)}
      style={{ height, borderRadius: height / 2 }}
    />
  )
}

LinePlaceholder.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.number,
  className: PropTypes.string,
  appearance: PropTypes.oneOf(['gray', 'dark-gray'])
}

LinePlaceholder.defaultProps = {
  as: 'span',
  height: 10,
  className: '',
  appearance: 'gray'
}

export default LinePlaceholder
