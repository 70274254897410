import gql from 'graphql-tag'

const WidgetConnectionObjectFragmentName = 'WidgetConnectionObjectFragment'

export const WidgetConnectionObjectFragment = {
  name: WidgetConnectionObjectFragmentName,
  fragment: gql`
        fragment ${WidgetConnectionObjectFragmentName} on WidgetConnectionObject {
            tenantId
            boardId
            widgetUuid
            cardUuid
            widgetTitle
            cardName
            widgetClassName
            type
        }
    `
}
