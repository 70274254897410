const workspaceMembers = {
  members: [],
  isWorkspaceMembersFetching: false,
  managerAssignmentModalData: {
    state: false,
    data: {}
  },
  workspaceMemberDeleteConfirmationData: {
    state: false,
    data: {}
  },
  deleteWorkspaceMemberRejectionData: { state: false, data: {} }
}

export default workspaceMembers
