import { type ReactNode, useMemo } from 'react'
import { StyledEngineProvider } from '@mui/material/styles'
import { NextUIProvider } from '@nextui-org/react'
import { matchPath, useHistory } from 'react-router-dom'
import { PATHS } from 'constants/paths.constants'
import IssueCounterContainer from 'containers/modals/IssueCounterContainer'
import WorkflowOverlayContainer from 'containers/workflowOverlay/WorkflowOverlayContainer'
import { useServiceWorker } from 'hooks/useServiceWorker'
import ToastsQueueContainer from '../ToastsQueueContainer'
import { useOrganizationSubscriptionAccess } from './hooks/useOrganizationSubscriptionAccess'
import { useSessionExpirationNotification } from './hooks/useSessionExpirationNotification'
import 'scss/main.scss'
import '@praxie/react-quill/dist/quill.snow.css'

type TProps = {
  children: ReactNode
}

export const AppContainer = ({ children }: TProps) => {
  const history = useHistory()

  useServiceWorker()

  const shouldSkipSubscriptionCheck = !!matchPath(history.location.pathname, {
    path: PATHS.snapshot.routerPath
  })

  useOrganizationSubscriptionAccess(shouldSkipSubscriptionCheck)
  useSessionExpirationNotification()

  return useMemo(
    () => (
      <StyledEngineProvider injectFirst>
        <NextUIProvider className="next-ui-provider">
          {children}
          <ToastsQueueContainer />
          <IssueCounterContainer />
          <WorkflowOverlayContainer />
          <div id="portal-container" className="portal-container" />
        </NextUIProvider>
      </StyledEngineProvider>
    ),
    [children]
  )
}
