import { Route } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import type { Integration } from '@sentry/types'
import { ENVIRONMENTS, ENVIRONMENTS_LIST } from 'constants/common'
import { history } from 'services/common/history.service'

export const initSentry = () => {
  const environment = ENVIRONMENTS_LIST.find(env => window.location.host.includes(env.domain))

  if (environment) {
    const gitVersion = process.env.GIT_VERSION || 'undefined'

    Sentry.init({
      dsn: 'https://f3af28f00da8479fbbfd6332545f7781@o1131882.ingest.sentry.io/6176915',
      release: `praxie-ui@${gitVersion}`,
      environment: environment.name,
      integrations: () => {
        const integrations = [
          new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
          })
        ] as Integration[]

        // Enable RRWeb replays for production and stage only.
        if (
          environment?.id === ENVIRONMENTS.production.id ||
          environment?.id === ENVIRONMENTS.stage.id
        ) {
          integrations.push(new Sentry.Replay())
        }

        return integrations
      },
      attachStacktrace: true,
      autoSessionTracking: true,
      normalizeDepth: 10,
      beforeSend: (event: Sentry.Event, hint?: Sentry.EventHint) => {
        if (hint) {
          const error = hint.originalException

          // https://leverxeu.atlassian.net/browse/EUPBOARD01-12367
          // Sometimes browser tries to load bundles that are no longer existing.
          // In that case, we want to reload the page to invalidate cache.
          const hasToRefresh =
            error instanceof Error && /Loading( CSS)? chunk [\d]+ failed/.test(error.message)

          if (hasToRefresh) {
            window.location.reload()
            return null
          }
        }
        return event
      }
    })
  }

  return {
    Route: Sentry.withSentryRouting(Route),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    reduxEnhancer: Sentry.createReduxEnhancer()
  }
}
