import BoardsBundleIcon from 'assets/images/icons/ic_boards_bundle.svg?react'
import messages from 'constants/messages'
import { type IJob, EJobStatuses } from 'features/jobs/jobs.types'
import CircleSpinner from 'components/spinners/CircleSpinner'

const getExportErrorMessage = (error: IJob['error']) => {
  if (error?.type === 'NotOrgAdminExportCrossTeamFilter') {
    return 'Oops! Export cannot be completed due to cross-team filter security constraints. Contact your Workspace Admin to run your export.'
  }

  return messages.OPERATION_FAILED
}

type TProps = {
  job: IJob
  retryExportBoardsBundle: () => void
}

export const ExportIndicatorItem = ({ job, retryExportBoardsBundle }: TProps) => (
  <div className="board_item">
    <div className="board_data">
      <BoardsBundleIcon />
      <div className="board_content">
        <span className="board_name bold">
          {job.data.bundleName}
          {job.data.boardIds && (
            <span className="gray-text">
              {messages.BOARDS_IN_BRACKETS(job.data.boardIds.length)}
            </span>
          )}
        </span>
        {job.status === EJobStatuses.ERROR && (
          <span className="error-text">{getExportErrorMessage(job.error)}</span>
        )}
      </div>
    </div>
    <span className="board_progress">
      {job.status !== EJobStatuses.DONE && job.status !== EJobStatuses.ERROR && (
        <CircleSpinner className="copied-board__spinner" />
      )}
      {job.status === EJobStatuses.DONE && (
        <span className="blue-link static">{messages.EXPORTED}</span>
      )}
      {job.status === EJobStatuses.ERROR && (
        <span className="blue-link" onClick={retryExportBoardsBundle}>
          {messages.RETRY}
        </span>
      )}
    </span>
  </div>
)
