import { type ForwardedRef, type InputHTMLAttributes, type ReactNode, forwardRef } from 'react'
import classNames from 'classnames'

type TProps = InputHTMLAttributes<HTMLInputElement> & {
  error?: string | null
  label: ReactNode
  children?: ReactNode
  hintText?: string | ReactNode
}

const FormField = forwardRef((props: TProps, ref: ForwardedRef<HTMLInputElement>) => (
  <div
    className={classNames({
      'form-field': true,
      'has-error': props.error,
      [props.className || '']: props.className
    })}
  >
    <label
      className={classNames('form-field-label', { required: props.required })}
      htmlFor={props.id}
    >
      {props.label}
    </label>
    {props.children ? (
      props.children
    ) : (
      <input
        ref={ref}
        className="input-field"
        id={props.id}
        type={props.type || 'input'}
        defaultValue={props.value}
        placeholder={props.placeholder}
        maxLength={props.maxLength}
        disabled={props.disabled}
        autoFocus={props.autoFocus}
        autoComplete={props.autoComplete === undefined ? 'on' : props.autoComplete}
        onBlur={props.onBlur}
        onKeyPress={props.onKeyPress}
        onFocus={props.onFocus}
      />
    )}
    <span className="field-error">{props.error}</span>
    {props.hintText && <span className="field-hint">{props.hintText}</span>}
  </div>
))

export default FormField
