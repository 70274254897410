import { requestWrapper, url } from 'helpers/fetchHelpers'
import graphqlRequestWrapper from '../graphql/common/requestHelpers'
import { extractVariables, extractConnections } from '../graphql/dataExtractors/variablesExtractors'
import { variablesQuery, variableConnectionsQuery } from '../graphql/queries/variablesQueries'

export function getVariables(variables) {
  return graphqlRequestWrapper({
    query: variablesQuery(),
    variables
  }).then(extractVariables)
}

export function getVariableConnections(variables) {
  return graphqlRequestWrapper({
    fetchPolicy: 'no-cache',
    query: variableConnectionsQuery(),
    variables
  }).then(extractConnections)
}

export function createVariable(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/variable`,
    type: 'POST',
    payload: payload.data
  })
}

export function editVariable(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/variable/${payload.data.id}`,
    type: 'PUT',
    payload: payload.data
  })
}

export function deleteVariable(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/variable/${payload.variableId}`,
    type: 'DELETE',
    payload: {}
  })
}

export function getVariablesSuggestions({ tenantId, query }) {
  return requestWrapper({
    rUrl: `${url}tenant/${tenantId}/variable/suggestion`,
    type: 'GET',
    payload: { query }
  })
}

export const getTagsSuggestions = ({ tenantId, query }) =>
  requestWrapper({
    rUrl: `${url}tenant/${tenantId}/variable/tags/suggestion?text=${query}`,
    type: 'GET',
    payload: {}
  })

export function duplicateVariable(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/variable/${payload.variableId}/copy`,
    type: 'POST'
  })
}
