const DATABASE_NAME = 'myDatabase'
const STORE_NAME = 'myStore'

export const SimpleIDB = {
  initialize() {
    return new Promise((resolve, reject) => {
      // This first deletes any database of the same name
      const dRequest = indexedDB.deleteDatabase(DATABASE_NAME)
      dRequest.onerror = () => {
        reject(dRequest.error)
      }
      // Then creates a new one
      const request = indexedDB.open(DATABASE_NAME)
      request.onupgradeneeded = () => {
        request.result.createObjectStore(STORE_NAME)
        resolve()
      }
      request.onerror = () => {
        reject(request.error)
      }
    })
  },

  get(key) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        // timeout to make time gap to synchronize indexdb
        const oRequest = indexedDB.open(DATABASE_NAME)
        oRequest.onsuccess = () => {
          const db = oRequest.result
          const tx = db.transaction(STORE_NAME, 'readonly')
          const st = tx.objectStore(STORE_NAME)
          const gRequest = st.get(key)
          gRequest.onsuccess = () => {
            resolve(gRequest.result)
          }
          gRequest.onerror = () => {
            reject(gRequest.error)
          }
        }
        oRequest.onerror = () => {
          reject(oRequest.error)
        }
      }, 100)
    })
  },

  set(key, value) {
    return new Promise((resolve, reject) => {
      const oRequest = indexedDB.open(DATABASE_NAME)
      oRequest.onsuccess = () => {
        const db = oRequest.result
        const tx = db.transaction(STORE_NAME, 'readwrite')
        const st = tx.objectStore(STORE_NAME)
        const sRequest = st.put(value, key)
        sRequest.onsuccess = () => {
          resolve()
        }
        sRequest.onerror = () => {
          reject(sRequest.error)
        }
      }
      oRequest.onerror = () => {
        reject(oRequest.error)
      }
    })
  },

  remove(key) {
    return new Promise((resolve, reject) => {
      const oRequest = indexedDB.open(DATABASE_NAME)
      oRequest.onsuccess = () => {
        const db = oRequest.result
        const tx = db.transaction(STORE_NAME, 'readwrite')
        const st = tx.objectStore(STORE_NAME)
        const rRequest = st.delete(key)
        rRequest.onsuccess = () => {
          resolve()
        }
        rRequest.onerror = () => {
          reject(rRequest.error)
        }
      }
      oRequest.onerror = () => {
        reject(oRequest.error)
      }
    })
  }
}

export const databaseExists = (dbname, callback) => {
  let existed = true

  const req = indexedDB.open(dbname)

  req.onsuccess = () => {
    // check whether store exist
    const hasStore = req.result.objectStoreNames.contains(STORE_NAME)
    if (!hasStore) {
      existed = false
    }

    req.result.close()
    if (!existed) {
      indexedDB.deleteDatabase(dbname)
    }
    callback(existed)
  }

  req.onupgradeneeded = () => {
    existed = false
  }
}

export const initIndexedBD = (dbName = DATABASE_NAME) => {
  databaseExists(dbName, isExist => {
    if (!isExist) {
      SimpleIDB.initialize()
    }
  })
}
