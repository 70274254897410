import classNames from 'classnames'
import MUITextField, { type TextFieldProps } from '@mui/material/TextField'
import './text-field.scss'

export type TTextFieldSize = 24 | 28 | 34 | 40 | 60 | 'custom'

export type TTextFieldProps = Omit<TextFieldProps, 'size'> & {
  size?: TTextFieldSize
}

export const TextField = ({
  className,
  size = 40,
  InputLabelProps,
  ...restProps
}: TTextFieldProps) => (
  <MUITextField
    className={classNames('praxie-text-field', className, `size-${size}`)}
    InputLabelProps={{
      shrink: true,
      ...InputLabelProps
    }}
    {...restProps}
  />
)
