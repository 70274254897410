import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import DotsSpinner from '../spinners/DotsSpinner'

import '../../scss/buttons/up-icon-button.scss'

const IconButton = props => {
  const {
    as: Component,
    id,
    type,
    size,
    icon,
    iconPosition,
    iconSeparated,
    children,
    className,
    appearance,
    onClick,
    busy,
    disabled,
    autoFocus,
    ...rest
  } = props

  return (
    <Component
      id={id}
      type={type}
      className={classNames('up-button', 'up-icon-button', appearance, size, className, {
        'icon-separated': iconSeparated,
        disabled,
        busy
      })}
      autoFocus={autoFocus}
      onClick={onClick}
      disabled={disabled || busy}
      {...rest}
    >
      {busy && <DotsSpinner color={appearance === 'primary' ? 'light' : 'dark'} />}
      {!busy && (
        <span
          className={classNames('icon-button-icon-wrapper', {
            'left-icon': iconPosition === 'left',
            'right-icon': iconPosition === 'right'
          })}
        >
          {icon}
        </span>
      )}
      {!busy && <span className="icon-button-text">{children}</span>}
    </Component>
  )
}

IconButton.propTypes = {
  id: PropTypes.string,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  to: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  iconSeparated: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  className: PropTypes.string,
  tabIndex: PropTypes.string,
  appearance: PropTypes.oneOf(['primary', 'secondary', 'secondary2', 'ribbon', 'light-bg']),
  onClick: PropTypes.func,
  busy: PropTypes.bool,
  disabled: PropTypes.bool
}

IconButton.defaultProps = {
  as: 'button',
  children: null,
  type: 'button',
  size: 'medium',
  iconPosition: 'left',
  iconSeparated: true,
  className: '',
  appearance: 'primary',
  onClick: null,
  busy: false,
  disabled: false
}

export default IconButton
