export const findErrorConstraints = error => {
  if (!error) {
    return ''
  }
  if (typeof error === 'string') {
    return error
  }
  if (Array.isArray(error) && error.length) {
    return findErrorConstraints(error[0])
  }
  if (error.constraints) {
    return error.constraints
  }
  if (error.message) {
    return findErrorConstraints(error.message)
  }
  if (error.children) {
    return findErrorConstraints(error.children)
  }
  return ''
}

export const isTextErrorMessage = message => message && typeof message === 'string'

// no error code in case request failed due to network issues, like net::ERR_FAILED
export const isServerUnavailable = errorCode => !errorCode || errorCode >= 500

export const USER_REMOVING_ERROR_CODES = {
  USER_ONLY_ADMIN: 'USER_ONLY_ADMIN',
  USER_HAVE_FILTERS: 'USER_HAVE_FILTERS'
}

export const ERRORS = {
  SERVER_ERROR_500: 500,
  FORBIDDEN_403: 403,
  NOT_FOUND_404: 404
}
