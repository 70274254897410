import { requestWrapper, url } from 'helpers/fetchHelpers'
import type { ITeam, ITeamMember } from 'features/team/team.types'

export class _TeamService {
  getTeamMemberList = (teamId: ITeam['tenantId']): Promise<{ data: ITeamMember[] }> => {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return requestWrapper({
      rUrl: `${url}tenant/${teamId}/users/list`,
      type: 'GET',
      payload: { avatars: true },
      cache: true
    })
  }

  updateTeamTaskDueSoonThreshold = (
    teamId: ITeam['tenantId'],
    payload: { taskDueSoonThreshold: number }
  ) => {
    return requestWrapper({
      rUrl: `${url}tenant/${teamId}/task-due-soon-threshold`,
      type: 'PUT',
      payload
    })
  }
}

export const TeamService = new _TeamService()
