import messages from 'constants/messages'

export const ALL_TEAM_MEMBERS = 'allTeamMembers'
export const TEAM_ADMIN = 'teamAdmin'
export const BOARD_MANAGER = 'boardManager'
const CARD_BUILDER = 'cardBuilder'
const COLLABORATOR = 'collaborator'
const VIEWER = 'viewer'
const BOARD_LEVEL_ACCESS = 'boardLevelAccess'

export const SPECIFIC_ROLES_OPTIONS = [
  { value: ALL_TEAM_MEMBERS, label: messages.ALL_TEAM_MEMBERS },
  { value: TEAM_ADMIN, label: messages.TEAM_ADMIN },
  { value: BOARD_MANAGER, label: messages.BOARD_MANAGER },
  { value: CARD_BUILDER, label: messages.CARD_BUILDER_TITLE },
  { value: COLLABORATOR, label: messages.COLLABORATOR },
  { value: VIEWER, label: messages.VIEWER },
  { value: BOARD_LEVEL_ACCESS, label: messages.BOARD_LEVEL_ACCESS }
]
