import classNames from 'classnames'

import 'scss/common/toggle-tabs.scss'

type TToggleTab = {
  value: string
  label: string
}

type TProps = {
  active: string
  onTabClick: (value: string) => void
  tabs: Array<TToggleTab>
  customClassName: string
}

export const ToggleTabs = ({ active, onTabClick, tabs, customClassName }: TProps) => (
  <div
    className={classNames('toggle-tabs not-interactable-zone', {
      [customClassName]: !!customClassName
    })}
  >
    {tabs.map(({ label, value }) => (
      <div
        key={value}
        className={classNames('toggle-tab', {
          active: value === active
        })}
        onClick={onTabClick.bind(null, value)}
      >
        {label}
      </div>
    ))}
  </div>
)

export default ToggleTabs
