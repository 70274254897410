import {
  Children,
  type ComponentProps,
  type ReactElement,
  type ReactNode,
  useEffect,
  useRef
} from 'react'
import InputAdornment from '@mui/material/InputAdornment'
import ListSubheader from '@mui/material/ListSubheader'
import classNames from 'classnames'
import SearchIcon from 'assets/images/icons/search.svg?react'
import CrossIcon from 'assets/images/icons/cross.svg?react'
import { TextField } from 'components/common/textField/textField'
import { Tooltip } from 'components/common/tooltip/tooltip'
import RoundIconButton from 'components/buttons/RoundIconButton'
import { MenuItem } from 'components/common/menu/menu'
import { Select } from '../select'
import './searchable-select.scss'

type TOption = {
  label: string | number
  value: string | number
}

const parseOptionLabel = (children: ReactNode) => {
  return Array.isArray(children)
    ? (children as Array<ReactNode>).reduce((result: string, __child) => {
        if (typeof __child === 'string') {
          return result.concat(__child)
        }

        return result
      }, '')
    : (children as TOption['label'])
}

type TProps = ComponentProps<typeof Select> & {
  search: string
  searchPlaceholder?: string
  shouldEnableSearch?: boolean
  subHeader?: ReactNode
  onSearchChange: (search: string) => void
}

export const SearchableSelect = ({
  children,
  search = '',
  searchPlaceholder = 'Search',
  shouldEnableSearch = true,
  subHeader,
  MenuProps,
  onSearchChange,
  ...restProps
}: TProps) => {
  const childrenMap = useRef<Map<TOption['value'], TOption['label']>>(new Map())

  // Save all options passed via children.
  // We need that to display selected value when searching, because some options will be absent.
  useEffect(() => {
    Children.forEach(children, child => {
      const _child = child as ReactElement<ComponentProps<typeof MenuItem>>

      if (!_child) return
      const storedChild = childrenMap.current.get(_child.props.value as TOption['value'])
      const label = parseOptionLabel(_child.props.children)

      if (!storedChild || storedChild !== label) {
        childrenMap.current.set(_child.props.value as TOption['value'], label)
      }
    })
  }, [children])

  return (
    <Select
      className="praxie-searchable-select"
      MenuProps={{
        ...MenuProps,
        disableAutoFocusItem: true,
        className: classNames(
          'praxie-searchable-select-menu',
          shouldEnableSearch && 'with-search',
          MenuProps?.className
        )
      }}
      renderValue={_value => childrenMap.current.get(_value as TOption['value'])}
      // To prevent show all list of options while closing animation
      onClose={() => setTimeout(() => onSearchChange(''), 300)}
      {...restProps}
    >
      {/* ListSubheader is necessary to properly split custom content from the options. */}
      <ListSubheader>
        {shouldEnableSearch && (
          <TextField
            className="search-input"
            size={28}
            InputProps={{
              value: search,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className="search-icon" />
                </InputAdornment>
              ),
              endAdornment: !!search && (
                <InputAdornment position="end">
                  <Tooltip title="Clear search" shouldWrapChildrenWithDiv>
                    {/* @ts-ignore */}
                    <RoundIconButton size="small" onClick={() => onSearchChange('')}>
                      <CrossIcon />
                    </RoundIconButton>
                  </Tooltip>
                </InputAdornment>
              )
            }}
            placeholder={searchPlaceholder}
            onChange={e => onSearchChange(e.target.value)}
            // To prevent events being captured by Menu.
            onKeyDown={e => e.stopPropagation()}
            onMouseDown={e => e.stopPropagation()}
          />
        )}
        {subHeader}
      </ListSubheader>
      {children}
    </Select>
  )
}
