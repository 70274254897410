const notifications = {
  cursor: null,
  isNotificationsLoading: false,
  notifications: [],
  hasNextPage: true,
  totals: {
    count: 0,
    read: 0,
    unread: 0
  }
}

export default notifications
