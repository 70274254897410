import React from 'react'
import classNames from 'classnames'

import { SORTING_DIRECTION } from 'constants/filters/filtersConstants'

import 'scss/common/sortingControls/sorting-icon.scss'

const SortingIcon = props => {
  const { direction } = props

  return (
    <span
      className={classNames('sorting-icons', {
        asc: direction === SORTING_DIRECTION.ASC,
        desc: direction === SORTING_DIRECTION.DESC
      })}
    >
      <i className="fa fa-caret-up" aria-hidden="true" />
      <i className="fa fa-caret-down" aria-hidden="true" />
    </span>
  )
}

export default SortingIcon
