import { EFieldKeys } from '../../constants/workflowBuilder/blocksFieldsKeys'

export const metaToStaticConnectionMapper = (block, inputName) => {
  // section index should start from 0 for BE
  if (inputName === EFieldKeys.SECTIONS) {
    return block.meta[inputName].map(section => ({
      ...section,
      index: section.index - 1
    }))
  }

  return block.meta[inputName]
}

export const staticConnectionToMetaMapper = (inputName, data) => {
  // section index should start from 1 on UI
  if (inputName === EFieldKeys.SECTIONS) {
    return data.map(section => ({
      ...section,
      index: section.index + 1
    }))
  }

  return data
}
