import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { findSelectedOptionByKey } from 'helpers/fields/selectHelpers'
import { getFieldNamesItems } from 'helpers/workflowBuilder/inputOperations'
import { SPECIFIC_ROLES_OPTIONS } from 'helpers/workflowSystemActions/systemActionsHelpers'

export const mapRoles = roles =>
  roles.reduce((acc, role) => {
    acc.push(findSelectedOptionByKey(role, SPECIFIC_ROLES_OPTIONS))
    return acc
  }, [])

export const metaToStaticConnectionMapper = (block, inputName, dynamicInputName) => {
  if (inputName === EFieldKeys.COLUMN_NUMBER) {
    const inputs = getFieldNamesItems(dynamicInputName, block.inputDefinition)

    // BE needs count of filled inputs
    return inputs.reduce((acc, name) => (block.input[name] ? acc + 1 : acc), 0)
  }

  return block.meta[inputName]
}

export const staticConnectionToMetaMapper = (inputName, data) => {
  if (inputName === EFieldKeys.CREDENTIAL_ID) {
    return data === null ? '' : data
  }

  if (inputName === EFieldKeys.IS_RETRY_ENABLED) {
    return data === null ? false : data
  }

  if (inputName === EFieldKeys.COLUMN_NUMBER) {
    // In case no one input is filled we should show one empty input
    return data === 0 ? 1 : data
  }

  return data
}
