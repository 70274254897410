/*
Allow to dispatch redux action from widgets using Custom Events

if type equals to COMPLEX_ACTION_TYPE custom action will be dispatched

Event example:
const event = new CustomEvent('upboard:busEvent', {
    detail: { type: string, payload: any, action: string }
});

document.dispatchEvent(event);
*/

import { COMPLEX_ACTION_TYPE, CUSTOM_EVENT } from 'features/widgets/eventBus/eventBus.constants'
import * as actions from '../../../actions'

const createEventBusMiddleware =
  () =>
  ({ dispatch }) => {
    document.addEventListener(CUSTOM_EVENT, event => {
      if (event.detail.type === COMPLEX_ACTION_TYPE) {
        dispatch(actions[event.detail.action](event.detail.payload))
      } else {
        dispatch({ type: event.detail.type, payload: event.detail.payload })
      }
    })

    return next => action => next(action)
  }

export default createEventBusMiddleware
