export interface IAppStoreCollection {
  attachedFileOnly: boolean
  description: string
  secondaryDescription: string
  id: string
  images: TAppStoreCollectionImage[]
  logo: string | null
  name: string
  parentCollectionId: string
  subscriptionOptions: TAppStoreCollectionSubscriptionOption[]
  trialPeriod: number
  status: ECollectionStatus
}

export enum ECollectionStatus {
  active = 'active',
  archived = 'archived'
}

export enum ECollectionImageColor {
  GREEN = 'Green',
  YELLOW = 'Yellow',
  BLUE = 'Blue',
  PINK = 'Pink'
}

export type TAppStoreCollectionSubscriptionOption = {
  description: string
  price: number
  purchaseId: string
}

type TAppStoreCollectionImage = {
  size: string
  url: string
  color: ECollectionImageColor
}

export interface IPurchaseItemErrors {
  purchaseId?: string
  price?: string
  description?: string
  trialInfo?: string
}

export interface IAppMetaErrors {
  purchaseInfo: {
    [key: number]: IPurchaseItemErrors
  }
}

export interface IAppStoreConfigCategory {
  id: string
  value: string
}

export interface IAppStoreConfigCollection {
  id: string
  value: string
  order: number
}

export interface IAppStoreConfig {
  categories: Array<IAppStoreConfigCategory>
  defaultCategories: Array<IAppStoreConfigCategory>
  collections: Array<IAppStoreConfigCollection>
  libraryCategoryId: string
}
