import React from 'react'

import FilterCondition from '../filterPreview/FilterCondition'

import '../../../scss/filters/conditionsModal/conditions-modal-rules-preview.scss'

const RulesPreview = props => {
  const { operator, rulesConfig, rules, queryObject } = props

  const isEmpty = !rules.length

  return (
    <div className="conditions-modal-rules-preview">
      {!isEmpty && <div className="rules-preview-title">Preview</div>}
      {isEmpty && <div className="rules-preview-empty">Rule preview displayed here</div>}
      <div className="rules-preview-wrapper">
        {rules.map((rule, index) => (
          <FilterCondition
            key={rule.uuid}
            isLast={index === rules.length - 1}
            rule={rule}
            rulesConfig={rulesConfig}
            operator={operator}
            object={queryObject}
          />
        ))}
      </div>
    </div>
  )
}

export default RulesPreview
