import React from 'react'
import { OPERATOR_TEXTS } from '../../../helpers/filters/constantsPresentationHelpers'

const LogicalOperator = props => {
  const { operator } = props

  return <div className="preview-rule-operator">{OPERATOR_TEXTS[operator]}</div>
}

export default LogicalOperator
