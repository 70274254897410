import * as types from '../../constants/builderActionTypes'
import { ENTER_KEY_CODE, ESC_KEY_CODE } from '../../constants/keyCodes'
import { getBoardObjectByID } from '../boardMenuHelpers'

export function getPreviousChangeType(changeType) {
  let previousType
  switch (changeType) {
    case types.CARD_UPDATE:
      previousType = types.CARD_UPDATE
      break
    case types.WIDGET_CREATE:
      previousType = types.WIDGET_DELETE
      break
    case types.WIDGET_DELETE:
      previousType = types.WIDGET_CREATE
      break
    case types.WIDGET_UPDATE:
      previousType = types.WIDGET_UPDATE
      break
    default:
  }
  return previousType
}

export function getPreviousChange(history, lastChange, originalCard) {
  let previousChange
  // type of previous event
  const previousType = getPreviousChangeType(lastChange.type)

  switch (lastChange.type) {
    case types.CARD_UPDATE:
      // when card was updated we should
      // find previous version
      previousChange = history.filter(element => element.type === previousType).pop()
      if (!previousChange) {
        previousChange = {
          data: {
            name: originalCard.name,
            showCardThumbnail: originalCard.showCardThumbnail
          },
          type: types.CARD_UPDATE
        }
      }
      break
    case types.WIDGET_CREATE:
      previousChange = {
        ...lastChange,
        type: previousType
      }
      break
    case types.WIDGET_DELETE:
      previousChange = {
        ...lastChange,
        type: previousType
      }
      break
    case types.WIDGET_UPDATE:
      previousChange = {
        data: [],
        type: types.WIDGET_UPDATE
      }
      lastChange.data.forEach(lastWidget => {
        // try to find widget update in history
        const widgetID = lastWidget.uuid
        let newWidget = history
          .filter(
            item =>
              (item.type === types.WIDGET_UPDATE || item.type === types.WIDGET_CREATE) &&
              item.data.filter(historyWidget => historyWidget.uuid === widgetID).length > 0
          )
          .pop()
        // if widget was found from history
        if (newWidget) {
          // find widget data
          previousChange.data.push(newWidget.data.filter(widget => widget.uuid === widgetID)[0])
        } else {
          // if not, we should take data from original card
          newWidget = originalCard.widgets.filter(widget => widget.uuid === widgetID)[0]
          if (newWidget) {
            previousChange.data.push(newWidget)
          }
        }
      })
      break
    default:
  }
  return previousChange
}

export const keyDownEventListener = (event, isShow, onEnterClick, onEscClick) => {
  if (isShow) {
    switch (event.keyCode) {
      case ENTER_KEY_CODE: {
        onEnterClick()
        break
      }
      case ESC_KEY_CODE: {
        if (onEscClick) {
          onEscClick()
        }
        break
      }
      default:
    }
  }
}

export const disableZoom = e => {
  const event = e.originalEvent || e
  if ((event.scale !== undefined && event.scale !== 1) || event.touches.length > 1) {
    event.preventDefault()
  }
}

export const disableZoomByDoubleTouch = event => {
  const now = new Date().getTime()
  const lastTouchEnd = window.lastTouchEnd || 0
  if (now - lastTouchEnd <= 300) {
    event.preventDefault()
  }
  window.lastTouchEnd = now
}

export const getTenantsListForBoardCreation = boardMenuTenants =>
  boardMenuTenants.reduce((acc, tenant) => {
    const permissions = tenant.accessInfo && tenant.accessInfo.permissions
    if (permissions && permissions.createBoard) {
      acc.push({
        key: tenant.tenantId,
        text: tenant.name
      })
    }

    return acc
  }, [])

export const getBoardAppId = (boards, boardId, tenantId) => {
  const board = getBoardObjectByID({ boards, tenants: [] }, boardId, tenantId)
  if (board) {
    return board.appId
  }
  return ''
}
