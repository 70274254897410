import { ERequestHeaders } from 'constants/common'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import {
  validateArrayNotEmpty,
  validateForNotEmpty,
  validateLongTextInputs
} from 'helpers/validationHelpers'
import { type IWorkflowBlock } from 'features/workflow/workflow.types'
import { type TRestApiBlockError, EAuthorizationType } from './restApiClientBlock.types'

const validateUrlTarget = (meta: IWorkflowBlock['meta']) => {
  const isUrlContainsPraxie = String(meta[EFieldKeys.URL_TARGET]).includes('praxie.com')

  if (isUrlContainsPraxie) {
    return 'You can’t reference praxie.com in the URL'
  }

  return validateArrayNotEmpty(meta[EFieldKeys.URL_TARGET])
}

const validateCredentials = (meta: IWorkflowBlock['meta']) => {
  if (meta[EFieldKeys.AUTHORIZATION_TYPE] === EAuthorizationType.BASIC) {
    const credentials = meta[EFieldKeys.CREDENTIAL_ID] as string

    return validateForNotEmpty(credentials)
  }

  return null
}

const validateForbiddenHeaders = (keys: Array<string | { fieldName: string }>) => {
  const forbiddenHeaders = Object.values(ERequestHeaders).map(header => header.toLowerCase())

  const hasForbiddenHeaders = keys.some(key => {
    const isToken = typeof key !== 'string'
    if (isToken) return false

    return forbiddenHeaders.includes(key.toLowerCase())
  })

  return hasForbiddenHeaders ? 'This key will be added automatically in Hidden Headers' : ''
}

const validateDuplicatedHeaders = (keys: string[], currentKey: unknown[]) => {
  const isDuplicatedKey = keys.includes(JSON.stringify(currentKey))

  if (isDuplicatedKey) {
    return 'Such header already exists'
  }

  return ''
}

const validateHeaderTokens = (
  header: Record<EFieldKeys, string[]>,
  input: IWorkflowBlock['input']
) => {
  return Object.keys(header).reduce((errors, key) => {
    const tokenError = validateLongTextInputs({ meta: header, fieldKey: key as EFieldKeys, input })

    return { ...errors, ...tokenError }
  }, {})
}

const validateHeaders = (block: IWorkflowBlock) => {
  const headers = block.meta[EFieldKeys.HEADERS] as Array<Record<EFieldKeys, string[]>>

  if (!headers.length) return null

  const { headersError, hasError, headersTokenErrors } = headers.reduce<{
    headerKeys: string[]
    headersError: Array<Record<EFieldKeys.KEY | EFieldKeys.VALUE, string>>
    headersTokenErrors: Record<string, string>
    hasError: boolean
  }>(
    (acc, header) => {
      const keyError =
        validateArrayNotEmpty(header[EFieldKeys.KEY]) ||
        validateForbiddenHeaders(header[EFieldKeys.KEY]) ||
        validateDuplicatedHeaders(acc.headerKeys, header[EFieldKeys.KEY])

      const valueError = validateArrayNotEmpty(header[EFieldKeys.VALUE])

      acc.headerKeys.push(JSON.stringify(header[EFieldKeys.KEY]))
      acc.headersError.push({ [EFieldKeys.KEY]: keyError, [EFieldKeys.VALUE]: valueError })

      const tokenErrors = validateHeaderTokens(header, block.input)

      acc.headersTokenErrors = { ...acc.headersTokenErrors, ...tokenErrors }

      if (!!keyError || !!valueError) {
        acc.hasError = true
      }

      return acc
    },
    { headerKeys: [], headersError: [], headersTokenErrors: {}, hasError: false }
  )

  return {
    ...headersTokenErrors,
    [EFieldKeys.HEADERS]: hasError ? headersError : null
  }
}

export const validateRestApiClientBlock = ({ block }: { block: IWorkflowBlock }) => {
  const urlTargetErrors = validateLongTextInputs({
    meta: block.meta,
    fieldKey: EFieldKeys.URL_TARGET,
    input: block.input
  })

  const bodyErrors = validateLongTextInputs({
    meta: block.meta,
    fieldKey: EFieldKeys.BODY,
    input: block.input
  })

  const headerErrors = validateHeaders(block)

  const error = {
    ...urlTargetErrors,
    ...bodyErrors,
    ...headerErrors,
    [EFieldKeys.URL_TARGET]: validateUrlTarget(block.meta),
    [EFieldKeys.CREDENTIAL_ID]: validateCredentials(block.meta)
  } as TRestApiBlockError

  return { error }
}
