import messages from 'constants/messages'
import { type IOutputDefinitionRecord } from 'features/workflow/workflow.types'

export const TRIGGER_OBJECTS = {
  WIDGET: 'widget',
  CARD: 'card',
  BOARD: 'board',
  WEBHOOK: 'webhook',
  SCHEDULE: 'schedule'
}

export enum OBJECT_TYPES {
  WIDGET = 'widget',
  CARD = 'card',
  BOARD = 'board',
  STRING = 'string',
  COLUMN = 'column',
  USER = 'user',
  WIDGETS_BUNDLE = 'widgetsBundle',
  COMMENT = 'comment',
  WIDGET_DATA = 'widgetData',
  JSON = 'json',
  FILE = 'file',
  CARD_BUNDLE = 'cardBundle'
}

export const TRIGGER_EVENTS = {
  CLICK: 'click',
  UPDATE: 'update',
  RECEIVE: 'receive_call',
  SCHEDULE: 'schedule'
}

export enum BLOCK_MENU_GROUPS {
  BOARD = 'board',
  COLUMN = 'column',
  CARD = 'card',
  CARD_BUNDLE = 'cardBundle',
  WIDGET = 'widget',
  WIDGETS_BUNDLE = 'widgetsBundle',
  USER = 'user',
  JSON = 'json',
  FILE = 'file',
  SYSTEM_ACTIONS = 'systemActions'
}

export enum OUTPUT_NAMES {
  OUT = 'out',
  TRIGGER_CARD = 'trigger_card',
  TRIGGER_COLUMN = 'trigger_column',
  TRIGGER_BOARD = 'trigger_board',
  TRIGGER_USER = 'trigger_user',
  JSON_PAYLOAD = 'json_payload',
  TRIGGER_FILE = 'trigger_file'
}

export const OBJECT_TYPE_OUTPUT_DEFINITION: Record<string, IOutputDefinitionRecord> = {
  [OBJECT_TYPES.BOARD]: {
    type: OBJECT_TYPES.BOARD,
    outputName: OUTPUT_NAMES.TRIGGER_BOARD,
    defaultPresentationName: messages.TRIGGERS_BOARD
  },
  [OBJECT_TYPES.COLUMN]: {
    type: OBJECT_TYPES.COLUMN,
    outputName: OUTPUT_NAMES.TRIGGER_COLUMN,
    defaultPresentationName: messages.TRIGGERS_COLUMN
  },
  [OBJECT_TYPES.CARD]: {
    type: OBJECT_TYPES.CARD,
    outputName: OUTPUT_NAMES.TRIGGER_CARD,
    defaultPresentationName: messages.TRIGGERS_CARD
  },
  [OBJECT_TYPES.USER]: {
    type: OBJECT_TYPES.USER,
    outputName: OUTPUT_NAMES.TRIGGER_USER,
    defaultPresentationName: messages.TRIGGERS_USER
  },
  [OBJECT_TYPES.JSON]: {
    type: OBJECT_TYPES.JSON,
    outputName: OUTPUT_NAMES.JSON_PAYLOAD,
    defaultPresentationName: messages.JSON_PAYLOAD
  },
  [OBJECT_TYPES.FILE]: {
    type: OBJECT_TYPES.FILE,
    outputName: OUTPUT_NAMES.TRIGGER_FILE,
    defaultPresentationName: messages.TRIGGERS_FILE
  }
}

export const TRIGGER_EVENT_OUTPUT_DEFINITIONS = {
  [TRIGGER_EVENTS.CLICK]: [
    OBJECT_TYPE_OUTPUT_DEFINITION[OBJECT_TYPES.BOARD],
    OBJECT_TYPE_OUTPUT_DEFINITION[OBJECT_TYPES.CARD],
    OBJECT_TYPE_OUTPUT_DEFINITION[OBJECT_TYPES.COLUMN],
    OBJECT_TYPE_OUTPUT_DEFINITION[OBJECT_TYPES.USER]
  ],
  [TRIGGER_EVENTS.UPDATE]: [
    OBJECT_TYPE_OUTPUT_DEFINITION[OBJECT_TYPES.BOARD],
    OBJECT_TYPE_OUTPUT_DEFINITION[OBJECT_TYPES.CARD],
    OBJECT_TYPE_OUTPUT_DEFINITION[OBJECT_TYPES.COLUMN],
    OBJECT_TYPE_OUTPUT_DEFINITION[OBJECT_TYPES.USER]
  ],
  [TRIGGER_EVENTS.RECEIVE]: [
    OBJECT_TYPE_OUTPUT_DEFINITION[OBJECT_TYPES.BOARD],
    OBJECT_TYPE_OUTPUT_DEFINITION[OBJECT_TYPES.USER],
    OBJECT_TYPE_OUTPUT_DEFINITION[OBJECT_TYPES.JSON],
    OBJECT_TYPE_OUTPUT_DEFINITION[OBJECT_TYPES.FILE]
  ],
  [TRIGGER_EVENTS.SCHEDULE]: [
    OBJECT_TYPE_OUTPUT_DEFINITION[OBJECT_TYPES.BOARD],
    OBJECT_TYPE_OUTPUT_DEFINITION[OBJECT_TYPES.CARD],
    OBJECT_TYPE_OUTPUT_DEFINITION[OBJECT_TYPES.COLUMN]
  ]
}

export const WF_USERS = {
  CURRENT_USER: 'currentUser',
  WORKSPACE_USER: 'workspaceUser'
}

export const BACKGROUND_TRIGGER_EVENT_TYPES = [
  TRIGGER_EVENTS.UPDATE,
  TRIGGER_EVENTS.RECEIVE,
  TRIGGER_EVENTS.SCHEDULE
]
