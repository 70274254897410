const variables = {
  variables: [],
  isVariablesFetching: false,
  variableConfiguration: {
    state: false,
    tenantId: null,
    tenantName: '',
    variable: null,
    onConfirm: null
  },
  variableDeleteConfirmation: {
    state: false,
    tenantId: null,
    tenantName: '',
    variableId: null,
    variableName: '',
    onConfirm: null
  }
}

export default variables
