import { createReducer } from 'redux-create-reducer'
import * as ActionTypes from '../constants/actionTypes'

export const spinner = createReducer(
  {},
  {
    [ActionTypes.TOGGLE_BOARD_SPINNER](state, action) {
      return { ...state, isBoardContainerLoading: action.payload }
    },

    [ActionTypes.TOGGLE_CARD_BUILDER_SPINNER](state, action) {
      return { ...state, isCardBuilderContainerLoading: action.payload }
    },

    [ActionTypes.TOGGLE_TOP_MENU_LOADER](state, action) {
      return {
        ...state,
        isTopMenuLoading: action.payload
      }
    },

    [ActionTypes.SHOW_FULL_BOARD_PAGE_SPINNER](state) {
      return {
        ...state,
        isTopMenuLoading: true,
        isBoardContainerLoading: true,
        isBoardMenuLoading: true,
        isCardBuilderContainerLoading: true
      }
    },

    [ActionTypes.REQUEST_BOARD_MENU](state) {
      return {
        ...state,
        isBoardMenuLoading: true
      }
    },

    [ActionTypes.RECEIVE_BOARD_MENU](state) {
      return {
        ...state,
        isBoardMenuLoading: false
      }
    },

    [ActionTypes.TOGGLE_BOARD_NAME_LOADER](state, action) {
      return {
        ...state,
        isBoardNameLoading: action.payload
      }
    },

    [ActionTypes.TOGGLE_CARD_NAME_SPINNER](state, action) {
      return {
        ...state,
        cardWithNameSpinner: action.payload
      }
    },

    [ActionTypes.TOGGLE_COLUMN_LOADING](state, action) {
      return {
        ...state,
        loadingColumnUuid: action.payload
      }
    },

    [ActionTypes.TOGGLE_COPYING_COLUMN_SPINNER](state, action) {
      return {
        ...state,
        copyingColumnIndex: action.payload
      }
    },

    [ActionTypes.SHOW_MODAL_WINDOW_SPINNER](state) {
      return {
        ...state,
        isModalWindowBusy: true,
        isOverlayShowing: true
      }
    },

    [ActionTypes.HIDE_MODAL_WINDOW_SPINNER](state) {
      return {
        ...state,
        isModalWindowBusy: false,
        isOverlayShowing: false
      }
    },

    [ActionTypes.REQUEST_CARD](state) {
      return { ...state, isCardRequested: true }
    },

    [ActionTypes.RECEIVE_CARD](state) {
      return { ...state, isCardRequested: false }
    },

    [ActionTypes.RECEIVE_CARD_ERROR](state) {
      return { ...state, isCardRequested: false }
    },

    [ActionTypes.TOGGLE_UNSAVED_CHANGES_SPINNER](state, action) {
      return { ...state, isUnsavedChangesSpinnerShown: action.payload }
    },

    [ActionTypes.AUTO_SAVE_START](state) {
      return { ...state, isAutoSaveWorking: true }
    },

    [ActionTypes.AUTO_SAVE_END](state) {
      return { ...state, isAutoSaveWorking: false }
    },

    [ActionTypes.START_EDIT_REQUEST](state) {
      return { ...state, isStartEditRequested: true }
    },

    [ActionTypes.START_EDIT_RECEIVE](state) {
      return { ...state, isStartEditRequested: false }
    },

    [ActionTypes.START_EDIT_RECEIVE_ERROR](state) {
      return { ...state, isStartEditRequested: false }
    },

    [ActionTypes.SET_ACTIVE_REQUEST](state) {
      return { ...state, isSetActiveRequested: true }
    },

    [ActionTypes.SET_ACTIVE_RECEIVE](state) {
      return { ...state, isSetActiveRequested: false }
    },

    [ActionTypes.SET_ACTIVE_RECEIVE_ERROR](state) {
      return { ...state, isSetActiveRequested: false }
    },

    [ActionTypes.TOGGLE_TEAM_ADMINISTRATION_PAGE_SPINNER](state, action) {
      return { ...state, isTeamAdministrationPageLoading: action.payload }
    },

    [ActionTypes.TOGGLE_COMMENTS_SPINNER](state, action) {
      return { ...state, isCommentsLoading: action.payload }
    },

    [ActionTypes.TOGGLE_AUTH_LOADER](state, action) {
      return { ...state, isAuthLoading: action.payload }
    }
  }
)

export default spinner
