import React from 'react'
import { connect } from 'react-redux'

import DotsSpinner from '../../components/spinners/DotsSpinner'

import '../../scss/workflowOverlay/workflow-overlay.scss'

const WorkflowOverlayContainer = props => {
  const {
    isWorkflowOverlayShown,
    isWorkflowExecutionLoading,
    isWorkflowRunning,
    runningWorkflowMeta: { loadingMessage }
  } = props

  if (!isWorkflowOverlayShown) {
    return null
  }

  return (
    <section className="workflow-overlay">
      <div className="workflow-overlay-info-box">
        {isWorkflowExecutionLoading && <DotsSpinner size="lg" />}
        {isWorkflowRunning && loadingMessage}
      </div>
    </section>
  )
}

function mapStateToProps(state) {
  return {
    isWorkflowOverlayShown: state.clickWorkflow.isWorkflowOverlayShown,
    isWorkflowExecutionLoading: state.clickWorkflow.isWorkflowExecutionLoading,
    isWorkflowRunning: state.clickWorkflow.isWorkflowRunning,
    runningWorkflowMeta: state.clickWorkflow.runningWorkflowMeta
  }
}

export default connect(mapStateToProps)(WorkflowOverlayContainer)
