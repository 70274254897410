import { type ChangeEvent, useRef, useState } from 'react'
import classNames from 'classnames'
import { useMount } from 'react-use'
import ArrowIcon from 'assets/images/icons/ic_arrow_dr_black.svg?react'
import CloseIcon from 'assets/images/icons/ic_cross.svg?react'
import messages from 'constants/messages'
import { Tooltip } from 'components/common/tooltip/tooltip'
import DotsSpinner from 'components/spinners/DotsSpinner'
import { WIDGET_ICONS } from 'features/widgets/widgets.constants'
import { WidgetPickerDropdownMenu } from './components/widgetPickerDropdownMenu/widgetPickerDropdownMenu'
import { type IWidgetPickerItem } from './widgetPickerDropdown.types'
import './widget-picker-dropdown.scss'

type TSelectedDropdownStateProps = {
  widgetClassName: string
  widgetTitle: string
  boardName: string
  cardName: string
}

const SelectedDropdownState = ({
  widgetClassName,
  widgetTitle,
  boardName,
  cardName
}: TSelectedDropdownStateProps) => (
  <div className="selected-state">
    <Tooltip id="selected-widget-name-tooltip" title={widgetTitle} placement="top">
      <div className="widget-name">
        <div className="icon">{WIDGET_ICONS[widgetClassName]}</div>
        {widgetTitle}
      </div>
    </Tooltip>
    <Tooltip id="selected-widget-info-tooltip" title={`${boardName}: ${cardName}`}>
      <div className="widget-info-wrapper">
        <span className="board-title bold">{boardName}</span>
        <span className="card-name">{`: ${cardName}`}</span>
      </div>
    </Tooltip>
  </div>
)

type TProps = {
  options: Array<{ board: { boardName: string; boardId: string }; widgets: IWidgetPickerItem[] }>
  selectedWidget: IWidgetPickerItem
  searchDataWidgets: (value: string) => Promise<unknown>
  selectWidget: (widget: IWidgetPickerItem) => void
  clearWidget: () => void
  isWidgetLoading: boolean
  hasWidgetError?: boolean
  shouldOpenAfterMount?: boolean
  placeholder?: string
  error?: boolean
  isSearchable?: boolean
  dropdownMenuClassname?: string
}

export const WidgetPickerDropdown = ({
  options,
  selectedWidget,
  searchDataWidgets,
  selectWidget,
  clearWidget,
  isWidgetLoading,
  hasWidgetError = false,
  shouldOpenAfterMount = true,
  placeholder = messages.CHOOSE_DATA_SOURCE,
  error = false,
  isSearchable = true,
  dropdownMenuClassname
}: TProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [isWidgetsListFetching, setIsWidgetsListFetching] = useState(false)

  const menuAnchorRef = useRef<HTMLDivElement>(null)
  const searchInputRef = useRef<HTMLInputElement>(null)

  const fetchDataWidgets = (value: string) => {
    setIsWidgetsListFetching(true)
    void searchDataWidgets(value).finally(() => setIsWidgetsListFetching(false))
  }

  const toggle = (state: boolean) => {
    // To prevent removal of focus from the input after opening the dropdown
    setTimeout(() => searchInputRef.current?.focus(), 0)

    if (isOpen !== state) {
      setIsOpen(state)

      const isEmptyList = !options.length
      const isSelectedEmpty = !selectedWidget.widgetUuid

      if (state && isEmptyList && !isWidgetsListFetching && isSelectedEmpty && !search) {
        fetchDataWidgets('')
      }
    }
  }

  const onSearch = (event: ChangeEvent) => {
    const { value } = event.target as HTMLTextAreaElement

    if (value !== search) {
      setSearch(value)
      toggle(true)

      if (value.length > 2) {
        fetchDataWidgets(value)
      } else if (!value) {
        fetchDataWidgets('')
      }
    }
  }

  const onSelect = (widget: IWidgetPickerItem) => {
    selectWidget(widget)
    toggle(false)
  }

  const clearProviders = () => {
    fetchDataWidgets('')
    setSearch('')
    clearWidget()
    toggle(true)

    setTimeout(() => {
      if (searchInputRef.current) {
        searchInputRef.current.focus()
      }
    }, 0)
  }

  useMount(() => {
    if (shouldOpenAfterMount) {
      // This setTimeout is necessary because rendering menu occurring with delay
      setTimeout(() => toggle(true), 0)
    }
  })

  const getContent = () => {
    if (hasWidgetError) {
      return <span className="error-state">Query deleted or unavailable</span>
    }

    if (isWidgetLoading) {
      return <DotsSpinner color="dark" size="sm" position="start" />
    }

    if (selectedWidget.widgetUuid) {
      return (
        <SelectedDropdownState
          widgetTitle={selectedWidget.widgetTitle}
          widgetClassName={selectedWidget.widgetClassName}
          cardName={selectedWidget.cardName}
          boardName={selectedWidget.boardName}
        />
      )
    }

    if (isSearchable) {
      return (
        <input
          ref={searchInputRef}
          className="search-field with-placeholder"
          type="text"
          value={search}
          placeholder={placeholder}
          onChange={event => onSearch(event)}
        />
      )
    }

    return <div className="select-placeholder">{placeholder}</div>
  }

  return (
    <div
      ref={menuAnchorRef}
      id="widget-picker-dropdown"
      className={classNames('widget-picker-dropdown', {
        open: isOpen,
        error: hasWidgetError || error
      })}
    >
      <div className="widget-picker-dropdown-toggle" onClick={() => toggle(!isOpen)}>
        {getContent()}
        <div className="actions">
          {(selectedWidget.widgetUuid || hasWidgetError) && (
            <CloseIcon className="icon-close" onClick={clearProviders} />
          )}
          <ArrowIcon className="dropdown-arrow" />
        </div>
      </div>
      <WidgetPickerDropdownMenu
        widgets={options}
        isOpen={isOpen}
        dropdownMenuClassname={dropdownMenuClassname}
        toggle={toggle}
        isWidgetsListFetching={isWidgetsListFetching}
        menuAnchorRef={menuAnchorRef}
        onSelect={onSelect}
      />
    </div>
  )
}
