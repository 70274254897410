import messages from 'constants/messages'
import { FILTERS_OBJECTS } from 'constants/filters/filtersConstants'
import { getPredicateConfig } from 'features/filter/filterRuleConditions/filterRuleConditions.helpers'
import { EFilterRuleConditionPredicate } from 'features/filter/filter.types'
import { capitalizeFirstLetter } from 'helpers/common/commonHelpers'
import { FILTER_PREDICATE } from './conditionsHelpers'

const RULES_ITEMS_LEVELS = {
  WIDGET_LEVEL: 'WIDGET_LEVEL',
  CARD_LEVEL: 'CARD_LEVEL',
  COLUMN_LEVEL: 'COLUMN_LEVEL',
  BOARD_LEVEL: 'BOARD_LEVEL',
  CARD_OWNER_LEVEL: 'CARD_OWNER_LEVEL',
  DATA_RECORD_LEVEL: 'DATA_RECORD_LEVEL',
  CURRENT_LEVEL: 'CURRENT_LEVEL',
  TEAM_LEVEL: 'TEAM_LEVEL'
}

const RULES_PROP_LEVELS = {
  OWNER_LEVEL: 'OWNER_LEVEL',
  NAME_LEVEL: 'NAME_LEVEL',
  ID_LEVEL: 'ID_LEVEL',
  CLASS_LEVEL: 'CLASS_LEVEL',
  DATA_LEVEL: 'DATA_LEVEL',
  DATA_RECORD_LEVEL: 'DATA_RECORD_LEVEL'
}

const CELL_PREDICATE_TYPE = {
  TEXT: 'text',
  DATE: 'date'
}

const DATA_LEVEL_TEXTS = {
  [FILTER_PREDICATE.CURRENT]: messages.IS_CURRENT,
  [FILTER_PREDICATE.IS]: messages.IS
}

const RULES_PRETEXTS = {
  [RULES_ITEMS_LEVELS.BOARD_LEVEL]: 'on boards which',
  [RULES_ITEMS_LEVELS.CARD_LEVEL]: 'on cards which',
  [RULES_ITEMS_LEVELS.COLUMN_LEVEL]: 'on cards which column',
  [RULES_ITEMS_LEVELS.WIDGET_LEVEL]: 'which',
  [RULES_ITEMS_LEVELS.CURRENT_LEVEL]: 'with',
  [RULES_ITEMS_LEVELS.TEAM_LEVEL]: 'from teams which'
}

const RULES_PRETEXTS_CARD = {
  [RULES_ITEMS_LEVELS.BOARD_LEVEL]: 'on boards which',
  [RULES_ITEMS_LEVELS.CARD_LEVEL]: 'which',
  [RULES_ITEMS_LEVELS.COLUMN_LEVEL]: 'in columns which',
  [RULES_ITEMS_LEVELS.WIDGET_LEVEL]: 'with widgets which',
  [RULES_ITEMS_LEVELS.CURRENT_LEVEL]: 'with',
  [RULES_ITEMS_LEVELS.TEAM_LEVEL]: 'from teams which'
}

const RULES_PRETEXTS_DATA_RECORD = {
  [RULES_ITEMS_LEVELS.WIDGET_LEVEL]: 'which widget',
  [RULES_ITEMS_LEVELS.DATA_RECORD_LEVEL]: 'which',
  [RULES_ITEMS_LEVELS.CURRENT_LEVEL]: 'with',
  [RULES_ITEMS_LEVELS.TEAM_LEVEL]: 'from teams which'
}

const RULES_CONDITION_NAME = {
  [RULES_ITEMS_LEVELS.BOARD_LEVEL]: 'board',
  [RULES_ITEMS_LEVELS.CARD_LEVEL]: 'card',
  [RULES_ITEMS_LEVELS.COLUMN_LEVEL]: 'column',
  [RULES_ITEMS_LEVELS.WIDGET_LEVEL]: 'widget'
}

const RULES_PROP_LEVELS_TEXTS = {
  [RULES_PROP_LEVELS.OWNER_LEVEL]: 'owner(s)',
  [RULES_PROP_LEVELS.NAME_LEVEL]: 'name',
  [RULES_PROP_LEVELS.ID_LEVEL]: 'ID',
  [RULES_PROP_LEVELS.CLASS_LEVEL]: 'widget class',
  [RULES_PROP_LEVELS.DATA_LEVEL]: 'Data',
  [RULES_PROP_LEVELS.DATA_RECORD_LEVEL]: 'Cell Data'
}

const getRuleItemLevel = attribute => {
  const isBoard = attribute.toLowerCase().startsWith('board')
  const isCard = attribute.toLowerCase().startsWith('card')
  const isColumn = attribute.toLowerCase().startsWith('column')
  const isDataRecord = attribute.toLowerCase().startsWith('datarecord')
  const isTeam = attribute.toLowerCase().startsWith('team')

  if (isBoard) {
    return RULES_ITEMS_LEVELS.BOARD_LEVEL
  }
  if (isCard) {
    return RULES_ITEMS_LEVELS.CARD_LEVEL
  }
  if (isColumn) {
    return RULES_ITEMS_LEVELS.COLUMN_LEVEL
  }
  if (isDataRecord) {
    return RULES_ITEMS_LEVELS.DATA_RECORD_LEVEL
  }
  if (isTeam) {
    return RULES_ITEMS_LEVELS.TEAM_LEVEL
  }

  return RULES_ITEMS_LEVELS.WIDGET_LEVEL
}

const getRulePropLevel = attribute => {
  if (attribute.toLowerCase().includes('owners')) {
    return RULES_PROP_LEVELS.OWNER_LEVEL
  }
  if (attribute.toLowerCase().includes('class')) {
    return RULES_PROP_LEVELS.CLASS_LEVEL
  }
  if (attribute.toLowerCase().includes('name') || attribute.toLowerCase().includes('title')) {
    return RULES_PROP_LEVELS.NAME_LEVEL
  }
  if (attribute.toLowerCase().includes('id')) {
    return RULES_PROP_LEVELS.ID_LEVEL
  }
  if (attribute.toLowerCase().includes('record')) {
    return RULES_PROP_LEVELS.DATA_RECORD_LEVEL
  }

  return RULES_PROP_LEVELS.DATA_LEVEL
}

export const getRulePretext = (rule, queryObject) => {
  const searchedItem = getRuleItemLevel(rule.condition)
  const propertyItem = getRulePropLevel(rule.condition)

  const isAll = rule.predicate === FILTER_PREDICATE.ALL
  const isCurrent = rule.predicate === FILTER_PREDICATE.CURRENT

  if (searchedItem === RULES_ITEMS_LEVELS.TEAM_LEVEL) {
    if (isAll) return 'from all teams'
    if (isCurrent) return 'from team which'
  }
  if (propertyItem === RULES_PROP_LEVELS.ID_LEVEL && isCurrent) {
    return RULES_PRETEXTS[RULES_ITEMS_LEVELS.CURRENT_LEVEL]
  }

  if (queryObject === FILTERS_OBJECTS.CARD) {
    return RULES_PRETEXTS_CARD[searchedItem]
  }
  if (queryObject === FILTERS_OBJECTS.DATA_RECORD) {
    return RULES_PRETEXTS_DATA_RECORD[searchedItem]
  }

  return RULES_PRETEXTS[searchedItem]
}
export const getRuleConditionText = (rule, rulesConfig) => {
  const searchedItem = getRuleItemLevel(rule.condition)
  const propertyItem = getRulePropLevel(rule.condition)

  const isAll = rule.predicate === FILTER_PREDICATE.ALL
  const isCurrent = rule.predicate === FILTER_PREDICATE.CURRENT
  const isOwnersManagerCurrent = rule.predicate === FILTER_PREDICATE.OWNERS_MANAGER

  if (isAll) return ''

  const searchedItemText = RULES_CONDITION_NAME[searchedItem]
  let propertyItemText = RULES_PROP_LEVELS_TEXTS[propertyItem]

  const conditionConfig = rulesConfig[rule.condition] || null
  const predicateConfig = conditionConfig
    ? getPredicateConfig(conditionConfig, rule.predicate)
    : null
  const cellPredicateConfig = predicateConfig
    ? getPredicateConfig(predicateConfig, rule.cellPredicate)
    : null

  if (propertyItem === RULES_PROP_LEVELS.DATA_LEVEL && cellPredicateConfig) {
    // The first word in the cell predicate name can be the field type
    const cellPredicateType = cellPredicateConfig.name.toLowerCase().split(' ')?.at(0)

    if (
      cellPredicateType === CELL_PREDICATE_TYPE.TEXT ||
      cellPredicateType === CELL_PREDICATE_TYPE.DATE
    ) {
      propertyItemText = capitalizeFirstLetter(cellPredicateType)
    }
  }

  const dataLevelText =
    propertyItem === RULES_PROP_LEVELS.DATA_LEVEL && DATA_LEVEL_TEXTS[rule.predicate]

  if (isCurrent && propertyItem === RULES_PROP_LEVELS.ID_LEVEL) {
    return `current ${searchedItemText} ${propertyItemText}`
  }
  if ((isCurrent || isOwnersManagerCurrent) && propertyItem === RULES_PROP_LEVELS.OWNER_LEVEL) {
    return `${propertyItemText} is ${predicateConfig && predicateConfig.name}`
  }
  if (dataLevelText) {
    return dataLevelText
  }
  if (isCurrent && propertyItem === RULES_PROP_LEVELS.NAME_LEVEL) {
    return `${propertyItemText} is equal to current ${searchedItemText} ${propertyItemText}`
  }
  if (rule.predicate === FILTER_PREDICATE.CELL_COORDINATES) {
    return `${propertyItemText} in the cells with coordinates `
  }
  return `${propertyItemText} ${predicateConfig && predicateConfig.name}`
}

export const getCellPredicateText = (rule, cellPredicateConfig) => {
  const rulePropLevel = getRulePropLevel(rule.condition)

  if (rulePropLevel === RULES_PROP_LEVELS.DATA_LEVEL) {
    // If the cell predicate name contains a field type,
    // it must be truncated in the predicate preview text
    const [cellPredicateType, ...cellPredicateName] = cellPredicateConfig.name
      .toLowerCase()
      .split(' ')

    if (
      cellPredicateType === CELL_PREDICATE_TYPE.TEXT ||
      cellPredicateType === CELL_PREDICATE_TYPE.DATE
    ) {
      return cellPredicateName.join(' ')
    }
  }

  return cellPredicateConfig.name
}

export const getDatePredicateText = (rule, datePredicateConfig) => {
  if (rule.datePredicate === EFilterRuleConditionPredicate.exactDate) return ''

  return datePredicateConfig.name.toLowerCase()
}

export const getObjectText = object => {
  switch (object) {
    case FILTERS_OBJECTS.WIDGET:
      return messages.WIDGETS
    case FILTERS_OBJECTS.CARD:
      return messages.CARDS
    case FILTERS_OBJECTS.DATA_RECORD:
      return messages.DATA_RECORDS
    default:
      return ''
  }
}
