import gql from 'graphql-tag'
import {
  ManagerFragment,
  MemberAclFragment,
  MemberTenantsFragment,
  SubordinatesFragment,
  UserFragment
} from '../fragments/workspaceMembersFragments'

export const workspaceMembersQuery = expand => {
  const { manager = false, subordinates = false, tenants = false, acls = false } = expand

  return gql`
        query getWorkspaceMembers($organizationId: ID!, $usersIds: [ID!], $filters: FiltersInput, $limit: Int = 20, $after: String) {
            organization(id: $organizationId) {
                users(ids: $usersIds, filters: $filters, limit: $limit, after: $after ) {
                    nodes {
                        ...${UserFragment.name}
                        isVerified
                        invitationDate
                        organizationRole
                        ${acls ? `...${MemberAclFragment.name}` : ''}
                        ${tenants ? `...${MemberTenantsFragment.name}` : ''}
                        ${manager ? `...${ManagerFragment.name}` : ''}
                        ${subordinates ? `...${SubordinatesFragment.name}` : ''}
                    }
                    total
                    filterTotal
                    pageInfo {
                        endCursor
                    }
                }
            }
        }
        ${acls ? MemberAclFragment.fragment : ''}
        ${tenants ? MemberTenantsFragment.fragment : ''}
        ${UserFragment.fragment}
        ${manager ? ManagerFragment.fragment : ''}
        ${subordinates ? SubordinatesFragment.fragment : ''}
    `
}

export const workspaceMemberQuery = expand => {
  const { manager = false, subordinates = false, acls = false } = expand

  return gql`
        query getWorkspaceMember($organizationId: ID!, $userId: ID!) {
            organization(id: $organizationId) {
                user(id: $userId) {
                    ...${UserFragment.name}
                    isVerified
                    invitationDate
                    organizationRole
                    ${acls ? `...${MemberAclFragment.name}` : ''}
                    ${manager ? `...${ManagerFragment.name}` : ''}
                    ${subordinates ? `...${SubordinatesFragment.name}` : ''}
                }
            }
        }
        ${acls ? MemberAclFragment.fragment : ''}
        ${UserFragment.fragment}
        ${manager ? ManagerFragment.fragment : ''}
        ${subordinates ? SubordinatesFragment.fragment : ''}
    `
}

export const userPossibleSubordinatesQuery = () =>
  gql`
        query getUserPossibleSubordinates($organizationId: ID!, $usersId: ID!, $search: String, $limit: Int = 6) {
            organization(id: $organizationId) {
                user(id: $usersId) {
                    possibleSubordinates (limit: $limit, search: $search) {
                        ...${UserFragment.name}
                    }
                }
            }
        }
        ${UserFragment.fragment}
    `

export const workspaceManagersQuery = gql`
        query getWorkspaceManagers($organizationId: ID!, $search: String) {
            organization(id: $organizationId) {
                 organizationManagers(search: $search) {
                    ...${UserFragment.name}
                }
            }
        }
        ${UserFragment.fragment}
    `

export const workspaceManagersToAssignQuery = gql`
        query getWorkspaceManagersToAssign($organizationId: ID!, $emails: [String!], $search: String ) {
            organization(id: $organizationId) {
                assigneeUsers(emails: $emails, search: $search) {
                    ...${UserFragment.name}
                }
            }
        }
        ${UserFragment.fragment}
    `
