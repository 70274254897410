import React, { Component } from 'react'
import { debounce } from 'helpers/common/timeoutHelpers'
import { SearchInput } from 'components/common/textField/searchInput/searchInput'

const withDebounce = WrappedComponent => {
  class DebouncedSearchInput extends Component {
    constructor(props) {
      super(props)

      this.state = {
        search: props.value || ''
      }
    }

    debouncedChange = debounce(value => {
      this.props.onChange(value.trim())
    }, this.props.timeout)

    componentDidUpdate(prevProps) {
      const { value } = this.props
      const { search } = this.state
      if (search && !value && prevProps.value) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          search: ''
        })
      } else if (!search && value && !prevProps.value) {
        this.setState({
          search: value
        })
      }
    }

    changeSearch = value => {
      this.setState({
        search: value
      })
      this.debouncedChange(value)
    }

    clearSearch = () => {
      this.setState({
        search: ''
      })
      this.props.clearSearch()
    }

    render() {
      const { search } = this.state
      return (
        <WrappedComponent
          {...this.props}
          value={search}
          onChange={({ target: { value } }) => this.changeSearch(value)}
          clearSearch={this.clearSearch}
        />
      )
    }
  }

  return DebouncedSearchInput
}

export default withDebounce(SearchInput)
