import { type KeyboardEvent, useEffect } from 'react'
import { Button } from '@praxie/shared'
import { useEffectOnce } from 'react-use'
import Close from 'assets/images/icons/ic_cross_xl_B5BFC7.svg?react'
import RoundIconButton from 'components/buttons/RoundIconButton'
import { Dialog, DialogActions, DialogContent, DialogTitle } from 'components/common/dialog/dialog'
import { type IDictionaryData } from '../dictionariesSettings.types'
import {
  type IDropdownContentSetupState,
  useDropdownContentSetupStore
} from './dropdownContentSetup.store'
import { type IDropdownManualOption, EDropdownOptionsSource } from './dropdownContentSetup.types'
import { DropdownContentLinkedOverlay } from './linkedOverlay/dropdownContentLinkedOverlay'
import { DropdownListSelector } from './listSelector/dropdownListSelector'
import { DropdownOptionsTable } from './optionsTable/dropdownOptionsTable'
import { DropdownQuerySelector } from './querySelector/dropdownQuerySelector'
import { DropdownSourceSelector } from './sourceSelector/dropdownSourceSelector'
import './dropdown-content-setup.scss'

type TDropdownContent = {
  isHintEnabled: boolean
  options: Array<{ value: string }>
  hintText: string
  dictionaryId?: string | null
  shouldUnlink?: boolean
}

type TQueryWidget =
  | (IDictionaryData['queryWidget'] & {
      outputType: IDropdownContentSetupState['selectedQueryOutputType']
    })
  | undefined

type TProps = {
  isOpen: boolean
  dictionaryId?: string | null
  dropdownQueryId?: string | null
  manualOptions: IDropdownManualOption[]
  tenantId: string
  manualOptionsHint: IDictionaryData['hint']
  isDropdownSourceUpdateDisabled?: boolean
  onClose: () => void
  unlinkAvailable?: boolean
  goToLinking?: () => void
  onDictionarySelect: (dictionaryData: {
    id: string
    options: Array<Omit<IDropdownManualOption, 'label'>>
    hint: IDictionaryData['hint']
    queryWidget?: TQueryWidget
    shouldUnlink?: boolean
  }) => void
  onDropdownQuerySelect?: (payload: {
    dropdownQueryId: string | null
    options: Array<Omit<IDropdownManualOption, 'label'>>
    isHintEnabled: boolean
    hintText: string
    shouldUnlink?: boolean
  }) => void
  onManualOptionsSelect: (payload: TDropdownContent) => void
}

const ModalContent = ({
  dictionaryId,
  dropdownQueryId,
  manualOptions,
  tenantId,
  manualOptionsHint,
  isDropdownSourceUpdateDisabled = false,
  onClose,
  unlinkAvailable = false,
  goToLinking = () => {},
  onDictionarySelect,
  onDropdownQuerySelect,
  onManualOptionsSelect
}: Omit<TProps, 'isOpen'>) => {
  const isDataLinked = useDropdownContentSetupStore(state => state.isDataLinked)
  const optionsSource = useDropdownContentSetupStore(state => state.optionsSource)
  const setManualOptions = useDropdownContentSetupStore(state => state.setManualOptions)
  const setIsDataLinked = useDropdownContentSetupStore(state => state.setIsDataLinked)
  const setManualOptionsHint = useDropdownContentSetupStore(state => state.setManualOptionsHint)
  const validateDropdownContent = useDropdownContentSetupStore(
    state => state.validateDropdownContent
  )
  const reset = useDropdownContentSetupStore(state => state.reset)
  const errors = useDropdownContentSetupStore(state => state.errors)

  useEffectOnce(() => {
    void useDropdownContentSetupStore.getState().fetchDictionaries(tenantId)

    if (dictionaryId) {
      useDropdownContentSetupStore.setState({
        selectedDictionaryId: dictionaryId,
        optionsSource: EDropdownOptionsSource.list
      })
    } else if (dropdownQueryId) {
      useDropdownContentSetupStore.setState({
        selectedQueryId: dropdownQueryId,
        optionsSource: EDropdownOptionsSource.query
      })
    } else {
      useDropdownContentSetupStore.setState({ optionsSource: EDropdownOptionsSource.manual })
    }

    if (manualOptionsHint) setManualOptionsHint(manualOptionsHint)
    if (manualOptions) {
      const _manualOptions = manualOptions.reduce<IDropdownManualOption[]>((acc, option) => {
        if (option.isHint) return acc
        acc.push({ value: String(option.value), label: option.value })

        return acc
      }, [])

      setManualOptions(_manualOptions)
    }

    return () => reset()
  })

  useEffect(() => {
    setIsDataLinked(unlinkAvailable)
  }, [unlinkAvailable, setIsDataLinked])

  const onSave = () => {
    validateDropdownContent()

    const {
      dictionaries,
      selectedDictionaryId,
      selectedQueryId,
      errors,
      options,
      selectedQueryOutputType,
      manualOptions: updatedOptions,
      manualOptionsHint: hint
    } = useDropdownContentSetupStore.getState()

    if (!errors.isValid) return

    const isDictionaryMode = !!selectedDictionaryId

    if (isDictionaryMode) {
      const selectedDictionary = dictionaries.find(
        dictionary => dictionary.value === selectedDictionaryId
      )!

      const isQueryOptions =
        !!selectedDictionary.queryWidget?.queryId && selectedDictionary.queryWidget.flag

      const queryWidget = isQueryOptions
        ? { ...selectedDictionary.queryWidget!, outputType: selectedQueryOutputType }
        : undefined

      onDictionarySelect({
        id: selectedDictionaryId,
        options: isQueryOptions ? options : selectedDictionary.options,
        hint: selectedDictionary.hint,
        queryWidget,
        shouldUnlink: unlinkAvailable && !isDataLinked
      })
    } else if (onDropdownQuerySelect && optionsSource === EDropdownOptionsSource.query) {
      onDropdownQuerySelect({
        dropdownQueryId: selectedQueryId,
        options,
        isHintEnabled: !!hint.flag,
        hintText: hint.textMessage,
        shouldUnlink: unlinkAvailable && !isDataLinked
      })
    } else {
      onManualOptionsSelect({
        dictionaryId: null,
        options: updatedOptions,
        isHintEnabled: !!hint.flag,
        hintText: hint.textMessage,
        shouldUnlink: unlinkAvailable && !isDataLinked
      })
    }

    onClose()
  }

  return (
    <>
      <DialogTitle className="header">
        <div className="title">Dropdown List Setup</div>
        <div className="close-button">
          {/* @ts-expect-error */}
          <RoundIconButton size="medium" onClick={onClose}>
            <Close title="Close" />
          </RoundIconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        {isDataLinked && (
          <DropdownContentLinkedOverlay goToLinking={goToLinking} onClose={onClose} />
        )}
        <DropdownSourceSelector isDisabled={isDropdownSourceUpdateDisabled} />
        {optionsSource === EDropdownOptionsSource.list && (
          <DropdownListSelector isDisabled={isDropdownSourceUpdateDisabled} tenantId={tenantId} />
        )}
        {optionsSource === EDropdownOptionsSource.query && (
          <DropdownQuerySelector isDisabled={isDropdownSourceUpdateDisabled} />
        )}
        <DropdownOptionsTable tenantId={tenantId} />
      </DialogContent>
      <DialogActions className="footer">
        <div className="duplicate-error">
          {errors.hasDuplicatedOptions && 'Options must be unique'}
        </div>
        <div className="actions-wrapper">
          <Button appearance="secondary" className="cancel" onClick={onClose}>
            Cancel
          </Button>
          <Button className="save" onClick={onSave}>
            Save
          </Button>
        </div>
      </DialogActions>
    </>
  )
}

export const DropdownContentSetup = ({ isOpen, ...restProps }: TProps) => (
  <Dialog
    className="dropdown-content-setup"
    open={isOpen}
    onClose={restProps.onClose}
    onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => e.stopPropagation()}
    onKeyUp={(e: KeyboardEvent<HTMLDivElement>) => e.stopPropagation()}
  >
    <ModalContent {...restProps} />
  </Dialog>
)
