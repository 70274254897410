import React from 'react'
import classNames from 'classnames'
import EditableDropdownMenu from 'components/fields/EditableDropdownMenu'
import 'scss/ribbonTools/ribbon-editable-select.scss'

const RibbonEditableDropdownMenu = props => {
  const { className } = props
  return (
    <EditableDropdownMenu
      {...props}
      className={classNames('ribbon-editable-dropdown-menu', className)}
    />
  )
}

export default RibbonEditableDropdownMenu
