export const isWidgetFromFirstSection = widget => widget.section === 0

export const isAnyWidgetFromFirstSection = widgets => widgets.some(isWidgetFromFirstSection)

export function getWidgetsDataByIds(widgets, widgetsIds, additionalData = {}) {
  // such implementation is for keeping order 'widget ID <-> widget Data'
  const widgetsData = []
  widgetsIds.forEach(widgetID => {
    // for each widget ID find widget
    const widgetItem = widgets.filter(widget => widget.uuid === widgetID)[0]
    if (widgetItem) {
      // if it was found put it to data array
      widgetsData.push({ ...widgetItem, ...additionalData })
    }
  })
  return widgetsData
}

export const hasFlag = (flags, flag) => flags?.[flag]

export const flagsList = {
  hasCustomRibbon: 'hasCustomRibbon',
  hasCustomRibbonOnDetailed: 'hasCustomRibbonOnDetailed',
  isDataConsumer: 'isDataConsumer',
  isDataProvider: 'isDataProvider',
  hasEditMode: 'hasEditMode',
  hasMixedState: 'hasMixedState',
  isEditableOnDetailed: 'isEditableOnDetailed', // Deprecated, use 'isLocked' instead.
  hidePencilOnDetailed: 'hidePencilOnDetailed',
  hideOutlineOnDetailed: 'hideOutlineOnDetailed', // deprecated https://leverxeu.atlassian.net/browse/EUPBOARD01-5829
  hasDownloadLink: 'hasDownloadLink',
  hasHyperlink: 'hasHyperlink',
  notClickable: 'notClickable',
  withoutAllFrames: 'withoutAllFrames',
  isChart: 'isChart',
  isHiddenOnDetailed: 'isHiddenOnDetailed',
  isThirdParty: 'isThirdParty',
  isClickTrigger: 'isClickTrigger',
  groupToUploadFilesWidget: 'groupToUploadFilesWidget',
  dontHaveBoardView: 'dontHaveBoardView',
  supportBoardViewFit: 'supportBoardViewFit',
  hideProvidersListOnDetailed: 'hideProvidersListOnDetailed',
  // define widget with data fetching request, used in pdf generation
  hasDataToFetch: 'hasDataToFetch',
  hideWidgetPlaceholderOnDetailed: 'hideWidgetPlaceholderOnDetailed',
  showProvidersListOnDetailed: 'showProvidersListOnDetailed',
  // define if widget has custom hide/unhide on detailed view
  hasCustomHideOnDetailed: 'hasCustomHideOnDetailed',
  hasBoardViewCustomPaddings: 'hasBoardViewCustomPaddings'
}

export const getGroupedFiles = (uploadFilesUuid, widgets) =>
  widgets.filter(widget => widget.uploadFilesUuid === uploadFilesUuid)

export const isWidgetHiddenInDetailedView = widget =>
  !!widget.uploadFilesUuid || hasFlag(widget.flags, flagsList.isHiddenOnDetailed)

export const isWidgetHiddenInBoardView = widget => {
  const { uploadFilesUuid, showOnBoardView } = widget

  const isHiddenOnDetailed = hasFlag(widget.flags, flagsList.isHiddenOnDetailed)
  return !!uploadFilesUuid || !showOnBoardView || isHiddenOnDetailed
}

export function hasCategory(categories, category, onlyParent = true) {
  if (!categories) {
    return false
  }

  return categories.reduce((accumulator, item) => {
    if (accumulator) {
      return true
    }

    if (item.categoryCode === category) {
      return true
    }
    if (!onlyParent && item.category && item.category.length) {
      return hasCategory(item.category, category, onlyParent)
    }
    return false
  }, false)
}

export function splitBySubCategories(array, parentCategory) {
  const res = {}
  if (!array) {
    return res
  }

  array.forEach(item => {
    if (!parentCategory || item.category[0].categoryCode === parentCategory) {
      item.category[0].category.forEach(subCategory => {
        if (!res[subCategory.categoryCode]) {
          res[subCategory.categoryCode] = []
        }
        res[subCategory.categoryCode].push(item)
      })
    }
  })

  return res
}

// calculate max z-index to set it to editing widget
export const calculateMaxZIndex = widgets => {
  if (!widgets) {
    return 1
  }

  return widgets.reduce((zIndex, widget) => {
    const widgetZIndex = widget.zIndex || 0

    return Math.max(widgetZIndex + 1, zIndex)
  }, 1)
}

export const getWidgetsBySection = (widgets, sectionIndex, isSnapshotPreview) =>
  widgets
    ? widgets
        .filter(widget =>
          isSnapshotPreview
            ? !isWidgetHiddenInDetailedView(widget)
            : widget.section === sectionIndex && !isWidgetHiddenInDetailedView(widget)
        )
        .sort((a, b) => a.zIndex - b.zIndex)
    : []

export const isFileManager = ({ category }) => hasCategory(category, 'upload', false)
