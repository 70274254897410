import { OBJECT_TYPES } from 'constants/workflows'
import { ORGANIZATION_TYPE, TENANT_TYPE } from '../constants/schemaConstants'

export const createEntitiesTree = ({ organizationId, payload = [] }) => {
  const children = payload.reduce((map, object) => {
    const { tenantId, boardId, cardUuid, widgetUuid, userId } = object.data
    if (!map[tenantId]) {
      map[tenantId] = {
        id: tenantId,
        type: TENANT_TYPE,
        children: {}
      }
    }
    const tenant = map[tenantId]
    if (boardId) {
      if (!tenant.children[boardId]) {
        tenant.children[boardId] = {
          id: boardId,
          type: OBJECT_TYPES.BOARD,
          children: {}
        }
      }
      const board = tenant.children[boardId]
      if (cardUuid) {
        if (!board.children[cardUuid]) {
          board.children[cardUuid] = {
            id: cardUuid,
            type: OBJECT_TYPES.CARD,
            children: {}
          }
        }
        const card = board.children[cardUuid]
        if (widgetUuid) {
          if (!card.children[widgetUuid]) {
            card.children[widgetUuid] = {
              id: widgetUuid,
              type: OBJECT_TYPES.WIDGET,
              children: {}
            }
          }
        }
      }
    }
    if (userId && !map[userId]) {
      map[userId] = {
        id: userId,
        type: OBJECT_TYPES.USER,
        children: {}
      }
    }
    return map
  }, {})

  return {
    id: organizationId,
    type: ORGANIZATION_TYPE,
    children
  }
}
