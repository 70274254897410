import { requestWrapper, url } from 'helpers/fetchHelpers'
import graphqlRequestWrapper from 'graphql/common/requestHelpers'
import { widgetsFromAllTenantsQuery, widgetsFromTenantQuery } from 'graphql/queries/tenantQueries'
import {
  extractWidgetsFromAllTenants,
  extractWidgetsFromTeam
} from 'graphql/dataExtractors/tenantExtractors'

export const getTenantsList = () =>
  requestWrapper({
    rUrl: `${url}tenants/list`,
    type: 'GET',
    payload: {}
  })

export function processAddTenantRequest(payload) {
  return requestWrapper({
    rUrl: `${url}tenant`,
    type: 'POST',
    payload: payload.data
  })
}

export function getResourcesNames(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/resources/names`,
    type: 'POST',
    payload: payload.data
  })
}

export function saveTeamSettingsRequest(payload) {
  const data = {}
  if (payload.data.teamName) {
    data.name = payload.data.teamName
  }
  if (payload.data.dateFormat) {
    data.settings = {}
    data.settings.dateFormat = payload.data.dateFormat
  }
  if (payload.data.sendNotifications !== undefined) {
    data.sendNotifications = payload.data.sendNotifications
  }

  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}`,
    type: 'PUT',
    payload: data
  })
}

export function renameTeamRequest(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/rename`,
    type: 'PUT',
    payload: payload.data
  })
}

export function getTeamSettingsRequest(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}`,
    type: 'GET',
    payload: {}
  })
}

export function deleteTenant(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}`,
    type: 'DELETE',
    payload: payload.data
  })
}

export function getTenantLog(payload) {
  const { tenantId, offset, limit } = payload
  return requestWrapper({
    rUrl: `${url}tenant/${tenantId}/log/list`,
    type: 'GET',
    payload: { offset, limit }
  })
}

export function getTenantPermissions(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/permissions`,
    type: 'GET',
    payload: {}
  })
}

export function deleteTenantMember(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/leave`,
    type: 'POST',
    payload: payload.data
  })
}

export const getWidgetsFromAllTenants = ({ variables }) =>
  graphqlRequestWrapper({
    query: widgetsFromAllTenantsQuery(),
    variables
  }).then(res => extractWidgetsFromAllTenants(res.data.organization.tenants))

export const getWidgetsFromTeam = ({ variables }) =>
  graphqlRequestWrapper({
    fetchPolicy: 'no-cache',
    query: widgetsFromTenantQuery(),
    variables
  }).then(res => extractWidgetsFromTeam(res.data.organization.tenant.boards))

export const getAssociatedWidgetsFromAllTenants = ({ variables }) =>
  graphqlRequestWrapper({
    query: widgetsFromAllTenantsQuery(),
    variables
  }).then(res => res?.data?.organization?.tenants)

export function getQueryWidgetsFromAllTenants(payload) {
  let queryString = ''

  if (payload.search) queryString = `?text=${payload.search}`

  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/queries${queryString}`,
    type: 'GET',
    payload: {}
  })
}

export const getQueryWidgetData = payload => {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/card/${payload.cardUuid}/query/${payload.widgetUuid}/run`,
    type: 'POST',
    payload: {
      search: payload.search
    }
  })
}
