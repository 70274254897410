/* eslint-disable import/no-cycle */
import { CopyBoardFilesRequest } from '_proto/uploader/v1/uploader.pb'
import * as widgetAPI from 'api/widgetAPI'
import { createWidgetFromSource, widgetPositionPad } from 'helpers/builderHelpers'
import { isTextErrorMessage } from 'helpers/errorsHandlingHelpers'
import { hasCategory } from 'helpers/widget/widgetDataHelpers'
import { FileUploaderGrpcService } from 'services/fileUploader.service'
import { createWidgetMenu, processUploadFile, processUploadCloudFile } from 'api/widgetAPI'
import { getCurrentUser } from 'selectors/profile.selectors'
import { parseFileToWidget, createWidgetFileLink } from 'helpers/files/filesHelpers'
import { currentCardSelector, getActiveSection } from 'selectors/builderSelectors'
import { isFileUploadingSelector } from '../selectors/widgetSelectors'
import { hideModalWindowSpinner, showModalWindowSpinner } from './spinnerActions'
import { saveChanges } from './builderActions'
import { showToastMessage, createWidgetOnDetailedView } from './boardActions'
import FilesUploader from '../helpers/filesUploaderDataProvider'
import messages from '../constants/messages'
import * as actions from '../constants/actionTypes'

// get widget list
export function widgetClassesListRequestStart() {
  return { type: actions.REQUEST_WIDGET_CLASSES_LIST }
}

export function widgetClassesListReceive(payload, tenantId, eventPayload = {}) {
  try {
    if (tenantId && payload && payload.length) {
      const menuListFromLocalStorage = localStorage.getItem('menu')
      let menuListToSave = menuListFromLocalStorage ? JSON.parse(menuListFromLocalStorage) : {}
      if (Object.keys(menuListToSave).length >= 3) {
        menuListToSave = {}
      }
      menuListToSave[tenantId] = payload
      localStorage.setItem('menu', JSON.stringify({ ...menuListToSave }))
    }
  } catch (e) {
    localStorage.removeItem('menu')
  }

  if (eventPayload.eventStartDate) {
    ga(
      'send',
      'event',
      'Card Builder',
      'Load Widget Menu',
      `Widget menu tab clicked ${eventPayload.isCache ? 'cache' : 'server'}`,
      parseFloat(new Date() - eventPayload.eventStartDate)
    )
  }

  return { type: actions.RECEIVE_WIDGET_CLASSES_LIST, payload }
}

export function receiveWidgetClassesListErrorMessage(payload) {
  return { type: actions.RECEIVE_WIDGET_CLASSES_LIST_ERROR, payload }
}

export function widgetClassesListRequest(payload, forceRequest, eventStartDate) {
  return dispatch => {
    dispatch(widgetClassesListRequestStart())
    const menuListFromLocalStorage = JSON.parse(localStorage.getItem('menu'))
    const menuFromLocalStorage = menuListFromLocalStorage
      ? menuListFromLocalStorage[payload.tenantId]
      : null
    return widgetAPI
      .getWidgetsMenu(payload, menuFromLocalStorage, forceRequest)
      .then(response => {
        dispatch(
          widgetClassesListReceive(response.data, payload.tenantId, {
            eventStartDate,
            isCache: menuFromLocalStorage && !forceRequest
          })
        )
        return response.data
      })
      .catch(err => dispatch(receiveWidgetClassesListErrorMessage(err)))
  }
}

// update widget menu item
export function updateWidgetMenuItemRequestStart(payload) {
  return { type: actions.REQUEST_UPDATE_WIDGET_MENU_ITEM, payload }
}

export function updateWidgetMenuItem(payload) {
  return dispatch => {
    dispatch(updateWidgetMenuItemRequestStart(payload))
    return widgetAPI.updateWidgetMenuItemRequest(payload).catch(err => console.error(err))
  }
}

// Custom widget uploading
export const receiveUploadWidget = payload => (dispatch, getState) => {
  const { username, email } = getCurrentUser(getState())
  const uploadedWidget = {
    ...payload,
    uploadedBy: {
      ...payload.uploadedBy,
      username,
      email
    },
    uploadDate: Date.now()
  }
  dispatch({ type: actions.RECEIVE_UPLOAD_WIDGET, payload: uploadedWidget })
}

export const uploadWidget = payload => async dispatch => {
  const { promise, canceler } = await processUploadFile(payload)

  const request = promise.catch(err => {
    if (err.name !== 'AbortError') {
      dispatch(
        showToastMessage({
          text: isTextErrorMessage(err.message) ? err.message : messages.FILE_UPLOAD_ERROR,
          size: 'M'
        })
      )
    }

    return { uploadingError: true }
  })

  return { promise: request, canceler }
}

export function uploadCloudWidget(payload) {
  return async (dispatch, getState) => {
    const { uuid } = currentCardSelector(getState())
    const { promise, canceler } = await processUploadCloudFile(payload)
    const request = promise
      .then(({ data }) => {
        dispatch(receiveUploadWidget(data))
        return {
          ...createWidgetFromSource(data),
          section: getActiveSection(getState()) || 0,
          cardUuid: uuid
        }
      })
      .catch(err => {
        dispatch(
          showToastMessage({
            text: isTextErrorMessage(err.message) ? err.message : messages.FILE_UPLOAD_ERROR,
            size: 'M'
          })
        )
        return { uploadingError: true }
      })
    return { promise: request, canceler }
  }
}

export const copyWidget =
  ({ oldWidgetKey, imgSrc, thumbnailSrc, fileLink, ...restPayload }) =>
  (dispatch, getState) => {
    const { profile } = getState()

    const request = CopyBoardFilesRequest.create({
      srcKey: {
        scope: {
          tenantId: oldWidgetKey.tenantId,
          boardId: oldWidgetKey.boardId
        }
      },
      dstKey: {
        scope: {
          tenantId: oldWidgetKey.targetTenantID,
          boardId: oldWidgetKey.targetBoardID
        }
      },
      names: FileUploaderGrpcService.getFileNames({ imgSrc, thumbnailSrc, fileLink })
    })

    const promise = FileUploaderGrpcService.copyBoardFiles({ request })
      .then(response => {
        const { targetBoardID, targetTenantID } = oldWidgetKey
        const copiedFiles = response.results
        const mappedFiles = copiedFiles.map(file =>
          createWidgetFileLink({
            tenantId: targetTenantID,
            boardId: targetBoardID,
            name: file.info?.name
          })
        )

        const copiedData = fileLink
          ? { fileLink: mappedFiles[0] }
          : mappedFiles.reduce((info, file) => {
              if (file.includes('thumbnail')) info.thumbnailSrc = file
              else info.imgSrc = file
              return info
            }, {})

        const uploadedWidget = {
          imgSrc,
          thumbnailSrc,
          fileLink,
          ...restPayload,
          ...copiedData,
          uploadedBy: {
            username: profile.user.username,
            email: profile.user.email,
            key: {
              path: ['User', profile.user.id]
            }
          },
          uploadDate: Date.now()
        }

        createWidgetMenu({
          tenantId: targetTenantID,
          boardId: targetBoardID,
          data: uploadedWidget
        }).then(() => dispatch(receiveUploadWidget(uploadedWidget)))

        const copiedWidget = { oldWidgetKey, imgSrc, thumbnailSrc, fileLink, ...restPayload }
        const newFields = {}
        if (copiedData.fileLink) {
          newFields.fileLink = copiedData.fileLink
        }
        if (copiedData.imgSrc) {
          newFields.imgSrc = copiedData.imgSrc
        }
        if (copiedData.thumbnailSrc) {
          newFields.thumbnailSrc = copiedData.thumbnailSrc
        }
        delete copiedWidget.oldWidgetKey
        delete copiedWidget.uploadedBy
        delete copiedWidget.dataCallback
        return { ...copiedWidget, ...newFields }
      })
      .catch(err => {
        dispatch(
          showToastMessage({
            text: isTextErrorMessage(err.message) ? err.message : messages.FILE_UPLOAD_ERROR,
            size: 'M'
          })
        )
        return { uploadingError: true }
      })

    return { promise, canceler: null }
  }

export function setUploadFilesWidgetData(payload) {
  return { type: actions.SET_UPLOAD_FILES_WIDGET_DATA, payload }
}

export function toggleUploadedImageDeleteModal(payload) {
  return { type: actions.TOGGLE_UPLOADED_IMAGE_DELETE_MODAL, payload }
}

// Custom file delete
export function receiveDeleteUploadedWidget(payload) {
  return { type: actions.RECEIVE_DELETE_UPLOADED_WIDGET, payload }
}

export function receiveDeleteUploadedWidgetErrorMessage(payload) {
  return { type: actions.RECEIVE_DELETE_UPLOADED_WIDGET_ERROR_MESSAGE, payload }
}

export function deleteUploadedWidget(payload) {
  return dispatch => {
    dispatch(showModalWindowSpinner())
    return widgetAPI
      .deleteWidgetMenuItem(payload)
      .then(() => {
        dispatch(receiveDeleteUploadedWidget(payload))
        dispatch(hideModalWindowSpinner())
        dispatch(toggleUploadedImageDeleteModal(false))
      })
      .catch(err => {
        dispatch(hideModalWindowSpinner())
        dispatch(receiveDeleteUploadedWidgetErrorMessage(err))
      })
  }
}

// files queue
export function toggleFilesUploadingFlag(payload) {
  return { type: actions.TOGGLE_FILES_UPLOADING_FLAG, payload }
}

export function updateCurrentUploadingData(payload) {
  return { type: actions.UPDATE_CURRENT_UPLOADING_DATA, payload }
}

// @param {boolean} needCreateWidgets - if new file widgets should be created
// @param {boolean} ignoreSelection - if selected widgets state should not be changed
// @param {boolean} isUploadingFromDetailedView - if new file should be created from detail view
export function createWidgetsAfterFilesUploading(
  needCreateWidgets,
  ignoreSelection,
  payload,
  isUploadingFromDetailedView = false
) {
  return (dispatch, getState) => {
    const state = getState()
    const { currentCard, activeSection } = state.builder
    const { currentUploadFilesWidgetData } = state.widget

    if (!currentCard.uuid && !payload.length) {
      return
    }

    const tenantId = currentCard.board.tenantId || currentUploadFilesWidgetData?.tenantId
    const boardId = currentCard.board.boardId || currentUploadFilesWidgetData?.boardId
    const cardID = currentCard.uuid || currentUploadFilesWidgetData?.cardUuid

    let newWidgets = payload
    if (needCreateWidgets) {
      newWidgets = widgetPositionPad(
        payload.map(widget => ({
          section: activeSection || 0,
          ...createWidgetFromSource(widget),
          cardUuid: cardID
        }))
      )
    }

    const payloadData = {
      type: actions.WIDGET_CREATE,
      data: newWidgets,
      tenantId,
      boardId,
      itemID: cardID,
      ignoreSelection
    }

    if (isUploadingFromDetailedView) {
      dispatch(createWidgetOnDetailedView(payloadData))
    } else {
      dispatch(saveChanges(payloadData))
    }

    dispatch(setUploadFilesWidgetData(null))
  }
}

export function startFileUploader(payload) {
  return (dispatch, getState) => {
    const isFileUploading = isFileUploadingSelector(getState())
    if (!isFileUploading) {
      dispatch(toggleFilesUploadingFlag(true))
      FilesUploader.setDispatch(dispatch)
      FilesUploader.setUploader(payload.uploader)
      FilesUploader.setCallback(payload.callback)

      if (payload.responses) {
        FilesUploader.setInitialResponse(payload.responses)
      }

      FilesUploader.addToQueue(payload.data)

      FilesUploader.upload(0)
    } else {
      FilesUploader.addToQueue(payload.data)
    }
  }
}

export const createWidgetsFromFiles =
  ({ data, tenantId, boardId }) =>
  dispatch => {
    const widgetMenuRequests = data.map(({ fileData, createFileResponse }) => {
      const file = {
        ...FileUploaderGrpcService.parseFile(createFileResponse),
        ...fileData
      }

      return createWidgetMenu({
        tenantId,
        boardId,
        data: parseFileToWidget({ file, tenantId, boardId })
      }).then(({ data: _data }) => {
        dispatch(receiveUploadWidget(_data))
        return _data
      })
    })

    return Promise.all(widgetMenuRequests)
  }

export const createFilesOnCard = data => async (dispatch, getState) => {
  const {
    currentCard: { tenantId, boardId },
    activeSection
  } = getState().builder
  const createdWidgets = await dispatch(createWidgetsFromFiles({ data, tenantId, boardId }))
  dispatch(
    createWidgetsAfterFilesUploading(
      true,
      false,
      createdWidgets.map(widget => ({
        ...widget,
        section: activeSection || 0
      }))
    )
  )
}

export function uploadFiles(payload) {
  return dispatch => {
    dispatch(
      startFileUploader({
        data: payload,
        uploader: uploadWidget,
        callback: createFilesOnCard
      })
    )
  }
}

export const uploadCloudFiles = payload => (dispatch, getState) => {
  dispatch(
    startFileUploader({
      data: payload,
      uploader: uploadCloudWidget,
      callback: data => _dispatch => {
        let widgets = data.map(widget => widget.createFileResponse || widget)

        // https://leverxeu.atlassian.net/browse/EUPBOARD01-12643
        // to have an ability to add files to Files Manager Widget from App/Detailed view.
        const currentUploadFilesWidgetData = getState().widget.currentUploadFilesWidgetData

        if (currentUploadFilesWidgetData) {
          const { uploadFilesWidgetId, cardUuid } = currentUploadFilesWidgetData

          widgets = widgets.map(widget => ({
            ...createWidgetFromSource(widget),
            uploadFilesUuid: uploadFilesWidgetId,
            cardUuid
          }))
        }

        _dispatch(
          createWidgetsAfterFilesUploading(
            false,
            false,
            widgets,
            currentUploadFilesWidgetData?.isUploadingFromDetailedView
          )
        )
      }
    })
  )
}

export function copyFiles(payload) {
  return dispatch => {
    const widgetsWithResources = []
    const widgets = []

    payload.forEach(widget => {
      if (hasCategory(widget.category, 'file')) {
        widgetsWithResources.push(widget)
      } else {
        widgets.push(widget)
      }
    })

    dispatch(
      startFileUploader({
        data: widgetsWithResources,
        responses: widgets,
        uploader: copyWidget,
        callback: data => _dispatch => {
          const _widgets = data.map(widget => widget.createFileResponse || widget)
          _dispatch(createWidgetsAfterFilesUploading(false, false, _widgets))
        }
      })
    )
  }
}

export function toggleGooglePicker(payload) {
  return { type: actions.TOGGLE_GOOGLE_PICKER, payload }
}

export function toggleOneDrivePicker(payload) {
  return { type: actions.TOGGLE_ONE_DRIVE_PICKER, payload }
}

export const toggleWidgetFullscreenModal = payload => ({
  type: actions.TOGGLE_WIDGET_FULLSCREEN_MODAL,
  payload
})
