import React from 'react'

import messages from 'constants/messages'
import FormField from 'components/common/FormField'
import PlainInput from 'components/fields/PlainInput'
import ReactSelect from 'components/fields/ReactSelect'
import { Tooltip } from 'components/common/tooltip/tooltip'
import { Switcher } from 'components/common/switcher/switcher'
import { FILTERS_OBJECTS_OPTIONS } from 'helpers/filters/constantsPresentationHelpers'
import { findSelectedOptionByKey } from 'helpers/fields/selectHelpers'

import 'scss/filters/filterConfiguration/filter-metadata.scss'

const FilterObjectTooltip = props => {
  const { isObjectSelectionDisabled } = props

  const tooltipText = isObjectSelectionDisabled
    ? messages.CANT_CHANGE_FILTER_OBJECT_TOOLTIP
    : messages.SELECT_FILTER_OBJECT_TOOLTIP

  return (
    <Tooltip id="filter-object-tooltip" placement="top" title={tooltipText}>
      <i className="icon icon-hint" />
    </Tooltip>
  )
}

const FilterMetadata = props => {
  const {
    filterId,
    name,
    nameError,
    onNameChange,
    object,
    onObjectChange,
    hasConnections,
    objectError,
    isCrossTeam,
    handleIsCrossTeamChange,
    isCrossTeamSwitcherShown,
    isReadOnly
  } = props

  const isCrossTeamsDisabled = !!filterId || isReadOnly
  const isObjectSelectionDisabled = hasConnections || isReadOnly

  const toggleCrossTeamSwitcher = () => {
    handleIsCrossTeamChange(!isCrossTeam)
  }

  return (
    <div className="filter-metadata-content">
      <FormField
        label={messages.FILTER_NAME}
        id="global-filter-name"
        type="text"
        error={nameError}
        className="filter-name-field"
      >
        <PlainInput
          className="input-field"
          maxLength={50}
          value={name}
          onChange={onNameChange}
          placeholder={messages.FILTER_NAME_PLACEHOLDER}
          disabled={isReadOnly}
        />
      </FormField>
      <FormField
        label={messages.FILTER_OBJECT}
        id="global-filter-object"
        className="filter-object-field"
        error={objectError}
      >
        <div>
          <FilterObjectTooltip isObjectSelectionDisabled={isObjectSelectionDisabled} />
          <ReactSelect
            className="filter-object"
            size="large"
            value={findSelectedOptionByKey(object, FILTERS_OBJECTS_OPTIONS)}
            options={FILTERS_OBJECTS_OPTIONS}
            onChange={onObjectChange}
            error={objectError}
            isDisabled={isObjectSelectionDisabled}
          />
        </div>
      </FormField>
      {isCrossTeamSwitcherShown && (
        <div className="multiple-teams-field">
          <Switcher
            id="multiple-teams-switcher"
            className="multiple-teams-switcher"
            checked={isCrossTeam}
            onChange={toggleCrossTeamSwitcher}
            disabled={isCrossTeamsDisabled}
          />
          <label htmlFor="multiple-teams-switcher">Filter data from multiple teams</label>
        </div>
      )}
    </div>
  )
}

export default FilterMetadata
