/* eslint-disable import/no-cycle */
import { getUserJobs } from '../api/jobsAPI'
import { poleWorkflowExecutionJobs } from './clickWorkflowActions'
import { jobCompleted } from './socketActions'

const POLLING_INTERVAL = 5000
const DURATION = POLLING_INTERVAL * 2

let pollingInterval = null
let workflowPollintInterval = null

const poleJobs = () => async dispatch => {
  try {
    const { data: jobs } = await getUserJobs({ duration: DURATION })

    jobs.forEach(job => dispatch(jobCompleted(job)))
  } catch (error) {
    console.error(error)
  }
}

const poleWFMonitors = () => (dispatch, getState) => {
  const {
    socket: { subscribedMonitor }
  } = getState()

  // if no subscription to WF monitor - skip polling
  if (!subscribedMonitor.monitorId) {
    return Promise.resolve()
  }

  return dispatch(poleWorkflowExecutionJobs(subscribedMonitor))
}

const poleAll = () => dispatch => {
  const actionsToPole = [poleJobs]

  return Promise.all(actionsToPole.map(action => dispatch(action()))).catch(error =>
    console.error(error)
  )
}

export const enableSocketFallback = () => dispatch => {
  pollingInterval = setInterval(() => dispatch(poleAll()), POLLING_INTERVAL)
}

export const disableSocketFallback = () => dispatch => {
  clearInterval(pollingInterval)
  pollingInterval = null

  // pole when disconnect to not skip events
  return dispatch(poleAll())
}

export const enableWorkflowSocketFallback = () => dispatch => {
  workflowPollintInterval = setInterval(() => {
    dispatch(poleWFMonitors()).catch(error => console.error(error))
  }, POLLING_INTERVAL)
}

export const disableWorkflowSocketFallback = () => {
  clearInterval(workflowPollintInterval)
  workflowPollintInterval = null
}

export const isSocketFallbackEnabled = () => !!pollingInterval
