import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import '../../scss/common/toggle-button-group.scss'

const Button = props => {
  const { isActive, className, children, onClick } = props

  return (
    <div
      className={classNames('up-toggle-button', className, { active: isActive })}
      onClick={onClick}
    >
      {children}
    </div>
  )
}

Button.propTypes = {
  isActive: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  onClick: PropTypes.func
}

Button.defaultProps = {
  isActive: false,
  className: '',
  children: null,
  onClick: null
}

const ToggleButtonGroup = props => {
  const { className, size, children } = props

  return <div className={classNames('up-toggle-button-group', className, size)}>{children}</div>
}

ToggleButtonGroup.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
}

ToggleButtonGroup.defaultProps = {
  size: 'medium',
  className: '',
  children: null
}

ToggleButtonGroup.Button = Button

export default ToggleButtonGroup
