import messages from '../../constants/messages'
import { EFieldKeys } from '../../constants/workflowBuilder/blocksFieldsKeys'

export const STRING_INPUT = 'stringInput'
export const PLAIN_STRING = 'plainString'
export const COLUMN_NUMBER = 'columnNumber'
export const LEFT_COLUMN = 'leftColumn'
export const RIGHT_COLUMN = 'rightColumn'
export const PREV_COLUMN = 'prevColumn'
export const NEXT_COLUMN = 'nextColumn'

export const COLUMN_TYPES = {
  [STRING_INPUT]: { value: STRING_INPUT, label: messages.COLUMN_NAME_EQUAL_TO_STRING },
  [PLAIN_STRING]: { value: PLAIN_STRING, label: messages.COLUMN_NAME_EQUAL_TO_INPUT_FIELD },
  [COLUMN_NUMBER]: { value: COLUMN_NUMBER, label: messages.COLUMN_NUMBER_EQUAL_TO_INPUT_FIELD },
  [LEFT_COLUMN]: { value: LEFT_COLUMN, label: messages.VERY_LEFT_COLUMN },
  [RIGHT_COLUMN]: { value: RIGHT_COLUMN, label: messages.VERY_RIGHT_COLUMN },
  [PREV_COLUMN]: { value: PREV_COLUMN, label: messages.PREV_COLUMN },
  [NEXT_COLUMN]: { value: NEXT_COLUMN, label: messages.NEXT_COLUMN }
}

export const COLUMN_TYPES_OPTIONS = Object.values(COLUMN_TYPES)

export const validateMoveCardBlock = ({ block }) => {
  const error = {}
  if (!block.input || !block.input[EFieldKeys.SOURCE_INPUT]) {
    error[EFieldKeys.SOURCE_INPUT] = messages.emptyField
    return { error }
  }

  if (
    !block.meta ||
    (block.meta[EFieldKeys.TYPE] === STRING_INPUT && !block.input[EFieldKeys.STRING_INPUT])
  ) {
    error[EFieldKeys.STRING_INPUT] = messages.emptyField
    return { error }
  }

  if (block.meta[EFieldKeys.TYPE] === PLAIN_STRING && !block.meta[EFieldKeys.NAME]) {
    error[EFieldKeys.NAME] = messages.emptyField
    return { error }
  }

  if (block.meta[EFieldKeys.TYPE] === COLUMN_NUMBER && !block.meta[EFieldKeys.COLUMN_NUMBER]) {
    error[EFieldKeys.COLUMN_NUMBER] = messages.emptyField
    return { error }
  }

  return { error }
}
