import React from 'react'
import classNames from 'classnames'
import MuiAutocomplete, {
  type AutocompleteProps,
  type AutocompleteRenderInputParams
} from '@mui/material/Autocomplete'
import { type TSelectSize } from '../select'
import { TextField } from '../../textField/textField'
import './autocomplete-select.scss'

type TOption = {
  value: string
  label: string
}

export type TAutocompleteProps = Omit<
  AutocompleteProps<TOption, boolean | undefined, boolean | undefined, boolean | undefined>,
  'size' | 'renderInput'
> & {
  size?: TSelectSize
  className?: string
  renderInput?: (value: AutocompleteRenderInputParams) => React.ReactNode
}

export const AutocompleteSelect = ({
  size = 34,
  className,
  inputValue,
  renderInput,
  options,
  ...props
}: TAutocompleteProps) => (
  <MuiAutocomplete
    className={classNames('praxie-autocomplete-select', `size-${size}`, className)}
    renderInput={renderInput || (params => <TextField {...params} size={size} />)}
    options={options}
    componentsProps={{ popper: { className: 'praxie-autocomplete-select-menu' } }}
    disableClearable
    {...props}
  />
)
