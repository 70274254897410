import { type ReactNode, useMemo, useState } from 'react'
import classNames from 'classnames'
import type { SelectChangeEvent } from '@mui/material/Select'
import EditIcon from 'assets/images/icons/ic_dataedit.svg?react'
import { memoize } from 'helpers/common/memoize'
import { EditDictionaryModal } from 'features/dictionaries/dropdownContentSetup/editDictionary/editDictionaryModal'
import { CreateDictionaryModal } from 'features/dictionaries/dropdownContentSetup/createDictionary/createDictionaryModal'
import RoundIconButton from 'components/buttons/RoundIconButton'
import { Tooltip } from 'components/common/tooltip/tooltip'
import CircleSpinner from 'components/spinners/CircleSpinner'
import { SearchableSelect } from 'components/common/select/searchableSelect/searchableSelect'
import { MenuItem } from 'components/common/menu/menu'
import TextButton from 'components/buttons/TextButton'
import { useDropdownContentSetupStore } from '../dropdownContentSetup.store'
import './dropdown-list-selector.scss'

type TProps = {
  tenantId: string
  isDisabled: boolean
}

export const DropdownListSelector = ({ tenantId, isDisabled }: TProps) => {
  const [search, setSearch] = useState('')

  const dictionaries = useDropdownContentSetupStore(state => state.dictionaries)
  const areDictionariesLoading = useDropdownContentSetupStore(state => state.areDictionariesLoading)
  const selectedDictionaryId = useDropdownContentSetupStore(state => state.selectedDictionaryId)
  const setIsDictionaryEditModalOpen = useDropdownContentSetupStore(
    state => state.setIsDictionaryEditModalOpen
  )
  const errors = useDropdownContentSetupStore(state => state.errors)

  const dictionaryOptions = useMemo(() => {
    return dictionaries.reduce((result, dictionary) => {
      if (!dictionary.label.toLowerCase().includes(search.toLowerCase())) return result

      result.push(
        <MenuItem key={dictionary.value} value={dictionary.value}>
          {dictionary.label}
          <Tooltip title="Edit dictionary" wrapperClassName="edit" shouldWrapChildrenWithDiv>
            {/* @ts-ignore */}
            <RoundIconButton size="small" onClick={() => setIsDictionaryEditModalOpen(true)}>
              <EditIcon />
            </RoundIconButton>
          </Tooltip>
        </MenuItem>
      )

      return result
    }, [] as Array<ReactNode>)
  }, [search, dictionaries, setIsDictionaryEditModalOpen])

  const getSelectedOptionLabel = useMemo(
    () =>
      memoize((value: string) => {
        if (!value) {
          return <span className="select-placeholder">Select list to link</span>
        }

        const dictionary = dictionaries.find(_dictionary => _dictionary.value === value)

        return dictionary?.label
      }),
    [dictionaries]
  )

  const handleListChange = (event: SelectChangeEvent<unknown>) => {
    const selectedSource = event.target.value as string

    useDropdownContentSetupStore.setState({
      selectedDictionaryId: selectedSource,
      errors: { dictionary: '', isValid: true }
    })
  }

  return (
    <div className={classNames('dropdown-list-selector', { disabled: isDisabled })}>
      <SearchableSelect
        className="list-select"
        value={selectedDictionaryId}
        search={search}
        size={40}
        error={!!errors?.dictionary}
        helperText={errors?.dictionary}
        label="Select list for dropdown options"
        MenuProps={{ className: 'dropdown-list-selector-menu' }}
        renderValue={getSelectedOptionLabel}
        onChange={handleListChange}
        onSearchChange={setSearch}
        displayEmpty
      >
        {areDictionariesLoading ? (
          <CircleSpinner className="dictionaries-loader" />
        ) : (
          dictionaryOptions
        )}
        {!areDictionariesLoading && !dictionaryOptions.length && (
          <span className="dictionaries-empty"> No Lists exist in this team </span>
        )}
      </SearchableSelect>
      <div className="dictionary-actions">
        <div className="dictionary-action">
          <CreateDictionaryModal tenantId={tenantId} />
          <TextButton
            onClick={() =>
              useDropdownContentSetupStore.setState({ isDictionaryCreateModalOpen: true })
            }
          >
            CREATE NEW LIST
          </TextButton>
        </div>
        {!!selectedDictionaryId && (
          <div className="dictionary-action">
            <EditDictionaryModal tenantId={tenantId} />
            <TextButton onClick={() => setIsDictionaryEditModalOpen(true)}>
              EDIT THIS LIST
            </TextButton>
            <span> (changes list everywhere used)</span>
          </div>
        )}
      </div>
    </div>
  )
}
