import { makeObjectFromArray } from 'helpers/common/commonHelpers'

const objectExtractor = (response, path) =>
  path.split('.').reduce((parent, child) => {
    let result = null
    if (parent && parent[child]) {
      result = parent[child]
    }
    return result
  }, response)

export const extractCardDetail = response =>
  objectExtractor(response, 'data.organization.tenant.board.card')

export const extractCardLogs = response =>
  objectExtractor(response, 'data.organization.tenant.board.card.logs')

export const extractCardsList = response =>
  objectExtractor(response, 'data.organization.tenant.board.cards')

export const extractColumn = response =>
  objectExtractor(response, 'data.organization.tenant.board.column')

export const extractWidgetsMap = response => {
  const { __typename, ...board } = objectExtractor(response, 'data.organization.tenant.board') || {}

  const cards = Object.values(board)
  return makeObjectFromArray(cards, card => card?.uuid, 'widgets')
}
