import { type KeyboardEvent, type ReactNode, type SyntheticEvent } from 'react'
import MUIDialog, { type DialogProps } from '@mui/material/Dialog'
import MUIDialogActions, { type DialogActionsProps } from '@mui/material/DialogActions'
import MUIDialogContent, { type DialogContentProps } from '@mui/material/DialogContent'
import MUIDialogTitle, { type DialogTitleProps } from '@mui/material/DialogTitle'
import classNames from 'classnames'
import CloseIcon from 'assets/images/icons/cross.svg?react'
import RoundIconButton from 'components/buttons/RoundIconButton'
import './dialog.scss'

type TDefaultDialogHeaderProps = {
  title?: string
  onClose?: (event: SyntheticEvent) => void
}

const DefaultDialogHeader = ({ title, onClose }: TDefaultDialogHeaderProps) => (
  <DialogTitle className="default-header">
    <span className="title">{title}</span>
    {onClose && (
      // @ts-expect-error
      <RoundIconButton className="close" onClick={onClose}>
        <CloseIcon className="close-icon" title="Close" />
      </RoundIconButton>
    )}
  </DialogTitle>
)

type TDialogProps = DialogProps & {
  type?: 'default' | 'barebone'
  variant?: 'filled'
  title?: ReactNode
  disableBackdropClickClose?: boolean
  onClose: (event: SyntheticEvent) => void
  onSubmit?: () => void
}

export const Dialog = ({
  className,
  type = 'barebone',
  variant,
  title,
  disableBackdropClickClose,
  onClose,
  onSubmit,
  ...props
}: TDialogProps) => {
  const handleClose = (e: SyntheticEvent, reason: 'backdropClick' | 'escapeKeyDown') => {
    // This is the only way to prevent closing dialog after backdrop click, since the onBackdropClick API is deprecated.
    // https://mui.com/material-ui/api/dialog/#dialog-prop-onBackdropClick
    if (disableBackdropClickClose && reason === 'backdropClick') return

    onClose(e)
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (onSubmit && e.key === 'Enter') {
      e.preventDefault()
      onSubmit()
    }
  }

  return (
    <MUIDialog
      className={classNames('praxie-dialog', className, variant)}
      onClose={handleClose}
      onKeyDown={handleKeyDown}
      {...props}
    >
      {type === 'default' && <DefaultDialogHeader title={title} onClose={onClose} />}
      {props.children}
    </MUIDialog>
  )
}

export const DialogTitle = ({ className, ...props }: DialogTitleProps) => (
  <MUIDialogTitle className={classNames('praxie-dialog-title', className)} {...props} />
)

export const DialogContent = ({ className, ...props }: DialogContentProps) => (
  <MUIDialogContent className={classNames('praxie-dialog-content', className)} {...props} />
)

export const DialogActions = ({ className, ...props }: DialogActionsProps) => (
  <MUIDialogActions className={classNames('praxie-dialog-actions', className)} {...props} />
)
