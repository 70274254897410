import gql from 'graphql-tag'
import { UserFragment } from 'graphql/fragments/workspaceMembersFragments'
import WidgetFragment from '../fragments/widgetFragment'

export const teamMembersQuery = () => gql`
  query getTeamMembers($organizationId: ID!, $tenantId: ID!, $isVerified: Boolean) {
    organization(id: $organizationId) {
      tenant(id: $tenantId) {
        users(isVerified: $isVerified) {
          username
        }
      }
    }
  }
`

export const teamsMembersQuery = () =>
  gql`
    query getTeamsMembers($organizationId: ID!, $tenantIds: [ID!]) {
      organization(id: $organizationId) {
        tenants(ids: $tenantIds) {
          id
          users {
             ...${UserFragment.name}
            isVerified
            organizationRole
          }
        }
      }
    }
    ${UserFragment.fragment}
  `

export const widgetsFromTenantQuery = () => gql`
  query widgetsFromTenant(
    $organizationId: ID!
    $tenantId: ID!
    $boardIds: [ID!]
    $cardIds: [ID!]
    $widgetIds: [ID!]
  ) {
    organization(id: $organizationId) {
      tenant(id: $tenantId) {
        boards(ids: $boardIds) {
          cards(uuids: $cardIds) {
            name
            widgets(uuids: $widgetIds) {
              ...${WidgetFragment.name}
            }
          }
        }
      }
    }
  }
  ${WidgetFragment.fragment}
`

export const widgetsFromAllTenantsQuery = () => gql`
  query widgetsFromAllTenants(
    $organizationId: ID!
    $tenantIds: [ID!]
    $boardIds: [ID!]
    $cardIds: [ID!]
    $widgetIds: [ID!]
  ) {
    organization(id: $organizationId) {
      tenants(ids: $tenantIds) {
        name
        id
        boards(ids: $boardIds) {
          id
          name
          cards(uuids: $cardIds) {
            name
            uuid
            widgets(uuids: $widgetIds) {
              id
              cardUuid
              widgetTitle
            }
          }
        }
      }
    }
  }
`
