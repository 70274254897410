import PropTypes from 'prop-types'
import TextButton from 'components/buttons/TextButton'
import { SearchInput } from 'components/common/textField/searchInput/searchInput'
import DotsSpinner from 'components/spinners/DotsSpinner'
import messages from 'constants/messages'
import UsersList from './UsersList'
import 'scss/usersList/users-selector.scss'

const UsersSelector = ({
  isFetching,
  canSelectUser,
  canUnselectUser,
  selectedUsersTitle,
  restUsersTitle,
  search,
  restUsers,
  selectedUsers,
  selectUser,
  unselectAll,
  unselectUser,
  changeSearch,
  clearSearch,
  isRemoveAllDisabled
}) => {
  const showRestUsers = !!restUsers.length && canSelectUser

  const isNoResults = canSelectUser
    ? !selectedUsers.length && !restUsers.length
    : !selectedUsers.length
  return (
    <div className="users-selector">
      <header className="users-selector-header">
        <SearchInput
          value={search}
          size={28}
          placeholder={messages.FIND_USER}
          clearSearch={clearSearch}
          onChange={changeSearch}
        />
      </header>
      <div className="users-groups-wrapper">
        {!isNoResults && !!selectedUsers.length && (
          <div className="users-groups-title">
            {selectedUsersTitle}
            {!search && canUnselectUser && !isRemoveAllDisabled && (
              <TextButton size="small" onClick={unselectAll}>
                {messages.REMOVE_ALL}
              </TextButton>
            )}
          </div>
        )}
        <UsersList
          users={selectedUsers}
          disabled={!canUnselectUser}
          isSelectionSupported={canUnselectUser}
          isChecked
          onUserClick={unselectUser}
        />
        {!isNoResults && showRestUsers && (
          <div className="users-groups-title">{restUsersTitle}</div>
        )}
        {isFetching && <DotsSpinner customClass="users-list-spinner" size="sm" color="dark" />}
        {!isFetching && showRestUsers && (
          <UsersList users={restUsers} disabled={!canSelectUser} onUserClick={selectUser} />
        )}
        {!isFetching && isNoResults && (
          <div className="users-list-empty-state">{messages.NO_RESULTS}</div>
        )}
      </div>
    </div>
  )
}

UsersSelector.propTypes = {
  isFetching: PropTypes.bool,
  canSelectUser: PropTypes.bool,
  canUnselectUser: PropTypes.bool,
  selectedUsersTitle: PropTypes.string,
  restUsersTitle: PropTypes.string,
  search: PropTypes.string,
  restUsers: PropTypes.arrayOf(PropTypes.shape({ userId: PropTypes.string })),
  selectedUsers: PropTypes.arrayOf(PropTypes.shape({ userId: PropTypes.string })),
  selectUser: PropTypes.func,
  unselectAll: PropTypes.func,
  unselectUser: PropTypes.func,
  changeSearch: PropTypes.func,
  clearSearch: PropTypes.func,
  isRemoveAllDisabled: PropTypes.bool
}

UsersSelector.defaultProps = {
  isFetching: false,
  canSelectUser: false,
  canUnselectUser: false,
  selectedUsersTitle: '',
  restUsersTitle: '',
  search: '',
  restUsers: [],
  selectedUsers: [],
  selectUser: null,
  unselectAll: null,
  unselectUser: null,
  changeSearch: null,
  clearSearch: null,
  isRemoveAllDisabled: false
}

export default UsersSelector
