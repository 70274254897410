import { VARIABLES_CONFIG } from 'constants/variables/variableConfiguration/constants'
import { makeObjectFromArray } from 'helpers/common/commonHelpers'
import { Attribute } from 'components/variables/variableConfiguration/Attribute'
import { Rule } from 'components/variables/variableConfiguration/Rule'
import 'scss/variables/variableConfiguration/rules.scss'

export const Rules = ({
  isSaving,
  tenantId,
  rules,
  attribute,
  variableObject,
  rulesError,
  onChangeRule,
  onChangeAttribute
}) => {
  const rulesMap = makeObjectFromArray(rules, rule => rule.object)

  return (
    <header className="variable-configuration-rules">
      {VARIABLES_CONFIG.objectFields[variableObject].map(object => (
        <Rule
          key={object}
          isSaving={isSaving}
          tenantId={tenantId}
          object={object}
          variableObject={variableObject}
          predicate={rulesMap[object].predicate}
          predicates={VARIABLES_CONFIG.rules[object].predicates}
          value={rulesMap[object].value}
          error={rulesError[object]}
          onChangeRule={onChangeRule}
        />
      ))}
      <Attribute
        isSaving={isSaving}
        attribute={attribute}
        variableObject={variableObject}
        onChangeAttribute={onChangeAttribute}
      />
    </header>
  )
}
