import { Fragment } from 'react'

import messages from 'constants/messages'
import {
  getCellPredicateText,
  getDatePredicateText,
  getObjectText,
  getRuleConditionText,
  getRulePretext
} from 'helpers/filters/conditionsPreviewHelpers'
import { FILTER_PREDICATE } from 'helpers/filters/conditionsHelpers'
import LogicalOperator from 'components/filters/conditionsModal/LogicalOperator'
import {
  getFormattedDatePredicateValue,
  getPredicateConfig
} from 'features/filter/filterRuleConditions/filterRuleConditions.helpers'
import { EFilterRuleConditionPredicate } from 'features/filter/filter.types'
import VariablePreview from './VariablePreview'

const getParams = rule => {
  const getCoordinate = value => {
    return value ?? ''
  }

  switch (rule.predicate) {
    case FILTER_PREDICATE.CELL_COORDINATES:
      return `[${getCoordinate(rule.x)}:${getCoordinate(rule.y)}]`
    case FILTER_PREDICATE.BY_COLUMN_NAME:
      return rule.columnName
    default:
      return ''
  }
}

// just to have an ability to insert plain space (don't use &nbsp; because it's non-breaking)
const space = ' '

const formatEntity = value => {
  if ('name' in value) {
    return value.name
  }
  return messages.DELETED.toUpperCase()
}

const formatDateValue = value => {
  const timestamp = parseInt(value)

  if (Number.isNaN(timestamp)) return ''

  return getFormattedDatePredicateValue(timestamp)
}

const ValuesPreview = ({ rule }) => {
  const { datePredicate, values, variables, entityIds } = rule

  const getFormatterValue = () => {
    const separator = ', '

    if (variables?.length) {
      return variables.map((variable, index) => (
        <Fragment key={variable.id}>
          <VariablePreview variable={variable} />
          {index < variables.length - 1 ? separator : null}
        </Fragment>
      ))
    }

    if (entityIds?.length) {
      return entityIds.map(formatEntity).join(separator)
    }

    if (datePredicate === EFilterRuleConditionPredicate.exactDate) {
      return values.map(formatDateValue).join(separator)
    }

    return values.join(separator)
  }

  return (
    <>
      {space}
      {getFormatterValue()}
    </>
  )
}

const ConditionContent = ({ rule, object, rulesConfig }) => {
  const conditionConfig = rulesConfig[rule.condition] || null

  const predicateConfig = conditionConfig
    ? getPredicateConfig(conditionConfig, rule.predicate)
    : null
  const cellPredicateConfig = predicateConfig
    ? getPredicateConfig(predicateConfig, rule.cellPredicate)
    : null
  const datePredicateConfig = cellPredicateConfig
    ? getPredicateConfig(cellPredicateConfig, rule.datePredicate)
    : null

  return (
    <p className="preview-rule-inner">
      <span className="query-object-description">
        {getObjectText(object)}
        {space}
      </span>
      <span className="condition-description">
        {getRulePretext(rule, object)}
        {space}
      </span>
      <span className="rule-condition">{getRuleConditionText(rule, rulesConfig)}</span>
      {predicateConfig?.params && (
        <span className="rule-params">
          {space}
          {getParams(rule)}
        </span>
      )}
      {cellPredicateConfig && (
        <span className="rule-condition">
          {space}
          {getCellPredicateText(rule, cellPredicateConfig)}
        </span>
      )}
      {datePredicateConfig && (
        <span className="rule-condition">
          {space}
          {getDatePredicateText(rule, datePredicateConfig)}
        </span>
      )}
      {(!!rule.values?.length || !!rule.variables?.length || !!rule.entityIds?.length) && (
        <span className="rule-value">
          <ValuesPreview rule={rule} />
        </span>
      )}
    </p>
  )
}

const FilterCondition = ({ isLast, rule, object, operator, onClick, rulesConfig }) => {
  return (
    <div className="preview-rule-wrapper">
      <div className="preview-rule" id={`rule-preview-uuid=${rule.uuid}`} onClick={onClick || null}>
        <ConditionContent rule={rule} object={object} rulesConfig={rulesConfig} />
      </div>
      {!isLast && <LogicalOperator operator={operator} />}
    </div>
  )
}

export default FilterCondition
