import React from 'react'
import PropTypes from 'prop-types'
import { FILTERS_OBJECTS } from 'constants/filters/filtersConstants'
import { mapFiltersFromFlowToState } from 'helpers/filters/filterMappers'
import LogicalOperator from 'components/filters/conditionsModal/LogicalOperator'
import FilterCondition from './FilterCondition'
import 'scss/filters/filterPreview/filter-rules-preview.scss'

const FilterRulesPreview = props => {
  const { filters, rulesConfig, object } = props

  const { rules, rulesOperator } = mapFiltersFromFlowToState(filters)

  return (
    <span className="filter-rules-preview">
      {rules.map((rule, filterIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <span key={filterIndex} className="filter-rules-preview-inner">
          [&nbsp;
          <span className="rules-preview-wrapper">
            {rule.conditions.map((condition, index) => (
              <FilterCondition
                key={condition.uuid}
                isLast={index === rule.conditions.length - 1}
                object={object}
                rule={condition}
                operator={rule.conditionsOperator}
                rulesConfig={rulesConfig}
              />
            ))}
          </span>
          &nbsp;]
          {filterIndex !== rules.length - 1 && <LogicalOperator operator={rulesOperator} />}
        </span>
      ))}
    </span>
  )
}

FilterRulesPreview.propTypes = {
  filters: PropTypes.shape({
    rules: PropTypes.array.isRequired,
    rulesOperator: PropTypes.string.isRequired
  }),
  rulesConfig: PropTypes.object,
  object: PropTypes.oneOf([
    FILTERS_OBJECTS.WIDGET,
    FILTERS_OBJECTS.CARD,
    FILTERS_OBJECTS.DATA_RECORD
  ]).isRequired
}

FilterRulesPreview.defaultProps = {
  filters: { rules: [], rulesOperator: '' },
  rulesConfig: {}
}

export default FilterRulesPreview
