import React, { Component } from 'react'
import classNames from 'classnames'
import { Tooltip } from 'components/common/tooltip/tooltip'
import 'scss/common/style-tool.scss'

const EditTooltip = React.forwardRef((props, ref) => (
  <div
    className={classNames('style-tool__tooltip', {
      'not-editable': !props.isEdit
    })}
  >
    <input
      ref={ref}
      defaultValue={props.value}
      type="text"
      disabled={!props.isEdit}
      placeholder={props.placeholder}
      onKeyPress={props.onKeyPress}
      className="widget-input forcing-focus"
      onBlur={props.onBlur}
    />
    {props.isEdit ? (
      <span className="save-action" onMouseDown={props.onSave} />
    ) : (
      <i className="fa fa-edit" onMouseDown={props.onEditToggle} />
    )}
  </div>
))

export default class StyleTool extends Component {
  static getDerivedStateFromProps(props, state) {
    let newState = null
    if (state.isEdit === !props.value) {
      newState = {
        isEdit: !props.value
      }
    }

    return newState
  }

  hintInputRef = React.createRef()

  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      isEdit: !props.value
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.isOpen && this.state.isOpen && this.state.isEdit) {
      if (this.getInputRef()) {
        this.getInputRef().focus()
      }
    }
  }

  onBlur() {
    if (this.state.isOpen) {
      this.toggle()
    }
  }

  onEditToggle() {
    const { isEdit } = this.state
    this.setState({ isEdit: !isEdit })

    if (!isEdit) {
      setTimeout(() => {
        if (this.getInputRef()) {
          this.getInputRef().select()
        }
      }, 1)
    }
  }

  onSave() {
    const { onChange, process, onSelect } = this.props
    const hintInputRef = this.getInputRef()
    const value =
      process && hintInputRef && hintInputRef.value
        ? process(hintInputRef.value)
        : hintInputRef.value
    if (this.state.isOpen) {
      this.toggle()
    }
    onChange(value)
    // could be passed from ActionButtonBlockTool
    if (onSelect) {
      onSelect()
    }
  }

  onKeyPress(event) {
    if (event.key === 'Enter') {
      this.onSave()
    }
  }

  onWrapperBlur() {
    if (this.state.isOpen && !this.state.isEdit) {
      this.toggle()
    }
  }

  getInputRef = () => this.hintInputRef.current

  toggle() {
    const { isOpen } = this.state
    const newState = { isOpen: !isOpen }
    if (isOpen) {
      newState.isEdit = !this.props.value
    }
    this.setState(newState)
  }

  render() {
    const { isOpen } = this.state
    const {
      value,
      root,
      className,
      placeholder,
      tooltipText,
      activeTooltipText,
      flipRight,
      hidden,
      disabled
    } = this.props

    const tooltipValue = value && activeTooltipText ? activeTooltipText : tooltipText

    return (
      <div
        tabIndex="0"
        onBlur={this.onWrapperBlur.bind(this)}
        className={classNames('action-btn style-tool', {
          [`${className}-tool`]: true,
          'active-state': isOpen,
          'flip-right': flipRight,
          hidden,
          disabled
        })}
      >
        <Tooltip
          id={`${root}-tooltip`}
          placement={isOpen ? 'top' : 'bottom'}
          title={isOpen ? '' : tooltipValue}
        >
          <i
            className={classNames('icon', {
              active: value,
              [`icon-${className}`]: true
            })}
            onBlur={this.onBlur.bind(this)}
            onClick={this.toggle.bind(this)}
          />
        </Tooltip>
        {isOpen && (
          <EditTooltip
            ref={this.hintInputRef}
            value={value}
            placeholder={placeholder}
            isEdit={this.state.isEdit}
            onEditToggle={this.onEditToggle.bind(this)}
            onKeyPress={this.onKeyPress.bind(this)}
            onBlur={this.onBlur.bind(this)}
            onSave={this.onSave.bind(this)}
          />
        )}
      </div>
    )
  }
}
