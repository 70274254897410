export const teamsMembersExtractor = ({ data }) =>
  data.organization.tenants.map(tenant => {
    return {
      id: tenant.id,
      users: tenant.users.filter(user => user.isVerified)
    }
  })

export const extractWidgetsFromAllTenants = response => {
  let widgetsInfoList = []

  const getWidgetsFromTenants = arr => {
    arr.forEach(obj => {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty('widgets')) {
        const mappedWidgets = obj.widgets.map(widget => ({
          ...widget,
          cardName: obj.name
        }))
        widgetsInfoList = widgetsInfoList.concat(mappedWidgets)
      } else if (Object.values(obj).length) {
        getWidgetsFromTenants(obj?.boards || obj?.cards)
      }
    })
  }

  getWidgetsFromTenants(response)

  return widgetsInfoList
}

export const extractWidgetsFromTeam = response => {
  const widgetsInfoList = []

  const getWidgetsFromTeam = arr => {
    arr.forEach(obj => {
      if (obj.widgets) {
        obj.widgets.forEach(_widget => {
          widgetsInfoList.push({ cardName: obj.name, ..._widget })
        })
      } else if (Object.values(obj).length) {
        getWidgetsFromTeam(obj?.cards)
      }
    })
  }

  getWidgetsFromTeam(response)

  return widgetsInfoList
}
