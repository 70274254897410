import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { Tooltip } from 'components/common/tooltip/tooltip'
import { MenuTab } from './menuTab/menuTab'
import 'scss/common/tabs.scss'

const TabItem = ({ index, tab, onClick }) => {
  const handleClick = () => {
    if (tab.disabled || !onClick) return

    onClick(index)
  }

  const className = classNames('tab', {
    active: tab.active,
    disabled: tab.disabled,
    hidden: tab.hidden,
    [tab.className]: tab.className
  })

  if (!tab.link) {
    if (tab.menuItems) {
      return !tab.hidden && <MenuTab {...tab} />
    }
    return (
      <a className={className} onClick={handleClick}>
        {tab.text}
      </a>
    )
  }

  return (
    <Link to={tab.link} className={className} onClick={handleClick}>
      {tab.text}
    </Link>
  )
}

export default class Tabs extends Component {
  componentDidMount() {
    this.setStylesForActiveTab()
  }

  componentDidUpdate() {
    this.setStylesForActiveTab()
  }

  setStylesForActiveTab() {
    const activeTab = this.node.getElementsByClassName('active')[0]
    if (activeTab) {
      const underlineStyles = this.tabsUnderline.style
      underlineStyles.width = `${activeTab.offsetWidth}px`
      underlineStyles.left = `${activeTab.offsetLeft}px`
    }
  }

  render() {
    const { tabs, appearance, className, onTabClick } = this.props

    return (
      <nav ref={node => (this.node = node)} className={classNames('tabs', className, appearance)}>
        {tabs.map((tab, index) => (
          <Tooltip
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            id={`${tab.text} tab tooltip`}
            placement="bottom"
            title={tab.tooltip || ''}
            shouldWrapChildrenWithDiv
          >
            <TabItem index={index} tab={tab} onClick={onTabClick} />
          </Tooltip>
        ))}
        <hr
          ref={tabsUnderline => (this.tabsUnderline = tabsUnderline)}
          className="tabs-underline"
        />
      </nav>
    )
  }
}

Tabs.propTypes = {
  appearance: PropTypes.oneOf(['legacy', 'modern']),
  className: PropTypes.string
}

Tabs.defaultProps = {
  appearance: 'legacy',
  className: ''
}
