import {
  FILTER_CONDITION,
  FILTER_PREDICATE,
  RULE_VALUE_TYPE
} from 'helpers/filters/conditionsHelpers'

const filtersMapper = ({ filters = { rules: [] }, conditionMapper }) => ({
  ...filters,
  rules: filters.rules
    ? filters.rules.map(rule => ({
        ...rule,
        conditions: rule.conditions.map(condition => conditionMapper(condition))
      }))
    : []
})

const conditionMapper = (sign = '+', condition) => {
  const _condition = structuredClone(condition)

  // Temporary: Convert plain text team values to entityIds for team IS conditions
  // TODO: Remove after BE implements search by team feature
  if (
    condition.condition === FILTER_CONDITION.TEAM &&
    condition.predicate === FILTER_PREDICATE.IS
  ) {
    if (condition.valueType === RULE_VALUE_TYPE.VALUE) {
      Object.assign(_condition, {
        valueType: RULE_VALUE_TYPE.ENTITY_IDS,
        entityIds: condition.values.map(tenantId => ({ tenantId })),
        values: []
      })
    } else {
      Object.assign(_condition, {
        valueType: RULE_VALUE_TYPE.VALUE,
        values: condition.entityIds.map(({ tenantId }) => tenantId),
        entityIds: []
      })
    }
  }
  if ('x' in condition && 'y' in condition) {
    Object.assign(_condition, {
      x: sign === '+' ? condition.x + 1 : condition.x - 1,
      y: sign === '+' ? condition.y + 1 : condition.y - 1
    })
  }

  return _condition
}

export const mapFiltersFromStateToFlow = filters =>
  filtersMapper({
    filters,
    conditionMapper: conditionMapper.bind(this, '-')
  })

export const mapFiltersFromFlowToState = filters =>
  filtersMapper({
    filters,
    conditionMapper: conditionMapper.bind(this, '+')
  })
