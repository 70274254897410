import { ENTITIES } from '../../constants/schemaConstants'
import { OBJECT_TYPES } from '../../../constants/workflows'
import { formatId } from '../../common/commonHelpers'

export const getWidgetPayload = ({ id }) => {
  const entity = ENTITIES[OBJECT_TYPES.WIDGET]
  const alias = `${entity}${formatId(id)}`
  return `${alias}: ${entity}(uuid: "${id}"){id,cardUuid,widgetUuid:id,widgetTitle,widgetClassName`
}

export const getWidgetResponse = ({ card, widgetUuid }) => {
  const alias = `${ENTITIES[OBJECT_TYPES.WIDGET]}${formatId(widgetUuid)}`
  return card[alias] || null
}
