import { getNumberedArray } from 'helpers/common/commonHelpers'
import { isOutputStopped } from './outputsStoppersHelpers'

export const generateBlockInputs = ({ prefix, value, inputCount }) =>
  getNumberedArray(inputCount).reduce((obj, key) => {
    obj[`${prefix}${key}`] = value
    return obj
  }, {})

export const getFieldNamesItems = (fieldName, inputDefinition) => {
  const fnInputDefinition = Object.entries(inputDefinition).filter(
    inputEntry => inputEntry[1].inputName === fieldName
  )

  return fnInputDefinition.map(input => input[0])
}

export const getFieldNameIndex = (field, fieldName) =>
  parseInt(field.substring(fieldName.length), 10)

export const updateBlockInput = ({ workflowBlocksMap, blockId, input, error }) => {
  const blocksMap = { ...workflowBlocksMap }
  let blockError

  if (error) {
    const fieldName = Object.keys(input)[0]
    blockError = { ...error, [fieldName]: null }
  } else {
    blockError = {}
  }

  blocksMap[blockId] = {
    ...workflowBlocksMap[blockId],
    input: {
      ...workflowBlocksMap[blockId].input,
      ...input
    },
    error: blockError,
    warn: {}
  }

  return blocksMap
}

export const getFilledInputs = ({ block }) => Object.values(block.input).filter(input => !!input)

export const getDefaultBlockInput = args => {
  const { workflowBlocksMap, blockId, workflowBlocks, outputsHistory, outputsStoppers } = args

  const input = {}
  const block = workflowBlocksMap[blockId]
  Object.keys(block.inputDefinition).forEach(key => {
    const { type, presetValue = false } = block.inputDefinition[key]
    // fill specific field types
    if (!presetValue) {
      return
    }

    const prevBlockId = workflowBlocks[block.index - 1]
    let prevOutputId = ''
    for (let i = block.index - 1; i >= 0; i -= 1) {
      const id = workflowBlocks[i]
      const prevBlock = workflowBlocksMap[id]

      if (!prevBlock.isCreateOutput) {
        break
      }

      const prevOutput = prevBlock.output.find(output => output.type === type)
      if (!prevOutput) {
        break
      }

      // don't preset output which was stopped by block above
      if (
        isOutputStopped({
          outputId: prevOutput.id,
          blockIndex: block.index,
          workflowBlocksMap,
          outputsStoppers
        })
      ) {
        break
      }

      // if previous block creates appropriate output, save that
      if (i === block.index - 1) {
        prevOutputId = prevOutput.id
        break
      }
      // if previous block uses appropriate output, save it too
      if (outputsHistory[prevOutput.id].indexOf(prevBlockId) !== -1) {
        prevOutputId = prevOutput.id
        break
      }
      // if more than one appropriate outputs where found, clear that
      if (prevOutputId) {
        prevOutputId = ''
        break
      }
      prevOutputId = prevOutput.id
    }

    if (prevOutputId) {
      input[key] = prevOutputId
    }
  })

  return input
}
