import { CountRequest, GetRequest } from '_proto/comments/v1/comments.pb'
import { getActiveOrganization } from 'selectors/profile.selectors'
import { setCardsCommentsCount } from 'actions/boardActions'
import { CardCommentsGrpcService } from 'features/cards/comments/cardComments.grpc.service'

export const getCardComment = async key => {
  const request = GetRequest.create({ keys: [key] })

  const res = await CardCommentsGrpcService.fetchComment({ request })

  const comment = res.results[0]?.comment
  if (comment) return CardCommentsGrpcService.parseComment(comment)
  return null
}

export const fetchCardsCommentsCount =
  (cards, { forceCommentsCountUpdate } = {}) =>
  async (dispatch, getState) => {
    const { organizationId } = getActiveOrganization(getState())

    const keys = cards.reduce((result, card) => {
      if (card.isCol) return result

      result.push({
        scope: {
          organizationId,
          tenantId: card.tenantId,
          boardId: card.boardId,
          cardId: card.uuid
        }
      })

      return result
    }, [])

    if (!keys.length) return

    const request = CountRequest.create({ cardKeys: keys })
    const res = await CardCommentsGrpcService.fetchCommentCount({ request })

    const cardsCommentsCount = keys.reduce((result, key, i) => {
      const cardCommentsCount = res.counts[i]

      if (cardCommentsCount || forceCommentsCountUpdate) {
        result[key.scope.cardId] = cardCommentsCount
      }

      return result
    }, {})

    const isAtLeastOneCommentExist = !!Object.keys(cardsCommentsCount).length

    if (isAtLeastOneCommentExist) {
      dispatch(setCardsCommentsCount(cardsCommentsCount))
    }
  }
