export const getDetailed = state => state.detailed

export const cardSectionsLoadingSelector = cardUuid => state =>
  state.detailed.sectionsLoading[cardUuid]

export const getIsDetailedViewModalReady = state => state.detailed.isDetailedViewModalReady

export const renderedCardSectionsSelector = state => getDetailed(state).renderedSections

export const getCopyCardSnapshotInProgress = state => getDetailed(state).copyCardSnapshotInProgress

export const getIsPDFGeneration = state => getDetailed(state).isPDFGeneration

export const getSavingInProcess = state => getDetailed(state).isSavingInProgress
