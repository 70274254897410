import { validateForNotEmpty } from '../validationHelpers'
import messages from '../../constants/messages'

export const getNameError = name => validateForNotEmpty(name)

export const getObjectError = (object, defaultObject) => {
  if (defaultObject && object !== defaultObject) {
    return messages.FILTER_OBJECT_ERROR
  }
  return ''
}

export const getRulesError = filters => {
  if (!filters.rules || !filters.rules.length) {
    return messages.RULES_ERROR
  }
  return ''
}
