import messages from 'constants/messages'

const APP_NAME_MIN_LENGTH = 3

export const validateAppName = value => {
  let valueError = ''

  const trimmedValue = value?.trim()

  if (!trimmedValue) {
    valueError = messages.emptyField
  } else if (trimmedValue.length < APP_NAME_MIN_LENGTH) {
    valueError = `App names require a minimum of ${APP_NAME_MIN_LENGTH} characters`
  }

  return valueError
}

export const validateApp = app => {
  const nameValidationError = validateAppName(app.name)

  if (nameValidationError) {
    return { name: nameValidationError }
  }

  return null
}
