import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import {
  deriveMemoizedActiveTeamMemberList,
  deriveMemoizedActiveTeamMemberMap
} from 'features/team/team.helpers'
import { TeamService } from 'features/team/team.service'
import { type ITeamMember } from './team.types'

type TState = {
  id: string
  members: ITeamMember[]
}

type TActions = {
  fetchMembers: () => Promise<void>
  getActiveMembers: () => ITeamMember[]
  getActiveMembersMap: () => Record<ITeamMember['userId'], ITeamMember>
  setMembers: (members: ITeamMember[]) => void
  reset: () => void
}

const INITIAL_STATE = {
  id: '',
  members: []
}

// Replaces `teamMembers` and will hold `teamSettings` in the future.
export const useTeamStore = create(
  devtools(
    immer<TState & TActions>((set, get) => ({
      ...INITIAL_STATE,
      fetchMembers: async () => {
        const id = get().id

        if (!id) {
          throw new Error('Team ID is required to fetch team members.')
        }

        try {
          const { data } = await TeamService.getTeamMemberList(id)

          set(state => {
            state.members = data
          })
        } catch (error) {
          console.error(error)
        }
      },
      getActiveMembers: () => {
        const members = get().members

        return deriveMemoizedActiveTeamMemberList(members)
      },
      getActiveMembersMap: () => {
        const members = get().members

        return deriveMemoizedActiveTeamMemberMap(members)
      },
      setMembers: (members: ITeamMember[]) => {
        set(state => {
          state.members = members
        })
      },
      reset: () => set(INITIAL_STATE)
    }))
  )
)
