import gql from 'graphql-tag'

export const getOrganizationPublishedAppsQuery = () => gql`
  query getOrganizationPublishedApps($organizationId: ID!, $applicationIds: [ID!]) {
    organization(id: $organizationId) {
      libraryApps(ids: $applicationIds) {
        appStoreId
        appLogo
        purchaseInfo {
          purchaseId
          price
        }
        name
      }
    }
  }
`

export const organizationNamesQuery = () => gql`
  query getOrganizationNames($organizationIds: [ID!], $tenantId: ID!) {
    organizations(ids: $organizationIds, tenantId: $tenantId) {
      id
      presentationName
    }
  }
`

export const getOrganizationCollectionsQuery = () => gql`
  query getOrganizationCollections($organizationId: ID!) {
    organization(id: $organizationId) {
      appStoreCollections {
        id
        name
        description
        secondaryDescription
        logo
        images {
          size
          url
          color
        }
        attachedFileOnly
        parentCollectionId
        trialPeriod
        subscriptionOptions {
          price
          purchaseId
          description
        }
        status
      }
    }
  }
`
