import { useEffect, useRef } from 'react'
import { autoScrollForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element'
import { type Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge'
import { reorderWithEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/reorder-with-edge'
import messages from 'constants/messages'
import IconButton from 'components/buttons/IconButton'
import { Switcher } from 'components/common/switcher/switcher'
import { Tooltip } from 'components/common/tooltip/tooltip'
import CircleSpinner from 'components/spinners/CircleSpinner'
import {
  MAX_DICTIONARY_MANUAL_OPTIONS,
  PLAIN_LIST_OPTIONS_LIMIT
} from 'features/dictionaries/dictionariesSettings.constants'
import { type TOption } from '../dictionariesSettings.types'
import { OptionItem } from './optionItem/optionItem'
import './options-table.scss'

type TProps = {
  options: TOption[]
  isHintEnabled: boolean
  hintMessage: string
  hasDuplicates?: boolean
  onChangeHintEnabled?: () => void
  addNewOption?: () => void
  deleteOption?: (index: number) => void
  updateOptions?: (options: TOption[]) => void
  changeOptionValue?: (optionIndex: number, value: string) => void
  changeHintValue?: (value: string) => void
  getOptionErrorText?: (props: { index: number; value: string }) => string
  areOptionsFromPlainList?: boolean
  isQuerySelected?: boolean
  isLoading?: boolean
  hasOptionsSource?: boolean
  hintError?: boolean
  queryError?: boolean
  optionsError?: boolean[]
}

export const OptionsTable = ({
  isHintEnabled,
  hintMessage,
  onChangeHintEnabled,
  options,
  updateOptions,
  addNewOption,
  deleteOption,
  changeOptionValue,
  changeHintValue,
  optionsError,
  hintError,
  queryError,
  areOptionsFromPlainList,
  isQuerySelected,
  isLoading,
  hasOptionsSource,
  hasDuplicates,
  getOptionErrorText
}: TProps) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const element = ref.current

    if (!element) return

    return autoScrollForElements({ element })
  }, [])

  const onDrop = ({
    indexOfSource,
    indexOfTarget,
    closestEdgeOfTarget
  }: {
    indexOfSource: number
    indexOfTarget: number
    closestEdgeOfTarget: Edge | null
  }) => {
    if (!updateOptions) return

    const updatedOptions = reorderWithEdge({
      list: options,
      startIndex: indexOfSource,
      indexOfTarget,
      closestEdgeOfTarget,
      axis: 'vertical'
    })

    updateOptions(updatedOptions)
  }

  const isAddButtonDisabled = options.length >= MAX_DICTIONARY_MANUAL_OPTIONS

  const shouldShowPlainListLimitNotification =
    areOptionsFromPlainList && options.length >= PLAIN_LIST_OPTIONS_LIMIT

  return (
    <div ref={ref} className="dictionary-options-table">
      <div className="hint-container">
        <Switcher
          className="hint-switcher"
          checked={isHintEnabled}
          disabled={!onChangeHintEnabled || queryError}
          onChange={onChangeHintEnabled}
        />
        <span>{messages.DICTIONARY_HINT_ENABLED}</span>
      </div>
      <div className="options-list">
        <div className="options-list-header">
          <span className="title options-menu-title">{messages.DICTIONARY_MENU_OPTIONS}</span>
          {shouldShowPlainListLimitNotification && (
            <span className="plain-list-limit-notification">
              Only first 50 options are listed for preview
            </span>
          )}
        </div>
        {isHintEnabled && (
          <OptionItem
            option={{ value: hintMessage }}
            changeOptionValue={changeHintValue}
            hasError={!!hintError}
            deleteDisabled
            isHint
          />
        )}
        {isLoading ? (
          <CircleSpinner className="loader" />
        ) : (
          <>
            {!options.length && (
              <div className="empty-state">
                {queryError && 'Options cannot be retrieved'}
                {!queryError && hasOptionsSource
                  ? 'No options here'
                  : 'Options will be listed here'}
              </div>
            )}
            {options.map((option, index) => (
              <OptionItem
                key={`${String(index)}_${option.value || ''}`}
                index={index}
                option={option}
                changeOptionValue={
                  changeOptionValue ? (value: string) => changeOptionValue(index, value) : undefined
                }
                isQuerySelected={isQuerySelected}
                deleteDisabled={options.length === 1}
                hasError={!!optionsError?.[index]}
                deleteOption={deleteOption ? () => deleteOption(index) : undefined}
                getOptionErrorText={getOptionErrorText}
                onDrop={onDrop}
              />
            ))}
          </>
        )}
        {!isQuerySelected && (
          <div className="option-action-container">
            {hasDuplicates && <p className="duplicate-error">{messages.OPTIONS_DUPLICATES}</p>}
            {!!addNewOption && (
              <Tooltip
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                title={isAddButtonDisabled ? messages.ADD_OPTION_RESTRICTION_MESSAGE : ''}
                placement="top"
                shouldWrapChildrenWithDiv
              >
                <IconButton
                  appearance="secondary"
                  iconSeparated={false}
                  className="add-option"
                  icon={<i className="icon icon-round-plus-6c7e8a" />}
                  disabled={isAddButtonDisabled}
                  onClick={addNewOption}
                >
                  {messages.ADD_OPTION}
                </IconButton>
              </Tooltip>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
