import {
  type BulkDetailsRequest,
  type CopyCommandRequest,
  type CreateCommandRequest,
  type DeleteCommandRequest,
  type DetailsRequest,
  type DictionaryClient,
  type DictionaryDetails,
  type ListByTenantRequest,
  type Option,
  type UpdateCommandRequest,
  DictionaryDefinition
} from '_proto/dictionary/v1/dictionary.pb'
import { GrpcService } from 'services/grpc.service'

export class _DictionariesGrpcService {
  private _client: DictionaryClient = GrpcService.createClient(DictionaryDefinition)

  parseDictionaryOption = (option: Option) => ({ value: option.name })

  parseDictionary = (dictionary: DictionaryDetails) => {
    return {
      id: dictionary.id,
      tenantId: dictionary.tenantId,
      name: dictionary.name,
      createdBy: dictionary.createdBy,
      options: dictionary.options.map(this.parseDictionaryOption),
      createdAt: dictionary.creationDate,
      hint: dictionary.hint,
      queryWidget: dictionary.queryWidget
    }
  }

  fetchDictionaryList = async (config: { request: ListByTenantRequest }) => {
    return this._client.listByTenant(config.request)
  }

  fetchDictionaries = async (config: { request: BulkDetailsRequest }) => {
    return this._client.bulkDetails(config.request)
  }

  fetchDictionary = async (config: { request: DetailsRequest }) => {
    return this._client.details(config.request)
  }

  createDictionary = async (config: { request: CreateCommandRequest }) => {
    return this._client.create(config.request)
  }

  updateDictionary = async (config: { request: UpdateCommandRequest }) => {
    return this._client.update(config.request)
  }

  deleteDictionary = async (config: { request: DeleteCommandRequest }) => {
    return this._client.delete(config.request)
  }

  copyDictionary = async (config: { request: CopyCommandRequest }) => {
    return this._client.copy(config.request)
  }
}

export const DictionariesGrpcService = new _DictionariesGrpcService()
