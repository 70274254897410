import {
  type FileUploaderClient,
  CopyBoardFilesRequest,
  FileUploaderDefinition,
  GenerateSignedLinkRequest,
  UploadAvatarRequest,
  UploadBundleRequest,
  UploadDatasetRequest,
  UploadFileRequest,
  UploadFileResponse,
  UploadTemplateRequest
} from '_proto/uploader/v1/uploader.pb'
import { type CallOptions } from 'nice-grpc-common'
import { GrpcService } from 'services/grpc.service'

export enum EFileUploaderFileTypes {
  USER_AVATAR = 'user-avatar',
  ORG_AVATAR = 'org-avatar',
  APP_LOGO = 'app-logo',
  SNAPSHOT = 'snapshot',
  APPSTORE = 'appstore',
  WIDGET = 'widget',
  TEMPLATE = 'template'
}

export class _FileUploaderGrpcService {
  private _client: FileUploaderClient = GrpcService.createClient(FileUploaderDefinition)

  parseLink = (link?: string) => {
    return link?.split('/file/').pop()
  }

  getFileNames = ({
    imgSrc,
    thumbnailSrc,
    fileLink
  }: {
    imgSrc?: string
    thumbnailSrc?: string
    fileLink?: string
  }) => {
    return [imgSrc, thumbnailSrc, fileLink].reduce<string[]>((result, link) => {
      if (!link) return result

      const parsedLink = this.parseLink(link)
      if (parsedLink) result.push(parsedLink)

      return result
    }, [])
  }

  getFileLink = (type: EFileUploaderFileTypes, params: Record<string, string>) => {
    const queryParams = Object.entries(params)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    return `api/uploader/signed-file-from-storage/${type}?${queryParams}`
  }

  getDownloadFileLink = (
    type: EFileUploaderFileTypes,
    fileName: string,
    params: Record<string, string>
  ) => {
    const queryParams = Object.entries(params)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    const name = encodeURIComponent(fileName)
    return `${window.istioHost}api/uploader/download-storage-file/${type}/${name}?${queryParams}`
  }

  convertFileToUint8Array = async (file: Blob) => {
    const arrayBuffer = await file.arrayBuffer()
    return new Uint8Array(arrayBuffer)
  }

  parseFile = ({
    key,
    info,
    fileLink,
    thumbnailLink,
    height,
    width,
    csvRows,
    fillColor,
    strokeWidth,
    strokeColor
  }: UploadFileResponse) => {
    return {
      id: key?.id,
      name: info?.name,
      mimeType: info?.mimeType,
      fileLink,
      thumbnailLink,
      height,
      width,
      rows: csvRows,
      fillColor,
      strokeWidth,
      strokeColor
    }
  }

  uploadFile = async (config: { request: UploadFileRequest; options?: CallOptions }) => {
    return this._client.uploadFile(config.request, config.options)
  }

  uploadBundle = async (config: { request: UploadBundleRequest; options?: CallOptions }) => {
    return this._client.uploadFile(config.request, config.options)
  }

  uploadAvatar = async (config: { request: UploadAvatarRequest; options?: CallOptions }) => {
    return this._client.uploadAvatar(config.request, config.options)
  }

  uploadTemplate = async (config: { request: UploadTemplateRequest; options?: CallOptions }) => {
    return this._client.uploadTemplate(config.request, config.options)
  }

  uploadDataset = async (config: { request: UploadDatasetRequest; options?: CallOptions }) => {
    return this._client.uploadDataset(config.request, config.options)
  }

  copyBoardFiles = async (config: { request: CopyBoardFilesRequest; options?: CallOptions }) => {
    return this._client.copyBoardFiles(config.request, config.options)
  }

  generateSignedLink = async (config: {
    request: GenerateSignedLinkRequest
    options?: CallOptions
  }) => {
    return this._client.generateSignedLinkToUploadFile(config.request, config.options)
  }
}

export const FileUploaderGrpcService = new _FileUploaderGrpcService()
