type TQueueItem<T> = (payload?: T | undefined) => Promise<T>

export class AsyncQueueService<T = void> {
  private queue: Array<TQueueItem<T>> = []
  private isProcessing = false

  private processQueue = async (payload?: T): Promise<void> => {
    this.isProcessing = true
    let result: T | undefined

    const request = this.queue.shift()

    if (request && typeof request === 'function') {
      result = await request(payload)
    }

    if (this.queue.length) {
      void this.processQueue(result)
    } else {
      this.isProcessing = false
    }
  }

  public enqueue = (request: TQueueItem<T>): void => {
    this.queue.push(request)

    if (!this.isProcessing) {
      void this.processQueue()
    }
  }

  public clear = (): void => {
    this.queue = []
  }
}
