import messages from '../../constants/messages'
import { EFieldKeys } from '../../constants/workflowBuilder/blocksFieldsKeys'

export const CARD_OWNER_SET = 'addNewOwner'
export const CARD_OWNER_ADD = 'addToExistingOwners'
export const CARD_OWNER_UNSET = 'removeAllOwners'

export const SET_CARD_OWNER_OPTIONS = {
  [CARD_OWNER_SET]: { value: CARD_OWNER_SET, label: messages.CARD_OWNER_SET_OUTPUT },
  [CARD_OWNER_ADD]: { value: CARD_OWNER_ADD, label: messages.CARD_OWNER_ADD_OUTPUT },
  [CARD_OWNER_UNSET]: { value: CARD_OWNER_UNSET, label: messages.CARD_OWNER_UNSET_OUTPUT }
}

export const SET_CARD_OWNER_OPTIONS_LIST = Object.values(SET_CARD_OWNER_OPTIONS)

export const validateSetCardOwnerBlock = ({ block }) => {
  const optionsRequiresUserInput = new Set([CARD_OWNER_SET, CARD_OWNER_ADD])
  const error = {}
  if (!block.input || !block.input[EFieldKeys.SOURCE_INPUT]) {
    error[EFieldKeys.SOURCE_INPUT] = messages.emptyField
    return { error }
  }

  if (
    !block.meta ||
    (optionsRequiresUserInput.has(block.meta[EFieldKeys.TYPE]) &&
      !block.input[EFieldKeys.USER_INPUT])
  ) {
    error[EFieldKeys.USER_INPUT] = messages.emptyField
    return { error }
  }

  return { error }
}

export const getCardOwnerOutputPrefixText = block => {
  const setCardOwnerOption = block.meta && block.meta[EFieldKeys.TYPE]
  let text

  switch (setCardOwnerOption) {
    case CARD_OWNER_UNSET:
      text = messages.CARD_OWNER_UNSET_OUTPUT_PREFIX
      break
    case CARD_OWNER_SET:
      text = messages.CARD_OWNER_SET_OUTPUT_PREFIX
      break
    case CARD_OWNER_ADD:
      text = messages.CARD_OWNER_ADD_OUTPUT_PREFIX
      break
    default:
      break
  }

  return text
}
