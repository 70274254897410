import gql from 'graphql-tag'

const boardFragmentName = 'BoardFragment'
const BoardFragment = {
  name: boardFragmentName,
  fragment: gql`
        fragment ${boardFragmentName} on Board {
            organizationId
            tenantId
            boardId: id
            appId
            name
            cardsPositionsETag
            allowPublicBoardsCopy
            boardSnapshot
            defaultBoard {
                tenantId
                boardId
            }
            defaultCard {
                tenantId
                boardId
                uuid
                addingOption
            }
            isColumnsHidden
            isLocked
            isPublic
            isWelcomeCardHidden
            welcomeCard
            workflowsNumber
            authMode
            isAutoRefresh3rdPartyDataSources
        }
    `
}

export default BoardFragment
