import React, { Component } from 'react'
import PropTypes from 'prop-types'
import WidgetPlaceholder from './WidgetPlaceholder'

class WidgetErrorBoundary extends Component {
  constructor(props) {
    super(props)

    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo)
    this.props.onCatch()
  }

  render() {
    const { children } = this.props
    const { hasError } = this.state

    if (hasError) {
      return <WidgetPlaceholder appearance="rendering-error" size="ordinary" />
    }

    return children
  }
}

WidgetErrorBoundary.propTypes = {
  onCatch: PropTypes.func
}

WidgetErrorBoundary.defaultProps = {
  onCatch: () => null
}

export default WidgetErrorBoundary
