import React from 'react'
import { connect } from 'react-redux'

import { ExportIndicatorItem } from '../../components/jobsIndicator/exportIndicatorItem'
import JobsIndicator from '../../components/jobsIndicator/JobsIndicator'
import jobsIndicatorHOC from '../hoc/jobsIndicatorHOC'

import * as actions from '../../actions/index'
import messages from '../../constants/messages'
import { EJobTypes } from '../../features/jobs/jobs.types'

const JOB_TYPES = [EJobTypes.EXPORT_BOARDS_JOB]

const ExportIndicatorContainer = props => {
  const {
    isIndicatorHidden,
    jobs,
    visible,
    invisible,
    width,
    transform,
    onMessageHide,
    onToggleHideJobsIndicator,
    onCloseJobsIndicator,
    retryExportBoardsBundle
  } = props

  return (
    <JobsIndicator
      title={messages.EXPORTING_IN_PROGRESS}
      closeTooltipText={messages.CLOSE_EXPORT_WINDOW}
      visible={visible}
      invisible={invisible}
      width={width}
      transform={transform}
      onMessageHide={onMessageHide}
      hidden={isIndicatorHidden}
      onCloseJobsIndicator={onCloseJobsIndicator}
      onToggleHideJobsIndicator={onToggleHideJobsIndicator}
    >
      {jobs.map(job => (
        <ExportIndicatorItem
          key={job.id}
          job={job}
          retryExportBoardsBundle={retryExportBoardsBundle.bind(null, job)}
        />
      ))}
    </JobsIndicator>
  )
}

function mapStateToProps(state) {
  return {
    jobs: state.socket.jobs.filter(job => JOB_TYPES.includes(job.type))
  }
}

const mapDispatchToProps = {
  toggleJobsIndicator: actions.toggleExportIndicator,
  retryExportBoardsBundle: actions.retryExportBoardsBundle
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(jobsIndicatorHOC(ExportIndicatorContainer))
