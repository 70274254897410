import { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { Dialog, DialogActions, DialogContent, DialogTitle } from 'components/common/dialog/dialog'
import { ConfirmationModal } from 'components/modals/confirmation/confirmationModal'
import ConnectionsListGroup from 'components/filters/connections/ConnectionsListGroup'
import FilterConfigurationFooter from 'components/filters/filterConfiguration/FilterConfigurationFooter'
import FilterMetadata from 'components/filters/filterConfiguration/FilterMetadata'
import ConditionsBlock from 'components/filters/filterConfiguration/ConditionsBlock'
import { FilterConnections } from 'containers/filters/filterConfiguration/filterConnections'
import VariableConfigurationModal from 'containers/variables/variableConfiguration/VariableConfigurationModal'
import ConditionsConfigurationContainer from 'containers/filters/conditionsConfiguration/ConditionsConfigurationContainer'
import IconButton from 'components/buttons/IconButton'
import CircleSpinner from 'components/spinners/CircleSpinner'
import VariableDeleteConfirmation from 'containers/variables/VariableDeleteConfirmation'
import { Error } from 'components/filters/connections/ConnectionsLoadingError'
import { toggleVariableConfiguration } from 'actions'
import { generateGUID } from 'helpers/common/commonHelpers'
import messages from 'constants/messages'
import { FILTERS_OBJECTS, OPERATOR, RULES_MODAL_MODES } from 'constants/filters/filtersConstants'
import { getNameError, getObjectError } from 'helpers/filters/filtersValidationHelpers'
import { createFilter, editFilter, fetchFilterConnections } from 'api/filtersAPI'
import { mapFiltersFromStateToFlow, mapFiltersFromFlowToState } from 'helpers/filters/filterMappers'
import splitArrayByType from 'helpers/splitArrayByType'
import { getTeamAdministrationRoute } from 'helpers/routesHelpers'
import {
  getFilterObjectRulesConfig,
  MULTIPLE_TEAMS_RULE_CONFIG,
  FILTER_CONDITION
} from 'helpers/filters/conditionsHelpers'
import { ETeamRole } from 'features/team/team.types'
import 'scss/filters/filterConfiguration/filter-configuration-modal.scss'

const ConditionsInfo = () => <div className="info-wrapper bold">{messages.SET_FILTER_RULES}</div>

const SAVE = 'Save'
const SAVE_AND_UPDATE_ALL = 'Save & apply all'

export class FilterConfigurationModalContainer extends Component {
  state = {
    isSaveBusy: false,
    isSaveAndApplyBusy: false,
    filters: {
      rulesOperator: OPERATOR.OR,
      rules: []
    },
    object: FILTERS_OBJECTS.WIDGET,
    objectError: '',
    name: '',
    nameError: '',
    ruleEditingData: {
      state: false,
      uuid: null,
      mode: RULES_MODAL_MODES.CREATE
    },
    updateConfirmationState: {
      show: false,
      action: ''
    },
    confirmationDialogState: {
      show: false,
      title: '',
      description: ''
    },
    connections: [],
    isConnectionsLoadingError: false,
    areConnectionsLoading: false,
    isCrossTeam: false
  }

  get isReadOnly() {
    const {
      teamRole,
      configurationModalState: { filter }
    } = this.props

    return filter.uuid && filter.isCrossTeam && teamRole !== ETeamRole.WorkspaceAdmin
  }

  get filterObjectRulesConfig() {
    const { object, isCrossTeam } = this.state
    const { filtersConfig } = this.props

    const filterObjectRulesConfig = getFilterObjectRulesConfig(filtersConfig, object)

    if (isCrossTeam) {
      return { ...MULTIPLE_TEAMS_RULE_CONFIG, ...filterObjectRulesConfig }
    }

    return filterObjectRulesConfig
  }

  componentDidMount() {
    const {
      configurationModalState: { filter }
    } = this.props

    this.updateState(filter)
  }

  shouldComponentUpdate(props) {
    const isModalOpen = props.configurationModalState.show
    const isModalWasClosed =
      !props.configurationModalState.show && this.props.configurationModalState.show
    return isModalOpen || isModalWasClosed
  }

  componentDidUpdate(prevProps) {
    const {
      configurationModalState: { show, filter }
    } = this.props

    const wasOpen = prevProps.configurationModalState.show !== show && show

    const shouldUpdateFilter =
      prevProps.configurationModalState.name !== filter.name ||
      prevProps.configurationModalState.object !== filter.object ||
      prevProps.configurationModalState.isCrossTeam !== filter.isCrossTeam ||
      JSON.stringify(prevProps.configurationModalState.filters) !== JSON.stringify(filter.filters)

    if (wasOpen && shouldUpdateFilter) {
      this.updateState(filter)
    }
  }

  onNameChange = event => {
    const value = event.target.value
    this.setState({ name: value, nameError: '' })
  }

  onObjectChange = item => {
    const { object: prevObject, filters } = this.state

    const object = item.value

    const shouldResetRules =
      prevObject !== object &&
      (prevObject === FILTERS_OBJECTS.DATA_RECORD || object === FILTERS_OBJECTS.DATA_RECORD)

    if (shouldResetRules) {
      this.setState({ filters: { ...filters, rules: [] } })
    }

    this.setState({ object })
  }

  onRemoveFilter = () => {
    const {
      configurationModalState: { filter }
    } = this.props
    this.props.deleteFilter(filter)
  }

  onModalCancel = () => {
    this.setState({
      isSaveAndApplyBusy: false,
      isSaveBusy: false,
      nameError: '',
      objectError: '',
      connections: [],
      isConnectionsLoadingError: false
    })
    this.props.toggleFilterConfigurationModal(false)
  }

  onUpdateConfirm = () => {
    const {
      updateConfirmationState: { action }
    } = this.state

    if (action === SAVE) {
      this.onSave()
    }
    if (action === SAVE_AND_UPDATE_ALL) {
      this.onSaveAndApply()
    }

    this.toggleUpdateConfirmation()
  }

  onSave = () => {
    if (this.validateFilter()) {
      this.setState({ isSaveBusy: true })
      this.saveFilter()
        .then(() => {
          if (this.props.updateFilter) {
            this.props.updateFilter()
          }
          this.onModalCancel()
        })
        .catch(() => {
          this.setState({ isSaveBusy: false })
        })
    }
  }

  onSaveAndApply = () => {
    if (this.validateFilter(true)) {
      this.setState({ isSaveAndApplyBusy: true })
      this.saveFilter()
        .then(response => {
          const { uuid, owner: { userId } = {} } = response.data

          this.onModalCancel()
          this.props.applyFilter(uuid, userId)
        })
        .catch(() => {
          this.setState({ isSaveAndApplyBusy: false })
        })
    }
  }

  getEditedRule = uuid => {
    const { filters } = this.state

    return filters.rules.find(rule => rule.uuid === uuid)
  }

  updateState = filter => {
    this.setState({
      name: filter.name,
      object: filter.object,
      filters: mapFiltersFromFlowToState(filter.filters),
      isCrossTeam: filter.isCrossTeam
    })
  }

  checkConnectionsOnSave = () => {
    const {
      configurationModalState: {
        filter: { connections }
      }
    } = this.props

    if (connections.length > 0) {
      this.toggleUpdateConfirmation(SAVE)
      if (!this.state.connections.length) {
        this.fetchConnections()
      }
    } else {
      this.onSave()
    }
  }

  checkConnectionsOnSaveAndApply = () => {
    const {
      configurationModalState: {
        filter: { connections }
      }
    } = this.props

    if (connections.length > 0) {
      this.toggleUpdateConfirmation(SAVE_AND_UPDATE_ALL)
    } else {
      this.onSaveAndApply()
    }
  }

  toggleUpdateConfirmation = action => {
    this.setState(state => ({
      updateConfirmationState: {
        show: !state.updateConfirmationState.show,
        action
      }
    }))
  }

  saveFilter = () => {
    const {
      filter: { uuid }
    } = this.props.configurationModalState
    let request
    if (!uuid) {
      request = this.createFilter()
    } else {
      request = this.editFilter(uuid)
    }
    return request
  }

  createFilter = () => {
    const { filters, name, object, isCrossTeam } = this.state
    const payload = {
      filters: mapFiltersFromStateToFlow(filters),
      name: name.trim(),
      object,
      isCrossTeam
    }
    const { tenantId } = this.props
    return createFilter({ tenantId, payload })
  }

  editFilter = uuid => {
    const { filters, name, object, isCrossTeam } = this.state
    const payload = {
      filters: mapFiltersFromStateToFlow(filters),
      name: name.trim(),
      object,
      isCrossTeam,
      uuid
    }
    const { tenantId } = this.props
    return editFilter({ tenantId, payload })
  }

  toggleRuleCreate = () => {
    this.toggleRulesModal({ state: true })
  }

  toggleRuleEdit = uuid => {
    this.toggleRulesModal({ state: true, uuid, mode: RULES_MODAL_MODES.EDIT })
  }

  toggleRulesModal = ({ state, uuid = null, mode = RULES_MODAL_MODES.CREATE }) => {
    // skip rules modal opening when filtersConfig is empty
    if (!Object.keys(this.filterObjectRulesConfig).length) {
      return
    }

    this.setState({
      ruleEditingData: { state, mode, uuid }
    })
  }

  applyRuleModalResult = ({ uuid, conditionsOperator, conditions }) => {
    const {
      ruleEditingData: { mode }
    } = this.state

    if (mode === RULES_MODAL_MODES.EDIT) {
      this.changeRule({ uuid, conditionsOperator, conditions })
    } else if (mode === RULES_MODAL_MODES.CREATE) {
      this.createRule({ conditionsOperator, conditions })
    }
  }

  changeRule = ({ uuid, conditionsOperator, conditions }) => {
    const { filters } = this.state

    this.setState({
      filters: {
        ...filters,
        rules: filters.rules.map(rule => {
          if (rule.uuid === uuid) {
            return {
              ...rule,
              conditionsOperator,
              conditions
            }
          }

          return rule
        })
      }
    })
  }

  createRule = rule => {
    const { filters } = this.state

    this.setState({
      filters: {
        ...filters,
        rules: [
          ...filters.rules,
          {
            uuid: generateGUID(),
            ...rule
          }
        ]
      }
    })
  }

  deleteRule = uuid => {
    const { filters } = this.state

    this.setState({
      filters: {
        ...filters,
        rules: filters.rules.filter(rule => rule.uuid !== uuid)
      }
    })
  }

  changeOperator = () => {
    const { filters } = this.state

    this.setState({
      filters: {
        ...filters,
        rulesOperator: filters.rulesOperator === OPERATOR.AND ? OPERATOR.OR : OPERATOR.AND
      }
    })
  }

  validateFilter = isShouldApply => {
    const { name, object } = this.state
    const {
      configurationModalState: { defaultObject }
    } = this.props
    const nameError = getNameError(name)
    const objectError = isShouldApply ? getObjectError(object, defaultObject) : ''
    this.setState({
      nameError,
      objectError
    })
    return !nameError && !objectError
  }

  addVariable = () => {
    const { tenantId, tenantName } = this.props

    this.props.toggleVariableConfiguration({
      state: true,
      tenantId,
      tenantName,
      variable: null,
      onConfirm: null
    })
  }

  editVariable = variable => {
    const { tenantId, tenantName } = this.props

    this.props.toggleVariableConfiguration({
      state: true,
      tenantId,
      tenantName,
      variable,
      onConfirm: null
    })
  }

  fetchConnections = () => {
    const {
      tenantId,
      configurationModalState: {
        filter: { uuid }
      }
    } = this.props

    this.setState({
      areConnectionsLoading: true,
      isConnectionsLoadingError: false
    })

    fetchFilterConnections(uuid, { tenantId })
      .then(res => {
        this.setState({
          connections: res.data
        })
      })
      .catch(() => {
        this.setState({
          isConnectionsLoadingError: true
        })
      })
      .finally(() => {
        this.setState({
          areConnectionsLoading: false
        })
      })
  }

  closeConfirmationDialog = () => {
    this.setState(prevState => ({
      confirmationDialogState: {
        ...prevState.confirmationDialogState,
        show: false
      }
    }))
  }

  confirmConfirmationDialog = () => {
    const { confirmationDialogState } = this.state

    if (confirmationDialogState.onModalConfirm) {
      confirmationDialogState.onModalConfirm()
    }

    this.closeConfirmationDialog()
  }

  openIsMultipleTeamsConfirmationDialog = onModalConfirm => {
    this.setState({
      confirmationDialogState: {
        show: true,
        title: 'Heads up!',
        description: 'Turning off the multi-team filter will remove current configuration rules',
        onModalConfirm
      }
    })
  }

  handleIsCrossTeamChange = isCrossTeam => {
    const { filters } = this.state

    const ruleIdsWithTeamCondition = filters.rules.reduce((ruleIds, rule) => {
      const hasTeamCondition = rule.conditions.some(c => c.condition === FILTER_CONDITION.TEAM)

      return hasTeamCondition ? [...ruleIds, rule.uuid] : ruleIds
    }, [])

    if (!isCrossTeam && ruleIdsWithTeamCondition.length > 0) {
      this.openIsMultipleTeamsConfirmationDialog(() => {
        this.setState(prevState => ({
          isCrossTeam,
          filters: {
            ...prevState.filters,
            rules: prevState.filters.rules.filter(
              rule => !ruleIdsWithTeamCondition.includes(rule.uuid)
            )
          }
        }))
      })
    } else {
      this.setState({ isCrossTeam })
    }
  }

  render() {
    const {
      filters,
      object,
      name,
      ruleEditingData,
      objectError,
      nameError,
      isSaveAndApplyBusy,
      isSaveBusy,
      updateConfirmationState,
      isCrossTeam,
      confirmationDialogState
    } = this.state

    const {
      tenantId,
      teamRole,
      configurationModalState: {
        show,
        filter: { uuid, connections = [] },
        defaultObject
      },
      canApplyFilter,
      canReadVariable
    } = this.props

    const rules = filters.rules || []
    const rulesOperator = filters.rulesOperator || OPERATOR.OR

    if (!show) {
      return null
    }

    const isWorkspaceAdmin = teamRole === ETeamRole.WorkspaceAdmin

    const hasConnections = !!connections.length
    const objectEqualsToDefault = defaultObject === object

    const { workflow, widget } = splitArrayByType(this.state.connections, 'connectionType')

    return (
      <>
        <Dialog
          open={show}
          className={classNames('filter-configuration-modal hidden-on-sm hidden-on-md', {
            'filter-rule-configuration': ruleEditingData.state
          })}
          disableEnforceFocus
          disableBackdropClickClose
          onClose={this.onModalCancel}
        >
          <DialogTitle className="header">
            <h3 className="title">
              {!ruleEditingData.state ? (
                messages.FILTER_CONFIGURATION
              ) : (
                <>
                  <span className="filter-configuration-title">{messages.FILTER}</span>
                  <span className="rule-configuration-title">{messages.RULE_CONFIGURATION}</span>
                </>
              )}
            </h3>
            <div className="actions">
              <FilterConnections
                tenantId={tenantId}
                filterId={uuid}
                hasConnections={hasConnections}
                connections={this.state.connections}
                isLoading={this.state.areConnectionsLoading}
                isError={this.state.isConnectionsLoadingError}
                fetchConnections={this.fetchConnections}
              />
              {canReadVariable && (
                <>
                  <IconButton
                    as="a"
                    className="go-to-variables-link"
                    appearance="ribbon"
                    iconSeparated={false}
                    size="small"
                    icon={<i className="icon icon-variable-14-color" aria-hidden="true" />}
                    href={getTeamAdministrationRoute({
                      tenantId,
                      tab: 'variables',
                      hasOpenedFrom: false
                    })}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {messages.VARIABLES}
                  </IconButton>
                  {!this.isReadOnly && (
                    <IconButton
                      as="a"
                      className="create-variable-button"
                      appearance="ribbon"
                      iconSeparated={false}
                      size="small"
                      icon={<i className="icon icon-plus-bold" aria-hidden="true" />}
                      onClick={this.addVariable}
                    />
                  )}
                </>
              )}
            </div>
          </DialogTitle>
          {!ruleEditingData.state ? (
            <>
              <DialogContent className="content">
                <FilterMetadata
                  filterId={uuid}
                  object={object}
                  name={name}
                  hasConnections={hasConnections}
                  objectError={objectError}
                  nameError={nameError}
                  isCrossTeam={isCrossTeam}
                  handleIsCrossTeamChange={this.handleIsCrossTeamChange}
                  isCrossTeamSwitcherShown={isWorkspaceAdmin}
                  isReadOnly={this.isReadOnly}
                  onNameChange={this.onNameChange}
                  onObjectChange={this.onObjectChange}
                />
                <ConditionsBlock
                  rules={rules}
                  object={object}
                  operator={rulesOperator}
                  rulesConfig={this.filterObjectRulesConfig}
                  deleteRule={this.deleteRule}
                  toggleRuleEdit={this.toggleRuleEdit}
                  changeOperator={this.changeOperator}
                  toggleRuleCreate={this.toggleRuleCreate}
                  ConditionsInfo={ConditionsInfo}
                  isReadOnly={this.isReadOnly}
                />
              </DialogContent>
              <DialogActions className="footer">
                <FilterConfigurationFooter
                  isSaveShown={!this.isReadOnly}
                  isSaveAndApplyShown={canApplyFilter && !this.isReadOnly}
                  isSaveAndApplyBusy={isSaveAndApplyBusy}
                  isSaveBusy={isSaveBusy}
                  isApplyDisabled={hasConnections && !objectEqualsToDefault}
                  isRemoveButtonShown={!!uuid && !this.isReadOnly}
                  isRemoveButtonDisabled={hasConnections}
                  onRemoveFilter={this.onRemoveFilter}
                  onCancel={this.onModalCancel}
                  onSave={this.checkConnectionsOnSave}
                  onSaveAndApply={this.checkConnectionsOnSaveAndApply}
                />
              </DialogActions>
            </>
          ) : (
            <ConditionsConfigurationContainer
              mode={ruleEditingData.mode}
              ruleUuid={ruleEditingData.uuid}
              tenantId={tenantId}
              rulesConfig={this.filterObjectRulesConfig}
              getEditedRule={this.getEditedRule}
              toggleRulesModal={this.toggleRulesModal}
              applyRules={this.applyRuleModalResult}
              editVariable={this.editVariable}
              queryObject={object}
            />
          )}
        </Dialog>
        <ConfirmationModal
          descriptionAs="div"
          size="md"
          show={updateConfirmationState.show}
          className="update-filter-confirm-with-connect"
          confirmButtonLabel={messages.SAVE_AND_UPDATE_ALL}
          title={
            <span>
              {messages.FILTERS_UPDATE_CONFIRMATION_WITH_CONNECT_TITLE_PART_ONE}
              <span className="update-filter-confirm-count">{connections.length}</span>
              {messages.FILTERS_UPDATE_CONFIRMATION_WITH_CONNECT_TITLE_PART_TWO}
            </span>
          }
          description={
            <div>
              <i className="icon icon-warning connections-warning" />
              <div>{messages.FILTERS_UPDATE_CONFIRMATION_WITH_CONNECT_DESCR}</div>
              <div className="update-filter-confirm-body-wrapper">
                <div className="update-filter-confirm-subheading">
                  Connections ({connections.length})
                </div>
                <ConnectionsListGroup>
                  {this.state.areConnectionsLoading ? (
                    <CircleSpinner className="spinner" />
                  ) : (
                    <ConnectionsListGroup.ConnectionsListWrapper
                      tenantId={tenantId}
                      workflowList={workflow}
                      widgetList={widget}
                    />
                  )}
                  {this.state.isConnectionsLoadingError && (
                    <Error className="connections-fetch-error" />
                  )}
                </ConnectionsListGroup>
              </div>
            </div>
          }
          showCancelButton
          onModalConfirm={this.onUpdateConfirm}
          onModalCancel={this.toggleUpdateConfirmation}
        />
        <ConfirmationModal
          className="filter-config-modal-confirmation-dialog"
          show={confirmationDialogState.show}
          title={confirmationDialogState.title}
          description={confirmationDialogState.description}
          confirmButtonLabel={messages.CONFIRM}
          descriptionAs="div"
          showCancelButton
          onModalConfirm={this.confirmConfirmationDialog}
          onModalCancel={this.closeConfirmationDialog}
        />
        <VariableConfigurationModal />
        <VariableDeleteConfirmation />
      </>
    )
  }
}

const mapDispatchToProps = {
  toggleVariableConfiguration
}

export default connect(null, mapDispatchToProps)(FilterConfigurationModalContainer)
