import { createReducer } from 'redux-create-reducer'

import {
  TOGGLE_VARIABLES_FETCHING,
  CLEAR_VARIABLES,
  SET_VARIABLES,
  APPEND_VARIABLES,
  TOGGLE_VARIABLE_CONFIGURATION,
  TOGGLE_VARIABLE_DELETE_CONFIGURATION
} from '../constants/actionTypes'

export const variables = createReducer(
  {},
  {
    [TOGGLE_VARIABLES_FETCHING](state, action) {
      return { ...state, isVariablesFetching: action.payload }
    },

    [CLEAR_VARIABLES](state) {
      return { ...state, variables: [] }
    },

    [SET_VARIABLES](state, action) {
      return { ...state, variables: action.payload }
    },
    [APPEND_VARIABLES](state, action) {
      return { ...state, variables: [...state.variables, ...action.payload] }
    },
    [TOGGLE_VARIABLE_CONFIGURATION]: (state, action) => ({
      ...state,
      variableConfiguration: {
        state: action.payload ? action.payload.state : false,
        tenantId: action.payload ? action.payload.tenantId : null,
        tenantName: action.payload ? action.payload.tenantName : null,
        variable: action.payload ? action.payload.variable : null,
        onConfirm: action.payload ? action.payload.onConfirm : null
      }
    }),

    [TOGGLE_VARIABLE_DELETE_CONFIGURATION]: (state, action) => ({
      ...state,
      variableDeleteConfirmation: {
        state: action.payload ? action.payload.state : false,
        tenantId: action.payload ? action.payload.tenantId : null,
        tenantName: action.payload ? action.payload.tenantName : '',
        variableId: action.payload ? action.payload.variableId : null,
        variableName: action.payload ? action.payload.variableName : '',
        onConfirm: action.payload ? action.payload.onConfirm : null
      }
    })
  }
)

export default variables
