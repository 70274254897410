export const isCardBuilderIconShown = args => {
  const { boardPermissions, card } = args

  if (!card.isLinked) {
    return boardPermissions && boardPermissions.accessCardBuilder
  }

  const isSourceCardFromCurrentBoard =
    !card.errorCode &&
    card.linkToSrcCard.tenantId === card.tenantId &&
    card.linkToSrcCard.boardId === card.boardId

  if (isSourceCardFromCurrentBoard) {
    return boardPermissions && boardPermissions.accessCardBuilder
  }

  return !card.errorCode && card.isEditableForCurrentUser
}

// to not set title editing state on card placed on board view
export const getDetailedViewRenamingCardUuid = uuid => `dv/${uuid}`
