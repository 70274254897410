import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const EndlessScrollTrigger = props => {
  const { as: Component, onIntersect, className, threshold, isDisabled } = props

  const triggerRef = useRef()

  useEffect(() => {
    if (isDisabled) return undefined

    const trigger = triggerRef.current

    const options = {
      root: null,
      rootMargin: '0px',
      threshold
    }

    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        onIntersect()
      }
    }, options)

    observer.observe(trigger)

    return () => observer.unobserve(trigger)
  }, [onIntersect, threshold, isDisabled])

  return <Component ref={triggerRef} className={classNames('endless-scroll-trigger', className)} />
}

EndlessScrollTrigger.propTypes = {
  as: PropTypes.string,
  className: PropTypes.string,
  onIntersect: PropTypes.func.isRequired,
  threshold: PropTypes.number,
  isDisabled: PropTypes.bool
}

EndlessScrollTrigger.defaultProps = {
  as: 'div',
  className: '',
  threshold: 1,
  isDisabled: false
}

export default EndlessScrollTrigger
