import React from 'react'
import { connect } from 'react-redux'

import { PublishAppIndicatorItem } from 'components/jobsIndicator/publishAppIndicatorItem'
import { EJobTypes } from 'features/jobs/jobs.types'
import JobsIndicator from '../../components/jobsIndicator/JobsIndicator'
import jobsIndicatorHOC from '../hoc/jobsIndicatorHOC'

import * as actions from '../../actions/index'
import messages from '../../constants/messages'

const JOB_TYPES = [EJobTypes.PUBLISH_APP_JOB]

const PublishAppIndicatorContainer = props => {
  const {
    isIndicatorHidden,
    jobs,
    visible,
    invisible,
    width,
    transform,
    onMessageHide,
    onToggleHideJobsIndicator,
    onCloseJobsIndicator
  } = props

  return (
    <JobsIndicator
      title={messages.APP_PUBLISHING_IN_PROGRESS}
      closeTooltipText={messages.CLOSE_APP_PUBLISH_WINDOW}
      visible={visible}
      invisible={invisible}
      width={width}
      transform={transform}
      onMessageHide={onMessageHide}
      hidden={isIndicatorHidden}
      onCloseJobsIndicator={onCloseJobsIndicator}
      onToggleHideJobsIndicator={onToggleHideJobsIndicator}
    >
      {jobs.map(job => (
        <PublishAppIndicatorItem key={job.id} job={job} />
      ))}
    </JobsIndicator>
  )
}

function mapStateToProps(state) {
  return {
    jobs: state.socket.jobs.filter(job => JOB_TYPES.includes(job.type))
  }
}

const mapDispatchToProps = {
  toggleJobsIndicator: actions.toggleAppPublishingIndicator
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(jobsIndicatorHOC(PublishAppIndicatorContainer))
