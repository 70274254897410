export const findSelectedOptionByKey = (value, options, valuePropName = 'value') =>
  value !== null && value !== undefined
    ? options.find(option => option[valuePropName] === value)
    : null

export const findSelectedOptionsByKey = (
  values,
  options = [],
  valuePropName = 'value',
  labelPropName = 'label'
) =>
  values !== null && values !== undefined
    ? options
        .filter(option => values.includes(option[valuePropName]))
        .map(value => ({ label: value[labelPropName], value: value[valuePropName] }))
    : null

export const getSelectedOptionByKey = (value, options, labelPropName = 'label') =>
  value !== null && value !== undefined ? { label: options[value][labelPropName], value } : null

export const mapSelectedValuesFromLabel = values =>
  values ? values.map(value => ({ label: value, value })) : null

export const mapSelectedValuesFromLabelAndValue = (
  values,
  valuePropName = 'value',
  labelPropName = 'label'
) =>
  values
    ? values.map(value => ({ label: value[labelPropName], value: value[valuePropName] }))
    : null

export const getKeyAsOptionValue = option => option.key

export const getTextAsOptionLabel = option => option.text
