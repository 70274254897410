import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'

import ReactSelect from 'components/fields/ReactSelect'

import 'scss/ribbonTools/ribbon-plain-select.scss'

const RibbonPlainSelect = props => {
  const {
    isSearchable,
    className,
    placeholder,
    value,
    options,
    disabled,
    onChange,
    formatOptionLabel,
    getOptionValue,
    getOptionLabel,
    hideSelectedOptions
  } = props

  return (
    <ReactSelect
      size="small"
      className={classNames('ribbon-plain-select', className)}
      isSearchable={isSearchable}
      placeholder={placeholder}
      value={value}
      options={options}
      onChange={onChange}
      isDisabled={disabled}
      formatOptionLabel={formatOptionLabel}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      hideSelectedOptions={hideSelectedOptions}
    />
  )
}

RibbonPlainSelect.propTypes = {
  value: PropTypes.object,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func,
  isSearchable: PropTypes.bool,
  disabled: PropTypes.bool,
  formatOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  hideSelectedOptions: PropTypes.bool
}

RibbonPlainSelect.defaultProps = {
  value: null,
  className: '',
  placeholder: '',
  onChange: null,
  isSearchable: false,
  disabled: false,
  formatOptionLabel: undefined,
  getOptionValue: undefined,
  getOptionLabel: undefined,
  hideSelectedOptions: false
}

export default RibbonPlainSelect
