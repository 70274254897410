export const DETAILED_VIEW_APPEARANCES = {
  ORDINARY: 'ordinary',
  EMBED: 'embed',
  APP: 'app'
} as const

export const FULL_SCREEN_APPEARANCES = [
  DETAILED_VIEW_APPEARANCES.EMBED,
  DETAILED_VIEW_APPEARANCES.APP
]

export const PDF_GENERATION_STATUS = {
  REJECTED: 'rejected'
}

export const DEFAULT_SWIPE_DELTA = 100

export enum ENavigationDirections {
  up = 'up',
  down = 'down',
  left = 'left',
  right = 'right'
}
