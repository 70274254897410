import { DEFAULT_CARD_HEIGHT, DEFAULT_CARD_WIDTH } from '../builderHelpers'

const backendUrl = import.meta.env.VITE_BACKEND_URL

export const getIconFromExt = extension => {
  const jpg = '/assets/images/icons/ic_files_images.svg'
  const ppt = '/assets/images/icons/ic_files_powerpoint_l.svg'
  const doc = '/assets/images/icons/ic_files_word_l.svg'
  const xls = '/assets/images/icons/ic_files_excel_l.svg'
  const fileExtensions = {
    jpg,
    jpeg: jpg,
    png: jpg,
    svg: '/assets/images/icons/ic_files_svg.svg',
    pdf: '/assets/images/icons/ic_files_adobe_l.svg',
    ppt,
    pptm: ppt,
    pptx: ppt,
    doc,
    docm: doc,
    docx: doc,
    xls,
    xlsm: xls,
    xlsx: xls,
    csv: xls,
    gdocs: '/assets/images/icons/ic_files_gdocs_l.svg',
    gsheets: '/assets/images/icons/ic_files_gsheets.svg',
    gslides: '/assets/images/icons/ic_files_glides_l.svg',
    onedocs: '/assets/images/icons/ic_files_onedocs_l.svg',
    onesheets: '/assets/images/icons/ic_files_onesheets.svg',
    oneslides: '/assets/images/icons/ic_files_oneslides_l.svg',
    default: '/assets/images/icons/ic_files_other_l.svg'
  }

  const ext = extension ? extension.toLowerCase() : ''
  return fileExtensions[ext] ? fileExtensions[ext] : fileExtensions.default
}

const FILE_WIDGET_POSITION = 10

export const applyImageSize = ({ width, height }) => {
  const widthRatio = DEFAULT_CARD_WIDTH / width
  const heightRation = DEFAULT_CARD_HEIGHT / height

  const resultRatio = Math.min(widthRatio, heightRation)
  if (resultRatio < 1) {
    return {
      // to have margin from all sides
      width: Math.floor(width * resultRatio) - 2 * FILE_WIDGET_POSITION,
      height: Math.floor(height * resultRatio) - 2 * FILE_WIDGET_POSITION
    }
  }

  return { width, height }
}

export const createWidgetFileLink = ({ tenantId, boardId, name }) => {
  return `api/tenant/${tenantId}/board/${boardId}/file/${name}`
}

export const getImageUrl = image => {
  if (!image) return ''
  const url = image.includes('uploader') ? window.istioHost : `${backendUrl}`
  return image.startsWith('api') ? `${url}${image}` : image
}

const CSV_FILE_SIZE_THRESHOLD_BYTES = 500000 // 500kb
const LARGE_CSV_WIDGET_DATA = {
  rows: '[[]]',
  flags: {
    isDataProvider: false,
    hasDownloadLink: true,
    groupToUploadFilesWidget: true
  }
}

export const parseFileToWidget = ({ file, tenantId, boardId }) => {
  const { name, mimeType, size, fileName, rows, strokeWidth, strokeColor, fillColor } = file

  const extension = fileName.split('.').pop()
  const widget = { size, mimeType, extension, widgetTitle: fileName }
  const fileLink = createWidgetFileLink({ tenantId, boardId, name })

  if (file.mimeType.startsWith('image') && file.mimeType !== 'image/webp') {
    const { width, height } = applyImageSize(file)

    if (strokeWidth) {
      return {
        ...widget,
        imgSrc: fileLink,
        thumbnailSrc: '',
        width,
        height,
        svgData: {
          strokeWidth,
          strokeColor,
          fillColor
        }
      }
    }

    return {
      ...widget,
      imgSrc: fileLink,
      thumbnailSrc: fileLink,
      width,
      height
    }
  }

  if (extension === 'csv') {
    const isLargeCSVFile = size >= CSV_FILE_SIZE_THRESHOLD_BYTES

    return {
      ...widget,
      ...(isLargeCSVFile ? LARGE_CSV_WIDGET_DATA : { rows: rows || '[[]]' }),
      fileLink
    }
  }

  return {
    ...widget,
    fileLink
  }
}
