import {
  organizationNamesQuery,
  getOrganizationPublishedAppsQuery,
  getOrganizationCollectionsQuery
} from 'graphql/queries/organizationQueries'
import graphqlRequestWrapper from 'graphql/common/requestHelpers'
import {
  workspaceManagersToAssignExtractor,
  workspaceManagersExtractor,
  workspaceMembersExtractor,
  workspaceMemberExtractor,
  possibleSubordinatesExtractor
} from 'graphql/dataExtractors/workspaceMembersExtractors'
import {
  workspaceManagersToAssignQuery,
  workspaceManagersQuery,
  workspaceMembersQuery,
  workspaceMemberQuery,
  userPossibleSubordinatesQuery
} from 'graphql/queries/workspaceMembersQueries'
import { teamsMembersQuery } from 'graphql/queries/tenantQueries'
import { requestWrapper, url } from 'helpers/fetchHelpers'
import { teamsMembersExtractor } from 'graphql/dataExtractors/tenantExtractors'

export function getOrganizationPermissionsRequest() {
  return requestWrapper({
    rUrl: `${url}organization/permissions`,
    type: 'GET',
    payload: {}
  })
}

export function saveOrganizationProfile(payload) {
  return requestWrapper({
    rUrl: `${url}organization`,
    type: 'PUT',
    payload: payload.data
  })
}

export function deleteOrganization(payload) {
  return requestWrapper({
    rUrl: `${url}organization`,
    type: 'DELETE',
    payload: payload.data
  })
}

export function getAllOrganizations() {
  return requestWrapper({
    rUrl: `${url}userOrganizations`,
    type: 'GET',
    payload: {},
    hasServerErrorHandling: true
  })
}

export function getAuthenticatedOrganizations() {
  return requestWrapper({ rUrl: `${url}authenticatedOrganizations`, type: 'GET', payload: {} })
}

export function processSwitchOrganization(payload) {
  return requestWrapper({
    rUrl: `${url}switchActiveOrganization`,
    type: 'POST',
    payload
  })
}

export const getWorkspaceTeamsMembers = ({ variables, expand = {} }) =>
  graphqlRequestWrapper({
    query: teamsMembersQuery(expand),
    variables
  }).then(teamsMembersExtractor)

export function getWorkspaceMembers({ variables, expand = {} }) {
  return graphqlRequestWrapper({
    query: workspaceMembersQuery(expand),
    variables
  }).then(workspaceMembersExtractor)
}

export function getWorkspaceMember({ variables, expand = {} }) {
  return graphqlRequestWrapper({
    query: workspaceMemberQuery(expand),
    variables
  }).then(workspaceMemberExtractor)
}

export function getUserPossibleSubordinates({ variables }) {
  return graphqlRequestWrapper({
    query: userPossibleSubordinatesQuery(),
    variables
  }).then(possibleSubordinatesExtractor)
}

export function getWorkspaceManagers({ variables }) {
  return graphqlRequestWrapper({
    query: workspaceManagersQuery,
    variables
  }).then(workspaceManagersExtractor)
}

export function getWorkspaceManagersToAssign({ variables }) {
  return graphqlRequestWrapper({
    query: workspaceManagersToAssignQuery,
    variables
  }).then(workspaceManagersToAssignExtractor)
}

export function assignManager({ organizationId, data }) {
  return requestWrapper({
    rUrl: `${url}organization/${organizationId}/user/manager`,
    type: 'PUT',
    payload: data
  })
}

export function removeOrganizationMember({ organizationId, userId }) {
  return requestWrapper({
    rUrl: `${url}organization/${organizationId}/user/${userId}`,
    type: 'DELETE'
  })
}

export function updateOrganizationTimeZone(payload) {
  return requestWrapper({
    rUrl: `${url}organization/time-zone`,
    type: 'PUT',
    payload
  })
}

export const fetchOrganizationPublishedApps = ({ variables, expand = {} }) =>
  graphqlRequestWrapper({
    query: getOrganizationPublishedAppsQuery(expand),
    variables
  }).then(res => res.data.organization.libraryApps)

export const fetchWorkspaceNames = ({ variables, expand = {} }) =>
  graphqlRequestWrapper({
    query: organizationNamesQuery(expand),
    variables
  }).then(res => res.data.organizations)

export const fetchOrganizationCollections = ({ variables }) =>
  graphqlRequestWrapper({
    query: getOrganizationCollectionsQuery(),
    variables
  }).then(res => res.data.organization.appStoreCollections)
