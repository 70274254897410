import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import 'scss/ribbonTools/ribbon-group.scss'

const RibbonGroup = props => {
  const { children, className, appearance, margin } = props

  return (
    <div
      className={classNames('ribbon-group', appearance, className, {
        'margin-4': margin === 4,
        'margin-8': margin === 8
      })}
    >
      {children}
    </div>
  )
}

RibbonGroup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.func]).isRequired,
  appearance: PropTypes.oneOf(['with-dividers', 'without-dividers']),
  margin: PropTypes.oneOf([0, 4, 8]),
  className: PropTypes.string
}

RibbonGroup.defaultProps = {
  margin: 0,
  appearance: 'with-dividers',
  className: ''
}

export default RibbonGroup
