import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'

const PlainInput = React.forwardRef((props, ref) => {
  const {
    id,
    name,
    className,
    defaultValue,
    value,
    placeholder,
    title,
    disabled,
    pattern,
    maxLength,
    onBlur,
    onFocus,
    onChange,
    onKeyPress,
    onKeyUp,
    type = 'text'
  } = props

  return (
    <input
      id={id}
      ref={ref}
      name={name}
      type={type}
      className={classNames('input-field', {
        [className]: className
      })}
      maxLength={maxLength}
      defaultValue={defaultValue}
      value={value}
      placeholder={placeholder}
      pattern={pattern}
      title={title}
      disabled={disabled}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      onKeyPress={onKeyPress}
      onKeyUp={onKeyUp}
    />
  )
})

PlainInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  placeholder: PropTypes.string,
  pattern: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  onKeyUp: PropTypes.func
}

PlainInput.defaultProps = {
  id: undefined,
  name: undefined,
  value: undefined,
  defaultValue: undefined,
  className: '',
  placeholder: '',
  pattern: undefined,
  title: '',
  disabled: false,
  maxLength: 100,
  onChange: null,
  onFocus: null,
  onBlur: null,
  onKeyPress: null,
  onKeyUp: null
}

export default PlainInput
