import { getPosition, sortByPositions } from 'helpers/cardsPositionHelpers'

export const sortNavItems = navItems => {
  const groupedNavItems = navItems.reduce((acc, navItem) => {
    if (!navItem.groupId) {
      return acc
    }

    if (!acc[navItem.groupId]) {
      acc[navItem.groupId] = []
    }

    acc[navItem.groupId].push(navItem)

    return acc
  }, {})

  return navItems
    .reduce((acc, navItem) => {
      // skip nav item in groups
      if (navItem.groupId) {
        return acc
      }

      // add children to group
      if (navItem.isGroup) {
        acc.push({
          ...navItem,
          children: groupedNavItems[navItem.id]?.sort(sortByPositions) || []
        })

        return acc
      }

      acc.push(navItem)

      return acc
    }, [])
    .sort(sortByPositions)
}

export const filterInaccessibleNavItems = navItems =>
  navItems.filter(navItem => navItem.errorCode !== 403)

export const getReorderNavItems = ({ navItems, draggedItemId, overItemId }) => {
  const draggedIndex = navItems.findIndex(navItem => navItem.id === draggedItemId)
  const overIndex = navItems.findIndex(navItem => navItem.id === overItemId)

  // whether dragged item should be placed below over item or above
  const isPlacedBelow = overIndex > draggedIndex

  const prevPos = isPlacedBelow ? navItems[overIndex] : navItems[overIndex - 1]
  const nextPos = isPlacedBelow ? navItems[overIndex + 1] : navItems[overIndex]

  const dragged = { ...navItems[draggedIndex], ...getPosition({ prevPos, nextPos }) }

  const newNavItems = [...navItems]
  newNavItems[draggedIndex] = dragged
  newNavItems.sort(sortByPositions)

  return newNavItems
}

export const splitNavItems = navItems => {
  const ancestorItems = []
  const descendantItems = []
  navItems.forEach(item => {
    if (item.groupId) {
      descendantItems.push(item)
    } else {
      ancestorItems.push(item)
    }
  })
  return {
    ancestorItems: ancestorItems.sort(sortByPositions),
    descendantItems
  }
}
