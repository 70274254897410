import React, { Component } from 'react'
import { connect } from 'react-redux'
import { GOOGLE_SUCCESSFUL_LOGIN } from 'features/widgets/eventBus/eventBus.constants'
import ActionsToastMessage from 'components/common/actionsToastMessage'
import * as actions from '../../actions'
import messages from '../../constants/messages'
import { authIfNeeded } from '../../helpers/microsoftAuthHelpers'

class MsAuthRequestContainer extends Component {
  msAuth() {
    authIfNeeded()
      .then(() => {
        const event = new CustomEvent(GOOGLE_SUCCESSFUL_LOGIN, {
          detail: { type: 'successful' }
        })

        document.dispatchEvent(event)

        this.props.toggleMSAuthRequest(false)
      })
      .catch(err => console.error(err))
  }

  render() {
    const { visible, height, width, transform, onMessageHide } = this.props

    return (
      <ActionsToastMessage
        isVisible={visible}
        height={height}
        width={width}
        transform={transform}
        onContinue={this.msAuth.bind(this)}
        onDiscard={onMessageHide}
        discardText={messages.SKIP}
        continueText={messages.SIGN_IN_INTO_MS_ACCOUNT}
      >
        <div>
          {messages.SIGN_IN_INTO_MS_ACCOUNT_DESC_1}&nbsp;
          <span className="bold">{messages.ONE_DRIVE}</span>
          <br />
          <br />
          {messages.SIGN_IN_INTO_MS_ACCOUNT_DESC_2}&nbsp;
          <span className="bold">{messages.ONE_DRIVE_ACCOUNT}</span>.
        </div>
      </ActionsToastMessage>
    )
  }
}

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  toggleMSAuthRequest: actions.toggleMSAuthRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(MsAuthRequestContainer)
