const splitArrayByType = (array, type) => {
  const resultObject = {}
  if (!array || !array.length) {
    return resultObject
  }

  array.forEach(item => {
    if (!item[type]) {
      return
    }

    if (!resultObject[item[type]]) {
      resultObject[item[type]] = []
    }

    resultObject[item[type]].push(item)
  })

  return resultObject
}

export default splitArrayByType
