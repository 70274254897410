export const REQUEST_LOGIN = 'REQUEST_LOGIN'
export const RECEIVE_LOGIN = 'RECEIVE_LOGIN'
export const RECEIVE_LOGIN_ERROR = 'RECEIVE_LOGIN_ERROR'

export const REQUEST_REGISTRATION = 'REQUEST_REGISTRATION'
export const RECEIVE_REGISTRATION = 'RECEIVE_REGISTRATION'
export const RECEIVE_REGISTRATION_ERROR = 'RECEIVE_REGISTRATION_ERROR'

export const REQUEST_RESET_LINK = 'REQUEST_RESET_LINK'
export const RECEIVE_RESET_LINK = 'RECEIVE_RESET_LINK'
export const RECEIVE_RESET_LINK_ERROR = 'RECEIVE_RESET_LINK_ERROR'

export const REQUEST_CHANGE_PASSWORD = 'REQUEST_CHANGE_PASSWORD'
export const RECEIVE_CHANGE_PASSWORD = 'RECEIVE_CHANGE_PASSWORD'
export const RECEIVE_CHANGE_PASSWORD_ERROR = 'RECEIVE_CHANGE_PASSWORD_ERROR'

export const TOGGLE_PASSWORD_CHANGED_MODAL = 'TOGGLE_PASSWORD_CHANGED_MODAL'

export const SET_SIGNED_UP_EMAIL = 'SET_SIGNED_UP_EMAIL'
export const SET_SIGNED_IN_EMAIL = 'SET_SIGNED_IN_EMAIL'
export const SET_KEEP_LOGGED_IN = 'SET_KEEP_LOGGED_IN'

export const REQUEST_ALL_ORGANIZATIONS = 'REQUEST_ALL_ORGANIZATIONS'
export const RECEIVE_ALL_ORGANIZATIONS = 'RECEIVE_ALL_ORGANIZATIONS'
export const RECEIVE_ALL_ORGANIZATIONS_ERROR = 'RECEIVE_ALL_ORGANIZATIONS_ERROR'
