import { type IWorkflowBlock, EWorkflowTriggerEventType } from 'features/workflow/workflow.types'
import { EWFScheduledRunsFrequency } from './scheduleParams/scheduleParams.types'
import { type TTriggerBlockError } from './triggerBlock.types'

const validateHours = (hours: Array<{ hour: string; minute: string }>): TTriggerBlockError => {
  const isDuplicate = hours.some(
    ({ hour, minute }, index) =>
      !!hours.slice(0, index).find(item => item.hour === hour && item.minute === minute)
  )

  return isDuplicate ? { hours: 'Such value already exists' } : {}
}

export const validateTriggerBlock = ({
  block: { scheduleSettings, meta }
}: {
  block: IWorkflowBlock
}) => {
  if (meta.eventType !== EWorkflowTriggerEventType.SCHEDULE || !scheduleSettings) {
    return {
      error: {}
    }
  }

  const { frequency, daysOfWeek, daysOfMonth, monthsOfYear, hours } = scheduleSettings

  const error: TTriggerBlockError = {
    ...validateHours(hours)
  }

  if (frequency === EWFScheduledRunsFrequency.WEEKLY) {
    if (!daysOfWeek?.length) {
      error.daysOfWeek = 'Choose at least one'
    }
  }

  if (frequency === EWFScheduledRunsFrequency.MONTHLY) {
    if (!monthsOfYear?.length) {
      error.monthsOfYear = 'Choose at least one'
    }
    if (!daysOfMonth?.length) {
      error.daysOfMonth = "You can't leave this empty"
    }
  }

  return {
    error
  }
}
