export const TOGGLE_DEACTIVATING_TENANT_MEMBER_MODAL = 'TOGGLE_DEACTIVATING_TENANT_MEMBER_MODAL'

export const RECEIVE_DEACTIVATE_MEMBERS = 'RECEIVE_DEACTIVATE_MEMBERS'

export const TOGGLE_INVITATION_MODAL = 'TOGGLE_INVITATION_MODAL'

export const TOGGLE_INVITE_MEMBERS_LOADER = 'TOGGLE_INVITE_MEMBERS_LOADER'

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST'
export const CHANGE_PASSWORD_RECEIVE = 'CHANGE_PASSWORD_RECEIVE'
export const CHANGE_PASSWORD_RECEIVE_ERROR = 'CHANGE_PASSWORD_RECEIVE_ERROR'

export const TOGGLE_TERMINATE_USER_MODAL = 'TOGGLE_TERMINATE_USER_MODAL'

export const TERMINATE_USER_REQUEST = 'TERMINATE_USER_REQUEST'
export const TERMINATE_USER_RECEIVE = 'TERMINATE_USER_RECEIVE'
export const TERMINATE_USER_RECEIVE_ERROR = 'TERMINATE_USER_RECEIVE_ERROR'

export const TOGGLE_DELETE_TEAM_MEMBER_MODAL = 'TOGGLE_DELETE_TEAM_MEMBER_MODAL'
export const TOGGLE_DELETE_TEAM_MEMBER_REJECTION_MODAL = 'TOGGLE_DELETE_TEAM_MEMBER_REJECTION_MODAL'
export const DELETE_TEAM_MEMBER_REQUEST = 'DELETE_TEAM_MEMBER_REQUEST'
export const DELETE_TEAM_MEMBER_RECEIVE = 'TERMINATE_USER_RECEIVE'
export const DELETE_TEAM_MEMBER_RECEIVE_ERROR = 'DELETE_TEAM_MEMBER_RECEIVE_ERROR'
export const SET_LEAVING_TEAM_DATA = 'SET_LEAVING_TEAM_DATA'
export const CLEAN_UP_LEAVING_TEAM_DATA = 'CLEAN_UP_LEAVING_TEAM_DATA'

export const TOGGLE_DELETE_TEAM_MODAL = 'TOGGLE_DELETE_TEAM_MODAL'
export const DELETE_TEAM_REQUEST = 'DELETE_TEAM_REQUEST'
export const DELETE_TEAM_RECEIVE = 'DELETE_TEAM_RECEIVE'
export const DELETE_TEAM_RECEIVE_ERROR = 'DELETE_TEAM_RECEIVE_ERROR'

export const RECEIVE_CURRENT_TENANT = 'RECEIVE_CURRENT_TENANT'
export const UPDATE_CURRENT_TENANT = 'UPDATE_CURRENT_TENANT'

export const TOGGLE_DELETE_ORGANIZATION_MODAL = 'TOGGLE_DELETE_ORGANIZATION_MODAL'
export const DELETE_ORGANIZATION_REQUEST = 'DELETE_ORGANIZATION_REQUEST'
export const DELETE_ORGANIZATION_RECEIVE = 'DELETE_ORGANIZATION_RECEIVE'
export const DELETE_ORGANIZATION_RECEIVE_ERROR = 'DELETE_ORGANIZATION_RECEIVE_ERROR'

export const WORKSPACES_REQUEST = 'WORKSPACES_REQUEST'
export const WORKSPACES_RECEIVE = 'WORKSPACES_RECEIVE'
export const WORKSPACES_RECEIVE_ERROR = 'WORKSPACES_RECEIVE_ERROR'
