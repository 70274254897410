import React, { useCallback } from 'react'
import Select from 'react-select'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import messages from 'constants/messages'
import '../../scss/common/multi-select.scss'

const MultiSelect = props => {
  const { onChange: propsOnChange } = props

  // react-select v3 begin to set values to null
  // in case user clears all values in multeselect field
  const onChange = useCallback(
    (values, ...args) => propsOnChange(values || [], ...args),
    [propsOnChange]
  )

  return (
    <Select
      className={classNames('upboard-react-select', props.size, props.className)}
      classNamePrefix="upboard-rs"
      isMulti
      placeholder={props.placeholder}
      value={props.values}
      options={props.selectOptions}
      isSearchable={props.isSearchable}
      onChange={onChange}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      isDisabled={props.isDisabled}
      getOptionValue={props.getOptionValue}
      getOptionLabel={props.getOptionLabel}
      formatGroupLabel={props.formatGroupLabel}
      closeMenuOnSelect={props.closeMenuOnSelect}
      noOptionsMessage={props.noOptionsMessage}
    />
  )
}

MultiSelect.propTypes = {
  values: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  className: PropTypes.string,
  size: PropTypes.oneOf(['medium', 'large']),
  placeholder: PropTypes.string,
  isSearchable: PropTypes.bool,
  selectOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  formatGroupLabel: PropTypes.func,
  isDisabled: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  noOptionsMessage: PropTypes.func
}

MultiSelect.defaultProps = {
  values: null,
  className: '',
  size: 'medium',
  placeholder: '',
  isSearchable: false,
  onChange: null,
  onFocus: null,
  onBlur: null,
  isDisabled: false,
  closeMenuOnSelect: true,
  getOptionValue: undefined,
  getOptionLabel: undefined,
  formatGroupLabel: undefined,
  noOptionsMessage: () => messages.NO_OPTIONS
}

export default MultiSelect
