import { chunkArray } from 'helpers/common/commonHelpers'
import { requestWrapper, url } from '../helpers/fetchHelpers'
import graphqlRequestWrapper from '../graphql/common/requestHelpers'
import { getBoardQuery, getBoardsQuery } from '../graphql/queries/boardQueries'
import { extractBoard, extractBoards } from '../graphql/dataExtractors/boardExtractors'
import { GraphQlError, GRAPHQL_UNEXPECTED_RESPONSE_TEXT } from '../graphql/common/errorHandler'

export const getBoardsList = ({ tenantId }) =>
  requestWrapper({
    rUrl: `${url}tenant/${tenantId}/boards/list`,
    type: 'GET',
    payload: {}
  })

export function createBoard(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board`,
    type: 'POST',
    payload: payload.board
  })
}

export function processBoardPermissionsRequest(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/permissions`,
    type: 'GET',
    payload: {}
  })
}

export function processGetBoardRequest(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}`,
    type: 'GET',
    payload: {}
  })
}

export async function processGQLGetBoardRequest({ expand, ...variables }) {
  try {
    const boardQuery = getBoardQuery({
      board: true,
      ...expand
    })

    const response = await graphqlRequestWrapper({
      query: boardQuery,
      variables
    })

    const data = extractBoard(response)
    if (data) {
      return { data }
    }
    throw new GraphQlError({
      message: GRAPHQL_UNEXPECTED_RESPONSE_TEXT
    })
  } catch (error) {
    throw new GraphQlError(error)
  }
}

export async function getBoardsRequest({ expand, boardIds, ...variables }) {
  const batchSize = 3
  const boardIdsBuffer = chunkArray(boardIds, batchSize)

  try {
    const boardQuery = getBoardsQuery({
      board: true,
      ...expand
    })
    const boardRequests = boardIdsBuffer.map(ids =>
      graphqlRequestWrapper({
        useBatch: true,
        query: boardQuery,
        variables: {
          boardIds: ids,
          ...variables
        }
      })
    )

    const data = await Promise.all(boardRequests).then(responses =>
      responses.reduce((acc, response) => {
        const boards = extractBoards(response)
        return acc.concat(boards)
      }, [])
    )

    if (data) {
      return { data }
    }
    throw new GraphQlError({
      message: GRAPHQL_UNEXPECTED_RESPONSE_TEXT
    })
  } catch (error) {
    throw new GraphQlError(error)
  }
}

export function processDeleteBoardRequest(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}`,
    type: 'DELETE',
    payload: {}
  })
}

export function processCopyBoardRequest(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/copy?async=true`,
    type: 'POST',
    payload: {
      name: payload.boardName,
      targetTenantId: payload.targetTenantId
    }
  })
}

// board settings
export function processBoardSettingsRequest(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/settings`,
    type: 'GET',
    payload: {}
  })
}

// update board settings
export function processUpdateBoardSettings(payload) {
  const { tenantId, boardId, data } = payload

  return requestWrapper({
    rUrl: `${url}tenant/${tenantId}/board/${boardId}/settings`,
    type: 'PUT',
    payload: data
  })
}

// public board setting
export function processPublicBoardRequest(payload) {
  const rUrl = payload.isPublic
    ? `${url}tenant/${payload.tenantId}/board/${payload.boardId}/public`
    : `${url}tenant/${payload.tenantId}/board/${payload.boardId}/private`
  return requestWrapper({
    rUrl,
    type: 'GET',
    payload: {}
  })
}

// boards bundle export
export function processExportBoardsBundle(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/bundle/export`,
    type: 'POST',
    payload: payload.data
  })
}

export function exportAppBundle(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/app/${payload.appId}/export`,
    type: 'POST',
    payload: payload.data
  })
}

// boards bundle import
export function processImportBoardsBundle({ tenantId, bundleName }) {
  return requestWrapper({
    rUrl: `${url}tenant/${tenantId}/bundle/import`,
    type: 'POST',
    payload: { bundleName }
  })
}

export const updateBoard = payload =>
  requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}`,
    type: 'PUT',
    payload: payload.data
  })

export const updateBoardAutoRefreshThirdParty = payload =>
  requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/auto-refresh-3rd-party-data-sources-toggle`,
    type: 'PUT',
    payload: payload.data
  })

export const updateBoardConditionalLayout = payload => {
  const { tenantId, boardId, isConditionalLayoutEnabled } = payload

  return requestWrapper({
    rUrl: `${url}tenant/${tenantId}/board/${boardId}/conditional-layout-toggle`,
    type: 'PUT',
    payload: {
      isConditionalLayoutEnabled
    }
  })
}
