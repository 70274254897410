import { createReducer } from 'redux-create-reducer'
import * as ActionTypes from '../constants/actionTypes'
import { getIdsObjectFromKey } from '../helpers/common/commonHelpers'
import { hasCategory } from '../helpers/widget/widgetDataHelpers'

export const widget = createReducer(
  {},
  {
    [ActionTypes.REQUEST_WIDGET_CLASSES_LIST](state) {
      return {
        ...state,
        isWidgetClassesListRequested: true
      }
    },

    [ActionTypes.RECEIVE_WIDGET_CLASSES_LIST](state, action) {
      const list = []
      const uploadedImages = []
      action.payload.forEach(item => {
        if (hasCategory(item.category, 'file')) {
          uploadedImages.push(item)
        } else {
          list.push(item)
        }
      })

      return {
        ...state,
        list,
        uploadedImages,
        isWidgetClassesListRequested: false
      }
    },

    [ActionTypes.RECEIVE_WIDGET_CLASSES_LIST_ERROR](state) {
      return {
        ...state,
        isWidgetClassesListRequested: false
      }
    },

    [ActionTypes.RECEIVE_UPLOAD_WIDGET](state, action) {
      const uploadedImages = state.uploadedImages.slice(0)
      uploadedImages.unshift(action.payload)

      return { ...state, uploadedImages }
    },

    [ActionTypes.REQUEST_UPDATE_WIDGET_MENU_ITEM](state, action) {
      const uploadedImages = state.uploadedImages.map(item => {
        if (getIdsObjectFromKey(item.key).menuwidget === action.payload.widgetID) {
          return { ...item, ...action.payload.data }
        }
        return item
      })

      return { ...state, uploadedImages }
    },

    [ActionTypes.TOGGLE_UPLOADED_IMAGE_DELETE_MODAL](state, action) {
      return { ...state, isUploadedImageDeleteModalShown: action.payload }
    },

    [ActionTypes.RECEIVE_DELETE_UPLOADED_WIDGET](state, action) {
      const uploadedImages = state.uploadedImages.filter(
        image => getIdsObjectFromKey(image.key).menuwidget !== action.payload.widgetID
      )

      return { ...state, uploadedImages, deleteUploadedImageErrorMessage: '' }
    },

    [ActionTypes.RECEIVE_DELETE_UPLOADED_WIDGET_ERROR_MESSAGE](state, action) {
      return { ...state, deleteUploadedImageErrorMessage: action.payload.message }
    },

    [ActionTypes.UPDATE_CURRENT_UPLOADING_DATA](state, action) {
      return { ...state, uploadingData: { ...state.uploadingData, ...action.payload } }
    },

    [ActionTypes.TOGGLE_FILES_UPLOADING_FLAG](state, action) {
      return { ...state, isFileUploading: action.payload }
    },

    [ActionTypes.TOGGLE_GOOGLE_PICKER](state, action) {
      return { ...state, isOpenGooglePicker: action.payload }
    },
    [ActionTypes.SET_UPLOAD_FILES_WIDGET_DATA](state, action) {
      return { ...state, currentUploadFilesWidgetData: action.payload }
    },

    [ActionTypes.TOGGLE_ONE_DRIVE_PICKER](state, action) {
      return { ...state, isOpenOneDrivePicker: action.payload }
    },
    [ActionTypes.TOGGLE_WIDGET_FULLSCREEN_MODAL](state, action) {
      return { ...state, fullScreenWidgetShow: action.payload }
    }
  }
)

export default widget
