import gql from 'graphql-tag'

export const getNotificationsListQuery = () => gql`
  query getNotificationsList($first: Int, $after: String) {
    user {
      feedNotifications(first: $first, after: $after) {
        totals {
          count
          unread
          read
        }
        nodes {
          id
          type
          resource
          timestamp
          actorUserId
          actorTenantId
          createdByWorkflow
          tenant {
            id
            name
          }
          read
          actor {
            username
            avatarImage: avatarUrl
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`

export const getNotificationsTotalsQuery = () => gql`
  query getNotificationsTotals {
    user {
      feedNotifications {
        totals {
          count
          unread
          read
        }
      }
    }
  }
`
