import shallowEqual from 'helpers/shallowEqual'

// just check is data the same as in cache and return it from the cache if it's true
export const memoizeByValue = () => {
  let cache = null

  return data => {
    if (!cache) {
      cache = data
      return data
    }

    if (shallowEqual(cache, data)) {
      return cache
    }

    cache = data
    return data
  }
}

const isEqualByLink = (a, b) => a === b

/**
 * memoize result of fn by checking whether arguments of fn were changed
 * @param {function} fn
 * @param {object} options
 * @param {boolean} options.isShallow - way of comparing arguments
 *
 * @example will return the same filtered array every time when called with arr argument
 * const memoized = memoize(arr => arr.filter(i => i > 1));
 * const arr = [2, 3, 4];
 * memoized(arr);
 */
export const memoize = (fn, { isShallow = false } = {}) => {
  let cached = null
  let prevArgs = []

  return (...args) => {
    // if cache is cold - calculate value and put it in cache
    if (!cached) {
      prevArgs = args
      cached = fn(...args)

      return cached
    }

    const isEqual = isShallow ? shallowEqual : isEqualByLink

    const isArgsChanged = args.some((arg, index) => !isEqual(arg, prevArgs[index]))

    // if any arg is changed - calculate new value and set it in cache
    if (isArgsChanged) {
      prevArgs = args
      cached = fn(...args)

      return cached
    }

    return cached
  }
}
