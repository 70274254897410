import { createReducer } from 'redux-create-reducer'
import * as ActionTypes from '../constants/actionTypes'

export const socket = createReducer(
  {},
  {
    [ActionTypes.TOGGLE_BOARD_SUBSCRIPTION](state, action) {
      return { ...state, isSubscribedToBoard: action.payload }
    },

    [ActionTypes.TOGGLE_COMMENTS_SUBSCRIPTION](state, action) {
      const { isSubscribedToComments, subscribedCard } = action.payload
      return { ...state, isSubscribedToComments, subscribedCard }
    },

    [ActionTypes.TOGGLE_BOARD_WATCHING](state, action) {
      return { ...state, ...action.payload }
    },

    [ActionTypes.BOARD_WATCHERS_UPDATE_SOCKET](state, action) {
      return { ...state, watchingUsers: action.payload }
    },

    [ActionTypes.ADD_NEW_JOB](state, action) {
      return { ...state, jobs: [...state.jobs, action.payload] }
    },

    [ActionTypes.REMOVE_JOB](state, action) {
      return { ...state, jobs: state.jobs.filter(job => job.id !== action.payload.id) }
    },

    [ActionTypes.JOB_COMPLETED_SOCKET](state, action) {
      const { id, data, progress, status, importedContext, error } = action.payload
      const jobs = state.jobs.map(job => {
        if (job.id === id) {
          return {
            ...job,
            data: {
              ...job.data,
              ...data
            },
            importedContext,
            progress,
            status,
            error
          }
        }

        return job
      })

      return { ...state, jobs }
    },

    [ActionTypes.TOGGLE_BOARD_COPYING](state, action) {
      return { ...state, isBoardCopying: action.payload }
    },

    [ActionTypes.TOGGLE_MONITOR_SUBSCRIPTION](state, action) {
      const { isSubscribedToMonitor, subscribedJobs, subscribedMonitor } = action.payload
      return { ...state, isSubscribedToMonitor, subscribedJobs, subscribedMonitor }
    }
  }
)

export default socket
