const auth = {
  isNewUserDataSent: false,
  loginErrorMessage: '',
  registrationErrorMessage: '',

  isResetLinkRequested: false,
  isResetPasswordRequested: false,
  resetLinkErrorMessage: '',

  isPasswordChangedShown: false,
  signedUpEmail: '',
  signedInEmail: '',
  keepLoggedIn: true,

  allOrganizations: []
}

export default auth
