import classNames from 'classnames'
import './progress-bar.scss'

type TProps = {
  progress?: number
  isInfinite?: boolean
}

export const ProgressBar = ({ progress, isInfinite }: TProps) => (
  <div className={classNames('praxie-progress-bar', isInfinite && 'is-infinite')}>
    <div className="bar" style={{ width: progress && `${progress}%` }} />
  </div>
)
