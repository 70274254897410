// @ts-nocheck
/* eslint-disable */
import { DragDropContext } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-mixed-backend'

// https://github.com/react-dnd/react-dnd-html5-backend/issues/7#issuecomment-303016329
// A modified version of the HTML5Backend that prevents the default behavior of the drag event.
// Without this the backend will prevent PragmaticDnD from working.
const ModifiedBackend = (...args: unknown[]) => {
  const instance = new HTML5Backend(...args)
  const original = instance.handleTopDragStart

  instance.handleTopDragStart = (e, ...extraArgs) => {
    e.preventDefault = () => {}
    original(e, ...extraArgs)
  }

  return instance
}

export default DragDropContext(ModifiedBackend)
