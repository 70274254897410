import { ENTITIES, TENANT_TYPE } from '../../constants/schemaConstants'
import { formatId } from '../../common/commonHelpers'

export const getTenantPayload = ({ id }) => {
  const entity = ENTITIES[TENANT_TYPE]
  const alias = `${entity}${formatId(id)}`
  return `${alias}: ${entity}(id: "${id}"){name,tenantId:id`
}

export const getTenantResponse = ({ organization, tenantId }) => {
  const alias = `${ENTITIES[TENANT_TYPE]}${formatId(tenantId)}`
  return organization[alias] || null
}
