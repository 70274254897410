import React from 'react'

import messages from '../../constants/messages'
import DotsSpinner from '../spinners/DotsSpinner'
import { getUniqueKey } from '../../helpers/dataLinkingHelpers'

const DataProvidersList = props => (
  <div className="data-providers-list">
    <h4>{messages.DATA_PROVIDERS}</h4>
    {props.isLoading ? (
      <DotsSpinner color="dark" size="sm" position="center" />
    ) : (
      <div className="data-providers-list-wrapper">
        {props.links.map(link => (
          <a
            key={getUniqueKey(link)}
            className="data-providers-list-item"
            target="_blank"
            rel="noopener noreferrer"
            href={`/board/${link.tenantId}/${link.boardId}/${link.cardUuid}`}
          >
            <i className={`icon icon-${link.widgetClassName}`} />
            <div className="widget-name" title={link.widgetTitle}>
              {link.widgetTitle || messages.DATA_SOURCE_NOT_FOUND}
            </div>
            <div className="data-info">
              <span className="board-name" title={link.boardName}>
                {link.boardName}
              </span>
              <span className="card-name" title={link.cardName}>
                : {link.cardName}
              </span>
            </div>
          </a>
        ))}
      </div>
    )}
  </div>
)

export default DataProvidersList
