import gql from 'graphql-tag'
import VariableFragment from '../fragments/variables/variableFragment'
import VariableConnectionFragment from '../fragments/variables/variableConnectionFragment'

export const variablesQuery = () =>
  gql`
        query getVariables($organizationId: ID!, $tenantId: ID!, $variableIds: [ID!], $filters: VariableFiltersInput, $limit: Int = 20, $after: String) {
            organization(id: $organizationId) {
                tenant(id: $tenantId) {
                    variables(ids: $variableIds, filters: $filters, limit: $limit, after: $after) {
                        nodes {
                            ...${VariableFragment.name}
                        }
                        total
                        filterTotal
                        pageInfo {
                            endCursor
                        }
                    }
                }
            }
        }
        ${VariableFragment.fragment}
    `

export const variableConnectionsQuery = () =>
  gql`
        query getVariableConnections($organizationId: ID!, $tenantId: ID!, $variableId: ID!) {
            organization(id: $organizationId) {
                tenant(id: $tenantId) {
                    variable(id: $variableId) {
                        connections {
                            ...${VariableConnectionFragment.name}
                        }
                    }
                }
            }
        }
        ${VariableConnectionFragment.fragment}
    `
