import { createReducer } from 'redux-create-reducer'
import * as ActionTypes from '../constants/actionTypes'

export const drawer = createReducer(
  {},
  {
    [ActionTypes.TOGGLE_DRAWER](state, action) {
      return { ...state, isDrawerShown: action.payload }
    },

    [ActionTypes.GET_DRAWER_CARDS_REQUEST](state) {
      return { ...state, isDrawerCardsRequested: true }
    },

    [ActionTypes.GET_DRAWER_CARDS_RECEIVE](state, action) {
      return {
        ...state,
        drawerCards: action.payload,
        isDrawerCardsRequested: false,
        paginationEnd: false
      }
    },

    [ActionTypes.GET_SEARCH_DRAWER_CARDS_RECEIVE](state, action) {
      const { cards, limit, offset } = action.payload
      const drawerCards = offset ? state.drawerCards.concat(cards) : cards
      return {
        ...state,
        drawerCards,
        isDrawerCardsRequested: false,
        paginationEnd: limit ? cards.length < limit : false
      }
    },

    [ActionTypes.GET_DRAWER_CARDS_RECEIVE_ERROR](state) {
      return { ...state, isDrawerCardsRequested: false }
    },

    [ActionTypes.TOGGLE_DRAWER_PAGINATION_END](state, action) {
      return { ...state, paginationEnd: action.payload }
    },

    [ActionTypes.TOGGLE_DRAWER_DRAG](state, action) {
      return { ...state, isDrawerDragDisabled: action.payload }
    },

    [ActionTypes.TOGGLE_DRAWER_CARD_PREVIEW](state, action) {
      return {
        ...state,
        drawerPreviewData: {
          state: action.payload ? action.payload.state : false,
          cardUuid: action.payload ? action.payload.cardUuid : null,
          boardId: action.payload ? action.payload.boardId : null,
          tenantId: action.payload ? action.payload.tenantId : null
        }
      }
    }
  }
)

export default drawer
