import AppIcon from 'assets/images/icons/ic_app_20_6c7e8a.svg?react'
import messages from 'constants/messages'
import { APP_DOWNLOAD_TEMPLATE_STATUS } from 'constants/appBuilder/appBuilderConstants'
import CircleSpinner from 'components/spinners/CircleSpinner'

type TProps = {
  download: {
    fileName: string
    status: string
  }
}

export const DownloadAppTemplateIndicatorItem = ({ download }: TProps) => (
  <div className="board_item">
    <div className="board_data">
      <AppIcon />
      <div className="board_content">
        <span className="board_name bold">{download.fileName}</span>
        {download.status === APP_DOWNLOAD_TEMPLATE_STATUS.FAILED && (
          <span className="error-text">{messages.OPERATION_FAILED}</span>
        )}
      </div>
    </div>
    <span className="board_progress">
      {download.status !== APP_DOWNLOAD_TEMPLATE_STATUS.FAILED &&
        download.status !== APP_DOWNLOAD_TEMPLATE_STATUS.DONE && (
          <CircleSpinner className="copied-board__spinner" />
        )}
      {download.status === APP_DOWNLOAD_TEMPLATE_STATUS.DONE && (
        <span className="blue-link static">{messages.TEMPLATE_DOWNLOADED}</span>
      )}
    </span>
  </div>
)
