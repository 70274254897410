import { requestWrapper, url } from '../helpers/fetchHelpers'
import graphqlRequestWrapper from '../graphql/common/requestHelpers'
import {
  getNotificationsTotalsQuery,
  getNotificationsListQuery
} from '../graphql/queries/notificationsQueries'
import {
  getNotificationsList,
  getNotificationsTotals
} from '../graphql/dataExtractors/notificationsExtractors'

const NOTIFICATIONS_LIMIT = 15

// GET /notifications
export function processNotificationsRequest({ cursor, first = NOTIFICATIONS_LIMIT }) {
  const variables = { first }
  if (cursor) {
    variables.after = cursor
  }
  return graphqlRequestWrapper({
    query: getNotificationsListQuery(),
    variables
  }).then(response => getNotificationsList(response))
}

export function processNotificationsTotalsRequest() {
  return graphqlRequestWrapper({
    query: getNotificationsTotalsQuery()
  }).then(response => getNotificationsTotals(response))
}

export function processNotificationsRead(payload) {
  return requestWrapper({
    rUrl: `${url}notifications/read`,
    type: 'PUT',
    payload: payload.notificationsList
  })
}
