import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { EWorkflowBlockTypes } from 'constants/workflowBuilder/blocksTypes'
import { OUTPUT_NAMES } from 'constants/workflows'
import { metaToStaticConnectionMapper as widgetDataBlocksMappers } from 'helpers/workflowWidgetDataBlock/workflowWidgetDataBlockMapper'
import { metaToStaticConnectionMapper as moveCardBlockMappers } from 'helpers/workflowMoveCardBlock/moveCardBlockMappers'
import { metaToStaticConnectionMapper as hideCardSectionMappers } from 'helpers/workflowHideCardSectionBlock/workflowHideCardSectionBlockMappers'
import { metaToStaticConnectionMapper as defineStringBlocksMappers } from 'helpers/workflowDefineStringBlocks/defineStringBlocksMapper'
import { metaToStaticConnectionMapper as systemActionsMappers } from 'helpers/workflowSystemActions/systemActionsMappers'

const getStaticInputs = inputDefinition =>
  Object.values(inputDefinition).filter(input => input.isStatic)

const {
  MOVE_CARD,
  TRIGGER,
  SEND_EMAIL,
  HIDE_CARD_SECTION,
  DEFINE_STRING_FROM_BOARD,
  DEFINE_STRING_FROM_WIDGET,
  DEFINE_STRING_FROM_CARD,
  DEFINE_STRING_FROM_USER,
  DEFINE_STRING_FROM_COLUMN,
  DEFINE_STRING_FROM_JSON_PAYLOAD,
  PUBLISH_FEED_NOTIFICATION,
  GET_WIDGET_DATA,
  UPDATE_WIDGET_DATA
} = EWorkflowBlockTypes

// some blocks may require data transforming when mapping from meta to static connection
const getMappedStaticConnectionData = (block, inputName) => {
  switch (block.type) {
    case MOVE_CARD: {
      return moveCardBlockMappers(block, inputName)
    }
    case HIDE_CARD_SECTION: {
      return hideCardSectionMappers(block, inputName)
    }
    case DEFINE_STRING_FROM_BOARD:
    case DEFINE_STRING_FROM_CARD:
    case DEFINE_STRING_FROM_WIDGET:
    case DEFINE_STRING_FROM_USER:
    case DEFINE_STRING_FROM_COLUMN:
    case DEFINE_STRING_FROM_JSON_PAYLOAD:
      return defineStringBlocksMappers(block, inputName)
    case GET_WIDGET_DATA:
    case UPDATE_WIDGET_DATA:
      return widgetDataBlocksMappers(block, inputName)
    case SEND_EMAIL: {
      return systemActionsMappers(block, inputName, EFieldKeys.NAME)
    }
    case PUBLISH_FEED_NOTIFICATION: {
      return systemActionsMappers(block, inputName, EFieldKeys.USER_INPUT)
    }
    default: {
      return block.meta[inputName] || null
    }
  }
}

// get one way connection from internal inputs of block
const getStaticConnectionsFromBlock = block => {
  const staticInputs = getStaticInputs(block.inputDefinition)

  return staticInputs.map(input => ({
    data: getMappedStaticConnectionData(block, input.inputName),
    tgt: {
      process: block.id,
      port: input.inputName
    },
    metadata: {}
  }))
}

const getSource = (blockId, output, workflowBlocksMap, outputsHistory) => {
  // history contains only original block (at first placed) and all modifying block
  const history = outputsHistory[output.id]

  // if output has less than 1 item in history - it wasn't changed by any modifying block
  if (history.length <= 1) {
    return {
      process: output.blockId,
      port: output.outputName
    }
  }

  // otherwise need to find in history block which placed above current
  // it will be last block(before current) were output was modified
  const blockIndex = workflowBlocksMap[blockId].index

  // find the LATEST block whose index is less then index of current block
  const prevBlockId = history
    .slice(0)
    .reverse()
    .find(id => workflowBlocksMap[id].index < blockIndex)

  return {
    process: prevBlockId,
    // for now all modifying blocks has OUTPUT_NAMES.OUT output name.
    // When it would be changed - need to map output name from block
    port: prevBlockId === output.blockId ? output.outputName : OUTPUT_NAMES.OUT
  }
}

// get two way connection from each external inputs of block
const getConnectionsFromBlock = (block, workflowBlocksMap, outputsMap, outputsHistory) =>
  Object.entries(block.input).reduce((acc, [inputName, outputId]) => {
    const inputDefinition = block.inputDefinition[inputName]

    const output = outputsMap[inputDefinition.type].find(item => item.id === outputId)

    // skip empty inputs
    if (!output) {
      return acc
    }

    acc.push({
      src: getSource(block.id, output, workflowBlocksMap, outputsHistory),
      tgt: {
        process: block.id,
        port: inputName
      },
      metadata: {
        outputId: output.id
      }
    })

    return acc
  }, [])

// from block to component
const blockMapper = block => ({
  component: block.type,
  metadata: {
    id: block.id,
    input: block.input,
    meta: block.meta,
    output: block.output,
    x: block.index,
    y: 0
  }
})

// from trigger block to flow trigger
const triggerBlockMapper = block => ({
  triggerId: block.id,
  filterUuid: block.meta.filterUuid,
  eventType: block.meta.eventType,
  eventObject: block.meta.eventObject,
  metadata: {
    output: block.output
  },
  scheduleSettings: block.scheduleSettings
})

export default class StateToFlowMapper {
  constructor() {
    this.workflow = {}
    this.processes = {}
    this.connections = []
  }

  setWorkflow(workflowDefinition) {
    this.workflow = workflowDefinition
  }

  setTrigger(trigger) {
    this.workflow = { ...this.workflow, ...triggerBlockMapper(trigger) }
  }

  getData() {
    return {
      workflow: this.workflow,
      flow: {
        processes: this.processes,
        connections: this.connections
      }
    }
  }

  convert(state) {
    const { workflowDefinition, workflowBlocksMap, outputs, workflowBlocks, outputsHistory } = state

    this.setWorkflow(workflowDefinition)

    workflowBlocks.forEach(blockId => {
      const block = workflowBlocksMap[blockId]

      if (block.type === TRIGGER) {
        this.setTrigger(block)
      }

      this.processes[block.id] = blockMapper(block)

      this.connections = [
        ...this.connections,
        ...getStaticConnectionsFromBlock(block),
        ...getConnectionsFromBlock(block, workflowBlocksMap, outputs, outputsHistory)
      ]
    })

    return this.getData()
  }
}
