import { createReducer } from 'redux-create-reducer'
import unionBy from 'lodash/unionBy'
import {
  bulkWidgetsCreate,
  bulkWidgetsDelete,
  bulkWidgetsUpdate,
  findCardIndexByID,
  setCardLockOwner,
  updateCardLinks,
  createWidgetOnCard
} from 'helpers/board/boardOperations'
import { makeObjectFromArray } from 'helpers/common/commonHelpers'
import {
  RENAME_BOARD_TOGGLE,
  TOGGLE_RENAME_BOARD_MODAL,
  TOGGLE_CARD_TITLE_EDITING,
  RECEIVE_BOARD,
  RECEIVE_UPDATE_BOARD,
  CLEAR_CURRENT_BOARD,
  SET_BOARD_CARDS,
  SET_BOARD_CARDS_COMMENTS_COUNT,
  ADD_NEW_CARDS,
  RELOAD_BOARD_CARDS,
  APPLY_CARDS_OPERATIONS,
  TOGGLE_BOARD_CREATION_MODAL,
  RECEIVE_COLUMN_UPDATE,
  CLEAR_CARD_SNAPSHOT,
  REQUEST_DELETE_CARD,
  RECEIVE_DELETE_CARD,
  RECEIVE_DELETE_CARD_ERROR,
  TOGGLE_BOARD_COPY_MODAL,
  TOGGLE_BOARD_DELETE_MODAL,
  ADD_TOAST_MESSAGE,
  TOGGLE_TOAST_MESSAGE_VISIBLE,
  HIDE_TOAST_MESSAGE,
  TOGGLE_COLUMN_DELETE_MODAL,
  TOGGLE_CARD_DELETE_MODAL,
  RECEIVE_BOARD_PERMISSIONS,
  RECEIVE_WIDGET_UPDATE_ON_DETAILED_VIEW,
  RECEIVE_WIDGET_CREATE_ON_DETAILED_VIEW,
  RECEIVE_WIDGET_DELETE_ON_DETAILED_VIEW,
  REQUEST_BOARD_SETTINGS,
  RECEIVE_BOARD_SETTINGS,
  RECEIVE_BOARD_SETTINGS_ERROR,
  SET_CARDS_POSITION_ETAG,
  SET_BOARD_THIRD_PARTY_WIDGETS,
  UPDATE_CARD_OWNERS,
  ADD_CARD_PARTICIPANTS,
  REMOVE_CARD_PARTICIPANTS,
  ADD_CARD_COMMENT,
  SET_CARD_COMMENTS_LIST_OFFSET,
  SET_CARD_COMMENTS_LOADED,
  SET_CARD_COMMENTS,
  EDIT_CARD_COMMENT,
  DELETE_CARD_COMMENT,
  CLEAN_UP_CARD_COMMENTS,
  CARD_CREATE_SOCKET,
  CARD_UPDATE_IN_CARD_BUILDER_SOCKET,
  CARD_UPDATE_IN_BOARD_VIEW_SOCKET,
  CARD_REORDER_SOCKET,
  COLUMN_CREATE_SOCKET,
  CARD_SNAPSHOT_UPDATE_SOCKET,
  CARD_DELETE_SOCKET,
  BOARD_UPDATE_SOCKET,
  CARD_START_EDIT_SOCKET,
  CARD_END_EDIT_SOCKET,
  WIDGET_UPDATE_SOCKET,
  BULK_WIDGETS_CREATE_SOCKET,
  WIDGET_DELETE_SOCKET,
  DISABLE_BOARD_DRAG,
  TOGGLE_BOARDS_EXPORT_MODAL,
  TOGGLE_TENANT_CREATION_MODAL,
  TOGGLE_WELCOME_CARD_PREVIEW,
  SET_BOARD_LOADING_ERROR,
  SET_BOARD_CARDS_WIDGETS,
  UPDATE_BOARD_CARDS_WIDGETS,
  APPEND_CARD_WIDGETS,
  TOGGLE_BOARD_WIDGETS_LOADING
} from '../constants/actionTypes'

export const board = createReducer(
  {},
  {
    [RENAME_BOARD_TOGGLE](state, action) {
      return {
        ...state,
        isRenameBoard: action.payload
      }
    },

    [TOGGLE_RENAME_BOARD_MODAL](state, action) {
      return {
        ...state,
        renameBoardModalData: {
          state: action.payload ? action.payload.state : false,
          tenantId: action.payload ? action.payload.tenantId : null,
          boardId: action.payload ? action.payload.boardId : null,
          name: action.payload ? action.payload.name : ''
        }
      }
    },

    [TOGGLE_CARD_TITLE_EDITING](state, action) {
      const {
        currentBoard: { boardPermissions }
      } = state

      const canUpdateCard = boardPermissions && boardPermissions.renameCard

      return {
        ...state,
        renamingCardUuid: canUpdateCard ? action.payload : null
      }
    },

    [RECEIVE_BOARD](state, action) {
      return {
        ...state,
        currentBoard: { ...state.currentBoard, ...action.payload }
      }
    },

    [RECEIVE_UPDATE_BOARD](state, action) {
      return {
        ...state,
        currentBoard: { ...state.currentBoard, ...action.payload }
      }
    },

    [CLEAR_CURRENT_BOARD](state) {
      return {
        ...state,
        currentBoard: {
          boardPermissions: {}
        }
      }
    },

    [SET_BOARD_CARDS](state, action) {
      return {
        ...state,
        // Remove comments count. Will be fetched and set later by `fetchCardsCommentsCount`.
        cards: action.payload.map(card => ({ ...card, commentsNumber: null }))
      }
    },

    [SET_BOARD_CARDS_COMMENTS_COUNT](state, action) {
      const cardsCommentsCount = action.payload

      const cards = state.cards.reduce((result, card) => {
        const commentsNumber = cardsCommentsCount[card.uuid]

        if (commentsNumber !== undefined) {
          result.push({ ...card, commentsNumber })
        } else {
          result.push(card)
        }

        return result
      }, [])

      return { ...state, cards }
    },

    [ADD_NEW_CARDS](state, action) {
      const cardsMap = makeObjectFromArray(state.cards, card => card.uuid)

      const { cards: newCards, widgets: newWidgets } = action.payload.reduce(
        (acc, { widgets, ...card }) => {
          // filter cards from payload because they can be already stored
          // by  "socket/CARD_CREATE" socket event
          // to fix https://leverxeu.atlassian.net/browse/EUPBOARD01-4087
          if (cardsMap[card.uuid]) {
            return acc
          }

          acc.cards.push(card)
          acc.widgets[card.uuid] = widgets || []

          return acc
        },
        { cards: [], widgets: {} }
      )

      const cards = [...state.cards, ...newCards]
      const widgetsMap = { ...state.widgetsMap, ...newWidgets }

      return { ...state, cards, widgetsMap }
    },

    [RELOAD_BOARD_CARDS](state) {
      const cards = state.cards.map((card, index) => {
        if (index === 0) {
          return {
            ...card,
            cardUpdateDate: Date.now()
          }
        }

        return card
      })

      // TODO fix same case for empty board

      return { ...state, cards }
    },

    [APPLY_CARDS_OPERATIONS](state, action) {
      const operations = action.payload

      const cards = state.cards.reduce((acc, card) => {
        if (operations.delete && operations.delete.uuid === card.uuid) {
          return acc
        }

        if (operations.update && operations.update.uuid === card.uuid) {
          acc.push(operations.update)
          return acc
        }

        acc.push(card)
        return acc
      }, [])

      if (operations.create) {
        cards.push(operations.create)
      }

      return {
        ...state,
        cards
      }
    },

    [TOGGLE_BOARD_CREATION_MODAL](state, action) {
      if (action.payload === false) {
        return { ...state, boardCreationData: { state: false, tenantId: null, gaLabel: null } }
      }
      return { ...state, boardCreationData: action.payload }
    },

    [RECEIVE_COLUMN_UPDATE](state, action) {
      const columnCardIndex = findCardIndexByID(state.cards, action.payload.uuid)
      const cards = state.cards.slice(0)

      if (columnCardIndex !== -1) {
        const card = cards[columnCardIndex]
        // response of PUT on card don't expand entities listed below
        // - so we should preserve it from previous card
        const { lockOwner, participants, owners } = card

        cards.splice(columnCardIndex, 1, {
          ...card,
          ...action.payload,
          lockOwner,
          participants,
          owners
        })
      }

      return { ...state, cards }
    },

    [CLEAR_CARD_SNAPSHOT](state, action) {
      const cards = state.cards.map(card => {
        const isCurrentCard = card.uuid === action.payload.uuid
        const isCardLink = card.isLinked && card.linkToSrcCard.cardUuid === action.payload.uuid

        // clear snapshot on all card-links as well
        if (isCurrentCard || isCardLink) {
          return {
            ...card,
            snapshot: null
          }
        }

        return card
      })

      return { ...state, cards }
    },

    [REQUEST_DELETE_CARD](state) {
      return { ...state, isDeleteCardRequested: true }
    },

    [RECEIVE_DELETE_CARD](state, action) {
      const { currentBoard, cards, widgetsMap } = state

      // Clean up a welcome card item in a board context menu after removing the card.
      // EUPBOARD01-6882
      let welcomeCard =
        currentBoard.welcomeCard === action.payload.cardUuid ? null : currentBoard.welcomeCard
      const deletedCards = action.payload.isColumnDeleting ? [] : [action.payload.cardUuid]

      const newCards = action.payload.isColumnDeleting
        ? cards.filter(card => {
            const isDeletedColumn = card.uuid === action.payload.cardUuid
            // if is column deleting - also needs to delete card within this column
            const isCardInDeletedColumn = card.columnUuid === action.payload.cardUuid

            if (welcomeCard && isCardInDeletedColumn && welcomeCard === card.uuid) {
              welcomeCard = null
            }

            if (isDeletedColumn || isCardInDeletedColumn) {
              deletedCards.push(card.uuid)
            }

            return !isDeletedColumn && !isCardInDeletedColumn
          })
        : cards.filter(card => card.uuid !== action.payload.cardUuid)

      const newWidgetsMap = { ...widgetsMap }

      deletedCards.forEach(cardUuid => {
        delete newWidgetsMap[cardUuid]
      })

      return {
        ...state,
        currentBoard: { ...currentBoard, welcomeCard },
        isDeleteCardRequested: false,
        cards: newCards,
        widgetsMap: newWidgetsMap
      }
    },

    [RECEIVE_DELETE_CARD_ERROR](state) {
      return {
        ...state,
        isDeleteCardRequested: false
      }
    },

    [TOGGLE_BOARD_COPY_MODAL](state, action) {
      return {
        ...state,
        boardCopyData: action.payload || {
          state: false,
          tenantId: null,
          boardId: null,
          name: ''
        }
      }
    },

    [TOGGLE_BOARD_DELETE_MODAL](state, action) {
      return {
        ...state,
        boardDeleteData: action.payload || {
          state: false,
          tenantId: null,
          boardId: null,
          name: '',
          goToRecentBoard: false
        }
      }
    },

    [ADD_TOAST_MESSAGE](state, action) {
      const toastMessages = state.toastMessages.slice()
      toastMessages.push(action.payload)
      return {
        ...state,
        toastMessages
      }
    },

    [TOGGLE_TOAST_MESSAGE_VISIBLE](state, action) {
      const toastMessages = state.toastMessages.slice()
      const toastMessage = toastMessages.filter(message => message.id === action.payload.id)[0]

      if (toastMessage) {
        toastMessage.visible = action.payload.visible
      }

      return {
        ...state,
        toastMessages
      }
    },

    [HIDE_TOAST_MESSAGE](state, action) {
      const toastMessages = state.toastMessages.slice()
      const index = toastMessages.findIndex(message => message.id === action.payload)
      if (index > -1) {
        toastMessages.splice(index, 1)
      }
      return {
        ...state,
        toastMessages
      }
    },

    [TOGGLE_COLUMN_DELETE_MODAL](state, action) {
      return {
        ...state,
        isColumnDeleteModalShown: action.payload.state,
        deletingCardData: action.payload.data
      }
    },

    [TOGGLE_CARD_DELETE_MODAL](state, action) {
      return {
        ...state,
        isCardDeleteModalShown: action.payload.state,
        deletingCardData: action.payload.data
      }
    },

    [RECEIVE_BOARD_PERMISSIONS](state, action) {
      const { currentBoard } = state

      return {
        ...state,
        currentBoard: {
          ...currentBoard,
          boardPermissions: action.payload.permissions || {},
          role: action.payload.role || 'PublicBoardViewer'
        }
      }
    },

    [RECEIVE_WIDGET_UPDATE_ON_DETAILED_VIEW](state, action) {
      const widgetsMap = { ...state.widgetsMap }

      const widgets = widgetsMap[action.payload.cardID]

      if (!widgets) {
        return state
      }

      widgetsMap[action.payload.cardID] = createWidgetOnCard({
        widgets,
        widget: action.payload.data[0]
      })

      return { ...state, widgetsMap }
    },

    [RECEIVE_WIDGET_CREATE_ON_DETAILED_VIEW](state, action) {
      const widgetsMap = { ...state.widgetsMap }

      const widgets = widgetsMap[action.payload.cardID]

      if (!widgets) {
        return state
      }

      const widgetsUuidMap = widgets.reduce((acc, widget) => {
        acc[widget.uuid] = true
        return acc
      }, {})

      // filter out widget which already were created by socket BULK_WIDGETS_CREATE socket message
      // https://leverxeu.atlassian.net/browse/EUPBOARD01-7539
      const newWidgets = action.payload.data.filter(widget => !widgetsUuidMap[widget.uuid])

      widgetsMap[action.payload.cardID] = [...widgets, ...newWidgets]

      return { ...state, widgetsMap }
    },

    [RECEIVE_WIDGET_DELETE_ON_DETAILED_VIEW](state, action) {
      const widgetsMap = { ...state.widgetsMap }

      const widgets = widgetsMap[action.payload.cardID]

      if (!widgets) {
        return state
      }

      widgetsMap[action.payload.cardID] = widgets.filter(
        widget => widget.uuid !== action.payload.data
      )

      return { ...state, widgetsMap }
    },

    [REQUEST_BOARD_SETTINGS](state) {
      return {
        ...state,
        isBoardSettingsRequested: true
      }
    },

    [RECEIVE_BOARD_SETTINGS](state, action) {
      return {
        ...state,
        isBoardSettingsRequested: false,
        boardSettings: action.payload
      }
    },

    [RECEIVE_BOARD_SETTINGS_ERROR](state) {
      return {
        ...state,
        isBoardSettingsRequested: false,
        boardSettings: {}
      }
    },

    [SET_CARDS_POSITION_ETAG](state, action) {
      return { ...state, cardsPositionsETag: action.payload }
    },

    [SET_BOARD_THIRD_PARTY_WIDGETS](state, action) {
      return { ...state, thirdPartyWidgets: action.payload }
    },

    [UPDATE_CARD_OWNERS](state, action) {
      const columnCardIndex = findCardIndexByID(state.cards, action.payload.cardID)
      const cards = state.cards.slice(0)

      if (columnCardIndex !== -1) {
        const card = cards[columnCardIndex]
        const owners = [...action.payload.data]

        cards.splice(columnCardIndex, 1, {
          ...card,
          owners
        })
      }

      return { ...state, cards }
    },

    [ADD_CARD_PARTICIPANTS](state, action) {
      const columnCardIndex = findCardIndexByID(state.cards, action.payload.cardUUID)
      const cards = state.cards.slice(0)

      if (columnCardIndex !== -1) {
        const card = cards[columnCardIndex]
        const participants = [...(card.participants || []), ...action.payload.data]

        cards.splice(columnCardIndex, 1, {
          ...card,
          participants
        })
      }

      return { ...state, cards }
    },

    [REMOVE_CARD_PARTICIPANTS](state, action) {
      const columnCardIndex = findCardIndexByID(state.cards, action.payload.cardUUID)
      const cards = state.cards.slice(0)

      if (columnCardIndex !== -1) {
        const card = cards[columnCardIndex]
        const participants = (card.participants || []).filter(
          participant => !action.payload.data.includes(participant)
        )

        cards.splice(columnCardIndex, 1, {
          ...card,
          participants
        })
      }

      return { ...state, cards }
    },

    [ADD_CARD_COMMENT](state, action) {
      const { disableCountUpdate, ...newComment } = action.payload
      const cards = [...state.cards]

      const commentExists = item => item.commentId === newComment.commentId

      if (!action.payload.parentCommentId) {
        const comments = [...(state.comments || []), newComment]
        const isCommentExists = state.comments.some(commentExists)

        if (!isCommentExists) {
          return { ...state, comments, cards }
        }
        return { ...state }
      }
      const comments = [...state.comments]
      let isCommentExists = false

      const oldComment = comments.find(item => item.commentId === action.payload.parentCommentId)
      if (oldComment.replies && oldComment.replies.length) {
        isCommentExists = oldComment.replies.some(commentExists)
      }
      if (!isCommentExists) {
        const comment = {
          ...oldComment,
          replies: [...(oldComment.replies || []), action.payload]
        }

        const commentIndex = comments.indexOf(oldComment)
        comments.splice(commentIndex, 1, comment)

        return { ...state, comments, cards }
      }
      return { ...state }
    },
    [SET_CARD_COMMENTS_LIST_OFFSET](state, action) {
      return { ...state, commentsListOffset: action.payload }
    },

    [SET_CARD_COMMENTS_LOADED](state, action) {
      return { ...state, areAllCommentsLoaded: action.payload }
    },

    [SET_CARD_COMMENTS](state, action) {
      const initialComments = action.payload
      initialComments.forEach(comment => {
        if (!comment.username) {
          comment.username = 'Unknown'
        }
      })
      const parentsComments = initialComments.filter(comment => !comment.parentCommentId)
      const replyComments = initialComments.filter(comment => comment.parentCommentId)

      if (replyComments.length) {
        replyComments.forEach(replyComment => {
          parentsComments.forEach(parentComment => {
            if (replyComment.parentCommentId === parentComment.commentId) {
              if (parentComment.replies) {
                parentComment.replies.push(replyComment)
              } else {
                parentComment.replies = [replyComment]
              }
            }
          })
        })
      }

      return { ...state, comments: parentsComments }
    },

    [EDIT_CARD_COMMENT](state, action) {
      const comments = [...state.comments]

      if (!action.payload.parentCommentId) {
        let commentIndex = -1
        comments.forEach((comment, index) => {
          if (
            comment.commentId === action.payload.commentId &&
            comment.text !== action.payload.text
          ) {
            commentIndex = index
          }
        })

        if (commentIndex > -1) {
          const oldComment = comments[commentIndex]
          const comment = {
            ...oldComment,
            text: action.payload.text,
            mentionedUsers: action.payload.mentionedUsers
          }
          comments.splice(commentIndex, 1, comment)

          return { ...state, comments }
        }
        return { ...state }
      }
      const oldComment = comments.find(item => item.commentId === action.payload.parentCommentId)

      const replies = [...oldComment.replies]
      const oldReply = replies.find(item => item.commentId === action.payload.commentId)

      if (oldReply.text !== action.payload.text) {
        const replyIndex = replies.indexOf(oldReply)

        const reply = {
          ...oldReply,
          text: action.payload.text,
          mentionedUsers: action.payload.mentionedUsers
        }

        replies.splice(replyIndex, 1, reply)

        const comment = {
          ...oldComment,
          replies
        }
        const commentIndex = comments.indexOf(oldComment)
        comments.splice(commentIndex, 1, comment)

        return { ...state, comments }
      }
      return { ...state }
    },

    [DELETE_CARD_COMMENT](state, action) {
      const { comments, cards } = state
      const { cardUUID: cardUuid, commentId, parentCommentId } = action.payload

      let deletedCommentsCount = 0

      const newComments = comments.reduce((acc, comment) => {
        // delete comment with replies
        if (comment.commentId === commentId) {
          deletedCommentsCount = 1 + (comment?.replies?.length ?? 0)

          return acc
        }

        // delete reply
        if (comment.commentId === parentCommentId) {
          acc.push({
            ...comment,
            replies: comment.replies.filter(reply => reply.commentId !== commentId)
          })

          deletedCommentsCount = 1

          return acc
        }

        acc.push(comment)

        return acc
      }, [])

      const newCards = cards.map(card => {
        if (card.uuid === cardUuid) {
          return {
            ...card,
            commentsNumber: card.commentsNumber - deletedCommentsCount
          }
        }

        return card
      })

      return { ...state, comments: newComments, cards: newCards }
    },

    [CLEAN_UP_CARD_COMMENTS](state) {
      const comments = []
      return { ...state, comments, commentsListOffset: '', areAllCommentsLoaded: false }
    },

    [CARD_CREATE_SOCKET](state, action) {
      const cards = state.cards.slice(0)
      const widgetsMap = { ...state.widgetsMap }

      const cardIndex = findCardIndexByID(state.cards, action.payload.uuid)
      if (cardIndex === -1) {
        const { widgets, ...card } = action.payload

        cards.push(card)
        widgetsMap[card.uuid] = widgets || []
      }

      const updatedCards = cards.map(card =>
        card.uuid === action.payload.uuid
          ? { ...card, workflowConnections: action.payload.workflowConnections }
          : card
      )

      return { ...state, cards: updatedCards }
    },

    [CARD_UPDATE_IN_CARD_BUILDER_SOCKET](state, action) {
      const cards = state.cards.slice(0)
      const widgetsMap = { ...state.widgetsMap }

      const cardIndex = findCardIndexByID(state.cards, action.payload.uuid)
      if (cardIndex !== -1) {
        const { widgets, ...card } = action.payload

        const { commentsNumber } = cards[cardIndex]

        cards.splice(cardIndex, 1, { ...card, commentsNumber })
        widgetsMap[action.payload.uuid] = widgets || []
      }

      const { cards: newCards, widgetsMap: newWidgetsMap } = updateCardLinks({
        cardUuid: action.payload.uuid,
        cards,
        widgetsMap,
        cardModifyFn: linkedCard => ({
          ...linkedCard,
          isDividersShown: action.payload.isDividersShown,
          showCardThumbnail: action.payload.showCardThumbnail,
          height: action.payload.height,
          snapshot: action.payload.snapshot,
          hiddenSections: action.payload.hiddenSections
        }),
        widgetsModifyFn: () => [...action.payload.widgets]
      })

      return { ...state, cards: newCards, widgetsMap: newWidgetsMap }
    },

    [CARD_UPDATE_IN_BOARD_VIEW_SOCKET](state, action) {
      const cards = state.cards.slice(0)

      const cardIndex = findCardIndexByID(state.cards, action.payload.uuid)
      if (cardIndex !== -1) {
        const { commentsNumber } = cards[cardIndex]
        cards.splice(cardIndex, 1, {
          ...action.payload,
          commentsNumber
        })
      }

      const { cards: newCards } = updateCardLinks({
        cardUuid: action.payload.uuid,
        cards,
        cardModifyFn: linkedCard => ({
          ...linkedCard,
          isDividersShown: action.payload.isDividersShown,
          showCardThumbnail: action.payload.showCardThumbnail,
          height: action.payload.height,
          snapshot: action.payload.snapshot,
          hiddenSections: action.payload.hiddenSections
        })
      })

      return { ...state, cards: newCards }
    },

    [CARD_REORDER_SOCKET](state, action) {
      const cards = state.cards.slice(0)
      const cardIndex = findCardIndexByID(state.cards, action.payload.card.uuid)

      if (cardIndex !== -1) {
        const card = cards[cardIndex]
        const { lockOwner, owners, participants, commentsNumber, snapshot } = card

        if (action.payload.etag && action.payload.etag !== state.cardsPositionsETag.toString()) {
          return state
        }

        cards.splice(cardIndex, 1, {
          ...action.payload.card,
          lockOwner,
          owners,
          participants,
          commentsNumber,
          snapshot
        })
      }

      return { ...state, cards }
    },

    [COLUMN_CREATE_SOCKET](state, action) {
      const cards = state.cards.slice(0)
      const cardIndex = findCardIndexByID(state.cards, action.payload.uuid)
      if (cardIndex === -1) {
        cards.push({
          ...action.payload,
          lockOwner: null,
          owners: []
        })
      }
      return { ...state, cards }
    },

    [CARD_SNAPSHOT_UPDATE_SOCKET](state, action) {
      const cards = state.cards.map(card => {
        const isCurrentCard = card.uuid === action.payload.cardUuid
        const isCardLink = card.isLinked && card.linkToSrcCard.cardUuid === action.payload.cardUuid

        // update snapshot on all card-links as well
        if (isCurrentCard || isCardLink) {
          return {
            ...card,
            snapshot: action.payload.snapshot
          }
        }

        return card
      })

      return { ...state, cards }
    },

    [CARD_DELETE_SOCKET](state, action) {
      const cards = state.cards.slice(0)
      const widgetsMap = { ...state.widgetsMap }

      const cardIndex = findCardIndexByID(state.cards, action.payload.cardUuid)
      if (cardIndex !== -1) {
        cards.splice(cardIndex, 1)
        delete widgetsMap[action.payload.cardUuid]
      }

      const { cards: newCards, widgetsMap: newWidgetsMap } = updateCardLinks({
        cardUuid: action.payload.cardUuid,
        cards,
        widgetsMap,
        cardModifyFn: linkedCard => ({
          ...linkedCard,
          errorCode: 404,
          snapshot: null,
          showCardThumbnail: false
        }),
        widgetsModifyFn: () => []
      })

      return { ...state, cards: newCards, widgetsMap: newWidgetsMap }
    },

    [BOARD_UPDATE_SOCKET](state, action) {
      const currentBoard = { ...state.currentBoard, ...action.payload }
      return { ...state, currentBoard }
    },

    [CARD_START_EDIT_SOCKET](state, action) {
      const cards = setCardLockOwner({
        cards: state.cards,
        cardUuid: action.payload.cardUuid,
        lockOwner: {
          id: action.payload.id,
          username: action.payload.username,
          email: action.payload.email
        }
      })

      return { ...state, cards }
    },

    [CARD_END_EDIT_SOCKET](state, action) {
      const cards = setCardLockOwner({
        cards: state.cards,
        cardUuid: action.payload.cardUuid,
        lockOwner: null
      })

      return { ...state, cards }
    },

    [WIDGET_UPDATE_SOCKET](state, action) {
      if (!action.payload.data) {
        return state
      }

      const widgetsMap = bulkWidgetsUpdate({
        cardUuid: action.payload.data.cardUuid,
        cards: state.cards,
        widgetsMap: state.widgetsMap,
        widgets: [action.payload.data]
      })

      return { ...state, widgetsMap }
    },

    [BULK_WIDGETS_CREATE_SOCKET](state, action) {
      const { data } = action.payload
      if (!data || !Array.isArray(data) || !data.length) {
        return state
      }

      const widgetsMap = bulkWidgetsCreate({
        cardUuid: data[0].cardUuid,
        cards: state.cards,
        widgetsMap: state.widgetsMap,
        widgets: data
      })

      return { ...state, widgetsMap }
    },

    [WIDGET_DELETE_SOCKET](state, action) {
      if (!action.payload.data) {
        return state
      }

      const widgetsMap = bulkWidgetsDelete({
        cardUuid: action.payload.data.cardUuid,
        cards: state.cards,
        widgetsMap: state.widgetsMap,
        widgets: [action.payload.data]
      })

      return { ...state, widgetsMap }
    },

    [DISABLE_BOARD_DRAG](state, action) {
      return { ...state, isBoardDragDisabled: action.payload }
    },

    [TOGGLE_BOARDS_EXPORT_MODAL](state, action) {
      return {
        ...state,
        exportBoardsModalData: {
          state: action.payload ? action.payload.state : false,
          tenantId: action.payload ? action.payload.tenantId : null
        }
      }
    },

    [TOGGLE_TENANT_CREATION_MODAL](state, action) {
      return { ...state, isTenantCreationShown: action.payload }
    },

    [TOGGLE_WELCOME_CARD_PREVIEW](state, action) {
      return {
        ...state,
        welcomeCardData: {
          state: action.payload ? action.payload.state : false,
          showFooter: action.payload ? action.payload.showFooter : true,
          tenantId: action.payload ? action.payload.tenantId : null,
          boardId: action.payload ? action.payload.boardId : null,
          cardUuid: action.payload ? action.payload.cardUuid : null
        }
      }
    },

    [SET_BOARD_LOADING_ERROR](state, action) {
      return {
        ...state,
        isBoardLoadingError: action.payload.isBoardLoadingError
      }
    },

    [SET_BOARD_CARDS_WIDGETS](state, action) {
      return {
        ...state,
        widgetsMap: action.payload
      }
    },

    [UPDATE_BOARD_CARDS_WIDGETS](state, action) {
      const updatedWidgetsMap = Object.entries(action.payload).reduce(
        (result, [cardUUID, widgets]) => {
          if (!state.widgetsMap[cardUUID]) {
            result[cardUUID] = widgets

            return result
          }

          result[cardUUID] = unionBy(widgets, state.widgetsMap[cardUUID], 'uuid')

          return result
        },
        {}
      )

      return {
        ...state,
        widgetsMap: {
          ...state.widgetsMap,
          ...updatedWidgetsMap
        }
      }
    },

    [APPEND_CARD_WIDGETS](state, action) {
      const { cardUuid, widgets } = action.payload

      const widgetsMap = bulkWidgetsCreate({
        cardUuid,
        cards: [], // no need to update linked cards
        widgetsMap: state.widgetsMap,
        widgets
      })

      return { ...state, widgetsMap }
    },

    [TOGGLE_BOARD_WIDGETS_LOADING](state, action) {
      return {
        ...state,
        isBoardWidgetsLoading: action.payload
      }
    }
  }
)

export default board
