import { useCallback } from 'react'
import AsyncCreatable from 'react-select/async-creatable'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import 'scss/common/multi-select.scss'

const MultiCreatableSelect = ({
  isMulti,
  isClearable,
  isDisabled,
  isValidNewOption,
  defaultOptions,
  maxLength,
  values,
  className,
  size,
  placeholder,
  onChange: propsOnChange,
  menuPortalTarget,
  menuPortalEnabled,
  loadSuggestion,
  customComponents,
  formatCreateLabel,
  onFocus,
  onBlur,
  menuPlacement,
  getNewOptionData
}) => {
  const portalTarget = menuPortalEnabled
    ? (menuPortalTarget ?? document.getElementById('portal-container') ?? document.body)
    : null

  const restrictMaxLength = inputValue => {
    if (maxLength === null) {
      return inputValue
    }

    return inputValue.length <= maxLength ? inputValue : inputValue.substr(0, maxLength)
  }

  // react-select v3 begin to set values to null
  // in case user clears all values in multeselect field
  const onChange = useCallback(
    (value, ...args) => propsOnChange(isMulti ? value || [] : value, ...args),
    [isMulti, propsOnChange]
  )

  return (
    <AsyncCreatable
      className={classNames('upboard-react-select multi-creatable without-arrow', size, className)}
      classNamePrefix="upboard-rs"
      isClearable={isClearable}
      isMulti={isMulti}
      isValidNewOption={isValidNewOption}
      formatCreateLabel={formatCreateLabel}
      createOptionPosition="first"
      placeholder={placeholder}
      value={values}
      components={customComponents}
      openMenuOnClick={defaultOptions}
      defaultOptions={defaultOptions}
      loadOptions={loadSuggestion}
      menuPortalTarget={portalTarget}
      menuPlacement={menuPlacement}
      getNewOptionData={getNewOptionData}
      isDisabled={isDisabled}
      allowCreateWhileLoading
      onChange={onChange}
      onInputChange={restrictMaxLength}
      onBlurResetsInput={false}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  )
}

MultiCreatableSelect.propTypes = {
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isValidNewOption: PropTypes.func,
  formatCreateLabel: PropTypes.func,
  defaultOptions: PropTypes.bool,
  maxLength: PropTypes.number,
  values: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  menuPlacement: PropTypes.oneOf(['bottom', 'top', 'auto']),
  menuPortalEnabled: PropTypes.bool,
  menuPortalTarget: PropTypes.instanceOf(Element),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  loadSuggestion: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
}

MultiCreatableSelect.defaultProps = {
  isMulti: true,
  isClearable: undefined,
  isDisabled: false,
  isValidNewOption: undefined,
  formatCreateLabel: undefined,
  defaultOptions: false,
  maxLength: null,
  values: null,
  className: '',
  size: 'medium',
  placeholder: '',
  menuPlacement: 'auto',
  menuPortalEnabled: false,
  menuPortalTarget: null,
  onChange: null,
  onFocus: null,
  onBlur: null,
  loadSuggestion: null
}

export default MultiCreatableSelect
