import React from 'react'
import classNames from 'classnames'
import messages from 'constants/messages'
import { Tooltip } from 'components/common/tooltip/tooltip'
import { getUniqueKey } from 'helpers/dataLinkingHelpers'
import 'scss/dataLinking/providers-list.scss'

const getProvidersList = links => {
  const valid = []
  let broken = 0
  let validCount = 0

  Object.keys(links).reduce((acc, key) => {
    const link = links[key]

    if (!acc[getUniqueKey(link.widgetKey)]) {
      acc[getUniqueKey(link.widgetKey)] = { ...link }

      const title = link.providerInfo && link.providerInfo.title

      if (link.errorCode) {
        broken += 1
      } else {
        validCount += 1
        if (title) {
          valid.push(title)
        }
      }
    }

    return acc
  }, {})

  return { valid, validCount, broken }
}

const getProvidersComponent = ({ valid, validCount, broken }) => (
  <div className="providers-tooltip__content">
    <p
      className={classNames({
        hidden: !validCount
      })}
    >
      {`${messages.DATA_SOURCE}: ${valid.join(', ')}`}
    </p>
    <p
      className={classNames({
        hidden: !broken
      })}
    >
      <span className="red-color">{broken}</span>&nbsp;
      {messages.BROKEN_LINK}
    </p>
  </div>
)

const ProvidersListTooltip = props => {
  const { providers, uuid, tooltipText, isActive, onClick, customClass } = props

  const { valid, validCount, broken } = getProvidersList(providers)
  const tooltipContent = tooltipText || getProvidersComponent({ valid, validCount, broken })
  const tooltipId = `${uuid}-tooltip`

  return (
    <Tooltip id={tooltipId} title={tooltipContent}>
      <section
        className={classNames('providers-list__container', {
          active: isActive,
          empty: !validCount && !broken,
          'is-broken': !!broken,
          [customClass]: true
        })}
        onClick={onClick}
      >
        <span className="providers-list__icon" />
        <span className="count-text">{validCount}</span>
      </section>
    </Tooltip>
  )
}

export default ProvidersListTooltip
