import { ListByTenantRequest } from '_proto/dictionary/v1/dictionary.pb'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { getQueryWidgetData } from 'api/tenantAPI'
import { parseOptionsFromQuery } from 'helpers/widgetDataConvertorsHelprers'
import { DictionariesGrpcService } from 'features/dictionaries/dictionaries.grpc.service'
import {
  type IDictionaryData,
  type IQueryWidgetResponse,
  type TOption,
  type TQueryWidget,
  EQueryOutputTypes
} from 'features/dictionaries/dictionariesSettings.types'
import { validateManualOptions } from './dropdownContentSetup.helpers'
import { EDropdownOptionsSource } from './dropdownContentSetup.types'

interface IDictionaryOption {
  label: IDictionaryData['name']
  value: IDictionaryData['id']
  options: IDictionaryData['options']
  hint: IDictionaryData['hint']
  queryWidget: IDictionaryData['queryWidget']
}

export interface IDropdownContentSetupState {
  optionsSource: EDropdownOptionsSource | null
  selectedQueryId: string | null
  dictionaries: IDictionaryOption[]
  selectedDictionaryId: IDictionaryOption['value'] | null
  areDictionariesLoading: boolean
  isQueryLoading: boolean
  areOptionsLoading: boolean
  options: TOption[]
  manualOptions: TOption[]
  selectedQueryOutputType: EQueryOutputTypes | null
  isDictionaryCreateModalOpen: boolean
  isDictionaryEditModalOpen: boolean
  errors: {
    dictionary?: string
    query?: string
    optionsError?: boolean[]
    hasDuplicatedOptions?: boolean
    hintError?: boolean
    isValid: boolean
  }
  isDataLinked: boolean
  manualOptionsHint: { flag: boolean; textMessage: string }
}

export interface IDropdownContentSetupActions {
  fetchDictionaries: (tenantId: string) => Promise<void>
  fetchQueryOptions: (payload: TQueryWidget) => Promise<unknown>
  addDictionary: (dictionary: IDictionaryOption) => void
  updateDictionary: (dictionary: IDictionaryOption) => void
  setIsDictionaryEditModalOpen: (isOpen: boolean) => void
  reset: () => void
  setManualOptions: (options: TOption[]) => void
  addManualOption: () => void
  deleteManualOption: (optionIndex: number) => void
  updateManualOption: (optionIndex: number, value: string) => void
  validateDropdownContent: () => void
  setIsDataLinked: (value: boolean) => void
  updateHint: (flag: boolean, textMessage: string) => void
  setManualOptionsHint: (value: { flag: boolean; textMessage: string }) => void
}

const INITIAL_STATE = {
  dictionaries: [],
  optionsSource: null,
  options: [],
  manualOptions: [],
  isDataLinked: false,
  manualOptionsHint: { flag: false, textMessage: '' },
  selectedDictionaryId: null,
  selectedQueryId: null,
  selectedQueryOutputType: null,
  areDictionariesLoading: false,
  isQueryLoading: false,
  areOptionsLoading: false,
  isDictionaryCreateModalOpen: false,
  isDictionaryEditModalOpen: false,
  errors: {
    dictionary: '',
    query: '',
    optionsError: [],
    hasDuplicatedOptions: false,
    hintError: false,
    isValid: true
  }
}

export const useDropdownContentSetupStore = create(
  devtools(
    immer<IDropdownContentSetupState & IDropdownContentSetupActions>((set, get) => ({
      ...INITIAL_STATE,
      fetchDictionaries: async tenantId => {
        const request = ListByTenantRequest.create({ tenantId })

        request.page = {
          limit: 0,
          startCursor: new Uint8Array(0)
        }

        set(state => {
          state.areDictionariesLoading = true
        })

        try {
          const res = await DictionariesGrpcService.fetchDictionaryList({ request })

          set(state => {
            state.dictionaries = res.dictionaries.map(
              dictionary =>
                ({
                  label: dictionary.name,
                  value: dictionary.id,
                  options: dictionary.options.map(DictionariesGrpcService.parseDictionaryOption),
                  hint: dictionary.hint,
                  queryWidget: dictionary.queryWidget
                }) as IDictionaryOption
            )
          })
        } finally {
          set(state => {
            state.areDictionariesLoading = false
          })
        }
      },
      fetchQueryOptions: async payload => {
        set(state => {
          state.areOptionsLoading = true
          state.selectedQueryOutputType = null
        })

        try {
          const { data } = (await getQueryWidgetData(payload)) as IQueryWidgetResponse

          const options = data.data

          set(state => {
            state.options = parseOptionsFromQuery(options) as TOption[]
            state.selectedQueryOutputType = data.outputType
          })
        } finally {
          set(state => {
            state.areOptionsLoading = false
          })
        }
      },
      addDictionary: dictionary => {
        set(state => {
          const index = get().dictionaries.findIndex(
            _dictionary => _dictionary.value === dictionary.value
          )

          if (index === -1) {
            state.dictionaries.unshift(dictionary)
          }
        })
      },
      updateDictionary: dictionary => {
        set(state => {
          const index = get().dictionaries.findIndex(
            _dictionary => _dictionary.value === dictionary.value
          )

          if (index !== -1) {
            state.dictionaries[index] = { ...state.dictionaries[index], ...dictionary }
          }
        })
      },
      setIsDictionaryEditModalOpen: isOpen => {
        set(state => {
          state.isDictionaryEditModalOpen = isOpen
        })
      },
      setManualOptions: (options: TOption[]) => {
        set(state => {
          state.manualOptions = options
        })
      },
      addManualOption: () => {
        set(state => {
          state.manualOptions.push({ value: '' })
        })
      },
      deleteManualOption: (optionIndex: number) => {
        set(state => {
          state.manualOptions.splice(optionIndex, 1)
        })
      },
      updateManualOption: (optionIndex: number, value: string) => {
        set(state => {
          if (state.manualOptions[optionIndex]) {
            state.manualOptions[optionIndex].value = value
          }
        })
      },
      validateDropdownContent: () => {
        set(state => {
          if (state.optionsSource === EDropdownOptionsSource.manual) {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const { optionsError, hasDuplicatedOptions, hintError, isValid } =
              validateManualOptions(state.manualOptions, state.manualOptionsHint)

            state.errors = { optionsError, hasDuplicatedOptions, hintError, isValid }
          }
          if (state.optionsSource === EDropdownOptionsSource.query) {
            const queryError = !state.selectedQueryId ? "You can't leave this empty" : ''

            state.errors = { query: queryError, isValid: !queryError }
          }
          if (state.optionsSource === EDropdownOptionsSource.list) {
            const dictionaryError = !state.selectedDictionaryId ? "You can't leave this empty" : ''

            state.errors = { dictionary: dictionaryError, isValid: !dictionaryError }
          }
        })
      },
      setIsDataLinked: (value: boolean) => {
        set(state => {
          state.isDataLinked = value
        })
      },
      updateHint: (flag: boolean, textMessage: string) => {
        set(state => {
          state.manualOptionsHint = { flag, textMessage }
        })
      },
      setManualOptionsHint: (payload: { flag: boolean; textMessage: string }) => {
        set(state => {
          state.manualOptionsHint = payload
        })
      },
      reset: () => set(INITIAL_STATE)
    })),
    { name: 'DropdownContentSetup' }
  )
)
