import React from 'react'

import VariableRulePreview from 'components/variables/variablePreview/VariableRulePreview'

import messages from 'constants/messages'
import { VARIABLES_CONFIG } from 'constants/variables/variableConfiguration/constants'
import { makeObjectFromArray } from 'helpers/common/commonHelpers'

import 'scss/variables/variable-details-preview.scss'

const getVariableObjectText = object => {
  const wordings = {
    widget: `${messages.OF} ${object}`,
    card: ''
  }

  return wordings[object]
}

const space = ' '

const VariableDetailsPreview = props => {
  const {
    variable: {
      object: variableObject,
      filter: {
        attribute: { propertyName, x, y },
        rules
      }
    }
  } = props

  const currentAttribute = VARIABLES_CONFIG.attributes[propertyName]
  const variableObjectText = getVariableObjectText(variableObject)

  const rulesMap = makeObjectFromArray(rules, rule => rule.object)

  return (
    <div className="variable-details-preview">
      <span className="variable-attribute bold">
        {messages.VARIABLE_IS}
        {currentAttribute.name}
        {currentAttribute.withCoordinates ? ` [${x + 1},${y + 1}]` : ''}
        {space}
      </span>
      {variableObjectText && (
        <span className="variable-object">
          {variableObjectText}
          {space}
        </span>
      )}
      {VARIABLES_CONFIG.objectFields[variableObject].map(object => (
        <VariableRulePreview key={object} rule={rulesMap[object]} />
      ))}
    </div>
  )
}

export default VariableDetailsPreview
