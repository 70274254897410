import gql from 'graphql-tag'

const FilterConnectionObjectFragmentName = 'FilterConnectionObjectFragment'

export const FilterConnectionObjectFragment = {
  name: FilterConnectionObjectFragmentName,
  fragment: gql`
        fragment ${FilterConnectionObjectFragmentName} on FilterConnectionObject {
            uuid
            name
            object
            creationDate
            ownerId
            type
        }
    `
}
