/* eslint-disable import/no-cycle */
import messages from '../../constants/messages'
import { EFieldKeys } from '../../constants/workflowBuilder/blocksFieldsKeys'
import { getFieldNamesItems } from '../workflowBuilder/inputOperations'

export const BOARD_INPUT_COUNT = 5

export const PREDICATES_KEYS = {
  CONTAINS: 'contains'
}

export const RULES = {
  OR: 'OR',
  AND: 'AND'
}

export const STRING_INPUT = 'stringInput'
export const PLAIN_STRING = 'plainString'
export const COMBINED_STRING = 'combinedString'

export const FIND_WIDGET_OPTIONS = {
  [PLAIN_STRING]: { value: PLAIN_STRING, label: messages.WIDGET_NAME_EQUAL_TO_INPUT_FIELD },
  [STRING_INPUT]: { value: STRING_INPUT, label: messages.WIDGET_NAME_EQUAL_TO_STRING_INPUT }
}

export const FIND_CARD_OPTIONS = {
  [PLAIN_STRING]: { value: PLAIN_STRING, label: messages.CARD_NAME_EQUAL_TO_INPUT_FIELD },
  [STRING_INPUT]: { value: STRING_INPUT, label: messages.CARD_NAME_EQUAL_TO_STRING_INPUT }
}

export const FIND_COLUMN_OPTIONS = {
  [PLAIN_STRING]: { value: PLAIN_STRING, label: messages.COLUMN_NAME_EQUAL_TO_INPUT_FIELD },
  [STRING_INPUT]: { value: STRING_INPUT, label: messages.COLUMN_NAME_EQUAL_TO_STRING }
}

export const FIND_BOARD_OPTIONS = {
  [PLAIN_STRING]: { value: PLAIN_STRING, label: messages.BOARD_NAME_EQUAL_TO_INPUT_FIELD },
  [STRING_INPUT]: { value: STRING_INPUT, label: messages.BOARD_NAME_EQUAL_TO_STRING_INPUT },
  [COMBINED_STRING]: {
    value: COMBINED_STRING,
    label: messages.BOARD_NAME_CONTAINS_COMBINED_INPUT
  }
}

export const PREDICATES_OPTIONS = {
  [PREDICATES_KEYS.CONTAINS]: { value: PREDICATES_KEYS.CONTAINS, label: 'Contains' }
}

export const FIND_WIDGET_OPTIONS_LIST = Object.values(FIND_WIDGET_OPTIONS)

export const FIND_CARD_OPTIONS_LIST = Object.values(FIND_CARD_OPTIONS)

export const FIND_COLUMN_OPTIONS_LIST = Object.values(FIND_COLUMN_OPTIONS)

export const FIND_BOARD_OPTIONS_LIST = Object.values(FIND_BOARD_OPTIONS)

export const PREDICATES_OPTIONS_LIST = Object.values(PREDICATES_OPTIONS)

export const validateFindBlock = ({ block }) => {
  const error = {}
  if (!block.input || !block.input[EFieldKeys.SOURCE_INPUT]) {
    error[EFieldKeys.SOURCE_INPUT] = messages.emptyField
    return { error }
  }

  if (
    !block.meta ||
    (block.meta[EFieldKeys.TYPE] === STRING_INPUT && !block.input[EFieldKeys.STRING_INPUT])
  ) {
    error[EFieldKeys.STRING_INPUT] = messages.emptyField
    return { error }
  }

  if (block.meta[EFieldKeys.TYPE] === PLAIN_STRING && !block.meta[EFieldKeys.NAME]) {
    error[EFieldKeys.NAME] = messages.emptyField
    return { error }
  }

  return { error }
}

const validateStringInputs = block => {
  let error
  const fieldNames = getFieldNamesItems(EFieldKeys.STRING_INPUT, block.inputDefinition)

  if (block.meta[EFieldKeys.COLUMN_NUMBER]) {
    fieldNames.forEach((name, index) => {
      if (!block.input[name] && index < block.meta[EFieldKeys.COLUMN_NUMBER]) {
        error = { ...error, [name]: messages.emptyField }
        error[EFieldKeys.STRING_INPUT] = messages.emptyField
      }
    })
  }
  return error
}

export const validateFindBoard = ({ block }) => {
  let error = {}

  if (block.meta[EFieldKeys.TYPE] === PLAIN_STRING && !block.meta[EFieldKeys.NAMES][0]) {
    error[EFieldKeys.NAMES] = messages.emptyField
    return { error }
  }

  if (
    !block.meta ||
    (block.meta[EFieldKeys.TYPE] === STRING_INPUT &&
      !block.input[getFieldNamesItems(EFieldKeys.STRING_INPUT, block.inputDefinition)[0]])
  ) {
    error[EFieldKeys.STRING_INPUT] = messages.emptyField
    return { error }
  }

  if (!block.meta || block.meta[EFieldKeys.TYPE] === COMBINED_STRING) {
    const stringInputs = validateStringInputs(block)

    if (!block.meta[EFieldKeys.NAMES].length) {
      error[EFieldKeys.NAMES] = messages.emptyField
    }

    error = {
      ...error,
      ...stringInputs
    }
  }

  return { error }
}
