import { validateCardCommentBlock } from 'helpers/workflowAddCommentBlock/workflowAddCommentBlockHelpers'
import { validateSetCardOwnerBlock } from 'helpers/workflowCardOwnerBlocks/workflowCardOwnerBlocksHelpers'
import { validateCreateColumnBlock } from 'helpers/workflowCreateColumnBlocks/workflowCreateColumnBlocksHelpers'
import {
  validateFindBlock,
  validateFindBoard
} from 'helpers/workflowFindBlock/workflowFindBloksHelpers'
import { validateFinishBlock } from 'helpers/workflowFinishBlock/workflowFinishBlockHelpers'
import { validateMoveCardBlock } from 'helpers/workflowMoveCardBlock/moveCardBlockHelpers'
import { validateMoveColumnBlock } from 'helpers/workflowMoveColumnBlock/moveColumnBlockHelpers'
import { validateRenameBlock } from 'helpers/workflowRenameBlocks/workflowRenameBlocksHelpers'
import { validateSwitchWidget } from 'helpers/workflowStageTracker/switchWidgetHelper'
import { validateFeedNotificationBlock } from 'helpers/workflowSystemActions/feedNotificationBlock'
import { validateSendEmailBlock } from 'helpers/workflowSystemActions/sendNotificationBlock'
import { validateSendPromptToAIBlock } from 'components/workflowBuilder/sendPromptToAIBlock/sendPromptToAIBlock.helpers'
import { validateRestApiClientBlock } from 'components/workflowBuilder/systemActions/restApiClientBlock/restApiClientBlock.helpers'
import { validateTriggerBlock } from 'components/workflowBuilder/triggersBlock/triggerBlock.helpers'
import { validateConvertWidgetDataBlock } from 'components/workflowBuilder/widgetDataBlocks/convertWidgetData/convertWidgetData.helpers'
import { type IWorkflowBlock } from 'features/workflow/workflow.types'
import { EFieldKeys } from './blocksFieldsKeys'
import { EWorkflowBlockTypes } from './blocksTypes'

export const BLOCKS_VALIDATION_LIST = {
  [EWorkflowBlockTypes.FINISH]: ({ block }: { block: IWorkflowBlock }) =>
    validateFinishBlock({ block }),
  [EWorkflowBlockTypes.GET_BOARD]: {
    meta: {
      [EFieldKeys.ENTITY_INPUT]: { mandatory: true }
    }
  },
  [EWorkflowBlockTypes.GET_COLUMN]: {
    meta: {
      [EFieldKeys.ENTITY_INPUT]: { mandatory: true }
    }
  },
  [EWorkflowBlockTypes.GET_CARD]: {
    meta: {
      [EFieldKeys.ENTITY_INPUT]: { mandatory: true }
    }
  },
  [EWorkflowBlockTypes.GET_WIDGET]: {
    meta: {
      [EFieldKeys.ENTITY_INPUT]: { mandatory: true }
    }
  },
  [EWorkflowBlockTypes.GET_USER]: {
    meta: {
      [EFieldKeys.ENTITY_INPUT]: { mandatory: true }
    }
  },
  [EWorkflowBlockTypes.FIND_WIDGET]: validateFindBlock,
  [EWorkflowBlockTypes.FIND_COLUMN]: validateFindBlock,
  [EWorkflowBlockTypes.FIND_CARD]: validateFindBlock,
  [EWorkflowBlockTypes.FIND_WIDGETS_BUNDLE]: {
    input: {
      [EFieldKeys.SOURCE_INPUT]: { mandatory: true }
    },
    meta: {
      [EFieldKeys.NAMES]: { mandatory: true }
    }
  },
  [EWorkflowBlockTypes.SELECT_WIDGETS_FROM_FILTER]: {
    meta: {
      [EFieldKeys.FILTER_UUID_SNAKE]: { mandatory: true }
    }
  },
  [EWorkflowBlockTypes.FIND_CARD_BUNDLE_FROM_FILTER]: {
    meta: {
      [EFieldKeys.FILTER_UUID_SNAKE]: { mandatory: true }
    }
  },
  [EWorkflowBlockTypes.FIND_BOARD]: validateFindBoard,
  [EWorkflowBlockTypes.RENAME_CARD]: validateRenameBlock,
  [EWorkflowBlockTypes.RENAME_COLUMN]: validateRenameBlock,
  [EWorkflowBlockTypes.SET_CARD_OWNER]: validateSetCardOwnerBlock,
  [EWorkflowBlockTypes.RENAME_BOARD]: validateRenameBlock,
  [EWorkflowBlockTypes.CREATE_COLUMN]: validateCreateColumnBlock,
  [EWorkflowBlockTypes.DEFINE_STRING_FROM_BOARD]: {
    input: {
      [EFieldKeys.SOURCE_INPUT]: { mandatory: true }
    },
    meta: {
      [EFieldKeys.ATTRIBUTE]: { mandatory: true }
    }
  },
  [EWorkflowBlockTypes.DEFINE_STRING_FROM_CARD]: {
    input: {
      [EFieldKeys.SOURCE_INPUT]: { mandatory: true }
    },
    meta: {
      [EFieldKeys.ATTRIBUTE]: { mandatory: true }
    }
  },
  [EWorkflowBlockTypes.DEFINE_STRING_FROM_WIDGET]: {
    input: {
      [EFieldKeys.SOURCE_INPUT]: { mandatory: true }
    },
    meta: {
      [EFieldKeys.ATTRIBUTE]: { mandatory: true }
    }
  },
  [EWorkflowBlockTypes.DEFINE_STRING_FROM_JSON_PAYLOAD]: {
    input: {
      [EFieldKeys.SOURCE_INPUT]: { mandatory: true }
    },
    meta: {
      [EFieldKeys.KEY]: { mandatory: true }
    }
  },
  [EWorkflowBlockTypes.DEFINE_FILE_FROM_WIDGET]: {
    input: {
      [EFieldKeys.SOURCE_INPUT]: { mandatory: true }
    }
  },
  [EWorkflowBlockTypes.GET_WIDGET_DATA]: {
    input: {
      [EFieldKeys.SOURCE_INPUT]: { mandatory: true }
    },
    meta: {
      [EFieldKeys.ATTRIBUTE]: { mandatory: true }
    }
  },
  [EWorkflowBlockTypes.CONVERT_WIDGET_DATA]: validateConvertWidgetDataBlock,
  [EWorkflowBlockTypes.CONVERT_WIDGET_DATA_INTO_FILE]: {
    input: {
      [EFieldKeys.SOURCE_INPUT]: { mandatory: true }
    }
  },
  [EWorkflowBlockTypes.UPDATE_WIDGET_DATA]: {
    input: {
      [EFieldKeys.UBF_CELL]: { mandatory: true }
    },
    meta: {
      [EFieldKeys.SOURCE_INPUT]: { mandatory: true },
      [EFieldKeys.PARAMS]: { mandatory: true }
    }
  },
  [EWorkflowBlockTypes.DEFINE_STRING_FROM_USER]: {
    input: {
      [EFieldKeys.SOURCE_INPUT]: { mandatory: true }
    },
    meta: {
      [EFieldKeys.ATTRIBUTE]: { mandatory: true }
    }
  },
  [EWorkflowBlockTypes.DEFINE_STRING_FROM_COLUMN]: {
    input: {
      [EFieldKeys.SOURCE_INPUT]: { mandatory: true }
    },
    meta: {
      [EFieldKeys.ATTRIBUTE]: { mandatory: true }
    }
  },
  [EWorkflowBlockTypes.HIDE_WIDGET]: {
    input: {
      [EFieldKeys.SOURCE_INPUT]: { mandatory: true }
    }
  },
  [EWorkflowBlockTypes.HIDE_WIDGETS_BUNDLE_COMPONENT]: {
    input: {
      [EFieldKeys.SOURCE_INPUT]: { mandatory: true }
    }
  },
  [EWorkflowBlockTypes.HIDE_CARD_SECTION]: {
    input: {
      [EFieldKeys.SOURCE_INPUT]: { mandatory: true }
    }
  },
  [EWorkflowBlockTypes.HIDE_CARD_ACTION]: {
    input: {
      [EFieldKeys.SOURCE_INPUT]: { mandatory: true }
    },
    meta: {
      [EFieldKeys.ACTIONS]: { mandatory: true }
    }
  },
  [EWorkflowBlockTypes.LOCK_WIDGET]: {
    input: {
      [EFieldKeys.SOURCE_INPUT]: { mandatory: true }
    }
  },
  [EWorkflowBlockTypes.LOCK_WIDGETS_BUNDLE_COMPONENT]: {
    input: {
      [EFieldKeys.SOURCE_INPUT]: { mandatory: true }
    }
  },
  [EWorkflowBlockTypes.MOVE_CARD]: validateMoveCardBlock,
  [EWorkflowBlockTypes.MOVE_CARD_TO_BOARD]: {
    input: {
      [EFieldKeys.SOURCE_INPUT]: { mandatory: true },
      [EFieldKeys.BOARD_INPUT]: { mandatory: true }
    }
  },
  [EWorkflowBlockTypes.MOVE_COLUMN]: validateMoveColumnBlock,
  [EWorkflowBlockTypes.COPY_BOARD]: {
    input: {
      [EFieldKeys.SOURCE_INPUT]: { mandatory: true }
    }
  },
  [EWorkflowBlockTypes.COPY_CARD]: {
    input: {
      [EFieldKeys.SOURCE_INPUT]: { mandatory: true },
      [EFieldKeys.BOARD_INPUT]: { mandatory: true }
    }
  },
  [EWorkflowBlockTypes.COPY_WIDGETS_BUNDLE]: {
    input: {
      [EFieldKeys.SOURCE_INPUT]: { mandatory: true },
      [EFieldKeys.CARD_INPUT]: { mandatory: true }
    }
  },
  [EWorkflowBlockTypes.COPY_COLUMN]: {
    input: {
      [EFieldKeys.SOURCE_INPUT]: { mandatory: true },
      [EFieldKeys.BOARD_INPUT]: { mandatory: true }
    }
  },
  [EWorkflowBlockTypes.DELETE_CARD]: {
    input: {
      [EFieldKeys.SOURCE_INPUT]: { mandatory: true }
    }
  },
  [EWorkflowBlockTypes.DELETE_CARD_BUNDLE]: {
    input: {
      [EFieldKeys.SOURCE_INPUT]: { mandatory: true }
    }
  },
  [EWorkflowBlockTypes.DELETE_WIDGET]: {
    input: {
      [EFieldKeys.SOURCE_INPUT]: { mandatory: true }
    }
  },
  [EWorkflowBlockTypes.DELETE_WIDGETS_BUNDLE]: {
    input: {
      [EFieldKeys.SOURCE_INPUT]: { mandatory: true }
    }
  },
  [EWorkflowBlockTypes.DELETE_COLUMN]: {
    input: {
      [EFieldKeys.SOURCE_INPUT]: { mandatory: true }
    }
  },
  [EWorkflowBlockTypes.SWITCH_STAGE_WIDGET]: validateSwitchWidget,
  [EWorkflowBlockTypes.ADD_CARD_COMMENT]: validateCardCommentBlock,
  [EWorkflowBlockTypes.FIND_USER]: {
    input: {
      [EFieldKeys.STRING_INPUT]: { mandatory: true }
    }
  },
  [EWorkflowBlockTypes.HIDE_COLUMN]: {
    input: {
      [EFieldKeys.SOURCE_INPUT]: { mandatory: true }
    }
  },
  [EWorkflowBlockTypes.SEND_EMAIL]: validateSendEmailBlock,
  [EWorkflowBlockTypes.PUBLISH_FEED_NOTIFICATION]: validateFeedNotificationBlock,
  [EWorkflowBlockTypes.REST_API_CLIENT]: validateRestApiClientBlock,
  [EWorkflowBlockTypes.SEND_PROMPT_TO_AI]: validateSendPromptToAIBlock,
  [EWorkflowBlockTypes.DEFINE_JSON_WITH_AI]: validateSendPromptToAIBlock,
  [EWorkflowBlockTypes.GENERATE_FILE_WITH_AI]: validateSendPromptToAIBlock,
  [EWorkflowBlockTypes.UPDATE_WIDGET_WITH_FILE]: {
    input: {
      [EFieldKeys.SOURCE_INPUT]: { mandatory: true },
      [EFieldKeys.FILE_INPUT]: { mandatory: true }
    }
  },
  [EWorkflowBlockTypes.TRIGGER]: validateTriggerBlock
}
