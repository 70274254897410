import React from 'react'
import classNames from 'classnames'
import messages from 'constants/messages'
import './connections-fetch-error.scss'

export const Error = ({ className }) => (
  <div className={classNames('connections-fetch-error', className)}>
    <i className="up-font-ic-error-12" />
    {messages.LOADING_FAILED}
  </div>
)
