import React, { Component } from 'react'

const jobsIndicatorHOC = WrappedComponent =>
  class extends Component {
    constructor(props) {
      super(props)

      this.state = {
        isIndicatorHidden: false
      }

      this.onToggleHideJobsIndicator = this.onToggleHideJobsIndicator.bind(this)
      this.onCloseJobsIndicator = this.onCloseJobsIndicator.bind(this)
    }

    componentDidMount() {
      this.props.onRef(this)
    }

    componentWillUnmount() {
      this.props.onRef(undefined)
    }

    onCloseJobsIndicator() {
      this.props.toggleJobsIndicator(false)
    }

    onToggleHideJobsIndicator() {
      // force update is needed for change toasts transform
      // when height of indicator was changed
      this.setState(
        prevState => ({
          isIndicatorHidden: !prevState.isIndicatorHidden
        }),
        () => this.props.onForceUpdate()
      )
    }

    render() {
      const { isIndicatorHidden } = this.state

      return (
        <WrappedComponent
          {...this.props}
          isIndicatorHidden={isIndicatorHidden}
          onToggleHideJobsIndicator={this.onToggleHideJobsIndicator}
          onCloseJobsIndicator={this.onCloseJobsIndicator}
        />
      )
    }
  }

export default jobsIndicatorHOC
