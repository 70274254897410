import { makeObjectFromArray } from 'helpers/common/commonHelpers'

export const getNewApp = ({ tenantId }) => ({
  name: '',
  tenantId,
  logo: null,
  homepage: { tenantId, boardId: '' },
  boardIds: [],
  showBoardsInMenu: true,
  showExportTools: true
})

export const resolveAppBoardsFromMenu = ({ tenantId, boardIds, boards }) =>
  boards.filter(board => board.tenantId === tenantId && boardIds.includes(board.boardId))

export const resolveBoardCards = boards => {
  const cardsMap = makeObjectFromArray(boards, board => board.boardId, 'cards')

  return Object.keys(cardsMap).reduce((acc, boardId) => {
    acc[boardId] = cardsMap[boardId].filter(card => !card.isCol)
    return acc
  }, {})
}

export const setBoardCardsLoading = ({ boardIds, loading }) =>
  boardIds.reduce((acc, board) => {
    acc[board] = loading
    return acc
  }, {})

export const isTenantHasBoards = ({ tenantId, boards }) =>
  boards.some(board => board.tenantId === tenantId && !board.appId)

export const resolveTenantNameFromMenu = ({ tenantId, tenants }) => {
  let name = '--'
  tenants.some(tenant => {
    if (tenant.tenantId === tenantId) {
      name = tenant.name
      return true
    }
    return false
  })

  return name
}

export const filterAppBoardsFromMenu = (tenantId, boards, appBoardIds, appId) =>
  boards.filter(board => {
    if (board.tenantId !== tenantId) {
      return false
    }
    if (appBoardIds.includes(board.boardId)) {
      return false
    }
    if (!board.appId || appId === board.appId) {
      return true
    }

    return false
  })
