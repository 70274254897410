import messages from '../../constants/messages'
import { validateEmail, validateLongTextInputs } from '../validationHelpers'
import { getFieldNamesItems } from '../workflowBuilder/inputOperations'
import { EFieldKeys } from '../../constants/workflowBuilder/blocksFieldsKeys'
import { WF_USERS } from '../../constants/workflows'

export const SUBJECT_INPUTS_COUNT = 10
export const BODY_INPUTS_COUNT = 10
export const USER_EMAIL_INPUTS_COUNT = 10
export const MAX_TOKENS_COUNT = 20
export const SEND_EMAIL_BODY_MAX_LENGTH = 2000
export const PUBLISH_FEED_BODY_MAX_LENGTH = 1000
export const EMAIL_SUBJECT_MAX_LENGTH = 100

const validateRecipients = block => {
  const fieldNames = getFieldNamesItems(EFieldKeys.NAME, block.inputDefinition)
  const isStringOutputFilled = fieldNames.some(name => block.input[name])

  const areEmptyRecipients = !block.meta[EFieldKeys.NAMES].length && !isStringOutputFilled

  let areEmptyFields = areEmptyRecipients

  if (block.meta[EFieldKeys.ROLES]) {
    const areEmptyRoles = block.meta[EFieldKeys.ROLES] && !block.meta[EFieldKeys.ROLES].length
    areEmptyFields = areEmptyRecipients && areEmptyRoles
  }

  if (areEmptyFields) {
    return { [EFieldKeys.NAME]: messages.emptyRecipients }
  }

  return null
}

const validateRecipientEmails = block => {
  let error
  if (block.meta[EFieldKeys.NAMES].length) {
    block.meta[EFieldKeys.NAMES].some(email => {
      const result = validateEmail(email)
      if (result) {
        error = { [EFieldKeys.NAMES]: result }
      }
      return result
    })
  }
  return error
}

export const validateSendEmailBlock = ({ block }) => {
  let error = {}
  const subjectError = validateLongTextInputs({
    meta: block.meta,
    fieldKey: EFieldKeys.SUBJECT,
    input: block.input
  })
  const bodyError = validateLongTextInputs({
    meta: block.meta,
    fieldKey: EFieldKeys.TEXT,
    input: block.input
  })
  const emptyRecipientsError = validateRecipients(block)
  const recipientEmails = validateRecipientEmails(block)

  if (block.meta && block.meta[EFieldKeys.TYPE] === WF_USERS.CURRENT_USER) {
    error = {
      ...error,
      ...subjectError,
      ...bodyError
    }
  }
  error = {
    ...error,
    ...emptyRecipientsError,
    ...recipientEmails
  }

  return { error }
}
