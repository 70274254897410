const backendUrl = import.meta.env.VITE_BACKEND_URL

export const getNewAppMeta = () => ({
  id: null,
  isPublished: false,
  version: '',
  industry: null,
  categoryIds: [],
  authorName: '',
  authorEmail: '',
  videoLink: '',
  tags: [],
  images: [],
  description: '',
  whatsNew: '',
  previousVersion: [],
  order: [],
  isPurchaseEnabled: false,
  purchaseInfo: [],
  isPremium: false
})

export const getAppMetaImageUrl = image => {
  if (image.file) {
    return image.file.preview
  }

  if (image.url) {
    return image.url
  }

  return `${backendUrl}api/tenant/${image.tenantId}/board/${image.boardId}/card/${image.cardUuid}/snapshot`
}

// convert the app price to number with 2 values after the dot
export const parseAppPrices = item => {
  return item?.purchaseInfo?.forEach(_item => (_item.price = parseFloat(_item.price).toFixed(2)))
}
