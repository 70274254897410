export const BOARD_MENU_APPEARANCES = {
  BOARD: 'board',
  HOMEPAGE: 'homepage'
}

export function getTenantBoards(boardMenuBoards, tenantId) {
  return boardMenuBoards.filter(board => board.tenantId === tenantId)
}

export function getTenant(tenants, tenantId) {
  const existingTenant = tenants.find(tenant => tenant.tenantId === tenantId)
  if (!existingTenant) {
    return {}
  }
  return {
    tenantId,
    tenantName: existingTenant.name
  }
}

export function getAppBoards(boardMenuBoards, appId) {
  return boardMenuBoards.filter(board => board.appId === appId)
}

const sortRecentBoards = (a, b) => {
  // to move all board without timestamp into the end of array
  if (!a.timestamp && !b.timestamp) {
    return 0
  }
  if (a.timestamp && !b.timestamp) {
    return -1
  }
  if (b.timestamp && !a.timestamp) {
    return 1
  }

  return b.timestamp - a.timestamp
}

// for technical purposes
export const getRecentBoard = (boards, setting = {}) => {
  const { ignoreBoardsFromApp = false } = setting

  return boards
    .filter(board => {
      if (ignoreBoardsFromApp) {
        return !board.appId
      }
      return true
    })
    .sort(sortRecentBoards)[0]
}

// for presentation purposes
export const getRecentBoards = boards =>
  boards
    .filter(board => board.timestamp && !board.appId)
    .sort(sortRecentBoards)
    .slice(0, 3)

export function getBoardObjectByID(menu, boardId, tenantId) {
  let board = {}

  menu.boards.some(item => {
    if (item.tenantId === tenantId && item.boardId === boardId) {
      board = { ...item }

      return true
    }
    return false
  })

  menu.tenants.some(tenant => {
    if (tenant.tenantId === tenantId) {
      board = {
        ...board,
        usersNumber: tenant.usersNumber,
        tenantName: tenant.name
      }

      return true
    }

    return false
  })

  return board
}

export const groupBoardsByTenant = boards =>
  boards.reduce((acc, board) => {
    if (!acc[board.tenantId]) {
      acc[board.tenantId] = {
        boards: []
      }
    }

    if (!board.appId) {
      acc[board.tenantId].boards.push(board)

      return acc
    }

    if (!acc[board.tenantId][board.appId]) {
      acc[board.tenantId][board.appId] = []
    }

    acc[board.tenantId][board.appId].push(board)

    return acc
  }, {})

export const groupBoardsByApp = ({ boards, tenantId }) =>
  boards.reduce((acc, board) => {
    if (board.tenantId !== tenantId || !board.appId) {
      return acc
    }

    if (!acc[board.appId]) {
      acc[board.appId] = []
    }

    acc[board.appId].push(board)

    return acc
  }, {})

export const hasNonAppBoards = boards => boards.some(board => !board.appId)
