const GRAPHQL_VALIDATION_FAILED = 'GRAPHQL_VALIDATION_FAILED'
const GRAPHQL_PARSE_FAILED = 'GRAPHQL_PARSE_FAILED'
const INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR'
const UNAUTHENTICATED = 'UNAUTHENTICATED'
const FORBIDDEN = 'FORBIDDEN'
const BAD_USER_INPUT = 'BAD_USER_INPUT'
const PERSISTED_QUERY_NOT_SUPPORTED = 'PERSISTED_QUERY_NOT_SUPPORTED'
const PERSISTED_QUERY_NOT_FOUND = 'PERSISTED_QUERY_NOT_FOUND'

export const GRAPHQL_ERROR_CODES = {
  GRAPHQL_VALIDATION_FAILED,
  GRAPHQL_PARSE_FAILED,
  INTERNAL_SERVER_ERROR,
  UNAUTHENTICATED,
  FORBIDDEN,
  BAD_USER_INPUT,
  PERSISTED_QUERY_NOT_SUPPORTED,
  PERSISTED_QUERY_NOT_FOUND
}

export const GRAPHQL_REST_ERROR_CODES_MAP = {
  [GRAPHQL_VALIDATION_FAILED]: 400,
  [GRAPHQL_PARSE_FAILED]: 400,
  [INTERNAL_SERVER_ERROR]: 500,
  [UNAUTHENTICATED]: 401,
  [FORBIDDEN]: 403,
  [BAD_USER_INPUT]: 404,
  [PERSISTED_QUERY_NOT_SUPPORTED]: 400,
  [PERSISTED_QUERY_NOT_FOUND]: 400
}
