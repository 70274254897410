import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import messages from '../../constants/messages'

const sizes = {
  ordinary: 'ordinary',
  small: 'small'
}

const appearances = {
  loading: 'loading',
  rendering: 'rendering',
  renderingError: 'rendering-error'
}

const wordings = {
  [appearances.loading]: null,
  [appearances.rendering]: messages.RENDERING,
  [appearances.renderingError]: messages.WIDGET_RENDERING_ERROR
}

export const getPlaceholderSize = ({ width, height }) => {
  const minWidth = 40
  const minHeight = 90

  return width < minWidth || height < minHeight ? sizes.small : sizes.ordinary
}

const WidgetPlaceholder = props => {
  const { appearance, size } = props

  const wording = wordings[appearance]

  return (
    <div className={classNames('widget-placeholder', [appearance], [size])}>
      <span className="wording" title={wording}>
        {wording}
      </span>
    </div>
  )
}

WidgetPlaceholder.propTypes = {
  appearance: PropTypes.oneOf([
    appearances.loading,
    appearances.rendering,
    appearances.renderingError
  ]).isRequired,
  size: PropTypes.oneOf([sizes.small, sizes.ordinary])
}

WidgetPlaceholder.defaultProps = {
  size: sizes.ordinary
}

export default WidgetPlaceholder
