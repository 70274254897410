import gql from 'graphql-tag'

const userFragmentName = 'UserTile'

export const UserFragment = {
  name: userFragmentName,
  fragment: gql`
        fragment ${userFragmentName} on User {
            userId: id
            username
            email
            avatarImage: avatarUrl
        }
    `
}

const managerFragmentName = 'ManagerFragment'

export const ManagerFragment = {
  name: managerFragmentName,
  fragment: gql`
        fragment ${managerFragmentName} on User {
            manager {
                ...${UserFragment.name}
            }
        }
        ${UserFragment.fragment}
    `
}

const subordinatesFragmentName = 'SubordinatesFragment'

export const SubordinatesFragment = {
  name: subordinatesFragmentName,
  fragment: gql`
        fragment ${subordinatesFragmentName} on User {
            subordinates {
                ...${UserFragment.name}
            }
        }
        ${UserFragment.fragment}
    `
}

const tenantsFragmentName = 'MemberTenantsFragment'

export const MemberTenantsFragment = {
  name: tenantsFragmentName,
  fragment: gql`
        fragment ${tenantsFragmentName} on User {
           tenants {
                tenantId: id
                name
           }
        }
    `
}

const aclFragmentName = 'MemberAclFragment'

export const MemberAclFragment = {
  name: aclFragmentName,
  fragment: gql`
        fragment ${aclFragmentName} on User {
            acls {
                tenant {
                    isInactive
                    role
                } 
                boards {
                    boardId
                    name
                    role
                }
                tenantId
                tenantName
           }
        }
    `
}
