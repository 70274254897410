import { flagsList, hasFlag } from './widget/widgetDataHelpers'

export const getCellValue = cell => {
  if (cell && typeof cell === 'object' && !Array.isArray(cell)) {
    return cell.calculatedValue !== undefined ? cell.calculatedValue : cell.value
  }
  return cell
}

export const isLinkedConsumer = widget => {
  const isConsumer = hasFlag(widget.flags, flagsList.isDataConsumer)
  const hasLinks = !!Object.keys(widget.dataLink || {}).length

  return isConsumer && hasLinks
}
