// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.0.3
//   protoc               v3.19.1
// source: comments/v1/comments.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { type CallContext, type CallOptions } from "nice-grpc-common";
import { GetResponseType } from "../../common/api.pb";
import { CUDEvent } from "../../common/event.pb";
import { Key, Scope } from "../../common/key.pb";
import { PageInfo, PageOptions } from "../../common/page.pb";
import { Timestamp } from "../../google/protobuf/timestamp.pb";

export const protobufPackage = "upboard.io.card.comments.v1";

export interface Comment {
  key: Key | undefined;
  parentId: string;
  /** User id of who left the comment */
  authorId: string;
  /**
   * Limit -  10 MiB (rpc size limit) / 500 (max per read call) = 20.48 KBi
   * -> approx 20k symbols
   */
  text: string;
  /** mark comment left using widget */
  createdByWorkflow: boolean;
  /** defining who leave a comment by a user or organization */
  isWorkspaceUser: boolean;
  /**
   * List of mentions. Probably should be used as readonly list, which would be
   * populated on save, therefore client don't need to pass it.
   * REVIEW: Not sure if we need it here.
   * Text parsing might be embeded to trigger some of the events.
   */
  mentions: string[];
}

export interface CreateRequest {
  comments: Comment[];
}

export interface CreateResponse {
  keys: Key[];
}

export interface GetRequest {
  keys: Key[];
}

export interface GetResponse {
  results: GetResponseResult[];
}

export interface GetResponseResult {
  type: GetResponseType;
  comment: Comment | undefined;
}

export interface UpdateRequest {
  comments: Comment[];
}

export interface UpdateResponse {
  keys: Key[];
}

export interface DeleteRequest {
  keys: Key[];
}

export interface DeleteResponse {
}

export interface QueryRequest {
  scope: Scope | undefined;
  filter: QueryRequestFilter | undefined;
  page: PageOptions | undefined;
}

/**
 * REVIEW: Seems too rigid, but datastore actually supports only such rigid requests,
 * especially applying sorting.
 */
export interface QueryRequestFilter {
  /** To lookup for specific reply comments */
  parentId: string;
  /** To lookup comments with specific mentions? */
  mentions: string;
}

export interface QueryResponse {
  page: PageInfo | undefined;
  comments: Comment[];
}

export interface ListenRequest {
  keyOnly?: ListenRequestKeyOnly | undefined;
  query?: ListenRequestQuery | undefined;
}

/** REVIEW: Probably not needed */
export interface ListenRequestKeyOnly {
  keys: Key[];
}

export interface ListenRequestQuery {
  Scope: Scope | undefined;
}

export interface MentionEvent {
  commentKey: Key | undefined;
  mentionIds: string[];
  publishTimestamp: Date | undefined;
}

export interface CountRequest {
  /** Card keys for which counters should be returned. */
  cardKeys: Key[];
}

export interface CountResponse {
  counts: number[];
}

function createBaseComment(): Comment {
  return {
    key: undefined,
    parentId: "",
    authorId: "",
    text: "",
    createdByWorkflow: false,
    isWorkspaceUser: false,
    mentions: [],
  };
}

export const Comment = {
  encode(message: Comment, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== undefined) {
      Key.encode(message.key, writer.uint32(10).fork()).join();
    }
    if (message.parentId !== "") {
      writer.uint32(18).string(message.parentId);
    }
    if (message.authorId !== "") {
      writer.uint32(26).string(message.authorId);
    }
    if (message.text !== "") {
      writer.uint32(34).string(message.text);
    }
    if (message.createdByWorkflow !== false) {
      writer.uint32(40).bool(message.createdByWorkflow);
    }
    if (message.isWorkspaceUser !== false) {
      writer.uint32(48).bool(message.isWorkspaceUser);
    }
    for (const v of message.mentions) {
      writer.uint32(58).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Comment {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseComment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = Key.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.parentId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.authorId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.text = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.createdByWorkflow = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.isWorkspaceUser = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.mentions.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<Comment>, I>>(base?: I): Comment {
    return Comment.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Comment>, I>>(object: I): Comment {
    const message = createBaseComment();
    message.key = (object.key !== undefined && object.key !== null) ? Key.fromPartial(object.key) : undefined;
    message.parentId = object.parentId ?? "";
    message.authorId = object.authorId ?? "";
    message.text = object.text ?? "";
    message.createdByWorkflow = object.createdByWorkflow ?? false;
    message.isWorkspaceUser = object.isWorkspaceUser ?? false;
    message.mentions = object.mentions?.map((e) => e) || [];
    return message;
  },
};

function createBaseCreateRequest(): CreateRequest {
  return { comments: [] };
}

export const CreateRequest = {
  encode(message: CreateRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.comments) {
      Comment.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CreateRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.comments.push(Comment.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CreateRequest>, I>>(base?: I): CreateRequest {
    return CreateRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateRequest>, I>>(object: I): CreateRequest {
    const message = createBaseCreateRequest();
    message.comments = object.comments?.map((e) => Comment.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCreateResponse(): CreateResponse {
  return { keys: [] };
}

export const CreateResponse = {
  encode(message: CreateResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.keys) {
      Key.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CreateResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.keys.push(Key.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CreateResponse>, I>>(base?: I): CreateResponse {
    return CreateResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateResponse>, I>>(object: I): CreateResponse {
    const message = createBaseCreateResponse();
    message.keys = object.keys?.map((e) => Key.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetRequest(): GetRequest {
  return { keys: [] };
}

export const GetRequest = {
  encode(message: GetRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.keys) {
      Key.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.keys.push(Key.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetRequest>, I>>(base?: I): GetRequest {
    return GetRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetRequest>, I>>(object: I): GetRequest {
    const message = createBaseGetRequest();
    message.keys = object.keys?.map((e) => Key.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetResponse(): GetResponse {
  return { results: [] };
}

export const GetResponse = {
  encode(message: GetResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.results) {
      GetResponseResult.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.results.push(GetResponseResult.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetResponse>, I>>(base?: I): GetResponse {
    return GetResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetResponse>, I>>(object: I): GetResponse {
    const message = createBaseGetResponse();
    message.results = object.results?.map((e) => GetResponseResult.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetResponseResult(): GetResponseResult {
  return { type: 0, comment: undefined };
}

export const GetResponseResult = {
  encode(message: GetResponseResult, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.comment !== undefined) {
      Comment.encode(message.comment, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetResponseResult {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetResponseResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.comment = Comment.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetResponseResult>, I>>(base?: I): GetResponseResult {
    return GetResponseResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetResponseResult>, I>>(object: I): GetResponseResult {
    const message = createBaseGetResponseResult();
    message.type = object.type ?? 0;
    message.comment = (object.comment !== undefined && object.comment !== null)
      ? Comment.fromPartial(object.comment)
      : undefined;
    return message;
  },
};

function createBaseUpdateRequest(): UpdateRequest {
  return { comments: [] };
}

export const UpdateRequest = {
  encode(message: UpdateRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.comments) {
      Comment.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.comments.push(Comment.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateRequest>, I>>(base?: I): UpdateRequest {
    return UpdateRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateRequest>, I>>(object: I): UpdateRequest {
    const message = createBaseUpdateRequest();
    message.comments = object.comments?.map((e) => Comment.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateResponse(): UpdateResponse {
  return { keys: [] };
}

export const UpdateResponse = {
  encode(message: UpdateResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.keys) {
      Key.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.keys.push(Key.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateResponse>, I>>(base?: I): UpdateResponse {
    return UpdateResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateResponse>, I>>(object: I): UpdateResponse {
    const message = createBaseUpdateResponse();
    message.keys = object.keys?.map((e) => Key.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDeleteRequest(): DeleteRequest {
  return { keys: [] };
}

export const DeleteRequest = {
  encode(message: DeleteRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.keys) {
      Key.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeleteRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.keys.push(Key.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<DeleteRequest>, I>>(base?: I): DeleteRequest {
    return DeleteRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteRequest>, I>>(object: I): DeleteRequest {
    const message = createBaseDeleteRequest();
    message.keys = object.keys?.map((e) => Key.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDeleteResponse(): DeleteResponse {
  return {};
}

export const DeleteResponse = {
  encode(_: DeleteResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeleteResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<DeleteResponse>, I>>(base?: I): DeleteResponse {
    return DeleteResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteResponse>, I>>(_: I): DeleteResponse {
    const message = createBaseDeleteResponse();
    return message;
  },
};

function createBaseQueryRequest(): QueryRequest {
  return { scope: undefined, filter: undefined, page: undefined };
}

export const QueryRequest = {
  encode(message: QueryRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.scope !== undefined) {
      Scope.encode(message.scope, writer.uint32(10).fork()).join();
    }
    if (message.filter !== undefined) {
      QueryRequestFilter.encode(message.filter, writer.uint32(18).fork()).join();
    }
    if (message.page !== undefined) {
      PageOptions.encode(message.page, writer.uint32(90).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scope = Scope.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.filter = QueryRequestFilter.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.page = PageOptions.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<QueryRequest>, I>>(base?: I): QueryRequest {
    return QueryRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<QueryRequest>, I>>(object: I): QueryRequest {
    const message = createBaseQueryRequest();
    message.scope = (object.scope !== undefined && object.scope !== null) ? Scope.fromPartial(object.scope) : undefined;
    message.filter = (object.filter !== undefined && object.filter !== null)
      ? QueryRequestFilter.fromPartial(object.filter)
      : undefined;
    message.page = (object.page !== undefined && object.page !== null)
      ? PageOptions.fromPartial(object.page)
      : undefined;
    return message;
  },
};

function createBaseQueryRequestFilter(): QueryRequestFilter {
  return { parentId: "", mentions: "" };
}

export const QueryRequestFilter = {
  encode(message: QueryRequestFilter, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.parentId !== "") {
      writer.uint32(10).string(message.parentId);
    }
    if (message.mentions !== "") {
      writer.uint32(18).string(message.mentions);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryRequestFilter {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryRequestFilter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.parentId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.mentions = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<QueryRequestFilter>, I>>(base?: I): QueryRequestFilter {
    return QueryRequestFilter.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<QueryRequestFilter>, I>>(object: I): QueryRequestFilter {
    const message = createBaseQueryRequestFilter();
    message.parentId = object.parentId ?? "";
    message.mentions = object.mentions ?? "";
    return message;
  },
};

function createBaseQueryResponse(): QueryResponse {
  return { page: undefined, comments: [] };
}

export const QueryResponse = {
  encode(message: QueryResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.page !== undefined) {
      PageInfo.encode(message.page, writer.uint32(10).fork()).join();
    }
    for (const v of message.comments) {
      Comment.encode(v!, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.page = PageInfo.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.comments.push(Comment.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<QueryResponse>, I>>(base?: I): QueryResponse {
    return QueryResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<QueryResponse>, I>>(object: I): QueryResponse {
    const message = createBaseQueryResponse();
    message.page = (object.page !== undefined && object.page !== null) ? PageInfo.fromPartial(object.page) : undefined;
    message.comments = object.comments?.map((e) => Comment.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListenRequest(): ListenRequest {
  return { keyOnly: undefined, query: undefined };
}

export const ListenRequest = {
  encode(message: ListenRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.keyOnly !== undefined) {
      ListenRequestKeyOnly.encode(message.keyOnly, writer.uint32(10).fork()).join();
    }
    if (message.query !== undefined) {
      ListenRequestQuery.encode(message.query, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListenRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListenRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.keyOnly = ListenRequestKeyOnly.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.query = ListenRequestQuery.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ListenRequest>, I>>(base?: I): ListenRequest {
    return ListenRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListenRequest>, I>>(object: I): ListenRequest {
    const message = createBaseListenRequest();
    message.keyOnly = (object.keyOnly !== undefined && object.keyOnly !== null)
      ? ListenRequestKeyOnly.fromPartial(object.keyOnly)
      : undefined;
    message.query = (object.query !== undefined && object.query !== null)
      ? ListenRequestQuery.fromPartial(object.query)
      : undefined;
    return message;
  },
};

function createBaseListenRequestKeyOnly(): ListenRequestKeyOnly {
  return { keys: [] };
}

export const ListenRequestKeyOnly = {
  encode(message: ListenRequestKeyOnly, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.keys) {
      Key.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListenRequestKeyOnly {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListenRequestKeyOnly();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.keys.push(Key.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ListenRequestKeyOnly>, I>>(base?: I): ListenRequestKeyOnly {
    return ListenRequestKeyOnly.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListenRequestKeyOnly>, I>>(object: I): ListenRequestKeyOnly {
    const message = createBaseListenRequestKeyOnly();
    message.keys = object.keys?.map((e) => Key.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListenRequestQuery(): ListenRequestQuery {
  return { Scope: undefined };
}

export const ListenRequestQuery = {
  encode(message: ListenRequestQuery, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.Scope !== undefined) {
      Scope.encode(message.Scope, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListenRequestQuery {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListenRequestQuery();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.Scope = Scope.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ListenRequestQuery>, I>>(base?: I): ListenRequestQuery {
    return ListenRequestQuery.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListenRequestQuery>, I>>(object: I): ListenRequestQuery {
    const message = createBaseListenRequestQuery();
    message.Scope = (object.Scope !== undefined && object.Scope !== null) ? Scope.fromPartial(object.Scope) : undefined;
    return message;
  },
};

function createBaseMentionEvent(): MentionEvent {
  return { commentKey: undefined, mentionIds: [], publishTimestamp: undefined };
}

export const MentionEvent = {
  encode(message: MentionEvent, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.commentKey !== undefined) {
      Key.encode(message.commentKey, writer.uint32(10).fork()).join();
    }
    for (const v of message.mentionIds) {
      writer.uint32(18).string(v!);
    }
    if (message.publishTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.publishTimestamp), writer.uint32(26).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): MentionEvent {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMentionEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.commentKey = Key.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.mentionIds.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.publishTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<MentionEvent>, I>>(base?: I): MentionEvent {
    return MentionEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MentionEvent>, I>>(object: I): MentionEvent {
    const message = createBaseMentionEvent();
    message.commentKey = (object.commentKey !== undefined && object.commentKey !== null)
      ? Key.fromPartial(object.commentKey)
      : undefined;
    message.mentionIds = object.mentionIds?.map((e) => e) || [];
    message.publishTimestamp = object.publishTimestamp ?? undefined;
    return message;
  },
};

function createBaseCountRequest(): CountRequest {
  return { cardKeys: [] };
}

export const CountRequest = {
  encode(message: CountRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.cardKeys) {
      Key.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CountRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCountRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.cardKeys.push(Key.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CountRequest>, I>>(base?: I): CountRequest {
    return CountRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CountRequest>, I>>(object: I): CountRequest {
    const message = createBaseCountRequest();
    message.cardKeys = object.cardKeys?.map((e) => Key.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCountResponse(): CountResponse {
  return { counts: [] };
}

export const CountResponse = {
  encode(message: CountResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    writer.uint32(10).fork();
    for (const v of message.counts) {
      writer.int32(v);
    }
    writer.join();
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CountResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCountResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.counts.push(reader.int32());

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.counts.push(reader.int32());
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CountResponse>, I>>(base?: I): CountResponse {
    return CountResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CountResponse>, I>>(object: I): CountResponse {
    const message = createBaseCountResponse();
    message.counts = object.counts?.map((e) => e) || [];
    return message;
  },
};

/**
 * REVIEW:
 * And migrate existing v2 comments to it, as well, as
 * v3 card not stores any comment related info.
 * As well this allows to eliminate notifications tech debt,
 * and listen by notifications service comments CUD and mentions, to send emails.
 */
export type CardCommentsServiceDefinition = typeof CardCommentsServiceDefinition;
export const CardCommentsServiceDefinition = {
  name: "CardCommentsService",
  fullName: "upboard.io.card.comments.v1.CardCommentsService",
  methods: {
    /**
     * Atomic
     * Add comments to datastore & update counter
     */
    create: {
      name: "Create",
      requestType: CreateRequest,
      requestStream: false,
      responseType: CreateResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              28,
              34,
              23,
              47,
              97,
              112,
              105,
              47,
              99,
              111,
              109,
              109,
              101,
              110,
              116,
              115,
              47,
              118,
              49,
              58,
              99,
              114,
              101,
              97,
              116,
              101,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    get: {
      name: "Get",
      requestType: GetRequest,
      requestStream: false,
      responseType: GetResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              25,
              34,
              20,
              47,
              97,
              112,
              105,
              47,
              99,
              111,
              109,
              109,
              101,
              110,
              116,
              115,
              47,
              118,
              49,
              58,
              103,
              101,
              116,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    /** Atomic */
    delete: {
      name: "Delete",
      requestType: DeleteRequest,
      requestStream: false,
      responseType: DeleteResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              28,
              34,
              23,
              47,
              97,
              112,
              105,
              47,
              99,
              111,
              109,
              109,
              101,
              110,
              116,
              115,
              47,
              118,
              49,
              58,
              100,
              101,
              108,
              101,
              116,
              101,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    /** Atomic */
    update: {
      name: "Update",
      requestType: UpdateRequest,
      requestStream: false,
      responseType: UpdateResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              28,
              34,
              23,
              47,
              97,
              112,
              105,
              47,
              99,
              111,
              109,
              109,
              101,
              110,
              116,
              115,
              47,
              118,
              49,
              58,
              117,
              112,
              100,
              97,
              116,
              101,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    /** Request data blocks using query */
    query: {
      name: "Query",
      requestType: QueryRequest,
      requestStream: false,
      responseType: QueryResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              27,
              34,
              22,
              47,
              97,
              112,
              105,
              47,
              99,
              111,
              109,
              109,
              101,
              110,
              116,
              115,
              47,
              118,
              49,
              58,
              113,
              117,
              101,
              114,
              121,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    listen: {
      name: "Listen",
      requestType: ListenRequest,
      requestStream: false,
      responseType: CUDEvent,
      responseStream: true,
      options: {},
    },
    listenMentions: {
      name: "ListenMentions",
      requestType: ListenRequest,
      requestStream: false,
      responseType: MentionEvent,
      responseStream: true,
      options: {},
    },
    /**
     * Allowing to count comments
     * Method guarantee the order of a counters relative to requested keys.
     * So, i-th counter in response is corresponds to i-th requested key.
     */
    count: {
      name: "Count",
      requestType: CountRequest,
      requestStream: false,
      responseType: CountResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              27,
              34,
              22,
              47,
              97,
              112,
              105,
              47,
              99,
              111,
              109,
              109,
              101,
              110,
              116,
              115,
              47,
              118,
              49,
              58,
              99,
              111,
              117,
              110,
              116,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
  },
} as const;

export interface CardCommentsServiceImplementation<CallContextExt = {}> {
  /**
   * Atomic
   * Add comments to datastore & update counter
   */
  create(request: CreateRequest, context: CallContext & CallContextExt): Promise<DeepPartial<CreateResponse>>;
  get(request: GetRequest, context: CallContext & CallContextExt): Promise<DeepPartial<GetResponse>>;
  /** Atomic */
  delete(request: DeleteRequest, context: CallContext & CallContextExt): Promise<DeepPartial<DeleteResponse>>;
  /** Atomic */
  update(request: UpdateRequest, context: CallContext & CallContextExt): Promise<DeepPartial<UpdateResponse>>;
  /** Request data blocks using query */
  query(request: QueryRequest, context: CallContext & CallContextExt): Promise<DeepPartial<QueryResponse>>;
  listen(
    request: ListenRequest,
    context: CallContext & CallContextExt,
  ): ServerStreamingMethodResult<DeepPartial<CUDEvent>>;
  listenMentions(
    request: ListenRequest,
    context: CallContext & CallContextExt,
  ): ServerStreamingMethodResult<DeepPartial<MentionEvent>>;
  /**
   * Allowing to count comments
   * Method guarantee the order of a counters relative to requested keys.
   * So, i-th counter in response is corresponds to i-th requested key.
   */
  count(request: CountRequest, context: CallContext & CallContextExt): Promise<DeepPartial<CountResponse>>;
}

export interface CardCommentsServiceClient<CallOptionsExt = {}> {
  /**
   * Atomic
   * Add comments to datastore & update counter
   */
  create(request: DeepPartial<CreateRequest>, options?: CallOptions & CallOptionsExt): Promise<CreateResponse>;
  get(request: DeepPartial<GetRequest>, options?: CallOptions & CallOptionsExt): Promise<GetResponse>;
  /** Atomic */
  delete(request: DeepPartial<DeleteRequest>, options?: CallOptions & CallOptionsExt): Promise<DeleteResponse>;
  /** Atomic */
  update(request: DeepPartial<UpdateRequest>, options?: CallOptions & CallOptionsExt): Promise<UpdateResponse>;
  /** Request data blocks using query */
  query(request: DeepPartial<QueryRequest>, options?: CallOptions & CallOptionsExt): Promise<QueryResponse>;
  listen(request: DeepPartial<ListenRequest>, options?: CallOptions & CallOptionsExt): AsyncIterable<CUDEvent>;
  listenMentions(
    request: DeepPartial<ListenRequest>,
    options?: CallOptions & CallOptionsExt,
  ): AsyncIterable<MentionEvent>;
  /**
   * Allowing to count comments
   * Method guarantee the order of a counters relative to requested keys.
   * So, i-th counter in response is corresponds to i-th requested key.
   */
  count(request: DeepPartial<CountRequest>, options?: CallOptions & CallOptionsExt): Promise<CountResponse>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

export type ServerStreamingMethodResult<Response> = { [Symbol.asyncIterator](): AsyncIterator<Response, void> };
