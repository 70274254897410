import type { ReactNode } from 'react'
import { useEffectOnce } from 'react-use'
import classnames from 'classnames'
import * as Sentry from '@sentry/react'
import errorIcon from 'assets/images/icons/ic_error.svg?url'
import './errorBoundary.scss'

type TErrorBoundaryProps = {
  children: ReactNode
  errorText: string
  isRoot?: boolean
}

export const ErrorBoundary = ({ children, errorText, isRoot }: TErrorBoundaryProps) => {
  useEffectOnce(() => {
    if (!isRoot) return undefined

    // Set global errors handler.
    window.onerror = message => {
      if (typeof message !== 'string') return false

      // https://leverxeu.atlassian.net/browse/EUPBOARD01-11321
      // Error inside the react-dnd library, will be fixed in the next versions of the library.
      // This script should catch the error, so it won't be thrown to the console and Sentry.
      return /Cannot call hover while not dragging/.test(message)
    }

    return () => {
      window.onerror = null
    }
  })

  return (
    <Sentry.ErrorBoundary
      fallback={
        <div className={classnames('error-boundary-placeholder')}>
          <img src={errorIcon} className="error-icon" alt="error" />
          <span className="error-text" title={errorText}>
            {errorText}
          </span>
        </div>
      }
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}
