import {
  DEFAULT_VARIABLE_OBJECT,
  VARIABLES_CONFIG
} from 'constants/variables/variableConfiguration/constants'
import { validateForNotEmpty } from 'helpers/validationHelpers'

export const getDefaultRules = ({ variableObject = DEFAULT_VARIABLE_OBJECT }) =>
  VARIABLES_CONFIG.objectFields[variableObject].map(object => ({
    object,
    predicate: VARIABLES_CONFIG.rules[object].defaultPredicate,
    value: ''
  }))

export const getDefaultFilter = ({ variableObject = DEFAULT_VARIABLE_OBJECT }) => ({
  attribute: {
    propertyName: VARIABLES_CONFIG.objects[variableObject].defaultAttribute,
    x: 0,
    y: 0
  },
  rules: getDefaultRules({ variableObject })
})

export const createVariable = ({ object = DEFAULT_VARIABLE_OBJECT }) => ({
  id: null,
  name: '',
  object,
  filter: getDefaultFilter({ variableObject: object }),
  tags: []
})

export const validateRule = rule => {
  const predicate = VARIABLES_CONFIG.rules[rule.object].predicates[rule.predicate]

  if (predicate.type) {
    return validateForNotEmpty(rule.value)
  }

  return ''
}

export const validateRules = rules =>
  rules.reduce((acc, rule) => {
    acc[rule.object] = validateRule(rule)

    return acc
  }, {})

export const formatAttribute = attribute => (attribute === '' ? '' : attribute + 1)

export const getAttributeName = attribute => {
  const { propertyName, x, y } = attribute

  const attributeConfig = VARIABLES_CONFIG.attributes[propertyName]

  let attributeName = attributeConfig.name
  if (attributeConfig.withCoordinates) {
    attributeName = `${attributeName} [${formatAttribute(x)},${formatAttribute(y)}]`
  }
  return attributeName
}
