import { useRef, useState } from 'react'
import classNames from 'classnames'
import { goToLink } from 'helpers/routesHelpers'

import { Menu, MenuItem } from '../menu/menu'

import './menuTab.scss'

export type TMenuItem = {
  text: string
  link: string
  active: boolean
}

export type TMenuTabProps = {
  text: string
  className?: string
  active: boolean
  menuItems: Array<TMenuItem>
}

export const MenuTab = ({ text, className, active, menuItems }: TMenuTabProps) => {
  const tabRef = useRef(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleMenuClick = () => {
    setAnchorEl(tabRef?.current)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (link: string) => {
    if (!link) return
    /* eslint-disable-next-line */
    goToLink(link)
    handleClose()
  }

  return (
    <div ref={tabRef} className={classNames('menu-tab-container', { active }, className)}>
      <div className="menu-trigger" onClick={handleMenuClick}>
        {text} <i className="up-font-ic-arrow-e908 icon" />
      </div>
      <Menu
        className="menu-tab"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        {menuItems.map((menuItem: TMenuItem) => (
          <MenuItem
            key={menuItem.text}
            onClick={() => handleMenuItemClick(menuItem.link)}
            className={classNames({ active: menuItem.active })}
          >
            {menuItem.text}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
