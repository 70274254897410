import { type TOption } from '../dictionariesSettings.types'

export const validateManualOptions = (
  options: TOption[],
  hint: { flag: boolean; textMessage: string }
) => {
  let hintError = false
  let isValid = true

  const { optionsError, hasDuplicatedOptions } = options.reduce<{
    optionsValues: string[]
    optionsError: boolean[]
    hasDuplicatedOptions: boolean
  }>(
    (acc, option) => {
      const isDuplicated = acc.optionsValues.includes(option.value)

      acc.optionsValues.push(option.value)
      acc.hasDuplicatedOptions = isDuplicated ? true : acc.hasDuplicatedOptions

      if (!option.value || isDuplicated) {
        acc.optionsError.push(true)
        isValid = false
      } else {
        acc.optionsError.push(false)
      }

      return acc
    },
    {
      optionsValues: [],
      optionsError: [],
      hasDuplicatedOptions: false
    }
  )

  if (hint.flag && !hint.textMessage.trim().length) {
    hintError = true
    isValid = false
  }

  return { optionsError, hasDuplicatedOptions, hintError, isValid }
}
