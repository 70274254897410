import moment from 'moment'

const { format } = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'short',
  day: 'numeric'
})

export const dateFormatter = date => format(date)

export function convertDate(object) {
  if (Array.isArray(object.data)) {
    object.data.forEach(item => (item.dateDay = moment(item.date).format('ll')))
  } else {
    object.data.dateDay = moment(object.data.date).format('ll')
  }
  return object
}

const millisecondsInDay = 86400 * 1000

// days between 1970/01/01 and 1900/01/01 (min date in Windows Excel)
const days = 25569

export const convertTimestampToExcelDate = timestamp => timestamp / millisecondsInDay + days

export const isDateFormat = _format => {
  const yearPattern = /yy|yyyy/i
  const monthPattern = /m{1,5}/i
  const dayPattern = /d{1,4}/i
  const hourPattern = /h{1,2}/i
  const minutePattern = /m{1,2}/i
  const secondsPattern = /s{1,2}/i

  const hasYearFormat = yearPattern.test(_format)
  const hasMonthFormat = monthPattern.test(_format)
  const hasDayFormat = dayPattern.test(_format)

  // in case format contains day and month or only year
  if ((hasDayFormat && hasMonthFormat) || hasYearFormat) {
    return true
  }

  // in case format contains hour and minute or minute and second
  const hasHourFormat = hourPattern.test(_format)
  const hasMinuteFormat = minutePattern.test(_format)
  const hasSecondFormat = secondsPattern.test(_format)

  return (hasHourFormat && hasMinuteFormat) || (hasMinuteFormat && hasSecondFormat)
}
