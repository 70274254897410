import * as actions from '../constants/actionTypes'
import {
  processNotificationsRead,
  processNotificationsRequest,
  processNotificationsTotalsRequest
} from '../api/notificationsAPI'

export function notificationsRequestStart() {
  return { type: actions.REQUEST_NOTIFICATIONS }
}

export function notificationsReceive(payload) {
  return { type: actions.RECEIVE_NOTIFICATIONS, payload }
}

export function clearNotifications(payload) {
  return { type: actions.CLEAR_NOTIFICATIONS, payload }
}

export function receiveNotificationsTotals(payload) {
  return { type: actions.RECEIVE_NOTIFICATIONS_TOTALS, payload }
}

export function receiveNotificationsErrorMessage(payload) {
  return { type: actions.RECEIVE_NOTIFICATIONS_ERROR, payload }
}

export function notificationsRequest() {
  return (dispatch, getState) => {
    const state = getState()
    const { cursor } = state.notifications
    dispatch(notificationsRequestStart())

    return processNotificationsRequest({ cursor })
      .then(response => {
        dispatch(notificationsReceive(response))
      })
      .catch(err => receiveNotificationsErrorMessage(err))
  }
}

export function notificationsTotalsRequest() {
  return dispatch =>
    processNotificationsTotalsRequest()
      .then(response => {
        dispatch(receiveNotificationsTotals(response))
      })
      .catch(err => receiveNotificationsErrorMessage(err))
}

export function makeNotificationsReadAction() {
  return { type: actions.MAKE_NOTIFICATIONS_READ }
}

export function makeNotificationsRead(payload) {
  return dispatch => {
    if (!payload.notificationsList.length) {
      return Promise.resolve()
    }

    return processNotificationsRead(payload)
      .then(() => dispatch(makeNotificationsReadAction()))
      .catch(err => console.error(err))
  }
}
