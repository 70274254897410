import React, { useMemo, useRef, useState } from 'react'
import classNames from 'classnames'

import { Menu } from 'components/common/menu/menu'
import DataProvidersList from 'components/dataLinking/DataProvidersList'
import { getUniqueKey } from 'helpers/dataLinkingHelpers'
import { getResourcesNames } from 'api/tenantAPI'

import 'scss/dataLinking/data-providers-list-dropdown.scss'

const Toggle = ({ onToggle, count, isOpen }) => (
  <div
    className={classNames('action-on-detailed-icon data-providers-list-toggle', {
      'is-active': isOpen
    })}
    onClick={onToggle}
  >
    <span>{count}</span>
  </div>
)

const DataProvidersListContainer = props => {
  const { dataLinks, className, pullRight } = props

  const anchorElRef = useRef(null)

  const [isOpen, setIsOpen] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const [links, setLinks] = useState([])

  const widgetLinkMap = useMemo(
    () =>
      Object.keys(dataLinks).reduce((acc, key) => {
        const link = dataLinks[key].widgetKey

        const linkKey = getUniqueKey(link)

        if (!acc[linkKey]) {
          acc[linkKey] = { ...link }
        }

        return acc
      }, {}),
    [dataLinks]
  )

  const widgetLinksCount = useMemo(() => {
    return Object.keys(widgetLinkMap).length
  }, [widgetLinkMap])

  const resolveLinksNames = () => {
    const widgetLinks = Object.values(widgetLinkMap)
    const tenantId = widgetLinks.find(link => Boolean(link.tenantId)).tenantId

    return getResourcesNames({ data: widgetLinks, tenantId }).then(response => response.data)
  }

  const fetchLinkNames = () => {
    if (!Object.keys(dataLinks).length) {
      return null
    }

    setIsLoading(true)

    return resolveLinksNames()
      .then(setLinks)
      .finally(() => {
        setIsLoading(false)
      })
  }

  const openDropdown = () => {
    setIsOpen(true)

    if (!links.length) {
      fetchLinkNames()
    }
  }

  const closeDropdown = () => {
    setIsOpen(false)
  }

  return (
    <div
      ref={anchorElRef}
      id="data-providers-list"
      className={classNames('data-providers-list-dropdown', className)}
    >
      <Toggle isOpen={isOpen} count={widgetLinksCount} onToggle={openDropdown} />
      <Menu
        className={classNames('data-providers-list-dropdown-menu', { 'pull-right': pullRight })}
        open={isOpen}
        onClose={closeDropdown}
        anchorEl={anchorElRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: pullRight ? 'right' : 'left'
        }}
        transformOrigin={{
          vertical: -10,
          horizontal: pullRight ? 'right' : 'left'
        }}
      >
        <DataProvidersList links={links} isLoading={isLoading} />
      </Menu>
    </div>
  )
}

export default DataProvidersListContainer
