import React from 'react'
import { ConfirmationModal } from 'components/modals/confirmation/confirmationModal'
import ConnectionsListGroup from './connections/ConnectionsListGroup'
import splitArrayByType from '../../helpers/splitArrayByType'
import messages from '../../constants/messages'

import '../../scss/filters/filter-delete-confirmation.scss'

const confirmWithoutConnectConfig = {
  className: 'delete-filters-confirm-no-connect',
  title: messages.FILTERS_DELETE_CONFIRMATION_NO_CONNECT_TITLE,
  description: messages.FILTERS_DELETE_CONFIRMATION_NO_CONNECT_DESCR,
  confirmButtonLabel: messages.DELETE,
  showCancelButton: true
}

const confirmWithConnectConfig = (tenantId, connections) => {
  const { workflow, widget } = splitArrayByType(connections, 'connectionType')
  const count = connections.length

  const deleteTitle = (
    <span>
      {messages.FILTERS_DELETE_CONFIRMATION_WITH_CONNECT_TITLE_PART_ONE}
      <span className="delete-filters-confirm-count">{count}</span>
      {messages.FILTERS_DELETE_CONFIRMATION_WITH_CONNECT_TITLE_PART_TWO}
    </span>
  )

  return {
    className: 'delete-filters-confirm-with-connect',
    confirmButtonLabel: messages.CLOSE,
    showCancelButton: false,
    title: deleteTitle,
    description: (
      <div>
        <i className="icon icon-warning connections-warning" />
        <div>{messages.FILTERS_DELETE_CONFIRMATION_WITH_CONNECT_DESCR}</div>
        <div className="delete-filters-confirm-body-wrapper">
          <div className="delete-filters-confirm-subheading">Connections ({count})</div>
          <ConnectionsListGroup>
            <ConnectionsListGroup.ConnectionsListWrapper
              tenantId={tenantId}
              workflowList={workflow}
              widgetList={widget}
            />
          </ConnectionsListGroup>
        </div>
      </div>
    )
  }
}

const FilterDeleteConfirmation = props => {
  const {
    tenantId,
    deleteConfirmationState: { show, isLoading, connections = [] },
    onDeleteConfirm,
    onDeleteCancel
  } = props

  const hasConnections = !!connections.length

  const currentConfirmConfig = hasConnections
    ? confirmWithConnectConfig(tenantId, connections)
    : confirmWithoutConnectConfig

  const onConfirm = hasConnections ? onDeleteCancel : onDeleteConfirm

  return (
    <ConfirmationModal
      descriptionAs="div"
      size="md"
      show={show}
      busy={isLoading}
      className={currentConfirmConfig.className}
      showCancelButton={currentConfirmConfig.showCancelButton}
      onModalConfirm={onConfirm}
      onModalCancel={onDeleteCancel}
      title={currentConfirmConfig.title}
      description={currentConfirmConfig.description}
      confirmButtonLabel={currentConfirmConfig.confirmButtonLabel}
    />
  )
}

export default FilterDeleteConfirmation
