import { useCallback, useLayoutEffect, useState } from 'react'
import { Button } from '@praxie/shared'
import { Status } from 'nice-grpc-common'
import CloseIcon from 'assets/images/icons/ic_cross_xl_B5BFC7.svg?react'
import WarningIcon from 'assets/images/icons/ic_workflow_warning.svg?react'
import messages from 'constants/messages'
import { getQueryWidgetData } from 'api/tenantAPI'
import { parseOptionsFromQuery } from 'helpers/widgetDataConvertorsHelprers'
import RoundIconButton from 'components/buttons/RoundIconButton'
import { Dialog, DialogActions, DialogContent, DialogTitle } from 'components/common/dialog/dialog'
import { TextField } from 'components/common/textField/textField'
import { DEFAULT_OPTIONS } from 'features/dictionaries/dictionariesSettings.constants'
import {
  parseServerErrorsToMessage,
  validateDictionaryData
} from 'features/dictionaries/dictionariesSettings.helpers'
import {
  type IDictionaryData,
  type IQueryWidgetResponse,
  type TDictionaryError,
  type TDictionaryModalData,
  type TOption,
  type TQueryWidget,
  EQueryOutputTypes
} from 'features/dictionaries/dictionariesSettings.types'
import { OptionsTable } from 'features/dictionaries/optionsTable/optionsTable'
import { QuerySection } from '../querySection/querySection'
import './dictionary-modal.scss'

type TProps = {
  isOpen: boolean
  dictionaryData?: TDictionaryModalData | null
  onSave: (dictionaryData: Omit<TDictionaryModalData, 'id'>) => Promise<unknown>
  onClose: () => void
  saveBtnText?: string
}

const HINT_TEXT = 'Hint text'

export const DictionaryModal = ({
  isOpen,
  dictionaryData,
  onSave,
  onClose,
  saveBtnText = messages.SAVE
}: TProps) => {
  const [name, setName] = useState<IDictionaryData['name']>(dictionaryData?.name || '')
  const [options, setOptions] = useState<TOption[]>(dictionaryData?.options ?? DEFAULT_OPTIONS)
  const [queryOptions, setQueryOptions] = useState<TOption[]>([])
  const [areOptionsLoading, setAreOptionsLoading] = useState(false)
  const [hint, setHint] = useState<IDictionaryData['hint']>(dictionaryData?.hint)
  const [areOptionsFromPlainList, setAreOptionsFromPlainList] = useState<boolean>(false)
  const [isQueryWidgetLoading, setIsQueryWidgetLoading] = useState(false)
  const [queryWidget, setQueryWidget] = useState<IDictionaryData['queryWidget']>(
    dictionaryData?.queryWidget
  )
  const [error, setError] = useState<TDictionaryError | null>(null)
  const [isSaving, setIsSaving] = useState(false)

  useLayoutEffect(() => {
    if (isOpen && dictionaryData) {
      setName(dictionaryData.name)
      setOptions(dictionaryData.options)
      setHint(dictionaryData.hint)
      setQueryWidget(dictionaryData.queryWidget)
    }
  }, [isOpen, dictionaryData])

  const fetchQueryOptions = useCallback(async (payload: TQueryWidget) => {
    setAreOptionsLoading(true)
    setAreOptionsFromPlainList(false)

    try {
      const { data } = (await getQueryWidgetData(payload)) as IQueryWidgetResponse

      const _options = data.data

      const parsedOptions = parseOptionsFromQuery(_options) as TOption[]
      setError(err => ({ ...err, query: false }))
      setQueryOptions(parsedOptions)
      setAreOptionsFromPlainList(data.outputType === EQueryOutputTypes.plainList)
    } catch (e) {
      setError(err => ({ ...err, query: true }))
    } finally {
      setAreOptionsLoading(false)
    }
  }, [])

  const resetModalData = () => {
    setName('')
    setIsSaving(false)
    setOptions(DEFAULT_OPTIONS)
    setQueryOptions([])
    setError(null)
    setHint(undefined)
    setQueryWidget(undefined)
  }

  const handleClose = () => {
    resetModalData()
    onClose()
  }

  const handleValidateData = (options: TOption[]) => {
    setError(null)
    const { errors, isValid } = validateDictionaryData({
      name,
      options,
      hint
    })

    if (!isValid) {
      setError(errors)
    }

    return isValid
  }

  const handleSubmit = () => {
    const isValid = handleValidateData(options)
    if (!isValid) return

    setIsSaving(true)

    onSave({
      name,
      options,
      hint,
      queryWidget
    })
      .then(() => handleClose())
      .catch((e: unknown) => {
        const error = e as { code: Status; message: string }
        if (error.code === Status.INVALID_ARGUMENT) {
          const parsedDictionaryError = parseServerErrorsToMessage(error)

          setError(prevErrors => ({ ...prevErrors, serverError: parsedDictionaryError }))
        }
      })
      .finally(() => setIsSaving(false))
  }

  const onChangeHintEnabled = (isHintEnabled: boolean) =>
    setHint({ flag: isHintEnabled, textMessage: hint?.textMessage || HINT_TEXT })

  const onChangeQueryEnabled = (isQueryEnabled: boolean) => {
    setQueryWidget({
      flag: isQueryEnabled,
      queryId: queryWidget?.queryId || '',
      cardId: queryWidget?.cardId || '',
      boardId: queryWidget?.boardId || ''
    })
  }

  const onChangeHintValue = (value: string) => setHint({ flag: true, textMessage: value })

  const onChangeQueryValue = ({
    queryId,
    cardId,
    boardId
  }: {
    queryId: string
    cardId: string
    boardId: string
  }) => {
    setQueryWidget({ flag: true, queryId, cardId, boardId })
    setError(err => ({ ...err, query: false }))
  }

  const onChangeDictionaryName = (dictionaryName: string) => {
    const updatedErrors = error ? { ...error, name: false } : null
    setError(updatedErrors)
    setName(dictionaryName)
  }

  const onChangeOptionValue = (optionIndex: number, value: string) => {
    const updatedErrors = error
      ? {
          ...error,
          options: error.options?.map((errorItem, errorIndex) =>
            errorIndex === optionIndex ? false : errorItem
          )
        }
      : null
    setOptions(
      options.map((option, index) => (index === optionIndex ? { ...option, value } : option))
    )
    setError(updatedErrors)
  }

  const handleGetOptionErrorText = ({ index, value }: { index: number; value: string }) => {
    if (error?.options?.[index]) {
      return value ? '' : "You can't leave this empty."
    }
    return ''
  }

  const handleUpdateOptions = (updatedOptions: TOption[]) => {
    setOptions(updatedOptions)
    handleValidateData(updatedOptions)
  }

  const isEditMode = !!dictionaryData?.id
  const isQueryOptions = queryWidget?.flag && queryWidget.queryId

  return (
    <Dialog className="dictionary-modal" open={isOpen} onClose={handleClose}>
      <DialogTitle className="header">
        <div className="title"> {messages.DICTIONARY_CONFIGURATION}</div>
        <div className="close-button">
          {/* @ts-expect-error */}
          <RoundIconButton size="medium" onClick={handleClose}>
            <CloseIcon title={messages.CLOSE} />
          </RoundIconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        {isEditMode && (
          <div className="edit-warning">
            <div className="title">
              <WarningIcon />
              Heads up!
            </div>
            Any changes made to this list will be applied across the system within this team,
            including previously selected options by users.
          </div>
        )}
        <div className="dictionary-name">
          <TextField
            className="form-field dictionary-name-input"
            defaultValue={name}
            size={34}
            placeholder={messages.DICTIONARY_NAME_PLACEHOLDER}
            label="Name"
            error={!!error?.name}
            inputProps={{ maxLength: 50 }}
            helperText={error?.name ? messages.emptyField : ''}
            onChange={({ target }) => onChangeDictionaryName(target.value)}
          />
        </div>
        <QuerySection
          isQueryEnabled={!!queryWidget?.flag}
          queryError={error?.query}
          queryWidget={queryWidget}
          setError={setError}
          fetchQueryOptions={fetchQueryOptions}
          isQueryWidgetLoading={isQueryWidgetLoading}
          setIsQueryWidgetLoading={setIsQueryWidgetLoading}
          onChangeQueryEnabled={() => onChangeQueryEnabled(!queryWidget?.flag)}
          onChangeQueryValue={onChangeQueryValue}
        />
        <OptionsTable
          isHintEnabled={!!hint?.flag}
          hintMessage={hint?.textMessage || HINT_TEXT}
          options={isQueryOptions ? queryOptions : options}
          addNewOption={() => setOptions([...options, { value: '' }])}
          deleteOption={(optionIndex: number) =>
            setOptions(options.filter((_, index) => index !== optionIndex))
          }
          changeOptionValue={onChangeOptionValue}
          changeHintValue={onChangeHintValue}
          updateOptions={handleUpdateOptions}
          areOptionsFromPlainList={areOptionsFromPlainList}
          isQuerySelected={!!queryWidget?.flag && !!queryWidget.queryId}
          isLoading={areOptionsLoading || isQueryWidgetLoading}
          queryError={error?.query}
          hintError={error?.hintMessage}
          optionsError={error?.options ?? []}
          getOptionErrorText={handleGetOptionErrorText}
          onChangeHintEnabled={() => onChangeHintEnabled(!hint?.flag)}
        />
      </DialogContent>
      <DialogActions className="footer">
        {error?.hasDuplicatedOptions && <div className="server-error">Options must be unique</div>}
        {error?.serverError && <span className="server-error">{error.serverError}</span>}
        <Button appearance="secondary" className="cancel" onClick={handleClose}>
          {messages.CANCEL}
        </Button>
        <Button className="save" busy={isSaving} onClick={handleSubmit}>
          {saveBtnText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
