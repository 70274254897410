import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from '@redux-devtools/extension'
import createSocketIoMiddleware from 'redux-socket.io'

import createEventBusMiddleware from 'features/widgets/eventBus/eventBusMiddleware'
import { registerListenersSocket, getSocket } from 'helpers/socketHelpers'
import app from './initialStates/app'
import appBuilder from './initialStates/appBuilder'
import auth from './initialStates/auth'
import board from './initialStates/board'
import widget from './initialStates/widget'
import profile from './initialStates/profile'
import spinner from './initialStates/spinner'
import builder from './initialStates/builder'
import teamAdministration from './initialStates/teamAdministration'
import notifications from './initialStates/notifications'
import drawer from './initialStates/drawer'
import socket from './initialStates/socket'
import workspacesAdministration from './initialStates/workspacesAdministration'
import workflow from './initialStates/workflow'
import clickWorkflow from './initialStates/clickWorkflow'
import filters from './initialStates/filters'
import workspaceMembers from './initialStates/workspaceMembers'
import detailed from './initialStates/detailed'
import ribbon from './initialStates/ribbon'
import variables from './initialStates/variables'
import rootReducer from '../reducers'

const eventBusMiddleware = createEventBusMiddleware()

export const REDUX_STORE_INITIAL_STATE = {
  app,
  appBuilder,
  auth,
  board,
  widget,
  profile,
  spinner,
  builder,
  teamAdministration,
  notifications,
  drawer,
  socket,
  workspacesAdministration,
  workflow,
  clickWorkflow,
  filters,
  workspaceMembers,
  detailed,
  ribbon,
  variables
}

export default function configureStore(enhancers = []) {
  const middlewares = [thunk, eventBusMiddleware]

  const socketObj = getSocket()
  const socketIoMiddleware = createSocketIoMiddleware(socketObj)
  middlewares.push(socketIoMiddleware)

  const store = createStore(
    rootReducer,
    REDUX_STORE_INITIAL_STATE,
    composeWithDevTools(applyMiddleware(...middlewares), ...enhancers)
  )
  registerListenersSocket(store)

  window.getStore = () => store

  return store
}
