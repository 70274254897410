import gql from 'graphql-tag'

import BoardFragment from '../fragments/boardFragment'
import CardFragment from '../fragments/cardFragment'

export const getBoardQuery = expand => {
  const { board = false, cards = null } = expand

  const boardFragment = board ? BoardFragment.fragment : ''
  const cardFragment = cards ? CardFragment.fragment(cards) : ''

  return gql`
        query getBoard ($organizationId: ID!, $tenantId: ID!, $boardId: ID!) {
            organization(id: $organizationId) {
                tenant(id: $tenantId) {
                    board(id: $boardId) {
                        ${board ? `...${BoardFragment.name}` : ''}
                        ${cards ? `cards {...${CardFragment.name}}` : ''}
                    }
                }
            }
        }
        ${boardFragment}
        ${cardFragment}
    `
}

export const getColumnQuery = expand => {
  const { column = false, cards = null } = expand

  const cardFragment = CardFragment.fragment(cards)

  return gql`
        query getColumn ($organizationId: ID!, $tenantId: ID!, $boardId: ID!, $columnUuid: ID!) {
            organization(id: $organizationId) {
                tenant(id: $tenantId) {
                    board(id: $boardId) {
                        ${column ? `column (uuid: $columnUuid) {...${CardFragment.name}}` : ''}
                    }
                }
            }
        }
        ${cardFragment}
    `
}

export const getBoardsQuery = expand => {
  const { board = false, cards = null } = expand

  const boardFragment = board ? BoardFragment.fragment : ''
  const cardFragment = cards ? CardFragment.fragment(cards) : ''

  return gql`
        query getBoards ($organizationId: ID!, $tenantId: ID!, $boardIds: [ID!]) {
            organization(id: $organizationId) {
                tenant(id: $tenantId) {
                    boards(ids: $boardIds) {
                        ${board ? `...${BoardFragment.name}` : ''}
                        ${cards ? `cards {...${CardFragment.name}}` : ''}
                    }
                }
            }
        }
        ${boardFragment}
        ${cardFragment}
    `
}
