import { requestWrapper, url } from 'helpers/fetchHelpers'

const constructBasicAuthString = ({ email, password }) =>
  `Basic ${btoa(`${email}:${atob(password)}`)}`

export async function getHomePageConfigRequest() {
  const response = await requestWrapper({
    rUrl: `${url}configs/homepage`,
    type: 'GET'
  })

  return response.data
}

export function getUserRequest() {
  return requestWrapper({
    rUrl: `${url}user`,
    type: 'GET',
    payload: {},
    hasServerErrorHandling: true
  })
}

export function getBoardMenuRequest() {
  return requestWrapper({
    rUrl: `${url}organization/menu`,
    type: 'GET',
    payload: {},
    hasServerErrorHandling: true
  })
}

export function saveUserProfile({ userId, data }) {
  return requestWrapper({
    rUrl: `${url}user/${userId}`,
    type: 'PUT',
    payload: data
  })
}

export function getJiraProjectsRequest(payload) {
  return requestWrapper({
    rUrl: `${payload.url}/rest/api/latest/project`,
    type: 'GET',
    payload: {},
    authorization: constructBasicAuthString(payload)
  })
}

export function getJiraIssueTypesRequest(payload) {
  return requestWrapper({
    rUrl: `${payload.url}/rest/api/latest/issuetype`,
    type: 'GET',
    payload: {},
    authorization: constructBasicAuthString(payload)
  })
}

export function getJiraStatusesRequest(payload) {
  return requestWrapper({
    rUrl: `${payload.url}/rest/api/latest/status`,
    type: 'GET',
    payload: {},
    authorization: constructBasicAuthString(payload)
  })
}

export function getJiraUsersRequest(payload) {
  return requestWrapper({
    rUrl: `${payload.url}/rest/api/latest/groupuserpicker?query=''&projectId=${payload.projectId}`,
    type: 'GET',
    payload: {},
    authorization: constructBasicAuthString(payload)
  })
}

export function getJiraComponentsRequest(payload) {
  return requestWrapper({
    rUrl: `${payload.url}/rest/api/latest/project/${payload.projectId}/components`,
    type: 'GET',
    payload: {},
    authorization: constructBasicAuthString(payload)
  })
}

export function getJiraFiltersRequest(payload) {
  return requestWrapper({
    rUrl: `${payload.url}/rest/api/latest/filter/favourite`,
    type: 'GET',
    payload: {},
    authorization: constructBasicAuthString(payload)
  })
}

export function getJiraTestRequest(payload) {
  return requestWrapper({
    rUrl: `${payload.url}/rest/api/latest/search`,
    type: 'GET',
    payload: {},
    authorization: constructBasicAuthString(payload)
  })
}

export function getProfileNotificationsRequest() {
  return requestWrapper({
    rUrl: `${url}notification/configs`,
    type: 'GET',
    payload: {}
  })
}

export function saveProfileNotifications(payload) {
  return requestWrapper({
    rUrl: `${url}notification/configs`,
    type: 'PUT',
    payload: payload.data
  })
    .then(response => response.data)
    .catch(err => console.error(err))
}
