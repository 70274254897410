import React from 'react'
import classNames from 'classnames'

import { Switcher } from 'components/common/switcher/switcher'

import messages from '../../../constants/messages'
import { OPERATOR } from '../../../constants/filters/filtersConstants'

import '../../../scss/filters/conditionsModal/rules-modal-controls.scss'

const RulesModalControls = props => {
  const { operator, toggleOperator, className, children } = props

  return (
    <div className={classNames('rules-modal-controls', className)}>
      {children}
      <div className="rules-satisfy-switcher-label">{messages.COMBINE_WITH}</div>
      <Switcher
        id="rules-satisfy-switcher"
        className="rules-satisfy-switcher"
        checked={operator === OPERATOR.AND}
        onChange={toggleOperator}
        onLabel={messages.OR.toUpperCase()}
        offLabel={messages.AND.toUpperCase()}
      />
    </div>
  )
}

export default RulesModalControls
