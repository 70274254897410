import React from 'react'

import EmptyState from '../../common/EmptyState'
import messages from '../../../constants/messages'

const ConnectionsEmptyList = ({ isShown }) => {
  return (
    <EmptyState
      as="div"
      show={isShown}
      className="connections-dropdown-empty"
      firstRow={messages.NO_WORKFLOW_CONNECTIONS}
      secondRow={messages.NO_WORKFLOW_CONNECTIONS_DESC}
    />
  )
}

export default ConnectionsEmptyList
