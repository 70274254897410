export const extractVariables = ({ data }) => {
  const {
    organization: {
      tenant: {
        variables: { nodes: variables, pageInfo, total, filterTotal }
      }
    }
  } = data
  return {
    variables,
    pageInfo,
    total,
    filterTotal
  }
}

export const extractConnections = ({ data }) => {
  const {
    organization: {
      tenant: {
        variable: { connections }
      }
    }
  } = data
  return connections
}
