const drawer = {
  isDrawerShown: false,
  isDrawerCardsRequested: false,
  isDrawerDragDisabled: false,
  drawerCards: [],

  drawerPreviewData: {
    state: false,
    cardUuid: null,
    boardId: null,
    tenantId: null
  }
}

export default drawer
