const socket = {
  isSubscribedToBoard: false,
  isSubscribedToComments: false,
  isSubscribedToMonitor: false,
  subscribedCard: {},
  subscribedJobs: [],
  subscribedMonitor: {},
  isWatchingBoard: false,
  watchingBoard: {},
  watchingUsers: [],
  jobs: [],
  isBoardCopying: false
}

export default socket
