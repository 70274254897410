import {
  type ComponentType,
  type MouseEvent,
  type MouseEventHandler,
  useEffect,
  useRef,
  useState
} from 'react'
import { type PopoverOrigin } from '@mui/material/Popover/Popover'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import { fetchVariableConnections } from 'actions/variablesActions'
import { makeCancelable } from 'helpers/fetchHelpers'
import { Menu } from 'components/common/menu/menu'
import { ConnectionList } from './connectionList/connectionList'
import { type TVariableConnection } from './variableConnections.types'
import './variable-connections.scss'

type TToggleProps = {
  onToggle: MouseEventHandler<HTMLDivElement>
  isActive: boolean
  connections: number
}

const DefaultToggle = ({ onToggle, isActive, connections }: TToggleProps) => (
  <div className={classNames('connections-toggle', { disabled: !isActive })} onClick={onToggle}>
    <span className="connection-text">{connections}</span>
  </div>
)

type TProps = {
  tenantId: string
  variableId: string
  connectionsNumber: number
  placement: 'top' | 'bottom'
  Toggle?: ComponentType<TToggleProps>
}

const PLACEMENT_ORIGINS: Record<
  'top' | 'bottom',
  {
    anchorOrigin: PopoverOrigin
    transformOrigin: PopoverOrigin
  }
> = {
  top: {
    anchorOrigin: { vertical: 'top', horizontal: 'center' },
    transformOrigin: { vertical: 'bottom', horizontal: 'center' }
  },
  bottom: {
    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
    transformOrigin: { vertical: 'top', horizontal: 'center' }
  }
}

export const VariableConnections = ({
  tenantId,
  variableId,
  connectionsNumber,
  placement = 'bottom',
  Toggle = DefaultToggle
}: TProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [connections, setConnections] = useState<TVariableConnection[]>([])

  const anchorEl = useRef<HTMLDivElement | null>(null)
  const dispatch = useDispatch()

  const isActive = connectionsNumber > 0

  useEffect(() => {
    let request: { promise: Promise<TVariableConnection[]>; cancel: () => void }

    if (isOpen) {
      setIsFetching(true)

      request = makeCancelable(
        dispatch(
          // @ts-expect-error
          fetchVariableConnections({
            variableId,
            tenantId
          })
        )
      )

      request.promise
        .then(data => {
          setConnections(data)
        })
        // eslint-disable-next-line @typescript-eslint/use-unknown-in-catch-callback-variable
        .catch((err: { message: string; value: unknown }) => {
          if (err.message !== 'isCanceled') {
            console.error(err.value)
          }
        })
        .finally(() => {
          setIsFetching(false)
        })
    }

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (request) {
        request.cancel()
        setIsFetching(false)
      }
    }
  }, [dispatch, isOpen, variableId, tenantId])

  const closePopover = () => {
    setIsOpen(false)
  }

  const openPopover = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()

    if (isActive) {
      setIsOpen(true)
    }
  }

  const { anchorOrigin, transformOrigin } = PLACEMENT_ORIGINS[placement]

  return (
    <div ref={anchorEl} className="variable-connections">
      <Toggle isActive={isActive} connections={connectionsNumber} onToggle={openPopover} />
      <Menu
        open={isOpen}
        anchorEl={anchorEl.current}
        className={classNames('variable-connections-dropdown-menu', placement)}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onClose={closePopover}
        onClick={e => e.stopPropagation()}
      >
        <ConnectionList connections={connections} loading={isFetching} />
      </Menu>
    </div>
  )
}
