import messages from '../../constants/messages'
import { flagsList, hasFlag } from '../widget/widgetDataHelpers'

export const workflowEmulationQueryParam = 'buttonWidgetUuid'

export const NO_WIDGET_ERROR = 'NO_WIDGET_ERROR'
export const WORKFLOW_MODE_ERROR = 'WIDGET_IS_NOT_IN_WORKFLOW_MODE'

const workflowPayloadErrors = {
  [NO_WIDGET_ERROR]: messages.NO_WIDGET_TO_RUN_WORKFLOW,
  [WORKFLOW_MODE_ERROR]: messages.WIDGET_IS_NOT_IN_WORKFLOW_MODE
}

export const getWorkflowPayloadErrors = error => workflowPayloadErrors[error] || null

export const getWorkflowPayloadByWidgetId = ({ widgetUuid, widgets }) => {
  const widget = widgets.find(item => item.uuid === widgetUuid)

  if (!widget || (widget && !hasFlag(widget.flags, flagsList.isClickTrigger))) {
    return [NO_WIDGET_ERROR, null]
  }

  if (!widget.workflowMeta || (widget.workflowMeta && !widget.workflowMeta.isWorkflowMode)) {
    return [WORKFLOW_MODE_ERROR, null]
  }

  return [
    null,
    {
      tenantId: widget.tenantId,
      boardId: widget.boardId,
      cardUuid: widget.cardUuid,
      widgetUuid,
      workflowMeta: widget.workflowMeta
    }
  ]
}

export const getSourceCardFromLinked = ({ cards, cardUuid }) => {
  const card = cards.find(({ uuid }) => uuid === cardUuid)

  if (card && card.isLinked) {
    return card.linkToSrcCard || {}
  }

  return null
}
