import messages from '../../constants/messages'

export const ONE_STAGE_LEFT = 'oneStageLeft'
export const ONE_STAGE_RIGHT = 'oneStageRight'
export const NOT_STARTED_YET_STAGE = 'notStarted'
export const COMPLETE_STAGE = 'completeStage'
export const INPUT_FIELD_EQUAL = 'inputField'
export const STRING_INPUT_EQUAL = 'stringInputField'

export const SWITCH_WIDGET_WAYS_CONFIGURATION = {
  [ONE_STAGE_LEFT]: { value: ONE_STAGE_LEFT, label: messages.MOVE_ONE_STAGE_LEFT },
  [ONE_STAGE_RIGHT]: { value: ONE_STAGE_RIGHT, label: messages.MOVE_ONE_STAGE_RIGHT },
  [NOT_STARTED_YET_STAGE]: { value: NOT_STARTED_YET_STAGE, label: messages.MOVE_TO_NOT_STARTED },
  [COMPLETE_STAGE]: { value: COMPLETE_STAGE, label: messages.MOVE_TO_COMPLETE_STAGE },
  [INPUT_FIELD_EQUAL]: { value: INPUT_FIELD_EQUAL, label: messages.MOVE_TO_NAME_INPUT_FIELD },
  [STRING_INPUT_EQUAL]: { value: STRING_INPUT_EQUAL, label: messages.MOVE_TO_NAME_INPUT_STRING }
}

export const SWITCH_WIDGET_WAYS_OPTIONS = Object.values(SWITCH_WIDGET_WAYS_CONFIGURATION)
