import React from 'react'
import UserLogo from 'components/common/UserLogo'
import 'scss/common/user/triple-logo.scss'

const TripleLogo = props => {
  const { users } = props

  const visibleUsers = users.slice(0, 3)

  const title = users?.map(user => user?.username)?.join(', ') || ''

  return (
    <div className="pw-triple-logo" title={title}>
      {visibleUsers.map(user => (
        <UserLogo
          key={user.userId}
          avatarImage={user.avatarImage}
          username={user.username}
          size="extra-small-24px"
        />
      ))}
      <div className="users-counter">{users.length > 100 ? '+99' : users.length}</div>
    </div>
  )
}

export default TripleLogo
