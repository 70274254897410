import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useEffectOnce } from 'react-use'
import { useValueRef } from 'helpers/customHooks'
import { showToastMessage, hideToastMessage } from 'actions'
import messages from 'constants/messages'
import { TOAST_MESSAGE_TYPE } from 'constants/boardActionsTypes'

export const TOGGLE_SESSION_EXPIRATION_NOTIFICATION = 'TOGGLE_SESSION_EXPIRATION_NOTIFICATION'

type TToastMessageData = {
  type?: string
  size: string
  message: string
}

export const useSessionExpirationNotification = () => {
  const dispatch = useDispatch()

  const toastMessageIdRef = useValueRef(null)

  const toggleSessionExpirationNotification = useCallback(
    (e: CustomEvent<TToastMessageData>) => {
      if (!toastMessageIdRef.current && e.detail?.type === 'show') {
        // @ts-ignore
        toastMessageIdRef.current = dispatch(
          // @ts-ignore
          showToastMessage(
            {
              size: 'M',
              text: messages.SESSION_EXPIRED_TOAST_MESSAGE,
              type: TOGGLE_SESSION_EXPIRATION_NOTIFICATION
            },
            TOAST_MESSAGE_TYPE,
            Infinity
          )
        )
      }

      if (e.detail?.type === 'hide') {
        // @ts-ignore
        dispatch(hideToastMessage(toastMessageIdRef.current))
        toastMessageIdRef.current = null
      }
    },
    [dispatch, toastMessageIdRef]
  )

  useEffectOnce(() => {
    document.addEventListener(
      TOGGLE_SESSION_EXPIRATION_NOTIFICATION,
      toggleSessionExpirationNotification as EventListener
    )

    return () => {
      document.removeEventListener(
        TOGGLE_SESSION_EXPIRATION_NOTIFICATION,
        toggleSessionExpirationNotification as EventListener
      )
    }
  })
}
