import React, { Component } from 'react'
import { connect } from 'react-redux'

import { CopyBoardsIndicatorItem } from '../../components/jobsIndicator/copyBoardsIndicatorItem'
import JobsIndicator from '../../components/jobsIndicator/JobsIndicator'
import jobsIndicatorHOC from '../hoc/jobsIndicatorHOC'

import * as actions from '../../actions/index'
import messages from '../../constants/messages'
import { EJobStatuses, EJobTypes } from '../../features/jobs/jobs.types'

const JOB_TYPES = [EJobTypes.COPY_BOARD_JOB]

const getTitleText = jobs => {
  const activeJobs = jobs.filter(item => item.status !== EJobStatuses.DONE)

  if (!activeJobs.length) {
    return messages.COPIYNG_BOARD_DONE(jobs.length)
  }
  return messages.COPIYNG_BOARD_ACTIVE(activeJobs.length)
}

class CopyBoardsIndicatorContainer extends Component {
  removeJob(job) {
    const { jobs } = this.props

    const hasActive = jobs.some(item => item.status !== EJobStatuses.DONE)

    this.props.removeJob(job)

    if (!hasActive) {
      this.props.toggleJobsIndicator(false)
    }
  }

  render() {
    const {
      isIndicatorHidden,
      jobs,
      visible,
      invisible,
      width,
      transform,
      onMessageHide,
      onToggleHideJobsIndicator,
      onCloseJobsIndicator,
      retryCopyBoard
    } = this.props

    return (
      <JobsIndicator
        title={getTitleText(jobs)}
        closeTooltipText={messages.CLOSE_COPY_WINDOW}
        visible={visible}
        invisible={invisible}
        width={width}
        transform={transform}
        onMessageHide={onMessageHide}
        hidden={isIndicatorHidden}
        onCloseJobsIndicator={onCloseJobsIndicator}
        onToggleHideJobsIndicator={onToggleHideJobsIndicator}
      >
        {jobs.map(job => (
          <CopyBoardsIndicatorItem
            key={job.id}
            job={job}
            retryCopyBoard={retryCopyBoard.bind(null, job)}
            onLinkClick={this.removeJob.bind(this, job)}
          />
        ))}
      </JobsIndicator>
    )
  }
}

function mapStateToProps(state) {
  return {
    jobs: state.socket.jobs.filter(job => JOB_TYPES.includes(job.type))
  }
}

const mapDispatchToProps = {
  toggleJobsIndicator: actions.toggleCopyBoardsIndicator,
  retryCopyBoard: actions.retryCopyBoard,
  removeJob: actions.removeJob
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(jobsIndicatorHOC(CopyBoardsIndicatorContainer))
