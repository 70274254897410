import { generatePath } from 'react-router-dom'
import { createPath } from 'history'
import { PATHS } from 'constants/paths.constants'
import { TOGGLE_SESSION_EXPIRATION_NOTIFICATION } from 'containers/common/appContainer/hooks/useSessionExpirationNotification'
import { history } from 'services/common/history.service'
import { getUrlParam } from 'helpers/fetchHelpers'
import { CARD_OPENING_MODE } from 'constants/appBuilder/navItemsConstants'

const DEFAULT_CARD_IDS_COUNT = 4

export const goToLink = link => history.push(link)

export const buildSearchParamsString = queries => {
  const searchParams = new URLSearchParams()

  Object.entries(queries).forEach(([key, value]) => {
    if (value) searchParams.set(key, String(value))
  })

  return searchParams.toString()
}

export const routesList = {
  cardBuilder: 'cardBuilder',
  tenant: 'tenant',
  detailedView: 'detailedView',
  board: 'board',
  homepage: 'homepage',
  orgAdministration: 'orgAdministration'
}

const getOpenedFromURLParam = routePath =>
  window.location.pathname === routePath
    ? getUrlParam(window.location.search, 'openedFrom')
    : `${window.location.pathname}${window.location.search}`

const routes = {
  [routesList.cardBuilder]: {
    regExp: /^\/cardbuilder\//i
  },
  [routesList.tenant]: {
    regExp: /^\/tenant\//i
  },
  [routesList.detailedView]: {
    regExp: /^\/board\/.+\/.+\/.+/i
  },
  [routesList.board]: {
    regExp: /^\/board\/|^\/$/i
  },
  [routesList.homepage]: {
    regExp: /^\/homepage/i
  },
  [routesList.orgAdministration]: {
    regExp: /^\/organization-administration\//i
  }
}

const isAuthCheckNeeded = () => {
  // to skip redirect on login page
  // url where auth don't need
  // Redirect the user to the authorization page if user logged in and stayed on the "signIn" page, when his JWT expired.
  const urls = [PATHS.auth.url, PATHS.signUp.url, PATHS.reset.url]
  const pathname = window.location.pathname

  return !urls.some(item => pathname.startsWith(item))
}

export const checkRoute = (pathname, route) => {
  const routeMeta = routes[route]

  if (!routeMeta) {
    throw new Error(`route ${route} doesn't exist`)
  }

  return routeMeta.regExp.test(pathname)
}

export const signInNavigation = goToUpBoard => {
  if (goToUpBoard) {
    const search = new URLSearchParams(history.location.search)
    const isEntitlementFlow = !!search.get('entitlementId')

    history.push({
      pathname: PATHS.homepage.url,
      search: isEntitlementFlow ? history.location.search : undefined
    })
  } else {
    history.push({
      pathname: PATHS.signIn.url,
      search: history.location.search
    })
  }
}

export const navigateToWorkflowBuilder = ({ tenantId, boardId, workflowId = 'new' }) => {
  const location = generatePath(PATHS.workflow.routerPath, { tenantId, boardId, workflowId })

  history.push(location)
}

export const navigateToBoardAdministration = ({ tenantId, boardId, tab }) => {
  const pathname = generatePath(PATHS.boardAdministration.routerPath, { tenantId, boardId })
  const openedFrom = getOpenedFromURLParam(pathname)

  const search = buildSearchParamsString({ tab, openedFrom })

  history.push({ pathname, search })
}

export const getFullscreenDetailedViewRoute = ({ tenantId, boardId, cardUuid }) => {
  const pathname = generatePath(PATHS.board.routerPath, { tenantId, boardId, cardID: cardUuid })
  const search = buildSearchParamsString({ presentation_mode: true })

  return createPath({ pathname, search })
}

export const getDetailedViewRoute = ({ tenantId, boardId, cardUuid, showComments }) => {
  const pathname = generatePath(PATHS.board.routerPath, { tenantId, boardId, cardID: cardUuid })
  const search = buildSearchParamsString({ showComments })

  return createPath({ pathname, search })
}

export function navigateToDetailedView(args) {
  // eslint-disable-next-line no-undef
  ga('send', 'event', 'Card', 'Load')

  const location = getDetailedViewRoute(args)

  history.push(location)
}

export const getAppViewRoute = ({ tenantId, boardId, cardUuid }) =>
  generatePath(PATHS.app.routerPath, { tenantId, boardId, cardID: cardUuid || '' })

const matchPathname = pathname => {
  const detailedRegex = /^(\/board\/[0-9a-z-]+\/[0-9]+\/[0-9a-z-]+)$/
  const boardRegex = /^(\/board\/[0-9a-z-]+\/[0-9]+)$/
  const embedRegex = /^(\/embed\/[0-9a-z-]+\/[0-9]+\/[0-9a-z-]+)$/
  const appViewRegex = /^(\/app\/[0-9a-z-]+\/[0-9]+\/[0-9a-z-]+)$/
  const builderRegex = /^(\/cardbuilder\/[0-9a-z-]+\/[0-9]+\/[0-9a-z-]+)$/
  const isRecentBoard = pathname === '/'

  if (boardRegex.test(pathname) || isRecentBoard) {
    return 'preview'
  }
  if (detailedRegex.test(pathname) || embedRegex.test(pathname) || appViewRegex.test(pathname)) {
    return 'detailed'
  }
  if (builderRegex.test(pathname)) {
    return 'builder'
  }
  return ''
}

const navigateToCardLink = ({
  tenantId,
  cardID,
  boardId,
  newCardId,
  newBoardId,
  newTenantId,
  match,
  pathname,
  mode,
  searchParams
}) => {
  if (mode) {
    const path = PATHS[mode]?.routerPath || PATHS.board.routerPath
    const newPath = generatePath(path, {
      tenantId: newTenantId,
      boardId: newBoardId,
      cardID: newCardId
    })

    history.push({ pathname: newPath, search: searchParams ? `?${searchParams}` : '' })

    return
  }
  if (match === 'preview') {
    navigateToDetailedView({ tenantId: newTenantId, boardId: newBoardId, cardUuid: newCardId })
  } else if (match === 'detailed') {
    const newPath = pathname
      .replace(tenantId, newTenantId)
      .replace(boardId, newBoardId)
      .replace(cardID, newCardId)

    history.push({ pathname: newPath, search: searchParams ? `?${searchParams}` : '' })
  } else {
    const newPath = generatePath(PATHS.board.routerPath, {
      tenantId: newTenantId,
      boardId: newBoardId,
      cardID: newCardId
    })

    window.open(`${window.location.origin}${newPath}`, '_blank')
  }
}

const navigateToBoardLink = ({ newTenantId, newBoardId, match }) => {
  const newPath = generatePath(PATHS.board.routerPath, {
    tenantId: newTenantId,
    boardId: newBoardId
  })

  if (match === 'builder') {
    window.open(`${window.location.origin}${newPath}`, '_blank')
  } else {
    history.push(newPath)
  }
}

export const parseSmartLink = link => {
  // Filter is necessary to remove empty strings. Empty string is possible if link starts with '/'.
  const ids = link.split('/').filter(Boolean)

  if (ids.length < DEFAULT_CARD_IDS_COUNT) {
    const [tenantId, boardId, cardUuidWithSection] = ids
    const [cardUuid, searchParams] = cardUuidWithSection ? cardUuidWithSection.split('?') : []

    return { tenantId, boardId, cardUuid, searchParams }
  }

  const [mode, tenantId, boardId, cardUuidWithMode] = ids
  const [cardUuid, searchParams] = cardUuidWithMode.split('?')

  return { mode, tenantId, boardId, cardUuid, searchParams }
}

export const navigateToLink = payload => {
  const pathname = history.location.pathname
  const match = matchPathname(pathname)

  if (!match) {
    return
  }

  const {
    mode,
    tenantId: newTenantId,
    boardId: newBoardId,
    cardUuid: newCardId,
    searchParams
  } = parseSmartLink(payload.link)

  if (newCardId) {
    navigateToCardLink({
      ...payload,
      newBoardId,
      newCardId,
      newTenantId,
      match,
      pathname,
      mode,
      searchParams
    })
  } else {
    navigateToBoardLink({ newBoardId, newTenantId, match })
  }
}

export const navigateToEmbedCard = ({ tenantId, boardId, cardUuid }) => {
  const location = generatePath(PATHS.embed.routerPath, { tenantId, boardId, cardID: cardUuid })

  history.push(location)
}

export const navigateToLinkInEmbedMode = ({ link }) => {
  navigateToEmbedCard(parseSmartLink(link))
}

export const navigateToRecentBoard = () => history.push(PATHS.board.url)

export const getBoardRoute = ({ tenantId, boardId }) =>
  generatePath(PATHS.board.routerPath, { tenantId, boardId })

export const navigateToBoard = ({ tenantId = null, boardId = null, isRecent = false }) => {
  if (isRecent) {
    navigateToRecentBoard()
  } else {
    history.push(getBoardRoute({ tenantId, boardId }))
  }
}

export const openBoardNewTab = ({ tenantId = null, boardId = null }) => {
  window.open(`${window.location.origin}${getBoardRoute({ tenantId, boardId })}`, '_blank')
}

export const navigateToEmptyTenant = tenantId => {
  const location = generatePath(PATHS.tenant.routerPath, { tenantId })

  history.push(location)
}

export const redirectToEmptyTenant = tenantId => {
  if (!tenantId || checkRoute(history.location.pathname, routesList.tenant)) return

  navigateToEmptyTenant(tenantId)
}

export const navigateToLoginPage = () => {
  if (isAuthCheckNeeded()) {
    history.push(PATHS.auth.url)
  }
}

export const navigateToLoginPageAfterSessionExpires = () => {
  const event = new CustomEvent(TOGGLE_SESSION_EXPIRATION_NOTIFICATION, {
    detail: { type: 'show' }
  })
  document.dispatchEvent(event)

  navigateToLoginPage()
}

export const navigateToHomePage = (data = { scrollPosition: 0 }) => {
  const search = new URLSearchParams(history.location.search)
  const entitlementId = search.get('entitlementId')

  history.push({
    pathname: PATHS.homepage.url,
    search: buildSearchParamsString({
      scrollPosition: data.scrollPosition || undefined,
      entitlementId
    })
  })
}

export const getBundleDetailsRoute = ({ bundleId }) =>
  generatePath(PATHS.bundle.routerPath, { appId: bundleId })

export const navigateToServerErrorState = () =>
  (window.location.href = '/internal-server-error.html')

export const getTeamAdministrationRoute = ({ tenantId, tab = '', hasOpenedFrom = true }) => {
  const pathname = generatePath(PATHS.teamAdministration.routerPath, { tenantId })
  const openedFrom = hasOpenedFrom ? getOpenedFromURLParam(pathname) : PATHS.root.url

  const search = buildSearchParamsString({ tab, openedFrom })

  return createPath({ pathname, search })
}

export const navigateToTeamAdministration = (tenantId, tab) => {
  history.push(getTeamAdministrationRoute({ tenantId, tab }))
}

export const getOrganizationAdministrationRoute = (organizationId, tab = null) => {
  const pathname = generatePath(PATHS.organizationAdministration.routerPath, { organizationId })
  const openedFrom = getOpenedFromURLParam(pathname)

  const search = buildSearchParamsString({ tab, openedFrom })

  return createPath({ pathname, search })
}

export const navigateToOrganizationAdministration = (organizationId, tab) => {
  history.push(getOrganizationAdministrationRoute(organizationId, tab))
}

export const navigateToCardBuilder = ({ tenantId, boardId, cardUuid, isApp }) => {
  const location = generatePath(PATHS.cardBuilder.routerPath, {
    tenantId,
    boardId,
    cardID: cardUuid
  })

  history.push({ pathname: location, state: { openedFromApp: isApp } })
}

export const getAppHomepageRoute = ({ homepage, boardIds }, boards) => {
  // check if app homepage is present in user available boards
  // or navigate to the first available board if the is no homepage
  if (boards && !boards.find(board => board.boardId === homepage?.boardId)) {
    // find first available board in app
    const appAvailableBoards = boards.filter(board => boardIds.includes(board.boardId))
    const firstAvailableBoard = appAvailableBoards[0]
    if (firstAvailableBoard) {
      return getBoardRoute(firstAvailableBoard)
    }
    return ''
  }
  if (homepage?.cardUuid) {
    const { cardOpeningMode = CARD_OPENING_MODE.app } = homepage
    switch (cardOpeningMode) {
      case CARD_OPENING_MODE.detailed:
        return getDetailedViewRoute(homepage)
      case CARD_OPENING_MODE.fullScreen:
        return getFullscreenDetailedViewRoute(homepage)
      default:
        return getAppViewRoute(homepage)
    }
  }
  return homepage ? getBoardRoute(homepage) : ''
}

export const navigateToAppView = ({ tenantId, boardId, cardID }) => {
  const pathname = generatePath(PATHS.app.routerPath, { tenantId, boardId, cardID })

  history.push({ pathname })
}

export const navigateToAppImportView = ({
  tenantId,
  appID,
  appJobId,
  appStoreID,
  fromAppImport
}) => {
  const pathname = generatePath(PATHS.appImport.routerPath, {
    tenantId,
    appID,
    appJobId,
    appStoreID
  })
  if (fromAppImport) {
    history.push({ pathname })
  } else {
    window.open(`${window.location.origin}${pathname}`, '_blank')
  }
}

export const navigateToAppBuilder = ({ tenantId, appId = 'new' }) => {
  const pathname = generatePath(PATHS.appBuilder.routerPath, { tenantId, appId })
  const search = buildSearchParamsString({ openedFrom: history.location.pathname })

  history.push({ pathname, search })
}

export const getAppStoreCategoryRoute = (category, saveSearch) => {
  const urlParams = new URLSearchParams(history?.location?.search)
  if (!saveSearch) urlParams.delete('search')
  const params = Object.fromEntries(urlParams)
  const search = buildSearchParamsString({ ...params, category })

  return createPath({ pathname: PATHS.appStore.url, search })
}

export const navigateToSearchPage = () => {
  const urlParams = new URLSearchParams(history?.location?.search)

  const params = Object.fromEntries(urlParams)
  const search = buildSearchParamsString({ ...params })

  // After navigating to the search page, the search in the URL should remain the same
  history.push({ pathname: PATHS.search.url, search })
}

export const navigateToAppStoreCategory = category => {
  history.push(getAppStoreCategoryRoute(category))
}

export const replaceAppStoreCategory = (category, saveSearch) => {
  history.replace(getAppStoreCategoryRoute(category, saveSearch))
}

export const exitFromAppBuilder = () => {
  const openedFrom = getUrlParam(window.location.search, 'openedFrom')

  if (openedFrom) {
    history.push(openedFrom)
  } else {
    navigateToRecentBoard()
  }
}

export const navToOpenedFrom = () => {
  const openedFrom = getUrlParam(window.location.search, 'openedFrom')

  if (openedFrom) {
    history.push(openedFrom)
    return true
  }
  return false
}

export const clearSearchParamFromURL = param => {
  const search = new URLSearchParams(history.location.search)
  search.delete(param)

  history.replace({
    search: search.toString()
  })
}
