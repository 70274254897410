import JSONIcon from 'assets/images/icons/json.svg?react'
import FileIcon from 'assets/images/icons/workflowBuilder/file-color.svg?react'
import messages from 'constants/messages'
import { OBJECT_TYPES } from 'constants/workflows'
import { type TWorkflowBlockOutputFormat } from 'features/workflow/workflowBuilder/model/workflowBuilder.types'

const MAX_TOKEN_COUNT = 30

export const TOKEN_INPUT_BOX_HINT_TEXT = `to select and configure message (max (${String(MAX_TOKEN_COUNT)}) [URL], [Header] and [Body] inputs altogether).`

export const REST_API_BLOCK_STRING_INPUTS_COUNT = 30

export const DEFAULT_REST_API_BLOCK_TIMEOUT = 30
export const DEFAULT_REST_API_BLOCK_RETRIES = 3
export const DEFAULT_REST_API_BLOCK_RETRY_TIMEOUT_FACTOR = 1

export const REST_API_CLIENT_BLOCK_OUTPUTS: Record<string, TWorkflowBlockOutputFormat> = {
  [OBJECT_TYPES.JSON]: {
    value: OBJECT_TYPES.JSON,
    title: messages.JSON,
    Icon: JSONIcon
  },
  [OBJECT_TYPES.FILE]: {
    value: OBJECT_TYPES.FILE,
    title: messages.FILE,
    Icon: FileIcon
  }
}
