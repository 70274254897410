const app = {
  appDeleteData: {
    state: false,
    app: null,
    tenantName: ''
  },
  appHistoryModalData: {
    state: false,
    appId: null
  },
  currentApp: null,
  isCurrentAppLoading: false,
  topMenuOpened: false
}

export default app
