import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import messages from 'constants/messages'
import PublishAppIndicatorContainer from 'containers/jobsIndicator/PublishAppIndicatorContainer'
import DownloadAppTemplateIndicatorContainer from 'containers/jobsIndicator/DownloadAppTemplateIndicatorContainer'
import WidgetActionToastMessage from 'components/messages/WidgetActionToastMessage'
import ActionsToastMessage from 'components/common/actionsToastMessage'
import ToastMessage from 'components/messages/ToastMessage'
import WorkflowExecutionIndicatorContainer from '../jobsIndicator/WorkflowExecutionIndicatorContainer'
import UnsavedDraftMessageContainer from './UnsavedDraftMessageContainer'
import MsAuthRequestContainer from './MsAuthRequestContainer'
import CopyBoardsIndicatorContainer from '../jobsIndicator/CopyBoardsIndicatorContainer'
import { hideToastMessage } from 'actions/boardActions'
import {
  COPY_BOARD_INDICATOR_TYPE,
  EXPORT_BUNDLE_INDICATOR_TYPE,
  IMPORT_BUNDLE_INDICATOR_TYPE,
  WORKFLOW_EXECUTION_INDICATOR_TYPE,
  APP_PUBLISHING_INDICATOR_TYPE,
  APP_TEMPLATE_DOWNLOAD_INDICATOR_TYPE,
  TOAST_MESSAGE_TYPE,
  DRAFT_MESSAGE_TYPE,
  MS_AUTH_MESSAGE_TYPE,
  TOGGLE_WIDGET_TOAST_ACTION_MESSAGE,
  NEW_VERSION_RELEASED
} from 'constants/actionTypes'
import ExportIndicatorContainer from '../jobsIndicator/ExportIndicatorContainer'
import ImportIndicatorContainer from '../jobsIndicator/ImportIndicatorContainer'
import 'scss/messages/toast-queue-container.scss'

const TOASTS_MARGING = 10
const TOAST_SMALL_WIDTH = 400
const TOAST_MEDIUM_WIDTH = 600
const TOAST_LARGE_WIDTH = 800

const TOAST_SMALL_HEIGHT = 40
const TOAST_MEDIUM_HEIGHT = 65
const TOAST_LARGE_HEIGHT = 100

const INDICATORS_TYPES = [
  COPY_BOARD_INDICATOR_TYPE,
  EXPORT_BUNDLE_INDICATOR_TYPE,
  IMPORT_BUNDLE_INDICATOR_TYPE,
  WORKFLOW_EXECUTION_INDICATOR_TYPE,
  APP_PUBLISHING_INDICATOR_TYPE,
  APP_TEMPLATE_DOWNLOAD_INDICATOR_TYPE
]

const getMessageHeight = size => {
  switch (size) {
    case 'S':
      return TOAST_SMALL_HEIGHT
    case 'M':
      return TOAST_MEDIUM_HEIGHT
    case 'L':
      return TOAST_LARGE_HEIGHT
    default:
      return TOAST_SMALL_HEIGHT
  }
}

const getMessageWidth = size => {
  switch (size) {
    case 'S':
      return TOAST_SMALL_WIDTH
    case 'M':
      return TOAST_MEDIUM_WIDTH
    case 'L':
      return TOAST_LARGE_WIDTH
    default:
      return TOAST_SMALL_WIDTH
  }
}

const getIndicatorComponent = type => {
  const components = {
    [COPY_BOARD_INDICATOR_TYPE]: CopyBoardsIndicatorContainer,
    [EXPORT_BUNDLE_INDICATOR_TYPE]: ExportIndicatorContainer,
    [IMPORT_BUNDLE_INDICATOR_TYPE]: ImportIndicatorContainer,
    [WORKFLOW_EXECUTION_INDICATOR_TYPE]: WorkflowExecutionIndicatorContainer,
    [APP_PUBLISHING_INDICATOR_TYPE]: PublishAppIndicatorContainer,
    [APP_TEMPLATE_DOWNLOAD_INDICATOR_TYPE]: DownloadAppTemplateIndicatorContainer
  }

  return components[type]
}

class ToastsQueueContainer extends Component {
  onMessageToastHide = id => {
    this.props.hideToastMessage(id)
  }

  handleForceUpdate = () => {
    this.forceUpdate()
  }

  getToastTransform(message) {
    const { id, visible, invisible, data } = message
    const { toastMessages } = this.props
    const index = toastMessages.findIndex(toast => toast.id === id)
    const height = getMessageHeight(data.size)
    const bottomOffset = toastMessages
      .slice(index + 1, toastMessages.length)
      .reduce((acc, toast) => {
        const { type } = toast
        if (toast.visible) {
          let messageHeight

          const isIndicator = INDICATORS_TYPES.includes(type)

          if (isIndicator && this.indicator?.getComponentHeight) {
            messageHeight = this.indicator.getComponentHeight()
          } else {
            messageHeight = getMessageHeight(toast.data.size)
          }

          return acc + messageHeight + TOASTS_MARGING
        }
        return acc
      }, 0)
    if (visible) {
      return `translateY(${-bottomOffset}px)`
    }
    if (invisible) {
      return `translate(800px,${-bottomOffset}px)`
    }
    return `translateY(${height + 45}px)`
  }

  getMessageComponent(message) {
    const width = getMessageWidth(message.data.size)
    const height = getMessageHeight(message.data.size)
    const transform = this.getToastTransform(message)

    const isIndicator = INDICATORS_TYPES.includes(message.type)
    const IndicatorComponent = isIndicator ? getIndicatorComponent(message.type) : null

    switch (message.type) {
      case TOAST_MESSAGE_TYPE:
        return (
          <ToastMessage
            key={message.id}
            messageId={message.id}
            visible={message.visible}
            invisible={message.invisible}
            text={message.data.text}
            width={width}
            height={height}
            transform={transform}
            onMessageHide={() => this.onMessageToastHide(message.id)}
          />
        )
      case DRAFT_MESSAGE_TYPE:
        return (
          <UnsavedDraftMessageContainer
            key={message.id}
            visible={message.visible}
            invisible={message.invisible}
            width={width}
            height={height}
            transform={transform}
            onMessageHide={() => this.onMessageToastHide(message.id)}
          />
        )
      case MS_AUTH_MESSAGE_TYPE:
        return (
          <MsAuthRequestContainer
            key={message.id}
            visible={message.visible}
            invisible={message.invisible}
            width={width}
            height={height}
            transform={transform}
            onMessageHide={() => this.onMessageToastHide(message.id)}
          />
        )
      case COPY_BOARD_INDICATOR_TYPE:
      case EXPORT_BUNDLE_INDICATOR_TYPE:
      case IMPORT_BUNDLE_INDICATOR_TYPE:
      case WORKFLOW_EXECUTION_INDICATOR_TYPE:
      case APP_PUBLISHING_INDICATOR_TYPE:
      case APP_TEMPLATE_DOWNLOAD_INDICATOR_TYPE:
        return (
          <IndicatorComponent
            key={message.id}
            visible={message.visible}
            invisible={message.invisible}
            width={width}
            transform={transform}
            onMessageHide={() => this.onMessageToastHide(message.id)}
            onForceUpdate={this.handleForceUpdate}
            onRef={ref => (this.indicator = ref)}
          />
        )
      case TOGGLE_WIDGET_TOAST_ACTION_MESSAGE:
        return (
          <WidgetActionToastMessage
            key={message.id}
            messageId={message.id}
            visible={message.visible}
            invisible={message.invisible}
            text={message.data.text}
            continueText={message.data.continueText}
            width={width}
            height={height}
            transform={transform}
            // eslint-disable-next-line react/jsx-handler-names
            onMessageContinue={message.data.onMessageContinue}
            onMessageHide={() => this.onMessageToastHide(message.id)}
          />
        )

      case NEW_VERSION_RELEASED:
        return (
          <ActionsToastMessage
            discardText="Dismiss"
            continueText="Refresh Page"
            isVisible={message.visible}
            isAvailableOnMobile
            onContinue={async () => {
              await message.data.onUpdate()
              window.location.reload()
            }}
            onDiscard={() => {
              message.data.onDismiss()
              this.onMessageToastHide(message.id)
            }}
          >
            <div>{messages.NEW_VERSION_RELEASED_TOAST_MESSAGE1}</div>
            <div>{messages.NEW_VERSION_RELEASED_TOAST_MESSAGE2}</div>
          </ActionsToastMessage>
        )

      default:
        return ''
    }
  }

  render() {
    const { toastMessages } = this.props

    const queue = toastMessages.map(message => this.getMessageComponent(message))

    if (!queue?.length) return null

    return <section className="toasts-container">{queue}</section>
  }
}

function mapStateToProps(state) {
  return {
    toastMessages: state.board?.toastMessages || []
  }
}

function mapDispatchToProps(dispatch) {
  return {
    hideToastMessage: bindActionCreators(hideToastMessage, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ToastsQueueContainer)
