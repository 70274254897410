const widget = {
  list: [],
  uploadedImages: [],
  isWidgetClassesListRequested: false,
  isUploadedImageDeleteModalShown: false,
  deleteUploadedImageErrorMessage: '',
  currentUploadFilesWidgetData: null,
  uploadingData: {
    index: -1,
    filesLength: 0,
    name: '',
    size: 0,
    percentage: 0
  },
  isFileUploading: false,
  toggleGooglePicker: false,
  isOpenOneDrivePicker: false,
  fullScreenWidgetShow: false
}

export default widget
