import { createReducer } from 'redux-create-reducer'
import * as ActionTypes from '../constants/actionTypes'

export const app = createReducer(
  {},
  {
    [ActionTypes.TOGGLE_APP_DELETE_MODAL](state, action) {
      return {
        ...state,
        appDeleteData: {
          state: action.payload ? action.payload.state : false,
          app: action.payload ? action.payload.app : null,
          tenantName: action.payload ? action.payload.tenantName : ''
        }
      }
    },

    [ActionTypes.TOGGLE_APP_HISTORY_MODAL](state, action) {
      return {
        ...state,
        appHistoryModalData: {
          state: action.payload ? action.payload.state : false,
          appId: action.payload ? action.payload.appId : null
        }
      }
    },

    [ActionTypes.TOGGLE_CURRENT_APP_LOADING](state, action) {
      return { ...state, isCurrentAppLoading: action.payload }
    },

    [ActionTypes.SET_CURRENT_APP](state, action) {
      return { ...state, currentApp: action.payload }
    },

    [ActionTypes.TOGGLE_TOP_MENU_OPENED](state, action) {
      return { ...state, topMenuOpened: action.payload }
    }
  }
)

export default app
