import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ArrowIcon from 'assets/images/icons/ic_arrow_bottom_394549.svg?react'
import { Menu } from 'components/common/menu/menu'
import RibbonIconButton from 'components/ribbonTools/RibbonIconButton'
import 'scss/ribbonTools/ribbon-split-toggle-button.scss'

const VERTICAL_DROPDOWN_OFFSET = -5

const Toggle = props => {
  const { isOpen, disabled, onToggle } = props

  return (
    <RibbonIconButton
      id="split-toggle-button"
      className="ribbon-toggle"
      active={isOpen}
      disabled={disabled}
      onClick={onToggle}
    >
      <ArrowIcon className={classNames('expand-icon', { open: isOpen })} />
    </RibbonIconButton>
  )
}

const RibbonSplitToggleButton = props => {
  const {
    id,
    defaultLastUsedIndex,
    appearance,
    className,
    disabled,
    dropUp,
    pullRight,
    additionalOption,
    singleOption,
    children
  } = props

  const anchorElRef = useRef(null)

  const [isOpen, setIsOpen] = useState(false)

  const [lastUsed, setLastUsed] = useState(null)

  const handleToggle = () => {
    setIsOpen(prev => !prev)
  }

  const closeDropdown = () => {
    setIsOpen(false)
  }

  const getClonedElement = ({ onClick, index }) => {
    const element = children({ onClick })[index]

    return React.cloneElement(element, {
      className: 'last-used-button',
      disabled
    })
  }

  const onChildClick = index => {
    setLastUsed(
      getClonedElement({
        onClick: onChildClick,
        index
      })
    )
  }

  useEffect(() => {
    if (singleOption) return

    setLastUsed(
      getClonedElement({
        onClick: onChildClick,
        index: defaultLastUsedIndex
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLastUsed])

  return (
    <div
      ref={anchorElRef}
      id={`${id}-dropdown`}
      className={classNames('ribbon-split-toggle-button', className)}
    >
      {singleOption || lastUsed}
      <Toggle isOpen={isOpen} disabled={disabled} onToggle={handleToggle} />
      <Menu
        className={classNames('ribbon-split-toggle-button-menu', appearance)}
        open={isOpen}
        onClose={closeDropdown}
        anchorEl={anchorElRef.current}
        container={anchorElRef.current}
        anchorOrigin={{
          vertical: dropUp ? VERTICAL_DROPDOWN_OFFSET : 'bottom',
          horizontal: pullRight ? 'right' : 'left'
        }}
        transformOrigin={{
          vertical: dropUp ? 'bottom' : VERTICAL_DROPDOWN_OFFSET,
          horizontal: pullRight ? 'right' : 'left'
        }}
      >
        {!singleOption && children && (
          <div className="button-list">{children({ onClick: onChildClick })}</div>
        )}
        {additionalOption}
      </Menu>
    </div>
  )
}

RibbonSplitToggleButton.propTypes = {
  id: PropTypes.string.isRequired,
  appearance: PropTypes.oneOf(['ordinary', 'horizontal-tools']),
  children: PropTypes.func,
  pullRight: PropTypes.bool,
  dropUp: PropTypes.bool,
  defaultLastUsedIndex: PropTypes.number,
  className: PropTypes.string,
  disabled: PropTypes.bool
}

RibbonSplitToggleButton.defaultProps = {
  appearance: 'horizontal-tools',
  className: '',
  dropUp: false,
  pullRight: false,
  defaultLastUsedIndex: 0,
  disabled: false
}

export default RibbonSplitToggleButton
