import React from 'react'
import classNames from 'classnames'
import crossIcon from 'assets/images/icons/ic_cross.svg?url'

const ToastMessage = props => {
  const { text, onMessageHide } = props
  return (
    <section
      className={classNames('toast-message', {
        visible: props.visible,
        'not-visible': props.invisible
      })}
      style={{
        transform: props.transform,
        width: props.width,
        height: props.height
      }}
    >
      <p> {text} </p>
      <div className="button-container" onClick={onMessageHide}>
        <img alt="" src={crossIcon} />
      </div>
    </section>
  )
}

export default ToastMessage
