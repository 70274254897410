export const getUniqueKey = ({ widgetUuid, cardUuid }) => `${cardUuid}-${widgetUuid}`

export const getIsDataLinkingResetNeed = widgetBufferList => {
  if (widgetBufferList.length < 2) return false
  const widgetsUUID = widgetBufferList.map(widget => widget.uuid)
  return widgetBufferList.some(widget => {
    const { dataLink } = widget
    if (!dataLink) return false
    const dataLinkValues = Object.values(dataLink)
    const foundLink = dataLinkValues.find(link => widgetsUUID.includes(link?.widgetKey?.widgetUuid))
    return foundLink
  })
}

export const updateCopiedWidgetDataLinking = widgetBuffer => {
  const updatedWidgets = widgetBuffer.map(widget => {
    const { dataLink } = widget
    const updatedDataLink = Object.entries(dataLink).map(([key, value]) => {
      const {
        widgetKey: { widgetUuid }
      } = value
      let updatedValue = value
      const dataSourceWidget = widgetBuffer.find(
        sourceWidget => sourceWidget.oldWidgetKey.widgetID === widgetUuid
      )
      if (dataSourceWidget) {
        updatedValue = {
          ...updatedValue,
          widgetKey: {
            ...updatedValue.widgetKey,
            tenantId: dataSourceWidget.tenantId,
            boardId: dataSourceWidget.boardId,
            cardUuid: dataSourceWidget.cardUuid,
            widgetUuid: dataSourceWidget.uuid
          }
        }
      }

      return { key, value: updatedValue }
    })

    return {
      ...widget,
      dataLink: updatedDataLink.reduce(
        (object, current) => ({ ...object, [current.key]: current.value }),
        {}
      )
    }
  })

  return updatedWidgets.map(widget => {
    const { oldWidgetKey, ...widgetData } = widget
    return widgetData
  })
}
