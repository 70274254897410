import { useMemo } from 'react'
import classNames from 'classnames'
import { components } from 'react-select'
import MultiCreatableSelect from 'components/fields/MultiCreatableSelect'
import FormField from 'components/common/FormField'
import { Tooltip } from 'components/common/tooltip/tooltip'
import { Select } from 'components/common/select/select'
import RoundIconButton from 'components/buttons/RoundIconButton'
import messages from 'constants/messages'
import { getSelectOptions } from 'helpers/filters/conditionsHelpers'
import { findSelectedOptionByKey } from 'helpers/fields/selectHelpers'
import { CURRENT_PREDICATE } from 'constants/variables/variableConfiguration/constants'
import { loadSuggestion } from 'helpers/autosuggestHelpers'
import 'scss/variables/variableConfiguration/rule.scss'

const fieldsConfigurationMap = {
  widget: {
    board: {
      showPredicate: true,
      predicateLabel: messages.WIDGET_FROM_BOARD,
      currentTooltip: messages.WIDGET_BOARD_CURRENT_TOOLTIP,
      valuePlaceholder: messages.BOARD_NAME,
      valueMaxLength: 200,
      suggestType: 'boardName'
    },
    card: {
      showPredicate: true,
      predicateLabel: messages.FROM_CARD,
      currentTooltip: messages.CARD_CURRENT_TOOLTIP,
      valuePlaceholder: messages.CARD_NAME,
      valueMaxLength: 50,
      suggestType: 'cardName'
    },
    widget: {
      className: 'widget-predicate',
      showPredicate: false,
      valueLabel: messages.WITH_NAME,
      valuePlaceholder: messages.WIDGET_NAME,
      valueMaxLength: 30,
      suggestType: 'widgetTitle'
    }
  },
  card: {
    board: {
      showPredicate: true,
      predicateLabel: messages.CARD_FROM_BOARD,
      currentTooltip: messages.CARD_BOARD_CURRENT_TOOLTIP,
      valuePlaceholder: messages.BOARD_NAME,
      valueMaxLength: 200,
      suggestType: 'boardName'
    },
    card: {
      showPredicate: true,
      predicateLabel: messages.FROM_CARD,
      currentTooltip: messages.CARD_CURRENT_TOOLTIP,
      valuePlaceholder: messages.CARD_NAME,
      valueMaxLength: 50,
      suggestType: 'cardName'
    }
  }
}

export const Rule = ({
  isSaving,
  tenantId,
  object,
  variableObject,
  predicate,
  predicates,
  value,
  error,
  onChangeRule
}) => {
  const currentPredicate = predicates[predicate]

  const predicateOptions = getSelectOptions(predicates)

  const ruleConfiguration = fieldsConfigurationMap[variableObject][object]

  const changePredicate = ({ value: newPredicate }) => {
    const rule = { object, predicate: newPredicate }

    if (newPredicate === CURRENT_PREDICATE) {
      rule.value = ''
    }

    onChangeRule(rule)
  }

  const changeValue = newValue => {
    onChangeRule({ object, value: newValue ? newValue.value.trim() : '' })
  }

  const onLoadSuggestion = inputValue =>
    loadSuggestion({
      type: ruleConfiguration.suggestType,
      tenantId,
      inputValue
    })

  const addValueOnBlur = event => {
    const newValue = event.target.value.trim()

    if (!newValue) {
      return
    }

    changeValue({ value: newValue })
  }

  const ClearIndicator = innerProps => (
    <components.ClearIndicator {...innerProps}>
      <i className="up-font-ic-cross-xs" />
    </components.ClearIndicator>
  )

  const selectedOption = useMemo(() => {
    const selectedOption = findSelectedOptionByKey(predicate, predicateOptions)

    return selectedOption?.value
  }, [predicate, predicateOptions])

  return (
    <div className={classNames('variable-configuration-rule-row', ruleConfiguration.className)}>
      {ruleConfiguration.showPredicate && (
        <Select
          className="rule-predicate"
          label={ruleConfiguration.predicateLabel}
          size={40}
          value={selectedOption}
          options={predicateOptions}
          disabled={isSaving}
          onChange={event => changePredicate(event.target)}
        />
      )}
      {!currentPredicate.type && (
        <Tooltip
          id="current-rule-tooltip"
          placement="top"
          title={ruleConfiguration.currentTooltip}
          className="rule-tooltip"
        >
          <RoundIconButton className="current-predicate-info" appearance="light-bg">
            <i className="up-font-ic-add-hint" />
          </RoundIconButton>
        </Tooltip>
      )}
      {currentPredicate.type && (
        <FormField
          id="rule-value"
          className="rule-value"
          label={ruleConfiguration.valueLabel || ''}
          error={error}
        >
          <MultiCreatableSelect
            size="large"
            values={value ? [{ value, label: value }] : []}
            placeholder={ruleConfiguration.valuePlaceholder}
            maxLength={ruleConfiguration.valueMaxLength}
            isMulti={false}
            loadSuggestion={onLoadSuggestion}
            getNewOptionData={inputValue => ({ value: inputValue, label: inputValue })}
            isDisabled={isSaving}
            customComponents={{ ClearIndicator }}
            isClearable
            onChange={changeValue}
            onBlur={addValueOnBlur}
          />
        </FormField>
      )}
    </div>
  )
}
