import { createReducer } from 'redux-create-reducer'
import * as ActionTypes from '../constants/actionTypes'

export const notifications = createReducer(
  {},
  {
    [ActionTypes.REQUEST_NOTIFICATIONS](state) {
      return { ...state, isNotificationsLoading: true }
    },

    [ActionTypes.RECEIVE_NOTIFICATIONS](state, action) {
      return {
        ...state,
        isNotificationsLoading: false,
        notifications: [...state.notifications, ...action.payload.notifications],
        cursor: action.payload.cursor,
        hasNextPage: action.payload.hasNextPage,
        totals: action.payload.totals
      }
    },

    [ActionTypes.CLEAR_NOTIFICATIONS](state) {
      return {
        ...state,
        notifications: [],
        hasNextPage: true,
        cursor: null
      }
    },

    [ActionTypes.RECEIVE_NOTIFICATIONS_ERROR](state, action) {
      return {
        ...state,
        isNotificationsLoading: false,
        notificationsErrorMessage: action.payload.message,
        cursor: 0
      }
    },

    [ActionTypes.RECEIVE_NOTIFICATIONS_TOTALS](state, action) {
      return {
        ...state,
        totals: action.payload.totals
      }
    },

    [ActionTypes.MAKE_NOTIFICATIONS_READ](state) {
      const { unread, read } = state.totals

      let readCount = 0
      if (!unread) {
        return { ...state }
      }

      const notificationsList = state.notifications.map(item => {
        if (!item.read) {
          readCount += 1
        }
        return { ...item, read: true }
      })

      return {
        ...state,
        totals: {
          ...state.totals,
          unread: unread - readCount,
          read: read + readCount
        },
        notifications: notificationsList
      }
    }
  }
)

export default notifications
