import { type ReactNode } from 'react'
import { type ModalProps } from '@mui/material/Modal'
import classNames from 'classnames'
import { Dialog, DialogContent, DialogTitle } from 'components/common/dialog/dialog'
import './widget-full-screen-modal.scss'

type TProps = {
  className?: string
  ribbon: ReactNode
  children: ReactNode
  show: boolean
  onModalCancel?: ModalProps['onClose']
}

export const WidgetFullScreenModal = ({
  className,
  ribbon,
  children,
  show,
  onModalCancel
}: TProps) => (
  <Dialog
    className={classNames('widget-full-screen-modal', className)}
    open={show}
    onClose={onModalCancel}
    onKeyUp={e => e.stopPropagation()}
  >
    <DialogTitle>{ribbon}</DialogTitle>
    <DialogContent>{children}</DialogContent>
  </Dialog>
)
