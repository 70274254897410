const profile = {
  user: {
    openedItems: {
      tenants: []
    }
  },
  teamSettings: {
    name: '',
    dateFormat: 'en-US',
    teamPermissions: {}
  },
  activeOrganization: {
    organizationPermissions: {},
    subscriptions: [],
    collections: null,
    areSubscriptionsLoaded: false
  },
  isOrganizationPermissionsRequested: false,
  organizations: [],
  boardMenu: {
    tenants: [],
    boards: [],
    apps: []
  },
  isJiraConnectionModalShown: false,
  jiraCredentials: {},
  jiraProjects: [],
  jiraIssueTypes: [],
  jiraStatuses: [],
  jiraUsers: [],
  jiraComponents: [],

  isOnBoardSettings: false,

  homePageConfigRequesting: false,
  homePageConfig: {},
  bundleImportTenantId: null,

  newImportedApps: []
}

export default profile
