const board = {
  cards: [],
  widgetsMap: {},
  isBoardWidgetsLoading: false,
  comments: [],
  commentsListOffset: '',
  areAllCommentsLoaded: false,
  currentBoard: {
    boardPermissions: {}
  },
  boardCreationData: {
    state: false,
    tenantId: null,
    gaLabel: null
  },
  isBoardDragDisabled: false,
  isRenameBoard: false,
  renameBoardModalData: {
    state: false,
    tenantId: null,
    boardId: null,
    name: ''
  },
  // uuid of card/column on board which title is in editing mode
  renamingCardUuid: null,
  boardCopyData: { state: false, tenantId: null, boardId: null, name: '' },
  boardDeleteData: {
    state: false,
    tenantId: null,
    boardId: null,
    name: '',
    goToRecentBoard: false
  },
  toastMessages: [],
  isColumnDeleteModalShown: false,
  isCardDeleteModalShown: false,
  deletingCardData: {},
  cardsPositionsETag: null,
  isCopyCardRequested: null,

  exportBoardsModalData: {
    state: false,
    tenantId: null
  },
  welcomeCardData: {
    state: false,
    tenantId: null,
    boardId: null,
    cardUuid: null
  },
  isBoardSettingsRequested: false,
  boardSettings: {},
  isTenantCreationShown: false,
  isBoardLoadingError: false,
  thirdPartyWidgets: []
}

export default board
