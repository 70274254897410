import { COMPLEX_ACTION_TYPE, CUSTOM_EVENT } from 'features/widgets/eventBus/eventBus.constants'

const graphAPIScopes = [
  'https://graph.microsoft.com/User.Read',
  'https://graph.microsoft.com/Files.Read',
  'https://graph.microsoft.com/Files.Read.All',
  'https://graph.microsoft.com/Sites.Read.All'
]

// to avoid problem with redirect after successful signup need to make auth again
// tou can see it in msal-login-redirect.html
const redirectUri = `${window.location.origin}/msal-login-redirect.html`

const clientId = import.meta.env.VITE_MICROSOFT_CLIENT_ID

export const getUserAgentApplication = () =>
  new window.Msal.UserAgentApplication(clientId, null, null, {
    redirectUri,
    cacheLocation: 'localStorage'
  })

const getGraphClient = authToken =>
  window.MicrosoftGraph.Client.init({
    authProvider(done) {
      if (typeof authToken === 'undefined') {
        done({ err: 'No auth token' })
      } else {
        done(null, authToken)
      }
    }
  })

export const authIfNeeded = () => {
  const userAgentApplication = getUserAgentApplication()

  if (!userAgentApplication) {
    return Promise.reject(new Error('NO_AUTH_INSTANCE'))
  }

  const user = userAgentApplication.getUser()

  if (!user) {
    return userAgentApplication
      .loginPopup(graphAPIScopes)
      .then(() => userAgentApplication.acquireTokenSilent(graphAPIScopes))
  }

  return userAgentApplication
    .acquireTokenSilent(graphAPIScopes)
    .catch(() => userAgentApplication.acquireTokenPopup(graphAPIScopes))
}

export const initializeMSApi = () =>
  authIfNeeded()
    .then(accessToken => getGraphClient(accessToken))
    .catch(err => {
      if (err.includes('popup_window_error')) {
        const event = new CustomEvent(CUSTOM_EVENT, {
          detail: { type: COMPLEX_ACTION_TYPE, payload: true, action: 'toggleMSAuthRequest' }
        })
        document.dispatchEvent(event)

        return Promise.reject(new Error('OneDrive auth popup blocked by browser'))
      }

      return Promise.reject(new Error('OneDrive initialization error'))
    })
