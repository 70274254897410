import { generateGUID } from '../common/commonHelpers'
import { getEntityFromResponse } from 'graphql/entities'
import { getDefaultPredicates } from 'features/filter/filterRuleConditions/filterRuleConditions.helpers'
import { EFilterRuleConditionPredicateName } from 'features/filter/filter.types'

export const FIELD_TYPES = {
  MULTI_SELECT: 'multiSelect',
  MULTI_INPUT: 'multiInput',
  INPUT: 'input'
}

export const RULE_VALUE_TYPE = {
  VALUE: 'text',
  VARIABLE: 'variable',
  ENTITY_IDS: 'entityIds'
}

export const FILTER_PREDICATE = {
  IS: 'is',
  CURRENT: 'current',
  CELL_COORDINATES: 'cellCoordinates',
  OWNERS_MANAGER: 'managerOfCurrentCardsOwner',
  BY_COLUMN_NAME: 'byColumnName',
  ALL: 'all'
}

export const FILTER_CONDITION = {
  UBF: 'UBF',
  BOARD: 'board',
  CARD: 'card',
  DATA_RECORD_COLUMN: 'dataRecordColumn',
  TEAM: 'team',
  TENANTS: 'tenants'
}

export const MULTIPLE_TEAMS_RULE_CONFIG = {
  [FILTER_CONDITION.TEAM]: {
    name: 'Team',
    defaultPredicate: FILTER_PREDICATE.CURRENT,
    predicates: {
      [FILTER_PREDICATE.CURRENT]: {
        name: FILTER_PREDICATE.CURRENT
      },
      [FILTER_PREDICATE.IS]: {
        name: FILTER_PREDICATE.IS,
        type: FIELD_TYPES.MULTI_INPUT,
        valueType: RULE_VALUE_TYPE.VALUE
      },
      [FILTER_PREDICATE.ALL]: {
        name: FILTER_PREDICATE.ALL
      }
    }
  }
}

export const changeArrayItemByUuid = ({ items, uuid, newProps }) =>
  items.map(rule => (rule.uuid === uuid ? { ...rule, ...newProps } : rule))

export const getAvailableRulesConfig = (rulesConfig, usedRules) => {
  return usedRules.reduce((availableRulesConfig, rule) => {
    const canUseMultipleTimes =
      rule.condition === FILTER_CONDITION.DATA_RECORD_COLUMN ||
      rule.condition === FILTER_CONDITION.UBF

    if (canUseMultipleTimes) {
      return availableRulesConfig
    }

    return {
      ...availableRulesConfig,
      [rule.condition]: {
        ...availableRulesConfig[rule.condition],
        used: true
      }
    }
  }, rulesConfig)
}

export const getFilterObjectRulesConfig = (rulesConfig, filterObject) =>
  Object.entries(rulesConfig).reduce((objectRulesConfig, [rule, config]) => {
    if (config.filterObjects?.includes(filterObject)) {
      return {
        ...objectRulesConfig,
        [rule]: config
      }
    }

    return objectRulesConfig
  }, {})

export const getFirstAvailableCondition = rulesConfig => {
  return Object.keys(rulesConfig).find(condition => !rulesConfig[condition]?.used)
}

export const createNewCondition = (condition, config) => {
  const conditionConfig = config[condition]
  const conditionDefaultPredicates = getDefaultPredicates(
    conditionConfig,
    EFilterRuleConditionPredicateName.condition
  )

  return {
    ...conditionDefaultPredicates,
    uuid: generateGUID(),
    condition,
    x: 1,
    y: 1,
    valueType: RULE_VALUE_TYPE.VALUE,
    variables: [],
    values: []
  }
}

export const getSelectOptions = (rules, filterUsed = false) =>
  Object.entries(rules).reduce((acc, [key, rule]) => {
    if (!filterUsed || !rule.used) {
      acc.push({
        value: key,
        label: rule.name
      })
    }

    return acc
  }, [])

export const getPredicateConfig = (rulesConfig, { condition, predicate }) =>
  rulesConfig?.[condition]?.predicates[predicate] || {}

const isEntityValid = (condition, { boardId, cardUuid }) =>
  (condition === FILTER_CONDITION.BOARD && boardId) ||
  (condition === FILTER_CONDITION.CARD && cardUuid)

const getEntityIds = ({ condition, entityIds }) =>
  entityIds.reduce((acc, value) => {
    const isValid = isEntityValid(condition, value)
    if (isValid) {
      acc.push({ data: value })
    }
    return acc
  }, [])

export const mapRulesToResourceData = ({ rules }) =>
  rules.reduce((acc, { conditions }) => {
    conditions.forEach(({ condition, predicate, entityIds }) => {
      if (predicate === FILTER_PREDICATE.IS) {
        Array.prototype.push.apply(acc, getEntityIds({ condition, entityIds }))
      }
    })
    return acc
  }, [])

export const mapResourceDataToRules = ({ rules }, data, organizationId) =>
  rules.map(rule => {
    const conditions = rule.conditions.map(filter => {
      const { condition, predicate } = filter
      if (predicate !== FILTER_PREDICATE.IS) {
        return filter
      }
      const entityIds = filter.entityIds.map(entity => {
        const isValid = isEntityValid(condition, entity)
        if (!isValid) {
          return entity
        }
        const resource = getEntityFromResponse({
          organizationId,
          data,
          entity
        })
        return {
          ...entity,
          ...resource
        }
      })
      return {
        ...filter,
        entityIds
      }
    })
    return {
      ...rule,
      conditions
    }
  })
