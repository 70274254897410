import { DateTime } from 'luxon'
import {
  type IFilterRuleCondition,
  type IFilterRulePredicateConfig,
  type TFilterRulesConfig,
  EFilterRuleCondition,
  EFilterRuleConditionPredicate,
  EFilterRuleConditionPredicateName,
  EFilterRuleConditionPredicateType,
  EFilterRuleConditionValueType
} from '../filter.types'
import { type TFilterRuleConditionAttributeOption } from './filterRuleConditions.types'

const RULE_CONDITION_NEXT_PREDICATE_NAME: Partial<
  Record<EFilterRuleConditionPredicateName, EFilterRuleConditionPredicateName>
> = {
  [EFilterRuleConditionPredicateName.condition]: EFilterRuleConditionPredicateName.predicate,
  [EFilterRuleConditionPredicateName.predicate]: EFilterRuleConditionPredicateName.cellPredicate,
  [EFilterRuleConditionPredicateName.cellPredicate]: EFilterRuleConditionPredicateName.datePredicate
}

const getConditionPredicatesConfigOptions = (
  conditionPredicatesConfig: TFilterRulesConfig<IFilterRulePredicateConfig>
) =>
  Object.entries(conditionPredicatesConfig).map<TFilterRuleConditionAttributeOption>(
    ([key, predicateConfig]) => ({
      value: key,
      label: predicateConfig.name,
      isUsed: predicateConfig.used,
      isBottomDivider: (key as EFilterRuleCondition) === EFilterRuleCondition.team
    })
  )

const getCellPredicatesConfigOptions = (
  cellPredicatesConfig: TFilterRulesConfig<IFilterRulePredicateConfig>
) => {
  return Object.entries(cellPredicatesConfig).map<TFilterRuleConditionAttributeOption>(
    ([key, predicateConfig]) => ({
      value: key,
      label: predicateConfig.name
    })
  )
}

export const getNextPredicateName = (predicateName: EFilterRuleConditionPredicateName) =>
  RULE_CONDITION_NEXT_PREDICATE_NAME[predicateName]

export const getPredicateConfig = (
  predicateConfig: IFilterRulePredicateConfig,
  predicate: EFilterRuleConditionPredicate | undefined
) => {
  const { predicates } = predicateConfig

  if (!predicates || !predicate || !predicates[predicate]) return null

  return predicates[predicate]
}

export const getDefaultPredicates = (
  predicateConfig: IFilterRulePredicateConfig,
  predicateName: EFilterRuleConditionPredicateName
): Partial<
  Record<EFilterRuleConditionPredicateName, EFilterRuleConditionPredicate | undefined | null>
> => {
  const defaultPredicateName = getNextPredicateName(predicateName)
  const defaultPredicateConfig = getPredicateConfig(
    predicateConfig,
    predicateConfig.defaultPredicate
  )

  if (!defaultPredicateName || !defaultPredicateConfig)
    return {
      [EFilterRuleConditionPredicateName.datePredicate]: null
    }

  return {
    [defaultPredicateName as string]: predicateConfig.defaultPredicate,
    ...getDefaultPredicates(defaultPredicateConfig, defaultPredicateName)
  }
}

export const getPredicatesConfigOptions = (
  predicateName: EFilterRuleConditionPredicateName,
  predicatesConfig: TFilterRulesConfig<IFilterRulePredicateConfig>
) => {
  if (predicateName === EFilterRuleConditionPredicateName.condition) {
    return getConditionPredicatesConfigOptions(predicatesConfig)
  }
  if (predicateName === EFilterRuleConditionPredicateName.cellPredicate) {
    return getCellPredicatesConfigOptions(predicatesConfig)
  }

  return Object.entries(predicatesConfig).map<TFilterRuleConditionAttributeOption>(
    ([key, predicateConfig]) => ({
      value: key,
      label: predicateConfig.name
    })
  )
}

export const getAttributeValueTypeOptions = (
  ruleCondition: IFilterRuleCondition,
  isSupportVariables = false
): TFilterRuleConditionAttributeOption[] => {
  if (
    ruleCondition.predicate === EFilterRuleConditionPredicate.is ||
    ruleCondition.cellPredicate === EFilterRuleConditionPredicate.is
  ) {
    return [
      {
        label: 'Manual input',
        value: EFilterRuleConditionValueType.entityIds
      }
    ]
  }

  const options: TFilterRuleConditionAttributeOption[] = [
    {
      label: 'Manual input',
      value: EFilterRuleConditionValueType.text
    }
  ]

  if (isSupportVariables && ruleCondition.condition !== EFilterRuleCondition.team) {
    options.push({
      label: 'Variable',
      value: EFilterRuleConditionValueType.variable
    })
  }

  return options
}

export const getAttributeValueFieldPlaceholder = (
  ruleCondition: IFilterRuleCondition,
  type: EFilterRuleConditionPredicateType
) => {
  if (type === EFilterRuleConditionPredicateType.multiInput) {
    return 'Type and press enter to add'
  }

  if (type === EFilterRuleConditionPredicateType.multiSelect) {
    if (ruleCondition.condition === EFilterRuleCondition.tenants) {
      return 'Select Team(s)'
    }

    return 'Select widget class(es)'
  }

  return ''
}

export const getFormattedDatePredicateValue = (timestamp: number) =>
  DateTime.fromMillis(timestamp).toFormat('LLL d, yyyy')
