import { type ChangeEvent, type ReactNode, useEffect, useRef, useState } from 'react'
import { Button } from '@praxie/shared'
import classNames from 'classnames'
import { type ColorResult, SketchPicker } from 'react-color'
import { useDispatch, useSelector } from 'react-redux'
import ChevronIcon from 'assets/images/icons/chevron.svg?react'
import { getCurrentUser } from 'selectors/profile.selectors'
import { updateUserColorsSet } from 'actions/profileActions'
import { addNewColorToUser, defaultColorSet } from 'helpers/builderHelpers'
import { Menu } from 'components/common/menu/menu'
import { Tooltip } from 'components/common/tooltip/tooltip'
import { Toggle } from 'components/ribbonTools/RibbonToggleButton'
import './color-picker.scss'

type TDefaultToggleProps = {
  toggleButtonIcon: ReactNode
  color: string
  toggleButtonClassName?: string
  isShown: boolean
  toggleColorPicker: () => void
}

const DefaultToggle = ({
  toggleButtonIcon,
  color,
  toggleButtonClassName,
  isShown,
  toggleColorPicker
}: TDefaultToggleProps) => (
  <div
    className={classNames('color-picker-toggle-btn', toggleButtonClassName)}
    onClick={toggleColorPicker}
  >
    {toggleButtonIcon}
    <ChevronIcon className={classNames('dropdown-arrow', { open: isShown })} />
    <div className="color-picker-toggle-btn-color" style={{ backgroundColor: color }} />
  </div>
)

type TProps = {
  toggleButtonIcon?: ReactNode
  color: string
  className?: string
  toggleButtonClassName?: string
  tooltipText?: string
  tooltipId?: string
  adjusted?: boolean
  disableAlpha?: boolean
  disabled?: boolean
  isShown: boolean
  onColorChange: (color: ColorResult) => void
  onToggleColorPicker: (value: boolean) => void
}

export const ColorPicker = ({
  toggleButtonIcon,
  color,
  className,
  toggleButtonClassName,
  tooltipText,
  tooltipId,
  adjusted = false,
  disableAlpha,
  disabled,
  isShown,
  onColorChange,
  onToggleColorPicker
}: TProps) => {
  const [lastColor, setLastColor] = useState('')
  const [presetColors, setPresetColors] = useState<string[]>([])

  const menuAnchor = useRef(null)
  const shouldCloseAfterChangeRef = useRef(true)

  const dispatch = useDispatch()

  const { id: userId, colorsSet } = useSelector(getCurrentUser) as {
    id: string
    colorsSet: string[]
  }

  useEffect(() => {
    if (!presetColors.length && userId) {
      setPresetColors(colorsSet.length ? colorsSet : defaultColorSet)
    }

    if (color && !lastColor) {
      setLastColor(color)
    }
  }, [color, presetColors, lastColor, userId, colorsSet])

  const toggleColorPicker = () => {
    onToggleColorPicker(!isShown)
  }

  const changeColor = (color: ColorResult) => {
    const colorCode = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`

    setLastColor(colorCode)
    onColorChange(color)

    if (shouldCloseAfterChangeRef.current) {
      toggleColorPicker()
    } else {
      shouldCloseAfterChangeRef.current = true
    }
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { currentTarget, type } = event

    // Check for mouse movement or click on .flexbox-fix to prevent closing the color picker
    if (type === 'mousemove' || (currentTarget.closest('.flexbox-fix') && !currentTarget.title)) {
      shouldCloseAfterChangeRef.current = false
    }
  }

  const saveColor = () => {
    if (lastColor) {
      onToggleColorPicker(false)

      dispatch(
        // @ts-expect-error
        updateUserColorsSet({
          data: { colorsSet: addNewColorToUser(presetColors, lastColor) as string[] },
          userId
        })
      )
    }
  }

  return (
    <div
      ref={menuAnchor}
      className={classNames('color-picker-component', className, {
        adjusted,
        open: isShown,
        disabled
      })}
    >
      {adjusted ? (
        <Toggle
          as="button"
          id={tooltipId}
          active={false}
          isOpen={isShown}
          className={classNames('color-picker-toggle-btn', toggleButtonClassName)}
          // eslint-disable-next-line react/no-unstable-nested-components
          iconRenderer={() => (
            <>
              {toggleButtonIcon}
              <div className="color-picker-toggle-btn-color" style={{ backgroundColor: color }} />
            </>
          )}
          tooltipText={tooltipText ? tooltipText : null}
          tooltipPlacement="bottom"
          disabled={disabled}
          onToggle={toggleColorPicker}
        />
      ) : (
        <Tooltip
          id={tooltipId}
          title={tooltipText ? tooltipText : ''}
          wrapperClassName="color-picker-toggle-btn-tooltip"
          shouldWrapChildrenWithDiv
        >
          <DefaultToggle
            toggleButtonClassName={toggleButtonClassName}
            toggleButtonIcon={toggleButtonIcon}
            color={color}
            toggleColorPicker={toggleColorPicker}
            isShown={isShown}
          />
        </Tooltip>
      )}
      {!disabled && (
        <Menu
          id="color-picker-pane-menu"
          className="color-picker-pane-menu"
          open={isShown}
          anchorEl={menuAnchor.current}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          onDoubleClick={e => e.stopPropagation()}
          onMouseDown={e => e.stopPropagation()}
          onClose={toggleColorPicker}
        >
          <div className={classNames('color-picker-wrapper', { show: isShown })}>
            <SketchPicker
              disableAlpha={disableAlpha}
              color={color}
              presetColors={presetColors}
              onChangeComplete={changeColor}
              onChange={(_, e) => handleChange(e)}
            />
            <Button appearance="secondary" onClick={saveColor}>
              Add to favorites
            </Button>
          </div>
        </Menu>
      )}
    </div>
  )
}
