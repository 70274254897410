import AppIcon from 'assets/images/icons/ic_app_20_6c7e8a.svg?react'
import { type IJob, EJobStatuses } from 'features/jobs/jobs.types'
import messages from 'constants/messages'
import CircleSpinner from 'components/spinners/CircleSpinner'

type TProps = {
  job: IJob
}

export const PublishAppIndicatorItem = ({ job }: TProps) => (
  <div className="board_item">
    <div className="board_data">
      <AppIcon />
      <div className="board_content">
        <span className="board_name bold">{job.data.name}</span>
        {job.status === EJobStatuses.ERROR && (
          <span className="error-text">{messages.OPERATION_FAILED}</span>
        )}
      </div>
    </div>
    <span className="board_progress">
      {job.status !== EJobStatuses.DONE && job.status !== EJobStatuses.ERROR && (
        <CircleSpinner className="copied-board__spinner" />
      )}
      {job.status === EJobStatuses.DONE && (
        <span className="blue-link static">{messages.PUBLISHED}</span>
      )}
    </span>
  </div>
)
