import { getWidgetSource } from 'features/widgets/widgets.helpers'
import { requestWrapper, url } from '../fetchHelpers'
import { resolveThirdPartyWidget } from './linkingResolution'

const getUnresolvedProviders = payload =>
  requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/cards/widgets/unresolvable`,
    type: 'GET',
    payload: {}
  })
const updateWidgetsData = payload =>
  requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/widgets`,
    type: 'PUT',
    payload: {
      widgets: payload.data
    }
  })

// need to return resolved widget with widget dataFormatCode to update it data
const mapResolvedProviderData = (dataFormatCode, resolvedProvider) => ({
  ...resolvedProvider,
  rows: JSON.stringify(resolvedProvider.data),
  dataFormatCode
})

export const updateThirdPartyWidget = widget => {
  const dataFormatCode = widget.dataFormatCode

  return getWidgetSource(widget)
    .then(widgetSource => resolveThirdPartyWidget(widgetSource, widget))
    .then(resolvedProvider => {
      if (!resolvedProvider) return null

      return mapResolvedProviderData(dataFormatCode, resolvedProvider)
    })
}

const resolveThirdPartyWidgets = widgets =>
  Promise.all(
    widgets.map(widget => {
      const dataFormatCode = widget.dataFormatCode
      return getWidgetSource(widget)
        .then(widgetSource => resolveThirdPartyWidget(widgetSource, widget))
        .then(resolvedProvider => mapResolvedProviderData(dataFormatCode, resolvedProvider))
        .catch(err => console.error(err))
    })
  ).then(resolvedProviders => resolvedProviders.filter(widget => !!widget))

export const updateThirdPartyWidgets = payload =>
  getUnresolvedProviders(payload)
    .then(response => resolveThirdPartyWidgets(response.data, payload.tenantId))
    .then(resolvedWidget => {
      if (!resolvedWidget.length) {
        return null
      }
      return updateWidgetsData({
        tenantId: payload.tenantId,
        data: resolvedWidget
      })
    })
    .catch(console.error)

export const updateThirdPartyWidgetsFromCard = widgets =>
  resolveThirdPartyWidgets(widgets)
    .then(resolvedWidget => {
      if (!resolvedWidget.length) {
        return null
      }
      return updateWidgetsData({
        tenantId,
        data: resolvedWidget
      })
    })
    .catch(console.error)
