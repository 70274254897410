import { requestWrapper, url } from 'helpers/fetchHelpers'
import graphqlRequestWrapper from '../graphql/common/requestHelpers'
import { getBoardQuery, getColumnQuery } from '../graphql/queries/boardQueries'
import {
  getCardDetailsQuery,
  getCardSectionDataQuery,
  getWidgetsQuery,
  getCardLogsQuery,
  getCardsWithActionsQuery
} from '../graphql/queries/cardQueries'
import {
  extractCardDetail,
  extractCardLogs,
  extractCardsList,
  extractColumn,
  extractWidgetsMap
} from '../graphql/dataExtractors/cardExtractors'
import { GraphQlError, GRAPHQL_UNEXPECTED_RESPONSE_TEXT } from '../graphql/common/errorHandler'

export async function getCardRequest(payload) {
  const {
    expand = {},
    filters = {},
    organizationId,
    tenantId,
    boardId,
    cardUuid,
    calledFrom = ''
  } = payload

  try {
    const response = await graphqlRequestWrapper({
      query: getCardDetailsQuery(expand, filters, { calledFrom }),
      variables: {
        organizationId,
        tenantId,
        boardId,
        cardUuid
      }
    })
    const cardData = extractCardDetail(response)
    if (cardData) {
      return { data: cardData }
    }
    throw new GraphQlError({
      message: GRAPHQL_UNEXPECTED_RESPONSE_TEXT
    })
  } catch (error) {
    throw new GraphQlError(error)
  }
}

export async function getCardSectionDataRequest(payload) {
  const { organizationId, tenantId, boardId, cardUuid } = payload

  try {
    const response = await graphqlRequestWrapper({
      query: getCardSectionDataQuery(),
      variables: {
        organizationId,
        tenantId,
        boardId,
        cardUuid
      }
    })
    const cardData = extractCardDetail(response)
    if (cardData) {
      return { data: cardData }
    }
    throw new GraphQlError({
      message: GRAPHQL_UNEXPECTED_RESPONSE_TEXT
    })
  } catch (error) {
    throw new GraphQlError(error)
  }
}

export async function getCardLogsRequest(payload) {
  const { organizationId, tenantId, boardId, cardUuid, offset, limit } = payload

  try {
    const response = await graphqlRequestWrapper({
      query: getCardLogsQuery(),
      variables: {
        organizationId,
        tenantId,
        boardId,
        cardUuid,
        offset,
        limit
      }
    })
    const cardLogsData = extractCardLogs(response)
    if (cardLogsData) {
      return cardLogsData
    }
    throw new GraphQlError({
      message: GRAPHQL_UNEXPECTED_RESPONSE_TEXT
    })
  } catch (error) {
    throw new GraphQlError(error)
  }
}

export function getCardSnapshot(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/card/${payload.cardUuid}/snapshot/widgets`,
    type: 'GET'
  })
}

export function toggleColumnKanbanVisibility(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/column/${payload.cardID}/kanban/visibility`,
    type: 'PUT',
    payload: payload.data
  })
}

export function toggleColumnVisibility(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/column/${payload.cardID}/visibility`,
    type: 'PUT',
    payload: payload.data
  })
}

export function changeCardOwners(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/card/${payload.cardID}/owners`,
    type: 'PUT',
    payload: payload.data
  })
}

export function renameCard(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/card/${payload.cardID}/rename`,
    type: 'PUT',
    payload: payload.data
  })
}

export function toggleColumnSorting(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/column/${payload.columnUuid}/sorting?enableSorting=${payload.data.enableSorting}`,
    type: 'PUT',
    payload: {}
  })
}

export async function getColumnRequest({ expand, ...variables }) {
  try {
    const columnQuery = getColumnQuery({
      ...expand
    })

    const response = await graphqlRequestWrapper({
      query: columnQuery,
      variables
    })

    const data = extractColumn(response)

    if (data) {
      return { data }
    }

    throw new GraphQlError({
      message: GRAPHQL_UNEXPECTED_RESPONSE_TEXT
    })
  } catch (error) {
    throw new GraphQlError(error)
  }
}

export async function getCardsRequest({ expand, ...variables }) {
  try {
    const response = await graphqlRequestWrapper({
      query: getBoardQuery({
        cards: expand
      }),
      variables
    })

    const data = extractCardsList(response)
    if (data) {
      return { data }
    }
    throw new GraphQlError({
      message: GRAPHQL_UNEXPECTED_RESPONSE_TEXT
    })
  } catch (error) {
    throw new GraphQlError(error)
  }
}

export async function getWidgets({ queries, boardId, tenantId, organizationId }) {
  try {
    if (!queries.length) {
      return { data: {} }
    }

    const data = await graphqlRequestWrapper({
      query: getWidgetsQuery(queries),
      variables: {
        organizationId,
        tenantId,
        boardId
      }
    })

    if (data) {
      return { data: extractWidgetsMap(data) }
    }
    throw new GraphQlError({
      message: GRAPHQL_UNEXPECTED_RESPONSE_TEXT
    })
  } catch (error) {
    throw new GraphQlError(error)
  }
}

export function processSaveCardsRequest(payload) {
  const updateOperation = payload.operations.update

  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/card/reorder`,
    type: 'PUT',
    payload: {
      ...payload.operations,
      update: {
        uuid: updateOperation.uuid,
        columnUuid: updateOperation.columnUuid,
        posN: updateOperation.posN,
        posD: updateOperation.posD
      }
    },
    positionsETag: payload.cardsPositionsETag
  })
}

export function processAddCardRequest(payload) {
  return requestWrapper({
    rUrl: `${url}v2/tenant/${payload.tenantId}/board/${payload.boardId}/card${
      payload.card.isCol ? '?isCol=true' : ''
    }`,
    type: 'POST',
    payload: payload.card
  })
}

export function processDeleteCardRequest(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/card/${payload.cardID}`,
    type: 'DELETE',
    payload: {},
    positionsETag: payload.cardsPositionsETag
  })
}

export function processCopyCardRequest(payload) {
  let queryParams = ''

  if (payload.data.link) {
    queryParams = '?link=true'
  } else if (payload.isCol) {
    queryParams = '?isCol=true'
  } else if (payload.async) {
    queryParams = '?async=true'
  }

  return requestWrapper({
    rUrl: `${url}v2/tenant/${payload.tenantId}/board/${payload.boardId}/card/${payload.cardID}/copy${queryParams}`,
    type: 'POST',
    payload: payload.data,
    positionsETag: payload.cardsPositionsETag
  })
}

export function processAddCardParticipants(payload) {
  const userId = payload.data[0]

  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantID}/board/${payload.boardID}/card/${payload.cardUUID}/participant/${userId}`,
    type: 'POST',
    payload: {}
  })
}

export function processRemoveCardParticipants(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantID}/board/${payload.boardID}/card/${payload.cardUUID}/participant/${payload.data}`,
    type: 'DELETE',
    payload: {}
  })
}

// default cards for board
export function processDefaultCardsRequest(payload) {
  const query = {}
  if (payload.withThumbnail) {
    query.withThumbnail = true
  }
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/card/list/settings/`,
    type: 'GET',
    payload: query
  })
}

// toggle showing of welcome card
export function toggleWelcomeCardShowing(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/welcome`,
    type: 'PUT',
    payload: payload.data
  })
}

export function processSearchDrawerRequest(payload) {
  const { query, offset, limit } = payload
  return requestWrapper({
    rUrl: `${url}card/search?text=${query}&offset=${offset}&limit=${limit}`,
    type: 'GET',
    payload: {}
  })
}

export function startCardEditing(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/card/${payload.itemID}?startEdit=true`,
    type: 'POST',
    payload: {}
  })
}

export function cancelCardEditing(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/card/${payload.itemID}?cancel=true`,
    type: 'POST',
    payload: {}
  })
}

// End of card editing
export function saveActiveCard(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/card/${payload.itemID}?saveActive=true`,
    type: 'POST',
    payload: {}
  })
}

export function searchCardsForSmartLink(payload) {
  const { tenantId, boardId, query, filterLinked = false } = payload

  const requestPayload = { text: query, filterLinked }

  return requestWrapper({
    rUrl: `${url}tenant/${tenantId}/board/${boardId}/smart-link/search`,
    type: 'GET',
    payload: requestPayload
  })
}

export function searchCards(payload) {
  const { tenantId, boardId, query, filterLinked = false } = payload

  const requestPayload = { text: query, filterLinked }

  return requestWrapper({
    rUrl: `${url}tenant/${tenantId}/board/${boardId}/card/search`,
    type: 'GET',
    payload: requestPayload
  })
}

// Saving to back-end operation
export function updateCardDraft(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/card/${payload.itemID}/draft/update`,
    type: 'POST',
    payload: payload.data
  })
}

export const resolveCard = payload => {
  const query = `?expand=widgets,lockOwner${
    payload.userDraftIfExist ? '&userDraftIfExist=true' : ''
  }`

  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/card/${payload.cardID}/resolution${query}`,
    type: 'GET',
    payload: {}
  })
}

export function getQueryWidgetsFromCard(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/queries`,
    type: 'POST',
    payload
  })
}

export function triggerCardAction(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/board/${payload.boardId}/card/${payload.cardUuid}/trigger/action`,
    type: 'POST',
    payload: payload.data
  })
}

export async function getCardsWithActions({ cards, organizationId }) {
  // group cards by tenantId + boardId
  const groupedCards = cards.reduce((acc, card) => {
    const key = `${card.tenantId}_${card.boardId}`
    if (!acc[key]) {
      acc[key] = {
        tenantId: card.tenantId,
        boardId: card.boardId,
        cardUuids: []
      }
    }
    acc[key].cardUuids.push(card.cardUuid)
    return acc
  }, {})

  const groups = Object.values(groupedCards)

  // configure ids for each group
  const variables = {
    organizationId,
    ...groups.reduce((acc, group, index) => {
      acc[`tenantId${index + 1}`] = group.tenantId
      acc[`boardId${index + 1}`] = group.boardId
      acc[`cardsUuids${index + 1}`] = group.cardUuids
      return acc
    }, {})
  }

  try {
    const data = await graphqlRequestWrapper({
      query: getCardsWithActionsQuery(groups),
      variables
    })

    if (data) {
      const cards = Object.values(data.data).flatMap(group => group.tenant.board.cards)

      return { data: cards }
    }
    throw new GraphQlError({
      message: GRAPHQL_UNEXPECTED_RESPONSE_TEXT
    })
  } catch (error) {
    throw new GraphQlError(error)
  }
}
