import { EWorkflowBlockTypes } from '../../constants/workflowBuilder/blocksTypes'
import { OBJECT_TYPES } from '../../constants/workflows'

const { GET_BOARD, GET_CARD, GET_WIDGET, GET_USER, GET_COLUMN } = EWorkflowBlockTypes

export const isGetBlock = blockType => {
  const getBlocks = [GET_WIDGET, GET_CARD, GET_BOARD, GET_USER, GET_COLUMN]

  return getBlocks.includes(blockType)
}

export const getResourcePayload = ({ entity, type }) => {
  switch (type) {
    case OBJECT_TYPES.BOARD:
      return {
        tenantId: entity.tenantId,
        boardId: entity.boardId,
        cardUuid: '',
        widgetUuid: ''
      }
    case OBJECT_TYPES.COLUMN:
      return {
        tenantId: entity.tenantId,
        boardId: entity.boardId,
        cardUuid: entity.cardUuid,
        widgetUuid: ''
      }
    case OBJECT_TYPES.CARD:
      return {
        tenantId: entity.tenantId,
        boardId: entity.boardId,
        cardUuid: entity.cardUuid,
        widgetUuid: ''
      }
    case OBJECT_TYPES.WIDGET:
      return {
        tenantId: entity.tenantId,
        boardId: entity.boardId,
        cardUuid: entity.cardUuid,
        widgetUuid: entity.widgetUuid
      }
    case OBJECT_TYPES.USER:
      return {
        userId: entity.userId
      }
    default:
      return {}
  }
}
