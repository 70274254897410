import React, { Component } from 'react'
import classNames from 'classnames'
import { Tooltip } from 'components/common/tooltip/tooltip'
import 'scss/common/action-button-block.scss'

export default class ActionButtonBlockTool extends Component {
  getActionButton(action, index) {
    const { value, tooltip, icon, selected, onClick, disabled, active, text, tooltipPosition } =
      action
    const { root } = this.props
    const button = (
      <div
        className={classNames('action-btn', {
          [value]: true,
          'is-selected': selected,
          active,
          disabled
        })}
        key={index}
        onClick={onClick || null}
      >
        {icon ? (
          <img src={`/assets/images/icons/${icon}`} alt={value} className={`${value}-icon`} />
        ) : (
          ''
        )}
        {text || ''}
      </div>
    )
    return tooltip
      ? this.getTooltipWrapper(button, tooltip, tooltipPosition, `${root}-${value}`)
      : button
  }

  getTooltipWrapper(component, tooltipText, tooltipPosition, tooltipId) {
    return (
      <Tooltip id={tooltipId} placement={tooltipPosition || 'bottom'} title={tooltipText}>
        {component}
      </Tooltip>
    )
  }

  render() {
    const { items, onSelect } = this.props

    const noSelected = items.length > 1 && items.every(item => !item.selected)
    const disabled = items.every(item => item.disabled)
    const single = items.length === 1
    return (
      <div
        className={classNames('actions-button-block', {
          'no-selected': noSelected,
          single,
          disabled
        })}
      >
        {items.map((item, index) => {
          if (item.component) {
            return React.cloneElement(item.component, {
              onSelect,
              // eslint-disable-next-line react/no-array-index-key
              key: index
            })
          }

          return this.getActionButton(item, index)
        })}
      </div>
    )
  }
}
