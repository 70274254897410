import React from 'react'
import classNames from 'classnames'

import messages from 'constants/messages'
import Condition from 'components/filters/filterConfiguration/Condition'
import ConditionsBlockHeader from './ConditionsBlockHeader'

import 'scss/filters/filterConfiguration/conditions-block.scss'

const MAX_RULES_COUNT = 20

const ConditionsBlock = props => {
  const {
    rules,
    object,
    operator,
    rulesConfig,
    deleteRule,
    toggleRuleEdit,
    changeOperator,
    toggleRuleCreate,
    ConditionsInfo,
    isReadOnly
  } = props

  const isAddRuleDisabled = rules.length >= MAX_RULES_COUNT || isReadOnly

  return (
    <div className="conditions-block">
      <ConditionsBlockHeader
        operator={operator}
        changeOperator={changeOperator}
        ConditionsInfo={ConditionsInfo}
        isReadOnly={isReadOnly}
      />
      <div className="filter-conditions-list">
        <button
          type="button"
          className={classNames('add-rule-btn', { disabled: isAddRuleDisabled })}
          onClick={toggleRuleCreate}
          disabled={isAddRuleDisabled}
        >
          {messages.ADD_RULE}
        </button>
        {rules.map((rule, index) => (
          <Condition
            key={rule.uuid}
            rule={rule}
            operator={operator}
            queryObject={object}
            rulesConfig={rulesConfig}
            deleteRule={deleteRule}
            toggleRuleEdit={toggleRuleEdit}
            isLast={index === rules.length - 1}
            isReadOnly={isReadOnly}
          />
        ))}
      </div>
    </div>
  )
}

export default ConditionsBlock
