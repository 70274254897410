import { requestWrapper, url } from 'helpers/fetchHelpers'

export function processSignInRequest(payload) {
  return requestWrapper({ rUrl: `${url}login`, type: 'POST', payload })
}

export function processSignUpRequest(payload) {
  const withVerify = payload.verifyToken ? `/token/${payload.verifyToken}` : ''
  return requestWrapper({ rUrl: `${url}signup${withVerify}`, type: 'POST', payload })
}

export function processResetLinkRequest(payload) {
  return requestWrapper({ rUrl: `${url}forgot`, type: 'POST', payload })
}

export function processSendVerifyAccount({ location, params }) {
  const searchParams = new URLSearchParams(location.search)
  const entitlementId = searchParams.get('entitlementId')

  const entitlementQueryParam = entitlementId ? `?entitlementId=${entitlementId}` : ''

  return requestWrapper({
    rUrl: `${url}verify/${params.token}${entitlementQueryParam}`,
    type: 'POST',
    payload: {},
    hasServerErrorHandling: true
  })
}

// to check expiration of invitation link from email
export function processSendVerifyLink(payload) {
  return requestWrapper({ rUrl: `${url}verify/${payload}`, type: 'GET', payload: {} })
}

// sign out
export function processUserSignOut() {
  return requestWrapper({ rUrl: `${url}logout`, type: 'POST', payload: {} })
}

export function processChangePasswordRequest(payload) {
  return requestWrapper({
    rUrl: `${url}reset/${payload.token}`,
    type: 'POST',
    payload: payload.data
  })
}
