import { createReducer } from 'redux-create-reducer'
import * as ActionTypes from '../constants/actionTypes'

export const ribbon = createReducer(
  {},
  {
    [ActionTypes.SET_RIBBON_CALLBACK](state, action) {
      return { ...state, ribbonCallback: action.payload || null }
    },

    [ActionTypes.SET_RIBBON_STATE](state, action) {
      return { ...state, ribbonState: action.payload || {} }
    }
  }
)
