import { type ChangeEvent } from 'react'
import classNames from 'classnames'
import PlainInput from 'components/fields/PlainInput'
import MultiSearchField from 'components/filters/conditionsModal/MultiSearchField'
import ValueField from 'components/filters/conditionsModal/ValueField'
import { VariablesField } from 'features/filter/components/variablesField/variablesField'
import {
  type IFilterRuleCondition,
  type IFilterRulePredicateConfig,
  type IFilterRuleValue,
  type TFilterRulesConfig,
  EFilterRuleCondition,
  EFilterRuleConditionPredicate,
  EFilterRuleConditionPredicateName,
  EFilterRuleConditionValueType
} from 'features/filter/filter.types'
import { type TFilterRuleConditionsProps } from 'features/filter/filterRuleConditions/filterRuleConditions'
import {
  getAttributeValueFieldPlaceholder,
  getAttributeValueTypeOptions
} from 'features/filter/filterRuleConditions/filterRuleConditions.helpers'
import { ConditionAttribute } from '../conditionAttribute/conditionAttribute'
import { DateField } from './components/dateField/dateField'
import './condition-value-type-attribute.scss'

type TProps = Pick<
  TFilterRuleConditionsProps,
  | 'rulesConfig'
  | 'isSupportVariables'
  | 'valueFieldRenderer'
  | 'onVariableUpdate'
  | 'onValueTypeSelect'
  | 'onVariablesChange'
  | 'onEntityIdsChange'
  | 'onValuesChange'
  | 'onLoadSuggestion'
  | 'onLoadVariableSuggestion'
> & {
  ruleCondition: IFilterRuleCondition
  attributeName: EFilterRuleConditionPredicateName
  attributesConfig: TFilterRulesConfig<IFilterRulePredicateConfig>
  onFocus: () => void
  onBlur: () => void
}

export const ConditionValueTypeAttribute = ({
  rulesConfig,
  ruleCondition,
  attributeName,
  attributesConfig,
  isSupportVariables,
  valueFieldRenderer,
  onVariableUpdate,
  onValueTypeSelect,
  onVariablesChange,
  onEntityIdsChange,
  onValuesChange,
  onLoadSuggestion,
  onLoadVariableSuggestion,
  onFocus,
  onBlur
}: TProps) => {
  const attribute = ruleCondition[attributeName] as EFilterRuleConditionPredicate
  const attributeConfig = attributesConfig[attribute]

  const handleValuesChange = (values: Array<{ value: IFilterRuleValue }>) => {
    if (onValuesChange) {
      onValuesChange(ruleCondition.uuid, values)
    }
  }

  const handleVariablesChange = (variables: IFilterRuleCondition['variables']) => {
    if (onVariablesChange) {
      onVariablesChange(ruleCondition.uuid, variables)
    }
  }

  const handleEntityIdsChange = (entityIds: IFilterRuleCondition['entityIds']) => {
    if (onEntityIdsChange) {
      onEntityIdsChange(ruleCondition.uuid, entityIds)
    }
  }

  const handleLoadSuggestion = (value: string) => {
    if (onLoadSuggestion) {
      return onLoadSuggestion(ruleCondition, value)
    }

    return Promise.resolve()
  }

  const getAttributeValueTypeField = () => {
    if (!attributeConfig?.type) return null

    const placeholder = getAttributeValueFieldPlaceholder(ruleCondition, attributeConfig.type)

    if (ruleCondition.condition === EFilterRuleCondition.team) {
      return (
        <PlainInput
          className="attribute-value-type-field"
          placeholder={placeholder}
          value={ruleCondition.values[0]}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleValuesChange([{ value: event.target.value }])
          }
          onFocus={onFocus}
          onBlur={onBlur}
        />
      )
    }

    if (isSupportVariables && ruleCondition.valueType === EFilterRuleConditionValueType.variable) {
      const isMultipleVariables =
        attributeName !== EFilterRuleConditionPredicateName.cellPredicate &&
        attributeName !== EFilterRuleConditionPredicateName.datePredicate

      return (
        <VariablesField
          className="attribute-value-type-field"
          isMulti={isMultipleVariables}
          variables={ruleCondition.variables}
          editVariable={onVariableUpdate}
          onLoadOptions={onLoadVariableSuggestion}
          onChange={handleVariablesChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      )
    }

    if (ruleCondition.valueType === EFilterRuleConditionValueType.entityIds) {
      const condition = ruleCondition.condition as string

      const hasError = ruleCondition.entityIds.some(value => !value.name)

      return (
        <MultiSearchField
          className="attribute-value-type-field"
          values={ruleCondition.entityIds}
          placeholder={`Select ${condition}(s)`}
          error={hasError && 'Some of these items do not exist'}
          loadSuggestion={handleLoadSuggestion}
          onChange={handleEntityIdsChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      )
    }

    if (
      attributeName === EFilterRuleConditionPredicateName.cellPredicate &&
      ruleCondition.valueType === EFilterRuleConditionValueType.text
    ) {
      return (
        <PlainInput
          className="attribute-value-type-field"
          placeholder={placeholder}
          value={ruleCondition.values[0]}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleValuesChange([{ value: event.target.value }])
          }
          onFocus={onFocus}
          onBlur={onBlur}
        />
      )
    }

    if (
      attributeName === EFilterRuleConditionPredicateName.datePredicate &&
      ruleCondition.valueType === EFilterRuleConditionValueType.text
    ) {
      return (
        <DateField
          className="attribute-value-type-field"
          value={Number(ruleCondition.values[0])}
          onChange={timestamp => handleValuesChange([{ value: String(timestamp) }])}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      )
    }

    const ValueFieldComponent = valueFieldRenderer ? valueFieldRenderer(ruleCondition) : ValueField

    const conditionConfig = rulesConfig[ruleCondition.condition as string]

    return (
      <ValueFieldComponent
        className="attribute-value-type-field"
        values={ruleCondition.values}
        valueType={ruleCondition.valueType}
        field={ruleCondition.condition as string}
        fieldType={attributeConfig.type}
        hasAutoComplete={!!attributeConfig.hasAutoComplete}
        placeholder={placeholder}
        options={conditionConfig?.options ?? []}
        loadSuggestion={handleLoadSuggestion}
        onChangeVariables={handleVariablesChange}
        onChange={handleValuesChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    )
  }

  const handleAttributeValueTypeSelect = (valueType: string) => {
    if (onValueTypeSelect) {
      onValueTypeSelect(ruleCondition.uuid, valueType as EFilterRuleConditionValueType)
    }
  }

  return (
    <ConditionAttribute
      className={classNames('filter-rule-condition-value-type-attribute', {
        'date-predicate': attributeName === EFilterRuleConditionPredicateName.datePredicate,
        'variable-type': ruleCondition.valueType === EFilterRuleConditionValueType.variable
      })}
      attributeValue={ruleCondition.valueType}
      attributeOptions={getAttributeValueTypeOptions(ruleCondition, isSupportVariables)}
      onAttributeSelect={handleAttributeValueTypeSelect}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      <div
        className="attribute-value-type-field"
        onClick={event => event.stopPropagation()}
        onKeyDown={event => event.stopPropagation()}
      >
        {getAttributeValueTypeField()}
      </div>
    </ConditionAttribute>
  )
}
