import { ENTITIES } from '../../constants/schemaConstants'
import { OBJECT_TYPES } from '../../../constants/workflows'
import { formatId } from '../../common/commonHelpers'

export const getUserPayload = ({ id }) => {
  const entity = ENTITIES[OBJECT_TYPES.USER]
  const alias = `${entity}${formatId(id)}`
  return `${alias}: ${entity}(id: "${id}"){username,userId:id,email,avatarImage:avatarUrl`
}

export const getUserResponse = ({ organization, userId }) => {
  const alias = `${ENTITIES[OBJECT_TYPES.USER]}${formatId(userId)}`
  return organization[alias] || null
}
