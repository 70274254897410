import { useMemo } from 'react'
import messages from 'constants/messages'
import { VARIABLES_CONFIG } from 'constants/variables/variableConfiguration/constants'
import { findSelectedOptionByKey } from 'helpers/fields/selectHelpers'
import { getSelectOptions } from 'helpers/filters/conditionsHelpers'
import FormField from 'components/common/FormField'
import { Select } from 'components/common/select/select'
import 'scss/variables/variableConfiguration/header.scss'

export const Header = ({ isSaving, name, object, nameError, onNameChange, onObjectChange }) => {
  const changeName = event => {
    const { value } = event.target

    if (!event.key || event.key === 'Enter') {
      onNameChange(value.trim())
    }
  }

  const objects = getSelectOptions(VARIABLES_CONFIG.objects)

  const selectedOption = useMemo(() => {
    const selectedOption = findSelectedOptionByKey(object, objects)

    return selectedOption?.value
  }, [object, objects])

  return (
    <header className="variable-configuration-header">
      <FormField
        id="variable-name"
        className="variable-name"
        label="Name"
        placeholder={messages.VARIABLE_NAME}
        maxLength={30}
        value={name}
        error={nameError}
        disabled={isSaving}
        onBlur={changeName}
        onKeyPress={changeName}
      />
      <Select
        className="variable-object"
        label={messages.DEFINE_FROM}
        size={40}
        value={selectedOption}
        options={objects}
        disabled={isSaving}
        onChange={event => onObjectChange(event.target)}
      />
    </header>
  )
}
