import React from 'react'
import { Tooltip } from 'components/common/tooltip/tooltip'
import VariableDetailsPreview from 'components/variables/variablePreview/VariableDetailsPreview'
import 'scss/filters/filterConfiguration/variable-preview.scss'

const VariablePreview = ({ variable }) => (
  <Tooltip
    id="variable-details-tooltip"
    placement="top"
    title={<VariableDetailsPreview variable={variable} />}
  >
    <span className="variable-preview">{variable.name}</span>
  </Tooltip>
)

export default VariablePreview
