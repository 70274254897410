import React from 'react'
import classNames from 'classnames'
import '../../scss/spinners/circle-spinner.scss'

const CircleSpinner = ({ className }) => {
  const wrapperClassName = classNames('circle-spinner_wrapper', {
    [className]: className
  })

  return (
    <section className={wrapperClassName}>
      <svg
        className="circle-spinner"
        width="25px"
        height="25px"
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className="path"
          fill="none"
          strokeWidth="6"
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="30"
        />
      </svg>
    </section>
  )
}

export default CircleSpinner
