import React from 'react'

import ConnectionsListHeader from './ConnectionsListHeader'
import ConnectionsListBody from './ConnectionsListBody'

const ConnectionsList = ({ activeKey, list, tenantId }) => {
  if (!list.length > 0) {
    return null
  }

  return (
    <div className="connections-list">
      <ConnectionsListHeader activeKey={activeKey} count={list.length} />
      <ConnectionsListBody activeKey={activeKey} list={list} tenantId={tenantId} />
    </div>
  )
}

export default ConnectionsList
