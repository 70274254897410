import { useMemo } from 'react'
import { generatePath } from 'react-router-dom'
import CardIcon from 'assets/images/icons/ic_card_grey.svg?react'
import { PATHS } from 'constants/paths.constants'
import DotsSpinner from 'components/spinners/DotsSpinner'
import { WIDGET_ICONS } from 'features/widgets/widgets.constants'
import {
  type IFilterConnectionObject,
  type ILogicalRuleConnectionObject,
  type IWidgetConnectionObject,
  type TVariableConnection,
  EVariableConnectionObjectType
} from '../variableConnections.types'
import { groupConnectionsByType } from './connectionList.helpers'
import './connection-list.scss'

const typedGeneratePath = generatePath as (route: string, params: Record<string, string>) => string

const FilterConnectionObject = ({ id, object }: TVariableConnection) => {
  const { name } = object as IFilterConnectionObject

  return (
    <li key={id} className="connection" title={name}>
      <span className="title">{name}</span>
    </li>
  )
}

const LogicalRuleConnectionObject = ({ id, object }: TVariableConnection) => {
  const { logicalRuleName } = object as ILogicalRuleConnectionObject

  return (
    <li key={id} className="connection" title={logicalRuleName}>
      <span className="title">{logicalRuleName}</span>
    </li>
  )
}

const WidgetConnectionObject = ({ id, object }: TVariableConnection) => {
  const { tenantId, boardId, cardUuid, widgetTitle, cardName, widgetClassName } =
    object as IWidgetConnectionObject

  const href = typedGeneratePath(PATHS.board.routerPath, {
    tenantId,
    boardId,
    cardID: cardUuid
  })

  return (
    <li key={id} className="connection">
      <div className="title" title={widgetTitle}>
        {WIDGET_ICONS[widgetClassName]}
        {widgetTitle}
      </div>
      <a
        className="source-name"
        title={cardName}
        target="_blank"
        rel="noopener noreferrer"
        href={href}
      >
        {cardName}
      </a>
    </li>
  )
}

type TProps = {
  connections: TVariableConnection[]
  loading: boolean
}

const CONNECTION_TYPE_COMPONENT = {
  [EVariableConnectionObjectType.FILTER]: FilterConnectionObject,
  [EVariableConnectionObjectType.WIDGET]: WidgetConnectionObject,
  [EVariableConnectionObjectType.LOGICAL_RULE]: LogicalRuleConnectionObject
}

const CONNECTION_TYPE_NAME = {
  [EVariableConnectionObjectType.FILTER]: 'filters',
  [EVariableConnectionObjectType.WIDGET]: 'widgets',
  [EVariableConnectionObjectType.LOGICAL_RULE]: 'logical rules'
}

export const ConnectionList = ({ connections, loading }: TProps) => {
  const groupedConnections = useMemo(() => {
    const _groupedConnections = groupConnectionsByType(connections)

    // @ts-expect-error
    return Object.keys(_groupedConnections).map((type: EVariableConnectionObjectType) => {
      const groupConnections = _groupedConnections[type]

      const isWidgetType = type === EVariableConnectionObjectType.WIDGET

      return (
        <div key={`$connection-group-${type}`} className="connection-group">
          <div className="header">
            Used in {CONNECTION_TYPE_NAME[type]} ({groupConnections.length})
            {isWidgetType && (
              <div className="card-column">
                <CardIcon />
                Card
              </div>
            )}
          </div>
          <ul className="connections">
            {groupConnections.map((connection: TVariableConnection) => {
              const Component = CONNECTION_TYPE_COMPONENT[connection.object.type]

              return <Component key={connection.id} id={connection.id} object={connection.object} />
            })}
          </ul>
        </div>
      )
    })
  }, [connections])

  return (
    <div className="variable-connection-group-list">
      {loading ? <DotsSpinner customClass="spinner" size="sm" color="dark" /> : groupedConnections}
    </div>
  )
}
